import { NotificationLevel, COMPANY_NAME } from '~/app/constants';
import {
  ConditionAssessmentPlan,
  BulletNoteStatusRecord,
  NotificationAttrs,
} from '~/app/@types/state';
import { isSalesDemoMode } from '~/app/flags';

import { NotificationType } from '~/app/@types';
import { isDismissed } from '../bulletNoteStatus';
import { createNotification } from './createNotification';

export const addDismissedNotification = (
  notifications: NotificationAttrs[],
  currentConditionResults: ConditionAssessmentPlan[],
  bulletNoteStatus: BulletNoteStatusRecord
) => {
  if (isSalesDemoMode) return;

  const numDismissed = currentConditionResults.filter((c) =>
    isDismissed(c.module, bulletNoteStatus, c.negative)
  ).length;
  const numNonNegativeConditions = currentConditionResults.filter((c) => !c.negative).length;

  if (numNonNegativeConditions === 0) {
    const notification = createNotification({
      type: NotificationType.ConditionsDismissed,
      content: `There is insufficient evidence for ${COMPANY_NAME} to recommend additional diagnoses.`,
      level: NotificationLevel.Warn,
    });
    notifications.push(notification);
  } else if (numDismissed && numNonNegativeConditions <= numDismissed) {
    const notification = createNotification({
      type: NotificationType.ConditionsDismissed,
      content: `All recommended ${COMPANY_NAME} diagnoses have been dismissed.`,
      level: NotificationLevel.Warn,
      buttonText: `View Dismissed ${numDismissed}`,
      buttonFunc: () =>
        document.querySelector('.DismissedList')?.scrollIntoView({ behavior: 'smooth' }),
    });
    notifications.push(notification);
  }
};
