import _ from 'lodash';
import { FC, ReactNode } from 'react';

import ChevronDownSVG from '../../images/chevron-down.svg';
import { AccordionAPI, SmartAccordion, theme } from '..';

const Content: FC = ({ children }) => <div>{children}</div>;

const getTitleCss = _.memoize(
  ({ isOpen, collapsable }: { isOpen: boolean; collapsable: boolean }) =>
    ({
      cursor: collapsable ? 'pointer' : 'initial',
      fontWeight: 600,
      padding: '5px 24px',
      position: 'relative',
      userSelect: 'none',
      msUserSelect: 'none',
      '& svg': {
        position: 'absolute',
        left: 5,
        top: 8,
        transform: isOpen ? 'rotate(180deg)' : 'none',
        transition: '200ms ease',
      },
    } as const)
);

const Header: FC<
  AccordionAPI & {
    title: ReactNode;
    collapsable: boolean;
  }
> = ({ isOpen, title, collapsable }) => (
  <div
    css={getTitleCss({ isOpen, collapsable })}
    onClick={(e) => {
      if (!collapsable) {
        e.stopPropagation();
      }
    }}
  >
    {collapsable && <ChevronDownSVG />}
    {title}
  </div>
);

const rootStyle = {};

const rootNotFirstStyle = {
  ...rootStyle,
  borderTop: `1px solid ${theme.colors.grey4}`,
};

export const TitledSmartAccordion: FC<{
  openByDefault?: boolean;
  first?: boolean;
  title: ReactNode;
  collapsable?: boolean;
}> = ({ children, first, openByDefault, title, collapsable = true }) => (
  <div style={first ? rootStyle : rootNotFirstStyle}>
    <SmartAccordion
      Content={Content}
      contentProps={{ children }}
      data-cy-titled-smart-accordion
      Header={Header}
      headerProps={{ title, collapsable }}
      openByDefault={openByDefault}
    />
  </div>
);
