import { FC } from 'react';
import { rowStyle } from './css';

import { AccordionContentItem, AccordionContentColumn } from './types';
import { AccordionTableContentCell } from './AccordionTableAccordionContentCell';

interface AccordionTableRowProps {
  data: AccordionContentItem;
  columns: AccordionContentColumn[];
}

const AccordionTableRow: FC<AccordionTableRowProps> = ({ columns, data }) => (
  <div data-cy-accordion-table-accordion-row style={rowStyle}>
    {columns.map((column, i) => (
      <AccordionTableContentCell key={i} column={column} data={data} />
    ))}
  </div>
);

interface AccordionTableColumnHeaders {
  columns: AccordionContentColumn[];
  showColumnHeaders: boolean;
}

export const AccordionTableColumnHeaders: FC<AccordionTableColumnHeaders> = ({
  columns,
  showColumnHeaders,
}) => {
  if (!showColumnHeaders) {
    return null;
  }
  // Not yet implemented
  console.warn('Column headers not yet implemented', columns);
  return null;
};

const contentStyle = { overflow: 'hidden' } as const;

interface AccordionTableAccordionContentProps {
  columns: AccordionContentColumn[];
  rows: AccordionContentItem[];
  showingAllRows: boolean;
  showColumnHeaders: boolean;
}

export const AccordionTableAccordionContent: FC<AccordionTableAccordionContentProps> = ({
  columns,
  rows,
  showingAllRows,
  showColumnHeaders,
}) => (
  <div style={contentStyle}>
    <AccordionTableColumnHeaders columns={columns} showColumnHeaders={showColumnHeaders} />
    {(showingAllRows ? rows : rows.slice(0, 3)).map((row, i) => (
      <AccordionTableRow key={i} columns={columns} data={row} />
    ))}
  </div>
);
