import { Dispatch, FC } from 'react';

import { DislikeDialogAction, DislikedReasonKey } from './dislikeDialogReducer';

const checkboxStyle = {
  marginTop: 6, // this would be set to 12, but some extra height is sneaking in
} as const;

const checkboxLabelStyle = {
  marginLeft: 4,
  position: 'relative',
  top: -2,
} as const;

export const MaxChatDislikeDialogCheckbox: FC<{
  dispatch: Dispatch<DislikeDialogAction>;
  id: DislikedReasonKey;
  label: string;
}> = ({ dispatch, id, label }) => (
  <div style={checkboxStyle}>
    <input
      id={id}
      onChange={(e) =>
        dispatch({
          type: 'updateDislikeReason',
          payload: { id, value: e.target.value as 'on' | '' },
        })
      }
      type="checkbox"
    />
    <label htmlFor={id} style={checkboxLabelStyle}>
      {label}
    </label>
  </div>
);
