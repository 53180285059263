import { filter, sortBy, memoize } from 'lodash';
import { Keyword } from '../../@types/state';
import { insertTagAtMatchedKeywords } from './insertTagAtMatchedKeywords';
import { splitHtmlIntoCharacterItems } from './splitHtmlIntoCharacterItems';
import { characterItemsToTree } from './characterItemsToTree';
import { CharacterItem, HtmlTree } from './types';
import { getRawHtml } from './utils';

interface SanitizeKeywordsProps {
  keywords: Keyword[];
  characterItems: CharacterItem[];
}

const sanitizeKeywords = (props: SanitizeKeywordsProps): Keyword[] => {
  const { keywords, characterItems } = props;
  const rawText = getRawHtml(characterItems);

  // Sort keywords so the first keyword is the keyword that first appears
  //  in the text. Keywords are known to get unsorted, for example, when
  //  cut/pasting a multi-module line from another condition.
  // If keywords are not sorted, we will end up overlooking viable matches
  //  in the naive `insertTagAtMatchedKeywords` function.
  // We filter to skip useless iterations down the line.
  const filteredKeywords = filter(keywords, (keyword) => rawText.includes(keyword.text));
  const sortedAndFilteredKeywords = sortBy(filteredKeywords, (keyword) =>
    rawText.indexOf(keyword.text)
  );

  return sortedAndFilteredKeywords;
};

interface GetHtmlTreeWithKeywordSpansProps {
  keywords: Keyword[];
  html: HtmlString;
}

/**
 * Returns a HtmlTree representing the given `html` with a
 * keyword node wrapping any found matches from the given keywords.
 */
export const getHtmlTreeWithKeywordSpans = memoize(
  (props: GetHtmlTreeWithKeywordSpansProps): HtmlTree => {
    const { keywords, html } = props;

    const characterItems = splitHtmlIntoCharacterItems(html);

    const sanitizedKeywords = sanitizeKeywords({ keywords, characterItems });

    const insertedCharacterItems = insertTagAtMatchedKeywords({
      keywords: sanitizedKeywords,
      characterItems,
    });

    const htmlTree = characterItemsToTree(insertedCharacterItems);
    return htmlTree;
  }
);
