import { FC, memo } from 'react';

import { innerStyle, suggestionsBorderStyle, suggestionsStyle } from './css';
import { useConditionContext } from '../conditionProvider';
import { ConditionSuggestion } from '../conditionSuggestion';
import { ConditionSuggestionsBackground } from '../conditionSuggestionsBackground';
import { ConditionLineStatus, Suggestion } from '../../../@types';
import { interpretationIsCritical, nth } from '../../../utils';

const addNextAndPreviousStatus = (
  suggestions: Suggestion[]
): {
  suggestion: Suggestion;
  key: string;
  nextStatus: ConditionLineStatus;
  previousStatus: ConditionLineStatus;
}[] =>
  suggestions.map((suggestion, i) => ({
    suggestion,
    key: suggestion.ids.join('_'),
    nextStatus: nth(suggestions, i + 1)?.status ?? 'none',
    previousStatus: nth(suggestions, i - 1)?.status ?? 'none',
  }));

export const ConditionSuggestions: FC = memo(() => {
  const { id, suggestions, suggestionsAreMinimized } = useConditionContext(
    ({ id, suggestions, suggestionsAreMinimized }) => ({
      id,
      suggestions,
      suggestionsAreMinimized,
    })
  );

  const filteredSuggestions = suggestionsAreMinimized
    ? suggestions.filter(({ interpretation }) => interpretationIsCritical(interpretation))
    : suggestions;

  return (
    <div data-cy-suggested-bullets>
      {filteredSuggestions.length ? (
        <div style={suggestionsStyle}>
          <div style={suggestionsBorderStyle} />
          <ConditionSuggestionsBackground />
          <div style={innerStyle}>
            {addNextAndPreviousStatus(filteredSuggestions).map(
              ({
                suggestion: { status, text, interpretation },
                key,
                nextStatus,
                previousStatus,
              }) => (
                <ConditionSuggestion
                  key={key}
                  conditionId={id}
                  id={key}
                  interpretation={interpretation}
                  nextStatus={nextStatus}
                  previousStatus={previousStatus}
                  status={status}
                  text={text}
                />
              )
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
});
ConditionSuggestions.displayName = 'ConditionSuggestions';
