import { FC } from 'react';

import { MoveMenuItem, MoveMenuCurrentItem } from './MoveMenuItem';
import { MenuItem } from './types';
import { itemsStyle } from './css';

interface MenuProps {
  items: MenuItem[];
  currentIndex: number;
  onPlacementClick?: (index: number) => void;
}

export const MoveMenu: FC<MenuProps> = (props) => {
  const { currentIndex, items, onPlacementClick } = props;
  const currentItem = items[currentIndex];

  return currentItem ? (
    <div data-cy-move-menu style={itemsStyle}>
      {items.map((menuItem) => {
        switch (menuItem.index) {
          case currentIndex:
            return menuItem.menuStyle !== 'header' ? (
              <MoveMenuItem key={menuItem.index} {...menuItem} />
            ) : null;
          case currentIndex + 1:
            return <MoveMenuCurrentItem key={menuItem.index} {...menuItem} />;
          default:
            return (
              <MoveMenuItem
                key={menuItem.index}
                onPlacementClick={onPlacementClick}
                {...menuItem}
              />
            );
        }
      })}
    </div>
  ) : null;
};
