import { TitleStyles } from '~/app/@types/state';
import { STYLE_BOLD_TAG, STYLE_ITALIC_TAG, STYLE_UNDERLINE_TAG } from '~/app/constants';

export const addStylesToHtml = (html: HtmlString, titleStyles: TitleStyles, wrapWithDiv = true) => {
  let styledHtml = html;

  if (titleStyles.bold)
    styledHtml = `<${STYLE_BOLD_TAG}>${styledHtml}</${STYLE_BOLD_TAG}>` as HtmlString;
  if (titleStyles.italic)
    styledHtml = `<${STYLE_ITALIC_TAG}>${styledHtml}</${STYLE_ITALIC_TAG}>` as HtmlString;
  if (titleStyles.underline)
    styledHtml = `<${STYLE_UNDERLINE_TAG}>${styledHtml}</${STYLE_UNDERLINE_TAG}>` as HtmlString;

  if (wrapWithDiv) styledHtml = `<div>${styledHtml}</div>` as HtmlString;

  return styledHtml as HtmlString;
};
