import _ from 'lodash';
import { captureExceptionWithContext } from '../../../analytics';
import { MeshingNotesError } from '../../../errors';
import { BulletIndexingMetaData, IndexingData, PostModuleNoteBlock } from '../../../@types/state';

const addMergedAndDedupedBullets = (
  bulletTextKey: string,
  indexingDataBullet: BulletIndexingMetaData,
  combinedBulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData>,
  bulletIdsAddedToBulletTextKey: Record<string, string>
) => {
  // In order for us to process bullets that have identical text, we treat them as one bullet
  // and store the bullet.ids of both into a shared bullet object's `ids` array
  const indexingDataBulletId = indexingDataBullet.ids[0];
  const justBulletId = indexingDataBulletId.slice(indexingDataBulletId.indexOf('.') + 1);

  if (bulletIdsAddedToBulletTextKey[justBulletId]) {
    const bulletTextKeyFromBulletId = bulletIdsAddedToBulletTextKey[justBulletId];
    try {
      combinedBulletsByTrimmedTextKey[bulletTextKeyFromBulletId].ids.push(indexingDataBulletId);
    } catch (e) {
      captureExceptionWithContext(new MeshingNotesError('Error deduping bullets'), {
        module,
        bulletId: indexingDataBulletId,
      });
    }
    return;
  }

  if (combinedBulletsByTrimmedTextKey[bulletTextKey]) {
    // This if translates to "If we have seen this exact bullet text from another mapped module already"
    if (combinedBulletsByTrimmedTextKey[bulletTextKey].ids.length === 1) {
      // eslint-disable-next-line no-param-reassign
      combinedBulletsByTrimmedTextKey[bulletTextKey] = {
        ...combinedBulletsByTrimmedTextKey[bulletTextKey],
        ids: [indexingDataBulletId].concat(combinedBulletsByTrimmedTextKey[bulletTextKey].ids),
      };
    } else {
      // Just add the ID to the previous bullet's `ids` array
      combinedBulletsByTrimmedTextKey[bulletTextKey].ids.push(indexingDataBulletId);
    }
    return;
  }

  // we must clone the original indexingDataBullet from the regardCondition so that
  // when we `push` additional IDs we do not affect the regardCondition's object
  const deepClonedBulletIndexingMetaData = _.cloneDeep(indexingDataBullet);

  // eslint-disable-next-line no-param-reassign
  combinedBulletsByTrimmedTextKey[bulletTextKey] = deepClonedBulletIndexingMetaData;
  // eslint-disable-next-line no-param-reassign
  bulletIdsAddedToBulletTextKey[justBulletId] = bulletTextKey;

  // bullet IDs can be a combo of 2+ ids connected by ampersand: "last-hgb&last-scr"
  // we want to split those ids so we can dedupe the un-combo-bullets in favor of the combo
  const splitBulletIds = justBulletId.split('&');
  if (splitBulletIds.length > 1) {
    splitBulletIds.forEach((splitBulletId) => {
      if (bulletIdsAddedToBulletTextKey[splitBulletId]) {
        // here we've found an un-combo-bullet has already been added for this condition
        // merge the un-combo-bullet's ID into the combo bullet for BulletNoteStatus tracking
        const existingBulletTextKey = bulletIdsAddedToBulletTextKey[splitBulletId];

        if (combinedBulletsByTrimmedTextKey[existingBulletTextKey]) {
          // In the case of combo bullets like completed-meds&completed-meds, this may duplicate
          // ids, but that doesn't have a negative effect on the front end
          combinedBulletsByTrimmedTextKey[existingBulletTextKey].ids.forEach((id) => {
            if (deepClonedBulletIndexingMetaData.ids.indexOf(id) === -1) {
              deepClonedBulletIndexingMetaData.ids.push(id);
            }
          });

          // then remove the un-combo-bullet from this condition unless it's the same text
          // in which case we'd be removing the information we just added
          if (existingBulletTextKey !== bulletTextKey) {
            // eslint-disable-next-line no-param-reassign
            delete combinedBulletsByTrimmedTextKey[existingBulletTextKey];
          }
        }
      }
      // eslint-disable-next-line no-param-reassign
      bulletIdsAddedToBulletTextKey[splitBulletId] = bulletTextKey;
    });
  }
};

const addBulletsForModule = (
  module: string,
  conditionsByModule: IndexingData['conditionsByModule'],
  combinedBulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData>,
  bulletIdsAddedToBulletTextKey: Record<string, string>
) => {
  const { bulletsByTrimmedTextKey: moduleBulletsByTrimmedTextKey } = conditionsByModule[module];

  // Now we merge and DEDUPE bullets that have matching text
  Object.entries(moduleBulletsByTrimmedTextKey).forEach(([bulletTextKey, indexingDataBullet]) =>
    addMergedAndDedupedBullets(
      bulletTextKey,
      indexingDataBullet,
      combinedBulletsByTrimmedTextKey,
      bulletIdsAddedToBulletTextKey
    )
  );
};

export const getBulletsByTrimmedTextKeyForMultipleModules = ({
  conditionsByModule,
  modules,
}: {
  conditionsByModule: IndexingData['conditionsByModule'];
  modules: string[];
}): Pick<PostModuleNoteBlock, 'bulletsByTrimmedTextKey'> => {
  const combinedBulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData> = {};
  const bulletIdsAddedToBulletTextKey: Record<string, string> = {};

  modules.forEach((module) =>
    addBulletsForModule(
      module,
      conditionsByModule,
      combinedBulletsByTrimmedTextKey,
      bulletIdsAddedToBulletTextKey
    )
  );

  return {
    bulletsByTrimmedTextKey: combinedBulletsByTrimmedTextKey,
  };
};
