import _ from 'lodash';
import { FC } from 'react';

import { ButtonTag, theme } from '../../../reuse';
import { getConditionDisplayName } from '../../../utils';

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
} as const;

const buttonFocus = {
  backgroundColor: theme.colors.lightBlue2,
  color: theme.colors.blueMedium,
  fontWeight: 600,
};

const getButtonCss = _.memoize(
  ({ first, selected }: { first: boolean; selected: boolean }) =>
    ({
      borderBottom: `4px solid ${selected ? theme.colors.blueRegular : theme.colors.none}`,
      color: selected ? theme.colors.grey1 : theme.colors.grey2,
      cursor: 'pointer',
      // NOTE: This flex style is not ideal; this is the only way to get
      //  something reasonable working in IE.
      // If it were Chrome, we'd just throw a flex-shrink: 1 on the
      //  non-selected tabs.
      flex: selected ? '1 0 auto' : '1 1',
      fontWeight: selected ? 700 : 600,
      height: 24,
      marginLeft: first ? 0 : 16,
      minWidth: 0,
      padding: '0 2px',
      position: 'relative',
      ...(selected
        ? undefined
        : {
            '&:focus': buttonFocus,
            '&:hover': buttonFocus,
            '&:active': buttonFocus,
          }),
    } as const)
);

const buttonTextStyle = {
  ...ellipsisStyle,
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
} as const;

const buttonWidthStyle = {
  fontWeight: 700,
  visibility: 'hidden',
} as const;

export const DxDetailTabButton: FC<{
  module: string;
  first: boolean;
  openDxDetails(args: { selectedModule: string }): void;
  selected: boolean;
}> = ({ module, first, openDxDetails, selected }) => {
  const readableConditionName = getConditionDisplayName(module);

  return (
    <ButtonTag
      css={getButtonCss({ first, selected })}
      data-cy-dx-detail-tab-button
      disabled={selected}
      onClick={() => openDxDetails({ selectedModule: module })}
    >
      <span style={buttonTextStyle}>{readableConditionName}</span>
      <span style={buttonWidthStyle}>{readableConditionName}</span>
    </ButtonTag>
  );
};
