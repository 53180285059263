import _ from 'lodash';
import { BR_ELEMENT } from '~/app/constants';
import { replace } from '~/app/utils';

import { CharacterItem, CharacterItemTagType } from './types';

const defaultDiv = { type: 'tag', tag: 'div', id: '0' };

const sanitizeBrsInLine = (items: CharacterItem[]): CharacterItem[][] => {
  const cleanedItemLines: CharacterItem[][] = [];
  let cleanedItems = items;
  let index = 0;
  // The number of times the pass in items needed to be split.
  // This allows distinct div ids to be generated per split line.
  let splitCount = 0;

  while (index < cleanedItems.length) {
    const item = cleanedItems[index];
    const isBr = item.character === BR_ELEMENT;
    // Case 1: Non Br element
    if (!isBr) {
      index += 1;
      // eslint-disable-next-line no-continue
      continue;
    }

    // Case 2: Empty line
    const isEmptyLine = cleanedItems.length === 1;
    if (isEmptyLine) {
      // Keep only the initial line tag (this cleans out any unnecessary styling)
      cleanedItems = replace({ ...item, tags: [item.tags[0] || defaultDiv] }, index, cleanedItems);
      index += 1;
      // eslint-disable-next-line no-continue
      continue;
    }

    const nextItem = cleanedItems[index + 1];

    // Case 3: Br at the end of a line (aka, a dangling Br)
    if (!nextItem) {
      cleanedItems = cleanedItems.slice(0, index);
      // eslint-disable-next-line no-continue
      continue;
    }

    // Case 4: Next item exists (non-dangling Br)
    // This splits the current line in two (removing the br), and restarts
    // the loop at the beggining of the new "next" line
    let previousItems = cleanedItems.slice(0, index);

    // Case 4a: No previous items means there's a Br at the start of a
    // non-empty line (ie. <div><br>a..</div>). The current item is added
    // back in to retain the blank line (similar to case 2).
    if (!previousItems.length) {
      // Keep only the initial line tag (this cleans out any unnecessary styling)
      previousItems = [{ ...item, tags: [item.tags[0] || defaultDiv] }];
    }

    // Case 4b
    cleanedItemLines.push(previousItems);
    cleanedItems = cleanedItems.slice(index + 1);
    splitCount += 1;

    const newLineIdSuffix = `_s_${splitCount}`;
    cleanedItems = cleanedItems.map((cleanedItem) => {
      const initialTag = cleanedItem.tags[0] || defaultDiv;

      if (initialTag.type === CharacterItemTagType.KEYWORD) {
        return cleanedItem;
      }
      return {
        ...cleanedItem,
        tags: [
          {
            ...initialTag,
            id: `${initialTag.id}${newLineIdSuffix}`,
          },
          ...cleanedItem.tags.slice(1),
        ],
      };
    });
    index = 0;
  }

  cleanedItemLines.push(cleanedItems);
  return cleanedItemLines;
};

/**
 * Splits a single array of CharacterItem up into multiple arrays
 * based on which line/div they're in.
 * ie, <div>ab</div><div>cd</div> => [a_1, b_1, c_2, d_2] => [[a, b], [c, d]]
 * Note: Assumes all text is wrapped in divs per line
 */
const splitCharacterItemsByLine = (items: CharacterItem[]): CharacterItem[][] => {
  const splitItems: CharacterItem[][] = [];
  let currentTagId = '';

  items.forEach((item) => {
    const firstTag = item.tags[0];
    const addToExistingLine =
      !firstTag || firstTag.type !== CharacterItemTagType.TAG || firstTag.id === currentTagId;

    if (addToExistingLine) {
      if (!splitItems[splitItems.length - 1]) splitItems.push([]);
      splitItems[splitItems.length - 1].push(item);
    } else {
      currentTagId = firstTag.id;
      splitItems.push([item]);
    }
  });

  return splitItems;
};

const emptyLine = /<div><br><\/div>/g;

/**
 * Sanitizes <br> tags in the following ways:
 * - Empty line <br>s are reduce down to the standard <div><br></div>
 * - Splits any <br>s in the middle of lines into separate
 * - Removes any dangling <br>s that may have piled up near the end of a line.
 *     (This can happen with CKE in IE when a line is rolled up into another by deleting
 *     at the start of the line)
 */
export const sanitizeBrs = (html: HtmlString, items: CharacterItem[]): CharacterItem[] => {
  // Sanitizing <br>s is an expensive process, so return early if there's
  // no <br>s outside of empty lines
  const htmlWithoutEmptyBrs = html.replace(emptyLine, '');
  if (!htmlWithoutEmptyBrs.includes('<br>')) {
    return items;
  }

  // It's easier to detect dangling Brs when the items are split up by line
  const splitItems = splitCharacterItemsByLine(items);
  const cleanedSplitItems = splitItems.map(sanitizeBrsInLine);
  const cleanedItems = _.flatten(_.flatten(cleanedSplitItems));
  return cleanedItems;
};
