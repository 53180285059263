import { FC } from 'react';

import { Popover, IconButton } from '~/app/reuse';
import SelectedSVG from '~/app/images/selected.svg';
import UnselectedSVG from '~/app/images/unselected.svg';
import '~/app/styles/ComplaintTableMenu.scss';

export interface MenuOption {
  show: boolean;
  formattedLabel: string;
  label: string;
}

interface ComplaintTableMenuProps {
  sortingFunc: (a: string, b: string) => number;
  options: Record<string, MenuOption>;
  menuOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
  toggleOption: (key: string) => void;
}

export const ComplaintTableMenu: FC<ComplaintTableMenuProps> = (props) => {
  const { sortingFunc, options, menuOpen, setMenuOpen, toggleOption } = props;

  const popoverContent = (
    <div className="ComplaintTableMenu" data-cy-complaint-table-menu>
      {Object.keys(options)
        .sort(sortingFunc)
        .map((optionKey) => (
          <div
            key={optionKey}
            className="option"
            onClick={(e) => {
              e.stopPropagation();
              toggleOption(optionKey);
            }}
          >
            {options[optionKey].show ? (
              <SelectedSVG alt={`Toggle ${optionKey}`} />
            ) : (
              <UnselectedSVG alt={`Toggle ${optionKey}`} />
            )}
            <div>{options[optionKey].formattedLabel || options[optionKey].label || optionKey}</div>
          </div>
        ))}
    </div>
  );

  return (
    <Popover
      className="complaintTablePopoverContainer"
      content={popoverContent}
      isVisible={menuOpen}
      onOutsideClick={() => setMenuOpen(false)}
      placement="top"
    >
      <IconButton
        color="tertiary-grey"
        iconName="ellipsisVertical"
        onClick={() => setMenuOpen(!menuOpen)}
        size="mini"
      />
    </Popover>
  );
};
