import { EMPTY_LINE_BR } from '~/app/constants';

export const replaceDivBlocksWithBr = (html: HtmlString): HtmlString =>
  html
    // Convert all empty lines to a single <br>
    .replace(/<div><br><\/div>/gi, EMPTY_LINE_BR)
    // For each line, remove the opening div tag
    .replace(/<div>/gi, '')
    // For each line, replace the closing div tag with a <br>
    .replace(/<\/div>/gi, EMPTY_LINE_BR)
    // Remove the final <br> as it's not necessary and may add unwanted
    // empty lines at the end of the copied note
    .replace(/[.\s]*(<br>)$/, '') as HtmlString;
