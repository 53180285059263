import {
  ChangeEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from '../../../../reuse';
import { track } from '../../../../analytics';
import { client } from '../../../../api';
import { getOAuthSubject } from '../../../../cookies';
import {
  buttonContainerStyle,
  buttonContainerVerticalStyle,
  innerInnerStyle,
  innerStyle,
  shadowStyle,
  style,
  submitButtonStyle,
  textareaStyle,
  textareaVerticalStyle,
  verticalStyle,
} from './style';
import { useMeshedContext } from '../../MeshedProvider';
import { useDetailsContext } from '../../detailsProvider';

const submitFormWithoutNavigatingAway = (form: HTMLFormElement) => {
  // NOTE: Calling form.submit() will navigate to a different url. The below
  //  method is the safest way that is compatible with IE11
  const submitButton = document.createElement('input');
  submitButton.type = 'submit';
  form.appendChild(submitButton);
  submitButton.click();
  form.removeChild(submitButton);
};

const onEnterSubmit = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();

    const { form } = e.currentTarget;
    if (form) submitFormWithoutNavigatingAway(form);
  }
};

const sendUserPromptRecord = ({
  chatId,
  text,
  index,
}: {
  chatId: string;
  text: string;
  index: number;
}) => {
  client.POST('/openapi/storeMessage', {
    body: {
      patientId: window.regardPatientId ?? '',
      encounterId: window.regardEncounterId ?? '',
      chatId,
      index,
      oauthSubject: getOAuthSubject(),
      content: text,
      source: 'user',
      fields: {},
      timestamp: Date.now(),
    },
  });
};

export const MaxChatForm: FC = () => {
  const { isVerticalLayout } = useMeshedContext(({ isVerticalLayout }) => ({ isVerticalLayout }));

  const { chatCheckingForAiResponse, chatId, chatRepliesLength, prompt } = useDetailsContext(
    ({ chatCheckingForAiResponse, chatId, chatRepliesLength, prompt }) => ({
      chatCheckingForAiResponse,
      chatId,
      chatRepliesLength,
      prompt,
    })
  );

  const [key, setKey] = useState(0);
  const [typedText, setTypedText] = useState('');
  const [disabled, setDisabled] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const type = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setTypedText(e.target.value);
    },
    [setTypedText]
  );

  const reset = useCallback(() => {
    setTypedText('');
    setKey((key) => key + 1);

    // Without a timeout, the textarea will not focus
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 0);
  }, [setKey, textareaRef]);

  const submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!disabled && !chatCheckingForAiResponse) {
        setDisabled(true);
        track.chatUserPrompted({
          chatId,
          promptIndex: chatRepliesLength,
        });
        prompt({ text: typedText, onRestart: () => setTypedText(typedText) });
        reset();
      }
      sendUserPromptRecord({ chatId, text: typedText, index: chatRepliesLength });
    },
    [
      chatCheckingForAiResponse,
      chatId,
      chatRepliesLength,
      disabled,
      prompt,
      reset,
      setDisabled,
      typedText,
    ]
  );

  // After the AI completes it's response
  useEffect(() => {
    if (!chatCheckingForAiResponse) {
      setDisabled(false);
    }
  }, [chatCheckingForAiResponse, reset, setDisabled]);

  return (
    <div style={isVerticalLayout ? verticalStyle : style}>
      <div style={shadowStyle} />
      <div style={innerStyle}>
        <div style={innerInnerStyle}>
          <form data-cy-chat-form onSubmit={submit}>
            <textarea
              key={key}
              ref={textareaRef}
              onChange={type}
              onKeyDown={onEnterSubmit}
              placeholder="type your query here..."
              style={isVerticalLayout ? textareaVerticalStyle : textareaStyle}
              value={typedText}
            />
            <div style={isVerticalLayout ? buttonContainerVerticalStyle : buttonContainerStyle}>
              <Button
                color="primary"
                disabled={disabled}
                size="standard"
                style={submitButtonStyle}
                submit
              >
                {/* Don't change this text without adjusting the `xxxVerticalStyle`s */}
                Send
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
