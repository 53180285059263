export const REQUEST_NOTE_INTERVAL = 500; // Check every 0.5 seconds if patient has loaded
export const REQUEST_NOTE_RETRIES = (4 * 60 * 1000) / REQUEST_NOTE_INTERVAL; // 4min fetch-timeout

export const NEW_DIFF = 'NEW';
export const EXISTING_DIFF = 'EXISTING';
export const UPDATED_DIFF = 'UPDATED';
export const OUTDATED_DIFF = 'OUTDATED';

export const PATIENT_TIMESTAMP = 'patient';
export const CURRENT_TIMESTAMP = 'current';

export const KEY_CODE_X = 'X'.charCodeAt(0);
export const KEY_CODE_Y = 'Y'.charCodeAt(0);
export const KEY_CODE_Z = 'Z'.charCodeAt(0);
export const KEY_CODE_C = 'C'.charCodeAt(0);
export const KEY_CODE_B = 'B'.charCodeAt(0);

export const KEY_CODE_1 = '1'.charCodeAt(0);
export const KEY_CODE_2 = '2'.charCodeAt(0);
export const KEY_CODE_3 = '3'.charCodeAt(0);
export const KEY_CODE_4 = '4'.charCodeAt(0);

export const KEY_CODE_BACKSPACE = 8;
export const KEY_CODE_CMD_BACKSPACE = 1114120;
export const KEY_CODE_DELETE = 46;

// Error messages
export const PT_BELOW_MIN_AGE_MESSAGE = 'patient must be eighteen or older';
export const RECEIVE_REGARD_NOTE_FAILURE_STATUS =
  'Unable to fetch patient note due to exceeding maximum retries.';

// Notification levels
export enum NotificationLevel {
  New = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  Critical = 4,
}
export const levelToClass = ['new', '', 'warn', 'error', 'critical'];
export const levelToDesc = ['New/Updated', 'Info', 'Warning', 'Error', 'Critical'];

// Url parameters to carry forward into a login redirect url
export const REDIRECT_PARAMETERS: [string, boolean][] = [
  ['review', false],
  ['test_create', true],
  ['test_check', false],
];

// GET request parameter for timestamp which the data should be no older than
export const CONDITIONS_PARAM = 'conditions';
export const ENCOUNTER_PARAM = 'enc';
export const PATIENT_PARAM = 'pt';
export const FORCE_FETCH_PARAM = 'force_fetch';
export const RECENCY_THRESHOLD_PARAM = 'recency-threshold';
export const REVIEW_PARAM = 'review';
export const TIMESTAMP_PARAM = 'timestamp';

export const COMPANY_NAME = 'Regard';
export const COMPANY_SUPPORT_EMAIL = 'support@withregard.com';
export const COMPANY_WEBSITE = 'https://withregard.com/';

// HTML
const DIV_TAG = 'div';
const BR_TAG = 'br';
export const NBSP = '&nbsp;';
export const BR_ELEMENT = `<${BR_TAG}>` as HtmlString;

export const STYLE_BOLD_TAG = 'strong';
export const STYLE_ITALIC_TAG = 'em';
export const STYLE_UNDERLINE_TAG = 'u';
export const EXEC_COMMAND_BOLD = 'bold';
export const EXEC_COMMAND_ITALIC = 'italic';
export const EXEC_COMMAND_UNDERLINE = 'underline';

export const LINE_DIVIDER_TAG_UPPER_CASE = DIV_TAG.toUpperCase();
export const LINE_DIVIDER_TAG = DIV_TAG;
export const EMPTY_LINE_BR_TAG = BR_TAG;
export const EMPTY_LINE_BR_TAG_UPPER_CASE = BR_TAG.toUpperCase();
export const EMPTY_LINE_BR = BR_ELEMENT;
export const EMPTY_LINE_NBSP = NBSP;

export const EMPTY_BR_HTML_LINE: HtmlString =
  `<${LINE_DIVIDER_TAG}>${EMPTY_LINE_BR}</${LINE_DIVIDER_TAG}>` as HtmlString;
export const EMPTY_NBSP_HTML_LINE: HtmlString =
  `<${LINE_DIVIDER_TAG}>${EMPTY_LINE_NBSP}</${LINE_DIVIDER_TAG}>` as HtmlString;
export const EMPTY_EMPTY_HTML_LINE = `<${LINE_DIVIDER_TAG}></${LINE_DIVIDER_TAG}>` as HtmlString;

export const SPACE_CODE = 32;
export const OPEN_ANGLE_CODE = 60;
export const NBSP_CODE = 160;
export const NBSP_SINGLE = String.fromCharCode(NBSP_CODE);
