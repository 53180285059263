import * as Sentry from '@sentry/browser';

import {
  BulletNoteStatusRecord,
  ConditionNoteBlock,
  IndexedConditionNoteBlock,
  IndexedNoteBlock,
  IndexingData,
  NoteBlock,
} from '~/app/@types/state';

import { EMPTY_BR_HTML_LINE } from '~/app/constants';
import { isConditionNoteBlock, uniqueId } from '~/app/utils';
import { trackBulletNoteStatusUpdates } from '../bulletNoteStatus';

import { retainStaleNoteBlockData } from './retainStaleNoteBlockData';
import { EditedCase, getEditedCase, UserAction } from './getEditedCase';
import { preModuleNoteBlocksToNoteBlocks } from './preModuleNoteBlocksToNoteBlocks';

declare global {
  interface Window {
    previousUserAction?: UserAction;
    reindexing?: boolean;
    lastEditedCase?: string;
  }
}

interface IndexHtmlNoteParams {
  staleBulletNoteStatus: BulletNoteStatusRecord;
  staleIndexingData: IndexingData;
  userAction: UserAction;
}

interface IndexHtmlNoteResults {
  bulletNoteStatus: BulletNoteStatusRecord;
  conditionsById: Record<string, IndexedConditionNoteBlock>;
  indexingData: IndexingData;
  newDxIndicatorLineHidden: boolean;
  noteBlocks: NoteBlock[];
  noteBlocksById: Record<string, IndexedNoteBlock>;
}

export const getEmptyNoteBlock = (): ConditionNoteBlock => ({
  bulletsByTrimmedTextKey: {},
  diff: null,
  id: uniqueId(),
  isNew: false,
  keywords: [],
  modules: [],
  lines: [
    {
      html: EMPTY_BR_HTML_LINE,
      key: 'freetext-0',
      status: 'none',
      type: 'freetext',
    },
  ],
  suggestions: [],
  text: '',
  type: 'condition',
});

const getConditionsAndNoteBlocksByIdFromNoteBlocks = (
  noteBlocks: NoteBlock[]
): {
  conditionsById: Record<string, IndexedConditionNoteBlock>;
  noteBlocksById: Record<string, IndexedNoteBlock>;
} => {
  const conditionsById: Record<string, IndexedConditionNoteBlock> = {};
  const noteBlocksById: Record<string, IndexedNoteBlock> = {};

  noteBlocks.forEach((noteBlock, i) => {
    if (isConditionNoteBlock(noteBlock)) {
      // Some choices were made here for speed over safety (immutability).
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      conditionsById[noteBlock.id] = noteBlock;
    }

    // Index gets added next
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    noteBlocksById[noteBlock.id] = noteBlock;

    // NOTE:
    // 1. This operation will modify the original object.
    // 2. A changing index will not reset the condition's object reference.
    noteBlocksById[noteBlock.id].index = i;
  });

  return { conditionsById, noteBlocksById };
};

/*
 * Injest `chunks` (an array of html strings) and transform them into
 *  `noteBlocks` (an array of objects that can be rendered by the Note
 *  component.
 *
 * Depending on what kind of user-edit that produced the new list of `chunks`,
 *  different optimization paths are taken to shortcut processing.
 *
 * NOTE: Please perform all side-effects in this function in this file
 *  (and not in some nested function.)
 * */
export const indexHtmlNote = ({
  staleBulletNoteStatus,
  staleIndexingData,
  userAction,
}: IndexHtmlNoteParams): IndexHtmlNoteResults => {
  // 0. Set fallbacks for these values; sometimes the fallback is the ideal
  //  result and other times the fallback is the only thing we have after
  //  encountering an error during reindexing
  let noteBlocksWithFooter: NoteBlock[] =
    'staleNoteBlocks' in userAction ? userAction.staleNoteBlocks : [];
  let bulletNoteStatus = staleBulletNoteStatus;
  let indexingData = staleIndexingData;
  let newDxIndicatorLineHidden = false;

  try {
    window.reindexing = true;

    // 1. Determine the type of user edit we're going to proccess; we can optimize
    //  processing for different types of edits
    const editedCaseResult = getEditedCase({
      userAction,
    });

    // 1.b. If a title was edited or split, the position of the New Dx Line may
    //  no longer be correct; so we hide it.
    if (
      editedCaseResult.editedCase === EditedCase.DifferentTitle ||
      editedCaseResult.editedCase === EditedCase.SplitIntoMultipleChunks
    ) {
      newDxIndicatorLineHidden = true;
    }

    // 1.c. If a condition is moved, determine if the condition is moved in or
    //  out of the section of new conditions at the bottom of the note
    if (userAction.type === EditedCase.Move) {
      const firstNewConditionIndex = userAction.staleNoteBlocks.findIndex(
        (noteBlock) => 'isNew' in noteBlock && noteBlock.isNew
      );
      newDxIndicatorLineHidden =
        userAction.fromIndex >= firstNewConditionIndex ||
        userAction.toIndex >= firstNewConditionIndex;
    }

    // 2. Based on the case from the previous step, keep appropriate data from the
    //  stale condition areas
    const retainStaleNoteBlockDataResult = retainStaleNoteBlockData({
      editedCaseResult,
    });

    // 3. Get `noteBlocks`
    const { noteBlocks, bulletNoteStatusUpdates, newBulletNoteStatus, newIndexingData } =
      preModuleNoteBlocksToNoteBlocks({
        retainStaleNoteBlockDataResult,
        staleBulletNoteStatus,
        staleIndexingData,
      });
    if (newBulletNoteStatus) bulletNoteStatus = newBulletNoteStatus;
    if (newIndexingData) indexingData = newIndexingData;

    // 4. SIDE-EFFECT: Track bulletNoteStatus updates
    if (userAction.type === 'typing' && bulletNoteStatusUpdates) {
      trackBulletNoteStatusUpdates(bulletNoteStatusUpdates, staleBulletNoteStatus);
    }

    // 4.b Set global variable for most recent editedCase. This is picked up by
    //  track.measuredRenderDuration to send along with render time analytics
    window.lastEditedCase = editedCaseResult.editedCase;

    // 5. Add footer
    //
    // NOTE: This should be done last because it modifies the length of the
    //  condition areas, and that could confuse the setCaretPosition logic.
    //
    //  Make sure there is at least one condition note block at the top
    noteBlocksWithFooter =
      noteBlocks.length && noteBlocks[0].type === 'condition'
        ? noteBlocks
        : [getEmptyNoteBlock(), ...noteBlocks];
  } catch (e) {
    console.warn(e);
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtra('currentUserAction', userAction);
      scope.setExtra('previousUserAction', window.previousUserAction);
      Sentry.captureException(e as Error);
    });
  } finally {
    window.reindexing = false;
  }

  // 6. Make a note of the last successful user action; this will help in
  //  debugging errors.
  window.previousUserAction = userAction;

  const noteBlocks = noteBlocksWithFooter;

  const { conditionsById, noteBlocksById } =
    getConditionsAndNoteBlocksByIdFromNoteBlocks(noteBlocks);

  return {
    bulletNoteStatus,
    conditionsById,
    indexingData,
    newDxIndicatorLineHidden,
    noteBlocks,
    noteBlocksById,
  };
};
