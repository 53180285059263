import { SummaryP, SummarySpanB } from './common';

export const sepsisSummary = (
  <>
    <SummaryP first>
      Regard will first diagnose sepsis by looking at the patient’s notes and problem list from the
      current encounter for the condition including mentions of “severe sepsis” or “septic shock”.
      Regard will display relevant scores, medications, and labs.
    </SummaryP>
    <SummaryP>
      If sepsis has not been previously identified in notes or problem list, Regard searches for two
      or more <SummarySpanB>SIRS</SummarySpanB> criteria: temperature &gt;38 or &lt;36 &#8451;,
      heart rate &gt; 90/bpm, respiratory rate &gt; 20 rpm or PaCO2 &lt;32mmHg, bands &gt; 10% or
      WBC &gt;12 or &lt;4x10&#179;/uL in the last 12 hours.
    </SummaryP>
    <SummaryP>
      Regard will positively identify <SummarySpanB>sepsis</SummarySpanB> if SIRS is positive along
      with a suspected source of infection or active IV antibiotics ordered.The algorithm will
      automatically display sepsis if one or more the following supported diagnoses are identified:
      pneumonia, cellulitis, and UTI (more Dxs in development!).
    </SummaryP>
    <SummaryP>
      If Regard detects a change in the SOFA score of 2 or more in a 24-hour period of time it will
      designate the diagnosis with{' '}
      <SummarySpanB>Multiple Organ Dysfunction Syndrome (MODS)</SummarySpanB>.
    </SummaryP>
    <SummaryP>
      <SummarySpanB>Septic Shock</SummarySpanB> is positive when SIRS is confirmed along with a
      prescribed vasopressor or inotrope, and a lactate &ge; 2 mmol/L within the encounter.
    </SummaryP>
  </>
);
