import {
  htmlLinesToHtmlString,
  htmlLineToCkeditorHtmlLine,
  htmlStringToHtmlLines,
  isIE,
  removeRedundantTags,
} from '~/app/utils';

export const processRawHtml = (rawHtml: HtmlString): HtmlString => {
  // 1. Remove redundant tags and invalid characters, like zero-width space
  // This also
  // - removes unnecessary Brs to reduce onChange jitter that
  //   comes from CKE inserting BRs in random places for formatting.
  //   We still want to store the extra BRs onChange, however, so that the overlay
  //   formatting will still match what's in CKE
  // - removes empty Divs. CKE will retain empty divs during actions, like "cut"ing
  //   multiple lines, that are unnecessary and can cause the underlay to render
  //   incorrectly if sanitized to empty `<div><br></div>` lines.
  const cleanedHtml = removeRedundantTags(rawHtml as HtmlString, { clearEmptyDivs: true });

  // 2. CKEditor in IE does not properly add non-breaking spaces, so
  //  do that now, otherwise when it comes back around to render again
  //  we'll lose those spaces
  const htmlFromDom = isIE()
    ? htmlLinesToHtmlString(htmlStringToHtmlLines(cleanedHtml).map(htmlLineToCkeditorHtmlLine))
    : cleanedHtml;

  return htmlFromDom;
};
