import { FC, memo, MouseEvent } from 'react';

import { Button } from '../../../reuse';

const style = {
  float: 'right',
} as const;

type CopyButtonProps = {
  copied: boolean;
  disabled?: boolean;
  onClick(e: MouseEvent<HTMLButtonElement>): void;
};

export const CopyButton: FC<CopyButtonProps> = memo(({ copied, disabled, onClick }) => (
  <Button
    color="primary"
    data-cy-copy-note-button
    disabled={disabled}
    onClick={onClick}
    size="standard"
    startIconName="copy"
    style={style}
  >
    {copied ? 'Copied!' : 'Copy Note'}
  </Button>
));
CopyButton.displayName = 'CopyButton';
