import _ from 'lodash';
import { Stats } from '../@types/state';

// Helper function for sending stats to Amplitude
export type SimpleStats = {
  matchedConditions: string[];
  newConditions: string[];
  matchedConditionCodes: string[];
  newConditionCodes: string[];
  numNewConditions: number;
  numUnrecognizedConditions: number;
  numUpdatedConditions: number;
};
export const getSimpleStats = (
  stats: Stats,
  conditionNameToICDCodesMap: Record<string, string[]>
): SimpleStats => ({
  matchedConditions: stats.matchedConditions.filter((c) => c),
  newConditions: stats.newConditions,
  matchedConditionCodes: _.flatten(
    stats.matchedConditions.map((matchedCondition) => conditionNameToICDCodesMap[matchedCondition])
  ),
  newConditionCodes: _.flatten(
    stats.newConditions.map((matchedCondition) => conditionNameToICDCodesMap[matchedCondition])
  ),
  numNewConditions: stats.newConditions ? stats.newConditions.length : 0,
  numUnrecognizedConditions: stats.missedConditions.length,
  numUpdatedConditions: stats.matchedConditions ? stats.matchedConditions.length : 0,
});

export const getCopyStats = (
  copiedConditions: string[],
  stats = window.store.getState().regardNote.stats,
  negativeConditions: string[] = window.store
    .getState()
    .regardNote.currentConditionResults.filter((c) => c.negative)
    .map((c) => c.module),
  conditionNameToICDCodesMap: Record<string, string[]> = window.store.getState().regardNote
    .indexingData.conditionNameToICDCodesMap
): {
  codes: string[];
  copiedNewCodes: string[];
  copiedMatchedCodes: string[];
  copiedConditions: string[];
  copiedNewConditions: string[];
  copiedMatchedConditions: string[];
  copiedLiveDxedConditions: string[];
  dismissedNewConditions: string[];
  dismissedNewCodes: string[];
} => {
  if (stats) {
    const codes = _.flatten(copiedConditions.map((c) => conditionNameToICDCodesMap[c]));

    const copiedMatchedConditions = stats.matchedConditions.filter(
      (c) => copiedConditions.includes(c) && !negativeConditions.includes(c)
    );
    const copiedMatchedCodes = _.flatten(
      copiedMatchedConditions.map((c) => conditionNameToICDCodesMap[c])
    );

    const copiedNewConditions = stats.newConditions.filter((c) => copiedConditions.includes(c));
    const copiedNewCodes = _.flatten(copiedNewConditions.map((c) => conditionNameToICDCodesMap[c]));

    const copiedLiveDxedConditions = negativeConditions.filter((c) => copiedConditions.includes(c));

    const dismissedNewConditions = stats.newConditions.filter((c) => !copiedConditions.includes(c));
    const dismissedNewCodes = _.flatten(
      dismissedNewConditions.map((c) => conditionNameToICDCodesMap[c])
    );

    return {
      codes,
      copiedConditions,
      copiedMatchedCodes,
      copiedMatchedConditions,
      copiedNewCodes,
      copiedNewConditions,
      copiedLiveDxedConditions,
      dismissedNewConditions,
      dismissedNewCodes,
    };
  }

  return {
    codes: [],
    copiedNewCodes: [],
    copiedMatchedCodes: [],
    copiedConditions: [],
    copiedNewConditions: [],
    copiedMatchedConditions: [],
    copiedLiveDxedConditions: [],
    dismissedNewConditions: [],
    dismissedNewCodes: [],
  };
};
