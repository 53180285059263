import { useCallback } from 'react';
import {
  htmlConvertLinesToListOnCopy,
  htmlUseBrLineFormatOnCopy,
  htmlWrapWithFontOnCopy,
} from '~/app/flags';
import { copyHtmlToClipboard, createFullNote, useTemporarilyTrueBoolean } from '~/app/utils';

export const useCopyNoteBlock = (
  noteBlockId: string
): {
  copyNoteBlock: () => void;
  isCopied: boolean;
} => {
  const [isCopied, { setTemporarilyTrue: setIsCopied }] = useTemporarilyTrueBoolean(2000);

  const copyNoteBlock = useCallback(() => {
    const editorText = createFullNote({
      noteBlockId,
      convertLinesToListOnCopy: htmlConvertLinesToListOnCopy,
      includePhysicalExam: false,
      useBrLineFormat: htmlUseBrLineFormatOnCopy,
      usePlaintext: false,
      wrapWithFontTag: htmlWrapWithFontOnCopy,
    });

    copyHtmlToClipboard(editorText as HtmlString);

    setIsCopied();
  }, [noteBlockId, setIsCopied]);

  return { copyNoteBlock, isCopied };
};
