import { HtmlTree, HtmlTreeNodeType } from './types';

export function htmlTreeToString(htmlTree: HtmlTree): HtmlString {
  return htmlTree
    .map((node) => {
      if (node.type === HtmlTreeNodeType.TEXT) {
        return 'originalText' in node ? node.originalText : node.text;
      }
      if (node.type === HtmlTreeNodeType.TAG) {
        return `<${node.tag}>${node.children ? htmlTreeToString(node.children) : ''}</${node.tag}>`;
      }

      console.warn('htmlTreeToString: Unsupported node type ', node.type);
      return '';
    })
    .join('') as HtmlString;
}
