import { FC } from 'react';

import { getTextareaCss } from './css';
import { containerStyle, getLabelStyle, getSubLabelStyle } from '../labeledInput/css';
import { useLabeledInput } from '../labeledInput/useLabeledInput';

export type LabeledTextareaProps = {
  error?: string;
  label: string;
  required?: boolean;
  subLabel?: string;
} & AugmentedRequired<
  Pick<JSX.IntrinsicElements['textarea'], 'disabled' | 'onChange' | 'rows' | 'style' | 'value'>,
  'onChange' | 'value'
>;

const notFilledStyle = {
  top: 10,
};

export const LabeledTextarea: FC<LabeledTextareaProps> = ({
  error,
  disabled,
  label,
  onChange,
  required,
  rows,
  style,
  subLabel,
  value,
}) => {
  const { focus, forceBlur, forceFocus, isFilled, isFocused, ref } =
    useLabeledInput<HTMLTextAreaElement>({ value });

  const finalSubLabel = error || subLabel;

  return (
    <div style={style}>
      <div onClick={focus} style={containerStyle}>
        <span
          onClick={focus}
          style={getLabelStyle({
            error,
            isFilled,
            isFocused,
            notFilledStyle,
          })}
        >
          {label}
          {required ? '*' : ''}
        </span>
        <textarea
          ref={ref}
          css={getTextareaCss({ error, isFocused })}
          disabled={disabled}
          onBlur={forceBlur}
          onChange={onChange}
          onFocus={forceFocus}
          rows={rows}
          value={value}
        />
      </div>
      {finalSubLabel ? <span style={getSubLabelStyle({ error })}>{finalSubLabel}</span> : null}
    </div>
  );
};
LabeledTextarea.displayName = 'LabeledTextarea';
