import _ from 'lodash';
import { CSSProperties } from 'react';

import { ThemeColor, theme } from '../theme';

const getLabelColor = ({
  error,
  isFocused,
}: {
  error: boolean | string | undefined;
  isFocused: boolean;
}): ThemeColor => {
  if (error) return theme.colors.red;
  if (isFocused) return theme.colors.blueRegular;
  return theme.colors.grey2;
};

export const containerStyle = {
  position: 'relative',
} as const;

export const getLabelStyle = _.memoize(
  ({
    error,
    notFilledStyle,
    isFilled,
    isFocused,
  }: {
    error: boolean | string | undefined;
    notFilledStyle?: CSSProperties;
    isFilled: boolean;
    isFocused: boolean;
  }) =>
    ({
      backgroundColor: theme.colors.none,
      color: getLabelColor({ error, isFocused }),
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: 14,
      left: 12,
      lineHeight: '20px',
      position: 'absolute',
      top: 13,
      transition: 'all 100ms ease',
      width: 'calc(100% - 36px)',
      ...(isFilled ? { fontSize: 12, top: 1 } : notFilledStyle),
    } as const)
);

const lineHeight = 20;
const paddingTop = 18;
const paddingBottom = 8;
const paddingX = 12;
const height = lineHeight + paddingTop + paddingBottom;

export const getInputBaseCss = ({ error }: { error: boolean | string | undefined }) =>
  ({
    background: 'none',
    borderRadius: theme.other.borderRadius4,
    borderColor: error ? theme.colors.red : theme.colors.grey3,
    borderStyle: 'solid',
    color: theme.colors.grey1,
    display: 'block',
    lineHeight: `${lineHeight}px`,
    overflow: 'hidden',
    resize: 'none',
    width: '100%',
    '&:focus': error ? undefined : { borderColor: theme.colors.blueRegular }, // blue border is only for actual focus
    '&:focus-visible': { outline: 0 }, // this is a new sort of focus that happens only when keyboard tabbing; we don't need it because we have a clear :focus style
    '&::-ms-clear': { display: 'none' },
  } as const);

export const getInputCss = _.memoize(
  ({ error, isFocused }: { error: boolean | string | undefined; isFocused: boolean }) =>
    ({
      ...getInputBaseCss({ error }),
      borderWidth: isFocused ? 2 : 1,
      height, // We have to force this, since IE10 renders at a different height than the line height
      padding: isFocused
        ? `${paddingTop - 1}px ${paddingX - 1}px ${paddingBottom - 1}px`
        : `${paddingTop}px ${paddingX}px ${paddingBottom}px`,
    } as const)
);

export const getSubLabelStyle = _.memoize(
  ({ error }: { error: boolean | string | undefined }) =>
    ({
      color: error ? theme.colors.red : theme.colors.grey2,
      display: 'block',
      fontSize: 12,
      lineHeight: 1.25,
      marginLeft: 12,
    } as const)
);
