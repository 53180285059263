import { ThemeProvider } from '@emotion/react';
import { FC } from 'react';
import { Provider } from 'react-redux';

import { FlagProvider } from '~/app/flags';
import { Normalize, theme } from '~/app/reuse';
import { store } from '~/app/store';

export const AppProvider: FC = ({ children }) => (
  <Provider store={store}>
    <FlagProvider>
      <ThemeProvider theme={theme}>
        <Normalize />
        {children}
      </ThemeProvider>
    </FlagProvider>
  </Provider>
);
