import _ from 'lodash';
import { FC } from 'react';

import { track } from '~/app/analytics';
import ChatBubbleSVG from '~/app/images/chat-bubble.svg';
import QuestionCirlceSVG from '~/app/images/question-circle.svg';
import { COMPANY_NAME } from '~/app/constants';
import { theme, zIndices } from '~/app/reuse';

const rootStyle = {
  backgroundColor: theme.colors.grey6,
  border: `1px solid ${theme.colors.grey5}`,
  bottom: 0,
  display: 'flex',
  fontWeight: 600,
  lineHeight: '30px',
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  zIndex: zIndices.tabPanelButtons,
} as const;

const getButtonCss = _.memoize(
  ({
    disableFeedback,
    isFeedbackButton,
  }: {
    disableFeedback: boolean;
    isFeedbackButton: boolean;
  }) => ({
    borderLeft: isFeedbackButton ? `1px solid ${theme.colors.grey5}` : 'none',
    color: theme.colors.grey2,
    cursor: 'pointer',
    width: disableFeedback ? '100%' : '50%',
    '&:hover': {
      backgroundColor: theme.colors.grey5,
      color: theme.colors.grey1,
    },
  })
);

const svgStyle = {
  fill: 'currentColor',
  marginRight: 4,
  position: 'relative',
  top: 3,
} as const;

// TODO: RND-1407 - Turn back on appcues
// const handleStyle = {
//   display: 'inline-block',
//   width: 1,
//   height: '1rem',
// } as const;

export const TabPanelButtons: FC<{
  disableFeedback: boolean;
  openFeedback(): void;
  openResources(): void;
}> = ({ disableFeedback, openFeedback, openResources }) => (
  <div style={rootStyle}>
    <div
      css={getButtonCss({ disableFeedback, isFeedbackButton: false })}
      data-cy-open-resources-panel-button
      onClick={() => {
        track.openedResourcesPanel();
        openResources();
      }}
    >
      <QuestionCirlceSVG alt="resources" style={svgStyle} />
      Help &amp; Resources
      {/* 
        // TODO: RND-1407 - Turn back on appcues
        <span data-appcues-handle-announcement style={handleStyle} />
      */}
    </div>
    {!disableFeedback && (
      <div
        css={getButtonCss({ disableFeedback, isFeedbackButton: true })}
        data-cy-open-user-feedback-button
        onClick={() => {
          track.openedUserFeedbackForm();
          openFeedback();
        }}
      >
        <ChatBubbleSVG alt={`${COMPANY_NAME} Feedback`} style={svgStyle} />
        {COMPANY_NAME} Feedback
      </div>
    )}
  </div>
);
