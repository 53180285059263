import { FC } from 'react';

import { EpicHeader } from './EpicHeader';
import { epicRegardTab } from './epicRegardTab';
import { EpicTab } from './EpicTab';
import { EpicTabs } from './EpicTabs';
import { EpicTable } from './EpicTable';
import { epicTheme } from './epicTheme';
import { DemoGrid } from '../demoGrid';
import { EpicTabNotesContent } from './EpicTabNotesContent';
import { EpicTabImageContent } from './EpicTabImageContent';
import { useDemoData } from '../demoData';
import { formatDatetime } from '../../../utils';

export const EpicCenterPane: FC = () => {
  const medications = useDemoData({ category: 'medications' });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        paddingTop: 16,
      }}
    >
      <EpicTabs>
        <EpicTab accentColor={epicTheme.colors.accentMagenta} isSelected label="Summary">
          <EpicHeader>Summary</EpicHeader>
          <div
            style={{
              backgroundColor: epicTheme.colors.white,
              border: `1px solid ${epicTheme.colors.darkBlue}`,
              fontSize: 18,
              fontWeight: 'bold',
              lineHeight: '20px',
              marginBottom: 32,
              padding: '12px 32px',
            }}
          >
            Lopez, Claudia #112233 (59 y.o. F) (Adm: 5/5/2021)
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'grid', gap: 24, width: '33%' }}>
              <EpicTable
                headerColor={epicTheme.colors.headerLightGreen}
                label="Vital Signs"
                rows={[
                  [null, '12:00'],
                  ['Temp', '98.6'],
                  ['BP', '132/72'],
                  ['Respitory Rate', '35'],
                  ['Oxygen Saturation', '97'],
                  ['Weight', '69.4'],
                  ['Height', '152.4'],
                ]}
              />
              <EpicTable
                headerColor={epicTheme.colors.headerLightBlue}
                label="Problem List"
                rows={[['CKD'], ['Heart Failure'], ['Anemia']]}
              />
              <EpicTable
                headerColor={epicTheme.colors.headerBlueGray}
                label="I/O"
                rows={[['Intake', <b>12:00</b>], ['Output'], ['Net']]}
              />
            </div>
            <div style={{ width: 24 }} />
            <div style={{ width: '67%' }}>
              <EpicTable
                headerColor={epicTheme.colors.headerPurple}
                label="Medications"
                rows={medications.slice(0, 10).map((medication) => {
                  const dose = medication.dose ? `${medication.dose} ` : '';
                  const route = medication.route ? `${medication.route} ` : '';
                  const frequency = medication.frequency ?? '';
                  const orderTime = formatDatetime(medication.orderTime.toISOString());
                  return [
                    `${medication.formulation}\n${dose}${route}${frequency}\nLast Action: Given ${orderTime}`,
                  ];
                })}
              />
            </div>
          </div>
        </EpicTab>
        <EpicTab accentColor={epicTheme.colors.accentYellow} isSelected={false} label="Results">
          <EpicHeader>Results</EpicHeader>
          <div style={{ maxWidth: 800 }}>
            <DemoGrid accentColor={epicTheme.colors.gray} />
          </div>
        </EpicTab>
        <EpicTab
          accentColor={epicTheme.colors.accentGreen}
          isSelected={false}
          label="Notes"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <EpicTabNotesContent />
        </EpicTab>
        <EpicTab accentColor={epicTheme.colors.accentBlue} isSelected={false} label="Orders">
          <EpicHeader>Results</EpicHeader>
          <div style={{ display: 'grid', gap: 24 }}>
            <EpicTable
              headerColor={epicTheme.colors.lightGray}
              label="Scheduled"
              rows={[
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
              ]}
            />
            <EpicTable
              headerColor={epicTheme.colors.lightGray}
              label="Continuous"
              rows={[
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
              ]}
            />
            <EpicTable
              headerColor={epicTheme.colors.lightGray}
              label="PRN"
              rows={[
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
                [
                  `Aspirin 81 mg chewable tablet
  81mg, Oral Daily
  Last Action: Given 81mg at 5/30 2:42`,
                ],
              ]}
            />
          </div>
        </EpicTab>
        <EpicTab
          accentColor={epicTheme.colors.accentPurple}
          isSelected={false}
          label="Images"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <EpicTabImageContent />
        </EpicTab>
        {epicRegardTab}
      </EpicTabs>
    </div>
  );
};
