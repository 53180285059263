import { last } from 'lodash';

import {
  ChunkOfLines,
  ChunkOfLinesWithText,
  MeshedNoteShelfDividerHtmlLine,
} from '~/app/@types/state';
import { EMPTY_BR_HTML_LINE } from '~/app/constants';
import {
  htmlLineToCkeditorHtmlLine,
  getIsEmptyHtmlString,
  htmlStringToHtmlLines,
  htmlStringToPlainText,
  stripShelfSignifiers,
  nth,
  linesToHtmlString,
  linesToPlainText,
  SHELF_SIGNIFIER,
  insert,
  isFooterChunk,
} from '~/app/utils';
import { SHELF_DIVIDER_DEFAULT_TEXT, SHELF_DIVIDER_DEFAULT_LINE } from '~/app/utils/shelf';

import { getNonGlobalLineRegex } from '../../regex';
import { splitFooterFromChunksWithLines } from '../removeFooter';

const getEmptyCondition = ({ type }: { type: 'condition' | 'shelvedCondition' }): ChunkOfLines => ({
  lines: [],
  type,
});

const defaultShelfDividerChunk = {
  lines: [
    {
      html: `<div>${SHELF_DIVIDER_DEFAULT_LINE}</div>` as HtmlString,
      htmlWithoutShelfSignifiers: `<div>${SHELF_DIVIDER_DEFAULT_TEXT}</div>` as HtmlString,
      type: 'shelfDivider' as const,
    },
  ],
  type: 'shelfDivider' as const,
};

export type ParseMode =
  | 'mesh' // called during "meshing", essentially the first pass of processing the note, when we're handling the base note from the API on application load
  | 'index' // called during "indexing", essentially every re-processing of the entire note; typically, this will only happen one time after "meshing"
  | 'single' // called when a user edit, such as "typing", can be isolated to a single condition area
  | 'copy' // called when the users copies the note
  | 'singleAtLastIndex' // same as 'single', but when the edit occurs at the last condition area
  | 'chunkForListConversion' // called during "replaceLineDivsWithLists" to chunk each line for easy html conversion
  | 'singleBlockDoubleNewline'; // experimental mode that splits on a double newline
type Options = {
  convertHtmlForCkeditor: boolean;
  splitFooterInto: 'separateChunk' | 'separateChunkAtLastIndex' | 'none';
  addShelfDividerIfNone: boolean;
  removeUpToOneTrailingNewlineFromAllButLastChunk: boolean;
};

type CaretPosition = { noteBlockIndex: number; caretPos: number };

const optionsByParseMode: Record<ParseMode, Options> = {
  chunkForListConversion: {
    convertHtmlForCkeditor: true,
    splitFooterInto: 'separateChunk',
    addShelfDividerIfNone: false,
    removeUpToOneTrailingNewlineFromAllButLastChunk: true,
  },
  copy: {
    convertHtmlForCkeditor: false,
    splitFooterInto: 'none', // will not add a footer chunk
    addShelfDividerIfNone: false,
    removeUpToOneTrailingNewlineFromAllButLastChunk: true,
  },
  index: {
    convertHtmlForCkeditor: true,
    splitFooterInto: 'separateChunk',
    addShelfDividerIfNone: true,
    removeUpToOneTrailingNewlineFromAllButLastChunk: true,
  },
  mesh: {
    convertHtmlForCkeditor: false,
    splitFooterInto: 'separateChunk',
    addShelfDividerIfNone: true,
    removeUpToOneTrailingNewlineFromAllButLastChunk: true,
  },
  single: {
    convertHtmlForCkeditor: true,
    splitFooterInto: 'none', // will not add a footer chunk
    addShelfDividerIfNone: false,
    removeUpToOneTrailingNewlineFromAllButLastChunk: false,
  },
  singleAtLastIndex: {
    convertHtmlForCkeditor: true,
    splitFooterInto: 'separateChunkAtLastIndex',
    addShelfDividerIfNone: false,
    removeUpToOneTrailingNewlineFromAllButLastChunk: false,
  },
  singleBlockDoubleNewline: {
    convertHtmlForCkeditor: true,
    splitFooterInto: 'none',
    addShelfDividerIfNone: false,
    removeUpToOneTrailingNewlineFromAllButLastChunk: false,
  },
};

// Iterates through each <div>...</div> line of ``noteHtml``
// For each line we try to match it as a condition title
// (Optionally we can find and isolate the footer text)
export const parseHtmlNoteByTitles = ({
  noteHtml,
  parseMode,
  noteBlockCaretPos = -1,
}: {
  noteHtml: HtmlString;
  parseMode: ParseMode;
  noteBlockCaretPos?: number;
}): {
  chunksWithLines: ChunkOfLinesWithText[];
  shelfDividerWasAdded: boolean;
  caretPosition: CaretPosition;
  noteHasHeader: boolean;
} => {
  if (!noteHtml) {
    switch (parseMode) {
      case 'index':
      case 'singleAtLastIndex': {
        // 'singleAtLastIndex' probably won't ever hit this
        const lines = [
          {
            html: EMPTY_BR_HTML_LINE as HtmlString,
            type: 'freetext' as const,
          },
        ];
        return {
          chunksWithLines: [
            {
              lines,
              text: linesToHtmlString(lines),
              type: 'condition',
            },
          ],
          shelfDividerWasAdded: false,
          caretPosition: { noteBlockIndex: 0, caretPos: 0 },
          noteHasHeader: false,
        };
      }
      case 'mesh':
      case 'single': // 'single' probably won't ever hit this
      default:
        return {
          chunksWithLines: [],
          shelfDividerWasAdded: false,
          caretPosition: { noteBlockIndex: 0, caretPos: 0 },
          noteHasHeader: false,
        };
    }
  }

  const {
    convertHtmlForCkeditor,
    splitFooterInto,
    addShelfDividerIfNone,
    removeUpToOneTrailingNewlineFromAllButLastChunk,
  } = optionsByParseMode[parseMode];

  const chunksWithLines: ChunkOfLines[] = [];
  let noteHasHeader: undefined | boolean;
  let titleLineWasAdded = false;
  let shelfDividerWasAdded = false;
  let retainNextNewLine = true;
  let caretPosition: null | CaretPosition = null;
  let traversedCaretPositions = -1;

  const lineRegex = getNonGlobalLineRegex();
  const getCurrentChunk = () => nth(chunksWithLines, chunksWithLines.length - 1);
  const getCurrentLines = () => getCurrentChunk()?.lines;
  const addConditionChunkIfNecessary = (html: HtmlString, options?: { isAddingTitle: boolean }) => {
    const currentChunk = getCurrentChunk();
    const currentLines = getCurrentLines();
    const currentConditionType = shelfDividerWasAdded ? 'shelvedCondition' : 'condition';

    // There are two cases, currently, where we need to add a new chunk:
    // 1. a chunk doesn't exist yet; currentChunk?.type will be `undefined`
    // 2. we're at or past the shelf divider
    const isWrongConditionType = currentChunk?.type !== currentConditionType;
    const lastLine = last(currentLines);
    const lastLineHtml = lastLine?.html;
    const lastLineType = last(currentLines)?.type;

    // Allow a single empty line to be attributed to the shelf divider to prevent
    // additional empty lines from accruing.
    // It will be removed automatically later if `removeUpToOneTrailingNewlineFromAllButLastChunk`.
    if (lastLineType === 'shelfDivider' && getIsEmptyHtmlString(html)) {
      return;
    }

    let shouldAddNewChunk = isWrongConditionType;
    const cursorInPreviousChunk = noteBlockCaretPos <= traversedCaretPositions;

    if (parseMode === 'singleBlockDoubleNewline') {
      const hasDoubleEmptyLine = lastLineHtml === EMPTY_BR_HTML_LINE && getIsEmptyHtmlString(html);
      // Case 1: Double empty line past the cursor -> remove this empty line
      if (hasDoubleEmptyLine && cursorInPreviousChunk) {
        retainNextNewLine = false;
      }
      // Case 2: Double empty line with cursor on second line -> remove only the previous empty line
      else if (hasDoubleEmptyLine && noteBlockCaretPos === traversedCaretPositions + 1) {
        currentLines?.pop();
      }
      // Case 3: Double empty line before the cursor -> remove both empty lines
      else if (hasDoubleEmptyLine) {
        retainNextNewLine = false;
        currentLines?.pop();
      }

      shouldAddNewChunk = shouldAddNewChunk || hasDoubleEmptyLine;
    } else {
      // this handles two cases case
      // a) adding the very first chunk
      // b) adding a second chunk if the first block is pretext followed by a title
      const lastLineNotTitleWhenAddingTitle =
        parseMode !== 'copy' && !!options?.isAddingTitle && lastLineType !== 'title';

      shouldAddNewChunk = shouldAddNewChunk || lastLineNotTitleWhenAddingTitle;
    }

    if (shouldAddNewChunk && cursorInPreviousChunk && !caretPosition) {
      const currentChunkText = linesToPlainText(currentChunk?.lines || []);
      caretPosition = {
        noteBlockIndex: chunksWithLines.length > 1 ? chunksWithLines.length - 1 : 0,
        caretPos: chunksWithLines.length === 1 ? currentChunkText.length : 0,
      };
    }

    if (shouldAddNewChunk) {
      chunksWithLines.push(getEmptyCondition({ type: currentConditionType }));
    }
  };

  const rawHtmlLines = htmlStringToHtmlLines(noteHtml);
  rawHtmlLines?.forEach((rawHtmlLine) => {
    const rawPlainText = htmlStringToPlainText(rawHtmlLine);
    const html = convertHtmlForCkeditor ? htmlLineToCkeditorHtmlLine(rawHtmlLine) : rawHtmlLine;

    const match: (string | undefined)[] = lineRegex.exec(rawPlainText) || [];
    const [
      ,
      //
      titleSignifier = '',
      shelfPrefixSignifier = '',
      bulletSignifier = '',
      plainText = '',
    ] = match;

    const plainTextTrimmed = plainText.trim();

    if (titleSignifier && plainText) {
      // --- Title Line --- //
      // A title is indicated by a title signifier and some text

      if (noteHasHeader === undefined) {
        noteHasHeader = false;
      }

      // NOTE: Checking for `plainText` (rather than `plainTextTrimmed`)
      //  allows for "whitespace titles". This means that a condition with
      //  text "# " will be treated as a titled condition and will not merge
      //  into the area below, on initial load or during note editing.
      // But if a condition has text "#" it will no longer be treated as an
      //  independent condition area.

      // 1. Add a new condition if (a) in the wrong type of condition or (b) somewhere in the middle or end of a condition
      addConditionChunkIfNecessary(html, { isAddingTitle: true });

      // 2. Then add the title
      // NOTE: We could be adding a second or third (stacked) title line to the current condition
      getCurrentLines()?.push({
        html,
        plainTextWithoutTitleSignifier: plainTextTrimmed,
        titleSignifier,
        type: 'title',
      });

      titleLineWasAdded = true;
    } else if (titleLineWasAdded && bulletSignifier) {
      // --- Bullet Line --- //
      // A bullet is indicated by a bullet signifier and can only occur after a title

      // Add bullet
      getCurrentLines()?.push({
        bulletSignifier,
        html,
        plainTextWithoutBulletSignifier: plainTextTrimmed,
        type: 'bullet',
      });
    } else if (
      !shelfDividerWasAdded &&
      shelfPrefixSignifier &&
      plainTextTrimmed &&
      plainTextTrimmed.substr(-3) === SHELF_SIGNIFIER
    ) {
      // --- Shelf Divider Chunk --- //
      // A shelf divider chunk is indicated by a shelf prefix signifier and can only be added once

      const shelfDividerLine: MeshedNoteShelfDividerHtmlLine = {
        html,
        htmlWithoutShelfSignifiers: stripShelfSignifiers(html),
        type: 'shelfDivider',
      };

      // 1. Add a shelf divider chunk
      chunksWithLines.push({
        lines: [shelfDividerLine],
        type: 'shelfDivider',
      });

      // 2. Note that we did that
      shelfDividerWasAdded = true;

      // 3. Note we are now title-less
      titleLineWasAdded = false;
    } else {
      // --- Freetext Line --- //
      if (noteHasHeader === undefined) {
        noteHasHeader = true;
      }

      // 1. Makes sure we're in a condition or shelved condition chunk
      addConditionChunkIfNecessary(html);

      // 2. Add freetext line
      const isEmptyLine = getIsEmptyHtmlString(html);

      if (retainNextNewLine || !isEmptyLine) {
        getCurrentLines()?.push({
          // Make sure "empty" lines are EMPTY_BR_HTML_LINE and nothing else
          html: isEmptyLine ? EMPTY_BR_HTML_LINE : html,
          type: 'freetext',
        });
      }
    }

    // Count an empty line as a single caret position
    traversedCaretPositions += rawPlainText.length + 1 || 1;
    retainNextNewLine = true;
  });

  // Check caret position before any extraneous chunks are added
  if (!caretPosition) {
    caretPosition = {
      noteBlockIndex: chunksWithLines.length > 1 ? chunksWithLines.length - 1 : 0,
      caretPos: chunksWithLines.length === 1 ? noteBlockCaretPos : 0,
    };
  }

  const { chunksWithFooter } = splitFooterFromChunksWithLines({
    chunksWithLines,
    splitFooterInto,
  });

  let chunksWithFooterAndShelfDivider = chunksWithFooter;
  const shouldInsertShelfDivider = !shelfDividerWasAdded && addShelfDividerIfNone;
  if (shouldInsertShelfDivider) {
    // Add the divider right before the first shelved condition or footer, if either exists exists
    const lastChunkIndex = chunksWithFooter.length - 1;
    const lastChunk = nth(chunksWithFooter, lastChunkIndex);
    const lastChunkIsFooter = lastChunk && isFooterChunk(lastChunk);

    chunksWithFooterAndShelfDivider = insert(
      chunksWithFooter,
      lastChunkIsFooter ? lastChunkIndex : lastChunkIndex + 1,
      defaultShelfDividerChunk
    );

    const newLastChunkIndex = chunksWithFooterAndShelfDivider.length - 1;
    if (lastChunkIsFooter) {
      chunksWithFooterAndShelfDivider[newLastChunkIndex].type = 'shelvedCondition';
    }
  }

  // Removing trailing `<div><br></div>` (EMPTY_BR_HTML_LINE) at the end of each
  //  chunk, if specified
  // Think: if these newlines are coming from the base note, they will get
  //  added back when the user clicks Copy Note!
  if (removeUpToOneTrailingNewlineFromAllButLastChunk) {
    chunksWithFooterAndShelfDivider.forEach(({ lines }, i) => {
      const isLastChunk = i === chunksWithFooterAndShelfDivider.length - 1;

      if (!isLastChunk) {
        const lastLineHtml = last(lines)?.html;
        const lastLineIsNewline = lastLineHtml === EMPTY_BR_HTML_LINE;

        if (lastLineIsNewline) {
          // OK to mutate here since the `lines` array is being built from
          //  scratch anyway
          lines.pop();
        }
      }
    });
  }

  return {
    chunksWithLines: chunksWithFooterAndShelfDivider.map((chunk) => ({
      ...chunk,
      text: linesToPlainText(chunk.lines),
    })),
    shelfDividerWasAdded,
    caretPosition,
    noteHasHeader: !!noteHasHeader,
  };
};
