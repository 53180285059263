import React from 'react';
import PropTypes from 'prop-types';

import { capitalizeFirstLetter, getLocalStorageItem, setLocalStorageItem } from '~/app/utils';

import { formatDate } from './content';

const LOCAL_STORAGE_KEY = 'pmemrDraftNote';

class NoteDetails extends React.Component {
  constructor(props) {
    super(props);
    const draftNote = getLocalStorageItem(LOCAL_STORAGE_KEY);
    this.state = { draftNote: draftNote || '' };
  }

  handleDraftNoteUpdate(draftNote) {
    setLocalStorageItem(LOCAL_STORAGE_KEY, draftNote);
    this.setState({ draftNote });
  }

  render() {
    const { fieldValues } = this.props;
    const { draftNote } = this.state;

    return (
      <div className="htemr-NoteDetails">
        <div className="htemr-NoteDetails-title">
          {capitalizeFirstLetter(fieldValues.type)} from
          {formatDate(fieldValues.createTime)}
          {fieldValues.draft ? <span className="htemr-NoteDetails-draftBadge">Draft</span> : null}
        </div>
        <div className="htemr-NoteDetails-textSection">
          <div className="htemr-NoteDetails-textSectionContent">
            <pre>{fieldValues.noteText}</pre>
          </div>
        </div>
        {fieldValues.draft && (
          <div className="htemr-NoteDetails-textSection">
            <textarea
              className="htemr-NoteDetails-textSectionContent"
              onChange={(e) => this.handleDraftNoteUpdate(e.target.value)}
              placeholder="Write your note here"
              value={draftNote}
            />
          </div>
        )}
      </div>
    );
  }
}

NoteDetails.propTypes = {
  fieldValues: PropTypes.shape({
    createTime: PropTypes.object,
    draft: PropTypes.bool,
    noteText: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default NoteDetails;
