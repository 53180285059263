/**
 * Use this react hook when you'd like to manually set a boolean value to
 *  `true` and have it automatically set itself back to `false` after a
 *  particular amount of time has passed.
 *
 * Think notification that appears on trigger and then disappears automatically
 *  after certain amount of time.
 */

import _ from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { useBooleanState } from './useBooleanState';

export const useTemporarilyTrueBoolean = (
  // NOTE: Modifying this number on the fly will cause unexpected behavior
  //  without further modifications to this hook.
  duration: number
): [
  boolean,
  {
    setTemporarilyTrue(): void;
    // setFalse(): void NOTE: This yet-to-be-developed function would set the boolean state to true and clear the timeout
  }
] => {
  const [boolean, { setFalse, setTrue }] = useBooleanState(false);
  const timeoutRef = useRef<null | number>(null);

  const setTemporarilyTrue = useCallback(() => {
    // 1. set state true
    setTrue();
    // 2. clear any existing timeout
    if (_.isNumber(timeoutRef.current)) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    // 3. create timeout to set state false
    timeoutRef.current = window.setTimeout(setFalse, duration);
  }, [duration, setFalse, setTrue]);

  useEffect(
    () => () => {
      // 1. clear any existing timeout
      if (_.isNumber(timeoutRef.current)) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    },
    []
  );

  return [
    boolean,
    {
      setTemporarilyTrue,
    },
  ];
};
