import { useCallback } from 'react';

import { useDispatch } from '../../../store';

export const useAddCondition = (): ((index: number) => void) => {
  const dispatch = useDispatch();

  const addCondition = useCallback(
    (index: number) => {
      dispatch({
        type: 'add a condition at index',
        payload: { index },
      });
    },
    [dispatch]
  );

  return addCondition;
};
