import { SummaryP, SummarySpanB } from './common';

export const neutropeniaSummary = (
  <>
    <SummaryP first>
      Regard will trigger a <SummarySpanB>positive</SummarySpanB> diagnosis for an absolute
      neutrophil count (ANC) &lt;500 in the past 48 hours. If ANC is not directly reported, Regard
      will calculate with WBC, % neutrophils, and % bands. Regard will prefer ANC manual labs over
      ANC automated labs.
    </SummaryP>
    <SummaryP>
      Neutropenic fever is <SummarySpanB>positive</SummarySpanB> when neutropenia is true{' '}
      <SummarySpanB>and</SummarySpanB> the patient’s temperature &gt;38&#8451; within 24 hours. Also
      positive if neutropenic fever or any synonyms are present in the patient’s notes.
    </SummaryP>
  </>
);
