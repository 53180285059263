import { flatten, pullAllWith } from 'lodash';

import { interpretationIsCritical } from '~/app/utils';
import { BulletNoteStatus, ObservationInterpretation } from '~/app/@types';
import { BulletLine, BulletNoteStatusRecord, Line, PostModuleNoteBlock } from '~/app/@types/state';

import { LINE_DIVIDER_TAG } from '~/app/constants';
import { isHidden, isMonitored, isNoted, isOmitted } from '../../bulletNoteStatus';
import { transformLines } from './transformLines';
import { bulletHasChanged } from '../../bulletHasChanged';
import { diffToStatus } from '../diffToStatus';
import { DEFAULT_BULLET_SIGNIFIER } from '../../regex';

type BlockWithLinesBeforeUpgrade = Pick<
  PostModuleNoteBlock,
  'bulletsByTrimmedTextKey' | 'diff' | 'lines' | 'modules' | 'textTimestamp'
>;

type TransformNoteBlocksLinesParams<T> = {
  noteBlocks: T[];
  staleBulletNoteStatus: BulletNoteStatusRecord;
  editedIndex?: number;
  movedIntoShelf?: boolean;
  movedOutOfShelf?: boolean;
};

type TransformNoteBlocksLinesResult<T> = {
  bulletNoteStatusUpdatesForBullets: BulletNoteStatusRecord;
  noteBlocksWithLines: (Omit<T, 'lines'> & { lines: Line[] })[];
};

/**
 * Upgrades block chunkLines to full Note Lines
 * Note: Also syncs
 */
export const transformNoteBlocksLines = <T extends BlockWithLinesBeforeUpgrade>(
  params: TransformNoteBlocksLinesParams<T>
): TransformNoteBlocksLinesResult<T> => {
  const { noteBlocks, staleBulletNoteStatus, editedIndex, movedIntoShelf, movedOutOfShelf } =
    params;

  const bulletNoteStatusUpdatesForBullets: BulletNoteStatusRecord = {};

  const noteBlocksWithLines = noteBlocks.map((noteBlock, index) => {
    const { foundBulletIds, transformedLines } = transformLines(noteBlock);
    const isEditedIndex = index === editedIndex;

    const notFoundMetaIds = flatten(
      Object.values(noteBlock.bulletsByTrimmedTextKey).map((bullet) =>
        bullet.ids.map((id) => ({
          id,
          interpretation: 'interpretation' in bullet ? bullet.interpretation : undefined,
          diff: bullet.diff,
        }))
      )
    );
    const notFoundBulletIds = pullAllWith(
      notFoundMetaIds,
      foundBulletIds,
      (metaId, foundBulletIds) => foundBulletIds.includes(metaId.id)
    );

    // Bullets found in the note
    foundBulletIds.forEach((id) => {
      if (isOmitted(id, staleBulletNoteStatus)) {
        // Record this bullet as Noted, since it appears in the condition text
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Noted;
      }

      // When a condition moved into the shelf, downgrade the found bullet:
      //   noted -> monitored
      if (isEditedIndex && movedIntoShelf && isNoted(id, staleBulletNoteStatus)) {
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Monitored;
      }
    });

    // Tracked bullets not found in the note
    notFoundBulletIds.forEach((notFoundMetaId, index) => {
      const { id } = notFoundMetaId;
      if (isNoted(id, staleBulletNoteStatus)) {
        // Record this bullet as Monitored
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Monitored;
      }

      // When a condition moved into the shelf, downgrade the suggested bullets bullets:
      //   monitored -> hidden
      if (isEditedIndex && movedIntoShelf && isMonitored(id, staleBulletNoteStatus)) {
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Hidden;
      }
      // When a condition moved out of the shelf, upgrade the bullets bullets:
      //   noted <- monitored <- hidden
      else if (isEditedIndex && movedOutOfShelf && isMonitored(id, staleBulletNoteStatus)) {
        // Find the related bullet suggestion and generate a line from it
        const bulletEntry = Object.entries(noteBlock.bulletsByTrimmedTextKey).find(
          ([, bulletData]) => bulletData.ids.includes(id)
        );
        if (bulletEntry) {
          const [bulletText, bulletIndexingMetadata] = bulletEntry;
          const { ids, diff } = bulletIndexingMetadata;
          const status = diffToStatus(diff);
          const bulletKey = `bullet-${ids.join('-')}-upgraded-${index}`;
          const newLine: BulletLine = {
            type: 'bullet',
            ids,
            status,
            interpretation:
              'interpretation' in bulletIndexingMetadata
                ? bulletIndexingMetadata.interpretation
                : ObservationInterpretation.Unknown,
            bulletSignifier: DEFAULT_BULLET_SIGNIFIER,
            html: `<${LINE_DIVIDER_TAG}>- ${bulletText}</${LINE_DIVIDER_TAG}>` as HtmlString,
            plainTextWithoutBulletSignifier: bulletText,
            key: bulletKey,
          };
          transformedLines.push(newLine);
        }
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Noted;
      } else if (isEditedIndex && movedOutOfShelf && isHidden(id, staleBulletNoteStatus)) {
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Monitored;
      }

      // Force any previously dismissed critical bullets to show if
      // it's been changed. A bullet is considered "changed" if the text
      // has changed or didn't exist in basenote.
      const isUpdatedCriticalId =
        interpretationIsCritical(notFoundMetaId.interpretation) &&
        bulletHasChanged(notFoundMetaId.diff) &&
        (isHidden(id, staleBulletNoteStatus) || isHidden(id, bulletNoteStatusUpdatesForBullets));
      if (isUpdatedCriticalId) {
        bulletNoteStatusUpdatesForBullets[id] = BulletNoteStatus.Monitored;
      }
    });

    if (isEditedIndex && movedIntoShelf) {
      // Remove all tracked bullets
      const cleanedLines = transformedLines.filter((line) => line.type !== 'bullet');
      return {
        ...noteBlock,
        lines: cleanedLines,
        textTimestamp: Date.now(),
      };
    }

    return {
      ...noteBlock,
      lines: transformedLines,
    };
  });

  return { bulletNoteStatusUpdatesForBullets, noteBlocksWithLines };
};
