import { FC, ReactNode } from 'react';

import { subtitleStyle, titleContainerStyle, titleStyle } from './css';

export const NoteContainerHeaderTitle: FC<{
  subtitle: ReactNode;
  title: ReactNode;
}> = ({ subtitle, title }) => (
  <span style={titleContainerStyle}>
    <div style={titleStyle}>{title}</div>
    {subtitle && <div style={subtitleStyle}>{subtitle}</div>}
  </span>
);
