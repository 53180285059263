import { ChangeEvent, FormEvent, RefObject, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import sendFeedback from '~/app/controllers/userFeedback';
import {
  getIsValidEmail,
  getLocalStorageItem,
  setLocalStorageItem,
  useDelayedSetState,
} from '~/app/utils';
import { getOAuthSubject } from '~/app/cookies';

import { PATIENT_PARAM, TIMESTAMP_PARAM } from '~/app/constants';
import { getHtmlBlob } from './getHtmlBlob';
import { embedExternalCssStyles } from './embedExternalCssStyles';

// NOTE: `embedCssStyles` must be run before submitting feedback.
window.setTimeout(embedExternalCssStyles, 1000);

const emailCacheKeyOrNull = () => (getOAuthSubject() ? `email-for-${getOAuthSubject()}` : null);

const defaultState = () => {
  // try to autopopulate the email field using the cached email address in localStorage
  const emailCacheKey = emailCacheKeyOrNull();
  const cachedEmailForUserOrNull = emailCacheKey ? getLocalStorageItem(emailCacheKey) : null;
  return {
    email: cachedEmailForUserOrNull ?? '',
    emailError: '',
    includeEmail: false,
    text: '',
    textError: '',
    type: 'default',
  };
};
type DefaultState = ReturnType<typeof defaultState>;

type EditedState = {
  email: string;
  emailError: string;
  includeEmail: boolean;
  text: string;
  textError: string;
  type: 'edited';
};

const sentState = {
  email: '',
  emailError: '',
  includeEmail: false,
  text: '',
  textError: '',
  type: 'sent',
} as const;
type SentState = typeof sentState;

type State = DefaultState | EditedState | SentState;

export const submitUserFeedback = (
  formState: Pick<State, 'includeEmail' | 'email' | 'text'>,
  searchParams: URLSearchParams,
  ptId?: string
) => {
  const { regardNote } = window.store.getState();
  const { encounterId, patientMrn, pt } = regardNote;
  const noteTimestamp = regardNote.baseNoteData.effectiveTimestamp;
  const noteId = regardNote.baseNoteData.resourceId;
  const htmlBlob = getHtmlBlob();

  // POST form data to /feedback to create Jira ticket
  sendFeedback({
    email: formState.includeEmail ? formState.email : '',
    encounterId,
    runTimestamp: regardNote.timestamp,
    noteTimestamp,
    urlTimestamp: searchParams.get(TIMESTAMP_PARAM),
    noteId,
    htmlBlob,
    patientMrn,
    notePatientId: ptId || pt,
    urlPatientId: searchParams.get(PATIENT_PARAM),
    reason: formState.text,
  });
};

export const useUserFeedbackForm = ({
  closeFeedback,
  pt,
}: {
  closeFeedback(): void;
  pt: string;
}): {
  cannotSend: boolean;
  editEmail(e: ChangeEvent<HTMLInputElement>): void;
  editText(e: ChangeEvent<HTMLTextAreaElement>): void;
  formCancel(): void;
  formClose(): void;
  formRef: RefObject<HTMLFormElement>;
  formSubmit(e: FormEvent<HTMLFormElement>): void;
  formState: State;
  toastClose(): void;
  toggleIncludeEmail(): void;
} => {
  // -- State --
  const [formState, setFormStateWithDelay] = useDelayedSetState<State>(defaultState());
  const [searchParams] = useSearchParams();

  // -- Ref --
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formState.includeEmail) {
      setTimeout(
        () => formRef.current?.querySelector<HTMLInputElement>('input[type="email"]')?.focus(),
        0
      );
    }
  }, [formState.includeEmail]);

  // -- Handlers --
  const toggleIncludeEmail = () =>
    setFormStateWithDelay({
      state: (state) => ({
        ...state,
        includeEmail: !formState.includeEmail,
        type: 'edited',
      }),
    });

  const editEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setFormStateWithDelay({
      state: (state) => ({
        ...state,
        email: e.target.value,
        type: 'edited',
      }),
    });

  const editText = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setFormStateWithDelay({
      state: (state) => ({
        ...state,
        text: e.target.value,
        type: 'edited',
      }),
    });

  const toastClose = () => {
    closeFeedback();
    setFormStateWithDelay({ state: defaultState });
  };

  const formCancel = () => {
    closeFeedback();
    setFormStateWithDelay({ state: defaultState, delay: 100 });
  };

  const formClose = closeFeedback;

  const formSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const noText = !formState.text;
    const invalidEmail = formState.includeEmail && !getIsValidEmail(formState.email);

    if (noText || invalidEmail) {
      setFormStateWithDelay({
        state: (state) => ({
          ...state,
          emailError: invalidEmail ? 'Please enter a valid email' : '',
          textError: noText ? 'Please enter text' : '',
          type: 'edited',
        }),
      });
    } else {
      setFormStateWithDelay({ state: sentState });

      setFormStateWithDelay({ callback: closeFeedback, state: defaultState, delay: 4000 });

      setTimeout(async () => {
        // cache this user's email address to populate later
        const emailCacheKey = emailCacheKeyOrNull();
        const shouldCacheEmailAddress = formState.includeEmail && getIsValidEmail(formState.email);
        if (shouldCacheEmailAddress && emailCacheKey) {
          setLocalStorageItem(emailCacheKey, formState.email);
        }

        submitUserFeedback(formState, searchParams, pt);
      }, 1000);
    }
  };

  return {
    cannotSend: !formState.text || (formState.includeEmail && !formState.email),
    editEmail,
    editText,
    formCancel,
    formClose,
    formRef,
    formSubmit,
    formState,
    toastClose,
    toggleIncludeEmail,
  };
};
