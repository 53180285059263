import { NBSP_CODE, NBSP_SINGLE, OPEN_ANGLE_CODE, SPACE_CODE } from '~/app/constants';

const replaceSubstringWithNonBreakingSpaces = <T extends string>({
  closedByOpenAngleBracketChar,
  end,
  start,
  string,
}: {
  closedByOpenAngleBracketChar: boolean;
  end: number;
  start: number;
  string: T;
}): T => {
  const length = end - start;

  if (length > 1) {
    const isOdd = !!(length % 2);

    return (string.substring(0, start) +
      `${NBSP_SINGLE} `.repeat(Math.floor(length / 2)) +
      (isOdd ? NBSP_SINGLE : '') +
      string.substring(end)) as T;
  }

  // This is a hack for IE. When splitting a stacked DX by adding a "#" at the
  //  top, the new condition was receiving "<div># <br></div>" as its html and
  //  CKEDitor converts that to "<div>#</div>", which then loses status as a
  //  condition area.
  // To counteract this, we convert any single space before an open angle
  //  bracket to a non-breaking space, so we get this text instead:
  //
  //    "<div>#&nbsp;<br></div>"
  if (length === 1 && closedByOpenAngleBracketChar) {
    return (string.substring(0, start) + NBSP_SINGLE + string.substring(end)) as T;
  }

  return string;
};

// This function exists because CKEditor will:
//
// (1) collapse successive spaces unless they are non-breaking. (And we don't
//  want spaces collapsed).
// ' '    => ' '
// '  '   => ` ${String.fromCharCode(160)}`
// '   '  => ` ${String.fromCharCode(160)}${String.fromCharCode(160)}`
// '    ' => ` ${String.fromCharCode(160)} ${String.fromCharCode(160)}`
// (2) trim regular spaces off the beginning and ends of a line
export const nbspifyStringForCkeditor = <T extends string>(string: T): T => {
  if (!string.length) return string;

  let start = -1;
  let result = string;

  for (
    let i = 0;
    // allow the loop to run onto imaginary index past the end;
    // this will close an open range
    i <= result.length;
    i++
  ) {
    const charCode = result.charCodeAt(i);
    const isSpace = charCode === SPACE_CODE || charCode === NBSP_CODE;
    const isOpenAngle = charCode === OPEN_ANGLE_CODE;

    if (start === -1 && isSpace) {
      // open a range
      start = i;
    } else if (start >= 0 && !isSpace) {
      // close the range
      result = replaceSubstringWithNonBreakingSpaces({
        closedByOpenAngleBracketChar: isOpenAngle,
        end: i,
        start,
        string: result,
      });
      start = -1;
    }
  }

  return result.replace(/^\s/, NBSP_SINGLE).replace(/\s$/, NBSP_SINGLE) as T;
};
