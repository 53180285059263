import { useEffect, useState } from 'react';

import { track } from '../../../analytics';
import { getEnv, isDebugPerformance } from '../../../flags';

// Tracks whether this is the first measurement reported.
let first = true;

const isDev = getEnv() === 'dev';

declare global {
  interface Window {
    reactNoteEditorOnInputRecord: Record<string, number>;
    reactNoteEditorOnInput?: number;
    reactNoteEditorThrottle?: number;
    reactNoteEditorSetTimeout?: number;
  }
}

window.reactNoteEditorOnInputRecord = {};

export const measureOnInput = (uniqueId: string): void => {
  if (
    first ||
    isDev ||
    Math.random() > 0.9375 // should report randomly around 1/16 times--but less, now that we're using the reactNoteEditorOnInputRecord pattern
  ) {
    window.reactNoteEditorOnInputRecord[uniqueId] = new Date().valueOf();
  }
};

export const measureThrottle = (uniqueId: string): void => {
  const onInput = window.reactNoteEditorOnInputRecord[uniqueId];

  if (onInput && !window.reactNoteEditorThrottle) {
    window.reactNoteEditorOnInputRecord = {};
    window.reactNoteEditorOnInput = onInput;
    window.reactNoteEditorThrottle = new Date().valueOf();
  }
};

export const measureSetTimeout = (): void => {
  if (
    window.reactNoteEditorOnInput &&
    window.reactNoteEditorThrottle &&
    !window.reactNoteEditorSetTimeout
  )
    window.reactNoteEditorSetTimeout = new Date().valueOf();
};

// NOTE: I tried using a ref, but it was unreliable. Alos, it's okay to use a
//  static property here.
let prevRenderTime = 0;

const reset = ({ renderTime }: { renderTime: number }) => {
  // Allow measuring to resume.
  window.reactNoteEditorOnInput = undefined;
  window.reactNoteEditorThrottle = undefined;
  window.reactNoteEditorSetTimeout = undefined;
  prevRenderTime = renderTime;
};

// This hook is designed to report the time before and after react reconciles
//  the render of interest.
export const useMeasureRender = (trigger: unknown) => {
  const [renderTime, setRenderTime] = useState(0);

  // Stage A
  useEffect(() => {
    if (window.reactNoteEditorOnInput && renderTime <= prevRenderTime) {
      // NOTE: Setting state here, will trigger another render, which allows us
      //  to see about how long reconciliation takes. (It's very fast.)
      setRenderTime(new Date().valueOf());
    }
  }, [renderTime, trigger]);

  // Stage B
  if (renderTime > prevRenderTime) {
    const typingCase = window.lastEditedCase ?? 'unknown';
    const onInputTime = window.reactNoteEditorOnInput ?? 0;
    const throttleTime = window.reactNoteEditorThrottle ?? 0;
    const setTimeoutTime = window.reactNoteEditorSetTimeout ?? 0;
    const renderCompleteTime = new Date().valueOf();

    const throttle = throttleTime - onInputTime;
    const delay = setTimeoutTime - throttleTime;
    const process = renderTime - setTimeoutTime;
    const render = renderCompleteTime - renderTime;
    const totalWithoutThrottle = renderCompleteTime - throttleTime;

    // Make sure we're not reporting some ridiculous value; sometimes the
    //  reporting bugs out and compares the current time time against 0.
    if (totalWithoutThrottle < 10000) {
      /* eslint-disable no-console */
      if (isDebugPerformance) {
        console.log(`Performance metrics for EditedCase "${typingCase}":`);
        console.log(`throttle: ${throttle}ms`);
        console.log(`delay: ${delay}ms`);
        console.log(`process: ${process}ms`);
        console.log(`render: ${render}ms`);
        console.log(`total (without throttle): ${totalWithoutThrottle}ms`);
      }
      /* eslint-enable no-console */

      // Extra protection on dev env, so we don't blow our event limits.
      if (!isDev) {
        track.measuredRenderDuration({
          throttle,
          delay,
          process,
          render,
          totalWithoutThrottle,
          first,
          typingCase,
        });
        first = false;
      }
    }

    reset({ renderTime });
  }
};
