import {
  LINE_DIVIDER_TAG_UPPER_CASE,
  STYLE_BOLD_TAG,
  STYLE_ITALIC_TAG,
  STYLE_UNDERLINE_TAG,
} from '~/app/constants';

import { addStylesToHtml } from '~/app/utils';

const getParentTagList = (element: Node | null) => {
  const tagList: string[] = [];
  let currentNode: Node | null = element;

  while (currentNode) {
    if (currentNode.nodeType === Node.TEXT_NODE) {
      const currentElement = currentNode as Text;
      currentNode = currentElement.parentNode;
    } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
      const currentElement = currentNode as HTMLElement;

      // Can stop once a line divider element is reached, since no more
      // styling tags "should" exist beyond the line divider
      if (currentElement.tagName === LINE_DIVIDER_TAG_UPPER_CASE) {
        break;
      }

      const elementTagName = currentElement.tagName.toLowerCase();
      tagList.push(elementTagName);

      currentNode = currentElement.parentNode;
    } else {
      // Other node types are not supported
      break;
    }
  }

  return tagList;
};

/**
 * Check if any containing or parent nodes are style tags and make sure the styles
 * are copied along with
 * @param range
 * @returns TitleStyle
 */
const getStylesInRange = (range: Range) => {
  const startParentTagList = getParentTagList(range.startContainer);
  const endParentTagList = getParentTagList(range.endContainer);

  const hasBold =
    startParentTagList.includes(STYLE_BOLD_TAG) && endParentTagList.includes(STYLE_BOLD_TAG);
  const hasItalic =
    startParentTagList.includes(STYLE_ITALIC_TAG) && endParentTagList.includes(STYLE_ITALIC_TAG);
  const hasUnderline =
    startParentTagList.includes(STYLE_UNDERLINE_TAG) &&
    endParentTagList.includes(STYLE_UNDERLINE_TAG);

  return {
    bold: hasBold,
    italic: hasItalic,
    underline: hasUnderline,
  };
};

export const formatSelectedHtml = (range: Range, html: HtmlString) => {
  const styles = getStylesInRange(range);
  const selectedHtml = addStylesToHtml(html, styles);
  return selectedHtml;
};

export const getSelectedHtml = (selection: Selection | null, removeSelection: boolean) => {
  const range = selection?.getRangeAt(0);
  if (!selection || !range) return '' as HtmlString;

  const selectionFragment = removeSelection ? range.extractContents() : range.cloneContents();
  const div = document.createElement('div');
  div.appendChild(selectionFragment);
  const selectedHtml = div.innerHTML as HtmlString;

  return formatSelectedHtml(range, selectedHtml);
};
