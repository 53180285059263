import { FC } from 'react';

import {
  chevronIconStyle,
  downChevronStyle,
  floatRightStyle,
  getHeaderStyle,
  upChevronStyle,
  xOfYStyle,
} from './css';
import { AccordionAPI, Icon, LinkButton } from '..';
import { capitalizeFirstLetter } from '../../utils';

type AccordionTableAccordionHeaderProps = AccordionAPI & {
  first: boolean;
  numberOfRows: number;
  setShowingAllRows(expanded: boolean): void;
  showingAllRows: boolean;
  text: string;
  collapsable: boolean;
};

export const AccordionTableAccordionHeader: FC<AccordionTableAccordionHeaderProps> = ({
  first,
  isOpen,
  numberOfRows,
  setShowingAllRows,
  showingAllRows,
  text,
  collapsable,
}) => (
  <div data-cy-accordion-table-accordion-header style={getHeaderStyle({ first })}>
    <span css={isOpen ? upChevronStyle : downChevronStyle}>
      <Icon iconName="upChevron" style={chevronIconStyle} />
    </span>
    <span>{capitalizeFirstLetter(text)}</span>
    {collapsable && isOpen && numberOfRows > 3 && (
      <span style={floatRightStyle}>
        {!showingAllRows && <span style={xOfYStyle}>(3 of {numberOfRows})</span>}
        <LinkButton
          onClick={(e) => {
            e.stopPropagation();
            setShowingAllRows(!showingAllRows);
          }}
        >
          {showingAllRows ? 'Show Less' : 'Show All'}
        </LinkButton>
      </span>
    )}
  </div>
);
