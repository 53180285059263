import { RECENCY_THRESHOLD_PARAM } from '~/app/constants';

/* * * * * * * * * * STRINGS * * * * * * * * * */

// Return the string key for saving the note to localStorage.
export const getPhysicalExamStorageKey = (
  mrn: string,
  physicianId: string,
  basenoteEffectiveTimestamp: ISODateString | undefined
): string => {
  let cacheKey = physicianId;
  if (basenoteEffectiveTimestamp) {
    cacheKey += `-${basenoteEffectiveTimestamp}`;
  }

  const keyTail = `cache-${mrn}-${cacheKey}`;
  return `physical-${keyTail}`;
};

/* * * * * * * * * * MISC * * * * * * * * * */

type SideCoordinates = {
  bottom: number;
  left: number;
  right: number;
  top: number;
};

const isChildVisibleInParent = (
  child: SideCoordinates,
  parent: SideCoordinates,
  options?: {
    wiggleRoom?: number;
  }
) => {
  const wiggle = options?.wiggleRoom ?? 0;

  const isBottomIn = child.bottom - wiggle <= parent.bottom;
  const isLeftIn = child.left + wiggle >= parent.left;
  const isRightIn = child.right - wiggle <= parent.right;
  const isTopIn = child.top + wiggle >= parent.top;

  const isChildAboveAndBelowParent = child.bottom >= parent.bottom && child.top <= parent.top;
  const isChildLeftAndRightOfParent = child.right >= parent.right && child.left <= parent.left;

  const isInVertically = (isBottomIn && isTopIn) || isChildAboveAndBelowParent;
  const isInHorizontally = (isLeftIn && isRightIn) || isChildLeftAndRightOfParent;

  return isInVertically && isInHorizontally;
};

export const isElementVisibleInViewport = (
  element: HTMLElement,
  options?: {
    wiggleRoom?: number;
  }
) => {
  // NOTE: clientHeight and clientWidth factor in the width and height of any
  //  scrollbars at the document level.
  const { clientHeight, clientWidth } = document.documentElement;

  return isChildVisibleInParent(
    element.getBoundingClientRect(),
    {
      bottom: clientHeight,
      left: 0,
      right: clientWidth,
      top: 0,
    },
    options
  );
};

export const isElementFullyVisibleInContainer = (
  element: HTMLElement,
  container: HTMLElement,
  options?: {
    wiggleRoom?: number;
  }
) =>
  isChildVisibleInParent(
    element.getBoundingClientRect(),
    container.getBoundingClientRect(),
    options
  );

export const isElementPartiallyVisibleInContainer = (
  element: HTMLElement,
  container: HTMLElement,
  min: number
) => {
  const child = element.getBoundingClientRect();
  const parent = container.getBoundingClientRect();

  const isBottomIn = child.bottom - min <= parent.bottom && child.bottom - min >= parent.top;
  const isTopIn = child.top + min >= parent.top && child.top + min <= parent.bottom;

  const isChildAboveAndBelowParent = child.bottom >= parent.bottom && child.top <= parent.top;

  return isBottomIn || isTopIn || isChildAboveAndBelowParent;
};

export const exhaust = (x: never) => x;

// reload page and force backend to refetch data
export const reloadAndForceRefetch = ({ wait }: { wait?: number }): void => {
  const fn = () => {
    const queries = new URLSearchParams(window.location.search);
    queries.set(RECENCY_THRESHOLD_PARAM, new Date().toISOString());

    const { protocol, host, pathname, hash } = window.location;
    const newURL = `${protocol}//${host}${pathname}?${queries.toString()}${hash}`;

    window.location.replace(newURL);
  };

  if (wait) {
    setTimeout(fn, wait);
  } else {
    fn();
  }
};
