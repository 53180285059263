import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useReducer } from 'react';

import { client } from '~/app/api';
import { createSelector, useSelector } from '~/app/store';
import { track } from '~/app/analytics';
import { isAiChatEnabled } from '~/app/flags';
import { ChatApiState, chatApiReducer, initialChatApiState } from './chatApiReducer';
import { getConditionHtmlStringForLlm } from './getConditionHtmlStringForLlm';

let maxChatLastUserPromptIndex = -1;

const CHECK_RESPONSE_INTERVAL = 1000;

const noteBlocksSelector = createSelector(
  (state) => state.regardNote.masterNoteBlocks,
  (x) => x
);

const flattenIncludeOrExcludeObject = (input: Record<string, [string, number][]>) => {
  const output: Record<string, string[]> = {};

  Object.entries(input).forEach(([key, value]) => {
    output[key] = value.map((item) => item[0]);
  });

  return output;
};

export type PromptFn = (args: { onRestart(): void; text: string }) => void;
export type UseChatApiReturn = Pick<
  ChatApiState,
  'chatId' | 'chatReplies' | 'chatCheckingForAiResponse' | 'model'
> & {
  prompt: PromptFn;
};

export const useChatApi = (): UseChatApiReturn => {
  // ///////////////////
  // -- Start Chat -- //
  // ///////////////////

  const noteBlocks = useSelector(noteBlocksSelector);

  const [state, dispatch] = useReducer(chatApiReducer, initialChatApiState);
  const { chatId, chatCheckingForAiResponse, initialized } = state;

  // API - startChat
  useEffect(() => {
    if (isAiChatEnabled && noteBlocks.length && !initialized) {
      dispatch({ type: 'initialize' });

      const fn = async () => {
        try {
          const noteHtml = noteBlocks.map(getConditionHtmlStringForLlm).join('') as HtmlString;
          const { data, error } = await client.POST('/openapi/startChat', {
            body: {
              patientId: window.regardPatientId ?? '',
              encounterId: window.regardEncounterId ?? '',
              noteHtml,
            },
          });

          if (error) {
            throw new Error((error as { message?: string }).message);
          } else {
            const { chatId, excluded, included, model, promptId, promptType } = data;

            const flatExcluded = flattenIncludeOrExcludeObject(
              excluded as Record<string, [string, number][]>
            );
            const flatIncluded = flattenIncludeOrExcludeObject(
              included as Record<string, [string, number][]>
            );

            track.chatStarted({ chatId, model, promptId, promptType });
            track.chatPromptInfo({
              chatId,
              included: flatIncluded,
              excluded: flatExcluded,
            });
            dispatch({ type: 'start', payload: { chatId, model } });
          }
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setExtra('error', e);
            Sentry.captureException('Error starting chat.');
          });

          dispatch({ type: 'error', payload: { message: 'Error starting chat.' } });
        }
      };

      fn();
    }
  }, [noteBlocks, initialized]);

  // /////////////////
  // -- Response -- //
  // /////////////////

  // API - chatLastAiResponse
  useEffect(() => {
    let timeoutId: number;
    const chatLastAiResponse = async () => {
      try {
        const { data, error } = await client.GET('/openapi/chatLastAiResponse', {
          params: { query: { chatId } },
          headers: {
            // Make sure the check is not cached. With no headers, IE tries
            // to be "helpful" and caches the initial response.
            // Cache-Control, Pragma, and Expires are necessary
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '-1',
          },
        });

        if (error) {
          throw new Error((error as { message?: string }).message);
        } else {
          dispatch({
            type: 'addOrReplaceLastAiResponse',
            payload: { response: { ...data, type: 'response' } },
          });

          if (['processing', 'responding'].includes(data.status)) {
            // Check response again, later...
            // (We could have some tolerance for the 'error' status here)
            timeoutId = window.setTimeout(chatLastAiResponse, CHECK_RESPONSE_INTERVAL);
          } else {
            // 'done' | 'error'
            track.chatAiResponded({
              chatId,
              responseIndex: maxChatLastUserPromptIndex + 1,
            });
          }
        }
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('error', e);
          Sentry.captureException('Error getting last AI response.');
        });

        dispatch({
          type: 'addOrReplaceLastAiResponse',
          payload: { response: { status: 'error', text: '', type: 'response', citations: {} } },
        });
      }
    };

    if (chatCheckingForAiResponse) chatLastAiResponse();

    return () => window.clearTimeout(timeoutId);
  }, [chatCheckingForAiResponse, chatId, dispatch]);

  // ///////////////
  // -- Prompt -- //
  // ///////////////

  // API - addChatUserPrompt
  const prompt = useCallback<PromptFn>(
    ({ onRestart, text }) => {
      const fn = async () => {
        try {
          const { error, response } = await client.POST('/openapi/addChatUserPrompt', {
            body: {
              chatId,
              text,
            },
          });

          if (error) {
            if (response.status === 404) {
              dispatch({
                type: 'restart',
                payload: {
                  userPromptText: text,
                },
              });
              onRestart();
              Sentry.withScope((scope) => {
                scope.setExtra('error', (error as { message?: string }).message);
                Sentry.captureException('Chat was restarted.');
              });
            } else {
              throw new Error((error as { message?: string }).message);
            }
          } else {
            maxChatLastUserPromptIndex += 2;
            dispatch({ type: 'addUserPrompt', payload: { prompt: { text, type: 'prompt' } } });
          }
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setExtra('error', e);
            Sentry.captureException('Error adding user prompt to chat.');
          });
          dispatch({ type: 'idle' });
        }
      };

      fn();
    },
    [chatId, dispatch]
  );

  return { ...state, prompt };
};
