import { FC } from 'react';
import { css, keyframes } from '@emotion/react';

// IE doesn't allow animating internal svg elements, so each animation piece
// is broken into a sepparate svg
import AnimatedFolderFolderSvg from '~/app/images/animated-folder_folder.svg';
import AnimatedFolderPaperSvg from '~/app/images/animated-folder_paper.svg';
import AnimatedFolderLightbulbSvg from '~/app/images/animated-folder_lightbulb.svg';

import { isIE } from '~/app/utils/isBrowser';

import { getProgressContainerStyle, progressTextStyle } from './styles';

type ProgressFolderProps = {
  text: string;
  isVerticalLayout: boolean;
};

// Spring easing generated with https://linear-easing-generator.netlify.app/
// (https://github.com/jakearchibald/linear-easing-generator)
// using the following: mass 1, stiffness 384, damping 12
const springEasing = `linear(
    0,
    0.0451 1.36%,
    0.1838 2.88%,
    1.1524 10.01%,
    1.2997 11.95%,
    1.3615 13.92%,
    1.3636,
    1.3552 15.42%,
    1.3067 17.08%,
    0.9387 24.61%,
    0.89 26.43%,
    0.8687 28.28%,
    0.8693 29.61%,
    0.8821 31.05%,
    1.0136 38.39%,
    1.0372,
    1.0476 42.6%,
    1.0429 45.48%,
    0.9953 52.78%,
    0.9828 56.9%,
    1.0062 71.01%,
    0.9978 85.22%,
    1.0008
  )`;
const springEasingDuration = 1167; // ms
// IE11 doesn't support linear easing, so we use a bezier curve instead to get close
const bezierSpringEasing = 'cubic-bezier(.4,2.2,0,.63)';
const usedEasing = isIE() ? bezierSpringEasing : springEasing;

// Animation Timeline:
// 800ms - nothing (~26%)
// 1167ms - spring up the y position document and lightbulb opacity (~38%)
// 800ms - nothing (~26%)
// 300ms - linear fade back to start (~10%)
const totalAnimationTime = `${800 + springEasingDuration + 800 + 300}ms`;
const bottomPosition = '47px';
const topPosition = '14px';
const bounceUpAnimation = keyframes`
  from, 26%, to {
    transform: translate3d(-50%, ${bottomPosition}, 0);
  }
  
  64%, 90% {
    transform: translate3d(-50%, ${topPosition}, 0);
    animation-timing-function: linear;
  }
`;
const opacityAnimation = keyframes`
  from, 26%, to {
    opacity: 0;
  }
  
  64%, 90% {
    opacity: 1;
    animation-timing-function: linear;
  }
`;

const animationContainerStyle = {
  position: 'relative',
  width: 124,
  height: 104,
} as const;
const animationFolderStyle = {
  position: 'absolute',
  left: animationContainerStyle.width / 2,
  bottom: '0',
  transform: 'translateX(-50%)',
} as const;
const animationPaperStyle = {
  position: 'absolute',
  top: '0',
  left: animationContainerStyle.width / 2,
  transform: 'translateX(-50%)',
} as const;
const animationLightbulbStyle = {
  position: 'absolute',
  top: 0,
  left: 79,
} as const;

const paperCss = css`
  animation: ${bounceUpAnimation} ${totalAnimationTime} ${usedEasing} infinite;
`;
const lightbulbCss = css`
  animation: ${opacityAnimation} ${totalAnimationTime} ${usedEasing} infinite;
`;

export const ProgressFolder: FC<ProgressFolderProps> = (props) => {
  const { text, isVerticalLayout } = props;

  return (
    <div style={getProgressContainerStyle(isVerticalLayout)}>
      <div style={animationContainerStyle}>
        <AnimatedFolderPaperSvg css={paperCss} style={animationPaperStyle} />
        <AnimatedFolderFolderSvg style={animationFolderStyle} />
        <AnimatedFolderLightbulbSvg css={lightbulbCss} style={animationLightbulbStyle} />
      </div>
      <div data-cy-loading-progress-text style={progressTextStyle}>
        {text}
      </div>
    </div>
  );
};
