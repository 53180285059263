import { FC, memo } from 'react';
import { shallowEqual } from 'fast-equals';
import _ from 'lodash';
import { CKEditorEventHandler } from 'ckeditor4-react';

import { CKEditorRichTextEditor } from '~/app/reuse/richTextEditor/CKEditorRichTextEditor';
import { TypingDirection } from '~/app/actions/regardNote';

import { SetRteCaretPosition } from '../condition/useConditionSetCaretPosition';
import { ConditionOnHtmlInputFn } from '../conditionRte';

import { buttonHoveredStyle, textareaStyle, style } from './css';

export const UnconnectedConditionRte: FC<{
  buttonHovered: boolean;
  htmlLines: HtmlString[];
  id: string;
  editorType?: string;
  onBlur?: CKEditorEventHandler<'blur'>;
  onDestroy?: CKEditorEventHandler<'destroy'>;
  onEmptyBackspace?: (id: string, direction: TypingDirection) => void;
  onNonEmptyBackspace?: (id: string, direction: TypingDirection) => void;
  onHtmlInput: ConditionOnHtmlInputFn;
  onFocus?: CKEditorEventHandler<'focus'>;
  onSelectionChange?: CKEditorEventHandler<'selectionChange'>;
  readOnly?: boolean;
  textTimestamp: number;
  setRteCaretPositionRef: SetRteCaretPosition;
}> = memo(
  ({
    buttonHovered,
    htmlLines,
    id,
    editorType,
    onBlur,
    onDestroy,
    onEmptyBackspace,
    onNonEmptyBackspace,
    onHtmlInput,
    onFocus,
    onSelectionChange,
    readOnly,
    textTimestamp,
    setRteCaretPositionRef,
  }) => (
    <div style={buttonHovered ? buttonHoveredStyle : style}>
      {/**
       * This strange pattern below, {[ <Component key={} /> ]}, has the effect
       *  of rerendering the Component from scratch when the key changes.
       *
       * This approach is a bit of a holdover from the <textarea>
       *  implementation where `defaultValue` was used because letting the
       *  textarea manage it's own state rather that relying on the
       *  redux/note-meshing loop on every typed character was way to slow. But
       *  we wanted to blow out the current text with some other text we could
       *  do that with the timestamp.
       *
       * However, there could be a better way, which I will test at some point.
       *  We could try a useEffect which calls setEditorState when the
       *  timestamp changes.
       */}
      {[
        <div key={textTimestamp} style={textareaStyle}>
          <CKEditorRichTextEditor
            editorType={editorType}
            htmlLines={htmlLines}
            id={id}
            onBlur={onBlur}
            onDestroy={onDestroy}
            onEmptyBackspace={onEmptyBackspace}
            onFocus={onFocus}
            onHtmlInput={onHtmlInput}
            onNonEmptyBackspace={onNonEmptyBackspace}
            onSelectionChange={onSelectionChange}
            readOnly={readOnly}
            setRteCaretPositionRef={setRteCaretPositionRef}
          />
        </div>,
      ]}
    </div>
  ),
  // NOTE: This function prevents this component from rerendering when the text prop changes.
  (prevProps, nextProps) => shallowEqual(_.omit(prevProps, 'text'), _.omit(nextProps, 'text'))
);
UnconnectedConditionRte.displayName = 'UnconnectedConditionRte';
