import { FC, memo, useEffect } from 'react';

import { track } from '~/app/analytics';
import { IconButton, StaticTooltip } from '~/app/reuse';
import { useConditionContext } from '../conditionProvider';
import { CONDITION_UNDERLAY_BUTTON_COLOR, CONDITION_UNDERLAY_BUTTON_SIZE } from '../const';
import { useNoteContext } from '../noteProvider';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionShelveButton: FC = memo(() => {
  const { shelveCondition } = useNoteContext(({ shelveCondition }) => ({
    shelveCondition,
  }));
  const { id, index, onShelveButtonEnter, onShelveButtonLeave } = useNoteBlockContext(
    ({ id, index, onShelveButtonEnter, onShelveButtonLeave }) => ({
      id,
      index,

      onShelveButtonEnter,
      onShelveButtonLeave,
    })
  );
  const { modules } = useConditionContext(({ modules }) => ({
    modules,
  }));

  /**
   * When this component is unmounted, we must reset the state in the
   *  provider, otherwise it may get stuck in the "mouse entered" state.
   */
  useEffect(() => onShelveButtonLeave, [onShelveButtonLeave]);

  return (
    <StaticTooltip display="inline-block" placement="top" tooltip="Shelve Dx">
      <IconButton
        color={CONDITION_UNDERLAY_BUTTON_COLOR}
        data-cy-condition-shelve-button
        iconName="shelve"
        onClick={() => {
          shelveCondition(id);
          track.clickedShelveDxButton({
            atIndex: index,
            modules,
          });
          modules.forEach((m) => track.conditionShelved({ module: m, uiElement: 'shelve button' }));
        }}
        onMouseEnter={onShelveButtonEnter}
        onMouseLeave={onShelveButtonLeave}
        size={CONDITION_UNDERLAY_BUTTON_SIZE}
      />
    </StaticTooltip>
  );
});
