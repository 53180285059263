import _ from 'lodash';
import { Identify } from '@amplitude/analytics-browser';
import amplitude from './amplitude';

// TODO: RND-1407 - Turn back on appcues
// import { trackEvent as trackAppcuesEvent } from './appcues';

import { influx, InfluxField } from './influx';
import { SimpleStats } from './noteMeshingStats';
import { APIOrganization, ModKeys } from '../@types/state';
import { URLSearchParamsToObject } from '../utils';
import { CopyMethod } from '../utils/clipboard';

declare global {
  interface Window {
    regardEncodedPatientId?: string;
    regardEncounterId?: string;
    regardEncounterOrganization?: APIOrganization | null;
    regardEncounterType?: string | null;
    regardPatientId?: string;
  }
}

type Field = InfluxField;
type DataEventOptions<K> = {
  flush?: boolean;
  nonPHIKeys?: K[];
  tags?: K[];
};
type EventOptions = Omit<DataEventOptions<never>, 'tags'>;

let identifiedAmplitudeUserEncodedPatientId = false;
let identifiedAmplitudeUserOrganization = false;

type DataEventFn<T extends Record<string, Field>> = (data: T) => void;
// returns a function that will log to influx and amplitude
// `options.tags` can be used to control which keys in `data` are sent as tags
// instead of fields for InfluxDB
const getDataEventFn =
  <T extends Record<string, Field>>(
    event: string,
    options?: DataEventOptions<keyof T>
  ): DataEventFn<T> =>
  (data: T) => {
    const {
      regardEncodedPatientId: encodedPatientId,
      regardEncounterId: encounterId,
      regardEncounterOrganization: encounterOrganization,
      regardEncounterType: encounterType,
      regardPatientId: patientId,
    } = window;

    // --- Amplitude ---

    // 1. Identify user -- use different if clauses because this data is set in different places
    // 1.a. encodedPatientId
    if (!identifiedAmplitudeUserEncodedPatientId && encodedPatientId) {
      const id = new Identify();
      id.set('encodedPatientId', encodedPatientId);
      amplitude.identify(id);
      identifiedAmplitudeUserEncodedPatientId = true;
    }
    // 1.b. organizationName
    if (!identifiedAmplitudeUserOrganization && encounterOrganization?.name) {
      const id = new Identify();
      id.set('organizationName', encounterOrganization.name);
      amplitude.identify(id);
      identifiedAmplitudeUserOrganization = true;
    }

    // 2. Log event
    const nonPHIData = options?.nonPHIKeys ? _.pick(data, options.nonPHIKeys) : data;
    amplitude.logEvent(event, nonPHIData);

    // --- Influx ---
    //
    // Influx has separate concepts of tags and fields. tags are indexed,
    // and can be used to improve query performance. However, high cardinality
    // tags can lead to memory bloat.
    //
    // - Do NOT include PHI data in tags. It is both high cardinality
    //   and is not sanitized by the backend before forwarding it on to InfluxDB.
    // - Likewise, ONLY include PHI in fields if it is hashed by the backend:
    //   it must be specifically listed in PHI_KEYS in automed/metrics/influx/sanitizer.py.

    const fields = {
      // Fields are part of the actual data and aren't indexed.
      // They are good for values that change often or don't fit well as labels
      ...(options?.tags ? _.omit(data, options.tags) : data),
      ...(encounterId ? { encounterId } : undefined),
      ...(patientId ? { patientId } : undefined),
    };
    const tags = {
      // tags are indexed and useful for filtering/grouping
      ...(options?.tags ? _.pick(data, options.tags) : {}),
      ...(encounterType ? { encounterType } : undefined),
      ...(encounterOrganization?.resource_id
        ? { organizationId: encounterOrganization.resource_id }
        : undefined),
      ...(encounterOrganization?.name
        ? { organizationName: encounterOrganization.name }
        : undefined),
    };
    influx.send(event, fields, tags);

    if (options?.flush) {
      influx.flush();
    }

    // TODO: RND-1407 - Turn back on appcues
    // --- Appcues ---
    // const appcuesData = {
    //   ...(options?.tags ? _.omit(data, options.tags) : data),
    //   ...(encounterId ? { encounterId } : undefined),
    //   ...(patientId ? { patientId } : undefined),
    // };
    // trackAppcuesEvent(event, appcuesData);
  };

type EventFn = () => void;
const getEventFn =
  (event: string, options?: EventOptions): EventFn =>
  () =>
    getDataEventFn(event, options)({});

export const clickedAddDxButton: DataEventFn<{
  atIndex: number;
}> = getDataEventFn('Clicked Add Dx Button');

export const clickedCopyDxButton: DataEventFn<{
  atIndex: number;
  modules: string[];
  copiedFromShelf: boolean;
}> = getDataEventFn('Clicked Copy Dx Button');

export const clickedDismissDxButton: DataEventFn<{
  atIndex: number;
  modules: string[];
  dismissedFromShelf: boolean;
}> = getDataEventFn('Clicked Dismiss Dx Button');

export const clickedShelveDxButton: DataEventFn<{
  atIndex: number;
  modules: string[];
}> = getDataEventFn('Clicked Shelve Dx Button');

export const clickedToggleDxSuggestionsButton: DataEventFn<{
  atIndex: number;
  modules: string[];
  isExpanding: boolean;
}> = getDataEventFn('Clicked Toggle Dx Suggestions Button');

// useMoveCondition.ts
export const movedCondition: DataEventFn<{
  direction: 'up' | 'down';
  modules: string[];
  fromIndex: number;
  toIndex: number;
  firstModule: string;
  movedIntoShelf: boolean;
  movedOutOfShelf: boolean;
}> = getDataEventFn('Moved Condition');

// notifications.tsx
export const failedToLoadData: DataEventFn<{
  missingData: string;
}> = getDataEventFn('Failed To Load Some Data');

// --- dismissedComplaints.ts ---
export const conditionRestored: DataEventFn<{
  module: string;
  uiElement: 'restore button' | 'textarea';
}> = getDataEventFn('Condition Restored', {
  tags: ['module'],
});

export const conditionCopied: DataEventFn<{
  copiedFromShelf: boolean;
  module: string;
  uiElement: 'copy dx button';
}> = getDataEventFn('Condition Copied', {
  tags: ['module'],
});

export const conditionDismissed = ({
  module,
  uiElement,
  dismissedFromShelf,
}: {
  module: string;
  uiElement: 'dismiss button' | 'textarea';
  dismissedFromShelf: boolean;
}) => {
  const getDismissalType = (m: string): 'new' | 'matched' | 'liveDX' => {
    if (window.newConditions?.has(m)) return 'new';
    if (window.matchedConditions?.has(m)) return 'matched';
    return 'liveDX';
  };
  const conditionDismissedAsType = getDataEventFn('Condition Dismissed', {
    tags: ['module', 'type'],
  });
  conditionDismissedAsType({
    module,
    uiElement,
    dismissedFromShelf,
    type: getDismissalType(module),
  });
};

export const conditionShelved = ({
  module,
  uiElement,
}: {
  module: string;
  uiElement: 'move button' | 'shelve button' | 'textarea';
}) => {
  const getShelvedType = (m: string): 'new' | 'matched' | 'liveDX' => {
    if (window.newConditions?.has(m)) return 'new';
    if (window.matchedConditions?.has(m)) return 'matched';
    return 'liveDX';
  };
  const conditionShelvedAsType = getDataEventFn('Condition Shelved', {
    tags: ['module', 'type'],
  });
  conditionShelvedAsType({ module, uiElement, type: getShelvedType(module) });
};

export const shelfDividerEdited: DataEventFn<{
  html: HtmlString;
  text: string;
}> = getDataEventFn('Shelf Divider Edited', { nonPHIKeys: ['text'] });

export const bulletAdded: DataEventFn<{
  inShelf: boolean;
  module: string;
  bulletId: string;
  uiElement: 'bullet monitor' | 'dx details' | 'textarea';
}> = getDataEventFn('Bullet Added', { tags: ['module', 'bulletId'] });
export const bulletRestored: DataEventFn<{
  inShelf: boolean;
  module: string;
  bulletId: string;
}> = getDataEventFn('Bullet Restored from Dx Details', { tags: ['module', 'bulletId'] });
export const bulletEditedOrDeleted: DataEventFn<{
  inShelf: boolean;
  module: string;
  bulletId: string;
}> = getDataEventFn('Bullet Edited or Deleted from textarea', { tags: ['module', 'bulletId'] });
export const bulletHidden: DataEventFn<{
  inShelf: boolean;
  module: string;
  bulletId: string;
  uiElement: 'bullet monitor' | 'textarea';
}> = getDataEventFn('Bullet Hidden', { tags: ['module', 'bulletId'] });
export const ignoredBullet = ({ bullets }: { bullets: { module: string; bulletId: string }[] }) =>
  // sends one event for each bullet; this makes querying the data simpler
  //  compared to passing an array with combined module|bulletId field and not
  //  leveraging tags.
  bullets.forEach((bullet) =>
    getDataEventFn<{
      module: string;
      bulletId: string;
    }>('Ignored Bullet', { tags: ['module', 'bulletId'] })(bullet)
  );

// --- regardNote.ts ---
export const failedToReceiveHTDXsAndPatientNotes: EventFn = getEventFn(
  'Failed To Receive HT DXs And Patient Notes'
);
export const totalLoadTime: DataEventFn<{ milliseconds: number }> =
  getDataEventFn('Total Load Time');
export const requestingHTDXsAndPatientNotesfromAPI: EventFn = getEventFn(
  'Requesting HT DXs And Patient Notes from API'
);

type LoadedHTApplicationProps = Partial<typeof window.encrypted> &
  SimpleStats & {
    baseNoteType: string;
    milliseconds: number;
    numberOfAvailableBaseNotes: number;
    timestamp: ISODateString | undefined;
    unspecifiedDXes: string[];
    viewportHeight: number;
    viewportWidth: number;
  };

export const loadedHTApplication = ({
  stats,
  ...rest
}: {
  baseNoteType: string;
  milliseconds: number;
  numberOfAvailableBaseNotes: number;
  stats: SimpleStats;
  timestamp: ISODateString | undefined;
  unspecifiedDXes: string[];
}) =>
  getDataEventFn<LoadedHTApplicationProps>('Loaded HT Application')({
    ...rest,
    ...stats,
    viewportHeight: document.documentElement.clientHeight,
    viewportWidth: document.documentElement.clientWidth,
  });

let interacted = false;
const firstUserInteraction: DataEventFn<{
  type: string;
}> = getDataEventFn('First User Interaction', { tags: ['type'] });
export const firstUserInteractionGated = (type: string) => {
  if (!interacted) {
    firstUserInteraction({ type });
    interacted = true;
  }
};

// Event fired once the first time the note is ready to render
export const noteReady: EventFn = getEventFn('Note Ready');

export const openedDXDetails: DataEventFn<{
  hasUpdatedHiddenBullets: boolean;
  detailsOpenedFor: string;
  location: 'titleButton' | 'titleKeyword';
  modulesString: string; // should be string[] with ',', eg. ['1', '2'] => '1,2'
  selectedModule: string;
}> = getDataEventFn('Opened DX Details', { tags: ['location', 'modulesString', 'selectedModule'] });

export const selectedDxDetailsTab: DataEventFn<{
  selectedModule: string;
}> = getDataEventFn('Selected Dx Details Tab', { tags: ['selectedModule'] });

// --- noteMeshing.ts ---
export const specifyModule = (name: string, module: string) =>
  getDataEventFn('Specified Condition Title')({
    name,
    module,
  });

// NoteContainer.tsx
export const selectedAndCopiedTextInNoteEditor: EventFn = getEventFn(
  'Selected and Copied Text in Note Editor'
);
export const noteCopied = (
  copyStats: Record<string, Field>,
  includePhysicalExam: boolean,
  modKeys: ModKeys,
  copyMethod: CopyMethod
) =>
  getDataEventFn('Note Copied', {
    flush: true, // really important that we capture this event
  })({
    ...copyStats,
    includePhysicalExam,
    modKeyShift: modKeys.shift,
    modKeyCtrl: modKeys.ctrl,
    modKeyAlt: modKeys.alt,
    copyMethod,
  });
export const formatNote: DataEventFn<{
  format: string;
}> = getDataEventFn('Format Titles');
export const redo: EventFn = getEventFn('Redo');
export const undo: EventFn = getEventFn('Undo');
export const bold: EventFn = getEventFn('Bold');
export const italic: EventFn = getEventFn('Italic');
export const underline: EventFn = getEventFn('Underline');
export const clickedCopyThenClickedViewNewDxs: EventFn = getEventFn(
  "Clicked Copy, Then Clicked 'View new Dxs'"
);
export const clickedCopyCopiedWithoutViewingAllNewDxs: EventFn = getEventFn(
  'Clicked Copy, Copied Without Viewing All New Dxs'
);
export const syncTextDiffAboveThreshold: DataEventFn<{ numCharactersDifferent: number }> =
  getDataEventFn('RTE sync had difference');
export const syncTimeAboveThreshold: DataEventFn<{ timeToSync: number }> = getDataEventFn(
  'RTE sync time above threshold'
);
export const clickedClearNoteDraft: EventFn = getEventFn('Clicked clear note draft');

// Notification.tsx
export const closedNotification: DataEventFn<{
  name: string;
}> = getDataEventFn('Closed Notification');

// ComplaintTableSection.jsx
export const closedTableMenu: DataEventFn<{
  label: string;
  module: string;
}> = getDataEventFn('Closed Table Menu');
export const openedTableMenu: DataEventFn<{
  label: string;
  module: string;
}> = getDataEventFn('Opened Table Menu');
export const selectedTableMenu: DataEventFn<{
  label: string;
  option: string;
  module: string;
}> = getDataEventFn('Selected Table Menu Option');
export const unselectedTableMenu: DataEventFn<{
  label: string;
  option: string;
  module: string;
}> = getDataEventFn('Unselected Table Menu Option');

// --- measure/index.ts ---
export const measuredRenderDuration: DataEventFn<{
  throttle: number;
  delay: number;
  process: number;
  render: number;
  totalWithoutThrottle: number;
  first: boolean;
  typingCase: string;
}> = getDataEventFn('Measured Render Duration');

// --- PhysicalExamContainer.ts ---
export const clickedHidePhysicalExam: EventFn = getEventFn('Clicked Hide Physical Exam Area');
export const clickedExpandPhysicalExam: EventFn = getEventFn('Clicked Expand Physical Exam Area');
export const clickedIncludePhysicalExamText: EventFn = getEventFn(
  'Clicked Include Physical Exam Text'
);
export const clickedExcludePhysicalExamText: EventFn = getEventFn(
  'Clicked Exclude Physical Exam Text'
);

// --- ComplaintTimeline.jsx ---
export const clickedTimeline: DataEventFn<{ date: Date }> = getDataEventFn('Clicked Timeline');

// --- TabPanel ---
export const clickedRefreshSidePanel: EventFn = getEventFn('Clicked Refresh (Side Panel)', {
  flush: true, // since we're about to reload the page
});
export const clickedTabPanelTab: DataEventFn<{
  name: string;
  module: string;
}> = getDataEventFn('Clicked Tab');
export const openedResourcesPanel: EventFn = getEventFn('Opened Help Panel'); // leaving this event as "help" to keep events aligned
export const openedUserFeedbackForm: EventFn = getEventFn('Opened User Feedback Form');
export const openedDxProcessDrawerViaResourcePanel: DataEventFn<{ title: string }> = getDataEventFn(
  'Opened Dx Process Drawer via Resource Panel'
);
export const openedDxProcessDrawerViaDxDetails: DataEventFn<{ title: string }> = getDataEventFn(
  'Opened Dx Process Drawer via Dx Details'
);

// --- CYOB (Choose Your Own Base Note) ---
export const clickedChangeBaseNote: EventFn = getEventFn('Clicked Change Base Note');

export const rejectedLosingEditsToChangeBaseNote: EventFn = getEventFn(
  'Rejected Losing Edits to Change Base Note'
);

const measuredTimeToChangeBaseNote: DataEventFn<{
  milliseconds: number;
  resourceId: string;
}> = getDataEventFn('Measured Time to Change Base Note', {
  nonPHIKeys: ['milliseconds'],
});

let changeBaseNoteStart: DateNumberValue = 0;
let changeBaseNoteResourceId = '';
export const startTimeToChangeBaseNote = ({ resourceId }: { resourceId: string }) => {
  changeBaseNoteResourceId = resourceId;
  changeBaseNoteStart = new Date().valueOf();
};
export const stopTimeToChangeBaseNote = () => {
  // This pattern is exposed to race conditions, but this shouldn't happen as
  //  long as the user can't initiate a second change base note action while
  //  the first one is in progress; in that case, the first change would report
  //  the information of the second change when the first change completed.
  if (changeBaseNoteStart && changeBaseNoteResourceId) {
    const milliseconds = new Date().valueOf() - changeBaseNoteStart;

    measuredTimeToChangeBaseNote({
      milliseconds,
      resourceId: changeBaseNoteResourceId,
    });
    changeBaseNoteStart = 0;
    changeBaseNoteResourceId = '';
  }
};

// NOTE: The shape for this event's data will be Record<string, string>.
export const failedToReceiveHistoricalProps = (params: URLSearchParams) =>
  getDataEventFn('Failed to Receive Historical Props')(URLSearchParamsToObject(params));

export const clickedRefreshNoteHeader: EventFn = getEventFn('Clicked Refresh (Note Header)', {
  flush: true, // since we're about to reload the page
});

export const clickedRefreshFromError: EventFn = getEventFn('Clicked Refresh From Error Page', {
  flush: true, // since we're about to reload the page
});

// ///////////////////
// --- Max Chat --- //
// ///////////////////

export const chatStarted = (args: {
  chatId: string;
  model: string;
  promptId: string;
  promptType: string;
}) => amplitude.logEvent('Chat - Started', args);

export const chatPromptInfo = (args: { chatId: string; excluded: object; included: object }) =>
  amplitude.logEvent('Chat - Prompt Info', args);

export const chatAiResponded = (args: { chatId: string; responseIndex: number }) =>
  amplitude.logEvent('Chat - AI Responded', args);

export const chatUserPrompted = (args: { chatId: string; promptIndex: number }) =>
  amplitude.logEvent('Chat - User Prompted', args);

export const chatCopiedAiResponse = (args: {
  chatId: string;
  responseIndex: number;
  responseType: 'clickedCopyButton';
}) => amplitude.logEvent('Chat - Copied AI Response', args);

export const chatLikedAiResponse = (args: { chatId: string; responseIndex: number }) =>
  amplitude.logEvent('Chat - Liked AI Response', args);

export type DislikedAiResponseSurvey = {
  dislikedDescription: string;
  dislikedReasonInaccurate: boolean;
  dislikedReasonMissingImportantInformation: boolean;
  dislikedReasonNotUseful: boolean;
  dislikedReasonPoorlyWorded: boolean;
};

export const chatDislikedAiResponse = (
  args: DislikedAiResponseSurvey & {
    chatId: string;
    responseIndex: number;
  }
) =>
  amplitude.logEvent(
    'Chat - Disliked AI Response',
    _.pick(
      args,
      // these are guaranteed to not contain PHI
      [
        'chatId',
        'dislikedReasonInaccurate',
        'dislikedReasonMissingImportantInformation',
        'dislikedReasonNotUseful',
        'dislikedReasonPoorlyWorded',
        'responseIndex',
      ]
    )
  );

// //////////////////
// --- Note UX --- //
// //////////////////

export const mergedNoteBlock: DataEventFn<{
  direction: 'down' | 'up';
  fromModules: string[];
  toModules: string[];
}> = getDataEventFn('Merged Note Block', {
  nonPHIKeys: ['direction', 'fromModules', 'toModules'],
});

export const splitNoteBlock: DataEventFn<{
  isSplitOnNewlineEnabled: boolean;
  modules: string[];
}> = getDataEventFn('Split Note Block', {
  nonPHIKeys: ['isSplitOnNewlineEnabled', 'modules'],
});

export const noteMeshed: DataEventFn<{
  numberOfTitleLines: number;
  noteResourceId: string;
  isSplitOnNewlineEnabled: boolean;
  noteHasHeader: boolean;
}> = getDataEventFn('Note Meshed', {
  nonPHIKeys: ['isSplitOnNewlineEnabled', 'noteHasHeader'],
});
