import { theme } from '../../../../reuse';

export const iconStyle = {
  position: 'relative',
  top: 3,
} as const;

export const saveDraftNoteStatusStyle = {
  color: theme.colors.grey3,
  fontSize: 12,
  fontWeight: 400,
  marginLeft: 1,
} as const;
