import { LINE_DIVIDER_TAG } from '~/app/constants';

import {
  CharacterItem,
  TagNode,
  TextNode,
  HtmlTree,
  KeywordNode,
  CharacterItemTagType,
  HtmlTreeNodeType,
} from './types';
import { tagsAreEqual } from './characterItemTagsAreEqual';
import { findIndex, getRawHtml, getRawOriginalHtml } from './utils';

const EMPTY_TREE: HtmlTree = [
  {
    type: HtmlTreeNodeType.TAG,
    tag: LINE_DIVIDER_TAG,
    children: [{ type: HtmlTreeNodeType.TEXT, text: '' }],
  },
];

interface ToTreeOptions {
  // The start index of the items that's being checked. This allows the same
  // array to be passed down recursively.
  startIndex: number;
  // The end index of the items being checked.
  endIndex: number;
  // The current level of recursion and tag to check to prevent having
  // to slice up and modify the tag arrays.
  tagIndex: number;
}

/**
 * Merges a character items array into a tree of nodes representing the
 * contents of a condition title.
 * @param {ToTreeOptions} options Used internally for recursion to allow characterItems to be
 *   passed down without the performance hit of slicing it.
 */
export const characterItemsToTree = (
  characterItems: CharacterItem[],
  options?: ToTreeOptions
): HtmlTree => {
  if (!characterItems?.length) {
    return EMPTY_TREE;
  }

  const nodes: HtmlTree = [];
  const startIndex = options?.startIndex ?? 0;
  const endIndex = options?.endIndex ?? characterItems.length;
  const tagIndex = options?.tagIndex ?? 0;

  let startOfAdjacentCharactersIndex = startIndex;

  // Find adjacent nodes that all have the same next tag
  while (startOfAdjacentCharactersIndex < endIndex) {
    const nextTag = characterItems[startOfAdjacentCharactersIndex].tags[tagIndex];

    const indexOfFirstCharacterWithoutNextTag = findIndex(
      characterItems,
      (item) => !tagsAreEqual(item.tags[tagIndex], nextTag),
      startOfAdjacentCharactersIndex,
      endIndex
    );

    const allRemainingCharactersHaveTag = indexOfFirstCharacterWithoutNextTag === -1;

    const nextStartIndex = startOfAdjacentCharactersIndex;
    const nextEndIndex = allRemainingCharactersHaveTag
      ? endIndex
      : indexOfFirstCharacterWithoutNextTag;

    startOfAdjacentCharactersIndex = allRemainingCharactersHaveTag
      ? endIndex
      : indexOfFirstCharacterWithoutNextTag;

    if (nextTag && nextTag.type === CharacterItemTagType.TAG) {
      // Case: The next tag is a DOM element
      const node: TagNode = {
        type: HtmlTreeNodeType.TAG,
        tag: nextTag.tag,
        children: characterItemsToTree(characterItems, {
          startIndex: nextStartIndex,
          endIndex: nextEndIndex,
          tagIndex: tagIndex + 1,
        }),
      };
      nodes.push(node);
    } else if (nextTag && nextTag.type === CharacterItemTagType.KEYWORD) {
      // Case: The next tag is a keyword element
      const node: KeywordNode = {
        type: HtmlTreeNodeType.KEYWORD,
        keyword: nextTag.keyword,
        children: characterItemsToTree(characterItems, {
          startIndex: nextStartIndex,
          endIndex: nextEndIndex,
          tagIndex: tagIndex + 1,
        }),
      };
      nodes.push(node);
    } else {
      // Case: There's no supported tag, so treat as a text node
      const node: TextNode = {
        type: HtmlTreeNodeType.TEXT,
        text: getRawHtml(characterItems, nextStartIndex, nextEndIndex),
        originalText: getRawOriginalHtml(characterItems, nextStartIndex, nextEndIndex),
      };

      nodes.push(node);
    }
  }

  return nodes;
};
