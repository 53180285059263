import { FC, memo } from 'react';

import { ConditionBackground } from '../conditionBackground';
import { CONDITION_UNDERLAY_BUTTONS_BORDER_RADIUS } from '../const';

const defaultStyle = CONDITION_UNDERLAY_BUTTONS_BORDER_RADIUS;

export const ConditionButtonsBackground: FC = memo(() => (
  <ConditionBackground defaultStyle={defaultStyle} />
));
ConditionButtonsBackground.displayName = 'ConditionButtonsBackground';
