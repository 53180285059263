import _ from 'lodash';
import { FC, memo } from 'react';

import { ConditionBackground } from '../conditionBackground';
import { theme } from '../../../reuse';

type LineStyle = {
  borderTopColor: string;
  borderTopStyle: 'solid';
  borderTopWidth: 1 | 0;
};

const getStyle = _.memoize(
  ({
    isFirstLine,
  }: {
    isFirstLine: boolean;
  }): {
    buttonHoveredStyle: LineStyle;
    hoverStyle: LineStyle;
  } => {
    const borderTopStyle = 'solid';
    const borderTopWidth = isFirstLine ? 1 : 0;

    return {
      buttonHoveredStyle: {
        borderTopColor: theme.colors.blueRegular,
        borderTopStyle,
        borderTopWidth,
      },
      hoverStyle: {
        borderTopColor: theme.colors.grey4,
        borderTopStyle,
        borderTopWidth,
      },
    };
  }
);

export const ConditionLineBackground: FC<{
  isFirstLine: boolean;
}> = memo(({ isFirstLine }) => {
  const { buttonHoveredStyle, hoverStyle } = getStyle({
    isFirstLine,
  });

  return <ConditionBackground buttonHoveredStyle={buttonHoveredStyle} hoverStyle={hoverStyle} />;
});
ConditionLineBackground.displayName = 'ConditionLineBackground';
