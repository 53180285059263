import { FC } from 'react';
import { memoize } from 'lodash';
import { COMPANY_SUPPORT_EMAIL } from '~/app/constants';
import MedicalSVG from '~/app/images/medical.svg';
import { theme, Button } from '..';

const getContainerStyle = memoize((inline?: boolean) =>
  inline
    ? ({ textAlign: 'center', margin: 16 } as const)
    : ({
        backgroundColor: theme.colors.grey6,
        height: '100%',
        textAlign: 'center',
        fontFamily: theme.font.sans,
        fontSize: '14px',
        lineHeight: 1.5,
      } as const)
);

export const getMessageContainerStyle = memoize((inline?: boolean) =>
  inline
    ? ({
        backgroundColor: theme.colors.white,
        borderRadius: theme.other.borderRadius4,
        boxShadow: theme.other.dropShadow1,
        maxWidth: 460,
        padding: 12,
        margin: '0 auto',
      } as const)
    : ({
        backgroundColor: theme.colors.white,
        borderRadius: theme.other.borderRadius4,
        boxShadow: theme.other.dropShadow1,
        display: 'inline-block',
        maxWidth: 520,
        padding: 48,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
      } as const)
);

const iconStyle = {
  fill: theme.colors.blueRegular,
} as const;

export const getHeaderTextStyle = memoize(
  (inline?: boolean) =>
    ({
      color: theme.colors.blueRegular,
      fontSize: 18,
      lineHeight: 1.25,
      margin: inline ? 0 : '20px 0',
    } as const)
);

const getButtonStyle = memoize(
  (inline?: boolean) =>
    ({
      marginTop: inline ? 4 : 12,
    } as const)
);

interface DefaultErrorFallback {
  inline?: boolean;
  resetError: () => void;
}

export const DefaultErrorFallback: FC<DefaultErrorFallback> = ({ inline, resetError }) => (
  <div style={getContainerStyle(inline)}>
    <div style={getMessageContainerStyle(inline)}>
      {!inline && <MedicalSVG alt="Medical" style={iconStyle} />}
      <h1 style={getHeaderTextStyle(inline)}>Something went wrong</h1>
      <p>
        The application has encountered an unexpected error.
        <br />
        If the problem persists, try reloading the page or <br />
        contact us at{' '}
        <a href={`mailto:${COMPANY_SUPPORT_EMAIL}`} style={{ textDecoration: 'none' }}>
          {COMPANY_SUPPORT_EMAIL}
        </a>
        .
      </p>
      <Button color="primary" onClick={resetError} size="standard" style={getButtonStyle(inline)}>
        Continue
      </Button>
    </div>
  </div>
);

DefaultErrorFallback.displayName = 'DefaultErrorFallback';
