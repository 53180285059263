import _ from 'lodash';

import {
  ChunkOfLines,
  IndexingData,
  Keyword,
  KeywordMatches,
  ModuleMatches,
  TitleLine,
} from '../../../@types/state';
import { findKeywordMatchesInTitleText } from './findKeywordMatchesInTitleText';
import { generateKeywords } from '../generateKeywords';
import { get } from '../../../utils';

type AssignedChunkMatchingData = {
  modules: string[];
  primaryMatches: KeywordMatches;
  secondaryMatches: KeywordMatches;
  spaceJoinedTitleLineText: string;
};

// Count total modules a title (or stacked title) is matched to. Used for prioritizing matches
const numTotalKeywordMatches = ({ primaryMatches, secondaryMatches }: ModuleMatches) =>
  Object.keys(primaryMatches).length + Object.keys(secondaryMatches).length;

// This is a key function: it takes a `ChunkWithLines[]` (at minimum) from
//  `meshNotes` and `indexNote` and parses each chunk title for Regard
//  condition keywords.
//
// Once it finds all the primary matches (condition keywords before a
//  `NEGATOR`) and secondary matches (condition keywords found _after_ a
//  `NEGATOR`), it finds the best chunk-title-to-regard-condition match as
//  such:
// 1. The note is parsed top to bottom. Generally the first condition keyword
//  found for a regard condition becomes the mapping keyword.
// 2. The above rule is broken if multiple regard conditions map to different
//  words in a title. If a title with a single match is found further down
//  the note like "# aki", that would override a higher-up match like "# dm2 w/
//  aki"
export const matchChunkTitlesToModulesAndDeleteDuplicates = <
  T extends Pick<ChunkOfLines, 'lines'>
>({
  allModuleMatchesByTitle,
  conditionKeywords,
  conditionQualifiers,
  conditionsByModule,
  exactMatchRegardTitlesInNoteByModule,
  chunks,
}: Pick<
  IndexingData,
  | 'allModuleMatchesByTitle'
  | 'conditionKeywords'
  | 'conditionQualifiers'
  | 'conditionsByModule'
  | 'exactMatchRegardTitlesInNoteByModule'
> & {
  chunks: T[];
}): {
  chunksWithModulesAndKeywords: (T & { modules: string[]; keywords: Keyword[] })[];
  allModuleMatchesByTitleUpdates: Record<string, ModuleMatches>;
} => {
  // This is used by the matching algorithm to keep track of which chunk-title
  // is the best match for each Regard condition
  const bestMatches: Record<string, AssignedChunkMatchingData & { chunk: T }> = {};

  // This maps title captions we match to condition keywords
  // it's used as a cache in later runs of this function
  const allModuleMatchesByTitleUpdates: Record<string, ModuleMatches> = {};

  const assignedChunkOrNulls = chunks.map((chunk) => {
    const { lines } = chunk;

    // NO MAPPING modules for this chunk if:
    //  1. There is no title in the chunk
    //  2. The title is the text is too short
    //  3. or the title text matches to footer keywords
    const titleLines = lines.filter((l) => l.type === 'title') as TitleLine[];
    const allTitlesTooShortToMatch =
      titleLines.length &&
      titleLines.every(
        ({ plainTextWithoutTitleSignifier }) => plainTextWithoutTitleSignifier.length < 2
      );

    // merge all title lines together with space characters so we have 1 string to match keywords with
    const spaceJoinedTitleLineText = titleLines
      .map(({ plainTextWithoutTitleSignifier }) => plainTextWithoutTitleSignifier)
      .join(' ');

    // quick exit
    if (titleLines.length === 0 || allTitlesTooShortToMatch) {
      return {
        chunk,
        modules: [],
        primaryMatches: {},
        secondaryMatches: {},
        spaceJoinedTitleLineText,
      };
    }

    // see if the title text matches a previous mapping attempt stored in cache
    const cachedModuleKeywordMatches = allModuleMatchesByTitle[spaceJoinedTitleLineText];
    const moduleKeywordMatches = cachedModuleKeywordMatches ?? {
      primaryMatches: {},
      secondaryMatches: {},
    };

    if (!cachedModuleKeywordMatches) {
      // If there is no exact match in the cache, try to find condition keywords in the title
      const keywordMatches = findKeywordMatchesInTitleText(
        spaceJoinedTitleLineText,
        conditionKeywords
      );

      Object.assign(moduleKeywordMatches, keywordMatches);

      // Add mapping attempt to title cache
      allModuleMatchesByTitleUpdates[spaceJoinedTitleLineText] = keywordMatches;
    }

    const matchedHtmlChunk: AssignedChunkMatchingData & { chunk: T } = {
      chunk,
      ...moduleKeywordMatches,
      modules: [],
      spaceJoinedTitleLineText,
    };

    /* Now we use the primaryModules array, and the position of the titles
     * because we are for-looping down the note by chunk, to determine
     * which title is the BEST match for each Regard condition we found. */
    const { primaryMatches, secondaryMatches } = moduleKeywordMatches;

    const conditionNameMatchesTuple = Object.entries(primaryMatches);
    const sortedConditionNameMatchesTuple = conditionNameMatchesTuple.sort(
      ([, matchA], [, matchB]) => matchA.captionIndex - matchB.captionIndex
    );
    const primaryConditionNames = sortedConditionNameMatchesTuple.map(
      ([conditionName]) => conditionName
    );

    for (let i = 0; i < primaryConditionNames.length; i++) {
      const conditionName = primaryConditionNames[i];
      const bestMatchForModule = bestMatches[conditionName];

      if (!bestMatchForModule) {
        matchedHtmlChunk.modules.push(conditionName);
        bestMatches[conditionName] = matchedHtmlChunk;
      } else {
        if (
          lines.length === 1 &&
          lines[0].type === 'title' &&
          exactMatchRegardTitlesInNoteByModule[conditionName] ===
            lines[0].plainTextWithoutTitleSignifier
        )
          return null;
        if (
          (primaryConditionNames.length === 1 &&
            Object.keys(bestMatchForModule.primaryMatches).length > 1) ||
          numTotalKeywordMatches({ primaryMatches, secondaryMatches }) <
            numTotalKeywordMatches(bestMatchForModule)
        ) {
          matchedHtmlChunk.modules.push(conditionName);
          bestMatches[conditionName] = matchedHtmlChunk;
          const spliceLoc = bestMatchForModule.modules.indexOf(conditionName);
          if (spliceLoc >= 0) bestMatchForModule.modules.splice(spliceLoc, 1);
        }
      }
    }

    return matchedHtmlChunk;
  });

  const assignedChunks = _.compact(assignedChunkOrNulls);

  // Now that we have matched all the primaryMatches, we see which Regard condtions are still
  // unmapped and loop through secondaryMatches to try to match those
  const allConditionNames = Object.keys(conditionKeywords);
  const conditionNameIsUnmapped = (conditionName: string) => !bestMatches[conditionName];
  const unmatchedRegardModules = allConditionNames.filter(conditionNameIsUnmapped);

  const chunksWithModulesAndKeywords = assignedChunks.map(
    ({ modules, primaryMatches, secondaryMatches, spaceJoinedTitleLineText, chunk }) => {
      // 1. Check if this chunk has secondaryMatches for any unmatched regard modules
      Object.keys(secondaryMatches).forEach((conditionName) => {
        const indexOfUnmappedConditionName = unmatchedRegardModules.indexOf(conditionName);
        if (indexOfUnmappedConditionName >= 0) {
          modules.push(conditionName);
          unmatchedRegardModules.splice(indexOfUnmappedConditionName, 1);
        }
      });

      // 2. Sort the modules array now that final matching is done
      const captionPos = (m: string) => {
        const match = primaryMatches[m] || secondaryMatches[m];
        return match ? match.captionIndex : Infinity;
      };
      const sortedModules = modules.sort((m1, m2) => captionPos(m1) - captionPos(m2));

      // 3. Use match data to generate keywords
      const keywords = modules.length
        ? generateKeywords({
            modules,
            getTitleTextMatchForModule: (m: string) =>
              (get(primaryMatches, m) ?? get(secondaryMatches, m))?.keyword ?? '',
            conditionsByModule,
            conditionQualifiers,
            titleText: spaceJoinedTitleLineText,
          })
        : [];

      return {
        ...chunk,
        modules: sortedModules,
        keywords,
      };
    }
  );

  return {
    chunksWithModulesAndKeywords,
    allModuleMatchesByTitleUpdates,
  };
};
