import { SummarySpanB, SummaryUl, SummaryOl } from './common';

export const phosphateSummary = (
  <SummaryOl>
    <li>
      Diagnose <SummarySpanB>hyperphosphatemia</SummarySpanB> or{' '}
      <SummarySpanB>hypophosphatemia</SummarySpanB> based on abnormal serum phosphorus labs from the
      current encounter or hyperphosphatemia or hypophosphatemia indicated in notes or problem list
      from the current encounter.
    </li>
    <li>
      Determine acuity:
      <SummaryUl innerList>
        <li>
          If a note specifies acute <SummarySpanB>or</SummarySpanB> abnormal lab is found, the
          condition is considered acute
        </li>
        <li>
          If a note specifies chronic, it is considered chronic
          <SummaryUl innerList>
            <li>If evidence of both are present, the condition is considered acute on chronic</li>
          </SummaryUl>
        </li>
      </SummaryUl>
    </li>
    <li>
      Determine trajectory
      <SummaryUl innerList>
        <li>
          If the most recent and second most recent serum phosphorus labs are available, abnormal,
          and if the time difference between both labs is 3 days, Regard will calculate the
          trajectory as worsening, improving, or stable.
        </li>
      </SummaryUl>
    </li>
    <li>
      Determine etiology
      <SummaryUl innerList>
        <li>
          Based on condition hyper/hypophosphatemia, the patient’s labs, past conditions (kidney
          disease, chemotherapy, malnutrition), and medications, Regard will recommend etiology.
        </li>
      </SummaryUl>
    </li>
  </SummaryOl>
);
