import { FC } from 'react';

import { MaxChatForm } from './maxChatForm';
import { MaxChatReplies } from './maxChatReplies';
import { MaxChatDislikeDialog } from './maxChatDislikeDialog';
import { useMeshedContext } from '../MeshedProvider';

const style = {
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
} as const;

export const MaxChat: FC = () => {
  const { isVerticalLayout } = useMeshedContext(({ isVerticalLayout }) => ({
    isVerticalLayout,
  }));

  return (
    <div style={style}>
      <MaxChatReplies />
      <MaxChatForm />
      {!isVerticalLayout && <MaxChatDislikeDialog />}
    </div>
  );
};
