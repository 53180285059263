import { CSSProperties, FC, ReactNode } from 'react';

import { DrawerBase } from '../drawerBase';
import { IconButton } from '../iconButton';

export type DrawerProps = {
  close(): void;
  content: ReactNode;
  enableCurtain: boolean;
  isOpen: boolean;
  style?: { curtain?: CSSProperties; drawer?: CSSProperties; header?: CSSProperties };
  title: ReactNode;
  zIndex: number;
};

const headerHeight = 42; // make dynamic when off IE10

const headerInnerStyle = {
  position: 'relative',
} as const;

const headerTitleStyle = {
  marginRight: 24,
  // START - Only needed when header is fixed height; see references in this file to IE10
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  // END
} as const;

const headerIconStyle = {
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

const contentStyle = {
  fontSize: 14,
  height: `calc(100% - ${headerHeight}px)`, // replace with flex when off IE10
  overflow: 'auto',
} as const;

export const Drawer: FC<DrawerProps> = ({
  close,
  content,
  enableCurtain,
  isOpen,
  style,
  title,
  ...rest
}) => (
  <DrawerBase close={close} enableCurtain={enableCurtain} isOpen={isOpen} style={style} {...rest}>
    <div style={style?.header}>
      <div style={headerInnerStyle}>
        <div style={headerTitleStyle}>{title}</div>
        <IconButton
          aria-label="close"
          color="tertiary-grey"
          iconName="x"
          onClick={close}
          size="mini"
          style={headerIconStyle}
        />
      </div>
    </div>
    <div style={contentStyle}>{content}</div>
  </DrawerBase>
);
Drawer.displayName = 'Drawer';
