import { PatientLoadDurations, PatientLoadPhases } from '~/app/@types';

const DEFAULT_PHASE_DESCRIPTION = 'Fetching patient data';

export const PHASE_DESCRIPTIONS: Record<PatientLoadPhases, string> = {
  STARTED: DEFAULT_PHASE_DESCRIPTION,
  FETCHING: DEFAULT_PHASE_DESCRIPTION,
  MAPPING: 'Analyzing patient data',
  DXING: 'Running diagnostic algorithms',
  LOADING: 'Generating Regard note',
};

export const LOADING_DESCRIPTIONS: Record<
  PatientLoadDurations,
  { title: string; description: string }
> = {
  LONG: {
    title: 'Regard is getting to know your patient for the first time...',
    description:
      'We’re gathering and thoroughly reviewing the patient’s medical history. This allows for faster future load times and provides robust clinical insights.',
  },
  MEDIUM: {
    title: 'Regard is updating your patient’s information...',
    description:
      'We haven’t seen this patient in a few days. We’re updating the patient insights with the latest information to optimize care decisions.',
  },
  SHORT: {
    title: 'Regard is loading your patient...',
    description: '',
  },
};
