import _ from 'lodash';
import { FC, Ref, forwardRef } from 'react';

type ButtonTagSubmitProps = Pick<JSX.IntrinsicElements['button'], 'onClick'> & {
  submit: true;
};

type ButtonTagNotSubmitProps = Required<Pick<JSX.IntrinsicElements['button'], 'onClick'>> & {
  submit?: false;
};

export type ButtonTagProps = Pick<
  JSX.IntrinsicElements['button'],
  | 'children'
  | 'className'
  | 'css'
  | 'disabled'
  | 'onBlur'
  | 'onFocus'
  | 'onMouseDown'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onMouseUp'
  | 'onTouchEnd'
  | 'onTouchMove'
  | 'onTouchStart'
  | 'style'
> & {
  ref?: Ref<HTMLButtonElement>;
} & (ButtonTagSubmitProps | ButtonTagNotSubmitProps);

const stringSpanStyle = { position: 'relative' } as const;

export const ButtonTag: FC<ButtonTagProps> = forwardRef(({ children, submit, ...rest }, ref) => (
  <button ref={ref} type={submit ? 'submit' : 'button'} {...rest}>
    {
      // Placing a child string inside a position: 'relative' span is a hack to prevent an IE10 behavior where the text inside the button shifts down and to the right while active.
      _.isString(children) ? <span style={stringSpanStyle}>{children}</span> : children
    }
  </button>
));
ButtonTag.displayName = 'ButtonTag';
