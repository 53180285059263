import { SummaryP, SummarySpanB } from './common';

export const hypomagnesemiaSummary = (
  <>
    <SummaryP first>
      Regard diagnoses hypomagnesemia based on an abnormally low magnesium lab in the current
      encounter <SummarySpanB>or</SummarySpanB> a prior note within the encounter stating the
      patient has hypomagnesemia.
    </SummaryP>
    <SummaryP>
      To determine <SummarySpanB>trajectory</SummarySpanB> we use a threshold of 0.3 mg/dL, if the
      labs are within 0.3 md/dL of one another then it is considered stable. Otherwise we look for
      an increase or decrease to determine the trajectory.
    </SummaryP>
    <SummaryP>
      <SummarySpanB>Acuity</SummarySpanB> is determined by the following. Acute is if the last lab
      is abnormal or an acute source was found. Chronic if a chronic source was found. Acute on
      chronic if both of them are true.
    </SummaryP>
  </>
);
