import Tippy from '@tippyjs/react';
import { FC } from 'react';

import { TooltipProps } from '../staticTooltip/types';

export type DynamicTooltipProps = Optional<TooltipProps, 'placement'>;

/**
 * Pros:
 * - Uses JS to position itself to always be visible.
 *
 * Cons:
 * - Slower.
 * - In IE10, the first render is out of position, so we have to introduce
 *   delays to hide the tooltip as it comes into view
 */
export const DynamicTooltip: FC<DynamicTooltipProps> = ({
  children,
  disabled,
  placement,
  tooltip,
  visible,
  ...rest
}) => (
  <Tippy
    animation={false}
    content={tooltip}
    delay={25}
    disabled={disabled}
    placement={placement}
    popperOptions={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        {
          name: 'arrow',
          options: {
            padding: 8,
          },
        },
      ],
    }}
    visible={visible}
    {...rest}
  >
    {children}
  </Tippy>
);
