import { APIConditionAssessmentPlanMed, ComplaintTableData } from '~/app/@types/state';
import { getSeriesKey } from './getSeriesKey';

export interface TableOption {
  show: boolean;
  label: string;
  formattedLabel: string;
  seriesId?: string;
  dose?: string;
  ordered?: Date | null;
  start?: Date | null;
  stop?: Date | null;
  status?: string;
  timing?: string;
  asNeeded?: string;
  isOnce(): boolean;
  isPrn(): boolean;
  isSuspended(): boolean;
}

export type TableOptions = Record<string, TableOption>;

export interface InitialStateOptions {
  labsOptions: TableOptions;
  vitalsOptions: TableOptions;
  medicationOptions: TableOptions;
}
export type OptionSection = keyof InitialStateOptions;

export type InitialState = InitialStateOptions & {
  leftMostVisibleDate: Date | null;
  highlightRowLabel: string | null;
  highlightColumnDate: Date | null;
};

export const getInitialState = (
  meds: APIConditionAssessmentPlanMed[],
  tableData?: ComplaintTableData
): InitialState => {
  const labsOptions = {};
  const vitalsOptions = {};
  if (tableData) {
    Object.keys(tableData.series).forEach((seriesId) => {
      const options: TableOptions =
        tableData.series[seriesId].section === 'lab' ? labsOptions : vitalsOptions;
      const optionKey = getSeriesKey(tableData.series[seriesId]);
      const { label } = tableData.series[seriesId];
      options[optionKey] = {
        show: true,
        label,
        formattedLabel: label,
        seriesId,
        isOnce: () => false,
        isPrn: () => false,
        isSuspended: () => false,
      };
    });
  }

  const medicationOptions: TableOptions = {};
  meds.forEach((med) => {
    const optionKey = med.key;
    const newTableOption: TableOption = {
      show: true,
      dose: med.dose,
      formattedLabel: med.formattedLabel,
      label: med.label,
      ordered: med.ordered ? new Date(med.ordered) : null,
      start: med.start ? new Date(med.start) : null,
      status: med.status,
      stop: med.stop ? new Date(med.stop) : null,
      timing: med.timing,
      asNeeded: med.as_needed,
      isOnce: () => !!(med.timing && med.timing.toLowerCase() === 'once'),
      isPrn: () => !!(med.as_needed || (med.timing && med.timing.toLowerCase().includes('prn'))),
      isSuspended: () => !!(med.status && med.status.toLowerCase().startsWith('sus')),
    };
    medicationOptions[optionKey] = newTableOption;
  });

  return {
    labsOptions,
    vitalsOptions,
    medicationOptions,
    leftMostVisibleDate: null,
    highlightRowLabel: null,
    highlightColumnDate: null,
  };
};
