import { isEqual } from 'date-fns';
import classNames from 'classnames';

import { formatDate } from './format';
import { TableOption } from './getInitialTableState';

/**
 * The following renders the labels, rows, and "pill bubbles" for medications
 * in the table.
 * First we create the label and hidden label.
 * Then in the `forEach` function we create a single (green) merged cell
 * where the colSpan represents the datetimes where the medication
 * was/is prescribed.
 */
export const generateMedRows = (
  medData: Record<string, TableOption>,
  timestamp: string,
  sortedDatetimes: Date[],
  scrollToDate: (date?: Date | null) => void,
  inCurrentEnc: (d: Date) => boolean
) => {
  const medIsActive = (m: TableOption) => !m.stop || m.stop > new Date(timestamp);

  const medSortingFunc = (a: string, b: string) => {
    const medA = medData[a];
    const medB = medData[b];

    if (medIsActive(medA) === medIsActive(medB)) {
      if (medA.isPrn() === medB.isPrn()) {
        return medA.formattedLabel < medB.formattedLabel ? -1 : 1;
      }
      return medA.isPrn() ? 1 : -1;
    }
    return medIsActive(medA) ? -1 : 1;
  };

  const sortedMedKeys = Object.keys(medData)
    .filter((m) => medData[m].show)
    .sort(medSortingFunc);

  const medRows: Record<string, React.ReactNode[]> = {};

  sortedMedKeys.forEach((key) => {
    const med = medData[key];

    medRows[key] = [
      <td key={`${key} sticky`} className="sticky med-label" onClick={() => scrollToDate(med.stop)}>
        <div className="c-wrap">{med.formattedLabel}</div>
      </td>,
      <td key={`${key} sticky opaque`} className="sticky opaque">
        <div className="c-wrap">{med.formattedLabel}</div>
      </td>,
      <td key={`${key} empty`} className="hide-me">
        <div className="c-wrap">{med.formattedLabel}</div>
      </td>,
    ];
    const noEndLabel = !med.stop || med.stop > new Date(timestamp);
    const startString = formatDate(med.start);
    let timeframe = `Started ${startString}`;
    if (med.isSuspended()) timeframe = '(suspended)';
    else if (med.stop && startString === formatDate(med.stop)) {
      timeframe = `${startString} to ${formatDate(med.stop)}`;
    }
    let mergeCounter = 1;
    sortedDatetimes.forEach((d, i) => {
      if ((!med.stop || d <= med.stop) && med.start && d >= med.start) {
        if (
          (!med.isOnce() && isEqual(d, med.start)) ||
          (med.isOnce() && isEqual(d, med.stop || new Date()))
        ) {
          if (i - mergeCounter >= 3) {
            medRows[key] = medRows[key].slice(0, 4);
            medRows[key].push(
              <td
                key={`${key} ${i - 1}`}
                className="date-button"
                colSpan={i - mergeCounter + 1}
                onClick={() => scrollToDate(med.stop)}
              >
                <span>{timeframe}</span>
              </td>
            );
          }
          medRows[key].push(
            <td
              key={`${key} ${i}`}
              className={classNames(
                'med',
                inCurrentEnc(d) ? 'current-enc' : 'previous-enc',
                med.isOnce() || noEndLabel ? 'no-end' : 'end',
                med.isOnce() ? 'no-start' : 'start',
                {
                  once: med.isOnce(),
                  suspended: med.isSuspended(),
                }
              )}
              colSpan={mergeCounter}
            >
              {med.dose ? med.dose : 'No Dosage'}
              {mergeCounter > 2 ? ` - ${timeframe}` : ''}
            </td>
          );
        } else {
          mergeCounter += 1;
        }
      } else if (isEqual(d, med.ordered || new Date())) {
        medRows[key].push(
          <td
            key={`${key} ${i}`}
            className={`ordered ${inCurrentEnc(d) ? 'current-enc' : 'previous-enc'}`}
          >
            Ordered
          </td>
        );
      } else {
        medRows[key].push(
          <td key={`${key} ${i}`} className={inCurrentEnc(d) ? 'current-enc' : 'previous-enc'} />
        );
      }
    });
  });

  return { sortedMedKeys, medRows, medSortingFunc };
};
