// div: Used separate lines
// strong: Used for bold text
// u: Used for underline text

import { replaceBrWithDivBlocks } from '~/app/utils';

// em: Used for italicized text
const allowedContent = 'div strong em u';

/**
 * pasteContentForms allows equivalent tags to be merged into a single kind.
 * For example, we only support the <strong> tag for bold text, so the below content
 * form transforms <b> and bold <span> tags into <strong>.
 */
const boldPasteContentForm = [
  'strong',
  'b',
  [
    'span',
    (el: { styles: Record<string, string> }) => {
      const fw = el.styles['font-weight'];
      return fw === 'bold' || +fw >= 700;
    },
  ],
];
const italicPasteContentForm = [
  'em',
  'i',
  [
    'span',
    (el: { styles: Record<string, string> }) => {
      const fs = el.styles['font-style'];
      return fs === 'italic';
    },
  ],
];
const underlinePasteContentForm = [
  'u',
  [
    'span',
    (el: { styles: Record<string, string> }) => {
      const td = el.styles['text-decoration'] || '';
      return td.includes('underline');
    },
  ],
];
const pasteContentForms = [boldPasteContentForm, italicPasteContentForm, underlinePasteContentForm];

const plugins = [
  // Custom plugins
  'regardCopyFormatter',

  // Custom trimmed down plugins to better fit our direct needs
  'regardBasicStyles', // 'basicstyles',
  'regardClipboard', // 'clipboard',
  'regardUndo', // 'undo',

  // Unmodified official plugins
  'enterkey',
  'entities',
  'floatingspace',
];

export const config = {
  allowedContent,
  pasteFilter: allowedContent,
  pasteContentForms,
  customPrePasteFilter: replaceBrWithDivBlocks,
  plugins,
  enterMode: CKEDITOR.ENTER_DIV,
  // Setting `shiftEnterMode: 1` prevents SHIFT+ENTER from creating newlines
  //  inside of paragraphs; rather, a new paragraph will be created, just as if
  //  you hit ENTER
  shiftEnterMode: CKEDITOR.ENTER_DIV,
  // Setting `title: false` stops each editor from getting a useless aria-label
  //  that appears as a system tooltip on hover
  title: false,
  // We're not using the toolbar, so configuring an empty set of buttons for it
  //  should improve performance
  toolbar: [],
};
