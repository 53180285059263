import { assertUnreachable } from '~/app/utils';

export const TEXTAREA_ID = 'dislikedDescription';

// ////////
// State //
// ////////

export type DislikedReasonKey =
  | 'dislikedReasonInaccurate'
  | 'dislikedReasonMissingImportantInformation'
  | 'dislikedReasonNotUseful'
  | 'dislikedReasonPoorlyWorded';

type DislikeDialogState = Record<DislikedReasonKey, boolean> & {
  [TEXTAREA_ID]: string;
  key: number;
};

export const initialState: DislikeDialogState = {
  [TEXTAREA_ID]: '',
  dislikedReasonInaccurate: false,
  dislikedReasonMissingImportantInformation: false,
  dislikedReasonNotUseful: false,
  dislikedReasonPoorlyWorded: false,
  key: 0,
};

// //////////
// Actions //
// //////////

type UpdateDislikeReason = {
  type: 'updateDislikeReason';
  payload: {
    id: DislikedReasonKey;
    value: 'on' | '';
  };
};

type UpdateDislikeDescription = {
  type: 'updateDislikeDescription';
  payload: {
    value: string;
  };
};

type Reset = {
  type: 'reset';
};

export type DislikeDialogAction = UpdateDislikeReason | UpdateDislikeDescription | Reset;

// //////////
// Reducer //
// //////////

export const dislikeDialogReducer = (
  state: DislikeDialogState,
  action: DislikeDialogAction
): DislikeDialogState => {
  switch (action.type) {
    case 'updateDislikeReason': {
      const { id, value } = action.payload;

      return {
        ...state,
        [id]: !!value,
      };
    }
    case 'updateDislikeDescription': {
      const { value } = action.payload;

      return {
        ...state,
        dislikedDescription: value,
      };
    }
    case 'reset': {
      return {
        ...initialState,
        key: state.key + 1,
      };
    }
    default:
      assertUnreachable(action);
      return state;
  }
};
