import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import { reducers } from '~/app/reducers';
import { isDev } from '~/app/flags';

const enableLoggingReduxActions = isDev;
const middleware = enableLoggingReduxActions
  ? applyMiddleware(thunkMiddleware, logger)
  : applyMiddleware(thunkMiddleware);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middleware);

export const store = createStore(reducers, enhancer);

export type AppStore = typeof store;
export type AppState = ReturnType<typeof store.getState>;
declare global {
  interface Window {
    store: AppStore;
  }
}

if (window) window.store = store;
