import { isConditionNoteBlock } from '~/app/utils';
import { createSelector, useSelector } from '../../../store';

const getNumberOfNewConditions = createSelector(
  (state) => state.regardNote.masterNoteBlocks,
  (noteBlocks) =>
    noteBlocks.filter((noteBlock) => isConditionNoteBlock(noteBlock) && noteBlock.isNew).length
);

export const useNumberOfNewConditions = () => useSelector(getNumberOfNewConditions);
