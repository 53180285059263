import { FC } from 'react';
import {
  replyPlaceholderTextStyle,
  replyBaseStyle,
  replyIconStyle,
  replyInnerStyle,
  replyLeftStyle,
  replyRightStyle,
  replyVisibleTextStyle,
} from '../maxChatAiResponse';
import UserSvg from './user.svg';

const style = {
  ...replyBaseStyle,
  padding: '20px 24px',
} as const;

export const MaxChatUserPrompt: FC<{
  text: string;
}> = ({ text }) => (
  <div style={style}>
    <div style={replyInnerStyle}>
      <div style={replyLeftStyle}>
        <div style={replyIconStyle}>
          <UserSvg alt="User" />
        </div>
      </div>
      <div style={replyRightStyle}>
        <div style={replyPlaceholderTextStyle}>{text}</div>
        <div style={replyVisibleTextStyle}>{text}</div>
      </div>
    </div>
  </div>
);
