import { FC } from 'react';

import { IconButton, StaticTooltip, theme } from '../../../../../reuse';
import { copyTextToClipboard } from '../../../../../utils';
import { track } from '../../../../../analytics';
import { DislikedAiResponseSurvey } from '../../../../../analytics/track';
import { client } from '../../../../../api';
import { ChatReplyLiked, useDetailsContext } from '../../../detailsProvider';

const style = {
  padding: '12px 0',
  textAlign: 'right',
} as const;

const iconStyle = {
  marginLeft: 4,
} as const;

const { blueDark, blurple, none } = theme.colors;

const unusedColor = {
  backgroundColor: none,
  color: blueDark,
};
const disabledPurpleColor = {
  ...unusedColor,
  '&:focus': unusedColor,
  '&:hover': unusedColor,
  '&:active, &.active': unusedColor,
  '&:disabled': {
    backgroundColor: none,
    color: blurple,
  },
};

export const MaxChatAiResponseButtons: FC<{
  index: number;
  liked: ChatReplyLiked;
  onCopy(): void;
  responseText: string;
}> = ({ index, liked, onCopy, responseText }) => {
  const { chatId, likeReply, openDislikeDialog } = useDetailsContext(
    ({ chatId, likeReply, openDislikeDialog }) => ({
      chatId,
      likeReply,
      openDislikeDialog,
    })
  );

  const isLiked = liked === 'liked';
  const isDisliked = liked === 'disliked';

  return (
    <div style={style}>
      <StaticTooltip display="inline-block" placement="top" style={iconStyle} tooltip="Like">
        <IconButton
          color={isLiked ? disabledPurpleColor : 'tertiary-grey'}
          data-cy-chat-ai-response-like-button
          disabled={isLiked}
          iconName={isLiked ? 'thumbsUpFilled' : 'thumbsUp'}
          onClick={() => {
            track.chatLikedAiResponse({
              chatId,
              responseIndex: index,
            });
            likeReply({ index, liked: 'liked' });
            client.POST('/openapi/storeInteraction', {
              body: {
                chatId,
                index,
                type: 'liked',
                fields: {},
              },
            });
          }}
          size="mini"
        />
      </StaticTooltip>
      <StaticTooltip display="inline-block" placement="top" style={iconStyle} tooltip="Dislike">
        <IconButton
          color={isDisliked ? disabledPurpleColor : 'tertiary-grey'}
          data-cy-chat-ai-response-dislike-button
          disabled={isDisliked}
          iconName={isDisliked ? 'thumbsDownFilled' : 'thumbsDown'}
          onClick={() =>
            openDislikeDialog({
              index,
              onSubmit: (surveyFields: DislikedAiResponseSurvey) => {
                track.chatDislikedAiResponse({
                  chatId,
                  ...surveyFields,
                  responseIndex: index,
                });
                client.POST('/openapi/storeInteraction', {
                  body: {
                    chatId,
                    index,
                    type: 'disliked',
                    // Typing from the backend is intentionally non-specific
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    fields: surveyFields,
                  },
                });
              },
            })
          }
          size="mini"
        />
      </StaticTooltip>
      <StaticTooltip display="inline-block" placement="top" style={iconStyle} tooltip="Copy">
        <IconButton
          color="tertiary-grey"
          data-cy-chat-ai-response-copy-button
          iconName="copy"
          onClick={() => {
            track.chatCopiedAiResponse({
              chatId,
              responseIndex: index,
              responseType: 'clickedCopyButton',
            });
            copyTextToClipboard(responseText);
            onCopy();
            client.POST('/openapi/storeInteraction', {
              body: {
                chatId,
                index,
                type: 'copy',
                fields: {},
              },
            });
          }}
          size="mini"
        />
      </StaticTooltip>
    </div>
  );
};
