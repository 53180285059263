import { FC, memo } from 'react';

import { Keyword } from '../../../@types/state';
import { getHtmlTreeWithKeywordSpans } from '../../../utils';

import { ConditionTitleLineHtmlNode } from './ConditionTitleLineHtmlNode';

interface ConditionTitleLineContentProps {
  keywords: Keyword[];
  html: HtmlString;
}

export const ConditionTitleLineContent: FC<ConditionTitleLineContentProps> = memo(
  ({ keywords, html }) => {
    const htmlTree = getHtmlTreeWithKeywordSpans({
      keywords,
      html,
    });

    return (
      <>
        {htmlTree.map((node, i) => (
          <ConditionTitleLineHtmlNode key={i} node={node} />
        ))}
      </>
    );
  }
);

ConditionTitleLineContent.displayName = 'ConditionTitleLineContent';
