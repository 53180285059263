import { TitleStyles } from '~/app/@types/state';
import { STYLE_BOLD_TAG, STYLE_ITALIC_TAG, STYLE_UNDERLINE_TAG } from '~/app/constants';
import { CharacterItemHtmlTag, CharacterItemTagType, splitHtmlIntoCharacterItems } from '.';

const NO_STYLES: TitleStyles = {
  bold: false,
  italic: false,
  underline: false,
};

export const getFirstCharacterStyles = (html: HtmlString): TitleStyles => {
  if (!html) {
    return NO_STYLES;
  }

  const firstCharacterItem = splitHtmlIntoCharacterItems(html, true)[0];
  if (!firstCharacterItem?.tags?.length) {
    return NO_STYLES;
  }

  const tags = firstCharacterItem.tags
    .filter((tag) => tag.type === CharacterItemTagType.TAG)
    .map((tag) => (tag as CharacterItemHtmlTag).tag);

  return {
    bold: tags.includes(STYLE_BOLD_TAG),
    italic: tags.includes(STYLE_ITALIC_TAG),
    underline: tags.includes(STYLE_UNDERLINE_TAG),
  };
};
