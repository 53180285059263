const weights = ['500', '600', '700', 'bold'];

export const fontWeightBoldTest = weights
  .map((w) => `<div><span style="font-weight: ${w};">weight ${w} text</span></div>`)
  .join('');

export const boldTextTest = `
    <div>regular text<div><div><b>b text</b></div>
    <div><strong>strong text</strong></div>
  `;

export const blankLineTagTest = `
    <div>text broken with</div>
    <div></div>
    <div></div>
    <div>empty div tags</div>
    <div>text broken with</div>
    <div><br/></div>
    <div><br/></div>
    <div>div tags with br-slash</div>
    <div>text broken with</div>
    <div><br></div>
    <div><br></div>
    <div>div tags with br</div>
    <div>this is a div tag</div>
    <div></div>
    <div></div>
    <div>with a couple empty divs</div>
  `;

export const nestedHtmlTest = `
    <div>A stroke<br></div>
    <div>Test some <strong>nested <strong> text<br></strong></strong></div>
    <div><strong></strong><strong>Me<strong>ow<br></strong></strong></div>
    <div><strong></strong><strong>why </strong>do you do this <strong>CKEditor <strong><strong>text<br></strong></strong></strong></div>
    <div><strong></strong><strong>why </strong>do you DO this <strong>CKEditor <strong><strong>text<br></strong><br></strong><br></strong><br></div>
  `;

export const underlineAndItalicsTest = `
    <div></div>
    <div>Underline</div>
    <div><u>underlined with u tag</u></div>
    <div><span style="text-decoration: underline">underlined with span tag</span></div>
    <div></div>
    <div>Italic</div>
    <div><i>italicized with i tag</i></div>
    <div><em>italicized with em tag</em></div>
    <div><span style="font-style: italic">italicized with span tag</span></div>
    <div><span style="font-style: italic;text-decoration: underline;font-weight: 700">everything span</span></div>
  `;

export const explicitStylesTest = `
    <div></div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0; font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles all in div line 1</div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0; font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles all in div line 2</div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0; font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles all in div line 3</div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0;"> <span style="font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles accross div and span line 1 </span></div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0;"> <span style="font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles accross div and span line 2 </span></div>
    <div style="line-height: 1.0; margin-top: 0; margin-bottom: 0;"> <span style="font-size: 11pt; font-family: Arial, sans-serif; color: rgb(0, 0, 0)"> explicit styles accross div and span line 3 </span></div>
  `;

export const fontTest = `
    <div></div>
    <div><font face="Arial", size="1">Front with Arial and size 1</font></div>
    <div><font face="Arial", size="2">Front with Arial and size 2</font></div>
    <div><font face="Arial, sans-serif", size="3">Front with Arial, sans-serif and size 3</font></div>
    <div><font face="Arial", size="4">Front with Arial and size 4</font></div>
    <div><font face="Arial", size="5">Front with Arial and size 5</font></div>
    <div></div>
    <div>
      <font face="Arial, sans-serif", size="3">Front with Arial, sans-serif<br>and size 3 with br <br> separation</font>
    </div>
  `;
