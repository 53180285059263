import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { doLogout } from '../actions/login';

const Logout: FC<{
  handleLogout(): void;
}> = ({ handleLogout }) => {
  useEffect(handleLogout, [handleLogout]);

  return <Navigate replace to="/" />;
};

const mapDispatchToProps = {
  handleLogout: doLogout,
};

export default connect(null, mapDispatchToProps)(Logout);
