import { FC, memo } from 'react';

import { ConditionLineStatus } from '~/app/@types';
import { Icon } from '~/app/reuse';
import { getBackgroundStyle, getIconSyle } from './css';

export const ConditionTitleRegardIndicator: FC<{
  status: ConditionLineStatus;
}> = memo(({ status }) => {
  const statusNew = status === 'new';
  const statusNone = status === 'none';

  return (
    <>
      <div
        data-cy-condition-title-regard-indicator
        data-cy-condition-title-regard-indicator-status={status}
        style={getBackgroundStyle({ statusNew })}
      />
      <Icon iconName="regard" style={getIconSyle({ statusNew, statusNone })} />
    </>
  );
});
ConditionTitleRegardIndicator.displayName = 'ConditionTitleRegardIndicator';
