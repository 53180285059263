import { FC, memo, ReactEventHandler } from 'react';

import { useMeshedContext } from '../MeshedProvider';
import { Divider } from './controls/Divider';
import { UndoButton } from './controls/UndoButton';
import { RedoButton } from './controls/RedoButton';
import { BoldButton } from './controls/BoldButton';
import { ItalicButton } from './controls/ItalicButton';
import { UnderlineButton } from './controls/UnderlineButton';
import { RefreshButton } from './controls/RefreshButton';
import { controlsStyle, stylingButtonContainerStyle, getNoteToolbarStyle } from './css';
import { useActiveStyles } from './useActiveStyles';
import { HashTitleButton } from './controls/HashTitleButton';
import { NumberTitleButton } from './controls/NumberTitleButton';

interface NoteToolbarProps {
  copied: boolean;
  unreadModalIsOpen: boolean;
  onRedoClick(): void;
  onUndoClick(): void;
  onHashTitleClick(): void;
  onNumberTitleClick(): void;
  onBeforeRefresh(): void;
  onBoldClick(): void;
  onItalicClick(): void;
  onUnderlineClick(): void;
}

export const NoteToolbar: FC<NoteToolbarProps> = memo(
  ({
    unreadModalIsOpen,
    copied,
    onRedoClick,
    onUndoClick,
    onHashTitleClick,
    onNumberTitleClick,
    onBeforeRefresh,
    onBoldClick,
    onItalicClick,
    onUnderlineClick,
  }) => {
    const activeStyles = useActiveStyles();
    const historyActionsDisabled = unreadModalIsOpen || copied;
    const styleActionsDisabled = unreadModalIsOpen || copied || !activeStyles.inEditor;

    const onMouseDown: ReactEventHandler<HTMLSpanElement> = (e) => {
      // Preventing the mouseDown event's default behavior stops editors from bluring
      // and losing caret positions when clicking on toolbar buttons.
      e.preventDefault();
    };
    const { isScaledHorizontalLayout } = useMeshedContext(({ isScaledHorizontalLayout }) => ({
      isScaledHorizontalLayout,
    }));

    return (
      <div data-cy-note-toolbar style={getNoteToolbarStyle(isScaledHorizontalLayout)}>
        <span style={controlsStyle}>
          <UndoButton disabled={historyActionsDisabled} onUndoClick={onUndoClick} />
          <RedoButton disabled={historyActionsDisabled} onRedoClick={onRedoClick} />
          <Divider />
          <span onMouseDown={onMouseDown} style={stylingButtonContainerStyle}>
            <HashTitleButton active={false} disabled={false} onClick={onHashTitleClick} />
            <NumberTitleButton active={false} disabled={false} onClick={onNumberTitleClick} />
          </span>
          <Divider />
          <span onMouseDown={onMouseDown} style={stylingButtonContainerStyle}>
            <BoldButton
              active={activeStyles.bold}
              disabled={styleActionsDisabled}
              onBoldClick={onBoldClick}
            />
            <ItalicButton
              active={activeStyles.italic}
              disabled={styleActionsDisabled}
              onItalicClick={onItalicClick}
            />
            <UnderlineButton
              active={activeStyles.underline}
              disabled={styleActionsDisabled}
              onUnderlineClick={onUnderlineClick}
            />
          </span>
        </span>
        <RefreshButton onBeforeRefresh={onBeforeRefresh} />
      </div>
    );
  }
);
NoteToolbar.displayName = 'NoteToolbar';
