// Whitespace
const NON_NEWLINE_WHITE_SPACE = '[^\\S\\r\\n\\f]'; // Matches horizontal whitespace
const OPTIONAL_NON_NEWLINE_WHITE_SPACE = `${NON_NEWLINE_WHITE_SPACE}?`;
const ZERO_OR_MORE_NON_NEWLINE_WHITE_SPACE = `${NON_NEWLINE_WHITE_SPACE}*`;

// Title
export const DEFAULT_HASH_TITLE_SIGNIFIER = '#';
export const DEFAULT_NUMBER_TITLE_SIGNIFIER = '1.';
export const DEFAULT_TITLE_SIGNIFIER = DEFAULT_HASH_TITLE_SIGNIFIER;
const TITLE_NUMBER = '(?:[0-9]{1,3}(?:\\)|(?:\\.(?![0-9]))))'; // Matches 1., 15),... but not 12.5
const TITLE_HASH = '#';
const TITLE_SIGNIFIER_1 = `(?:(?:${TITLE_HASH}${OPTIONAL_NON_NEWLINE_WHITE_SPACE})?${TITLE_NUMBER})`; // Matches # 1), #11., 5)
const TITLE_SIGNIFIER_2 = `(?:${TITLE_HASH}(?:${OPTIONAL_NON_NEWLINE_WHITE_SPACE}[.)])?)`; // Matches #, #., # ), ...
const TITLE_SIGNIFIER = `${TITLE_SIGNIFIER_1}|${TITLE_SIGNIFIER_2}`;

// Bullet
export const DEFAULT_BULLET_SIGNIFIER = '-';
const BULLET_DASH = '(?:[-\\u2010\\u2012][-\\u2010\\u2012]?)';
const BULLET_STAR = '\\*';
const BULLET_SIGNIFIER = `${BULLET_DASH}|${BULLET_STAR}`;

// Shelf
const SHELF_SIGNIFIER = '(?:[:]{3})';

// Line
const INITIAL_SIGNIFIER = `(?:(${TITLE_SIGNIFIER})|(${SHELF_SIGNIFIER})|(${BULLET_SIGNIFIER}))?`;
const LINE_TEXT = '([^\r\n]+)'; // captures one or more non-line-break characters, and none shelf signifier
const LINE_CONTENT = `(?:${INITIAL_SIGNIFIER}${ZERO_OR_MORE_NON_NEWLINE_WHITE_SPACE}${LINE_TEXT})?`;
const FULL_LINE = `^${ZERO_OR_MORE_NON_NEWLINE_WHITE_SPACE}${LINE_CONTENT}$`;
export const getNonGlobalLineRegex = () => new RegExp(FULL_LINE, 'm');

// Footer
// Keep in sync with the backend "sections:FOOTER" sections in automed/references/defaults/note_sections.yml
const FOOTER_SIGNIFIERS = [
  '(?:(?:Full )?Code(?: Status)?)',
  '(?:(?:Pharmacologic )?VTE(?: (?:Prophylaxis|Ppx|Risk and Treatment))?)',
  '(?:Counseling Time)',
  '(?:Diet)',
  '(?:Dispo(?: Planning)?)',
  '(?:Disposition)',
  '(?:DNR(?:\\/DNI)?)',
  '(?:DVT(?:\\/VTE)? (?:Prophylaxis|Ppx))',
  '(?:Expected Discharge Date)',
  '(?:F/E/N)',
  '(?:FEN)',
  '(?:FENppx)',
  '(?:Fluids, Electrolytes, Nutrition)',
  '(?:Global)',
  '(?:Goals of Care)',
  '(?:GoC)',
  '(?:Ppx)',
];
const FOOTER = `(?:(?:(?:${FOOTER_SIGNIFIERS.join('|')})\\b)|(?:\\[\\d\\]))`;
export const FOOTER_MARKER = new RegExp(`^${FOOTER}`, 'gmi');
export const getFooterKeywordRegex = () => new RegExp(`^${FOOTER}`, 'i');
