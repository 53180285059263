import { SummaryOl, SummarySpanB } from './common';

export const depressionAndAnxietySummary = (
  <SummaryOl>
    <li>
      Regard will search the patient’s condition history for depression and anxiety. If the patient
      has a history, active, or home medications associated with the conditions the{' '}
      <SummarySpanB>dx is positive</SummarySpanB>.
    </li>
    <li>If positive Regard will list prescriptions and details on the condition</li>
  </SummaryOl>
);
