import { FC } from 'react';

import { VERSION } from '../../../version';

interface ApplicationVersionProps {
  reduxReplayVersion: string;
}

const warningStyle = {
  lineHeight: '16px',
  marginLeft: 4,
} as const;

const versionTextStyle = {
  lineHeight: '16px',
  marginLeft: 4,
} as const;

export const ApplicationVersion: FC<ApplicationVersionProps> = ({ reduxReplayVersion }) => {
  const applicationVersionText = `Version: ${VERSION}`;
  const reduxReplayVersionText =
    reduxReplayVersion && ` | Redux Replay Version: ${reduxReplayVersion}`;
  const versionText = applicationVersionText + reduxReplayVersionText;

  const warning = VERSION !== reduxReplayVersion ? '⚠️' : '';
  return (
    <>
      {warning && <div style={warningStyle}>{warning}</div>}
      <div style={versionTextStyle}>{versionText}</div>
    </>
  );
};
