/**
 * Use this react hook instead of wrapping your setState calls with setTimeout.
 *
 * It's going to be much safer.
 */

import { useCallback, useEffect, useRef, useState } from 'react';

type DelayedSetStateFn<S> = ({
  callback,
  delay,
  state,
}: {
  callback?(): void; // another function that should execute along with setState
  delay?: number;
  state: S | ((prevState: S) => S);
}) => void;

export const useDelayedSetState = <S>(initialState: S): [S, DelayedSetStateFn<S>] => {
  const [state, setState] = useState(initialState);

  const timeoutRef = useRef(0);

  const setFormStateWithDelay = useCallback<DelayedSetStateFn<S>>(({ callback, delay, state }) => {
    if (delay) {
      // clear out possible pre-existing timeout
      window.clearTimeout(timeoutRef.current);
      // now create a new timeout
      timeoutRef.current = window.setTimeout(() => {
        setState(state);
        if (callback) callback();
      }, delay);
    } else {
      // clear out possible pre-existing timeout
      window.clearTimeout(timeoutRef.current);
      setState(state);
      if (callback) callback();
    }
  }, []);

  useEffect(
    () => () => {
      // clear out possible leftover timeout
      window.clearTimeout(timeoutRef.current);
    },
    []
  );

  return [state, setFormStateWithDelay];
};
