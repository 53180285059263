import { isArray, isBoolean, isPlainObject } from 'lodash';

import {
  getOAuthSubject as getOAuthSubjectUtil,
  getUserRegardAdmin as getUserRegardAdminUtil,
} from '../cookies';

import { UserFlag } from './types';
import {
  UnleashClient,
  flagHasVariant,
  getFlagVariantValue,
  unleashClient as regardUnleashClient,
} from './unleash';
import { getFlagFromLocalStorageOrWindow } from '../utils/localStorageFlags';

interface IsFlagEnabledForUserOptions<K extends keyof Flags> {
  getFlagValue?: (key: K) => Flags[K] | undefined;
  getOAuthSubject?: () => string;
  getIsRegardAdmin?: () => boolean;
  enableForAllInternalUsers?: boolean;
  defaultValue?: boolean;
}

const defaultOptions = {
  getFlagValue: getFlagFromLocalStorageOrWindow,
  getOAuthSubject: getOAuthSubjectUtil,
  getIsRegardAdmin: getUserRegardAdminUtil,
  enableForAllInternalUsers: false,
  defaultValue: false,
} as const;

export const isFlagEnabledForUser = <K extends keyof Flags>(
  flagKey: K,
  options: IsFlagEnabledForUserOptions<K> = {},
  unleashClient: UnleashClient = regardUnleashClient
): boolean => {
  const {
    getFlagValue,
    getOAuthSubject,
    getIsRegardAdmin,
    enableForAllInternalUsers,
    defaultValue,
  } = { ...defaultOptions, ...options };

  const flag = getFlagValue(flagKey) as UserFlag;
  if (flag === 'FORCE_DISABLE') {
    return false;
  }

  if (enableForAllInternalUsers && getIsRegardAdmin()) {
    return true;
  }

  if (isBoolean(flag)) {
    return flag;
  }

  const oauthSub = getOAuthSubject();

  if (isArray(flag)) {
    return flag.includes(oauthSub);
  }

  if (flag && isPlainObject(flag)) {
    if (flag.exclude?.includes(oauthSub)) return false;
    if (flag.include?.includes(oauthSub)) return true;
    return flag.default ?? defaultValue;
  }

  const shouldCheckUnleash =
    flag === undefined && unleashClient && unleashClient.isEnabled(flagKey);
  if (shouldCheckUnleash) {
    const variant = unleashClient.getVariant(flagKey);
    const hasVariant = flagHasVariant(variant);
    if (hasVariant) {
      const variantValue = getFlagVariantValue(flagKey, variant);
      return !!variantValue;
    }

    return true;
  }

  return defaultValue;
};

export const createIsFlagEnabledForUserGetter =
  <K extends keyof Flags>(
    flagKey: K,
    options: IsFlagEnabledForUserOptions<K> = {},
    unleashClient: UnleashClient = regardUnleashClient
  ): (() => boolean) =>
  () =>
    isFlagEnabledForUser(flagKey, options, unleashClient);
