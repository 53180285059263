import { CSSProperties, FC, memo } from 'react';

import { getStyle } from './css';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionBackground: FC<{
  buttonHoveredStyle?: CSSProperties;
  defaultStyle?: CSSProperties;
  hoverStyle?: CSSProperties;
  isSuggestion?: boolean;
}> = memo(({ buttonHoveredStyle, defaultStyle, hoverStyle, isSuggestion }) => {
  const { buttonHovered, copyButtonHovered, highlighted, highlightedKey } = useNoteBlockContext(
    ({ buttonHovered, copyButtonHovered, highlighted, highlightedKey }) => ({
      buttonHovered,
      copyButtonHovered,
      highlighted,
      highlightedKey,
    })
  );

  const { finalDefaultStyle, finalButtonHoveredStyle, finalHoverStyle, finalHighlightStyle } =
    getStyle({
      // NOTE: The style object props passed to a memoized function, so do not
      //  pass a unique object reference on every render or you will create a small
      //  memory leak!
      buttonHoveredStyle,
      defaultStyle,
      hoverStyle,
    });

  return (
    <>
      <div
        data-condition-default-background
        data-cy="condition-default-background"
        style={finalDefaultStyle}
      />
      <div
        data-condition-hover-background
        data-cy="condition-hover-background"
        style={
          buttonHovered || (copyButtonHovered && !isSuggestion)
            ? finalButtonHoveredStyle
            : finalHoverStyle
        }
      />
      {highlighted && (
        <div
          key={highlightedKey}
          data-cy="condition-highlight-background"
          style={finalHighlightStyle}
        />
      )}
    </>
  );
});
ConditionBackground.displayName = 'ConditionBackground';
