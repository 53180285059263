import { FC } from 'react';
import { connect } from 'react-redux';

import { AppState, NotificationAttrs } from '../../@types/state';
import { removeNotification } from '../../actions/ui';
import Notification from './Notification';

interface StateProps {
  notifications: NotificationAttrs[];
}

interface DispatchProps {
  dispatchRemoveNotification: (indexOfNotification: number) => void;
}

type Props = StateProps & DispatchProps;

const NotificationContainer: FC<Props> = ({ dispatchRemoveNotification, notifications }) => {
  if (notifications.length === 0) return null;
  return (
    <div className="NotificationContainer">
      {notifications.map((notification, i) => (
        <Notification
          {...notification}
          key={notification.id}
          close={() => dispatchRemoveNotification(i)}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ ui: { notifications } }: AppState): StateProps => ({ notifications });

const mapDispatchToProps = { dispatchRemoveNotification: removeNotification };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
