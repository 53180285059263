import { LiHTMLAttributes } from 'react';

import * as utils from '../../utils';
import { APIBullet, Item } from '../../@types/state';

// Takes an item (which can be a string, date, datetime, chartlink
// or an array containing any combination of these) and recursively
// converts all contents to a single string
const stringifyItemRecursively = (item: Item): string => {
  if (typeof item === 'object') {
    if (Array.isArray(item)) {
      return item.reduce<string>(
        (itemString, i: Item): string => itemString + stringifyItemRecursively(i),
        ''
      );
    }
    if (item.type === 'datetime') {
      return utils.formatDatetime(item.value);
    }
    if (item.type === 'date') {
      const date = utils.parseStringToDateOrNull(item.value) ?? new Date();
      const localOffsetMinutes = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() + localOffsetMinutes); // changes hours/days if needed
      return utils.formatDate(date.toISOString());
    }
    return item.value;
  }
  if (typeof item !== 'string') return `${item}`;
  return item;
};

// Once we piece the bullet item into a bullet string
// we want to trim any leading/trailing spaces and replace all multi-spaces
// (matching bullets from note to Regard bullets is easier this way)
export const stringifyItems = (item: Item[]): string =>
  stringifyItemRecursively(item)
    .trim()
    .replace(/[ ]{2,}/g, ' ');

// takes a section like complaint.assessment and renders
// into an array of objects. each object's keys match up
// to an <li> element
export const displayComplaintSection = (section: APIBullet[]): LiHTMLAttributes<HTMLLIElement>[] =>
  section.map((lineObject: APIBullet) => ({
    key: lineObject.id,
    className: lineObject.tags.join(' '),
    children: stringifyItems(lineObject.item),
  }));
