import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { selectScreen } from '../../actions/record';
import { SCREEN_NAV_ITEMS } from './screens';

const ScreenNav = ({ selectedScreen, select }) => (
  <ul className="htemr-ScreenNav">
    {SCREEN_NAV_ITEMS.map((screen) => (
      <li
        key={screen.label}
        className={classNames('htemr-ScreenNav-item', {
          'htemr-ScreenNav-selectedItem': selectedScreen === screen,
        })}
        onClick={selectedScreen === screen ? null : () => select(screen)}
      >
        {screen.label}
      </li>
    ))}
  </ul>
);

ScreenNav.propTypes = {
  selectedScreen: PropTypes.shape({ ItemView: PropTypes.func }),
  select: PropTypes.func.isRequired,
};

ScreenNav.defaultProps = {
  selectedScreen: null,
};

const mapStateToProps = (state) => ({
  selectedScreen: state.selectedScreen,
});

const mapDispatchToProps = {
  select: selectScreen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenNav);
