import { htmlStringToPlainText } from '~/app/utils';

const getFragmentHTML = (fragment: DocumentFragment) => {
  const container = document.createElement('div');
  container.appendChild(fragment.cloneNode(true));
  return container.innerHTML as HtmlString;
};

type TryToTriggerNonEmptyBackspaceParams = {
  id: string;
  editorElement: HTMLElement;
  isBackwardBackspace: boolean;
  isForwardBackspace: boolean;
  onNonEmptyBackspace: (id: string, direction: 'forward' | 'backward') => void;
};
export const tryToTriggerNonEmptyBackspace = (params: TryToTriggerNonEmptyBackspaceParams) => {
  const { id, editorElement, isBackwardBackspace, isForwardBackspace, onNonEmptyBackspace } =
    params;

  // Determine the current caret position relative to the full editor
  const selection = window.getSelection();
  if (!selection?.rangeCount) {
    return;
  }

  const range = selection.getRangeAt(0);
  const caretStart = range.startOffset;
  const caretEnd = range.endOffset;

  const clonedRange = range.cloneRange();
  clonedRange.setStart(editorElement, 0);
  const clonedSelectionContent = clonedRange.cloneContents();

  const htmlUpToCaret = getFragmentHTML(clonedSelectionContent);

  // Convert both to string via htmlStringToPlainText to remove any discrepancies
  // with accesing content via innerText
  const textUpToCaret = htmlStringToPlainText(htmlUpToCaret);
  const editorText = htmlStringToPlainText(editorElement.innerHTML as HtmlString);

  const caretRelativeToEditorStart = textUpToCaret?.length || 0;
  const caretIsInCorectPosition =
    caretStart === caretEnd &&
    ((caretRelativeToEditorStart === 0 && isBackwardBackspace) ||
      (caretRelativeToEditorStart === editorText.length && isForwardBackspace));

  if (!caretIsInCorectPosition) {
    return;
  }

  // timeout required here to prevent an uncaught error in CKEditor
  setTimeout(() => onNonEmptyBackspace(id, isForwardBackspace ? 'forward' : 'backward'), 0);
};
