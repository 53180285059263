import { SummaryOl, SummarySpanB, SummarySpanI } from './common';

export const calciumSummary = (
  <SummaryOl>
    <li>
      The calcium module will diagnose <SummarySpanB>Hyper/Hypocalcemia</SummarySpanB> prioritizing
      ionized calcium levels over serum calcium levels, if available. If serum albumin is{' '}
      <SummarySpanI>abnormal</SummarySpanI>, Regard will automatically correct the serum calcium.
    </li>
    <li>
      The algorithm will consider parathyroid hormone (PTH) levels, urine labs, vitamin D, kidney
      disease, phosphorus, magnesium, and home medications when making recommendations.
    </li>
  </SummaryOl>
);
