import { theme, zIndices } from '~/app/reuse';
import { CONDITION_TEXTAREA_LINE_HEIGHT } from '../const';

export const copiedStyle = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: zIndices.conditionCopiedMessage,
} as const;

export const copiedBackgroundStyle = {
  backgroundColor: theme.colors.lightBlue2,
  bottom: 0,
  left: 0,
  opacity: 0.6,
  position: 'absolute',
  right: 0,
  top: 0,
} as const;

export const copiedPillStyle = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.other.borderRadius4,
  color: theme.colors.blueRegular,
  fontSize: 12,
  fontWeight: 600,
  height: 20,
  left: '50%',
  padding: '0 20px 0 16px',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
} as const;

export const copiedMessageStyle = {
  position: 'relative',
  top: -1,
} as const;

export const copiedMessageIconStyle = {
  marginRight: 2,
  position: 'relative',
  top: 3,
} as const;

const overflowWrap = 'break-word';

export const wrapStyle = {
  overflowWrap, // 'overflow-wrap' is the updated name for 'word-wrap'
  whiteSpace: 'pre-wrap', // 'break-spaces' would be best but it's not supported by IE10
  wordWrap: overflowWrap,
} as const;

export const contentCss = {
  ...wrapStyle,
  lineHeight: `${CONDITION_TEXTAREA_LINE_HEIGHT}px`,
  position: 'absolute',
  width: '100%',
} as const;
