import { theme } from '~/app/reuse';

export const itemsStyle = {
  marginTop: 4,
  marginBottom: 8,
};

export const menuItemStyle = {
  display: 'block',
  height: 16,
  width: '100%',
  marginTop: 4,
  borderRadius: theme.other.borderRadius4,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
} as const;

export const moveButton = {
  ...menuItemStyle,
  position: 'relative',
  background: theme.colors.lightBlue1,
  border: '1px dashed transparent',
  height: 24,
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    background: theme.colors.lightBlue2,
    color: theme.colors.blueRegular,
    borderColor: theme.colors.blueRegular,
  },

  '&:active': {
    background: theme.colors.lightBlue3,
    color: theme.colors.blueDark,
    borderColor: theme.colors.blueDark,
  },

  '&:not(:hover) svg': {
    display: 'none',
  },

  '&:not(:hover) span': {
    display: 'none',
  },
} as const;

export const menuItemNameStyle = {
  ...menuItemStyle,
  height: 'initial',
  marginTop: 4,
} as const;

export const menuItemDividerStyle = {
  ...menuItemStyle,
  margin: '6px 0',
  position: 'relative',
  textAlign: 'center',
} as const;

export const menuItemDividerLineStyle = {
  backgroundColor: theme.colors.grey2,
  height: 1,
  left: 0,
  position: 'absolute',
  right: 0,
  top: '50%',
} as const;

export const menuItemDividerNameStyle = {
  backgroundColor: theme.colors.white,
  fontSize: 12,
  color: theme.colors.grey2,
  display: 'inline-block',
  fontWeight: 600,
  padding: '0 12px',
  position: 'relative',
  textTransform: 'uppercase',
} as const;

export const currentItemStyle = {
  ...menuItemStyle,
  color: theme.colors.blueRegular,
  border: `1px dashed ${theme.colors.blueRegular}`,
  height: 28,
  lineHeight: '26px', // 28 height - 2px border
  marginLeft: -8,
  paddingLeft: 8,
  width: 'calc(100% + 16px)',
} as const;
