import { CSSProperties, FC, memo } from 'react';

import { memoize } from 'lodash';
import { assertUnreachable } from '~/app/utils';
import { IconName, iconMap } from '../icons';

export type IconSize = 'standard' | 'wide';

export type ConditionMoveType = 'top' | 'up' | 'down' | 'bottom';

export type IconProps = {
  iconName: IconName;
  style?: CSSProperties;
};

export type IconPropsRest = {
  size?: IconSize;
  width?: number | string;
  height?: number | string;
  viewBox?: string;
};

const baseStyle = {
  fill: 'currentColor',
};

const getStyle = memoize((style: CSSProperties | undefined) => ({
  ...baseStyle,
  ...style,
}));

const getViewAttributes = memoize((size: IconSize) => {
  switch (size) {
    case 'standard':
      return { viewBox: '0 0 16 16', width: 16, height: 16 } as const;
    case 'wide':
      return { viewBox: '0 0 32 16', width: 32, height: 16 } as const;
    default:
      assertUnreachable(size);
  }
  return {} as const;
});

export const Icon: FC<IconProps & IconPropsRest> = memo(
  ({ iconName, style, size = 'standard', ...rest }) => (
    <svg
      style={getStyle(style)}
      xmlns="http://www.w3.org/2000/svg"
      {...getViewAttributes(size)}
      {...rest}
    >
      {iconMap[iconName]}
    </svg>
  )
);
Icon.displayName = 'Icon';
