import { NBSP } from '~/app/constants';
import { getIsEmptyHtmlString, insertTextInHtmlString, replaceTextInHtmlString } from '..';

export const SHELF_SIGNIFIER = ':::';
export const SHELF_DIVIDER_DEFAULT_TEXT = 'Stable/Chronic/Resolved';
export const SHELF_DIVIDER_DEFAULT_LINE = `${SHELF_SIGNIFIER} ${SHELF_DIVIDER_DEFAULT_TEXT} ${SHELF_SIGNIFIER}`;
export const SHELF_DIVIDER_DEFAULT_LINE_HTML =
  `<div>${SHELF_SIGNIFIER}${NBSP}${SHELF_DIVIDER_DEFAULT_TEXT}${NBSP}${SHELF_SIGNIFIER}</div>` as HtmlString;

const defaultEmptyDividerLineHtml =
  `<div>${SHELF_SIGNIFIER}${NBSP}${SHELF_SIGNIFIER}</div>` as HtmlString;

function removeBrTags(html: HtmlString) {
  return html.replace(/<br\s*\/?>/gi, '') as HtmlString;
}

export const wrapWithShelfSignifiers = (html: HtmlString) => {
  let wrappedText = '';
  if (getIsEmptyHtmlString(html)) {
    wrappedText = defaultEmptyDividerLineHtml;
  } else {
    // Strip any br tags that have gotten into the html since a shelf line should not multiple lines.
    // (IE adds them when typing into an empty line)
    const sanitizedHtml = removeBrTags(html);
    wrappedText = insertTextInHtmlString(sanitizedHtml, `${SHELF_SIGNIFIER}${NBSP}`, 0);
    // insertTextInHtmlString will clamp the index
    wrappedText = insertTextInHtmlString(
      wrappedText as HtmlString,
      `${NBSP}${SHELF_SIGNIFIER}`,
      wrappedText.length
    );
  }

  return wrappedText as HtmlString;
};

export const stripShelfSignifiers = (html: HtmlString) => {
  const htmlWithoutShelfSignifiersWithoutPrefixSignifier = replaceTextInHtmlString(
    html,
    SHELF_SIGNIFIER,
    '',
    { trimLeadingWhitespace: true }
  );
  const htmlWithoutShelfSignifiers = replaceTextInHtmlString(
    htmlWithoutShelfSignifiersWithoutPrefixSignifier,
    SHELF_SIGNIFIER,
    '',
    { trimTrailingWhitespace: true }
  );
  return htmlWithoutShelfSignifiers;
};
