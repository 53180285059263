import { BR_ELEMENT } from '~/app/constants';
import { splitHtmlIntoCharacterItems, characterItemsToTree, htmlTreeToString } from '.';

/**
 * Inserts plain text in an html string that can span different tags
 */
export function insertTextInHtmlString(html: HtmlString, text: string, index: number) {
  if (!text) return html;

  // 1. Split html string to characters
  const characters = splitHtmlIntoCharacterItems(html);
  const isSingleEmptyBr = characters.length === 1 && characters[0].character === BR_ELEMENT;

  // 2. Only allow insertion at valid index
  const clampedIndex = Math.max(0, Math.min(index, characters.length));

  // 3. Prepare text to be inserted
  // Use tags from the previous character. (or 0 if inserting at the start)
  const oldTextTags = characters[clampedIndex > 0 ? clampedIndex - 1 : 0].tags;
  const textCharacters = text.split('').map((newTextChar) => ({
    character: newTextChar,
    tags: oldTextTags,
  }));

  // 4. Insert text (or replace text if it was previously an empty <br>)
  const newCharacters = isSingleEmptyBr
    ? textCharacters
    : [...characters.slice(0, clampedIndex), ...textCharacters, ...characters.slice(clampedIndex)];

  // 5. Convert back into HtmlString
  const htmlTree = characterItemsToTree(newCharacters);
  const newHtml = htmlTreeToString(htmlTree);
  return newHtml;
}
