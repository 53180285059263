import { FC, FormEvent, useCallback, useReducer } from 'react';

import { Button, DialogBase, IconButton, LabeledTextarea } from '~/app/reuse';
import { isNumber } from 'lodash';
import { useDetailsContext } from '../../detailsProvider';
import { buttonContainerStyle, closeButtonStyle, formStyle, headerTextStyle, style } from './style';
import { dislikeDialogReducer, initialState } from './dislikeDialogReducer';
import { MaxChatDislikeDialogCheckbox } from './MaxChatDislikeDialogCheckbox';

export const MaxChatDislikeDialog: FC = () => {
  const { closeDislikeDialog, dislikeDialogState, likeReply } = useDetailsContext(
    ({ closeDislikeDialog, dislikeDialogState, likeReply }) => ({
      closeDislikeDialog,
      dislikeDialogState,
      likeReply,
    })
  );
  const { isOpen, onSubmit, replyIndex } = dislikeDialogState;

  const [state, dispatch] = useReducer(dislikeDialogReducer, initialState);
  const {
    dislikedDescription,
    dislikedReasonInaccurate,
    dislikedReasonMissingImportantInformation,
    dislikedReasonNotUseful,
    dislikedReasonPoorlyWorded,
    key,
  } = state;

  const closeDislikeDialogAndResetForm = useCallback(() => {
    closeDislikeDialog();
    dispatch({ type: 'reset' });
  }, [closeDislikeDialog, dispatch]);

  const submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      // 1. No default form behavior, thank you
      e.preventDefault();

      // 2. Fire analytics
      onSubmit({
        dislikedDescription,
        dislikedReasonInaccurate,
        dislikedReasonMissingImportantInformation,
        dislikedReasonNotUseful,
        dislikedReasonPoorlyWorded,
      });

      // 3. Dislike in the UI :(
      if (isNumber(replyIndex)) {
        likeReply({
          index: replyIndex,
          liked: 'disliked',
        });
      }

      // 4. Close the dialog--we're done here!
      closeDislikeDialogAndResetForm();
    },
    [
      closeDislikeDialogAndResetForm,
      dislikedDescription,
      dislikedReasonInaccurate,
      dislikedReasonMissingImportantInformation,
      dislikedReasonNotUseful,
      dislikedReasonPoorlyWorded,
      replyIndex,
      likeReply,
      onSubmit,
    ]
  );

  return (
    <DialogBase close={closeDislikeDialogAndResetForm} isOpen={isOpen}>
      <div style={style}>
        <div style={headerTextStyle}>Share additional feedback</div>
        <IconButton
          color="tertiary-grey"
          iconName="x"
          onClick={closeDislikeDialogAndResetForm}
          size="mini"
          style={closeButtonStyle}
        />
        <form key={key} data-cy-chat-dislike-form onSubmit={submit} style={formStyle}>
          <MaxChatDislikeDialogCheckbox
            dispatch={dispatch}
            id="dislikedReasonInaccurate"
            label="inaccurate"
          />
          <MaxChatDislikeDialogCheckbox
            dispatch={dispatch}
            id="dislikedReasonPoorlyWorded"
            label="poorly worded"
          />
          <MaxChatDislikeDialogCheckbox
            dispatch={dispatch}
            id="dislikedReasonNotUseful"
            label="not useful"
          />
          <MaxChatDislikeDialogCheckbox
            dispatch={dispatch}
            id="dislikedReasonMissingImportantInformation"
            label="missing important information"
          />
          <LabeledTextarea
            label="Describe the issue"
            onChange={(e) =>
              dispatch({
                type: 'updateDislikeDescription',
                payload: { value: e.target.value },
              })
            }
            rows={2}
            style={{ marginTop: 12 }}
            value={dislikedDescription}
          />
          <div style={buttonContainerStyle}>
            <Button color="primary" size="standard" submit>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </DialogBase>
  );
};
