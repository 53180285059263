import { FC, memo, useEffect } from 'react';

import { track } from '~/app/analytics';
import { IconButton, StaticTooltip } from '~/app/reuse';
import { get } from '~/app/utils';
import { CONDITION_UNDERLAY_BUTTON_COLOR, CONDITION_UNDERLAY_BUTTON_SIZE } from '../const';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionCopyButton: FC = memo(() => {
  const { copyNoteBlock, id, index, isCopied, onCopyButtonEnter, onCopyButtonLeave } =
    useNoteBlockContext(
      ({ copyNoteBlock, id, index, isCopied, onCopyButtonEnter, onCopyButtonLeave }) => ({
        copyNoteBlock,
        id,
        index,
        isCopied,
        onCopyButtonEnter,
        onCopyButtonLeave,
      })
    );

  /**
   * When this component is unmounted, we must reset the state in the
   *  provider, otherwise it may get stuck in the "mouse entered" state.
   */
  useEffect(() => onCopyButtonLeave, [onCopyButtonLeave]);

  return (
    <StaticTooltip display="inline-block" placement="top" tooltip="Copy">
      <IconButton
        color={CONDITION_UNDERLAY_BUTTON_COLOR}
        data-cy-condition-copy-button
        disabled={isCopied}
        iconName="copy"
        onClick={() => {
          const { regardNote } = window.store.getState();
          const noteBlock = get(regardNote.noteBlocksById, id);

          if (noteBlock) {
            const modules = ('modules' in noteBlock && noteBlock.modules) || [];
            const copiedFromShelf = noteBlock.type === 'shelvedCondition';

            copyNoteBlock();

            track.clickedCopyDxButton({
              atIndex: index,
              modules,
              copiedFromShelf,
            });
            modules.forEach((m) =>
              track.conditionCopied({
                module: m,
                uiElement: 'copy dx button',
                copiedFromShelf,
              })
            );
          }
        }}
        onMouseEnter={onCopyButtonEnter}
        onMouseLeave={onCopyButtonLeave}
        size={CONDITION_UNDERLAY_BUTTON_SIZE}
      />
    </StaticTooltip>
  );
});
