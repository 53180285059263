import { SummaryUl, SummaryOl, SummarySpanB } from './common';

export const pulmonaryEmbolismSummary = (
  <SummaryOl>
    <li>
      Regard will diagnose pulmonary embolism based on clinical notes and imaging reports (CT and VQ
      scans) within the current encounter.
    </li>
    <li>
      Determine acute vs. chronic.
      <SummaryUl innerList>
        <li>
          Chronic acuity is determined by evidence in previous notes. If there are imaging results
          indicative of an acute PE, that evidence takes precedence.
        </li>
      </SummaryUl>
    </li>
    <li>
      Check for associated heart failure. If the condition is acute <SummarySpanB>and</SummarySpanB>{' '}
      if any imaging (including TTE and TEE) includes any keywords indicating right ventricle
      dysfunction, PE is <SummarySpanB>with cor pulmonale</SummarySpanB>
    </li>
    <li>
      Assess risk-stratification by following the simplified pulmonary embolism severity index
      (sPESI) scoring system and output the sPESI score.
    </li>
  </SummaryOl>
);
