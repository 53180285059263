import { FC, memo, useMemo } from 'react';

import { ConditionNoteBlock } from '~/app/@types/state';
import { interpretationIsCritical } from '~/app/utils';
import { ConditionLineType } from '~/app/@types';

import { ConditionProvider } from '../conditionProvider';
import { Condition } from '.';

type ComputedConditionNoteBlock = {
  isFirstCondition: boolean;
  isFirstNewCondition: boolean;
  isNewCondition: boolean;
  isCriticalCondition: boolean;
};

const getComputedConditionFlags = ({
  conditionNoteBlock,
  firstNewConditionIndex,
  index,
}: {
  conditionNoteBlock: ConditionNoteBlock;
  firstNewConditionIndex: number;
  index: number;
}): ComputedConditionNoteBlock => {
  const { isNew, lines, suggestions } = conditionNoteBlock;

  const isFirstCondition = index === 0;
  const isFirstNewCondition = isNew && index === firstNewConditionIndex;
  const isNewCondition = isNew && index >= firstNewConditionIndex;
  const hasCriticalBulletsOrSuggestions =
    lines.some(
      (line) =>
        line.type === ConditionLineType.Bullet && interpretationIsCritical(line.interpretation)
    ) || //
    suggestions.some((suggestion) => interpretationIsCritical(suggestion.interpretation));

  return {
    isFirstCondition,
    isFirstNewCondition,
    isNewCondition,
    isCriticalCondition: hasCriticalBulletsOrSuggestions,
  };
};

interface ConditionWithProviderProps {
  i: number;
  id: string;
  isLastNoteBlock: boolean;
  noteBlock: ConditionNoteBlock;
  firstNewConditionIndex: number;
}

export const ConditionWithProvider: FC<ConditionWithProviderProps> = memo((props) => {
  const { i, id, isLastNoteBlock, noteBlock, firstNewConditionIndex } = props;
  const { isFirstCondition, isFirstNewCondition, isNewCondition, isCriticalCondition } = useMemo(
    () =>
      getComputedConditionFlags({
        conditionNoteBlock: noteBlock,
        firstNewConditionIndex,
        index: i,
      }),
    [i, firstNewConditionIndex, noteBlock]
  );

  return (
    <ConditionProvider
      id={id}
      isCriticalCondition={isCriticalCondition}
      isFirstCondition={isFirstCondition}
      isFirstNewCondition={isFirstNewCondition}
      isLastNoteBlock={isLastNoteBlock}
      isNewCondition={isNewCondition}
    >
      <Condition />
    </ConditionProvider>
  );
});
ConditionWithProvider.displayName = 'ConditionWithProvider';
