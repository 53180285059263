import ItemList from './ItemList';
import ItemGrid from './ItemGrid';
import FieldList from './FieldList';
import NoteDetails from './NoteDetails';

/*
 * Schemas for displaying the fields of different data types
 */

export const PATIENT_INFO_DISPLAY_FIELDS = [
  { key: 'name' },
  { key: 'ageAndSex' },
  { key: 'dob', label: 'DOB' },
  { key: 'height', label: 'Height' },
  { key: 'weight', label: 'Weight' },
  { key: 'ethnicGroup', label: 'Ethnic Group' },
  { key: 'races', label: 'Races' },
];

/*
 * Definitions for the various screen item filters.
 */

const CURRENT_ALL_FILTER = {
  key: 'currentAllFilter',
  options: ['Current', 'All'],
  default: 'All',
  filter: (filterValue, item) => filterValue === 'All' || item.current === true,
};

const UNRESOLVED_RESOLVED_ALL_FILTER = {
  key: 'unresolvedResolvedAllFilter',
  options: ['Unresolved', 'Resolved', 'All'],
  default: 'Unresolved',
  filter: (filterValue, item) =>
    (item.resolved && filterValue !== 'Unresolved') ||
    (!item.resolved && filterValue !== 'Resolved'),
};

/*
 * Constants and definitions for the EHR screens.
 */

export const ENCOUNTERS_SCREEN = {
  label: 'Encounters',
  itemLabel: 'Encounter',
  contentKey: 'encounters',
  displayFields: [
    { key: 'type', label: 'Encounter Type', listColumn: true },
    { key: 'start', label: 'Start Date', listColumn: true },
    { key: 'end', label: 'End Date', listColumn: true },
  ],
  ItemView: ItemList,
  DetailsView: FieldList,
};

export const NOTES_SCREEN = {
  label: 'Notes',
  itemLabel: 'Note',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'notes',
  displayFields: [
    {
      key: 'type',
      label: 'Note Type',
      listColumn: true,
      draftBadge: true,
      noteTitle: true,
    },
    {
      key: 'createTime',
      label: 'Create Time',
      listColumn: true,
      noteMeta: true,
    },
    { key: 'noteText', label: 'Note Text', noteTextSection: true },
  ],
  ItemView: ItemList,
  DetailsView: NoteDetails,
};

export const PROBLEM_LIST_SCREEN = {
  label: 'Problem List',
  itemLabel: 'Problem',
  filters: [UNRESOLVED_RESOLVED_ALL_FILTER],
  contentKey: 'problems',
  displayFields: [
    { key: 'dx', label: 'Diagnosis', listColumn: true },
    { key: 'icd', label: 'ICD Code' },
    { key: 'notedDate', label: 'Date Noted', listColumn: true },
    { key: 'resolvedDate', label: 'Date Resolved', listColumn: true },
  ],
  ItemView: ItemList,
  DetailsView: FieldList,
};

export const MEDICATIONS_SCREEN = {
  label: 'Medications',
  itemLabel: 'Medication',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'medications',
  displayFields: [
    { key: 'name', label: 'Name' },
    { key: 'formulation', label: 'Formulation', listColumn: true },
    { key: 'components', label: 'Components' },
    { key: 'orderTime', label: 'Order Date', listColumn: true },
    { key: 'startTime', label: 'Start Date', listColumn: true },
    { key: 'endTime', label: 'End Date', listColumn: true },
    { key: 'frequency', label: 'Frequency' },
    { key: 'dose', label: 'Dose' },
    { key: 'route', label: 'Route' },
  ],
  ItemView: ItemList,
  DetailsView: FieldList,
};

export const VITALS_SCREEN = {
  label: 'Vitals',
  itemLabel: 'Measurement',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'vitals',
  displayFields: [
    { key: 'measure', label: 'Measure', gridRow: true },
    { key: 'recordedTime', label: 'Recorded Time', gridColumn: true },
    { key: 'entryTime', label: 'Entry Time' },
    { key: 'value', label: 'Value', gridValue: true },
    { key: 'comment', label: 'Comment' },
    { key: 'location', label: 'Location' },
  ],
  ItemView: ItemGrid,
  DetailsView: FieldList,
};

export const LAB_RESULTS_SCREEN = {
  label: 'Lab Results',
  itemLabel: 'Result',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'labResults',
  displayFields: [
    { key: 'loincId', label: 'LOINC ID' },
    { key: 'procedure', label: 'Procedure' },
    { key: 'procedureComments', label: 'Procedure Comments' },
    { key: 'specimenTakenTime', label: 'Specimen Taken Time', gridColumn: true },
    { key: 'component', label: 'Component', gridRow: true },
    { key: 'resultTime', label: 'Result Time' },
    { key: 'resultValue', label: 'Result Value', gridValue: true },
    { key: 'referenceRange', label: 'Reference Range' },
  ],
  ItemView: ItemGrid,
  DetailsView: FieldList,
};

export const ORDERS_SCREEN = {
  label: 'Orders',
  itemLabel: 'Order',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'orders',
  displayFields: [
    { key: 'description', label: 'Description', listColumn: true },
    { key: 'orderDate', label: 'Order Date', listColumn: true },
  ],
  ItemView: ItemList,
  DetailsView: FieldList,
};

export const IMAGES_SCREEN = {
  label: 'Images',
  itemLabel: 'Result',
  filters: [CURRENT_ALL_FILTER],
  contentKey: 'images',
  displayFields: [
    { key: 'description', label: 'Description', listColumn: true },
    { key: 'orderDate', label: 'Order Date', listColumn: true },
    { key: 'noteText', label: 'Narrative' },
  ],
  ItemView: ItemList,
  DetailsView: FieldList,
};

export const REGARD_SCREEN = {
  label: 'Regard',
  itemLabel: 'regard',
  filters: [],
  contentKey: 'regard',
  displayFields: [],
  ItemView: {},
  DetailsView: {},
};

export const SCREEN_NAV_ITEMS = [
  ENCOUNTERS_SCREEN,
  NOTES_SCREEN,
  PROBLEM_LIST_SCREEN,
  MEDICATIONS_SCREEN,
  VITALS_SCREEN,
  ORDERS_SCREEN,
  LAB_RESULTS_SCREEN,
  IMAGES_SCREEN,
  REGARD_SCREEN,
];
