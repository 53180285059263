import { REDIRECT_PARAMETERS } from '~/app/constants';

export const buildRedirectUrl = (redirectUrl: string) => {
  const curUrl = window.location.search;
  const curUrlLower = `${curUrl.replace('?', '&').toLowerCase()}&`;

  const params: string[] = [];
  REDIRECT_PARAMETERS.forEach((parameter) => {
    // Find parameter in current url and continue if not found
    let pos = curUrlLower.indexOf(`&${parameter[0]}&`);
    if (pos < 0) pos = curUrlLower.indexOf(`&${parameter[0]}=`);
    if (pos < 0) return; // Acts as continue in forEach

    // If the parameter value is not preserved, add just the parameter key to the list
    if (!parameter[1]) {
      params.push(parameter[0]);
      return; // Acts as continue in forEach
    }

    // Otherwise find the parameter value and add it to the list
    const equPos = curUrlLower.indexOf('=', pos + 1);
    const ampPos = curUrlLower.indexOf('&', pos + 1);
    if (ampPos < equPos || equPos < 0) return; // Acts as continue in forEach
    params.push(`${parameter[0]}${curUrl.substring(equPos, ampPos)}`);
  });

  // Build and return url
  return params.length ? redirectUrl.replace('?', `?${params.join('&')}&`) : redirectUrl;
};
