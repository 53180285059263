import { FC } from 'react';
import _ from 'lodash';
import { zIndices } from '~/app/reuse';
import { DESKTOP_GUTTER_WIDTH_VALUE } from '../useMeshedNoteAppStyle';

const curtainBaseStyle = {
  bottom: 0,
  cursor: 'pointer',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  transition: 'background-color 150ms ease',
  zIndex: zIndices.selectBaseNoteCurtain,
} as const;

export const curtainClosedStyle = {
  ...curtainBaseStyle,
  transform: 'translateX(100%)',
};

export const curtainOpenStyle = {
  ...curtainBaseStyle,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  transform: 'translateX(0)',
};

export const getCurtainOpenStyle = _.memoize(
  (isScaledHorizontalLayout: boolean, width: number, isVerticalLayout: boolean) => {
    const noteScaledWidth = `translateX(${(2 * (width - DESKTOP_GUTTER_WIDTH_VALUE)) / 3}px)`;
    const noteNonScaledWidth = isVerticalLayout
      ? 'translateX(100%)'
      : `translateX(${(width - DESKTOP_GUTTER_WIDTH_VALUE) / 2 + DESKTOP_GUTTER_WIDTH_VALUE}px)`;
    return {
      ...curtainOpenStyle,
      transform: isScaledHorizontalLayout ? noteScaledWidth : noteNonScaledWidth,
    } as const;
  }
);

export const SelectBaseNoteCurtain: FC<{
  isOpen: boolean;
  onClick(): void;
}> = ({ isOpen, onClick }) => (
  <div
    aria-label="cancel"
    onClick={onClick}
    role="button"
    style={isOpen ? curtainOpenStyle : curtainClosedStyle}
    tabIndex={0}
  />
);
