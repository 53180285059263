import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Meshed } from '../meshed/Meshed';
import ItemFilters from './ItemFilters';

class ScreenContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      filterValues: Object.assign({}, ...props.filters.map((f) => ({ [f.key]: f.default }))),
    };
    this.updateFilter = this.updateFilter.bind(this);
  }

  static getDerivedStateFromProps({ filters }, state) {
    if (state.filters !== filters) {
      return {
        filters,
        filterValues: Object.assign({}, ...filters.map((f) => ({ [f.key]: f.default }))),
      };
    }
    return null;
  }

  updateFilter(key, value) {
    this.setState((prevState) => ({
      filterValues: { ...prevState.filterValues, [key]: value },
    }));
  }

  render() {
    const { filters, label } = this.props;
    const { filterValues } = this.state;

    if (label === 'Regard') {
      return (
        <div className="htemr-ScreenContent healthtensor">
          <Meshed />
        </div>
      );
    }

    const { ItemView } = this.props;
    const filterItem = (item) =>
      filters.reduce(
        (prevValue, { filter, key }) => prevValue && filter(filterValues[key], item),
        true
      );

    return (
      <div className="htemr-ScreenContent">
        {label && (
          <div className="htemr-ScreenContent-titleBar">
            <h1 className="htemr-ScreenContent-title">{label}</h1>
            <ItemFilters
              filters={filters}
              filterValues={filterValues}
              updateFilter={this.updateFilter}
            />
          </div>
        )}
        <ItemView filterItem={filterItem} />
      </div>
    );
  }
}

ScreenContent.propTypes = {
  label: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.func.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  ItemView: PropTypes.object.isRequired,
};

ScreenContent.defaultProps = {
  label: null,
  filters: [],
};

const mapStateToProps = (state) => ({
  label: state.record.selectedScreen.label,
  filters: state.record.selectedScreen.filters,
  ItemView: state.record.selectedScreen.ItemView,
});

export default connect(mapStateToProps)(ScreenContent);
