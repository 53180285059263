import _ from 'lodash';

import {
  CONDITION_TEXTAREA_BOTTOM_GUTTER,
  CONDITION_TEXTAREA_LINE_HEIGHT,
  NOTE_LEFT_GUTTER,
  NOTE_RIGHT_GUTTER,
} from '../const';
import { theme } from '../../../reuse';
import { isIE } from '../../../utils';

export const indicatorContainerStyle = {
  /**
   * It's extremely important to understand why this padding-right: 2 is here.
   *
   *   1. the condition underlay and its lines contain the same text as the
   *      condition textarea
   *   2. the condition underlay must wrap text exactly as the condition
   *      textarea does; otherwise, when hovering over the condition delete/
   *      move buttons, the text will appear to shift
   *   3. IE10 wraps text in textareas as if they have 2 or 3px less width
   *      than a div of the same size
   *
   * Thus we add a bit of padding to the lines and reduce their width to
   *  compensate. 2px isn't perfect and 3px isn't perfect, so we err on the
   *  side of the lower number.
   *
   * To debug this issue, try changing the color of the condition underlay text
   *  from 'transparent' to 'red'.
   */
  paddingRight: isIE() ? 2 : 0,
  position: 'relative',
} as const;

export const getRootStyle = _.memoize(
  ({
    buttonHovered,
    dismissButtonHovered,
    isLastLine,
  }: {
    buttonHovered: boolean;
    dismissButtonHovered: boolean;
    isLastLine: boolean;
  }) => {
    const color = buttonHovered ? theme.colors.blueMedium : theme.colors.none;
    const { minHeight, paddingBottom } = isLastLine
      ? {
          minHeight: CONDITION_TEXTAREA_LINE_HEIGHT + CONDITION_TEXTAREA_BOTTOM_GUTTER,
          paddingBottom: CONDITION_TEXTAREA_BOTTOM_GUTTER,
        }
      : {
          minHeight: CONDITION_TEXTAREA_LINE_HEIGHT,
          paddingBottom: 0,
        };
    const textDecoration = dismissButtonHovered ? 'line-through' : undefined;

    return {
      color,
      fontWeight: 'initial',
      minHeight, // so empty lines still have height! (this should match the line height!)
      paddingBottom,
      paddingLeft: NOTE_LEFT_GUTTER,
      paddingRight: NOTE_RIGHT_GUTTER,
      position: 'relative',
      textDecoration,
    } as const;
  }
);
