import _ from 'lodash';
import { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { Patient } from '../../../@types/state';
import { CONDITION_TEXTAREA_LINE_HEIGHT, CONDITION_TEXTAREA_BOTTOM_GUTTER } from '../../note/const';

const overflowWrap = 'break-word';

export const wrapStyle = {
  overflowWrap, // 'overflow-wrap' is the updated name for 'word-wrap'
  whiteSpace: 'pre-wrap', // 'break-spaces' would be best but it's not supported by IE10
  wordWrap: overflowWrap,
} as const;

const baseTextareaStyle = {
  ...wrapStyle,
  background: 'none',
  border: 0,
  color: 'inherit',
  display: 'block',
  lineHeight: `${CONDITION_TEXTAREA_LINE_HEIGHT}px`,
  overflow: 'hidden',
  padding: `0 0 ${CONDITION_TEXTAREA_BOTTOM_GUTTER}px`,
  position: 'relative',
  resize: 'none',
  width: '100%',
} as const;

const style = {
  ...baseTextareaStyle,
  padding: '4px 8px',
  margin: '-8px',
  width: 'calc(100% + 16px)',
};

export const DemoNote: FC<{
  draftIndicatorStyle: {
    color: string;
  };
  selectedItem: Patient['notes'][0];
  textareaStyle: {
    background: string;
    border: string;
  };
}> = ({
  draftIndicatorStyle,
  selectedItem: { createTime, draft, noteText, type },
  textareaStyle,
}) => (
  <>
    <div>
      <b>
        {_.startCase(type)} from {createTime.toISOString()}
        {draft ? (
          <>
            {' '}
            <span style={draftIndicatorStyle}>Draft</span>
          </>
        ) : (
          ''
        )}
      </b>
    </div>
    <div>
      {noteText}
      {draft && (
        <TextareaAutosize
          draggable={false}
          placeholder="Write your note here"
          style={{
            ...style,
            ...textareaStyle,
          }}
        />
      )}
    </div>
  </>
);
