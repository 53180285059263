import _ from 'lodash';
import { ComponentProps, CSSProperties, FC } from 'react';

import { epicTheme } from './epicTheme';

export const EpicTab: FC<{
  accentColor: string;
  isSelected: boolean;
  label: string;
  onClick?(): void;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: CSSProperties; // used by EpicTabs
}> = ({ accentColor, isSelected, label, onClick }) =>
  onClick ? (
    <button
      onClick={isSelected ? _.noop : onClick}
      style={{
        alignItems: 'center',
        backgroundColor: epicTheme.colors.lightGray,
        display: 'flex',
        flexDirection: 'column',
        height: 48,
        marginRight: 4,
        opacity: isSelected ? 1 : 0.75,
        position: 'relative',
        top: isSelected ? 0 : 4,
        width: 128,
      }}
      type="button"
    >
      <div style={{ height: 8, width: '100%' }}>
        <div
          style={{
            backgroundColor: accentColor,
            height: isSelected ? 8 : 4,
          }}
        />
      </div>
      <div
        style={{
          alignItems: 'center',
          color: epicTheme.colors.darkBlue,
          display: 'flex',
          fontWeight: 'bold',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        {label}
      </div>
    </button>
  ) : null;

export type EpicTabProps = ComponentProps<typeof EpicTab>;
