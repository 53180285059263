import { theme } from '~/app/reuse';
import { NOTE_RIGHT_GUTTER } from '../const';

const { blueRegular, grey3, lightBlue2, lightBlue3, none } = theme.colors;

export const color = {
  backgroundColor: none,
  color: 'inherit',
  '&:focus': {
    backgroundColor: lightBlue2,
    color: blueRegular,
  },
  '&:hover': {
    backgroundColor: lightBlue2,
    color: blueRegular,
  },
  '&:active': {
    backgroundColor: lightBlue3,
    color: blueRegular,
  },
  '&:disabled': {
    backgroundColor: none,
    color: grey3,
  },
};

export const size = {
  fontSize: 14,
  lineHeight: '20px',
  minWidth: 0,
  padding: '0 0 0 27px', // another 1px comes from the border, totalling 28px,
  '& [data-button-start-icon]': {
    marginRight: 0,
    marginTop: 0,
  },
};

export const buttonCss = {
  borderRadius: 0,
  display: 'block',
  fontWeight: 400,
  paddingRight: NOTE_RIGHT_GUTTER,
  position: 'relative',
  textAlign: 'left',
  width: '100%',
} as const;

export const iconStyle = {
  height: 16,
  left: 6,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: 16,
} as const;

export const criticalFlagStyle = {
  position: 'absolute',
  top: -1,
  left: -1,
  width: 0,
  height: 0,
  borderLeft: `6px solid ${theme.colors.red}`,
  borderTop: `6px solid  ${theme.colors.red}`,
  borderRight: '6px solid transparent',
  borderBottom: '6px solid transparent',
  pointerEvents: 'none',
} as const;
