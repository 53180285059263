import { noop } from 'lodash';
import { FC, createContext, memo } from 'react';
import { shallowEqual } from 'react-redux';

import { createSelectorProvider, omit, useContextSelector } from '~/app/utils';
import { TypingDirection } from '~/app/actions/regardNote';

import { useAddCondition } from './useAddCondition';
import { useDismissCondition } from './useDismissCondition';
import { useShelveCondition } from './useShelveCondition';
import { useNumberOfNewConditions } from './useNumberOfNewConditions';
import { useReviewMode } from './useReviewMode';
import { useMergeCondition } from './useMergeCondition';

type NoteContext = {
  addCondition(index: number): void;
  dismissCondition(conditionId: string): void;
  mergeCondition(conditionId: string, direction: TypingDirection): void;
  numberOfNewConditions: number;
  reviewMode: boolean;
  setTimeoutDelay(fn: () => void): void;
  shelveCondition(conditionId: string): void;
  throttleDelay: <T extends unknown[]>(fn: (...args: T) => void) => (...args: T) => void;
};

const defaultNoteContext: NoteContext = {
  addCondition: noop,
  dismissCondition: noop,
  mergeCondition: noop,
  numberOfNewConditions: 0,
  reviewMode: false,
  setTimeoutDelay: (fn) => fn(),
  shelveCondition: noop,
  throttleDelay:
    (fn) =>
    (...args) =>
      fn(...args),
};

const NoteContext = createContext<NoteContext>(defaultNoteContext);
const NoteContextProvider = createSelectorProvider(NoteContext);

export const useNoteContext = <T,>(selector: (context: NoteContext) => T): T =>
  useContextSelector<NoteContext, T>(NoteContext, selector);

export const NoteProvider: FC<Partial<Pick<NoteContext, 'throttleDelay' | 'setTimeoutDelay'>>> =
  memo(
    ({
      children,
      throttleDelay = defaultNoteContext.throttleDelay,
      setTimeoutDelay = defaultNoteContext.setTimeoutDelay,
    }) => {
      const addCondition = useAddCondition();
      const dismissCondition = useDismissCondition();
      const mergeCondition = useMergeCondition();
      const shelveCondition = useShelveCondition();
      const numberOfNewConditions = useNumberOfNewConditions();
      const reviewMode = useReviewMode();

      return (
        <NoteContextProvider
          value={{
            addCondition,
            dismissCondition,
            mergeCondition,
            numberOfNewConditions,
            reviewMode,
            setTimeoutDelay,
            shelveCondition,
            throttleDelay,
          }}
        >
          {children}
        </NoteContextProvider>
      );
    },
    /**
     * This is an optimization.
     *
     * We prevent children from triggering a rerender because children will
     *  always trigger a rerender--it defeats the memo function and is not useful
     *  in this respect. If we want the child of NoteProvider to rerender,
     *  assuming that this child is Conditions, we should expect a prop on
     *  NoteProvider to change.
     *
     */
    (prevProps, nextProps) => shallowEqual(omit(prevProps, 'children'), omit(nextProps, 'children'))
  );
NoteProvider.displayName = 'NoteProvider';
