import { FC, ReactNode, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { UiState } from '../../../../@types/state';
import { getTimeDifferenceInWords } from '../../../../utils/date';
import { Icon } from '../../../../reuse';

import { useMeshedContext } from '../../MeshedProvider';
import { iconStyle, saveDraftNoteStatusStyle } from './css';
import { useSaveDraftNoteStatus } from './useSaveDraftNoteStatus';

const getPretext = ({
  isCondensedView,
  isDifferenceInWords,
}: {
  isCondensedView: boolean;
  isDifferenceInWords: boolean;
}) => {
  if (isCondensedView) return isDifferenceInWords ? '' : 'Saved';

  return isDifferenceInWords ? 'Saved ' : 'Edits Saved';
};

const getSaveDraftNoteStatusText = ({
  currentTime,
  isCondensedView,
  lastSaved,
  status,
}: {
  currentTime: DateNumberValue;
  isCondensedView: boolean;
  lastSaved: UiState['saveDraftNoteLastSaved'];
  status: UiState['saveDraftNoteStatus'];
}): ReactNode => {
  if (status === 'error' && !lastSaved)
    return (
      <>
        <Icon iconName="noFile" style={iconStyle} />
        Autosave error
      </>
    );
  if (status === 'saved') {
    const differenceInWords = getTimeDifferenceInWords(currentTime, lastSaved);
    const pretext = getPretext({
      isCondensedView,
      isDifferenceInWords: !!differenceInWords,
    });

    return (
      <>
        <Icon iconName="fileCheck" style={iconStyle} />
        {pretext} {differenceInWords}
      </>
    );
  }
  if (status === 'saving') return 'Saving...';

  return null;
};

export const SaveDraftNoteStatus: FC = () => {
  const { isVerticalLayout } = useMeshedContext(({ isVerticalLayout }) => ({
    isVerticalLayout,
  }));

  const { saveDraftNoteLastSaved, saveDraftNoteStatus } = useSaveDraftNoteStatus();

  const [currentTime, setCurrentTime] = useState<DateNumberValue>(new Date().valueOf());

  useInterval(() => setCurrentTime(new Date().valueOf()), 60 * 1000 /* one minute */);

  return (
    <span data-cy-save-draft-note-status style={saveDraftNoteStatusStyle}>
      {getSaveDraftNoteStatusText({
        currentTime,
        isCondensedView: isVerticalLayout,
        lastSaved: saveDraftNoteLastSaved,
        status: saveDraftNoteStatus,
      })}
    </span>
  );
};
