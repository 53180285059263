import styled from '@emotion/styled';
import { theme } from '~/app/reuse';

export const ConditionInnerWrapper = styled.div({
  position: 'relative',
  // when the condition is not hovered and move menu is closed
  '&:not([data-condition-move-menu-open="true"]):not(:hover)': {
    '[data-condition-hover-background]': { opacity: 0 },
    '[data-condition-buttons]': { visibility: 'hidden' },
  },
  // when the condition is hovered
  '&:hover': {
    '[data-condition-default-background]': { opacity: 0 },
    '[data-condition-open-condition-button]:not(:focus):not(:hover):not(:active):not(:disabled)': {
      color: theme.colors.blueRegular,
    },
  },
});

export const textStyle = {
  position: 'relative',
} as const;
