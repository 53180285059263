import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import _ from 'lodash';

import { IconButtonColor, IconButtonSize } from './types';
import { theme } from '../theme';
import { ButtonBase } from '../buttonBase';

const {
  blueRegular,
  blueMedium,
  blueDark,
  grey1,
  grey4,
  grey5,
  lightBlue2,
  lightBlue3,
  none,
  white,
} = theme.colors;

const coreStyle = {
  borderColor: none,
  borderRadius: theme.other.borderRadius4,
  borderStyle: 'solid',
  borderWidth: '1px',
  display: 'inline-block',
  position: 'relative',
  userSelect: 'none',
} as const;

export const iconStyle = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
} as const;

type IconButtonColorCSSBase = {
  backgroundColor: string;
  color: string;
};

export type IconButtonColorCSS = IconButtonColorCSSBase &
  Record<'&:focus' | '&:hover' | '&:active, &.active' | '&:disabled', IconButtonColorCSSBase>;

const colorCss: Record<IconButtonColor, IconButtonColorCSS> = {
  primary: {
    backgroundColor: blueRegular,
    color: white,
    '&:focus': {
      backgroundColor: blueMedium,
      color: white,
    },
    '&:hover': {
      backgroundColor: blueMedium,
      color: white,
    },
    '&:active, &.active': {
      backgroundColor: blueDark,
      color: white,
    },
    '&:disabled': {
      backgroundColor: grey4,
      color: white,
    },
  },
  'tertiary-blue': {
    backgroundColor: none,
    color: blueRegular,
    '&:focus': {
      backgroundColor: lightBlue2,
      color: blueMedium,
    },
    '&:hover': {
      backgroundColor: lightBlue2,
      color: blueMedium,
    },
    '&:active, &.active': {
      backgroundColor: lightBlue3,
      color: blueDark,
    },
    '&:disabled': {
      backgroundColor: none,
      color: grey4,
    },
  },
  'tertiary-grey': {
    backgroundColor: none,
    color: grey1,
    '&:focus': {
      backgroundColor: grey4,
      color: grey1,
    },
    '&:hover': {
      backgroundColor: grey5,
      color: grey1,
    },
    '&:active, &.active': {
      backgroundColor: grey4,
      color: grey1,
    },
    '&:disabled': {
      backgroundColor: none,
      color: grey4,
    },
  },
};

export type IconButtonSizeCSS = {
  height: number | string;
  width: number | string;
};

const sizeCss: Record<IconButtonSize, IconButtonSizeCSS> = {
  standard: {
    height: 28,
    width: 28,
  },
  small: {
    height: 24,
    width: 24,
  },
  'small-wide': {
    height: 24,
    width: 38,
  },
  mini: {
    height: 20,
    width: 20,
  },
};

export const Root = styled(ButtonBase, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'color',
})(
  coreStyle,
  ({
    color,
    size,
  }: {
    color: IconButtonColor | IconButtonColorCSS;
    size: IconButtonSize | IconButtonSizeCSS;
  }) => [_.isString(color) ? colorCss[color] : color, _.isString(size) ? sizeCss[size] : size]
);
