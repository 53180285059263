/* eslint-disable max-classes-per-file */

// Custom Errors for Automed FE app

// Custom error for reporting dismiss reason to Sentry
export class BulletNoteStatusError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Failed to save BulletNoteStatus';
  }
}

// Custom error for reporting custom feedback to Sentry
// when POST to flask server fails.
export class FeedbackFailed extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Failed To Save User Feedback';
  }
}

export class MeshingNotesError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Meshing Notes Failed';
  }
}

export class RegardApiError extends Error {
  // `message` should be a simplified version of the url used
  constructor(message: string) {
    super(message);
    this.name = 'Regard API Error';
  }
}

export class RegardNoteError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Regard Note Error';
  }
}

export class InvalidBulletID extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Warning: Invalid Bullet ID Found';
  }
}

export class InvalidBulletText extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Warning: Invalid Bullet Text Found';
  }
}

export class MissingRequiredConfigValues extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Missing required config values';
  }
}
