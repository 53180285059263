import { FC, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { SizeMeProps, withSize } from 'react-sizeme';

import { RegardLoading } from '~/app/components/meshed/regardLoading';
import { PatientLoadPhase } from '~/app/@types/types';
import { getOAuthSubject as getOAuthSubjectUtil } from '~/app/cookies';
import { theme } from '~/app/reuse';

import { unleashClient } from './unleash';
import { isFlagEnabledForUser } from './isFlagEnabled';
import { FlagValues } from './types';
import { getFlagValue } from './getFlagValue';

/**
 * React provider and hooks related to feature flags in the regard app.
 * Inspired by @unleash/proxy-client-react with additions
 * to include the legacy `window` and `localStorage` Regard flags.
 * See https://github.com/Unleash/proxy-client-react/
 */

type ProviderState = {
  flagsReady: boolean;
  flagsError: null | Error;
};

// Duplicating the helper function in MeshedProvider to avoid a circular dependency
const getIsVerticalLayout = (width: number): boolean => width < theme.breakpoints.tablet;

/**
 * Regard feature flags are pulled from multiple places and fallback in the following order:
 *   1. Unleash
 *   2. config-from-db
 *   3. Window
 *   4. LocalStorage
 * Note: The higher number takes precedence. ie. LocalStorage overrides Window, &c.
 */
const UnsizedFlagProvider: FC<SizeMeProps> = ({ children, size }) => {
  const [providerState, setProviderState] = useState<ProviderState>({
    flagsReady: false,
    flagsError: null,
  });

  const isVerticalLayout = getIsVerticalLayout(size.width ?? 1024);

  useEffect(() => {
    const errorCallback = (e: Error) => {
      Sentry.captureException(e);
      setProviderState((state) => ({ ...state, flagsError: state.flagsError || e }));
    };

    const clearErrorCallback = () => {
      setProviderState((state) => ({ ...state, flagsError: null }));
    };

    let timeout = 0;
    const readyCallback = () => {
      // wait for flags to resolve after useFlag gets the same event
      timeout = window.setTimeout(() => {
        setProviderState((state) => ({ ...state, flagsReady: true }));
      }, 0);
    };

    unleashClient.on('ready', readyCallback);
    unleashClient.on('error', errorCallback);
    unleashClient.on('recovered', clearErrorCallback);

    const userId = getOAuthSubjectUtil();
    unleashClient.setContextField('userId', userId);
    unleashClient.start();

    return () => {
      unleashClient.off('error', errorCallback);
      unleashClient.off('ready', readyCallback);
      unleashClient.off('recovered', clearErrorCallback);
      unleashClient.stop();
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  // Since we only support a single unleash client, we don't need a true "provider"

  return providerState.flagsReady || providerState.flagsError ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <RegardLoading
      estimatedPatientLoadDuration="SHORT"
      isVerticalLayout={isVerticalLayout}
      percentage={0}
      phase={PatientLoadPhase.Loading}
    />
  );
};

export const FlagProvider = withSize()(UnsizedFlagProvider);

export const useUserFlag = (flagName: keyof Flags) => {
  // Don't actually need to pull the context to get the client since we only
  // use one for the entire app.
  // const { flagsReady, flagsError, unleashClient } = useContext(FlagContext);
  const [flag, setFlag] = useState(unleashClient.isEnabled(flagName));
  const flagRef = useRef<typeof flag>();
  flagRef.current = flag;

  useEffect(() => {
    if (!unleashClient) throw new Error('useFlag must be used within a FlagProvider');

    const updateHandler = () => {
      const enabled = isFlagEnabledForUser(flagName, {}, unleashClient);
      if (enabled !== flagRef.current) {
        flagRef.current = enabled;
        setFlag(!!enabled);
      }
    };

    unleashClient.on('update', updateHandler);
    return () => {
      unleashClient.off('update', updateHandler);
    };
  }, [flagName]);

  return flag;
};

export const useValueFlag = (flagName: keyof Flags) => {
  // Don't actually need to pull the context to get the client since we only
  // use one for the entire app.
  // const { flagsReady, flagsError, unleashClient } = useContext(FlagContext);
  const [flag, setFlag] = useState<FlagValues>(!!unleashClient.isEnabled(flagName));
  const flagRef = useRef<FlagValues>();
  flagRef.current = flag;

  useEffect(() => {
    if (!unleashClient) throw new Error('useFlag must be used within a FlagProvider');

    const updateHandler = () => {
      const value = getFlagValue(flagName, {}, unleashClient);
      if (value !== flagRef.current) {
        flagRef.current = value;
        setFlag(value);
      }
    };

    unleashClient.on('update', updateHandler);
    return () => {
      unleashClient.off('update', updateHandler);
    };
  }, [flagName]);

  return flag;
};
