import { FC } from 'react';

import { CernerHeader } from './CernerHeader';
import { CernerTable } from './CernerTable';
import { CernerTab } from './CernerTab';
import { CernerTabs } from './CernerTabs';
import { cernerTheme } from './cernerTheme';
import { CernerTabImageContent } from './CernerTabImageContent';
import { CernerTabMedicationContent } from './CernerTabMedicationContent';
import { CernerTabNotesContent } from './CernerTabNotesContent';
import { Meshed } from '../../meshed/Meshed';
import { DemoGrid } from '../demoGrid';
import { COMPANY_NAME } from '../../../constants';

export const CernerDemo: FC = () => (
  <div
    css={{
      '*': {
        boxSizing: 'border-box',
      },
    }}
    data-cy-demo-cerner
    style={{
      backgroundColor: cernerTheme.colors.darkBlue,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <CernerHeader />
    <CernerTabs>
      <CernerTab label="Workflow">
        <CernerTable
          label="Vital Signs"
          rows={[
            [null, <b>12:00</b>, <b>1:00</b>, <b>2:00</b>, <b>3:00</b>, <b>4:00</b>, <b>5:00</b>],
            ['Temp', '98.6', null, null, null, null, null],
            ['BP', '132/72', '130/67', '130/67', '130/67', '130/67', '130/67'],
            ['Respiratory Rate', '35', null, null, null, null, null],
            ['Oxygen saturation', '97', null, null, null, null, null],
            ['Weight', '69.4', null, null, null, null, null],
            ['Height', '152.4', null, null, null, null, null],
          ]}
          style={{ marginTop: 48 }}
        />
        <CernerTable
          label="Home Medications"
          rows={[
            ['Insulin glargine', 'dose'],
            ['Metoprolol', '132/72'],
            ['Losinopril', 'dose'],
          ]}
          style={{ marginTop: 48 }}
        />
      </CernerTab>
      <CernerTab add label="Order Profile">
        <CernerTable
          label={
            <span>
              Orders <span style={{ fontSize: 14, fontWeight: 400 }}>(23)</span>
            </span>
          }
          rows={[
            [null, <b>Start</b>, <b>Status</b>, <b>Status updated</b>, <b>Ordering provider</b>],
            {
              type: 'header',
              label: 'Medications',
            },
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['med name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            {
              type: 'header',
              label: 'Laboratory',
            },
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
            ['lab name', '06/09/2021', 'ordered', '06/09/2021', 'Williams MD, Mika'],
          ]}
        />
      </CernerTab>
      <CernerTab add label="Medication List">
        <CernerTabMedicationContent />
      </CernerTab>
      <CernerTab label="Results Review">
        <CernerTable label="Labs" rows={[]} />
        <div style={{ height: 10 }} />
        <DemoGrid accentColor={cernerTheme.colors.lightGray} />
      </CernerTab>
      <CernerTab label="Microbiology">
        <div />
      </CernerTab>
      <CernerTab
        label="Notes"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CernerTabNotesContent />
      </CernerTab>
      <CernerTab
        label={COMPANY_NAME}
        style={{
          padding: 0,
        }}
      >
        <div
          style={{
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            position: 'relative',
            height: '100%',
          }}
        >
          <Meshed />
        </div>
      </CernerTab>
      <CernerTab
        label="Documentation"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CernerTabNotesContent />
      </CernerTab>
      <CernerTab label="MAR Summary">
        <div />
      </CernerTab>
      <CernerTab label="Inpatient Summary">
        <div />
      </CernerTab>
      <CernerTab label="Review Chart">
        <div />
      </CernerTab>
      <CernerTab label="Diagnoses &amp; Problems">
        <div />
      </CernerTab>
      <CernerTab label="Histories">
        <div />
      </CernerTab>
      <CernerTab
        add
        label="Images"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CernerTabImageContent />
      </CernerTab>
      <CernerTab label="Patient Information">
        <div />
      </CernerTab>
    </CernerTabs>
  </div>
);
