import { SummaryP } from './common';

export const goutSummary = (
  <SummaryP first>
    Regard will search the patient’s history for gout and any associated medications before
    recommending the diagnosis. Using the information from all encounters the algorithm will
    determine if the condition is chronic or acute. Other relevant information such as medications,
    diagnostic reports, and procedures will be displayed.
  </SummaryP>
);
