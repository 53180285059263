import _ from 'lodash';
import { CSSProperties, FC, memo } from 'react';

import { criticalStyle, nextStatusStyleMap, previousStatusStyleMap, statusStyleMap } from './css';
import { ConditionLineStatus } from '../../../@types';

const getStyle = _.memoize(
  ({
    left,
    nextStatus,
    previousStatus,
    status,
    isCritical,
  }: {
    left: number;
    nextStatus: ConditionLineStatus;
    previousStatus: ConditionLineStatus;
    status: ConditionLineStatus;
    isCritical: boolean;
  }): CSSProperties => ({
    bottom: 0,
    left,
    position: 'absolute',
    top: 0,
    ...previousStatusStyleMap[status][previousStatus],
    ...statusStyleMap[status],
    ...nextStatusStyleMap[status][nextStatus],
    ...(isCritical ? criticalStyle : {}),
  })
);

export const ConditionLineStatusIndicator: FC<{
  left: number;
  nextStatus: ConditionLineStatus;
  previousStatus: ConditionLineStatus;
  status: ConditionLineStatus;
  isCritical: boolean;
}> = memo(({ left, nextStatus, previousStatus, status, isCritical }) => (
  <div
    data-cy-condition-line-is-critical={isCritical || undefined}
    data-cy-condition-line-status-indicator={status}
    style={getStyle({
      left,
      nextStatus,
      previousStatus,
      status,
      isCritical,
    })}
  />
));
