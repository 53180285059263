import { useMemo } from 'react';

import { createSelector, useShallowEqualSelector } from '~/app/store';
import { get } from '~/app/utils';

const BLANK_BLOCK = {
  lines: [],
  textTimestamp: undefined,
};

const textTimestampCache: Record<string /* note section id */, DateNumberValue> = {};
interface UseNoteBlockTextReturn {
  htmlLines: HtmlString[];
  textTimestamp: DateNumberValue;
}

export const useConditionText = (id: string): UseNoteBlockTextReturn => {
  const getNoteBlockText = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.noteBlocksById, id),
        (noteBlock) =>
          noteBlock
            ? {
                lines: noteBlock.lines,
                textTimestamp: noteBlock.textTimestamp,
              }
            : BLANK_BLOCK
      ),
    [id]
  );
  const { lines, textTimestamp } = useShallowEqualSelector(getNoteBlockText);

  /**
   * Caching the old timestamp value makes ids more consistent; it prevents the
   *  case where we switch timestamps back and forth like:
   *
   * BAD
   * undefined (0) => 1621551963182 => undefined (0)
   *                        ^
   *    (back to 21 again, because scrollIntoView is now undefined)
   *
   * GOOD
   * undefined (0) => 1621551963182 => 1621551963182
   *
   * If we don't do this, the condition textarea will unexpectedly lose focus
   *  as the text timestamp, which is the key on the textarea, changes
   *  unnecessarily.
   */
  if (textTimestamp) textTimestampCache[id] = textTimestamp;
  const cachedTextTimestamp = get(textTimestampCache, id) ?? 0;

  const htmlLines = lines.map(({ html }) => html);

  return {
    htmlLines,
    textTimestamp: cachedTextTimestamp,
  };
};
