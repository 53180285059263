import { FC } from 'react';

import { Button } from '../../../reuse';

const cancelButtonStyle = {
  marginRight: 4,
};

export const SelectBaseNoteButtons: FC<{
  isSubmitEnabled: boolean;
  onSubmit(): void;
  onCancel(): void;
}> = ({ isSubmitEnabled, onSubmit, onCancel }) => (
  <>
    <Button
      color="tertiary"
      data-cy-cyob-dropdown-selector-cancel-button
      onClick={onCancel}
      size="standard"
      style={cancelButtonStyle}
    >
      Cancel
    </Button>
    <Button
      color="primary"
      data-cy-cyob-dropdown-selector-load-button
      disabled={!isSubmitEnabled}
      onClick={onSubmit}
      size="standard"
    >
      Load Note
    </Button>
  </>
);
