import { Patient } from '../../../@types/state';
import { createSelector, useSelector } from '../../../store';

const contentKey = 'labResults';
const gridColumn = { key: 'specimenTakenTime', label: 'Specimen Taken Time' } as const;
const gridRow = { key: 'component', label: 'Component' } as const;
const gridValue = { key: 'resultValue', label: 'Result Value' } as const;

const demoGridSelector = createSelector(
  (state) => state.record,
  (record) => {
    const columns: ISODateString[] = [];
    const unorderedRows: Record<string, Record<ISODateString, Patient['labResults'][0]>> = {};

    if (record.data) {
      record.data[contentKey].forEach((item) => {
        const gridColumnValue = item[gridColumn.key].toISOString();
        if (columns.indexOf(gridColumnValue) === -1) {
          columns.push(gridColumnValue);
        }

        const gridRowValue = item[gridRow.key];
        if (!unorderedRows[gridRowValue]) {
          unorderedRows[gridRowValue] = {};
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Unfortunately, tsc cannot understand using ISODateString as an index
        unorderedRows[gridRowValue][gridColumnValue] = item;
      });
    }

    const rows = Object.keys(unorderedRows)
      .sort()
      .map((rowName) => ({ label: rowName, items: unorderedRows[rowName] }));

    return {
      columns,
      rows,
      gridValueKey: gridValue.key,
    };
  }
);

export const useDemoGrid = () => {
  const sections = useSelector(demoGridSelector);

  return sections;
};
