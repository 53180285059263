import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { formatDate } from './content';
import PatientInfo from './PatientInfo';
import ScreenNav from './ScreenNav';
import ScreenContent from './ScreenContent';
import DetailsPopup from './DetailsPopup';
import { AppState } from '../../store';
import '../../styles/Pmemr.scss';

const UnconnectedMedicalRecord: FC<
  PropsFromRedux & {
    timestamp: ISODateString;
  }
> = ({ selectedItem, selectedScreen, timestamp }) => (
  <div className="htemr-App" data-cy-demo-classic>
    <header className="htemr-Header">
      <div className="htemr-Header-logo">EHR</div>
      <div className="htemr-Header-timestamp">{formatDate(new Date(timestamp))}</div>
    </header>
    <PatientInfo />
    <div className="htemr-App-divider" />
    <div className="htemr-App-patientScreens">
      <ScreenNav />
      <div className="htemr-App-screenContentAndDetails">
        {selectedScreen && (
          // eslint-disable-next-line
          // @ts-ignore
          <ScreenContent />
        )}
        {selectedItem && <DetailsPopup />}
      </div>
    </div>
  </div>
);

const connector = connect(({ record }: AppState) => ({
  selectedScreen: !!record.selectedScreen,
  selectedItem: !!record.selectedItem,
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

export const MedicalRecord = connector(UnconnectedMedicalRecord);
