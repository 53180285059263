import { SummaryP } from './common';

export const acidBaseDisorderSummary = (
  <>
    <SummaryP first>
      The Condition will be recommended if there is an abnormal blood gas or bicarbonate. Values
      considered: arterial blood gas (ABG), venous blood gas (VBG), bicarbonate (HCO3), partial
      pressure of carbon dioxide dissolved in blood (pCO2).
    </SummaryP>
    <SummaryP>
      Respiratory or metabolic pathways are identified and ABGs are prioritized over VBGs for acid
      base imbalances. PO2 will be tracked within the diagnosis.
    </SummaryP>
  </>
);
