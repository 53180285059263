import { FC, useCallback } from 'react';

import { BulletType } from '~/app/@types';
import { BulletNoteStatusRecord, Item } from '~/app/@types/state';
import { Button } from '~/app/reuse';
import { track } from '~/app/analytics';

import { isNoted } from '~/app/controllers/bulletNoteStatus';
import { stringifyItems } from '~/app/controllers/stringifyItems';
import '~/app/styles/DismissedComplaint.scss';

import { plainTextToHtmlString } from '~/app/utils';
import { useDispatch } from '~/app/store';
import { ConditionLineCriticalFlag, SvgStyle } from '../note/conditionLineCriticalFlag';

export const DismissedComplaint: FC<{
  bulletsToBeDisplayed: { text: string; isCritical: boolean }[];
  bulletsToBeRestored: {
    id: string;
    text: string;
    type: BulletType;
  }[];
  bulletNoteStatusByBulletId: BulletNoteStatusRecord;
  module: string;
  title: Item[];
}> = ({ bulletsToBeDisplayed, bulletsToBeRestored, bulletNoteStatusByBulletId, module, title }) => {
  const dispatch = useDispatch();

  const restore = useCallback(
    (conditionText: string) => {
      dispatch({
        type: 'modify note block',
        payload: {
          conditionHtml: plainTextToHtmlString(conditionText),
          type: 'restore',
        },
      });
    },
    [dispatch]
  );

  const formattedTitle = `# ${stringifyItems(title)}`;
  const hasCriticalBullet = bulletsToBeDisplayed.some((bullet) => bullet.isCritical);

  return (
    <div
      className="DismissedComplaint"
      data-condition-is-critical={hasCriticalBullet}
      data-cy-dismissed-condition={module}
    >
      <div className="condition" data-cy-dismissed-condition-title>
        {formattedTitle}
      </div>
      <ul key="note-text" data-cy-dismissed-condition-bullets>
        {bulletsToBeDisplayed.map(({ text, isCritical }, index) => (
          <li key={index}>
            {isCritical && <ConditionLineCriticalFlag left={-20} svgStyle={SvgStyle.Note} />}-{' '}
            {text}
          </li>
        ))}
      </ul>
      <div key="dismissed-by" className="dismissed-by">
        <div className="text" />
        <Button
          color="secondary"
          onClick={() => {
            const bulletText = bulletsToBeRestored
              .map((bullet) =>
                [BulletType.Assessment, BulletType.Plan].includes(bullet.type) &&
                isNoted(`${module}.${bullet.id}`, bulletNoteStatusByBulletId)
                  ? `\n- ${bullet.text}`
                  : ''
              )
              .join('');
            track.conditionRestored({ module, uiElement: 'restore button' });
            restore(`${formattedTitle}${bulletText}`);
          }}
          size="small"
          startIconName="restore"
        >
          Restore
        </Button>
      </div>
    </div>
  );
};
