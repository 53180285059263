import { SummaryOl, SummarySpanB, SummaryUl } from './common';

export const malnutritionSummary = (
  <SummaryOl>
    <li>
      Clinical notes for current encounter are gathered with dietitian notes filtered and parsed.
      <SummaryUl innerList>
        <li>
          If positive indications of malnutrition are present, dx is{' '}
          <SummarySpanB>positive</SummarySpanB>.
        </li>
        <li>
          If dietician notes during this encounter contain negative indicators for malnutrition
          (“well nourished”, “does not meet established malnutrition criteria”) dx is{' '}
          <SummarySpanB>negative</SummarySpanB>.
        </li>
        <li>Any sentences containing valid recommendations from dietitian notes will be listed.</li>
      </SummaryUl>
    </li>
    <li>
      <SummarySpanB>Severity</SummarySpanB> is determined by the dietician note containing “mild”,
      “moderate”, or “severe” followed by any malnutrition phrasing.
    </li>
    <li>
      <SummarySpanB>Acuity</SummarySpanB> is determined based on the dietician note containing
      “acute” or “chronic” directly preceding any valid malnutrition phrasing.
    </li>

    <li>
      Height, weight, and BMI from the past 26 weeks are reviewed, removing outliers from patients
      of ages 21 years or older.
    </li>
  </SummaryOl>
);
