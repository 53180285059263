import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';

import { BulletType, BulletNoteStatus } from '~/app/@types';
import { AppState } from '~/app/@types/state';
import { NEW_DIFF, UPDATED_DIFF, OUTDATED_DIFF } from '~/app/constants';
import { isHidden, isMonitored } from '~/app/controllers/bulletNoteStatus';
import { updateBulletNoteStatusFromDxDetailsThunk } from '~/app/actions/regardNote';
import { track } from '~/app/analytics';
import { getIsModuleShelved, interpretationIsCritical } from '~/app/utils';
import CheckInCircleSVG from '~/app/images/check-in-circle.svg';

import { ConditionLineCriticalFlag, SvgStyle } from '../note/conditionLineCriticalFlag';
import '~/app/styles/BulletDetails.scss';

const RESTORE_TEXT = 'restore';
const ADD_TEXT = 'add';

type BulletItemProps = {
  children: string | JSX.Element;
  buttonText: string;
  isNew: boolean;
  isCritical: boolean;
  updateBulletFlag: (() => void) | undefined;
};

const BulletItem = ({
  children,
  buttonText = '',
  isNew,
  updateBulletFlag,
  isCritical,
}: BulletItemProps): JSX.Element => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  return (
    <li className={classNames({ 'bullet-item-hovered': isMouseOver })} data-cy-bullet-item>
      <span className={classNames({ new: isNew, 'not-new': !isNew, critical: isCritical })}>
        {children}
      </span>
      <span
        className={classNames('bullet-button', { 'bullet-button-hoverable': buttonText })}
        onClick={updateBulletFlag}
        onMouseEnter={buttonText ? () => setIsMouseOver(true) : undefined}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {buttonText ? <span>{buttonText}</span> : <CheckInCircleSVG />}
      </span>
      {isCritical && <ConditionLineCriticalFlag left={-20} svgStyle={SvgStyle.DxDetails} />}
    </li>
  );
};

const UnconnectedBulletDetails: React.FC<
  {
    module: string;
  } & PropsFromRedux
> = ({
  bulletsByModule,
  bulletNoteStatusByBulletId,
  module,
  updateBulletNoteStatusFromDxDetailsThunk,
}) => {
  const bullets = bulletsByModule?.[module];
  const children: JSX.Element[] = [];
  bullets?.forEach((bullet) => {
    const { diff, id, text, type } = bullet;

    // If the bullet isn't tracked by Regard or there is no text content to render: skip
    if (![BulletType.Assessment, BulletType.Plan].includes(type) || diff === OUTDATED_DIFF) return;

    const key = `${module}.${id}`;
    const isCritical = interpretationIsCritical(
      'interpretation' in bullet ? bullet.interpretation : undefined
    );

    let buttonText = '';
    let updateBulletFlag;
    if (isHidden(key, bulletNoteStatusByBulletId)) {
      buttonText = RESTORE_TEXT;
      // We have to use the window-bound function here because
      // importing from actions/regardNote.ts causes some kind of
      // circular dependency issue which in turn causes the
      // function to be undefined.
      updateBulletFlag = () => {
        updateBulletNoteStatusFromDxDetailsThunk({
          conditionName: module,
          newBulletNoteStatus: BulletNoteStatus.Monitored,
          text,
        });

        const inShelf = getIsModuleShelved(module);
        track.bulletRestored({ inShelf, module, bulletId: id });
      };
    } else if (isMonitored(key, bulletNoteStatusByBulletId)) {
      buttonText = ADD_TEXT;
      updateBulletFlag = () => {
        updateBulletNoteStatusFromDxDetailsThunk({
          conditionName: module,
          newBulletNoteStatus: BulletNoteStatus.Noted,
          text,
        });

        const inShelf = getIsModuleShelved(module);
        track.bulletAdded({ inShelf, module, bulletId: id, uiElement: 'dx details' });
      };
    }

    children.push(
      <BulletItem
        key={key}
        buttonText={buttonText}
        isCritical={isCritical}
        isNew={diff === NEW_DIFF || diff === UPDATED_DIFF}
        updateBulletFlag={updateBulletFlag}
      >
        {`- ${text}`}
      </BulletItem>
    );
  });

  return <ul className="BulletDetails">{children}</ul>;
};

const connector = connect(
  ({ regardNote: { bulletsByModule, bulletNoteStatusByBulletId } }: AppState) => ({
    bulletsByModule,
    bulletNoteStatusByBulletId,
  }),
  { updateBulletNoteStatusFromDxDetailsThunk }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const BulletDetails = connector(UnconnectedBulletDetails);
