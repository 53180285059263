import _ from 'lodash';

import {
  IndexingData,
  SimpleCondition,
  Stats,
  IndexingDataCondition,
  BulletNoteStatusRecord,
  ChunkOfLinesWithTextAndModules,
  MeshedNoteTitleHtmlLine,
  TitleSignifier,
  ChunkOfLines,
} from '~/app/@types/state';
import { EXISTING_DIFF, UPDATED_DIFF } from '~/app/constants';
import { get, getFirstCharacterStyles, isFooterChunk, uniqueId } from '~/app/utils';
import { addStylesToHtml } from '~/app/utils/addStyles';

import { renumberHtmlTitleLines } from '../renumberHtmlTitleLines';

import { attemptToMatchRegardBulletHtmlInLines } from './attemptToMatchRegardBulletHtmlInLines';
import { setupBulletTracking } from './setupBulletTracking';

interface AnalyzeAndMeshHtmlChunksParams {
  currentRegardConditionsMap: Record<string, SimpleCondition>;
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  chunksWithModules: ChunkOfLinesWithTextAndModules[];
  titleSignifier: TitleSignifier;
  previousTitleToNewTitle: Record<string, string>;
  updatableBulletTextByConditionName: Record<string, Record<string, string>>;
  removableBulletTextKeyToConditionNameMap: Record<string, string[]>;
  indexingData: IndexingData;
}

interface AnalyzeAndMeshHtmlChunksResults {
  currentTitleIndex: number;
  existingChunks: ChunkOfLines[];
  footerTitleLineCount: number;
  stats: Stats;
}

export const analyzeAndMeshHtmlChunks = ({
  currentRegardConditionsMap,
  bulletNoteStatusFromAPI,
  chunksWithModules,
  titleSignifier,
  previousTitleToNewTitle,
  updatableBulletTextByConditionName,
  removableBulletTextKeyToConditionNameMap,
  indexingData,
}: AnalyzeAndMeshHtmlChunksParams): AnalyzeAndMeshHtmlChunksResults => {
  let currentTitleIndex = 0;
  let footerTitleLineCount = 0;

  // Prepare stats data
  const stats: Stats = {
    matchedConditions: [], // DXs found in MRPN and matched to Regard DXs (potentially updated)
    updatedBullets: {},
    updatedTitles: [],
    newConditions: [], // DXs NOT found in MRPN, but found in Regard note (new)
    missedConditions: [], // DXs found in MRPN but NOT matched to Regard note (MRPN only)
    removedBullets: [],
    noProgressNote: chunksWithModules.length === 0,
  };

  // For chunks with actual titles, we will
  //   1. update any bullets that we can update
  //   2. and remove outdated medication bullets
  //   3. add suggestedQualifiers for the specChecker
  //   4. if we suggested a title yesterday and now suggest a different title and the user hasn't changed the title, update it (but not stacked dxs)
  const existingChunks = chunksWithModules.map((chunk, i) => {
    const { lines, modules, type } = chunk;

    // If this section is not a condition or there are no title lines, we can
    //  skip the rest of the logic.
    if (type === 'shelfDivider' || !lines.some(({ type }) => type === 'title')) {
      return {
        lines,
        type,
      };
    }

    const isFooter = i === chunksWithModules.length - 1 && isFooterChunk(chunk);

    stats.matchedConditions.push(...modules);

    // Prepare index object for condition
    const indexedCondition: IndexingDataCondition = {
      id: uniqueId(),
      modules,
      diff: null, // Indicates the Regard diff state if Regard found this condition also
      bulletsByTrimmedTextKey: {},
      suggestedQualifier:
        modules.length === 1 ? currentRegardConditionsMap[modules[0]].suggestedQualifier : '',
    };

    const titleLines = lines.filter(({ type }) => type === 'title') as MeshedNoteTitleHtmlLine[];

    // NOTE: This if clause means that if we have a stacked dx, we will not
    //  update titles.
    if (titleLines.length === 1) {
      const titleLine = titleLines[0];
      const { plainTextWithoutTitleSignifier, titleSignifier } = titleLine;

      const updatedTitle = get(previousTitleToNewTitle, plainTextWithoutTitleSignifier);
      const conditionNameIfRegardTitleOrUndefined = get(
        indexingData.regardGeneratedTitleLookup,
        plainTextWithoutTitleSignifier
      );

      if (updatedTitle) {
        // 1. If a title in the base note is a previous regard title and we have
        //  and updated version, update the text; the condition is updated
        stats.updatedTitles.push(...modules);
        indexedCondition.diff = UPDATED_DIFF;
        // 2. Update the lines
        // Should be OK to mutate here since these objects are built from
        //  scratch anyway.
        titleLine.plainTextWithoutTitleSignifier = updatedTitle;

        // Retain previous html formatting
        const styles = getFirstCharacterStyles(titleLine.html);
        const line = `${titleSignifier} ${updatedTitle}` as HtmlString;
        titleLine.html = addStylesToHtml(line, styles);
      } else if (conditionNameIfRegardTitleOrUndefined) {
        // If the title in the base note perfectly matches a regard title, then
        //  this condition is existing
        indexedCondition.diff = EXISTING_DIFF;
        // Add to indexingData so if user creates a new title for this condition above in the note,
        // we can remove this Regard condition. The user would consider this a duplicate
        // eslint-disable-next-line no-param-reassign
        indexingData.exactMatchRegardTitlesInNoteByModule[conditionNameIfRegardTitleOrUndefined] =
          plainTextWithoutTitleSignifier;
      }
    }

    // Renumber titles
    const { linesWithRenumberedTitleLines, titleLineCount } = renumberHtmlTitleLines({
      lines,
      currentTitleIndex,
      noteTitleSignifier: titleSignifier,
    });
    currentTitleIndex += titleLineCount;

    if (isFooter) {
      footerTitleLineCount = titleLineCount;
    }

    // Build bulletsByTrimmedTextKey
    const {
      bulletsAlreadyInText,
      bulletUpdates,
      combinedBulletsInText,
      linesWithUpdatedBullets,
      removedBulletsFromText,
    } = attemptToMatchRegardBulletHtmlInLines({
      lines: linesWithRenumberedTitleLines,
      matchedConditionNames: modules,
      updatableBulletTextByConditionName,
      removableBulletTextKeyToConditionNameMap,
    });

    stats.removedBullets.push(...removedBulletsFromText);
    Object.assign(stats.updatedBullets, bulletUpdates);

    // Because the BE sends multiple runs-worth of previous bullet
    // text, it is possible to update a bullet in the basenote but the
    // indexing data thinks the bullet is "Existing". So here we make
    // sure currentRegardConditionsMap has the correct diff for any bullet
    // text we just updated in the base note
    const updatedBulletTexts = Object.keys(bulletUpdates);
    const allBullets = _.flatten(
      modules.map((module) => currentRegardConditionsMap[module].bullets)
    );
    allBullets.forEach((bullet) => {
      if (updatedBulletTexts.includes(bullet.text)) {
        // eslint-disable-next-line no-param-reassign
        bullet.diff = UPDATED_DIFF;
      }
    });

    // Add index object to indexing data structures
    if (indexedCondition.modules.length > 1) {
      indexedCondition.modules.forEach((m) => {
        const splitCondition = _.cloneDeep(indexedCondition);
        splitCondition.modules = [m];
        splitCondition.suggestedQualifier = currentRegardConditionsMap[m].suggestedQualifier;
        // eslint-disable-next-line no-param-reassign
        indexingData.conditionsByModule[m] = splitCondition;
      });
    } else if (indexedCondition.modules.length === 1) {
      // eslint-disable-next-line no-param-reassign
      indexingData.conditionsByModule[indexedCondition.modules[0]] = indexedCondition;
    }

    // NOTE: The following function contains important SIDE-EFFECTS
    setupBulletTracking({
      conditionNames: modules,
      currentRegardConditionsMap,
      conditionsByModule: indexingData.conditionsByModule,
      bulletNoteStatusFromAPI,
      combinedBulletsInText,
      bulletsAlreadyInText,
    });

    if (
      modules.length === 0 &&
      !isFooter // This means that missed condition titles cannot be reported from the footer, whether it's "# FEN" or "# Diabetes"
    ) {
      stats.missedConditions.push(
        (
          linesWithUpdatedBullets.filter(
            ({ type }) => type === 'title'
          ) as MeshedNoteTitleHtmlLine[]
        )
          .map(({ plainTextWithoutTitleSignifier }) => plainTextWithoutTitleSignifier)
          .join('\n')
      );
    }

    return {
      lines: linesWithUpdatedBullets,
      type,
    };
  });

  return {
    currentTitleIndex,
    existingChunks,
    footerTitleLineCount,
    stats,
  };
};
