import { FC, useEffect, useRef, useState } from 'react';
import { memoize } from 'lodash';

import { nth } from '~/app/utils';
import { theme } from '~/app/reuse';
import { MaxChatUserPrompt } from '../maxChatUserPrompt';
import { MaxChatAiResponse } from '../maxChatAiResponse';
import { MAX_CHAT_FORM_HEIGHT, MAX_CHAT_FORM_VERTICAL_HEIGHT } from '../maxChatForm';
import { useDetailsContext } from '../../detailsProvider';
import { useMeshedContext } from '../../MeshedProvider';
import { useScrollAttachedDetector } from './useScrollAttachedDetector';

const MAX_CHAT_3_POINT_5_WARNING_HEIGHT = 53;

const getStyle = memoize(
  ({ is3Point5, isVerticalLayout }: { is3Point5: boolean; isVerticalLayout: boolean }) => {
    const paddingBottom = isVerticalLayout ? MAX_CHAT_FORM_VERTICAL_HEIGHT : MAX_CHAT_FORM_HEIGHT;

    return {
      height: '100%',
      paddingBottom: paddingBottom + (is3Point5 ? MAX_CHAT_3_POINT_5_WARNING_HEIGHT : 0),
    };
  }
);

const innerStyle = {
  height: '100%',
  marginTop: 8,
  overflow: 'auto',
} as const;

const warningStyle = {
  backgroundColor: theme.colors.grey5,
  borderRadius: theme.other.borderRadius3,
  margin: 8,
  padding: '8px 16px',
  textAlign: 'center',
} as const;

export const MaxChatReplies: FC = () => {
  const { isVerticalLayout } = useMeshedContext(({ isVerticalLayout }) => ({ isVerticalLayout }));

  const { chatReplies, chatRepliesMetadata, model } = useDetailsContext(
    ({ chatReplies, chatRepliesMetadata, model }) => ({
      chatReplies,
      chatRepliesMetadata,
      model,
    })
  );

  const [isScrollAttached, setIsScrollAttached] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  useScrollAttachedDetector(ref, setIsScrollAttached);

  // Whenever a new reply is added, reset `isScrollAttached` to true.
  useEffect(() => {
    setIsScrollAttached(true);
  }, [chatReplies.length, setIsScrollAttached]);

  useEffect(() => {
    if (isScrollAttached) {
      const el = document.querySelector('[data-max-chat-replies-scroll]');

      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    }
  }, [chatReplies, isScrollAttached]);

  const is3Point5 = model.includes('3.5');

  return (
    <div style={getStyle({ isVerticalLayout, is3Point5 })}>
      {is3Point5 && <div style={warningStyle}>⚠️ Using Max 3.5 ⚠️</div>}
      <div ref={ref} data-max-chat-replies-scroll style={innerStyle}>
        {chatReplies.map((reply, i) =>
          reply.type === 'response' ? (
            <MaxChatAiResponse
              key={i}
              citations={reply.citations}
              index={i}
              liked={nth(chatRepliesMetadata, i)?.liked}
              status={reply.status}
              text={reply.text}
            />
          ) : (
            <MaxChatUserPrompt key={i} text={reply.text} />
          )
        )}
      </div>
    </div>
  );
};
