import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDate } from './content';

const FieldList = ({ className, fields, fieldValues }) =>
  fields ? (
    <ul className={classNames(className, 'htemr-FieldList')}>
      {fields.map(({ key, label }) => (
        <li key={key} className="htemr-FieldList-item">
          <strong>{label || formatDate(fieldValues[key])}</strong>
          {label ? <span>:{formatDate(fieldValues[key])}</span> : null}
        </li>
      ))}
    </ul>
  ) : null;

FieldList.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  fieldValues: PropTypes.shape({}).isRequired,
};

FieldList.defaultProps = {
  className: '',
};

export default FieldList;
