import * as Sentry from '@sentry/browser';

import { HtmlVersion, NoteType } from '~/app/@types';
import { EligibleHtmlBaseNote } from '~/app/@types/state';
import { fetchDraft } from '~/app/api';
import { RegardNoteError } from '~/app/errors';
import * as flags from '~/app/flags';
import { getIsEmptyHtmlString, getLocalStorageItem } from '~/app/utils';

// This file/function uses business logic to decide what base note we mesh with:
// In "review mode" we dont want to mesh so we return a blank note
// If there is a draft note with matching previous progess note timestamp: we load that
// otherwise we process the assessmentPlan and footer from the note from the API

const getExistingDraftOrFetchDraft = async ({
  draft,
  encounterId,
  resourceId,
}: {
  draft?: { html: HtmlString; baseNoteContentHash: string | null };
  encounterId: string;
  resourceId: string;
}): Promise<{
  htmlVersion: HtmlVersion.HtmlWithEMU;
  noteHtml: HtmlString;
  baseNoteContentHash: string | null;
} | null> => {
  if (draft) {
    return {
      htmlVersion: HtmlVersion.HtmlWithEMU,
      noteHtml: draft.html ?? ('' as HtmlString),
      baseNoteContentHash: draft.baseNoteContentHash ?? null,
    };
  }

  const response = await fetchDraft(resourceId, encounterId);

  if (response && response.status === 'success' && response.result) {
    const { result } = response;

    return {
      htmlVersion: HtmlVersion.HtmlWithEMU,
      noteHtml: result.note_html,
      baseNoteContentHash: result.basenote_content_hash,
    };
  }

  return null;
};

const EMPTY_NOTE = {
  baseNoteType: NoteType.Blank,
  baseNoteHtml: '' as HtmlString,
  baseNoteContentHash: null,
  physicalExamText: '',
};

interface GenerateBaseNoteTextParams {
  draft?: { html: HtmlString; baseNoteContentHash: string | null };
  useDraft: boolean;
  noteFromAPI: EligibleHtmlBaseNote;
  physicalExamTextFromAPI: string;
  physicalExamKey: string;
  encounterId: string;
}

interface GenerateBaseNoteTextResult {
  baseNoteHtml: HtmlString;
  baseNoteContentHash: string | null;
  baseNoteType: NoteType;
  physicalExamText: string;
}

const unsafeGenerateBaseNoteText = async ({
  draft,
  useDraft,
  noteFromAPI,
  physicalExamTextFromAPI,
  physicalExamKey,
  encounterId,
}: GenerateBaseNoteTextParams): Promise<GenerateBaseNoteTextResult> => {
  // In review mode we want to use a blank note so the reviewing physician can just
  // focus on the title, bullets, and supporting info that Regard sends to the FE
  if (flags.isReview()) {
    // OUTCOME 1: We don't want no stinkin' base note!
    return EMPTY_NOTE;
  }

  const physicalExamText = getLocalStorageItem(physicalExamKey) || physicalExamTextFromAPI;

  const shouldUseDraft = useDraft && !flags.isSalesDemoMode && !flags.isDraftLoadDisabled;
  if (shouldUseDraft) {
    const eitherDraft = await getExistingDraftOrFetchDraft({
      draft,
      encounterId,
      resourceId: noteFromAPI.resourceId,
    });

    // OUTCOME 2: We have and want to use a draft note (as long as the draft
    // note is not an empty string!)
    const draftIsUsable =
      eitherDraft &&
      eitherDraft.htmlVersion === HtmlVersion.HtmlWithEMU &&
      !getIsEmptyHtmlString(eitherDraft.noteHtml);
    if (draftIsUsable) {
      return {
        baseNoteHtml: eitherDraft.noteHtml,
        baseNoteContentHash: eitherDraft.baseNoteContentHash,
        baseNoteType: NoteType.Draft,
        physicalExamText,
      };

      // Empty string? Fall through.
    }

    // API failure? Fall through.
  }

  // OUTCOME 3: We do not have (or want) a draft note.
  return {
    baseNoteHtml: noteFromAPI.noteHtml,
    baseNoteContentHash: noteFromAPI.contentHash,
    baseNoteType: getIsEmptyHtmlString(noteFromAPI.noteHtml) ? NoteType.Blank : NoteType.Note,
    physicalExamText,
  };
};

export const generateBaseNoteText = async (
  params: GenerateBaseNoteTextParams
): Promise<GenerateBaseNoteTextResult> =>
  new Promise((resolve) => {
    unsafeGenerateBaseNoteText(params)
      .then(resolve)
      .catch((error) => {
        Sentry.withScope((scope: Sentry.Scope) => {
          scope.setExtra('draft html', params.draft?.html);
          scope.setExtra('noteFromAPI', params.noteFromAPI);
          scope.setExtra('physicalExamTextFromAPI', params.physicalExamTextFromAPI);
          scope.setExtra('physicalExamKey', params.physicalExamKey);
          scope.setExtra('encounterId', params.encounterId);
          scope.setExtra('error message', `${error}`);
          const sentryRegardError = new RegardNoteError('Failed to generate base note text');
          sentryRegardError.stack = error.stack;
          Sentry.captureException(sentryRegardError);
        });
        resolve(EMPTY_NOTE);
      });
  });
