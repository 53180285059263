import { FC, memo, useEffect } from 'react';

import { track } from '~/app/analytics';
import { IconButton, StaticTooltip } from '~/app/reuse';
import { get } from '~/app/utils';
import { CONDITION_UNDERLAY_BUTTON_COLOR, CONDITION_UNDERLAY_BUTTON_SIZE } from '../const';
import { useNoteContext } from '../noteProvider';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionDismissButton: FC = memo(() => {
  const { dismissCondition } = useNoteContext(({ dismissCondition }) => ({
    dismissCondition,
  }));
  const { id, index, onDismissButtonEnter, onDismissButtonLeave } = useNoteBlockContext(
    ({ id, index, onDismissButtonEnter, onDismissButtonLeave }) => ({
      id,
      index,
      onDismissButtonEnter,
      onDismissButtonLeave,
    })
  );

  /**
   * When this component is unmounted, we must reset the state in the
   *  provider, otherwise it may get stuck in the "mouse entered" state.
   */
  useEffect(() => onDismissButtonLeave, [onDismissButtonLeave]);

  return (
    <StaticTooltip display="inline-block" placement="top" tooltip="Dismiss">
      <IconButton
        color={CONDITION_UNDERLAY_BUTTON_COLOR}
        data-cy-condition-dismiss-button
        iconName="trash"
        onClick={() => {
          const { regardNote } = window.store.getState();
          const noteBlock = get(regardNote.noteBlocksById, id);
          const modules = (noteBlock && 'modules' in noteBlock && noteBlock.modules) || [];

          dismissCondition(id);
          track.clickedDismissDxButton({
            atIndex: index,
            modules,
            dismissedFromShelf: noteBlock?.type === 'shelvedCondition',
          });
          modules.forEach((m) =>
            track.conditionDismissed({
              module: m,
              uiElement: 'dismiss button',
              dismissedFromShelf: noteBlock?.type === 'shelvedCondition',
            })
          );
        }}
        onMouseEnter={onDismissButtonEnter}
        onMouseLeave={onDismissButtonLeave}
        size={CONDITION_UNDERLAY_BUTTON_SIZE}
      />
    </StaticTooltip>
  );
});
