import { useMemo } from 'react';

import { get, isConditionNoteBlock } from '~/app/utils';
import { createSelector, useSelector } from '~/app/store';

export const useHasTitle = (id: string): boolean => {
  const getHasTitle = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.noteBlocksById, id),
        (noteBlock) =>
          !!(
            noteBlock &&
            isConditionNoteBlock(noteBlock) &&
            noteBlock.lines.some(({ type }) => type === 'title')
          )
      ),
    [id]
  );

  const hasTitle = useSelector(getHasTitle);

  return hasTitle;
};
