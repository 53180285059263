import { theme } from '~/app/reuse';

export const noteCriticalFlagSvg = (
  <svg fill="none" height="20" viewBox="0 0 18 20" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.50183 1.66897C0.922663 1.02544 1.37935 0 2.24513 0H17.9998V20L1.50183 1.66897Z"
      fill={theme.colors.red}
    />
    <path
      d="M13.6012 8.43695L13.2933 3.95015L13.2199 2H15.39L15.3167 3.95015L15.0088 8.43695H13.6012ZM14.305 12C13.9335 12 13.6207 11.8729 13.3666 11.6188C13.1222 11.3548 13 11.0323 13 10.651C13 10.26 13.1222 9.93744 13.3666 9.68328C13.6207 9.41936 13.9335 9.28739 14.305 9.28739C14.6764 9.28739 14.9844 9.41936 15.2287 9.68328C15.4829 9.93744 15.61 10.26 15.61 10.651C15.61 11.0323 15.4829 11.3548 15.2287 11.6188C14.9844 11.8729 14.6764 12 14.305 12Z"
      fill={theme.colors.white}
    />
  </svg>
);

export const suggestionCriticalFlagSvg = (
  <svg fill="none" height="24" viewBox="0 0 18 24" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.19986 1.6C0.705435 0.940764 1.17582 0 1.99986 0H17.9999V24L1.19986 1.6Z"
      fill={theme.colors.red}
    />
    <path
      d="M13.6012 9.43695L13.2933 4.95015L13.2199 3H15.39L15.3167 4.95015L15.0088 9.43695H13.6012ZM14.305 13C13.9335 13 13.6207 12.8729 13.3666 12.6188C13.1222 12.3548 13 12.0323 13 11.651C13 11.26 13.1222 10.9374 13.3666 10.6833C13.6207 10.4194 13.9335 10.2874 14.305 10.2874C14.6764 10.2874 14.9844 10.4194 15.2287 10.6833C15.4829 10.9374 15.61 11.26 15.61 11.651C15.61 12.0323 15.4829 12.3548 15.2287 12.6188C14.9844 12.8729 14.6764 13 14.305 13Z"
      fill={theme.colors.white}
    />
  </svg>
);

export const dxDetailsCriticalFlagSvg = (
  <svg fill="none" height="29" viewBox="0 0 18 29" width="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.948018 1.52736C0.534544 0.861209 1.01362 0 1.79766 0H18V29L0.948018 1.52736Z"
      fill={theme.colors.red}
    />
    <path
      d="M12.6012 10.4369L12.2933 5.95015L12.2199 4H14.39L14.3167 5.95015L14.0088 10.4369H12.6012ZM13.305 14C12.9335 14 12.6207 13.8729 12.3666 13.6188C12.1222 13.3548 12 13.0323 12 12.651C12 12.26 12.1222 11.9374 12.3666 11.6833C12.6207 11.4194 12.9335 11.2874 13.305 11.2874C13.6764 11.2874 13.9844 11.4194 14.2287 11.6833C14.4829 11.9374 14.61 12.26 14.61 12.651C14.61 13.0323 14.4829 13.3548 14.2287 13.6188C13.9844 13.8729 13.6764 14 13.305 14Z"
      fill={theme.colors.white}
    />
  </svg>
);
