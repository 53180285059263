import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createSelectItemAction } from '../../actions/record';
import { formatDate } from './content';

const ItemGrid = ({ columns, gridValueKey, groups, rows, selectItem, selectedItem }) => {
  //  Renders grid header
  const gridHeader = (
    <div className="htemr-ItemGrid-row htemr-ItemGrid-headerRow">
      {columns.map((column, columnIndex) => (
        <div key={columnIndex} className="htemr-ItemGrid-headerRowCell">
          {columns[columnIndex]}
        </div>
      ))}
    </div>
  );

  //  Renders rows, either grouped or ungrouped
  const itemAt = (r, cI) => r.items[columns[cI]];
  const isSelected = (r, cI) => selectedItem === itemAt(r, cI);
  const renderLabels = (rowList) => (
    <div className="htemr-ItemGrid-row-label-wrapper">
      <div className="htemr-ItemGrid-cell" />
      <div className="htemr-ItemGrid-row-labels">
        {rowList.map((row) => (
          <div key={row.label} className="htemr-ItemGrid-headerColumnCell">
            {row.label}
          </div>
        ))}
      </div>
    </div>
  );
  const renderRows = (rowList) =>
    rowList.map((row) => (
      <div key={`row-${row.label}`} className="htemr-ItemGrid-row">
        {columns.map((column, columnIndex) =>
          itemAt(row, columnIndex) ? (
            <div
              key={`${row.label}-${columnIndex}`}
              className={classNames('htemr-ItemGrid-cell', 'has-data', {
                'htemr-ItemGrid-selected': isSelected(row, columnIndex),
              })}
              onClick={
                isSelected(row, columnIndex) ? null : () => selectItem(itemAt(row, columnIndex))
              }
            >
              {formatDate(itemAt(row, columnIndex)[gridValueKey])}
            </div>
          ) : (
            <div key={`${row.label}-${columnIndex}`} className="htemr-ItemGrid-cell" />
          )
        )}
      </div>
    ));

  const labels = renderLabels(rows);
  const renderedRows = renderRows(rows);

  return (
    <div className={classNames('htemr-ItemGrid-grid', { 'htemr-ItemGrid-groups': groups })}>
      {labels}
      <div className="htemr-ItemGrid-wrapper">
        <div className="htemr-ItemGrid-horizontal-scroll">
          {gridHeader}
          <div className="htemr-ItemGrid-rows">{renderedRows}</div>
        </div>
      </div>
    </div>
  );
};

ItemGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.object.isRequired,
    })
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      rows: PropTypes.array.isRequired,
    })
  ),
  selectedItem: PropTypes.shape({}),
  gridValueKey: PropTypes.string.isRequired,
  selectItem: PropTypes.func.isRequired,
};

ItemGrid.defaultProps = {
  groups: null,
  selectedItem: null,
};

const mapStateToProps = (state, ownProps) => {
  const { contentKey, displayFields } = state.record.selectedScreen;
  const [gridColumn] = displayFields.filter((f) => f.gridColumn);
  const [gridRow] = displayFields.filter((f) => f.gridRow);
  const [gridValue] = displayFields.filter((f) => f.gridValue);
  const [gridRowGrouping] = displayFields.filter((f) => f.gridRowGrouping);
  const columns = [];
  const unorderedRows = {};
  state.record.data[contentKey].filter(ownProps.filterItem).forEach((item) => {
    const gridColumnValue = formatDate(item[gridColumn.key]);
    if (columns.indexOf(gridColumnValue) === -1) {
      columns.push(gridColumnValue);
    }

    const gridRowValue = formatDate(item[gridRow.key]);
    if (!unorderedRows[gridRowValue]) {
      unorderedRows[gridRowValue] = {};
    }

    unorderedRows[gridRowValue][gridColumnValue] = item;
  });

  const rows = Object.keys(unorderedRows)
    .sort()
    .map((rowName) => ({ label: rowName, items: unorderedRows[rowName] }));

  let groups = null;
  if (gridRowGrouping) {
    const unorderedGroups = {};
    rows.forEach((row) => {
      const groupName = formatDate(Object.values(row.items)[0][gridRowGrouping.key]);
      if (!unorderedGroups[groupName]) {
        unorderedGroups[groupName] = [];
      }
      unorderedGroups[groupName].push(row);
    });

    groups = Object.keys(unorderedGroups)
      .sort()
      .map((groupName) => ({ label: groupName, rows: unorderedGroups[groupName] }));
  }

  return {
    columns,
    rows,
    groups,
    selectedItem: state.record.selectedItem,
    gridValueKey: gridValue.key,
  };
};

const mapDispatchToProps = {
  selectItem: createSelectItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemGrid);
