import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { closeDetails } from '../../actions/record';

const DetailsPopup = ({ itemLabel, DetailsView, displayFields, item, close }) => (
  <div className="htemr-DetailsPopup">
    <span className="htemr-DetailsPopup-closeButton" onClick={close}>
      x
    </span>
    <h1 className="htemr-DetailsPopup-title">{itemLabel} Details</h1>
    <div className="htemr-DetailsPopup-detailsView">
      <DetailsView fields={displayFields} fieldValues={item} />
    </div>
  </div>
);

DetailsPopup.propTypes = {
  itemLabel: PropTypes.string.isRequired,
  DetailsView: PropTypes.func.isRequired,
  displayFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.shape({ id: PropTypes.any.isRequired }).isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  itemLabel: state.record.selectedScreen.itemLabel,
  DetailsView: state.record.selectedScreen.DetailsView,
  displayFields: state.record.selectedScreen.displayFields,
  item: state.record.selectedItem,
});

const mapDispatchToProps = {
  close: closeDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPopup);
