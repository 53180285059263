import { CharacterItem } from './types';

export const findIndex = <T>(
  array: T[],
  predicate: (item: T) => boolean,
  fromIndex?: number,
  toIndex?: number
) => {
  for (let i = fromIndex ?? 0, len = toIndex ?? array.length; i < len; ++i) {
    if (predicate(array[i])) return i;
  }

  return -1;
};

export const joinArray = <T>(
  array: T[],
  predicate: (item: T) => string | undefined,
  fromIndex?: number,
  toIndex?: number
) => {
  let output = '';
  for (let i = fromIndex ?? 0, len = toIndex ?? array.length; i < len; ++i) {
    output += predicate(array[i]) || '';
  }
  return output;
};

export const getCharacterFromItem = (item: CharacterItem) => item.character;
export const getOriginalCharacterFromItem = (item: CharacterItem) =>
  'original' in item ? item.original : item.character;

export const getRawHtml = (items: CharacterItem[], fromIndex?: number, toIndex?: number) =>
  joinArray(items, getCharacterFromItem, fromIndex, toIndex) as HtmlString;

export const getRawOriginalHtml = (items: CharacterItem[], fromIndex?: number, toIndex?: number) =>
  joinArray(items, getOriginalCharacterFromItem, fromIndex, toIndex) as HtmlString;
