import * as Sentry from '@sentry/browser';

import { PreviousConditionAssessmentPlan } from '~/app/@types/state';
import { track } from '~/app/analytics';
import { RegardNoteError } from '~/app/errors';

type HistoricalPropsResponse = {
  previousConditionResults: PreviousConditionAssessmentPlan[] | null;
};

/**
 * Fetch historical props for a basenote
 * @param baseNote
 * @param numConditionsReceived
 * @returns
 */
export const fetchHistoricalProps = async ({
  encounterId,
}: {
  encounterId: string;
}): Promise<HistoricalPropsResponse['previousConditionResults']> => {
  const params = new URLSearchParams({ encounter_id: encounterId });
  const fetchOptions = {
    method: 'POST',
    credentials: 'include',
    body: new FormData(),
  };

  let response: Response | undefined;
  let responseJson: HistoricalPropsResponse | undefined;
  const maxAttempts = 2;
  try {
    for (let i = 0; i < maxAttempts; i++) {
      // eslint-disable-next-line no-await-in-loop
      response = await fetch(
        `/api/get_historical_text?${params.toString()}`,
        fetchOptions as RequestInit
      );
      if (response.status === 200) {
        break;
      }
    }
    if (response === undefined) {
      throw Error('Max retries exceeded.');
    }

    responseJson = await response.json();
  } catch (error) {
    track.failedToReceiveHistoricalProps(params);
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtra('originalError', error);
      scope.setExtra('response', response);
      Sentry.captureException(
        new RegardNoteError('Failed To Receive Regard DXs And Patient Notes')
      );
    });
    return null;
  }

  if (!response || !responseJson) return null;
  return responseJson.previousConditionResults;
};
