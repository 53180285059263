import { FC, memo } from 'react';

import { useNoteContext } from '../noteProvider';
import { ConditionButtonsBackground } from '../conditionButtonsBackground';
import { ConditionDismissButton } from '../conditionDismissButton';
import { ConditionShelveButton } from '../conditionShelveButton';
import { ConditionMoveMenu } from '../conditionMoveMenu';
import { useNoteBlockContext } from '../noteBlockProvider';
import { ConditionCopyButton } from '../conditionCopyButton';

import { getRootStyle, spacer } from './css';
import { useHasTitle } from './useHasTitle';

interface ConditionButtonsProps {
  reverseIndex: number;
}

export const ConditionButtons: FC<ConditionButtonsProps> = memo(({ reverseIndex }) => {
  const { reviewMode } = useNoteContext(({ reviewMode }) => ({
    reviewMode,
  }));
  const { buttonHovered, copyButtonHovered, type, id } = useNoteBlockContext(
    ({ buttonHovered, copyButtonHovered, type, id }) => ({
      buttonHovered,
      copyButtonHovered,
      type,
      id,
    })
  );

  const hasTitle = useHasTitle(id);

  return reviewMode ? null : (
    <div
      data-condition-buttons
      data-cy-condition-buttons
      style={getRootStyle({ reverseIndex, buttonHovered: buttonHovered || copyButtonHovered })}
    >
      <ConditionButtonsBackground />
      <ConditionCopyButton />
      <ConditionDismissButton />
      {spacer}
      {type !== 'footer' && <ConditionMoveMenu />}
      {hasTitle && type === 'condition' && (
        <>
          {spacer}
          <ConditionShelveButton />
        </>
      )}
    </div>
  );
});

ConditionButtons.displayName = 'ConditionButtons';
