import { theme } from '../../../../reuse';
import { formZIndex } from '../userFeedbackForm/css';
import { isSalesDemoMode } from '../../../../flags';

const gutter = 24;

export const getCss = ({ fullSize, visible }: { fullSize: boolean; visible: boolean }) =>
  ({
    backgroundColor: theme.colors.white,
    borderRadius: theme.other.borderRadius4,
    boxShadow: '0 4px 26px rgba(0, 0, 0, 0.08), 0 1px 14px rgba(0, 0, 0, 0.06)',
    color: theme.colors.blueRegular,
    fontSize: 14,
    padding: '18px 16px 32px',
    position: fullSize || isSalesDemoMode ? 'absolute' : 'fixed',
    textAlign: 'center',
    transition: 'all 200ms ease',
    width: 248,
    // The full size toast needs to sit on top of both the form and the transparent blue backdrop
    // The regular toast needs to hide below the form until the form is removed from view
    zIndex: fullSize ? formZIndex + 2 : formZIndex - 1,
    ...(fullSize
      ? {
          bottom: 0,
          display: visible ? 'block' : 'none',
          height: 144,
          left: 0,
          margin: 'auto',
          right: 0,
          top: 0,
        }
      : {
          bottom: gutter,
          display: isSalesDemoMode && !visible ? 'none' : 'block',
          height: 'auto',
          right: gutter,
          transform: visible ? undefined : 'translateY(120%)', // Would like to use calc, but that's not possible int transforms in IE10
        }),
  } as const);

export const backdropStyle = {
  backgroundColor: theme.colors.lightBlue2,
  bottom: 0,
  left: 0,
  opacity: 0.6,
  position: 'absolute',
  right: 0,
  top: 0,
  // Above the form, below the toast
  zIndex: formZIndex + 1,
} as const;

export const svgStyle = {
  display: 'block',
  fill: 'currentColor',
  margin: '0 auto',
} as const;
