import { FC } from 'react';

import { TitledSmartAccordion } from '../../../reuse/titledSmartAccordion';
import { BulletDetails } from '../BulletDetails';
import { DxDetailLinesTitle } from './DxDetailLinesTitle';

export const DxDetailLinesSection: FC<{
  module: string;
}> = ({ module }) => (
  <TitledSmartAccordion first title={<DxDetailLinesTitle module={module} />}>
    <BulletDetails module={module} />
  </TitledSmartAccordion>
);
