import { deepEqual } from 'fast-equals';

import { isMonitored } from './bulletNoteStatus';
import { Suggestion, BulletTag, ObservationInterpretation } from '../@types';
import { BulletNoteStatusRecord, BulletIndexingMetaData } from '../@types/state';
import { diffToStatus } from './noteMeshing/diffToStatus';

const tagToPriorityMap = {
  [BulletTag.Observation]: 4,
  [BulletTag.Medication]: 3,
  [BulletTag.Info]: 2,
};

const getTagPriority = (tag: BulletTag) => tagToPriorityMap[tag];

// Quick an easy suggestion sorting function that will force ASSESSMENT-type bullets
// to show before PLAN-type bullets. If bullets share a type then they are reverse-sorted
// by tag.. observation -> medication -> info. if same tags, sort alphabetically
type Sortable = Pick<Suggestion, 'tags' | 'text' | 'type'>;
export const sortSuggestionsByTypeAndTags = (sugA: Sortable, sugB: Sortable): number => {
  if (sugA.type !== sugB.type) return sugA.type > sugB.type ? 1 : -1;

  if (sugA.tags.length && sugB.tags.length) {
    if (!deepEqual(sugA.tags, sugB.tags))
      return getTagPriority(sugB.tags[0]) - getTagPriority(sugA.tags[0]);
  } else if (sugA.tags.length) return -1;
  else if (sugB.tags.length) return 1;

  return sugA.text > sugB.text ? 1 : -1;
};

const EMPTY_SUGGESTIONS: Suggestion[] = [];

const filterBulletsToSuggestions = ({
  bulletNoteStatus,
  bulletsByTrimmedTextKey,
}: {
  bulletNoteStatus: BulletNoteStatusRecord;
  bulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData>;
}): Suggestion[] => {
  const suggestions: Suggestion[] = [];

  Object.entries(bulletsByTrimmedTextKey).forEach(([bulletText, bulletIndexingMetadata]) => {
    const { ids, diff, type, tags } = bulletIndexingMetadata;

    // only suggest bullets set as "monitored", the default bulletNoteStatus state
    const shouldHideBullet = ids.some((id) => !isMonitored(id, bulletNoteStatus));
    if (shouldHideBullet) return;

    const status = diffToStatus(diff);
    suggestions.push({
      ids,
      status,
      tags,
      text: bulletText,
      type,
      interpretation:
        'interpretation' in bulletIndexingMetadata
          ? bulletIndexingMetadata.interpretation
          : ObservationInterpretation.Unknown,
    });
  });

  if (suggestions.length === 0) {
    // Returning the same reference for an empty array will reduce rerenders
    return EMPTY_SUGGESTIONS;
  }

  suggestions.sort(sortSuggestionsByTypeAndTags);
  return suggestions;
};

export const getSuggestions = ({
  bulletsByTrimmedTextKey,
  bulletNoteStatus,
}: {
  bulletsByTrimmedTextKey: Record<string, BulletIndexingMetaData>;
  bulletNoteStatus: BulletNoteStatusRecord;
}): Suggestion[] =>
  filterBulletsToSuggestions({
    bulletNoteStatus,
    bulletsByTrimmedTextKey,
  });
