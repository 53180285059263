import useEventListener from '@use-it/event-listener';
import { FC, useCallback } from 'react';

import { track } from '../../../analytics';

export const TrackFirstUserInteractionListener: FC<{ setInteracted(value: boolean): void }> = ({
  setInteracted,
}) => {
  const onInteraction = useCallback(
    ({ type }: Event) => {
      setInteracted(true);
      track.firstUserInteractionGated(type);
    },
    [setInteracted]
  );

  useEventListener('click', onInteraction);
  useEventListener('keydown', onInteraction);
  useEventListener('mousemove', onInteraction);
  useEventListener('onscroll', onInteraction);

  return null;
};
