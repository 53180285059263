import { SummaryP } from './common';

export const hyponatremiaSummary = (
  <>
    <SummaryP first>
      The diagnosis triggers based on any abnormally low serum sodium labs from the current
      encounter. Based on available data the algorithm will check for severity, trajectory, and
      resolution.
    </SummaryP>
    <SummaryP>
      The application corrects for pseudohyponatremia from elevated glucose levels. Etiology is
      recommended based on blood osmolality, urine osmolality, and urine sodium labs.
    </SummaryP>
  </>
);
