import { FC } from 'react';

import { DemoGridColumnLabels } from './DemoGridColumnLabels';
import { DemoGridRowLabels } from './DemoGridRowLabels';
import { getRenderableValue } from './helpers';
import { useDemoGrid } from './useDemoGrid';

const backgroundColor = 'white';

export const DemoGrid: FC<{
  accentColor: string;
}> = ({ accentColor }) => {
  const { columns, rows, gridValueKey } = useDemoGrid();

  return (
    <div>
      <DemoGridRowLabels backgroundColor={backgroundColor} rows={rows} />
      <div
        style={{
          display: 'inline-block',
          width: 'calc(100% - 250px)',
          verticalAlign: 'top',
        }}
      >
        <div style={{ overflowX: 'scroll' }}>
          <DemoGridColumnLabels backgroundColor={backgroundColor} columns={columns} />
          <div>
            {rows.map((row, rowIndex) => (
              <div
                key={`row-${row.label}`}
                style={{
                  display: 'flex',
                }}
              >
                {columns.map((column, columnIndex) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore Unfortunately, tsc cannot understand using ISODateString as an index
                  const item = row.items[column];

                  return (
                    <div
                      key={`${row.label}-${columnIndex}`}
                      style={{
                        backgroundColor:
                          (rowIndex + columnIndex) % 2 ? accentColor : backgroundColor,
                        minWidth: 180,
                        padding: '5px 8px 4px',
                        textAlign: 'center',
                      }}
                    >
                      {item ? getRenderableValue(item[gridValueKey]) : null}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
