import _ from 'lodash';
import { MouseEvent } from 'react';

import { TOOLTIP_HOVER_CLASS } from '../../../reuse/staticTooltip/StaticTooltip';

export const SPEC_CHECKER_TOOLTIP_SELECTOR = '[data-spec-checker-tooltip]';

/**
 * The following function attaches to the parent of all condition textareas
 *  and listens to mousemove events in order to determine if the user's mouse
 *  is hovering over an elmenent with a spec checker tooltip attached. If so,
 *  we manually add a css class to that element such taht it highlights and
 *  reveals the tooltip.
 */
const throttledShowSpecCheckerTooltips = _.throttle(
  ({ clientX, clientY, element }: { clientX: number; clientY: number; element: Element }) =>
    element.querySelectorAll(SPEC_CHECKER_TOOLTIP_SELECTOR).forEach((el) => {
      const { height, left, top, width } = el.getBoundingClientRect();
      const isHovering =
        clientX > left && clientX < left + width && clientY > top && clientY < top + height;

      if (isHovering) {
        el.classList.add(TOOLTIP_HOVER_CLASS);
      } else {
        el.classList.remove(TOOLTIP_HOVER_CLASS);
      }
    }),
  200,
  {
    trailing: true,
  }
);

export const showSpecCheckerTooltips = ({ clientX, clientY, currentTarget }: MouseEvent) =>
  throttledShowSpecCheckerTooltips({ clientX, clientY, element: currentTarget });

/**
 * When the mouse leaves parent of all condition textareas, we must ensure that
 *  all tooltips be hidden--no stragglers! This function cancels any scheduled
 *  checks to see if we should show a tooltip. Then, it makes sure that any
 *  existing hover classes are removed.
 * */
export const hideSpecCheckerTooltips = () => {
  throttledShowSpecCheckerTooltips.cancel();

  document
    .querySelectorAll(SPEC_CHECKER_TOOLTIP_SELECTOR)
    .forEach(({ classList }) => classList.remove(TOOLTIP_HOVER_CLASS));
};
