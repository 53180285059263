import { theme } from '../theme';

const curtainBaseStyle = {
  bottom: 0,
  cursor: 'pointer',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transition: 'background-color 150ms ease',
  zIndex: 100,
} as const;

export const curtainClosedStyle = {
  ...curtainBaseStyle,
  visibility: 'hidden',
} as const;

export const curtainOpenStyle = {
  ...curtainBaseStyle,
  backgroundColor: theme.colors.grey4,
  opacity: 0.5,
} as const;

export const dialogAnimationDuration = 200;

const dialogBaseStyle = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.other.borderRadius4,
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.12)',
  maxHeight: '100%',
  maxWidth: '100%',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  transition: `opacity ${dialogAnimationDuration}ms ease`,
  zIndex: 120,
} as const;

export const dialogClosedStyle = {
  ...dialogBaseStyle,
  opacity: 0,
  visibility: 'hidden',
} as const;

export const dialogOpenStyle = {
  ...dialogBaseStyle,
} as const;
