import { SummaryP, SummarySpanUB, SummaryUl } from './common';

export const alcoholUseDisorderSummary = (
  <>
    <SummaryP first>
      Alcohol use disorder is recommended if the patients presents with{' '}
      <SummarySpanUB>two or more</SummarySpanUB> of the following:
    </SummaryP>
    <SummaryUl>
      <li>Prior condition history</li>
      <li>Abnormal AUDIT or AUDIT-C (any prior encounter)</li>
      <li>Elevated CDT/ethanol/PEth (any prior encounter)</li>
      <li>home medications: acamprosate, disulfiram, naltrexone, gabapentin, or topiramate.</li>
    </SummaryUl>
    <SummaryP>Alcohol withdrawal is included if noted in the current encounter.</SummaryP>
  </>
);
