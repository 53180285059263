import _ from 'lodash';
import { FC, useEffect } from 'react';

const RERENDER_DELAY = 100;
const THROTTLE_DELAY = 200;

let timeout = 0;

const setReflowElementsDisplayStyle = (display: 'none' | ''): void =>
  document.querySelectorAll<HTMLDivElement>('[data-reflow-on-resize]').forEach((el) => {
    // eslint-disable-next-line no-param-reassign
    el.style.display = display;
  });

const reflow = (): void => {
  setReflowElementsDisplayStyle('none');

  window.clearTimeout(timeout);
  timeout = window.setTimeout(() => {
    setReflowElementsDisplayStyle('');
  }, RERENDER_DELAY);
};

const onResize = _.throttle(reflow, THROTTLE_DELAY);

export const NoteReflowOnResize: FC = () => {
  useEffect(() => {
    window.addEventListener('resize', onResize); // for 'resize', must be window instead of document

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return null;
};
