import { NotificationLevel, NEW_DIFF, UPDATED_DIFF } from '~/app/constants';
import {
  BulletNoteStatusRecord,
  NotificationAttrs,
  SimpleCondition,
  Stats,
} from '~/app/@types/state';

import { NotificationType } from '~/app/@types';
import { isHidden } from '../bulletNoteStatus';

import { createNotification } from './createNotification';
import { tableButtonNotifications } from './tableButtonNotifications';

export const addNewUpdatedConditionsNotification = (
  notifications: NotificationAttrs[],
  conditions: Record<string, SimpleCondition>,
  stats: Stats,
  bulletNoteStatus: BulletNoteStatusRecord
) => {
  const numNew = stats.newConditions.length;
  const numUpdated = Object.keys(stats.matchedConditions).length;
  const dx = numNew === 1 || (!numNew && numUpdated === 1) ? ' diagnosis' : ' diagnoses';

  // Calculate which Table buttons should be highlighted
  // because of "hidden" bullets updated/new
  Object.values(conditions).forEach((c): void => {
    tableButtonNotifications[c.module] = false;
    for (let i = 0; i < c.bullets.length; i++) {
      if (
        isHidden(`${c.module}.${c.bullets[i].id}`, bulletNoteStatus) &&
        (c.bullets[i].diff === NEW_DIFF || c.bullets[i].diff === UPDATED_DIFF)
      ) {
        tableButtonNotifications[c.module] = true;
        return;
      }
    }
  });

  // Create the New/Updated Green notification if we have at least 1 of either
  if (!numUpdated && !numNew) return;

  const notification = createNotification({
    type: NotificationType.ConditionsUpdated,
    content: (
      <>
        {numUpdated ? <strong>{`${numUpdated} updated`}</strong> : ''}
        {numUpdated && numNew ? ' and ' : ''}
        {numNew ? <strong>{`${numNew} new`}</strong> : ''}
        {dx}
        {numNew ? ' detected' : ''}
      </>
    ),
    level: NotificationLevel.New,
    allowDismissal: true,
  });
  notifications.push(notification);
};
