import { EMPTY_EMPTY_HTML_LINE, EMPTY_LINE_NBSP, EMPTY_NBSP_HTML_LINE } from '~/app/constants';

import {
  characterItemsToTree,
  htmlTreeToString,
  splitHtmlIntoCharacterItems,
  sanitizeBrs,
} from '..';

interface RemoveRedundantTagsOptions {
  // How to represent an empty line (default is <br>)
  emptyLine?: typeof EMPTY_LINE_NBSP | 'empty';
  // Whether empty `<div></div>` lines should be cleared or converted
  // into an `emptyLine`.
  clearEmptyDivs?: boolean;
}

/**
 * Remove nested tags that are redundant.
 * ie. The inner tag in `<strong><strong>text</strong></strong>` is unnecessary
 *     and clutters the html.
 */
export const removeRedundantTags = (
  html: HtmlString,
  passedOptions?: Partial<RemoveRedundantTagsOptions>
): HtmlString => {
  const { emptyLine, clearEmptyDivs }: RemoveRedundantTagsOptions = {
    ...passedOptions,
  };

  // 1. Round trip the html through the parser to remove redundant tags
  const chars = splitHtmlIntoCharacterItems(html, false, clearEmptyDivs);

  // 2. Cleanup any <br>s that may have incorrectly made their way in
  const processedChars = sanitizeBrs(html, chars);

  const htmlTree = characterItemsToTree(processedChars);
  const parsedHtml = htmlTreeToString(htmlTree);

  // 3. Replace empty line content if need be
  let customEmptyLineHtml = '' as HtmlString;
  if (emptyLine === EMPTY_LINE_NBSP) {
    customEmptyLineHtml = parsedHtml.replace(
      /<div><br><\/div>/g,
      EMPTY_NBSP_HTML_LINE
    ) as HtmlString;
  } else if (emptyLine === 'empty') {
    customEmptyLineHtml = parsedHtml.replace(
      /<div><br><\/div>/g,
      EMPTY_EMPTY_HTML_LINE
    ) as HtmlString;
  } else {
    customEmptyLineHtml = parsedHtml;
  }

  return customEmptyLineHtml;
};
