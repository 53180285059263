import { FC } from 'react';

import { CernerTable } from './CernerTable';
import { cernerTheme } from './cernerTheme';
import { useDemoNotesRows } from '../demoData';
import { DemoNote } from '../demoNote';
import { CernerCompactTableWithDescription } from './CernerCompactTableWithDescription';

const draftIndicatorStyle = { color: cernerTheme.colors.magenta };
const textareaStyle = {
  background: cernerTheme.colors.lighterGray,
  border: `1px solid ${cernerTheme.colors.lightGray}`,
};

export const CernerTabNotesContent: FC = () => {
  const { rows, selectedItem } = useDemoNotesRows();

  const descriptionBody = (
    <DemoNote
      draftIndicatorStyle={draftIndicatorStyle}
      selectedItem={selectedItem}
      textareaStyle={textareaStyle}
    />
  );

  return (
    <>
      <CernerTable label="List" rows={[]} />
      <div
        style={{
          alignItems: 'start',
          display: 'flex',
          marginTop: 10,
          minHeight: 0,
        }}
      >
        <CernerCompactTableWithDescription descriptionBody={descriptionBody} rows={rows} />
      </div>
    </>
  );
};
