import _ from 'lodash';
import { CSSProperties, FC } from 'react';

import { epicTheme } from './epicTheme';
import { DemoImageRow, DemoRow } from '../demoData';

const columnStyle = {
  flexBasis: 0,
  flexGrow: 1,
  overflow: 'hidden',
  padding: '2px 12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-line',
} as const;

export const EpicCompactTable: FC<{
  rows: (DemoRow | DemoImageRow)[];
  style: CSSProperties;
}> = ({ rows, style }) => {
  const [header, ...content] = rows;

  return (
    <div
      style={{
        backgroundColor: epicTheme.colors.white,
        border: `1px solid ${epicTheme.colors.darkBlue}`,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'pre-wrap',
        ...style,
      }}
    >
      {header && (
        <div
          style={{
            backgroundColor: epicTheme.colors.lightGray,
            borderBottom: `1px solid ${epicTheme.colors.darkBlue}`,
            display: 'flex',
          }}
        >
          {header.items.map((column, columnIndex) => (
            <div key={columnIndex} style={columnStyle}>
              {column}
            </div>
          ))}
        </div>
      )}
      {content.map(({ isSelected, items, onClick }, rowIndex) => {
        const actualOnClick = onClick === _.noop ? undefined : onClick;

        return (
          <div
            key={rowIndex}
            style={{
              backgroundColor:
                // eslint-disable-next-line no-nested-ternary
                isSelected
                  ? epicTheme.colors.darkGray
                  : rowIndex % 2 === 0
                  ? epicTheme.colors.white
                  : epicTheme.colors.gray,
              cursor: actualOnClick ? 'pointer' : 'default',
              display: 'flex',
            }}
          >
            {items.map((item, i) => (
              <div key={i} onClick={actualOnClick} style={columnStyle}>
                {item}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
