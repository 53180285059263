import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useHover = <T extends HTMLElement>(): [MutableRefObject<T | null>, boolean] => {
  const hoverRef = useRef<T>(null);

  const [hovered, setHovered] = useState(false);
  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  useEffect(
    () => {
      const { current } = hoverRef;

      if (current) {
        current.addEventListener('mouseenter', onMouseEnter);
        current.addEventListener('mouseleave', onMouseLeave);

        return () => {
          current.removeEventListener('mouseenter', onMouseEnter);
          current.removeEventListener('mouseleave', onMouseLeave);
        };
      }

      return undefined;
    },
    /**
     * NOTE: A very important caveat!
     *
     * Because the element held by hoverRef could change and would not trigger
     *  this effect to run again (since we're triggering off hoverRef, not the
     *  actual element inside), this hook will not operate reliably in all
     *  circumstances.
     *
     * For example, we used to have this hook monitor the hover state of the
     *  condition buttons. It worked perfectly when the condition buttons were
     *  rendered on the condition but worked imperfectly when the condition
     *  buttons were rendered on the condition line. So, we switched to the
     *  current implementation of useState + component onEvent prop handlers.
     */

    [hoverRef]
  );

  return [hoverRef, hovered];
};
