import * as Sentry from '@sentry/browser';

import { ConditionLineType, ConditionLineTypeLiteral } from '~/app/@types';
import {
  MeshedNoteBulletHtmlLine,
  MeshedNoteFreetextHtmlLine,
  MeshedNoteHtmlLine,
  MeshedNoteTitleHtmlLine,
} from '~/app/@types/state';

import { getDominantTitleSignifierFromChunksWithLines } from '~/app/controllers/noteMeshing/getDominantTitleSignifier';
import { parseHtmlNoteByTitles } from '~/app/controllers/noteMeshing/parseHtmlNoteByTitles';
import { DEFAULT_TITLE_SIGNIFIER } from '~/app/controllers/regex';
import { EMPTY_BR_HTML_LINE } from '~/app/constants';

import { replaceTextInHtmlString } from '..';

const ORDERED_LIST = 'ol';
const ORDERED_LIST_TAG = '<ol>';
const ORDERED_LIST_CLOSE_TAG = '</ol>';
const UNORDERED_LIST = 'ul';
const UNORDERED_LIST_TAG = '<ul>';
const UNORDERED_LIST_CLOSE_TAG = '</ul>';
const LIST_ITEM = 'li';
const LIST_ITEM_TAG = '<li>';
const LIST_ITEM_CLOSE_TAG = '</li>';

type ListStackTags = typeof ORDERED_LIST | typeof UNORDERED_LIST | typeof LIST_ITEM;

interface ReplaceLineDivsWithListsOptions {
  emptyLine: HtmlString;
}

interface InternalState {
  // Currently built html string
  chunkedHtml: string;
  // Stack of currently open list tags
  listStack: ListStackTags[];
  // Last line type that opened a list tag
  lastListable: '' | ConditionLineTypeLiteral;
}

/* eslint-disable no-param-reassign */
const closeOutListStack = (state: InternalState, { retainNEntries } = { retainNEntries: 0 }) => {
  // Close out any remaining lists in the stack
  while (state.listStack.length && state.listStack.length > retainNEntries) {
    const listItem = state.listStack.pop();
    if (listItem === ORDERED_LIST) {
      state.chunkedHtml += ORDERED_LIST_CLOSE_TAG;
    } else if (listItem === UNORDERED_LIST) {
      state.chunkedHtml += UNORDERED_LIST_CLOSE_TAG;
    } else if (listItem === LIST_ITEM) {
      state.chunkedHtml += LIST_ITEM_CLOSE_TAG;
    }
  }
};

const processTitleLine = (
  state: InternalState,
  line: MeshedNoteTitleHtmlLine,
  titleSignifier: string
) => {
  const inAList = !!state.listStack.length;
  if (!inAList) {
    const listElement = titleSignifier === DEFAULT_TITLE_SIGNIFIER ? UNORDERED_LIST : ORDERED_LIST;
    const listTag =
      titleSignifier === DEFAULT_TITLE_SIGNIFIER ? UNORDERED_LIST_TAG : ORDERED_LIST_TAG;
    state.listStack.push(listElement);
    state.chunkedHtml += listTag;
  }

  state.lastListable = ConditionLineType.Title;

  // New titles should close out all open list tags other than the
  // top level list.
  closeOutListStack(state, { retainNEntries: 1 });

  state.listStack.push(LIST_ITEM);
  state.chunkedHtml += LIST_ITEM_TAG;

  // remove the signifier and space after (if it exists)
  const sanitizedLine = replaceTextInHtmlString(line.html, line.titleSignifier, '', {
    trimLeadingWhitespace: true,
  });
  state.chunkedHtml += sanitizedLine;
};

const processBulletLine = (state: InternalState, line: MeshedNoteBulletHtmlLine) => {
  const shouldAddNewList = state.lastListable === ConditionLineType.Title;
  const lastItemIsOpenListItem = state.listStack[state.listStack.length - 1] === LIST_ITEM;

  state.lastListable = ConditionLineType.Bullet;
  if (shouldAddNewList) {
    state.listStack.push(UNORDERED_LIST);
    state.chunkedHtml += UNORDERED_LIST_TAG;
  } else if (lastItemIsOpenListItem) {
    // Close out the previous bullet list item
    state.listStack.pop();
    state.chunkedHtml += LIST_ITEM_CLOSE_TAG;
  }

  // Don't add list item tags for bullets in pre-condition text or footer
  const inAList = !!state.listStack.length;
  if (inAList) {
    state.listStack.push(LIST_ITEM);
    state.chunkedHtml += LIST_ITEM_TAG;
  }

  // remove the bullet and space after (if it exists)
  const sanitizedLine = replaceTextInHtmlString(line.html, line.bulletSignifier, '', {
    trimLeadingWhitespace: true,
  });
  state.chunkedHtml += sanitizedLine;
};

const processFreetextLine = (
  state: InternalState,
  line: MeshedNoteFreetextHtmlLine,
  chunkLines: MeshedNoteHtmlLine[],
  lineIndex: number,
  emptyLine: string
) => {
  const isFinalLine = lineIndex === chunkLines.length - 1;
  const isBlankLine = line.html === emptyLine;
  if (isFinalLine && isBlankLine) {
    closeOutListStack(state, { retainNEntries: 2 });
  }

  state.chunkedHtml += line.html;
};
/* eslint-enable no-param-reassign */

export const replaceLineDivsWithLists = (
  domNote: HtmlString,
  options: ReplaceLineDivsWithListsOptions
) => {
  const chunkedNote = parseHtmlNoteByTitles({
    noteHtml: domNote,
    parseMode: 'chunkForListConversion',
  });
  const titleSignifier = getDominantTitleSignifierFromChunksWithLines(chunkedNote.chunksWithLines);

  const state: InternalState = {
    chunkedHtml: '',
    listStack: [],
    lastListable: '',
  };

  chunkedNote.chunksWithLines.forEach((chunk) => {
    // Don't include chunk if it's empty
    const isEmpty = chunk.lines.length === 1 && chunk.lines[0].html === EMPTY_BR_HTML_LINE;
    if (isEmpty) return;

    chunk.lines.forEach((line, lineIndex) => {
      if (line.type === ConditionLineType.Title) {
        processTitleLine(state, line, titleSignifier);
      } else if (line.type === ConditionLineType.Bullet) {
        processBulletLine(state, line);
      } else if (line.type === ConditionLineType.FreeText) {
        processFreetextLine(state, line, chunk.lines, lineIndex, options.emptyLine);
      } else {
        Sentry.withScope((scope: Sentry.Scope) => {
          scope.setExtra('note line', line);
          const sentryRegardError = new Error(
            'Faild to replace line divs with lists. Unsupported line type'
          );
          Sentry.captureException(sentryRegardError);
        });
      }
    });
  });

  closeOutListStack(state);

  return state.chunkedHtml as HtmlString;
};
