import { FC, useEffect } from 'react';

import { getCss, getTitleButtonStyle } from './css';
import { useUserFeedbackForm } from '../userFeedbackWidget/useUserFeedbackForm';
import { COMPANY_NAME } from '../../../../constants';
import { Button, InputSubmitButton, LabeledInput, LabeledTextarea } from '../../../../reuse';

export const UserFeedbackForm: FC<
  Pick<
    ReturnType<typeof useUserFeedbackForm>,
    | 'cannotSend'
    | 'editEmail'
    | 'editText'
    | 'formCancel'
    | 'formClose'
    | 'formRef'
    | 'formSubmit'
    | 'formState'
    | 'toggleIncludeEmail'
  > & {
    fullSize?: boolean;
    visible: boolean;
  }
> = ({
  cannotSend,
  editEmail,
  editText,
  formCancel,
  formClose,
  formRef,
  formSubmit,
  formState,
  toggleIncludeEmail,
  fullSize = false,
  visible,
}) => {
  // Give the text area focus each time the feedback widget opens.
  useEffect(() => {
    if (visible) setTimeout(() => formRef.current?.querySelector('textarea')?.focus(), 0);
  }, [formRef, visible]);

  return (
    <div css={getCss({ fullSize, visible })} data-cy-user-feedback-form data-user-feedback-form>
      <div
        data-cy-user-feedback-title-button
        onClick={fullSize ? undefined : formClose}
        style={getTitleButtonStyle({ fullSize })}
      >
        <span>{fullSize ? 'Contact Us' : `${COMPANY_NAME} Feedback`}</span>
      </div>
      <form ref={formRef} noValidate onSubmit={formSubmit} style={{ position: 'relative' }}>
        <LabeledTextarea
          error={formState.textError}
          label="Describe the issue"
          onChange={editText}
          required
          rows={5}
          style={{ textAlign: 'left' }}
          subLabel="*Required"
          value={formState.text}
        />
        <div
          style={{
            marginBottom: 6,
            marginTop: 6,
            textAlign: 'left',
          }}
        >
          <label htmlFor="user-feedback-email">
            <input
              checked={!!formState.includeEmail}
              id="user-feedback-email"
              onChange={toggleIncludeEmail}
              style={{
                marginRight: 4,
                position: 'relative',
                top: 2,
              }}
              type="checkbox"
            />
            Email me status updates
          </label>
        </div>
        {formState.includeEmail ? (
          <LabeledInput
            error={formState.emailError}
            inputProps={{
              autoComplete: 'off',
              type: 'email',
            }}
            label="Email Address"
            onChange={editEmail}
            required
            style={{ marginBottom: 8, textAlign: 'left' }}
            value={formState.email}
          />
        ) : (
          <div style={{ height: 8 }} />
        )}
        <div style={{ textAlign: 'right' }}>
          <Button
            color="tertiary"
            css={{ display: 'inline-block', marginRight: 4 }}
            data-cy-close-user-feedback-button
            onClick={formCancel}
            size="standard"
          >
            Cancel
          </Button>
          <InputSubmitButton
            color="primary"
            css={{ cursor: cannotSend ? 'not-allowed' : 'pointer', display: 'inline-block' }}
            disabled={cannotSend}
            size="standard"
            value={fullSize ? 'Send' : 'Screenshot & Send'}
          />
        </div>
      </form>
    </div>
  );
};
