import { useCallback, useState } from 'react';

export const useBooleanState = (
  defaultValue: boolean
): [
  boolean,
  {
    setFalse(): void;
    setTrue(): void;
    toggle(): void;
  }
] => {
  const [boolean, setBoolean] = useState(defaultValue);
  const setFalse = useCallback(() => setBoolean(false), [setBoolean]);
  const setTrue = useCallback(() => setBoolean(true), [setBoolean]);
  const toggle = useCallback(() => setBoolean((value) => !value), [setBoolean]);

  return [
    boolean,
    {
      setFalse,
      setTrue,
      toggle,
    },
  ];
};
