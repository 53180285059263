import * as Sentry from '@sentry/browser';

import { RegardNoteError } from '~/app/errors';
import * as flags from '~/app/flags';
import { getUserReadOnly } from '~/app/cookies';

import { APIResult, fetchJson, wrapSuccessResult } from './base';
import { EMPTY_BR_HTML_LINE } from '../constants';

export type FetchDraftApiResult = {
  note_html: HtmlString;
  basenote_content_hash: string | null;
} | null;

/**
 * Fetch server-persisted draft note for the basenote/encounter/user from the API
 */
export const fetchDraft = async (
  basenoteId: string,
  encounterId: string
): Promise<APIResult<FetchDraftApiResult>> => {
  const params = new URLSearchParams({
    basenote_id: basenoteId,
    encounter_id: encounterId,
    status: 'DRAFT',
    html_version: `${flags.getCurrentHtmlVersion()}`,
  });
  const response = await fetchJson<FetchDraftApiResult>(
    `/api/generated-notes?${params.toString()}`
  );
  if (response.status === 'success') {
    const { result } = response;

    return wrapSuccessResult(result);
  }

  return response;
};

type SavedNoteStatus = 'DRAFT' | 'COPIED' | 'MESHED';

/**
 * Persist note for the basenote/encounter/user/status to the server
 */
const persistNote = async (
  basenoteId: string,
  encounterId: string,
  baseNoteHash: string | null,
  noteText: string,
  status: SavedNoteStatus
): Promise<APIResult<null>> => {
  if (flags.isDraftSaveDisabled) {
    return {
      status: 'success',
      result: null,
    };
  }

  const saveDraftNoteApiTimestamp = new Date().valueOf();
  window.store.dispatch({
    type: 'set save draft note status',
    payload: {
      saveDraftNoteStatus: 'saving',
      saveDraftNoteApiTimestamp,
    },
  });

  const formData = new FormData();
  formData.append('basenote_id', basenoteId);
  formData.append('encounter_id', encounterId);
  formData.append('status', status);
  formData.append(
    'note_text',
    noteText ||
      // server doesn't like an empty string
      EMPTY_BR_HTML_LINE
  );
  formData.append('html_version', `${flags.getCurrentHtmlVersion()}`);
  if (baseNoteHash != null) {
    formData.append('basenote_content_hash', baseNoteHash);
  }

  const result = await fetchJson<null>(`/api/generated-notes`, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  });

  const saveDraftNoteStatus =
    flags.isSalesDemoMode || result.status === 'success' ? 'saved' : 'error';
  if (saveDraftNoteStatus !== 'saved' && !getUserReadOnly()) {
    Sentry.captureException(new RegardNoteError('Failed to save draft note.'));
  }
  window.store.dispatch({
    type: 'set save draft note status',
    payload: {
      saveDraftNoteStatus,
      saveDraftNoteApiTimestamp,
    },
  });

  return result;
};
/**
 * Persist draft note for the basenote/encounter/user to the server
 */
export const persistDraftNote = async (
  basenoteId: string,
  encounterId: string,
  baseNoteHash: string | null,
  noteText: string
) => persistNote(basenoteId, encounterId, baseNoteHash, noteText, 'DRAFT');
/**
 * Persist copied note for the basenote/encounter/user to the server
 */
export const persistCopiedNote = async (
  basenoteId: string,
  encounterId: string,
  baseNoteHash: string | null,
  noteText: string
) => persistNote(basenoteId, encounterId, baseNoteHash, noteText, 'COPIED');
/**
 * Persist meshed note for the basenote/encounter/user to the server
 */
export const persistMeshedNote = async (
  basenoteId: string,
  encounterId: string,
  baseNoteHash: string | null,
  noteText: string
) => persistNote(basenoteId, encounterId, baseNoteHash, noteText, 'MESHED');
