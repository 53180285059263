import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const encephalopathySummary = (
  <>
    <SummaryP first>
      Triggered if any note is found mentioning encephalopathy, altered mental status, or delirium.
      Specificity of:
    </SummaryP>
    <SummaryUl>
      <li>
        <SummarySpanB>Possibly toxic</SummarySpanB> if abnormal urine/serum toxicology, ethanol,
        salicylate, valproate, digoxin, or lithium labs.
      </li>
      <li>
        <SummarySpanB>Possible metabolic</SummarySpanB> for abnormal labs in the{' '}
        <SummarySpanB>last 72 hours</SummarySpanB>:
        <SummaryUl innerList>
          <li>
            Sodium, BUN, glucose (low), venous / arterial pCO2 (high), serum / ionized calcium, TSH
            / free T4, ammonia, vitamin B12 (low), or porphobilinogen.
          </li>
        </SummaryUl>
      </li>
      <li>
        <SummarySpanB>Possible neurological</SummarySpanB> based on CT brain, MRI brain,
        electroencephalogram (EEG), CSF oligoclonal bands, or an autoimmune encephalitis panel.
      </li>
      <li>
        <SummarySpanB>Possible delirium</SummarySpanB> is recommended if none of the above are
        present and delirium is not definitively specified in a prior note.
      </li>
    </SummaryUl>
    <SummaryP>
      Medications that may affect mental status along with currently ordered neuroleptics within the
      past 48 hours are reported.
    </SummaryP>
  </>
);
