export const canUseClipboardAPI = async () => {
  if (!navigator.clipboard) return false;

  try {
    // Note: This permission check only works in chromium/blink browsers.
    // Others, like Firefox, will fail be forced to use the legacy copy command
    // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1245#issuecomment-1522204068
    const permissionStatus = await navigator.permissions.query({
      name: 'clipboard-write' as PermissionName,
    });
    if (['granted', 'prompt'].includes(permissionStatus.state)) {
      return true;
    }
  } catch (e) {
    /* empty */
  }

  return false;
};
