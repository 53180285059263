import { shallowEqual } from 'fast-equals';
import { FC, memo } from 'react';
import { memoize } from 'lodash';

import { ConditionLineStatus } from '~/app/@types';
import ChevronDownSVG from '~/app/images/chevron-down.svg';
import { CSS } from '~/app/reuse';

import { ConditionTitleRegardIndicator } from '../conditionTitleRegardIndicator';
import { NOTE_LEFT_GUTTER, CONDITION_TEXTAREA_LINE_HEIGHT } from '../const';

const rootStyle = {
  left: -NOTE_LEFT_GUTTER,
  position: 'absolute',
  zIndex: 1, // To keep the buttons above the condition line backgrounds
} as const;

const innerStyle = {
  height: CONDITION_TEXTAREA_LINE_HEIGHT,
  position: 'relative',
} as const;

const getAccordionSvgButtonCss = memoize(
  ({ isMinimized }: { isMinimized: boolean }): CSS => ({
    cursor: 'pointer',
    position: 'relative',
    height: 16,
    left: 28,
    top: 3,
    width: 16,
    '& svg': {
      transform: isMinimized ? 'none' : 'rotate(180deg)',
      transition: '200ms ease',
    },
  })
);

export const ConditionTitleDecorator: FC<{
  hasNonCriticalSuggestions: boolean;
  isShelvedCondition: boolean;
  status: ConditionLineStatus;
  suggestionsAreMinimized: boolean;
  toggleSuggestionsMinimized(): void;
}> = memo(
  ({
    hasNonCriticalSuggestions,
    isShelvedCondition,
    status,
    suggestionsAreMinimized,
    toggleSuggestionsMinimized,
  }) => (
    <div style={rootStyle}>
      <div style={innerStyle}>
        <ConditionTitleRegardIndicator status={status} />
        {isShelvedCondition && hasNonCriticalSuggestions && (
          <div
            css={getAccordionSvgButtonCss({ isMinimized: suggestionsAreMinimized })}
            data-cy-toggle-suggestions-minimized-button
            onClick={toggleSuggestionsMinimized}
          >
            <ChevronDownSVG />
          </div>
        )}
      </div>
    </div>
  ),
  shallowEqual
);
ConditionTitleDecorator.displayName = 'ConditionTitleDecorator';
