import { CSSProperties, FC, useMemo } from 'react';

import { curtainClosedStyle, curtainOpenStyle, drawerClosedStyle, drawerOpenStyle } from './css';

export type DrawerBaseProps = {
  close(): void;
  enableCurtain: boolean;
  isOpen: boolean;
  openByDefault?: boolean;
  style?: { curtain?: CSSProperties; drawer?: CSSProperties };
  zIndex: number;
};

const drawerCss = {
  boxSizing: 'border-box',
  '& > *': {
    boxSizing: 'border-box',
  },
} as const;

export const DrawerBase: FC<DrawerBaseProps> = ({
  children,
  close,
  enableCurtain,
  isOpen,
  style,
  zIndex,
  ...rest
}) => {
  const curtainStyle = style?.curtain;
  const finalCurtainOpenStyle = useMemo(
    () => ({
      ...curtainOpenStyle,
      zIndex,
      ...curtainStyle,
      display: enableCurtain ? 'block' : 'none',
    }),
    [curtainStyle, zIndex, enableCurtain]
  );
  const finalCurtainClosedStyle = useMemo(
    () => ({
      ...curtainClosedStyle,
      zIndex,
      ...curtainStyle,
    }),
    [curtainStyle, zIndex]
  );

  const drawerStyle = style?.drawer;
  const drawerZIndex = zIndex + 2;
  const finalDrawerOpenStyle = useMemo(
    () => ({
      ...drawerOpenStyle,
      zIndex: drawerZIndex,
      ...drawerStyle,
    }),
    [drawerStyle, drawerZIndex]
  );
  const finalDrawerClosedStyle = useMemo(
    () => ({
      ...drawerClosedStyle,
      zIndex: drawerZIndex,
      ...drawerStyle,
    }),
    [drawerStyle, drawerZIndex]
  );

  return (
    <>
      <div
        aria-label="close"
        onClick={close}
        role="button"
        style={isOpen ? finalCurtainOpenStyle : finalCurtainClosedStyle}
        tabIndex={0}
      />
      <div css={drawerCss} style={isOpen ? finalDrawerOpenStyle : finalDrawerClosedStyle} {...rest}>
        {children}
      </div>
    </>
  );
};
DrawerBase.displayName = 'DrawerBase';
