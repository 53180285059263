import { ReactElement, useState } from 'react';

import { EpicTab, EpicTabProps } from './EpicTab';
import { epicTheme } from './epicTheme';

export const EpicTabs = ({ children }: { children: ReactElement<EpicTabProps>[] }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabsProps = children.map(({ props }, i) => ({
    ...props,
    isSelected: i === selectedTab,
    onClick: () => setSelectedTab(i),
  }));
  const { children: content, style } = children[selectedTab].props;

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginLeft: 24,
        }}
      >
        {tabsProps.map((tabProps, i) => (
          <EpicTab key={i} {...tabProps} />
        ))}
      </div>
      <div
        data-cy-demo-tab-content
        style={{
          backgroundColor: epicTheme.colors.lightGray,
          height: '100%',
          overflow: 'auto',
          padding: 24,
          ...style,
        }}
      >
        {content}
      </div>
    </>
  );
};
