import { htmlStringToPlainText, replaceTextInHtmlString } from '~/app/utils';
import { parseHtmlNoteByTitles } from '~/app/controllers/noteMeshing/parseHtmlNoteByTitles';

/**
 * Sanitize all title lines
 * - Sanitize 1: Make sure all title signifiers are followed by a space. 3M does not parse
 *   lines correctly otherwise.
 */
export const sanitizeHtmlTitles = (html: HtmlString): HtmlString => {
  const chunkedNote = parseHtmlNoteByTitles({
    noteHtml: html,
    parseMode: 'copy',
  });

  let chunkedHtml = '';
  chunkedNote.chunksWithLines.forEach(({ lines, type }) => {
    lines.forEach((line) => {
      // Case: Not a condition OR not a title line
      if (
        (type !== 'condition' && type !== 'shelvedCondition') || //
        line.type !== 'title'
      ) {
        chunkedHtml += line.html;
        return;
      }

      // Case: Title line, but already has white space after the signifier
      const plainText = htmlStringToPlainText(line.html);
      const characterAfterSignifier = plainText[line.titleSignifier.length];
      const characterAfterSignifierIsWhiteSpace = characterAfterSignifier.match(/\s/) !== null;
      if (characterAfterSignifierIsWhiteSpace) {
        chunkedHtml += line.html;
        return;
      }

      // Case: Title line, and does not have a space after the signifier
      const sanitizedLine = replaceTextInHtmlString(
        line.html,
        line.titleSignifier,
        `${line.titleSignifier} `
      );
      chunkedHtml += sanitizedLine;
    });
  });

  return chunkedHtml as HtmlString;
};
