import { combineReducers } from 'redux';
import regardNote from './regardNote';
import login from './login';
import record from './record';
import ui from './ui';
import { undo } from './undo';

export const reducers = combineReducers({
  login,
  regardNote,
  record,
  ui,
  undo,
});
