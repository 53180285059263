import { FC, useCallback, useRef } from 'react';
import { noop } from 'lodash';
import { CKEditorEventHandler } from 'ckeditor4-react';

import { useDispatch } from '~/app/store';
import { setLastFocusedEditor } from '~/app/actions/ui';
import { SHELF_SIGNIFIER } from '~/app/utils';
import { theme } from '~/app/reuse';
import { CKEditorRichTextEditor, config } from '~/app/reuse/richTextEditor';

import { useNoteBlockContext } from '../noteBlockProvider';
import { useShelfDividerText } from './useShelfDividerText';
import { useShelfDividerOnHtmlInput } from './useShelfDividerOnHtmlInput';
import { NOTE_LEFT_GUTTER, NOTE_RIGHT_GUTTER } from '../const';

const customLineStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: 20,
  paddingLeft: NOTE_LEFT_GUTTER,
  paddingRight: NOTE_RIGHT_GUTTER,
} as const;

const shelfLineDisclaimerLineStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: 8,
  paddingLeft: 40,
  paddingRight: NOTE_RIGHT_GUTTER,
  lineHeight: 1,
} as const;

const shelfLineDisclaimerTextStyle = {
  backgroundColor: theme.colors.white,
  fontSize: 12,
  color: theme.colors.grey3,
  display: 'inline-block',
  fontWeight: 200,
  padding: '0 12px',
  position: 'relative',
} as const;

const margin = 8;
const shelfSignifierWidth = 21;
const sideWidth = margin * 2 + shelfSignifierWidth * 2;
const placeholderWidth = 165;
const customLineEditorWrapperStyle = {
  margin: `0 ${margin}px`,
  maxWidth: `calc(100% - ${sideWidth}px)`,
  minWidth: placeholderWidth,
  wordWrap: 'break-word',
} as const;

const lineBreakElementsRegex =
  /<br\s*\/?>|<p\s*\/?>|<\/p\s*\/?>|<div\s*\/?>|<\/div\s*\/?>|<hr\s*\/?>/gi;
const removeLineBreaks = (html: string): string => html.replace(lineBreakElementsRegex, '');

const allowedContent = 'strong em u';

const editorConfig = {
  ...config,
  plugins: [...config.plugins, 'regardEditorPlaceholder', 'doNothing'],
  regardEditorPlaceholder: 'Name this section (optional)',
  // Disable enter key since shelved name is only a single line
  keystrokes: [
    [13, 'doNothing'], // Enter
    [CKEDITOR.SHIFT + 13, 'doNothing'], // Shift + Enter
  ],
  // Remove line breaks from getting pasted into the editor
  allowedContent,
  pasteFilter: allowedContent,
  customPasteFilter: removeLineBreaks,
};

let lastFocusedEditor = '';

export const ShelvedNameEditor: FC = () => {
  const dispatch = useDispatch();
  const setRteCaretPositionRef = useRef<(position: number) => void>(noop);

  const { id } = useNoteBlockContext(({ id }) => ({ id }));

  const { htmlDividerLineWithoutShelfSignifier, textTimestamp } = useShelfDividerText(id);

  const onShelfHtmlInput = useShelfDividerOnHtmlInput(id);

  const onHtmlInput = useCallback(
    ({ caretPos, rawHtml, prevHtmlRef }) => {
      onShelfHtmlInput({
        caretPos,
        prevHtmlRef,
        rawHtml,
      });
    },
    [onShelfHtmlInput]
  );

  const onFocus: CKEditorEventHandler<'focus'> = useCallback(
    ({ editor }) => {
      lastFocusedEditor = editor.name;
      dispatch(setLastFocusedEditor(editor.name));
    },
    [dispatch]
  );

  const onBlur: CKEditorEventHandler<'blur'> = useCallback(
    ({ editor }) => {
      // If we're blurring the editor that was last focused, that's something to
      //  care about; otherwise, this event is simply noise
      if (editor.name === lastFocusedEditor) {
        dispatch(setLastFocusedEditor(''));
      }
    },
    [dispatch]
  );

  const onDestroy: CKEditorEventHandler<'destroy'> = useCallback(
    ({ editor }) => {
      // If the editor is destroyed, treat it like the editor is being blured,
      // since the blur event isn't fired.
      if (editor.name === lastFocusedEditor) {
        dispatch(setLastFocusedEditor(''));
      }
    },
    [dispatch]
  );

  return (
    <>
      <div key={textTimestamp} style={customLineStyle}>
        <span>{SHELF_SIGNIFIER}</span>
        <div style={customLineEditorWrapperStyle}>
          <CKEditorRichTextEditor
            config={editorConfig}
            editorType="shelfDivider"
            htmlLines={[htmlDividerLineWithoutShelfSignifier]}
            id={`${id}-shelf-divider`}
            onBlur={onBlur}
            onDestroy={onDestroy}
            onFocus={onFocus}
            onHtmlInput={onHtmlInput}
            setRteCaretPositionRef={setRteCaretPositionRef}
          />
        </div>
        <span>{SHELF_SIGNIFIER}</span>
      </div>
      <div style={shelfLineDisclaimerLineStyle}>
        <div style={shelfLineDisclaimerTextStyle}>
          (section title is editable and included when copying note)
        </div>
      </div>
    </>
  );
};
