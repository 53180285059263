import { MutableRefObject, useEffect, useMemo } from 'react';

import { get, isElementVisibleInViewport } from '~/app/utils';
import { createSelector, useSelector } from '../../../store';

export const useScrollToNoteBlock = ({
  noteBlockRef,
  id,
}: {
  noteBlockRef: MutableRefObject<HTMLDivElement | null>;
  id: string;
}): void => {
  const getScrollIntoView = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.noteBlocksById, id),
        (noteBlock) => noteBlock?.scrollIntoView
      ),
    [id]
  );

  const scrollIntoView = useSelector(getScrollIntoView);

  useEffect(() => {
    /**
     * We must use a setTimeout here, because the UI hasn't finished rendering,
     *  and we cannot therefore assume that the positions of these elements are
     *  stable until the render has completed!
     */
    setTimeout(() => {
      const el = noteBlockRef.current;
      if (scrollIntoView && el) {
        // Smooth scroll
        const alreadyVisible = isElementVisibleInViewport(el, { wiggleRoom: 12 });
        if (!alreadyVisible) el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  }, [noteBlockRef, scrollIntoView]);
};
