import { NBSP_SINGLE, EMPTY_LINE_BR, NBSP } from '~/app/constants';
import { nbspifyStringForCkeditor } from '..';

const LENGTH_OF_OPENING_DIV = '<div>'.length;
const LENGTH_OF_CLOSING_DIV = '</div>'.length;
const NBSP_REGEX = new RegExp(NBSP_SINGLE, 'g');

// As best as we can, convert an html string to the exact format that CKEditor
//  would convert/render it it as. This results in less thrashing.
export const htmlLineToCkeditorHtmlLine = (htmlString: HtmlString): HtmlString =>
  // 5. Finally, add the opening and closing div tags back in
  `<div>${
    // NOTE: This call to nbspifyStringForCkeditor will not deal with the edge
    //  case of repeated spaces where some spaces are wrapped in other styling
    //  tags, such as `<div># Meow <span> </span><strong> </strong></div>`, but at
    //  that point, who really cares?
    // 2. Convert repeated spaces to non-breaking spaces (as CKEditor would)
    nbspifyStringForCkeditor(
      // 1. Strip opening and closing div tags
      htmlString.substring(
        LENGTH_OF_OPENING_DIV,
        htmlString.length - LENGTH_OF_CLOSING_DIV
      ) as HtmlString
    )
      // 3. Convert non-breaking spaces to '&nbsp;'
      .replace(NBSP_REGEX, NBSP) ||
    // 4. Or if string is empty, insert at br tag
    EMPTY_LINE_BR
  }</div>` as HtmlString;
