import { FC, memo, ReactNode } from 'react';
import _ from 'lodash';

import {
  authorCellStyle,
  getdropdownClosedStyle,
  getdropdownOpenStyle,
  dropdownRowInnerStyle,
  getRowCss,
  abbrNoteTypeCellStyle,
  timestampCellStyle,
  statusCellStyle,
  previousEncountersContainerStyle,
  previousEncountersHeaderStyle,
  previousEncountersTitleStyle,
} from './css';
import { useMeshedContext } from '../MeshedProvider';
import { circleCheck, circleEmpty, circleSelected } from './svg';
import { EligibleHtmlBaseNote } from '../../../@types/state';
import { COMPANY_NAME } from '../../../constants';
import { getBlankBaseNote, getIsBlankBaseNote } from '../../../controllers/regardNote';
import { formatDatetime } from '../../../utils';

const getIcon = ({ current, selected }: { current: boolean; selected: boolean }): ReactNode => {
  if (current) return circleCheck;
  if (selected) return circleSelected;
  return circleEmpty;
};

export const SelectBaseNoteDropdown: FC<{
  baseNotes: EligibleHtmlBaseNote[];
  currentBaseNote: EligibleHtmlBaseNote | undefined;
  isOpen: boolean;
  selectedBaseNote: EligibleHtmlBaseNote | undefined;
  onSelect(note: EligibleHtmlBaseNote): void;
}> = memo(({ baseNotes, currentBaseNote, isOpen, selectedBaseNote, onSelect }) => {
  let tabIndex = 0;
  function renderBaseNote(baseNote: EligibleHtmlBaseNote) {
    const { abbrNoteType, author, resourceId, effective, status } = baseNote;
    const current = currentBaseNote === baseNote;
    const selected = selectedBaseNote === baseNote;

    const onClick = () => onSelect(baseNote);

    const css = getRowCss({ current });
    tabIndex += 1;

    return (
      <div
        key={resourceId}
        css={css}
        data-cy-basenote-select
        onClick={onClick}
        role="button"
        tabIndex={tabIndex}
      >
        <div style={dropdownRowInnerStyle}>
          {getIcon({ current, selected })}
          <span style={timestampCellStyle}>{formatDatetime(effective)}</span>
          <span style={abbrNoteTypeCellStyle}>{abbrNoteType}</span>
          <span style={authorCellStyle}>{author}</span>
          <span style={statusCellStyle}>{_.startCase(status)}</span>
        </div>
      </div>
    );
  }

  const [currentEncounterBaseNotes, previousEncounterBaseNotes] = _.partition(
    baseNotes,
    (note) => note.currentEncounter
  );
  const { isScaledHorizontalLayout } = useMeshedContext(({ isScaledHorizontalLayout }) => ({
    isScaledHorizontalLayout,
  }));

  return (
    <>
      {
        // NOTE: If we want to animate this transition, we could employ
        //  https://www.npmjs.com/package/react-animate-height.
      }
      <div
        data-cy-basenote-dropdown
        style={
          isOpen
            ? getdropdownOpenStyle(isScaledHorizontalLayout)
            : getdropdownClosedStyle(isScaledHorizontalLayout)
        }
      >
        <div
          key="cyob-blank-basenote-option"
          css={getRowCss({ current: getIsBlankBaseNote(currentBaseNote), opacity: 1 })}
          data-cy-basenote-select
          onClick={() => onSelect(getBlankBaseNote())}
          role="button"
          tabIndex={0}
        >
          <div style={dropdownRowInnerStyle}>
            {getIcon({
              current: getIsBlankBaseNote(currentBaseNote),
              selected: getIsBlankBaseNote(selectedBaseNote),
            })}
            <span>Blank note (will load only {COMPANY_NAME} Dxs)</span>
          </div>
        </div>
        {currentEncounterBaseNotes.map(renderBaseNote)}
        {previousEncounterBaseNotes.length > 0 && (
          <div key="previous-encounters-basenote-list" style={previousEncountersContainerStyle}>
            <div css={previousEncountersHeaderStyle}>
              <span style={previousEncountersTitleStyle}>Previous encounters</span>
            </div>

            {previousEncounterBaseNotes.map(renderBaseNote)}
          </div>
        )}
      </div>
    </>
  );
});
SelectBaseNoteDropdown.displayName = 'SelectBaseNoteDropdown';
