import { FC, useState, useRef, useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import { isAfter, isEqual, parse } from 'date-fns';
import { ConnectedProps, connect } from 'react-redux';

import { APIConditionAssessmentPlanMed, AppState, ComplaintTableData } from '~/app/@types/state';

import { ComplaintTableSection } from '../ComplaintTableSection';
import { ComplaintTimeline } from '../ComplaintTimeline';

import {
  InitialState,
  OptionSection,
  TableOption,
  TableOptions,
  getInitialState,
} from './getInitialTableState';
import { sortTableDataByLabel } from './sortTableDataByLabel';
import { useTableData } from './convertTableDataToMaps';

import '~/app/styles/ComplaintTable.scss';
import { generateDataRows } from './generateDataRows';
import { generateMedRows } from './generateMedRows';
import { generateDatetimeTds } from './generateDatetimeTds';

interface ComplaintTableProps extends PropsFromRedux {
  // The below fields are used in the component, so it's
  // unclear why lint is complaining.
  // eslint-disable-next-line react/no-unused-prop-types
  tableData?: ComplaintTableData;
  // eslint-disable-next-line react/no-unused-prop-types
  meds: APIConditionAssessmentPlanMed[];
  // eslint-disable-next-line react/no-unused-prop-types
  module: string;
}

type ComplaintTableState = InitialState;

const sortLabelsFunc = (
  tableData: ComplaintTableData | undefined,
  state: ComplaintTableState,
  statePiece: OptionSection
) => {
  if (!tableData) return () => 0;
  const stateObj: TableOptions = state[statePiece];
  return sortTableDataByLabel(tableData, stateObj);
};

/* This function is passed to the ComplaintTableMenus so the user can
 * toggle on/off labs, vitals and medications individually */
const editOption =
  (optionSection: OptionSection, setState: Dispatch<SetStateAction<ComplaintTableState>>) =>
  (option: keyof TableOption) => {
    setState((prevState) => ({
      ...prevState,
      [optionSection]: {
        ...prevState[optionSection],
        [option]: {
          ...prevState[optionSection][option],
          show: !prevState[optionSection][option].show,
        },
      },
    }));
  };

/*
 * The ComplaintTable component digests tableData (the serialized
 * version of automed.views.charts objects in an assessment_plan)
 * It first filters which "charts" it needs to render to tables.
 * Then does some data manipulation to create objects for rendering.
 * Finally it puts all the data into a <table> for rendering.
 */
const UnconnectedComplaintTable: FC<ComplaintTableProps> = (props: ComplaintTableProps) => {
  const { tableData, meds, encounterStart, module, timestamp } = props || {};

  const tableRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<ComplaintTableState>(() => getInitialState(meds, tableData));

  useEffect(() => {
    // Reset the component if the meds or tableData has changed
    setState(getInitialState(meds, tableData));

    /* When a user switches the condition data they are viewing,
     * we want to reset the table's horizontal scroll position */
    if (!tableRef.current) return;
    tableRef.current.scrollLeft = 0;
  }, [meds, tableData]);

  const {
    highlightColumnDate,
    highlightRowLabel,
    labsOptions,
    leftMostVisibleDate,
    medicationOptions,
    vitalsOptions,
  } = state;

  const { dataMap, sortedDatetimes, vitalsLabels, labsLabels, timelineDataMap, medData } =
    useTableData(tableData, module, timestamp, labsOptions, medicationOptions, vitalsOptions);

  useEffect(() => {
    if (leftMostVisibleDate) return;
    setState((prevState) => ({ ...prevState, leftMostVisibleDate: sortedDatetimes[0] }));
  }, [leftMostVisibleDate, sortedDatetimes]);

  const handleHorizontalScroll = useCallback(
    ({ target }: React.UIEvent<HTMLDivElement>) => {
      const { leftMostVisibleDate } = state;

      const avgColumnWidth = (target.scrollWidth - 161) / sortedDatetimes.length;
      const dateIndex = Math.round(target.scrollLeft / avgColumnWidth);
      if (leftMostVisibleDate !== sortedDatetimes[dateIndex]) {
        setState((prevState) => ({
          ...prevState,
          leftMostVisibleDate: sortedDatetimes[dateIndex],
        }));
      }
    },
    [sortedDatetimes, state]
  );

  const setHighlightRow = useCallback((label: string) => {
    setState((prevState) => ({ ...prevState, highlightRowLabel: label }));
  }, []);

  const scrollToDate = useCallback(
    (date?: Date | null) => {
      if (!date) return;
      setState((prevState) => ({ ...prevState, highlightColumnDate: null }));

      if (!tableRef.current) return;

      const totalScrollableWidth = tableRef.current.scrollWidth;
      const maxScrollLeft = totalScrollableWidth - tableRef.current.clientWidth;

      if (maxScrollLeft > 0) {
        const avgColumnWidth = (totalScrollableWidth - 161) / sortedDatetimes.length;
        const columIndex = sortedDatetimes.findIndex((d) => isEqual(d, date));
        if (columIndex >= 0) {
          tableRef.current.scrollLeft = Math.min(avgColumnWidth * columIndex, maxScrollLeft);
        } else if (date > sortedDatetimes[0]) {
          tableRef.current.scrollLeft = 0;
        }
      }
      setTimeout(() => setState((prevState) => ({ ...prevState, highlightColumnDate: date })), 10);
    },
    [sortedDatetimes]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editMedicationOptions = useCallback(editOption('medicationOptions', setState), [setState]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editVitalsOptions = useCallback(editOption('vitalsOptions', setState), [setState]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const editLabsOptions = useCallback(editOption('labsOptions', setState), [setState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const vitalsSortingFunc = useCallback(sortLabelsFunc(tableData, state, 'vitalsOptions'), [
    tableData,
    state,
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const labsSortingFunc = useCallback(sortLabelsFunc(tableData, state, 'labsOptions'), [
    tableData,
    state,
  ]);

  vitalsLabels.sort(vitalsSortingFunc);
  labsLabels.sort(labsSortingFunc);

  const labsAndVitalsLabels = vitalsLabels.concat(labsLabels);

  const inCurrentEnc = (d: Date) => isAfter(d, parse(encounterStart || ''));

  const dataRows = generateDataRows(labsAndVitalsLabels, sortedDatetimes, inCurrentEnc);
  const { sortedMedKeys, medRows, medSortingFunc } = generateMedRows(
    medData,
    timestamp || '',
    sortedDatetimes,
    scrollToDate,
    inCurrentEnc
  );
  const datetimeTds = generateDatetimeTds(
    sortedDatetimes,
    highlightColumnDate,
    dataMap,
    dataRows,
    inCurrentEnc
  );

  return (
    <>
      <div key="details" className="ComplaintTable" data-cy-complaint-table>
        <div ref={tableRef} className="wrapper" onScroll={handleHorizontalScroll}>
          <table>
            <thead>
              <tr key="dates">{datetimeTds}</tr>
            </thead>
            <tbody>
              {!!Object.keys(medicationOptions).length && [
                <ComplaintTableSection
                  key="medications"
                  label="Medications"
                  module={module}
                  options={medicationOptions}
                  sortingFunc={medSortingFunc}
                  toggleOption={editMedicationOptions}
                />,
                sortedMedKeys.map((key) => <tr key={key}>{medRows[key]}</tr>),
              ]}
              {!!Object.keys(vitalsOptions).length && [
                <ComplaintTableSection
                  key="vitals"
                  label="Vitals"
                  module={module}
                  options={vitalsOptions}
                  sortingFunc={vitalsSortingFunc}
                  toggleOption={editVitalsOptions}
                />,
                vitalsLabels.map((label) => (
                  <tr key={label} className={label === highlightRowLabel ? 'highlight' : ''}>
                    {dataRows[label]}
                  </tr>
                )),
              ]}
              {!!Object.keys(labsOptions).length && [
                <ComplaintTableSection
                  key="labs"
                  label="Labs"
                  module={module}
                  options={labsOptions}
                  sortingFunc={labsSortingFunc}
                  toggleOption={editLabsOptions}
                />,
                labsLabels.map((label) => (
                  <tr key={label} className={label === highlightRowLabel ? 'highlight' : ''}>
                    {dataRows[label]}
                  </tr>
                )),
              ]}
            </tbody>
          </table>
        </div>
      </div>
      {!!Object.keys(timelineDataMap).length && (
        <ComplaintTimeline
          key="table"
          dataMap={timelineDataMap}
          greyMarkerLocation={leftMostVisibleDate || sortedDatetimes[0]}
          highlightLabel={highlightRowLabel ?? undefined}
          scrollToDate={scrollToDate}
          setHighlightRow={setHighlightRow}
          sortedDatetimes={sortedDatetimes}
          sortedLabels={labsAndVitalsLabels}
        />
      )}
    </>
  );
};

const connector = connect(
  ({ regardNote: { encounterStart, timestamp } }: AppState) => ({
    encounterStart,
    timestamp,
  }),
  null,
  null,
  {
    areStatePropsEqual: (next, prev) => next.timestamp === prev.timestamp,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ComplaintTable = connector(UnconnectedComplaintTable);
