import _ from 'lodash';
import { theme, zIndices } from '../../../reuse';

export const TOOLBAR_HEIGHT = '33px'; // 32px height, 1px border

export const toolbarStyle = {
  msGridRow: 2,
  gridRow: 2,
  borderBottom: `1px solid ${theme.colors.grey4}`,
  zIndex: zIndices.noteToolbar,
  display: 'flex',
  background: `${theme.colors.grey6}`,
} as const;

export const getNoteToolbarStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...toolbarStyle,
      padding: isScaledHorizontalLayout ? '4px calc(50% - 336px)' : '4px 24px',
    } as const)
);

export const controlsStyle = {
  msFlex: '1 1 0%',
  flex: '1 1 0%',
  display: 'flex',
  alignContent: 'center',
} as const;

export const buttonTooltipStyle = {
  display: 'inline-block',
} as const;

export const dividerStyle = {
  display: 'inline-block',
  width: '1px',
  background: theme.colors.grey4,
  margin: '4px 4px',
  borderRadius: '4px',
} as const;

export const iconStyle = {
  display: 'block',
} as const;

export const subsequentIconStyle = {
  ...iconStyle,
  marginLeft: 2,
} as const;

export const stylingButtonContainerStyle = {
  display: 'flex',
} as const;
