import { Patient } from '~/app/@types/state';
import { createSelector, useSelector } from '~/app/store';
import { NULL_DATE } from '~/app/utils';

const DEFAULT_DATA: Patient = {
  patientInfo: {
    name: '',
    ageAndSex: '',
    dob: '',
    height: '',
    weight: '',
    ethnicGroup: '',
    races: '',
  },
  encounters: [],
  notes: [],
  problems: [],
  medications: [],
  orders: [],
  images: [],
  vitals: [],
  labResults: [],
  timestamp: NULL_DATE.toISOString(),
};

const demoDataSelector = createSelector(
  ({ record }) => record.data,
  (x) => x
);

export const useDemoData = (): Patient => {
  const data = useSelector(demoDataSelector);

  return data || DEFAULT_DATA;
};
