import _ from 'lodash';
import { theme, zIndices } from '../../../reuse';

export const NOTE_CONTAINER_HEADER_HEIGHT = 56;

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
} as const;

export const style = {
  backgroundColor: theme.colors.white,
  borderBottom: `1px solid ${theme.colors.grey5}`,
  msGridRow: 1,
  gridRow: 1,
  height: NOTE_CONTAINER_HEADER_HEIGHT,
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
} as const;

export const getNoteHeaderStyle = _.memoize(
  ({
    isScaledHorizontalLayout,
    isSelecting,
  }: {
    isScaledHorizontalLayout: boolean;
    isSelecting: boolean;
  }) =>
    ({
      ...style,
      padding: isScaledHorizontalLayout ? '0px calc(50% - 336px)' : '0px 24px',
      zIndex: isSelecting ? zIndices.noteHeader : 180,
    } as const)
);

export const titleContainerStyle = {
  flex: 1,
  minWidth: 0,
} as const;

export const buttonsContainerStyle = {
  display: 'flex',
  marginLeft: 20,
  whiteSpace: 'nowrap',
} as const;

export const titleStyle = {
  ...ellipsisStyle,
  display: 'flex',
  fontSize: 18,
  fontWeight: 600,
} as const;

export const subtitleStyle = {
  clear: 'both',
} as const;

export const subtitleTextStyle = {
  ...ellipsisStyle,
  display: 'inline-block',
  float: 'left',
  maxWidth: 'calc(100% - 106px)',
  position: 'relative',
} as const;

export const subtitleTextStyleNoButton = {
  ...subtitleTextStyle,
  maxWidth: '100%',
} as const;

export const subtitleButtonStyle = {
  position: 'relative',
  float: 'left',
  right: -4,
  top: 2,
} as const;

export const textStyle = {
  marginLeft: 0,
  flex: '0 1 content',
  minWidth: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;
