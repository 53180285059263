import { SummaryOl, SummaryUl } from './common';

export const hyperkalemiaSummary = (
  <SummaryOl>
    <li>
      Regard searches for abnormally elevated serum potassium labs from the current encounter.
    </li>
    <li>
      Based on gathered data the algorithm will include the severity and trajectory of the
      condition.
      <SummaryUl innerList>
        <li>
          Potassium &gt; 6.5 mmol/L is considered “severe”, a value greater than 5.5 mmol/L is
          “moderate”, and all other values are considered “mild”.
        </li>
        <li>
          Trajectory is determined by comparing the highest serum potassium and comparing it to the
          most recent.
        </li>
      </SummaryUl>
    </li>
    <li>
      Any relevant properties of the diagnosis are considered (creatinine clearance, urine output,
      hemolysis and WBCs for pseudohyperkalemia, dialysis) along with associated medications and
      presented.
    </li>
  </SummaryOl>
);
