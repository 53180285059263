import { FC, memo } from 'react';

import { isConditionNoteBlock } from '~/app/utils';
import { useNoteBlocks } from './useNoteBlocks';
import { NoteBlockProvider } from '../noteBlockProvider';
import { ShelfDivider } from '../condition/ShelfDivider';
import { ConditionWithProvider } from '../condition/ConditionWithProvider';

export const NoteBlocks: FC = memo(() => {
  const noteBlocks = useNoteBlocks();
  const noteBlocksLength = noteBlocks.length;

  const firstNewConditionIndex = noteBlocks.findIndex(
    (noteBlock) => isConditionNoteBlock(noteBlock) && noteBlock.isNew
  );

  return (
    <>
      {noteBlocks.map((noteBlock, i) => {
        const { id, key, scrollIntoView, type } = noteBlock;
        return (
          <NoteBlockProvider
            key={key}
            id={id}
            index={i}
            reverseIndex={noteBlocksLength - i - 1}
            triggerHighlight={scrollIntoView?.timestamp}
            type={type}
          >
            {(() => {
              switch (type) {
                case 'condition':
                case 'shelvedCondition': {
                  return (
                    <ConditionWithProvider
                      firstNewConditionIndex={firstNewConditionIndex}
                      i={i}
                      id={id}
                      isLastNoteBlock={i === noteBlocksLength - 1}
                      noteBlock={noteBlock}
                    />
                  );
                }
                case 'shelfDivider': {
                  return <ShelfDivider isLastNoteBlock={i === noteBlocksLength - 1} />;
                }
                default:
                  return null;
              }
            })()}
          </NoteBlockProvider>
        );
      })}
    </>
  );
});
NoteBlocks.displayName = 'NoteBlocks';
