import { FC } from 'react';
import { theme } from '../../reuse';

export const NoteContainerScrollMarker: FC = () => (
  <div
    data-note-container-scroll-marker
    style={{
      background: theme.colors.red,
      display: 'none',
      height: 8,
      position: 'absolute',
      right: 0,
      width: 8,
      zIndex: 10,
    }}
  />
);
