import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const chronicKidneyDiseaseSummary = (
  <>
    <SummaryP first>
      Regard will gather all sCr (serum creatinine) labs within the last 90 days of the most recent
      sCr and calculate an eGFR (estimated glomerular filtration rate) for each one. A positive
      diagnosis will trigger for a eGFR less than 60, and Regard will assign a stage to it.
    </SummaryP>
    <SummaryUl>
      <li>
        <SummarySpanB>stage 3A</SummarySpanB> if 45 &le; eGFR &lt; 60
      </li>
      <li>
        <SummarySpanB>stage 3B</SummarySpanB> if 30 &le; eGFR &lt; 45
      </li>
      <li>
        <SummarySpanB>stage 4</SummarySpanB> if 15 &le; eGFR &lt; 30
      </li>
      <li>
        <SummarySpanB>stage 5</SummarySpanB> if eGFR &lt; 15
      </li>
    </SummaryUl>
    <SummaryP>
      The algorithm will check for history of CKD from the notes and problem list to trigger a
      diagnosis. It will assign the most severe stage from the history if no labs are used, and if
      the history shows end stage renal disease, that will override the lab calculations. CKD is{' '}
      <SummarySpanB>not reported</SummarySpanB> when any eGFR within the last 90 days is greater
      than 60, if the most recent eGFR older than 90 days is more than 60, or if there are no labs
      within the last 90 days.
    </SummaryP>
    <SummaryP>
      Regard will also determine if a <SummarySpanB>patient is on dialysis</SummarySpanB> by
      searching previous notes and procedures from the 12 weeks before the current encounter for
      “iHD”, “dialysis”, “HD”, “peritoneal dialysis”, “CVVH”, “CRRT”, or “CVVHD”, followed by “MWF”,
      “TTS”, or “TuThSa” (schedule).
    </SummaryP>
  </>
);
