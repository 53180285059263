import { SummaryOl, SummaryUl } from './common';

export const hypokalemiaSummary = (
  <SummaryOl>
    <li>
      Regard searches for abnormally low serum potassium labs within the current encounter,
      including the day before.
    </li>
    <li>
      The algorithm will then determine severity and trajectory based on available data.
      <SummaryUl innerList>
        <li>
          “Severe” is considered a serum potassium under 3.0 mmol/L while any other values fall into
          the “mild to moderate” category.
        </li>
        <li>
          Trajectory is determined by comparing the last two potassium labs in a 72 hour period.
        </li>
      </SummaryUl>
    </li>
    <li>
      Associated properties related to the diagnosis are considered (last dialysis procedure within
      72 hours, associated electrolytes, creatinine) along with medications and are presented.
    </li>
  </SummaryOl>
);
