import { LINE_DIVIDER_TAG_UPPER_CASE } from '~/app/constants';
import { get } from '~/app/utils';

const makeRange = (node: Node | undefined, position: number): Range => {
  const range = document.createRange();
  if (node) {
    range.selectNode(node);
    range.setStart(node, 0);
    range.setEnd(node, position);
  }
  return range;
};

// https://stackoverflow.com/questions/6249095/how-to-set-the-caret-cursor-position-in-a-contenteditable-element-div
// NOTE: This function uses `textContent` rather than `innerText`. They produce
//  different results and there is some debate on which is better to use. If
//  we experience bugs in this function or other functions trawling over text
//  in the DOM, this is a point to consider.
const createRange = (
  node: Node | undefined,
  position: number
): { range: Range } | { position: number } => {
  if (position === 0) {
    return { range: makeRange(node, position) };
  }

  let currentPosition = position;

  if (node && position > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContentLength = (node.textContent ?? '').length;

      if (textContentLength < currentPosition) {
        currentPosition -= textContentLength;
      } else {
        return { range: makeRange(node, currentPosition) };
      }
    }

    for (let i = 0; i < node.childNodes.length; i++) {
      const result = createRange(node.childNodes[i], currentPosition);

      if ('range' in result) {
        // Early exit this function, because we have found the range.
        return { range: result.range };
      }
      if ('position' in result) {
        currentPosition = result.position;
      }
    }

    if (node.nodeName === LINE_DIVIDER_TAG_UPPER_CASE) {
      // If no match was found in this paragraph, remove 1 character to account for a newline.
      currentPosition -= 1;
    }
  }

  return { position: currentPosition };
};

const setCkeditorCaretPosAtNonZeroPosition = ({
  id,
  position,
}: {
  id: string;
  position: number;
}) => {
  if (position === 0) {
    // Performing this operation in IE will set the cursor before the first <div>
    //  tag, creating a litany of problems.
    throw new Error(`Cannot use this function to set caret position to zero.`);
  }

  const editor = get(window.CKEDITOR.instances, id);
  if (!editor) {
    console.warn(`Could not set caret. Editor not found: ${id}.`);
    return;
  }

  const parent = editor?.element?.$;
  if (!parent) {
    console.warn(`Could not set caret. Parent not found: ${id}.`);
    return;
  }

  const result = createRange(parent, position);
  const selection = window.getSelection();

  if (!('range' in result && selection)) {
    console.warn(`Could not set caret. Range or selection not found: ${id}, ${position}.`);
    return;
  }

  const { range } = result;
  range.collapse(false);

  // Timeout to makes sure CKEditor is stable before trying to load.
  // Helps on slow operations.
  setTimeout(() => {
    selection.removeAllRanges();
    selection.addRange(range);
  });
};

const setCkeditorCaretPosAtStart = ({ id }: { id: string }) => {
  const editor = get(window.CKEDITOR.instances, id);

  if (editor) {
    // Of course, this assumes that the editor is not already focused.
    // This is the safest way that we know at the moment to set the caret
    //  position at zero in IE.
    editor.focus();
  } else {
    console.warn(`Could not set caret. Editor not found: ${id}.`);
  }
};

export const setCkeditorCaretPos = ({ id, position }: { id: string; position: number }) => {
  if (position === 0) {
    setCkeditorCaretPosAtStart({ id });
  } else {
    setCkeditorCaretPosAtNonZeroPosition({ position, id });
  }
};
