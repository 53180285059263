import React, { MouseEventHandler, useState } from 'react';
import classNames from 'classnames';

import { NotificationAttrs } from '~/app/@types/state';
import { levelToClass, levelToDesc, NotificationLevel } from '~/app/constants';
import CloseSVG from '~/app/images/x.svg';
import '~/app/styles/Notification.scss';
import { track } from '~/app/analytics';
import { Button } from '~/app/reuse';

// WARNING: This file does not comply with react standards around the use of hooks. It should be rewritten.

type Props = NotificationAttrs & { close: () => void };

const Notification = ({
  type,
  buttonText,
  buttonFunc,
  close,
  content,
  level = NotificationLevel.Info,
  followingState,
  retainOnButtonClick = false,
  allowDismissal = false,
}: Props) => {
  const [mainContent, setMainContent] = useState(content);
  const [buttonAvailability, setButtonAvailability] = useState(true);

  let timeoutId: NodeJS.Timeout;
  const updateFromFrame = (currentFrame: number): void => {
    // ensure that only one timeout is active at any time
    clearTimeout(timeoutId);

    // fetch content that follows current frame and load after specified time
    const {
      millisDelay,
      content: newContent,
      buttonAvailable: newAvailability,
      isFinal,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } = followingState(currentFrame);
    timeoutId = setTimeout(() => {
      setMainContent(newContent);
      setButtonAvailability(newAvailability);
      if (!isFinal) {
        // repeat for next frame
        updateFromFrame(currentFrame + 1);
      }
    }, millisDelay);
  };

  // If notification is dynamic (has `followingState`),
  // make sure `updateFromFrame(0)` is executed at most once: in the beginning.
  // Also clear timeout if notification is removed
  if (followingState) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(
      () => {
        updateFromFrame(0);
        return () => clearTimeout(timeoutId);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
  }

  const onClose = () => {
    track.closedNotification({ name: levelToDesc[level] });
    close();
  };

  let button = null;
  if (buttonText) {
    const onClick: MouseEventHandler<HTMLButtonElement> = () => {
      if (!buttonAvailability) return;
      if (buttonFunc) buttonFunc();
      if (!retainOnButtonClick) close();
    };
    const classes = ['button'];
    if (!buttonAvailability) {
      classes.push('disabled');
    }
    button = (
      <div className="button-wrapper">
        <Button
          className={classes.join(' ')}
          color="tertiary"
          disabled={!buttonAvailability}
          onClick={onClick}
          size="small"
        >
          {buttonText}
        </Button>
      </div>
    );
  }

  const showClose = !!(!button || allowDismissal);

  const styleClasses = classNames('Notification', levelToClass[level], {
    'contains-button': button,
    'contains-two-buttons': button && showClose,
  });

  const typeAttribute = {
    [`data-notification-type-${type}`]: true,
  };

  return (
    <div className={styleClasses} data-cy-note-notification {...typeAttribute}>
      <div className="color-bar" />
      <div className="notification-content">{mainContent}</div>
      {button}
      {showClose && <CloseSVG className="close-x" onClick={onClose} />}
    </div>
  );
};

export default Notification;
