import { secretCopyOverride } from '~/app/flags';
import { copyHtmlToClipboard } from '~/app/utils';
import {
  boldTextTest,
  fontWeightBoldTest,
  blankLineTagTest,
  nestedHtmlTest,
  underlineAndItalicsTest,
  explicitStylesTest,
  fontTest,
} from './clipboardTestData';

interface CopyBoldToClipboardOptions {
  includeBoldTest?: boolean;
  includeBlankLineTest?: boolean;
  includeNestedTest?: boolean;
  includeUnderlineTest?: boolean;
  includeExplicitStylesTest?: boolean;
  includeFontTest?: boolean;
}

const allOptions = {
  includeBoldTest: true,
  includeBlankLineTest: true,
  includeNestedTest: true,
  includeUnderlineTest: true,
  includeExplicitStylesTest: true,
  includeFontTest: true,
};

const include = (text: string, flag?: boolean) => (flag ? text : '');

export const copyBoldToClipboard = (options: CopyBoldToClipboardOptions = allOptions) => {
  const defaultInnerHtml = [
    include(boldTextTest, options.includeBoldTest),
    include(fontWeightBoldTest, options.includeBoldTest),
    include(blankLineTagTest, options.includeBlankLineTest),
    include(nestedHtmlTest, options.includeNestedTest),
    include(underlineAndItalicsTest, options.includeUnderlineTest),
    include(explicitStylesTest, options.includeExplicitStylesTest),
    include(fontTest, options.includeFontTest),
  ].join('') as HtmlString;

  copyHtmlToClipboard(secretCopyOverride ?? defaultInnerHtml);
};
