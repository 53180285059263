import {
  CONDITION_TEXTAREA_LINE_HEIGHT,
  HEIGHT_OF_SPACE_BETWEEN_CONDITIONS,
  NOTE_LEFT_GUTTER,
} from '../const';
import { theme } from '../../../reuse';

const MARGIN_BOTTOM = 1;
const MARGIN_TOP = 1;
const HEIGHT = CONDITION_TEXTAREA_LINE_HEIGHT - MARGIN_BOTTOM - MARGIN_TOP;
const HORIZONTAL_PADDING = 8;

export const css = {
  height: HEIGHT_OF_SPACE_BETWEEN_CONDITIONS,
  userSelect: 'none',
  msUserSelect: 'none',
  '&:hover': {
    backgroundColor: theme.colors.lightBlue1,
    color: theme.colors.blueMedium,
    opacity: 1,
    '& button': {
      opacity: 2,
    },
  },
};

export const buttonCss = {
  border: 0,
  borderRadius: theme.other.borderRadius4,
  color: theme.colors.blueRegular,
  cursor: 'pointer',
  display: 'block',
  fontWeight: 600,
  height: HEIGHT,
  lineHeight: `${HEIGHT}px`,
  marginBottom: 1,
  marginLeft: NOTE_LEFT_GUTTER - HORIZONTAL_PADDING,
  marginTop: 1,
  opacity: 0,
  padding: `0 ${HORIZONTAL_PADDING}px`,
  userSelect: 'none',
  msUserSelect: 'none',
  textAlign: 'left',
  textDecoration: 'none',
  '&:focus': {
    backgroundColor: theme.colors.lightBlue2,
    color: theme.colors.blueMedium,
    opacity: 1,
  },
  '&:hover': {
    backgroundColor: theme.colors.lightBlue2,
    color: theme.colors.blueMedium,
    opacity: 1,
  },
  '&:active': {
    backgroundColor: theme.colors.lightBlue3,
    color: theme.colors.blueDark,
    opacity: 1,
  },
  '&:disabled': {
    backgroundColor: theme.colors.none,
    color: theme.colors.grey4,
  },
} as const;

export const iconStyle = {
  marginRight: 4,
  position: 'relative',
  top: 1,
} as const;

export const textStyle = {
  position: 'relative',
  top: -2,
  userSelect: 'none',
  msUserSelect: 'none',
} as const;
