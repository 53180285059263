import { theme } from '~/app/reuse';

export const style = {
  padding: '12px 16px 20px',
  width: 264,
} as const;

export const headerTextStyle = {
  color: theme.colors.blueRegular,
  fontSize: 16,
  fontWeight: 700,
  lineHeight: 1.25,
} as const;

export const closeButtonStyle = {
  position: 'absolute',
  right: 12,
  top: 12,
} as const;

export const formStyle = {
  marginTop: 14,
} as const;

export const buttonContainerStyle = {
  marginTop: 8,
  textAlign: 'right',
} as const;
