import _ from 'lodash';

import { IndexingData, Keyword } from '~/app/@types/state';
import { createTooltipText, unspecifiedRegardConditions } from './createTooltipText';

export const generateKeywords = ({
  conditionsByModule,
  conditionQualifiers,
  getTitleTextMatchForModule,
  modules,
  titleText,
}: Pick<IndexingData, 'conditionsByModule' | 'conditionQualifiers'> & {
  getTitleTextMatchForModule: (module: string) => string;
  modules: string[];
  titleText: string;
}): Keyword[] =>
  _.compact(
    modules.map((module) => {
      const qualifyObj = conditionQualifiers[module];
      const textMatch = getTitleTextMatchForModule(module);

      // specChecker keyword
      if (qualifyObj && textMatch) {
        const { suggestedQualifier } = conditionsByModule[module];
        const foundQualifiers: string[] = [];
        const regardTitleQualifiers: string[] = [];

        Object.entries(qualifyObj).forEach(([qualifierName, regex]) => {
          const qualifierMatch = titleText.match(regex);
          if (qualifierMatch) foundQualifiers.push(qualifierName);
        });
        if (suggestedQualifier)
          Object.entries(qualifyObj).forEach(([qualifierName, regex]) => {
            const qualifierMatch = suggestedQualifier.match(regex);
            if (qualifierMatch) regardTitleQualifiers.push(qualifierName);
          });

        const tooltipText = createTooltipText({
          module,
          suggestedQualifier,
          foundQualifiers,
          regardTitleQualifiers,
        });

        if (tooltipText) {
          // NOTE: Major side-effect alert!
          if (unspecifiedRegardConditions[module] === undefined)
            unspecifiedRegardConditions[module] = true;

          return {
            module,
            text: textMatch,
            tooltipText,
            type: 'specChecker',
          };
        }
      }

      // title keyword
      if (textMatch) {
        return {
          module,
          text: textMatch,
          type: 'title',
        };
      }

      return undefined;
    })
  );
