import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { memoize } from 'lodash';

import { AppState } from '../../store';
import { closeResources } from '../../actions/ui';
import { theme, zIndices } from '../../reuse';
import CloseSVG from '../../images/x.svg';

import '../../styles/ResourcesFlyout.scss';

import { MIN_APP_WIDTH, DESKTOP_GUTTER_WIDTH_VALUE } from '../meshed/useMeshedNoteAppStyle';
import { useMeshedContext } from '../meshed/MeshedProvider';

import { resourcesCopy } from './resourcesCopy';

const flyoutStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  color: theme.colors.grey1,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: zIndices.resourcesFlyout,
} as const;

const getFlyoutStyle = memoize(
  ({
    isVerticalLayout,
    isScaledHorizontalLayout,
    width,
  }: {
    isVerticalLayout: boolean;
    isScaledHorizontalLayout: boolean;
    width: number;
  }) => {
    const dxDetailsScaledWidth = (width - DESKTOP_GUTTER_WIDTH_VALUE) / 3;
    const flyoutWidth = isScaledHorizontalLayout ? `${dxDetailsScaledWidth}px` : MIN_APP_WIDTH;
    return {
      backgroundColor: 'white',
      float: 'right',
      height: '100%',
      minWidth: MIN_APP_WIDTH,
      width: isVerticalLayout ? 'calc(100% - 40px)' : flyoutWidth,
    } as const;
  }
);

const UnconnectedResourcesFlyout: FC<PropsFromRedux> = ({ closeResources, resourcesOpen }) => {
  const { isVerticalLayout, isScaledHorizontalLayout, width } = useMeshedContext(
    ({ isVerticalLayout, isScaledHorizontalLayout, width }) => ({
      isVerticalLayout,
      isScaledHorizontalLayout,
      width,
    })
  );
  const contentFlyoutStyle = getFlyoutStyle({ isVerticalLayout, isScaledHorizontalLayout, width });
  if (!resourcesOpen) {
    return null;
  }
  return (
    <div className="ResourcesFlyout" onClick={closeResources} style={flyoutStyle}>
      <div className="content" onClick={(e) => e.stopPropagation()} style={contentFlyoutStyle}>
        <h4>
          Help &amp; Resources
          <CloseSVG alt="Close resources flyout" onClick={closeResources} />
        </h4>
        <div className="scroll-wrapper">{resourcesCopy}</div>
      </div>
    </div>
  );
};

const connector = connect(({ ui: { resourcesOpen } }: AppState) => ({ resourcesOpen }), {
  closeResources,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

// eslint-disable-next-line import/prefer-default-export
export const ResourcesFlyout = connector(UnconnectedResourcesFlyout);
