import * as Sentry from '@sentry/browser';

import { RegardNoteError } from '~/app/errors';
import {
  LINE_DIVIDER_TAG,
  EMPTY_LINE_BR_TAG,
  EMPTY_LINE_BR,
  EMPTY_BR_HTML_LINE,
  STYLE_BOLD_TAG,
  STYLE_ITALIC_TAG,
  STYLE_UNDERLINE_TAG,
  BR_ELEMENT,
} from '~/app/constants';

import { htmlStringToPlainText, replace } from '..';

import { tagsAreEqual } from './characterItemTagsAreEqual';
import { CharacterItem, CharacterItemTagType, CharacterItemHtmlTag } from './types';

const TAG_FIRST_CHARACTER = '<';
const TAG_LAST_CHARACTER = '>';
const CLOSE_TAG_CHARACTER = '/';
const SPACE_CHARACTER = ' ';
const DEFAULT_SUPPORTED_TAGS = [
  LINE_DIVIDER_TAG,
  STYLE_BOLD_TAG,
  STYLE_ITALIC_TAG,
  STYLE_UNDERLINE_TAG,
];

// Tags that have inherent structural meaning and should not be merged
// aka, <b>meow</b><b>cat</b> === <b>meowcat</b> practically speaking, but
//      <div>meow</div><div>cat</div> !== <div>meowcat</div>
const STRUCTURAL_TAGS = [LINE_DIVIDER_TAG];

// Non structural tags share the same `0` index
// to allow duplicates to equal one another and be
// filtered out.
const NON_STRUCTURAL_TAG_INDEX = '0';

// Elements that are self closing and cannot contain data. These are treated
// as single "characters" in the parser so that they get passed through correctly
// when merged
// https://developer.mozilla.org/en-US/docs/Glossary/Void_element
const VOID_ELEMENTS = [EMPTY_LINE_BR_TAG];

const HTML_ENTITY_FIRST_CHARACTER = '&';
const SUPPORTED_HTML_ENTITIES = ['&nbsp;', '&gt;', '&lt;', '&amp;'];
const HTML_ENTITY_MAP: Record<string, string> = {
  '&nbsp;': ' ',
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
};

const ZERO_WIDTH_SPACE = String.fromCharCode(0x200b);
const STRIPPED_CHARACTERS = [ZERO_WIDTH_SPACE];

const getHtmlWithDefault = (html: HtmlString) => {
  if (!html) return EMPTY_BR_HTML_LINE;
  if (html === BR_ELEMENT) return EMPTY_BR_HTML_LINE;
  return html;
};

const throwMalformedHtmlError = (html: HtmlString) => {
  throw new Error(`Malformed html: "${html}"`);
};

const getEntityMatchAtIndex = (html: HtmlString, index: number) =>
  SUPPORTED_HTML_ENTITIES.find((entity) => html.indexOf(entity, index) === index);

const filterDuplicateTags = (tags: CharacterItemHtmlTag[]) => {
  let filteredTags: CharacterItemHtmlTag[] = [];
  const foundTags: Record<string, number> = {};

  tags.forEach((currentTag) => {
    const isStructuralTag = currentTag.id !== NON_STRUCTURAL_TAG_INDEX;
    const tagKey = currentTag.tag;
    const foundTag = tagKey in foundTags;

    if (foundTag && isStructuralTag) {
      // Swap the found tag out for the current tag. This helps condense
      // structural tags but keep lines separate and retain tag ordering.
      // ie. [div1, strong, div2] => [div2, strong]
      const tagIndex = foundTags[tagKey];
      filteredTags = replace(currentTag, tagIndex, filteredTags);
    } else if (foundTag) {
      // Do nothing since the non structural tag is already in the stack
    } else {
      filteredTags.push(currentTag);
      // Store the currentTag's new index in filteredTags for easier access
      foundTags[tagKey] = filteredTags.length - 1;
    }
  });

  return filteredTags;
};

const unsafeSplitHtmlIntoCharacterItems = (
  html: HtmlString,
  parseFirstCharacterOnly?: boolean,
  removeEmptyLines = false
) => {
  if (!html) return [];

  const currentTags: CharacterItemHtmlTag[] = [];
  const characterItems: CharacterItem[] = [];

  let index = 0;
  let tagIndex = 0;

  // Tracks if a text character has been added
  // to the current stack of tags. This lets us track if
  // an empty line should be added back in if all tags in the stack
  // have been closed without a text character being added.
  let hasAddedTextCharacterForStackOfTags = false;

  const htmlLen = html.length;

  const getTagIndex = (isClosingTag: boolean) => {
    if (isClosingTag) {
      // Reuse the same id as the last open tag, if available, so
      // that both tags will pass the equality check.
      const lastTag = currentTags[currentTags.length - 1];
      if (lastTag) {
        return lastTag.id;
      }
    }

    tagIndex += 1;
    return `${tagIndex}`;
  };

  const addTextCharacter = (nextCharacter: string) => {
    const characterIsAllowed = !STRIPPED_CHARACTERS.includes(nextCharacter);
    if (characterIsAllowed) {
      characterItems.push({
        character: nextCharacter,
        tags: filterDuplicateTags(currentTags),
      });
      hasAddedTextCharacterForStackOfTags = true;
    }
    index += 1;
  };

  const addHtmlTag = () => {
    const isClosingTag = html[index + 1] === CLOSE_TAG_CHARACTER;
    const nextSpace = html.indexOf(SPACE_CHARACTER, index);
    const nextClose = html.indexOf(TAG_LAST_CHARACTER, index);
    const nextOpen = html.indexOf(TAG_FIRST_CHARACTER, index + 1);

    const tagNameStop = Math.min(
      nextSpace === -1 ? Number.MAX_VALUE : nextSpace,
      nextClose === -1 ? Number.MAX_VALUE : nextClose
    );

    const tagName = html.substring(index + (isClosingTag ? 2 : 1), tagNameStop).toLowerCase();
    const isVoid = VOID_ELEMENTS.includes(tagName);
    const isSupported = DEFAULT_SUPPORTED_TAGS.includes(tagName);
    const isStructural = STRUCTURAL_TAGS.includes(tagName);
    const isNonTagLessThanSymbol = nextOpen < nextClose; // ie. "<15%"

    if (isVoid) {
      characterItems.push({
        character: `<${tagName}>`,
        tags: filterDuplicateTags(currentTags),
      });
      hasAddedTextCharacterForStackOfTags = true;
    } else if (isNonTagLessThanSymbol && !isClosingTag) {
      addTextCharacter('&lt;');
      return;
    } else if (isSupported && isClosingTag) {
      const nextTag = {
        type: CharacterItemTagType.TAG,
        tag: tagName,
        id: isStructural ? getTagIndex(isClosingTag) : NON_STRUCTURAL_TAG_INDEX,
      } as const;

      const poppedTag = currentTags.pop();
      if (!poppedTag || !tagsAreEqual(nextTag, poppedTag)) {
        throwMalformedHtmlError(html);
      }

      // if closing out the current stack of tags (ie. no current tags remain)
      // and no text character has been added.
      // We want to
      // if not `removeEmptyLines`: add in an empty line <br> to replace that
      //     whole stack so that it's not completely ignored.
      //     Ex: `<div><strong></strong></div>` -> `<div><br></div>
      // otherwise, clear the line
      if (currentTags.length === 0) {
        if (!hasAddedTextCharacterForStackOfTags && !removeEmptyLines) {
          characterItems.push({
            character: EMPTY_LINE_BR,
            tags: [
              {
                type: CharacterItemTagType.TAG,
                tag: LINE_DIVIDER_TAG,
                id: getTagIndex(false),
              },
            ],
          });
        }
        hasAddedTextCharacterForStackOfTags = false;
      }
    } else if (isSupported) {
      const nextTag = {
        type: CharacterItemTagType.TAG,
        tag: tagName,
        id: isStructural ? getTagIndex(isClosingTag) : NON_STRUCTURAL_TAG_INDEX,
      } as const;

      currentTags.push(nextTag);
    } else {
      // Ignore unsupported tags
    }

    const nextIndex = nextClose + 1;

    // If the next index is the same, an infinite loop has been hit
    // due to an improper html string
    if (index === nextIndex) {
      throwMalformedHtmlError(html);
    }

    index = nextIndex;
  };

  const addHtmlEntity = (nextCharacter: string) => {
    const entityMatch = getEntityMatchAtIndex(html, index);
    // Only map entities that are allowed
    // This allows non encoded '&'s to fall through and
    // act like any other text character
    if (entityMatch && HTML_ENTITY_MAP[entityMatch]) {
      characterItems.push({
        character: HTML_ENTITY_MAP[entityMatch],
        original: entityMatch,
        tags: filterDuplicateTags(currentTags),
      });
      hasAddedTextCharacterForStackOfTags = true;
      index += entityMatch.length;
    } else {
      addTextCharacter(nextCharacter);
    }
  };

  while (index < htmlLen && (!parseFirstCharacterOnly || characterItems.length < 1)) {
    const nextCharacter = html[index];
    if (nextCharacter === TAG_FIRST_CHARACTER) {
      // Case: Found an html tag
      addHtmlTag();
    } else if (nextCharacter === HTML_ENTITY_FIRST_CHARACTER) {
      // Case: Found an html entity (like &amp;)
      addHtmlEntity(nextCharacter);
    } else {
      // Case: Found text
      addTextCharacter(nextCharacter);
    }
  }

  return characterItems;
};

/**
 * Extracts the plain text from an html string and splits it into a
 * list of `CharacterItem`s.
 * Each item contains a single string character and the tags it's inside.
 * Note: Strips any attributes, unsupported tags, or unsafe characters.
 * Note: Void elements (ie. '<br>') treated as single characters and passed through
 * Note: Duplicate nested style tags are ignored (ie. `<strong><strong>text</strong></strong>)
 * Note: Duplicate nested structural tags are removed (ie. `<div><div>text</div></div>)
 *
 * @param html
 * @param parseFirstCharacterOnly
 * @param removeEmptyLines
 * @returns CharacterItem[]
 */
export const splitHtmlIntoCharacterItems = (
  html: HtmlString,
  parseFirstCharacterOnly = false,
  removeEmptyLines = false
) => {
  let htmlWithDefault = '' as HtmlString;

  try {
    htmlWithDefault = getHtmlWithDefault(html);
    return unsafeSplitHtmlIntoCharacterItems(
      htmlWithDefault,
      parseFirstCharacterOnly,
      removeEmptyLines
    );
  } catch (e) {
    // Attempt to split on the html string's plaintext
    let plainTextHtml = '' as HtmlString;

    try {
      plainTextHtml = `<div>${htmlStringToPlainText(htmlWithDefault)}</div>` as HtmlString;
      const splitPlainText = unsafeSplitHtmlIntoCharacterItems(
        plainTextHtml,
        parseFirstCharacterOnly,
        removeEmptyLines
      );

      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setExtra('html', html);
        scope.setExtra('plainTextHtml', plainTextHtml);
        Sentry.captureException(new RegardNoteError('Failed to split html into character items'));
      });

      return splitPlainText;
    } catch (e) {
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setExtra('html', html);
        scope.setExtra('plainTextHtml', plainTextHtml);
        Sentry.captureException(
          new RegardNoteError('Failed to split html and plaintext html into character items')
        );
      });

      return [];
    }
  }
};
