/* This file provides sentry helper functions that will automatically
 * add important state variables to the scope of the Sentry exception */
import { captureException, Scope, withScope } from '@sentry/browser';

import { EMPTY_LINE_NBSP } from '~/app/constants';
import { getDomHtml } from '~/app/utils';

const addObjectToScope = (scope: Scope, obj: Record<string, unknown>) =>
  Object.entries(obj).forEach(([key, value]) => scope.setExtra(key, value));

export const captureExceptionWithContext = (error: Error, otherScope?: Record<string, unknown>) => {
  withScope((scope) => {
    // -- DOM note --
    addObjectToScope(scope, {
      ...{
        domHtml: getDomHtml({
          emptyLine: EMPTY_LINE_NBSP,
          shouldRemoveRedundantTags: false,
        }),
      },
    });

    // -- whatever is provided by callee --
    if (otherScope) {
      addObjectToScope(scope, otherScope);
    }

    captureException(error);
  });
};
