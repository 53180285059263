import { ForwardedRef, forwardRef } from 'react';

import { AnchorTag, AnchorTagProps } from '../anchorTag';
import { ButtonTag, ButtonTagProps } from '../buttonTag';

export type ButtonBaseProps =
  | (AnchorTagProps & { component: 'a' })
  | (ButtonTagProps & { component?: 'button' });

export const ButtonBase = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonBaseProps>(
  (props, ref) =>
    'component' in props && props.component === 'a' ? (
      <AnchorTag ref={ref as ForwardedRef<HTMLAnchorElement>} {...props} />
    ) : (
      <ButtonTag ref={ref as ForwardedRef<HTMLButtonElement>} {...props} />
    )
);
ButtonBase.displayName = 'ButtonBase';
