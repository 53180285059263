import { SummaryOl, SummarySpanB } from './common';

export const heartFailureSummary = (
  <SummaryOl>
    <li>
      The algorithm checks the patient’s history for a past diagnosis of heart failure, and if
      present will label it as chronic. If any heart failure conditions indicate systolic or
      diastolic diagnoses those are captured.
    </li>
    <li>
      The algorithm will <SummarySpanB>diagnose based on an echocardiogram</SummarySpanB> (ECHO) in
      the last 1-2 years reviewing only the most recent and checking for a mention of
      diastolic/systolic left ventricular ejection fraction (LVEF).
    </li>
    <li>
      Patient’s notes from 1 day before admission are reviewed to check for acute symptoms. If
      positive indications of two or more acute symptoms are found, Regard will identify it as
      acute.
    </li>
    <li>
      The module reviews chest image reports, starting with the most recent image for one of the
      following symptoms: congestion; effusion; edema; or pulmonary vasculature.
    </li>
    <li>
      A baseline weight is calculated by gathering all previous weights, removing outliers, and
      computing the mean of the lower 20% of weights.
    </li>
  </SummaryOl>
);
