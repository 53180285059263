import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { openDxDetails, selectDxDetailsTab } from '~/app/actions/ui';
import { createSelector, useDeepEqualSelector } from '~/app/store';
import { get, isConditionNoteBlock, nth } from '~/app/utils';

type DxDetailsData = {
  lastClickedKeywordModule: string;
  openModules: string[];
  selectedModule: string;
  tabPanelOpen: boolean;
};

const dxDetailsSelector = createSelector(
  ({
    regardNote: { masterNoteBlocks, conditionsById },
    ui: {
      dxDetailsOpenConditionId,
      dxDetailsSelectedModule,
      tabPanelOpen,
      lastClickedKeywordModule,
    },
  }) => ({
    masterNoteBlocks,
    conditionsById,
    dxDetailsOpenConditionId,
    dxDetailsSelectedModule,
    lastClickedKeywordModule,
    tabPanelOpen,
  }),
  ({
    masterNoteBlocks,
    conditionsById,
    dxDetailsOpenConditionId,
    dxDetailsSelectedModule,
    lastClickedKeywordModule,
    tabPanelOpen,
  }) => {
    // Dynamically build the list of open modules. These will become the tabs
    //  in the Dx Details panel.
    const openModules = get(conditionsById, dxDetailsOpenConditionId)?.modules ?? [];

    // If the user deletes the selected module from the condition area, fall back to
    // 1. The first open module in the open condition.
    // 2. The first open module in the subsequent conditions.
    const openModuleIncludesSelectedModule = openModules.includes(dxDetailsSelectedModule);
    let nextSelectedModule = openModuleIncludesSelectedModule ? undefined : nth(openModules, 0);
    let nextSelectedConditionId = '';

    const shouldTryToSelectNextModule = !openModuleIncludesSelectedModule && !nextSelectedModule;
    if (shouldTryToSelectNextModule) {
      const currentIndex = masterNoteBlocks.findIndex(
        (block) => block.id === dxDetailsOpenConditionId
      );
      const blocksInSelectionOrder = masterNoteBlocks
        .slice(currentIndex + 1)
        .concat(masterNoteBlocks.slice(0, currentIndex));
      const nextBlockWithModule = blocksInSelectionOrder.find(
        (block) => isConditionNoteBlock(block) && !!block.modules.length
      );
      if (nextBlockWithModule && isConditionNoteBlock(nextBlockWithModule)) {
        nextSelectedConditionId = nextBlockWithModule.id;
        nextSelectedModule = nextBlockWithModule.modules[0];
      }
    }

    return {
      lastClickedKeywordModule,
      nextSelectedModule,
      nextSelectedConditionId,
      openModules,
      selectedModule: dxDetailsSelectedModule,
      tabPanelOpen,
    };
  }
);

// WARNING: Because of the useEffects in here, this hook is not to be
//  instantiated in more than one place.
export const useDxDetails = (): DxDetailsData => {
  const {
    lastClickedKeywordModule,
    nextSelectedModule,
    nextSelectedConditionId,
    openModules,
    selectedModule,
    tabPanelOpen,
  } = useDeepEqualSelector(dxDetailsSelector);

  const dispatch = useDispatch();

  // Handle case where current selected module no longer exists in open modules.
  // 1. Select next open module
  useEffect(() => {
    if (!nextSelectedModule) return;
    dispatch(selectDxDetailsTab({ selectedModule: nextSelectedModule }));
  }, [dispatch, nextSelectedModule]);

  // 2. Select next condition in the note
  useEffect(() => {
    if (!nextSelectedConditionId) return;
    dispatch(
      openDxDetails({
        conditionId: nextSelectedConditionId,
        selectedModule: nextSelectedModule ?? '',
        dxDetailsButtonClicked: false,
      })
    );
  }, [dispatch, nextSelectedConditionId, nextSelectedModule]);

  return {
    lastClickedKeywordModule,
    openModules,
    selectedModule,
    tabPanelOpen,
  };
};
