import { useEffect, useState } from 'react';

import { ANIMATION_DURATION } from '../conditionBackground/css';

// This hook helps to clean up a highlight after its animation completes.
export const useConditionHighlighted = (
  triggerHighlight: number | undefined
): {
  highlighted: boolean;
  highlightedKey: number;
} => {
  const [highlightTimeout, setHighlightTimeout] = useState<number>(0);

  useEffect(() => {
    if (triggerHighlight) {
      setHighlightTimeout((highlightTimeout) => {
        // Out with the old... (Clear any existing timeout.)
        window.clearTimeout(highlightTimeout);

        // And in with the new. (Create a new timeout and store the identifier.)
        return window.setTimeout(() => setHighlightTimeout(0), ANIMATION_DURATION);
      });
    }
  }, [setHighlightTimeout, triggerHighlight]);

  // This cleans up the first effect without creating the infinite loop that
  //  would occur if highlightTimeout was a dependency of the first effect.
  useEffect(() => () => window.clearTimeout(highlightTimeout), [highlightTimeout]);

  return {
    highlighted: !!highlightTimeout,
    highlightedKey: highlightTimeout,
  };
};
