import { memoize } from 'lodash';
import { FC } from 'react';

const accordionContentStyle = {
  padding: '0 24px 16px 24px',
} as const;
export const AccordionContent: FC = ({ children }) => (
  <div style={accordionContentStyle}>{children}</div>
);

const orderedListStyle = {
  fontWeight: 600,
  margin: '20px 0 0 -40px',
} as const;
export const OrderedList: FC = ({ children }) => <ol style={orderedListStyle}>{children}</ol>;

const getListItemStyle = memoize(
  (first?: boolean) =>
    ({
      listStylePosition: 'inside',
      marginTop: first ? 0 : 20,
    } as const)
);
export const ListItem: FC<{ first?: boolean }> = ({ first = false, children }) => (
  <li style={getListItemStyle(first)}>{children}</li>
);

const listItemContentStyle = {
  fontWeight: 'normal',
} as const;
export const ListItemContent: FC = ({ children }) => (
  <span style={listItemContentStyle}>{children}</span>
);

const resourceImageStyle = {
  display: 'block',
  width: '100%',
  marginTop: '12px',
} as const;
export const ResourceImage: FC<{ alt: string; src: string }> = ({ alt, src }) => (
  <img alt={alt} src={src} style={resourceImageStyle} />
);

const boldSpanStyle = {
  fontWeight: 600,
} as const;
export const BoldSpan: FC = ({ children }) => <span style={boldSpanStyle}>{children}</span>;

const paddedSpanStyle = {
  display: 'inline-block',
  marginTop: 12,
} as const;
export const PaddedSpan: FC = ({ children }) => <span style={paddedSpanStyle}>{children}</span>;

const accordionTableSpacerStyle = {
  marginTop: 12,
} as const;
export const AccordionTableSpacer: FC = ({ children }) => (
  <div style={accordionTableSpacerStyle}>{children}</div>
);
