import { SummaryP, SummarySpanB } from './common';

export const coronaryArteryDiseaseSummary = (
  <SummaryP first>
    Regard will search prior notes and conditions to determine if the patient has a history of{' '}
    <SummarySpanB>angina pectoris, coronary artery disease, or stable angina</SummarySpanB>. If none
    of these are present the diagnosis will return negative. If the algorithm does return a positive
    diagnosis, it will display procedures, imaging and associated medications.
  </SummaryP>
);
