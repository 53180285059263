import { useMemo } from 'react';

import { createSelector, useSelector } from '../../../store';

export const useDxDetailTable = (module: string) => {
  const dxDetailTableSelector = useMemo(
    () =>
      createSelector(
        (state) => state.regardNote.currentConditionResults,
        (currentConditionResults) => {
          const sources = currentConditionResults.find((item) => item.module === module);

          return {
            meds: sources?.meds ?? [],
            tableData: sources?.tableData ?? undefined,
          };
        }
      ),
    [module]
  );

  const { meds, tableData } = useSelector(dxDetailTableSelector);

  return {
    meds,
    tableData,
  };
};
