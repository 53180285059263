import { FC } from 'react';

import { ClassicDemo } from './ClassicDemo';
import { CernerDemo } from './cernerDemo';
import { EpicDemo } from './epicDemo';
import { epicTheme } from './epicDemo/epicTheme';
import { Fullscreen } from '../Fullscreen';
import { useDemoContext } from './DemoProvider';

export const DemoSwitcher: FC = () => {
  const { ehr, timestamp, toggleEhr } = useDemoContext(({ ehr, timestamp, toggleEhr }) => ({
    ehr,
    timestamp,
    toggleEhr,
  }));

  return (
    <div
      style={{
        height: 'calc(100% - 6em)',
        margin: '0 auto',
        maxHeight: '56em',
        maxWidth: '100em',
        padding: '3em 0',
        width: 'calc(100% - 6em)',
      }}
    >
      <div
        style={{
          border: `1px solid ${epicTheme.colors.darkBlue}`,
          height: '100%',
          position: 'relative',
        }}
      >
        <Fullscreen toggleEhr={toggleEhr}>
          {ehr === 'epic' && <EpicDemo />}
          {ehr === 'cerner' && <CernerDemo />}
          {ehr === 'classic' && <ClassicDemo timestamp={timestamp} />}
        </Fullscreen>
      </div>
    </div>
  );
};
