import _ from 'lodash';

import { formatDateLong } from '../../../utils';
import { SourceType } from '../../../@types';
import { APISupportingEvidence, APIMatchedEvidence, Source } from '../../../@types/state';
import { authorDefault } from '../../note/const';
import { DispatchOpenSource } from '../../../actions/ui';
import type {
  AccordionContentItem,
  AccordionTableSection,
} from '../../../reuse/accordionTable/types';

import {
  quoteSpanStyle,
  startQuoteStyle,
  endQuoteStyle,
  matchCellStyle,
  dateCellStyle,
  authorCellStyle,
} from './css';

const sourceTableColumns = [
  {
    label: 'match',
    accessor: 'match',
    cellStyle: matchCellStyle,
  },
  {
    label: 'date',
    accessor: 'date',
    cellStyle: dateCellStyle,
  },
  {
    label: 'author',
    accessor: 'author',
    cellStyle: authorCellStyle,
  },
];

const getMatchContent = (sourceItem: Source) => ({
  label:
    'match' in sourceItem && sourceItem.match ? (
      <span style={quoteSpanStyle}>
        <div style={startQuoteStyle}>“</div>
        {sourceItem.match}
        <div style={endQuoteStyle}>”</div>
      </span>
    ) : (
      sourceItem.kind
    ),
  action:
    sourceItem.type === SourceType.SourceCondition
      ? null
      : () => {
          sourceItem.open(sourceItem);
        },
});

const createAccordionSection = (sourceTableSection: { content: Source[]; header: string }) =>
  ({
    header: sourceTableSection.header,
    showColumnHeaders: false,
    collapsable: true,
    columns: sourceTableColumns,
    content: sourceTableSection.content.map((sourceItem) => ({
      match: getMatchContent(sourceItem),
      date: formatDateLong(sourceItem.date),
      author: sourceItem.author || authorDefault,
    })) as AccordionContentItem[],
  } as AccordionTableSection);

export const getSourceTableSectionsFromSources = ({
  open,
  sourceConditions,
  sourceNotes,
  supportingEvidence,
}: {
  open: DispatchOpenSource;
  sourceConditions: APIMatchedEvidence[];
  sourceNotes: APIMatchedEvidence[];
  supportingEvidence: APISupportingEvidence[];
}): AccordionTableSection[] =>
  _.compact<AccordionTableSection>([
    sourceNotes.length
      ? createAccordionSection({
          content: sourceNotes.map((source) => ({
            ...source,
            open,
            type: SourceType.SourceNote,
          })),
          header: 'Dx Source: Documents',
        })
      : undefined,
    sourceConditions.length
      ? createAccordionSection({
          content: sourceConditions.map((source) => ({
            ...source,
            type: SourceType.SourceCondition,
          })),
          header: 'Dx Source: Problem List',
        })
      : undefined,
    supportingEvidence.length
      ? createAccordionSection({
          content: supportingEvidence.map((source) => ({
            ...source,
            open,
            type: SourceType.SupportingEvidence,
          })),
          header: 'Supporting Evidence',
        })
      : undefined,
  ]);
