import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from './webStorage';

// The following two functions are meant to retain the users CYOB choice after refreshing
// by linking the default Basenote ID from the server to the basenote ID the user preferred
const userPreferredCyobId = 'cyob-user-preferred-base-note-id-after-refresh';
export const setCyobIdMapInLocalStorage = (chosenCyobId: string): void => {
  setLocalStorageItem(userPreferredCyobId, chosenCyobId);
};

export const getCyobIdMapInLocalStorage = (): string | null =>
  getLocalStorageItem(userPreferredCyobId);

export const removeCyobIdMapInLocalStorage = (): void =>
  removeLocalStorageItem(userPreferredCyobId);
