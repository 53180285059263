import { FC } from 'react';

import { EpicHeader } from './EpicHeader';
import { epicTheme } from './epicTheme';
import { DemoNote } from '../demoNote';
import { useDemoNotesRows } from '../demoData';
import { EpicCompactTableWithDescription } from './EpicCompactTableWithDescription';

const draftIndicatorStyle = { color: epicTheme.colors.accentMagenta };
const textareaStyle = {
  background: epicTheme.colors.lightGray,
  border: `1px solid ${epicTheme.colors.gray}`,
};

export const EpicTabNotesContent: FC = () => {
  const { rows, selectedItem } = useDemoNotesRows();

  const descriptionBody = (
    <DemoNote
      draftIndicatorStyle={draftIndicatorStyle}
      selectedItem={selectedItem}
      textareaStyle={textareaStyle}
    />
  );

  return (
    <>
      <EpicHeader>Notes</EpicHeader>
      <div
        style={{
          alignItems: 'start',
          display: 'flex',
          minHeight: 0,
        }}
      >
        <EpicCompactTableWithDescription descriptionBody={descriptionBody} rows={rows} />
      </div>
    </>
  );
};
