import { CharacterItemTag, CharacterItemTagType } from './types';

export const tagsAreEqual = (a: CharacterItemTag | undefined, b: CharacterItemTag | undefined) => {
  if (!a || !b) {
    return a === b;
  }

  if (a.type === CharacterItemTagType.TAG && b.type === CharacterItemTagType.TAG) {
    return a.tag === b.tag && a.id === b.id;
  }

  if (a.type === CharacterItemTagType.KEYWORD && b.type === CharacterItemTagType.KEYWORD) {
    return a.keyword === b.keyword;
  }

  // This case should not be hit unless a new type has been added without editing
  // this function, but just in case
  return a === b;
};
