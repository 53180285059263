import classNames from 'classnames';
import { format } from 'date-fns';
import { FC, useEffect, useState, createRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  CONDITIONS_PARAM,
  FORCE_FETCH_PARAM,
  PATIENT_PARAM,
  TIMESTAMP_PARAM,
} from '~/app/constants';
import { getBaseNotesAppUrl, isSalesDemoMode } from '../../flags';
import { LONG_DEMO_DAY_2 } from '../demo/const';
import { parseStringToDateOrNull } from '../../utils';
import '../../styles/QueryForm.scss';

const DEFAULT_DATE_STRING = isSalesDemoMode ? LONG_DEMO_DAY_2 : '2016-11-10T00:00:00.000-08:00';
const DEFAULT_PT_ID = isSalesDemoMode ? '123' : 'zaphod-beeblebrox';
const ENCODED_STRING = '[encoded]';

export const QueryForm: FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [conditions, setConditions] = useState(params.get(CONDITIONS_PARAM) || '');
  const [dateString, setDateString] = useState<string>(
    params.get(TIMESTAMP_PARAM) || DEFAULT_DATE_STRING
  );
  const [encodedPtId, setEncodedPtId] = useState(params.get(PATIENT_PARAM) || '');
  const [ptId, setPtId] = useState(params.get(PATIENT_PARAM) ? ENCODED_STRING : DEFAULT_PT_ID);

  if (conditions) params.set(CONDITIONS_PARAM, conditions);

  const date = parseStringToDateOrNull(dateString);
  if (date) params.set(TIMESTAMP_PARAM, format(date));

  if (encodedPtId) params.set(PATIENT_PARAM, encodedPtId);
  useEffect(() => {
    if (ptId === ENCODED_STRING) return;
    if (ptId.length < 3) return;

    const fn = async () => {
      // Clear the encoded patient ID to prevent form from submitting obsolete data.
      // This prevents an edge case where zaphod is acidentally submitted,
      //  particularly during cypress tests.
      setEncodedPtId('');
      const response = await fetch(`/api/encode/${ptId}`);

      if (response.ok) {
        try {
          setEncodedPtId(await response.text());
        } catch {
          console.error(`Error pulling text off patient ID response.`);
        }
      } else {
        console.error(
          `Error retrieving encoded patient ID: ${response.status} ${response.statusText}`
        );
      }
    };

    fn();
  }, [ptId]);

  const submitRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (isSalesDemoMode && submitRef.current) {
      submitRef.current.click();
    }
  }, [submitRef]);

  return (
    <form className="QueryForm" onSubmit={(evt) => evt.preventDefault()}>
      <div className="formContent">
        <div className="formItem">
          <label htmlFor="ptInput">
            Patient ID*
            <input
              defaultValue={ptId}
              id="ptInput"
              onChange={(e) => setPtId(e.target.value)}
              type="text"
            />
          </label>
        </div>
        <div className="formItem">
          <label htmlFor="conditionsInput">
            Diagnoses
            <input
              defaultValue={conditions}
              id="conditionsInput"
              onChange={(e) => setConditions(e.target.value)}
              placeholder="hyponatremia, acidosis"
              type="text"
            />
          </label>
        </div>
        <div className="formItem">
          <label htmlFor="timestampInput">
            <div>Up Until</div>
            <input
              id="timestampInput"
              onChange={(e) => {
                setDateString(e.target.value);
              }}
              type="text"
              value={dateString}
            />
          </label>
          <button
            className={classNames('button', 'secondary')}
            onClick={() => setDateString(new Date().toISOString())}
            type="button"
          >
            Now
          </button>
        </div>
        <div className="formItem">
          <label htmlFor="reviewToggle">
            <div>Review</div>
            <input
              checked={!!params.get('review')}
              id="reviewToggle"
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  params.set('review', 'true');
                } else {
                  params.delete('review');
                }
                window.location.href = `/query?${params.toString()}`;
              }}
              type="checkbox"
            />
          </label>
          <label htmlFor="fetchToggle">
            <div>Force fetch</div>
            <input
              checked={!!params.get(FORCE_FETCH_PARAM)}
              id="fetchToggle"
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  params.set(FORCE_FETCH_PARAM, 'true');
                } else {
                  params.delete(FORCE_FETCH_PARAM);
                }
                window.location.href = `/query?${params.toString()}`;
              }}
              type="checkbox"
            />
          </label>
        </div>
      </div>
      <div className="actions">
        <Link to={`/doc?${params.toString()}`}>
          <input
            ref={submitRef}
            className={classNames('button', 'primary', { disabled: !encodedPtId })}
            disabled={!encodedPtId}
            type="submit"
            value="Submit"
          />
        </Link>
        <a
          aria-label="submit for new notes app"
          href={`${getBaseNotesAppUrl()}?${params.toString()}`}
        >
          <span
            className={classNames('button', 'primary', { disabled: !encodedPtId })}
            style={{
              border: 'outset buttonborder',
            }}
          >
            Submit (New Notes App)
          </span>
        </a>
      </div>
    </form>
  );
};
