/**
 * Create empty data cells and sticky label cells for labs and vitals
 */
export const generateDataRows = (
  labsAndVitalsLabels: string[],
  sortedDatetimes: Date[],
  inCurrentEnc: (d: Date) => boolean
) => {
  const dataRows: Record<string, React.ReactNode[]> = {};
  labsAndVitalsLabels.forEach((label) => {
    const newDataRow = [
      <td key={`${label} sticky`} className="sticky to-highlight">
        <div className="c-wrap">{label}</div>
      </td>,
      <td key={`${label} sticky opaque`} className="sticky opaque">
        <div className="c-wrap">{label}</div>
      </td>,
      <td key={`${label} hide`} className="hide-me">
        <div className="c-wrap">{label}</div>
      </td>,
    ];
    sortedDatetimes.forEach((d, i) => {
      newDataRow.push(
        <td
          key={`${label} ${i}`}
          className={`to-highlight ${inCurrentEnc(d) ? 'current-enc' : 'previous-enc'}`}
        />
      );
    });

    dataRows[label] = newDataRow;
  });

  return dataRows;
};
