import { css } from '@emotion/react';
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CKEditorInstance } from 'ckeditor4-react';

import { ModKeys } from '~/app/@types/state';
import {
  copyHtmlToClipboard,
  copyTextToClipboard,
  isElementFullyVisibleInContainer,
  isElementPartiallyVisibleInContainer,
  createFullNote,
  getModKeysForEvent,
  setCyobIdMapInLocalStorage,
} from '~/app/utils';
import { track } from '~/app/analytics';
import { isMonitored } from '~/app/controllers/bulletNoteStatus';
import { AppState } from '~/app/store';
import { closeClearNoteDraftModalAction } from '~/app/actions/ui';
import {
  fetchPrevPropsAndRemeshWithNoDraft,
  undoOrRedoNoteBlocks,
  formatNoteTitles,
} from '~/app/actions/regardNote';
import { getCopyStats } from '~/app/analytics/noteMeshingStats';
import * as undoManager from '~/app/controllers/undoManager';
import { COMPANY_NAME, KEY_CODE_Y, KEY_CODE_Z, KEY_CODE_C } from '~/app/constants';
import {
  clipboardClearTimeout,
  getIsOneLinerEnabled,
  htmlConvertLinesToListOnCopy,
  htmlUseBrLineFormatOnCopy,
  htmlWrapWithFontOnCopy,
  isSalesDemoMode,
} from '~/app/flags';
import { persistCopiedNote } from '~/app/api';
import { theme, Button } from '~/app/reuse';

import ArrowDownSVG from '~/app/images/arrow-down.svg';
import FileCheckSVG from '~/app/images/file-check.svg';
import DashInCircleSVG from '~/app/images/dash-in-circle.svg';

import '~/app/styles/NoteModal.scss';
import '~/app/styles/UnreadDxUI.scss';
import '~/app/styles/ClearDraftModal.scss';

import '~/app/styles/CopyAnimation.scss';
import '~/app/styles/NoteContainer.scss';
import '~/app/styles/Tooltip.scss';

import { Note } from '../note';
import { NoteContainerScrollMarker } from './NoteContainerScrollMarker';
import { PhysicalExamContainer } from './PhysicalExamContainer';
import NotificationContainer from './NotificationContainer';
import { NoteReflowOnResize } from './NoteReflowOnResize';
import { NoteContainerHeader } from './noteContainerHeader';
import { DismissedList } from './DismissedList';
import { NoteToolbar, TOOLBAR_HEIGHT } from './noteToolbar';
import { useMeshedContext } from './MeshedProvider';
import { OneLiner } from './oneLiner/OneLiner';

declare global {
  interface Window {
    noteKeyDown: (arg0: KeyboardEvent) => void;
  }
}

const headerHeight = '56px';
const templateRows = `${headerHeight} ${TOOLBAR_HEIGHT} calc(100% - ${headerHeight} - ${TOOLBAR_HEIGHT})`;

const noteContainerCss = css`
  height: 100%;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: ${templateRows};
  grid-template-rows: ${templateRows};
  background: ${theme.colors.grey5};
`;

const noteEditorStyle = {
  background: `${theme.colors.white}`,
  gridRow: 3,
  msGridRow: 3,
  msGridColumnAlign: 'center',
  height: '100%',
  justifySelf: 'center',
  marginTop: 0,
  position: 'relative',
} as const;

const getNoteEditorStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...noteEditorStyle,
      borderLeft: isScaledHorizontalLayout ? `1px solid ${theme.colors.grey4}` : undefined,
      borderRight: isScaledHorizontalLayout ? `1px solid ${theme.colors.grey4}` : undefined,
      width: isScaledHorizontalLayout ? `720px` : '100%',
    } as const)
);

const getConditions = (): NodeListOf<HTMLElement> =>
  document.querySelectorAll('[data-note-block-id]');

const getNewConditions = (): NodeListOf<HTMLElement> =>
  document.querySelectorAll('[data-condition-is-new="true"]');

const getLastNewCondition = (): HTMLElement | undefined => _.last(getNewConditions());

// helper function to determine if the user has scrolled down enough
// in NoteEditor to reveal all the "new" conditions
const hasScrolledToLastNewDx = (container: HTMLElement) => {
  const lastNewCondition = getLastNewCondition();

  return lastNewCondition
    ? // check
      isElementPartiallyVisibleInContainer(lastNewCondition, container, 32)
    : // we don't need to check--there are no new conditions!
      true;
};

const noModKeys: ModKeys = {
  shift: false,
  ctrl: false,
  alt: false,
};

interface State {
  copied: boolean;
  clipboardCleared: boolean;
  copyError: boolean;
  hasUnreadDxs: boolean;
  includePhysicalExam: boolean;
  unreadModalIsOpen: boolean;
  modKeys: ModKeys;
}

type Props = PropsFromRedux & { timestamp: ISODateString };

class UnconnectedNoteContainer extends React.Component<Props, State> {
  private scrollRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      copied: false,
      clipboardCleared: false,
      copyError: false,
      hasUnreadDxs: true,
      includePhysicalExam: true,
      unreadModalIsOpen: false,
      modKeys: noModKeys,
    };
    this.onCopy = this.onCopy.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onResize = this.onResize.bind(this);
    this.checkUnreadDxScroll = _.throttle(this.checkUnreadDxScroll, 200);
    this.redo = this.redo.bind(this);
    this.undo = this.undo.bind(this);
    this.formatTitleWithHash = this.formatTitleWithHash.bind(this);
    this.formatTitleWithNumber = this.formatTitleWithNumber.bind(this);
    this.applyBold = this.applyBold.bind(this);
    this.applyItalic = this.applyItalic.bind(this);
    this.applyUnderline = this.applyUnderline.bind(this);
    this.scrollToNextNewDx = this.scrollToNextNewDx.bind(this);
    this.flipIncluded = this.flipIncluded.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('resize', this.onResize);

    this.checkUnreadDxScroll();
  }

  componentDidUpdate(
    prevProps: Props,
    prevState: State,
    snapshot: { scrollRatio: number; scrollTop: number }
  ) {
    const { bulletNoteStatusByBulletId } = this.props;

    if (bulletNoteStatusByBulletId !== prevProps.bulletNoteStatusByBulletId) {
      // When the reducer changes the index (user clicks a bullet) we perform
      // the following set to ensure the user's scroll doesn't jump
      if (this.scrollRef.current) this.scrollRef.current.scrollTop = snapshot.scrollTop;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getSnapshotBeforeUpdate() {
    const scrollDiv = this.scrollRef.current;
    const viewableHeight = scrollDiv ? scrollDiv.getBoundingClientRect().height : 0;
    return {
      scrollRatio: scrollDiv ? scrollDiv.scrollTop / (scrollDiv.scrollHeight - viewableHeight) : 0,
      scrollTop: scrollDiv ? scrollDiv.scrollTop : 0,
    };
  }

  async onCopy(modKeys: ModKeys): Promise<void> {
    const { bulletsByModule, baseNoteData, bulletNoteStatusByBulletId, encounterId } = this.props;
    const { includePhysicalExam } = this.state;
    const {
      regardNote: { conditionsById },
    } = window.store.getState();

    const usePlaintext = !!modKeys.shift;

    const editorText = createFullNote({
      usePlaintext,
      includePhysicalExam,
      convertLinesToListOnCopy: htmlConvertLinesToListOnCopy,
      useBrLineFormat: htmlUseBrLineFormatOnCopy,
      wrapWithFontTag: htmlWrapWithFontOnCopy,
    });

    let copyResult;
    if (usePlaintext) {
      copyResult = await copyTextToClipboard(editorText);
    } else {
      copyResult = await copyHtmlToClipboard(editorText as HtmlString);
    }

    if (copyResult.error !== null) {
      this.setState({ copyError: true });
      setTimeout(() => this.setState({ copyError: false }), 2000);
      return;
    }

    if (_.isNumber(clipboardClearTimeout) && clipboardClearTimeout > 0) {
      setTimeout(() => copyTextToClipboard(' '), clipboardClearTimeout);
    }

    const copiedConditions = _.flatten(Object.values(conditionsById).map(({ modules }) => modules));

    track.ignoredBullet({
      bullets: _.flatten(
        Object.entries(bulletsByModule).map(([module, bullets]) =>
          bullets
            .filter(
              (bullet) =>
                copiedConditions.includes(module) &&
                isMonitored(`${module}.${bullet.id}`, bulletNoteStatusByBulletId)
            )
            .map(({ id }) => ({
              module,
              bulletId: id,
            }))
        )
      ),
    });

    track.noteCopied(
      getCopyStats(copiedConditions),
      includePhysicalExam,
      modKeys,
      copyResult.copyMethod
    );

    persistCopiedNote(baseNoteData.resourceId, encounterId, baseNoteData.contentHash, editorText);

    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 1000);
  }

  onCopyClick(e: React.MouseEvent<HTMLButtonElement>): void {
    const { hasUnreadDxs } = this.state;

    const modKeys = getModKeysForEvent(e);

    if (hasUnreadDxs) {
      this.setState({ unreadModalIsOpen: true, modKeys });
    } else {
      this.onCopy(modKeys);
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && (event.which || event.keyCode) === KEY_CODE_C) {
      // Single space is necessary to clear clipboard in IE11
      copyTextToClipboard(' ');
      this.setState({ clipboardCleared: true });
      setTimeout(() => this.setState({ clipboardCleared: false }), 1000);
    }
  }

  onResize(): void {
    this.checkUnreadDxScroll();
  }

  getLastFocusedEditor() {
    // Another option (if the current implementation proves unreliable) is to
    //  get the focused editor from the DOM:
    // Object.values(CKEDITOR.instances).find(({ focusManager }) => focusManager.hasFocus)

    const { lastFocusedEditor } = this.props;
    const editorInstances = window.CKEDITOR.instances;
    const instance = editorInstances[lastFocusedEditor] as CKEditorInstance;
    return instance;
  }

  noteKeyDown = (e: KeyboardEvent): void => {
    // metaKey is not currently supported in IE11
    // but it's helpful when testing on a mac to allow
    // cmd shortcut keys
    const ctrlHeld = e.ctrlKey || e.metaKey;
    if (ctrlHeld) {
      const keyCode = e.which || e.keyCode;
      if (keyCode === KEY_CODE_Z) {
        if (e.shiftKey) {
          this.redo();
        } else {
          this.undo();
        }
        e.preventDefault();
        e.stopPropagation();
      } else if (keyCode === KEY_CODE_Y) {
        this.redo();
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  checkUnreadDxScroll() {
    const container = this.scrollRef.current;
    if (container && hasScrolledToLastNewDx(container)) this.setState({ hasUnreadDxs: false });
  }

  scrollToNextNewDx() {
    this.setState({ copied: false });

    // We start our search by finding the index of the last visible condition
    //  in the scroll container.
    const conditions = getConditions();
    const container = this.scrollRef.current;

    const lastFullyVisibleConditionIndex = container
      ? _.findLastIndex(conditions, (condition) =>
          isElementFullyVisibleInContainer(condition, container)
        )
      : -1;
    const lastPartiallyVisibleConditionIndex = container
      ? _.findLastIndex(conditions, (condition) =>
          isElementPartiallyVisibleInContainer(condition, container, 32)
        )
      : -1;
    const lastVisibleConditionIndex =
      lastFullyVisibleConditionIndex >= 0 &&
      lastPartiallyVisibleConditionIndex > lastFullyVisibleConditionIndex
        ? lastFullyVisibleConditionIndex
        : lastPartiallyVisibleConditionIndex;

    // From that point, we find the next new condition.
    const nextNewCondition = _.find(
      conditions,
      (condition, i) =>
        i > lastVisibleConditionIndex && condition.getAttribute('data-condition-is-new') === 'true'
    );

    if (nextNewCondition) nextNewCondition.scrollIntoView({ behavior: 'smooth' });
  }

  /* Class undo manager wrapper. By default it will perform a "redo"
   * action. But if any truthy value is passed as the `undo` arg, this
   * function will first push a new undo state to the manager, so the
   * user could redo this undo, then performs an undo. */
  redo() {
    const { undoOrRedoNoteBlocks } = this.props;

    const newState = undoManager.popRedo()?.state;
    if (newState) undoOrRedoNoteBlocks(newState);

    track.redo();
  }

  undo() {
    const { undoOrRedoNoteBlocks } = this.props;

    const newState = undoManager.popUndo()?.state;
    if (newState) undoOrRedoNoteBlocks(newState);

    track.undo();
  }

  formatTitleWithHash() {
    const { formatNoteTitles } = this.props;
    formatNoteTitles({ format: 'hash' });
    track.formatNote({ format: 'hash' });
  }

  formatTitleWithNumber() {
    const { formatNoteTitles } = this.props;
    formatNoteTitles({ format: 'number' });
    track.formatNote({ format: 'number' });
  }

  applyBold() {
    const instance = this.getLastFocusedEditor();
    if (instance) {
      instance.execCommand('bold');
      track.bold();
    }
  }

  applyItalic() {
    const instance = this.getLastFocusedEditor();
    if (instance) {
      instance.execCommand('italic');
      track.italic();
    }
  }

  applyUnderline() {
    const instance = this.getLastFocusedEditor();
    if (instance) {
      instance.execCommand('underline');
      track.underline();
    }
  }

  flipIncluded() {
    this.setState(({ includePhysicalExam }) => ({
      includePhysicalExam: !includePhysicalExam,
    }));
  }

  render(): JSX.Element {
    const {
      baseNoteData,
      physicalExamKey,
      timestamp,
      clearNoteDraftModalIsOpen,
      closeClearNoteDraftModalAction,
      fetchPrevPropsAndRemeshWithNoDraft,
      isScaledHorizontalLayout,
    } = this.props;
    const {
      copied,
      clipboardCleared,
      copyError,
      hasUnreadDxs,
      includePhysicalExam,
      unreadModalIsOpen,
      modKeys,
    } = this.state;

    // This code disables the "backspace" key from going "back" in the browser
    if (typeof window.noteKeyDown !== 'function') {
      window.noteKeyDown = this.noteKeyDown.bind(this);
      document.addEventListener('keydown', window.noteKeyDown, false);
    }

    return (
      <div className="NoteContainer" css={noteContainerCss}>
        {/* 
          // TODO: RND-1407 - Turn back on appcues
          <TrackNoteReady />
        */}
        <NoteReflowOnResize />
        <NoteContainerHeader
          copied={copied}
          onCopyClick={this.onCopyClick}
          timestamp={timestamp}
          unreadModalIsOpen={unreadModalIsOpen}
        />
        <NoteToolbar
          copied={copied}
          onBeforeRefresh={() =>
            // Set the current basenote ID in localStorage so when user loads after refresh
            // we can return them to the basenote they were editing
            setCyobIdMapInLocalStorage(baseNoteData.resourceId)
          }
          onBoldClick={this.applyBold}
          onHashTitleClick={this.formatTitleWithHash}
          onItalicClick={this.applyItalic}
          onNumberTitleClick={this.formatTitleWithNumber}
          onRedoClick={this.redo}
          onUnderlineClick={this.applyUnderline}
          onUndoClick={this.undo}
          unreadModalIsOpen={unreadModalIsOpen}
        />
        <div className="editor" style={getNoteEditorStyle(isScaledHorizontalLayout)}>
          {(clipboardCleared || copied) && (
            <div className="note-modal copied-animation">
              <div>
                <FileCheckSVG />
                {clipboardCleared ? 'Clipboard cleared' : 'Copied to clipboard'}
              </div>
            </div>
          )}
          {copyError && (
            <div className="note-modal copied-animation">
              <div>
                <DashInCircleSVG />
                Error copying to clipboard
              </div>
            </div>
          )}
          {unreadModalIsOpen && (
            <div className="note-modal unread-dx">
              <div>
                <div>
                  All new diagnoses added by {COMPANY_NAME}
                  <br />
                  have not been viewed yet.
                </div>
                <span
                  className="button primary"
                  onClick={() => {
                    track.clickedCopyThenClickedViewNewDxs();
                    this.setState({ unreadModalIsOpen: false, modKeys: noModKeys });
                    this.scrollToNextNewDx();
                  }}
                >
                  View New Dxs
                </span>
                <span
                  className="button tertiary"
                  onClick={() => {
                    track.clickedCopyCopiedWithoutViewingAllNewDxs();
                    this.setState({
                      hasUnreadDxs: false,
                      unreadModalIsOpen: false,
                      modKeys: noModKeys,
                    });
                    this.onCopy(modKeys);
                  }}
                >
                  Copy Note Without Viewing
                </span>
              </div>
            </div>
          )}
          {clearNoteDraftModalIsOpen && (
            <div className="note-modal clear-draft">
              <div>
                <div className="modal-content">
                  Updating the base note will remove any edits made in the current draft.
                  <br />
                  <strong>This action cannot be reversed.</strong>
                </div>
                <div className="modal-actions">
                  <Button
                    className="button"
                    color="primary"
                    data-cy-update-base-note-modal-update-button
                    onClick={() => {
                      track.clickedClearNoteDraft();
                      closeClearNoteDraftModalAction();
                      fetchPrevPropsAndRemeshWithNoDraft(baseNoteData);
                    }}
                    size="standard"
                  >
                    Update Base Note
                  </Button>
                  <Button
                    className="button"
                    color="tertiary"
                    data-cy-update-base-note-modal-keep-button
                    onClick={() => closeClearNoteDraftModalAction()}
                    size="standard"
                  >
                    Keep Current Base Note
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div
            ref={this.scrollRef}
            className="scroll-wrapper"
            data-cy-note-scroll-wrapper
            data-note-container-scroll-wrapper
            onScroll={() => {
              if (hasUnreadDxs) this.checkUnreadDxScroll();
            }}
          >
            {!isSalesDemoMode && baseNoteData.physicalExamText && (
              <PhysicalExamContainer
                author={baseNoteData.author}
                flipIncluded={this.flipIncluded}
                included={includePhysicalExam}
                physicalExamKey={physicalExamKey}
                physicalExamText={baseNoteData.physicalExamText}
                signedTimestamp={baseNoteData.effectiveTimestamp}
              />
            )}
            <NotificationContainer />
            {getIsOneLinerEnabled() && <OneLiner />}
            <Note />
            <DismissedList />
            <NoteContainerScrollMarker />
          </div>
          {hasUnreadDxs && (
            <div
              className="meshed-note-tutorial-step-4"
              data-cy-unread-dx-button
              id="unread-dx-button"
              onClick={() => this.scrollToNextNewDx()}
            >
              <ArrowDownSVG />
              New Diagnoses
            </div>
          )}
        </div>
      </div>
    );
  }
}

const connector = connect(
  ({
    regardNote: {
      baseNoteData,
      bulletsByModule,
      bulletNoteStatusByBulletId,
      physicalExamKey,
      pt,
      encounterId,
    },
    ui: { lastFocusedEditor, clearNoteDraftModalIsOpen, isScaledHorizontalLayout },
  }: AppState) => ({
    baseNoteData,
    bulletsByModule,
    bulletNoteStatusByBulletId,
    physicalExamKey,
    pt,
    encounterId,
    lastFocusedEditor,
    clearNoteDraftModalIsOpen,
    isScaledHorizontalLayout,
  }),
  {
    undoOrRedoNoteBlocks,
    formatNoteTitles,
    closeClearNoteDraftModalAction,
    fetchPrevPropsAndRemeshWithNoDraft,
  }
);

const WrappedNoteContainer = (props: Props) => {
  const { isScaledHorizontalLayout } = useMeshedContext(({ isScaledHorizontalLayout }) => ({
    isScaledHorizontalLayout,
  }));
  return (
    <UnconnectedNoteContainer {...props} isScaledHorizontalLayout={isScaledHorizontalLayout} />
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;
export const NoteContainer = connector(WrappedNoteContainer);
