import { DiagnosisType, ConditionDevelopmentState } from '~/app/@types';
import type { Diagnosis } from '~/app/@types/state';

import { acidBaseDisorderSummary } from './diagnosisSummaries/acidBaseDisorder';
import { acuteCoronarySyndromeSummary } from './diagnosisSummaries/acuteCoronarySyndrome';
import { acuteKidneyInjurySummary } from './diagnosisSummaries/acuteKidneyInjury';
import { alcoholUseDisorderSummary } from './diagnosisSummaries/alcoholUseDisorder';
import { encephalopathySummary } from './diagnosisSummaries/encephalopathy';
import { anemiaSummary } from './diagnosisSummaries/anemia';
import { atrialFibSummary } from './diagnosisSummaries/atrialFib';
import { benignProstateHyperplasiaSummary } from './diagnosisSummaries/benignProstateHyperplasia';
import { cancerSummary } from './diagnosisSummaries/cancer';
import { calciumSummary } from './diagnosisSummaries/calcium';
import { chronicKidneyDiseaseSummary } from './diagnosisSummaries/chronicKidneyDisease';
import { coronaryArteryDiseaseSummary } from './diagnosisSummaries/coronaryArteryDisease';
import { covidSummary } from './diagnosisSummaries/covid';
import { cellulitisSummary } from './diagnosisSummaries/cellulitis';
import { dementiaSummary } from './diagnosisSummaries/dementia';
import { depressionAndAnxietySummary } from './diagnosisSummaries/depressionAndAnxiety';
import { diabetesSummary } from './diagnosisSummaries/diabetes';
import { endocarditisSummary } from './diagnosisSummaries/endocarditis';
import { gerdSummary } from './diagnosisSummaries/gerd';
import { giBleedSummary } from './diagnosisSummaries/giBleed';
import { goutSummary } from './diagnosisSummaries/gout';
import { heartFailureSummary } from './diagnosisSummaries/heartFailure';
import { hyperkalemiaSummary } from './diagnosisSummaries/hyperkalemia';
import { hyperlipidemiaSummary } from './diagnosisSummaries/hyperlipidemia';
import { hypermagnesemiaSummary } from './diagnosisSummaries/hypermagnesemia';
import { hypernatremiaSummary } from './diagnosisSummaries/hypernatremia';
import { hypertensionSummary } from './diagnosisSummaries/hypertension';
import { hypokalemiaSummary } from './diagnosisSummaries/hypokalemia';
import { hypomagnesemiaSummary } from './diagnosisSummaries/hypomagnesemia';
import { hyponatremiaSummary } from './diagnosisSummaries/hyponatremia';
import { malnutritionSummary } from './diagnosisSummaries/malnutrition';
import { neutropeniaSummary } from './diagnosisSummaries/neutropenia';
import { obesitySummary } from './diagnosisSummaries/obesity';
import { obstructiveLungSummary } from './diagnosisSummaries/obstructiveLung';
import { pancytopeniaSummary } from './diagnosisSummaries/pancytopenia';
import { phosphateSummary } from './diagnosisSummaries/phosphate';
import { pneumoniaSummary } from './diagnosisSummaries/pneumonia';
import { pressureUlcerSummary } from './diagnosisSummaries/pressureUlcer';
import { pulmonaryEmbolismSummary } from './diagnosisSummaries/pulmonaryEmbolism';
import { pulmonaryHypertensionSummary } from './diagnosisSummaries/pulmonaryHypertension';
import { respiratoryFailureSummary } from './diagnosisSummaries/respiratoryFailure';
import { sepsisSummary } from './diagnosisSummaries/sepsis';
import { strokeSummary } from './diagnosisSummaries/stroke';
import { thromboSummary } from './diagnosisSummaries/thrombo';
import { thyroidSummary } from './diagnosisSummaries/thyroid';
import { getGenericLiveDxSummary } from './diagnosisSummaries/generic';

export const diagnoses: Diagnosis[] = [
  {
    title: 'Acid Base Disorder',
    type: DiagnosisType.CoreDx,
    summary: acidBaseDisorderSummary,
    conditionKeys: ['acid_base'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Acute Coronary Syndrome (ACS)',
    type: DiagnosisType.SupplementalDx,
    summary: acuteCoronarySyndromeSummary,
    conditionKeys: [
      'acid_base_disorder',
      'acidosis',
      'acs',
      'acute_mi_unspecified',
      'alkalosis',
      'angina_pectoris_other',
      'angina_pectoris_unspecified',
      'angina_pectoris_with_documented_spasm',
      'anion_gap_metabolic_acidosis',
      'metabolic_acidosis',
      'metabolic_alkalosis',
      'normal_anion_gap_metabolic_acidosis',
      'nstemi',
      'respiratory_acidosis',
      'respiratory_alkalosis',
      'stemi_anterior_wall',
      'stemi_inferior_wall',
      'stemi_other',
      'stemi_unspecified',
      'troponin_elevation',
      'type_ii_mi',
      'uncategorized_acs',
      'unspecified_acute_ischemic_heart_disease',
      'unspecified_chest_pain',
      'unstable_angina',
    ],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Acute Kidney Injury (AKI)',
    type: DiagnosisType.CoreDx,
    summary: acuteKidneyInjurySummary,
    conditionKeys: ['kidney_disease'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Alcohol Use Disorder',
    type: DiagnosisType.CoreDx,
    summary: alcoholUseDisorderSummary,
    conditionKeys: ['alcohol'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Anemia',
    type: DiagnosisType.CoreDx,
    summary: anemiaSummary,
    conditionKeys: ['anemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Appendicitis',
    type: DiagnosisType.LiveDx,
    summary: getGenericLiveDxSummary('appendicitis'),
    conditionKeys: ['appendicitis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Atrial Fibrillation (A-fib)',
    type: DiagnosisType.SupplementalDx,
    summary: atrialFibSummary,
    conditionKeys: ['afib'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Benign Prostate Hyperplasia (BPH)',
    type: DiagnosisType.SupplementalDx,
    summary: benignProstateHyperplasiaSummary,
    conditionKeys: ['bph'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Calcium',
    type: DiagnosisType.CoreDx,
    summary: calciumSummary,
    conditionKeys: ['hypercalcemia', 'hypocalcemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Cancer',
    type: DiagnosisType.SupplementalDx,
    summary: cancerSummary,
    conditionKeys: [
      'acute_lymphoblastic_leukemia',
      'acute_myeloid_leukemia',
      'adenocarcinoma_of_the_lung',
      'adenocarcinoma_of_the_pancreas',
      'ampullary_adenocarcinoma',
      'amyloidosis',
      'anal_cancer',
      'anal_squamous_cell_carcinoma',
      'anaplastic_large_cell_lymphoma',
      'anaplastic_thyroid_carcinoma',
      'angioimmunoblastic_t_cell_lymphoma',
      'appendiceal_cancer',
      'appendiceal_neuroendocrine_tumor',
      'astrocytoma',
      'b_cell_lymphoma',
      'basal_cell_carcinoma',
      'bladder_cancer',
      'breast_cancer',
      'burkitt_lymphoma',
      'cancer',
      'carcinoid_tumor',
      'case_sensitive_gastrointestinal_stromal_tumor',
      'case_sensitive_neuroendocrine_tumor',
      'castleman_disease',
      'cervical_cancer',
      'cholangiocarcinoma',
      'chondrosarcoma',
      'chordoma',
      'choriocarcinoma',
      'chronic_lymphocytic_leukemia',
      'chronic_myeloid_leukemia',
      'classical_hodgkin_lymphoma',
      'clear_cell_carcinoma_of_the_ovary',
      'colon_cancer',
      'colorectal_adenocarcinoma',
      'cutaneous_melanoma',
      'diffuse_large_b_cell_lymphoma',
      'ductal_carcinoma_in_situ',
      'embryonal_carcinoma',
      'endodermal_sinus_tumor',
      'endometrial_cancer',
      'epithelial_ovarian_cancer',
      'erdheim_chester',
      'esophageal_adenocarcinoma',
      'esophageal_and_esophagogastric_junction_cancer',
      'ewing_sarcoma',
      'extrahepatic_cholangiocarcinoma',
      'extranodal_marginal_zone_lymphoma',
      'follicular_lymphoma',
      'follicular_thyroid_carcinoma',
      'gallbladder_cancer',
      'gastric_adenocarcinoma',
      'gastric_cancer',
      'gastric_neuroendocrine_tumor',
      'gastrinoma',
      'gastrointestinal_stromal_tumor',
      'giant_cell_tumor_of_the_bone',
      'glioblastoma',
      'glioma',
      'glucagonoma',
      'hairy_cell_leukemia',
      'head_and_neck_cancer',
      'hepatocellular_carcinoma',
      'her2_positive_breast_cancer',
      'high_grade_b_cell_lymphoma',
      'hodgkin_lymphoma',
      'hormone_receptor_positive_breast_cancer',
      'hypopharyngeal_cancer',
      'insulinoma',
      'intrahepatic_cholangiocarcinoma',
      'invasive_ductal_carcinoma',
      'invasive_lobular_carcinoma',
      'invasive_papillary_carcinoma',
      'kaposi_sarcoma',
      'kidney_cancer',
      'langerhans_cell_histiocytosis',
      'large_cell_lung_cancer',
      'laryngeal_cancer',
      'lung_cancer',
      'lung_neuroendocrine_tumor',
      'lymphoma',
      'malignant_melanoma',
      'mantle_cell_lymphoma',
      'marginal_zone_lymphoma',
      'medullary_thyroid_carcinoma',
      'medulloblastoma',
      'mesothelioma',
      'microinvasive_carcinoma',
      'mucinous_carcinoma_of_the_ovary',
      'mucinous_colorectal_adenocarcinoma',
      'multiple_myeloma',
      'muscle_invasive_bladder_cancer',
      'mycosis_fungoides',
      'myelodysplastic_syndrome',
      'nasopharyngeal_cancer',
      'neuroendocrine_tumor',
      'nk_t_cell_lymphoma',
      'nodal_marginal_zone_lymphoma',
      'nodular_lymphocyte_predominant_hodgkin_lymphoma',
      'non_hodgkin_lymphoma',
      'non_muscle_invasive_bladder_cancer',
      'non_small_cell_lung_cancer',
      'nonseminoma',
      'oligodendroglioma',
      'oncocytic_thyroid_carcinoma',
      'oropharyngeal_cancer',
      'osteosarcoma',
      'ovarian_cancer',
      'ovarian_carcinosarcoma',
      'ovarian_serous_carcinoma',
      'pancreatic_cancer',
      'pancreatic_neuroendocrine_tumor',
      'papillary_thyroid_carcinoma',
      'penile_cancer',
      'penile_carcinoma',
      'penile_squamous_cell_carcinoma',
      'peripheral_t_cell_lymphoma',
      'peritoneal_mesothelioma',
      'pheochromocytoma',
      'plasma_cell_leukemia',
      'plasmacytoma',
      'pleural_mesothelioma',
      'post_transplant_lymphoproliferative_disorder',
      'ppoma',
      'primary_cns_lymphoma',
      'primary_cutaneous_t_cell_lymphoma',
      'primitive_neuroectodermal_tumors',
      'prostate_adenocarcinoma',
      'prostate_cancer',
      'prostate_ductal_carcinoma',
      'prostate_small_cell_carcinoma',
      'pure_seminoma',
      'rectal_adenocarcinoma',
      'rectal_cancer',
      'rectal_neuroendocrine_tumor',
      'renal_cell_carcinoma',
      'rosai_dorfman',
      'sarcoma',
      'sezeray_syndrome',
      'signet_ring_cell_carcinoma',
      'skin_cancer',
      'small_bowel_adenocarcinoma',
      'small_bowel_cancer',
      'small_cell_lung_cancer',
      'small_intestinal_neuroendocrine_tumor',
      'small_lymphocytic_lymphoma',
      'somatostatinoma',
      'splenic_marginal_zone_lymphoma',
      'squamous_cell_carcinoma_of_the_esophagus',
      'squamous_cell_carcinoma_of_the_lung',
      'squamous_cell_carcinoma_of_the_skin',
      'systemic_light_chain_amyloidosis',
      'systemic_mastocytosis',
      't_cell_large_granular_lymphocytic_leukemia',
      't_cell_prolymphocytic_leukemia',
      'testicular_cancer',
      'testicular_germ_cell_tumor',
      'thymic_cancer',
      'thymic_carcinoma',
      'thymoma',
      'thyroid_cancer',
      'triple_negative_breast_cancer',
      'tubular_carcinoma',
      'urothelial_carcinoma_of_the_renal_pelvis',
      'urothelial_carcinoma_of_the_ureter',
      'urothelial_carcinoma',
      'uterine_cancer',
      'uterine_sarcoma',
      'uveal_melanoma',
      'vipoma',
      'vulvar_adenocarcinoma',
      'vulvar_cancer',
      'vulvar_squamous_cell_carcinoma',
      'waldenstrom_macroglobulinemia',
      'wilms_tumor',
      'yolk_sac_tumor',
    ],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Cellulitis',
    type: DiagnosisType.SupplementalDx,
    summary: cellulitisSummary,
    conditionKeys: ['cellulitis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Chronic Kidney Disease (CKD)',
    type: DiagnosisType.CoreDx,
    summary: chronicKidneyDiseaseSummary,
    conditionKeys: ['kidney_disease'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Cirrhosis',
    type: DiagnosisType.LiveDx,
    summary: getGenericLiveDxSummary('cirrhosis'),
    conditionKeys: ['cirrhosis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Coronary Artery Disease (CAD)',
    type: DiagnosisType.SupplementalDx,
    summary: coronaryArteryDiseaseSummary,
    conditionKeys: ['cad'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Covid',
    type: DiagnosisType.CoreDx,
    summary: covidSummary,
    conditionKeys: ['covid'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Dementia',
    type: DiagnosisType.SupplementalDx,
    summary: dementiaSummary,
    conditionKeys: ['dementia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Depression and Anxiety',
    type: DiagnosisType.SupplementalDx,
    summary: depressionAndAnxietySummary,
    conditionKeys: ['depression_anxiety'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Diabetes (DM)',
    type: DiagnosisType.CoreDx,
    summary: diabetesSummary,
    conditionKeys: ['diabetes'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Diverticulitis',
    type: DiagnosisType.LiveDx,
    summary: getGenericLiveDxSummary('diverticulitis'),
    conditionKeys: ['diverticulitis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Encephalopathy',
    type: DiagnosisType.SupplementalDx,
    summary: encephalopathySummary,
    // TODO: 'ams' will no longer be necessary when the backend has been migrated (CAT-262)
    conditionKeys: ['ams', 'encephalopathy'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Endocarditis',
    type: DiagnosisType.SupplementalDx,
    summary: endocarditisSummary,
    conditionKeys: ['endocarditis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Gastroesophageal Reflux Disease (GERD)',
    type: DiagnosisType.SupplementalDx,
    summary: gerdSummary,
    conditionKeys: ['gerd'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Gastrointestinal Bleed (GI Bleed)',
    type: DiagnosisType.SupplementalDx,
    summary: giBleedSummary,
    conditionKeys: ['gi_bleed'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Gout',
    type: DiagnosisType.SupplementalDx,
    summary: goutSummary,
    conditionKeys: ['gout'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Heart Failure (HF)',
    type: DiagnosisType.CoreDx,
    summary: heartFailureSummary,
    conditionKeys: ['heart_failure'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hyperkalemia',
    type: DiagnosisType.CoreDx,
    summary: hyperkalemiaSummary,
    conditionKeys: ['hyperkalemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hyperlipidemia (HLD)',
    type: DiagnosisType.CoreDx,
    summary: hyperlipidemiaSummary,
    conditionKeys: ['hyperlipidemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypermagnesemia',
    type: DiagnosisType.CoreDx,
    summary: hypermagnesemiaSummary,
    conditionKeys: ['hypermagnesemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypernatremia',
    type: DiagnosisType.CoreDx,
    summary: hypernatremiaSummary,
    conditionKeys: ['hypernatremia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypertension (HTN)',
    type: DiagnosisType.CoreDx,
    summary: hypertensionSummary,
    conditionKeys: ['hypertension'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypoglycemia',
    type: DiagnosisType.LiveDx,
    summary: getGenericLiveDxSummary('hypoglycemia'),
    conditionKeys: ['hypoglycemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypokalemia',
    type: DiagnosisType.CoreDx,
    summary: hypokalemiaSummary,
    conditionKeys: ['hypokalemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hypomagnesemia',
    type: DiagnosisType.CoreDx,
    summary: hypomagnesemiaSummary,
    conditionKeys: ['hypomagnesemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Hyponatremia',
    type: DiagnosisType.CoreDx,
    summary: hyponatremiaSummary,
    conditionKeys: ['hyponatremia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Malnutrition',
    type: DiagnosisType.CoreDx,
    summary: malnutritionSummary,
    conditionKeys: ['malnutrition'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Neutropenia',
    type: DiagnosisType.CoreDx,
    summary: neutropeniaSummary,
    conditionKeys: ['neutropenia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Obesity',
    type: DiagnosisType.CoreDx,
    summary: obesitySummary,
    conditionKeys: ['obesity'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Obstructive Lung Disease (COPD and asthma)',
    type: DiagnosisType.CoreDx,
    summary: obstructiveLungSummary,
    conditionKeys: ['obstructive_lung'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pancreatitis',
    type: DiagnosisType.LiveDx,
    summary: getGenericLiveDxSummary('pancreatitis'),
    conditionKeys: ['pancreatitis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pancytopenia',
    type: DiagnosisType.CoreDx,
    summary: pancytopeniaSummary,
    conditionKeys: ['pancytopenia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Phosphate',
    type: DiagnosisType.CoreDx,
    summary: phosphateSummary,
    conditionKeys: ['hyperphosphatemia', 'hypophosphatemia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pneumonia (PNA)',
    type: DiagnosisType.CoreDx,
    summary: pneumoniaSummary,
    conditionKeys: ['pneumonia'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pressure Ulcer',
    type: DiagnosisType.CoreDx,
    summary: pressureUlcerSummary,
    conditionKeys: ['pressure_ulcer'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pulmonary Embolism',
    type: DiagnosisType.CoreDx,
    summary: pulmonaryEmbolismSummary,
    conditionKeys: ['pulmonary_embolism'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Pulmonary Hypertension (pHTN)',
    type: DiagnosisType.SupplementalDx,
    summary: pulmonaryHypertensionSummary,
    conditionKeys: ['pulmonary_hypertension'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Respiratory Failure',
    type: DiagnosisType.CoreDx,
    summary: respiratoryFailureSummary,
    conditionKeys: ['respiratory_failure'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Sepsis',
    type: DiagnosisType.CoreDx,
    summary: sepsisSummary,
    conditionKeys: ['sepsis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Stroke',
    type: DiagnosisType.SupplementalDx,
    summary: strokeSummary,
    conditionKeys: ['stroke'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Thrombocytopenia/Thrombocytosis',
    type: DiagnosisType.CoreDx,
    summary: thromboSummary,
    conditionKeys: ['thrombocytopenia', 'thrombocytosis'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
  {
    title: 'Thyroid',
    type: DiagnosisType.CoreDx,
    summary: thyroidSummary,
    conditionKeys: ['hyperthyroidism', 'hypothyroidism'],
    developmentState: ConditionDevelopmentState.ProdReady,
  },
];
