/**
 * Keep in sync with htmlStringToPlainText in frontend/app/cypress/support/utils.ts:16
 * Modified from:
 *  https://stackoverflow.com/questions/15180173/convert-html-to-plain-text-in-js-without-browser-environment
 */
export const htmlStringToPlainText = (htmlString: HtmlString): string => {
  const result = htmlString
    // ignore newlines
    .replace(/\n/g, '')
    // treate empty lines, closing divs, and br tags as newlines
    .replace(/<br>(?:<\/div>)?|<\/div>/gi, '\n')
    // remove the extra last newline added when replacing div/br
    .replace(/\n$/g, '')
    // remove tags
    .replace(/<[^>]*>/g, '')
    // replace non-breaking spaces with regular spaces
    .replace(/&nbsp;/g, ' ')
    // replace &gt;
    .replace(/&gt;/g, '>')
    // replace &lt;
    .replace(/&lt;/g, '<')
    // replace &amp;
    .replace(/&amp;/g, '&');

  return result;
};
