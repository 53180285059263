import { FC, memo } from 'react';

import {
  getLefterlineCss,
  getRighterlineCss,
  getTextCss,
  tooltipCss,
  getUnderlineStyle,
} from './css';
import { Keyword } from '../../../@types/state';
import { StaticTooltip } from '../../../reuse';
import { useMeshedContext } from '../../meshed/MeshedProvider';

const UnconnectedConditionKeyword: FC<{
  keyword: Keyword;
  selected: boolean;
}> = memo(({ keyword, selected, children }) => {
  const inner = (
    <span
      css={getTextCss({ type: keyword.type, selected })}
      data-condition-keyword-module={keyword.module}
      {...(keyword.type === 'specChecker'
        ? {
            'data-cy-spec-checker-text': true,
            'data-spec-checker-text': true,
          }
        : undefined)}
    >
      {children ?? keyword.text}
      <span css={getLefterlineCss({ selected })} data-condition-keyword-lefterline />
      <span css={getRighterlineCss({ selected })} data-condition-keyword-righterline />
      <span data-cy-spec-checker-underline style={getUnderlineStyle({ type: keyword.type })} />
    </span>
  );

  return keyword.type === 'specChecker' ? (
    <StaticTooltip
      css={tooltipCss}
      data-cy-spec-checker-tooltip
      data-spec-checker-tooltip
      display="inline-block"
      placement="top"
      tooltip={keyword.tooltipText}
    >
      {inner}
    </StaticTooltip>
  ) : (
    inner
  );
});

export const ConditionKeyword: FC<{
  keyword: Keyword;
}> = ({ keyword, children }) => {
  const { dxDetailsVisible, lastClickedKeywordModule, selectedModule } = useMeshedContext(
    ({ dxDetailsVisible, lastClickedKeywordModule, selectedModule }) => ({
      dxDetailsVisible,
      lastClickedKeywordModule,
      selectedModule,
    })
  );

  const shouldHighlightSelectedKeyword = dxDetailsVisible || !!lastClickedKeywordModule;

  return (
    <UnconnectedConditionKeyword
      keyword={keyword}
      selected={shouldHighlightSelectedKeyword && selectedModule === keyword.module}
    >
      {children}
    </UnconnectedConditionKeyword>
  );
};
