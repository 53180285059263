import _ from 'lodash';
import { FC, ReactElement } from 'react';

export type AccordionAPI = {
  close(): void;
  isOpen: boolean;
  open(): void;
  toggle(): void;
};

export type DumbAccordionProps<
  C extends Record<string, unknown>,
  H extends Record<string, unknown>
> = Partial<AccordionAPI> & {
  Content: FC<C & AccordionAPI>;
  contentProps: C;
  Header: FC<H & AccordionAPI>;
  headerProps: H;
  openByDefault?: boolean;
};

const headerStyle = { cursor: 'pointer' };

const contentBaseStyle = {
  position: 'relative',
} as const;
const contentClosedStyle = {
  ...contentBaseStyle,
  height: 0,
  overflow: 'hidden',
} as const;
const contentOpenStyle = { ...contentBaseStyle, height: 'auto' } as const;

export const DumbAccordion = <
  C extends Record<string, unknown>,
  H extends Record<string, unknown>
>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  close = _.noop,
  Content,
  contentProps,
  Header,
  headerProps,
  isOpen = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  open = _.noop,
  toggle = _.noop,
  ...rest
}: DumbAccordionProps<C, H>): ReactElement => (
  <div {...rest}>
    <div onClick={toggle} role="button" style={headerStyle} tabIndex={0}>
      {/* eslint-disable-next-line */}
        {/* @ts-ignore An insurmountable typing issue manifesting in @emotion/react 11.*/}
      <Header isOpen={isOpen} {...headerProps} />
    </div>
    {
      // NOTE: If we want to animate this transition, we could employ
      //  https://www.npmjs.com/package/react-animate-height.
    }
    <div style={isOpen ? contentOpenStyle : contentClosedStyle}>
      {/* eslint-disable-next-line */}
        {/* @ts-ignore An insurmountable typing issue manifesting in @emotion/react 11.*/}
      <Content isOpen={isOpen} {...contentProps} />
    </div>
  </div>
);
DumbAccordion.displayName = 'DumbAccordion';
