import classNames from 'classnames';
import { forwardRef } from 'react';

import { IconButtonColorCSS, IconButtonSizeCSS, Root, iconStyle } from './css';
import { IconButtonColor, IconButtonSize } from './types';
import { ButtonBaseProps } from '../buttonBase';
import { IconName } from '../icons';
import { Icon, IconPropsRest } from '../icon';

export type IconButtonProps = ButtonBaseProps & {
  color: IconButtonColor | IconButtonColorCSS;
  iconName: IconName;
  size: IconButtonSize | IconButtonSizeCSS;
  active?: boolean;
  iconAttributes?: IconPropsRest;
};

export const IconButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, IconButtonProps>(
  ({ color, iconName, size, active, className, iconAttributes, ...rest }, ref) => (
    <Root
      ref={ref}
      className={classNames({ active }, className)}
      color={color}
      size={size}
      {...rest}
    >
      <Icon iconName={iconName} style={iconStyle} {...iconAttributes} />
    </Root>
  )
);
IconButton.displayName = 'IconButton';
