import { FC, useState } from 'react';

import { AccordionTableAccordionContent } from './AccordionTableAccordionContent';
import { AccordionTableAccordionHeader } from './AccordionTableAccordionHeader';
import { SmartAccordion } from '..';
import { AccordionTableSection } from './types';

type AccordionTableAccordionProps = AccordionTableSection & {
  first: boolean;
};

export const AccordionTableAccordion: FC<AccordionTableAccordionProps> = ({
  content: rows,
  first,
  showColumnHeaders,
  columns,
  header: text,
  collapsable = true,
}) => {
  const numberOfRows = rows.length;

  const [showingAllRows, setShowingAllRows] = useState(!collapsable);

  return (
    <SmartAccordion
      Content={AccordionTableAccordionContent}
      contentProps={{ rows, showingAllRows, showColumnHeaders, columns }}
      data-cy-accordion-table-accordion
      Header={AccordionTableAccordionHeader}
      headerProps={{ first, numberOfRows, setShowingAllRows, showingAllRows, text, collapsable }}
      openByDefault
    />
  );
};
AccordionTableAccordion.displayName = 'AccordionTableAccordion';
