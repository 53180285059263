import { RefObject, useEffect } from 'react';

const PIXEL_TOLERANCE = 4;

const getContainerIsScrolledToBottom = (container: HTMLElement): boolean => {
  if (!container) return false;

  // Calculate the maximum scroll position to consider as "bottom"
  const maxScrollPosition = container.scrollHeight - container.clientHeight;

  // Check if the current scroll position is close to the maximum
  return container.scrollTop >= maxScrollPosition - PIXEL_TOLERANCE;
};

export const useScrollAttachedDetector = (
  ref: RefObject<HTMLElement>,
  setIsScrollAttached: (isDetached: boolean) => void
) => {
  useEffect(() => {
    const { current } = ref;

    if (current) {
      const onScroll = () => {
        const containerIsScrolledToBottom = getContainerIsScrolledToBottom(current);
        setIsScrollAttached(containerIsScrolledToBottom);
      };

      current.addEventListener('scroll', onScroll);

      return () => current.removeEventListener('scroll', onScroll);
    }

    return undefined;
  }, [ref, setIsScrollAttached]);
};
