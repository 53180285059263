import { theme } from '../../../reuse';
import { cellStyle } from '../../../reuse/accordionTable/css';

const border = `1px solid ${theme.colors.grey4}`;

export const matchCellStyle = {
  ...cellStyle,
  width: 'calc(56% - 38px)',
};

export const quoteSpanStyle = {
  padding: '0 5px',
} as const;

export const quoteStyle = {
  position: 'absolute',
  top: 0,
} as const;

export const startQuoteStyle = {
  ...quoteStyle,
  left: 0,
} as const;

export const endQuoteStyle = {
  ...quoteStyle,
  right: 0,
} as const;

export const dateCellStyle = {
  ...cellStyle,
  borderLeft: border,
  width: 76,
} as const;

export const authorCellStyle = {
  ...cellStyle,
  borderLeft: border,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'calc(44% - 38px)',
} as const;
