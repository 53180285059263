import { createSelector, useShallowEqualSelector } from '~/app/store';
import { ConditionNoteBlock, ShelfDividerBlock } from '~/app/@types/state';
import { get } from '~/app/utils';

type KeyedConditionNoteBlock = ConditionNoteBlock & {
  key: string;
};

type KeyedShelfDividerBlock = ShelfDividerBlock & {
  key: string;
};

type KeyedNoteBlock = KeyedConditionNoteBlock | KeyedShelfDividerBlock;

const getNoteBlocks = createSelector(
  (state) => state.regardNote.masterNoteBlocks,
  (noteBlocks) => noteBlocks
);

const scrollIntoViewCache: Record<string /* condition id */, number> = {};

const getConditionKey = ({
  id,
  scrollIntoViewTimestamp,
}: {
  id: string;
  scrollIntoViewTimestamp: number | undefined;
}): string => {
  /**
   * Caching the old scrollIntoView value makes ids more consistent; it
   *  prevents the case where we switch keys back and forth like:
   *
   * BAD
   * 21 => 21-1621551963182 => 21
   *                          ^
   *    (back to 21 again, because scrollIntoView is now undefined)
   *
   * GOOD
   * 21 => 21-1621551963182 => 21-1621551963182
   *
   * If we don't do this, the condition textarea will unexpectedly lose focus
   *  as the component key changes unnecessarily.
   */
  if (scrollIntoViewTimestamp) scrollIntoViewCache[id] = scrollIntoViewTimestamp;
  const cachedScrollIntoView = get(scrollIntoViewCache, id);

  // key
  // NOTE: We must use a pair of id and scrollIntoView as the key here
  //  rather than simply id, otherwise two undesireable effects will
  //  will result upon reordering a condition:
  //    1. react will jump (scroll) to the moved condition
  //    2. the focus on the clicked move condition button will stick
  // These undesireable effects, for some reason, only happen on moving
  //  to top. See the restoreSelection method in the react-dom package;
  //  this method is causing the problem.
  // But rekeying on a reorder avoids these outcomes.
  const key = cachedScrollIntoView ? `${id}-scroll-${cachedScrollIntoView}` : id;

  return key;
};

const getKeyedNoteBlocks = (
  noteBlocks: (ConditionNoteBlock | ShelfDividerBlock)[]
): KeyedNoteBlock[] => {
  const keyedNoteBlocks = noteBlocks.map((noteBlock) => ({
    ...noteBlock,
    key: getConditionKey({
      id: noteBlock.id,
      scrollIntoViewTimestamp: noteBlock.scrollIntoView?.timestamp,
    }),
  }));

  return keyedNoteBlocks;
};

export const useNoteBlocks = (): KeyedNoteBlock[] => {
  // A shallow equals comparision is sufficient as along as note section object
  //  references do not change unless their object contents change.
  const noteBlocks = useShallowEqualSelector(getNoteBlocks);
  const keyedNoteBlocks = getKeyedNoteBlocks(noteBlocks);

  return keyedNoteBlocks;
};
