import { FC } from 'react';

import { theme } from '~/app/reuse';

import { DxDetailTabButton } from '../dxDetailTabButton';
import { DxDetailTabContent } from '../dxDetailTabContent/DxDetailTabContent';
import { useMeshedContext } from '../MeshedProvider';

import { useSelectDxDetailsTab } from './useSelectDxDetailsTab';
import { EmptyNote } from './EmptyNote';

// here we set Y to scroll for dxDetails with a lot of content,
// but we set X to hidden to prevent a horizontal scrolling bug in IE
const rootStyle = {
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
} as const;

const buttonContainerStyle = {
  borderBottom: `1px solid ${theme.colors.blueRegular}`,
  display: 'flex',
  padding: '10px 24px 0',
} as const;

export const DxDetails: FC = () => {
  const { openModules, selectedModule } = useMeshedContext(({ openModules, selectedModule }) => ({
    openModules,
    selectedModule,
  }));
  const openDxDetails = useSelectDxDetailsTab();

  const hasModules = openModules.length > 0;

  return (
    <div data-cy-dx-details style={rootStyle}>
      {hasModules && (
        <div style={buttonContainerStyle}>
          {openModules.map((module, i) => {
            const selected = module === selectedModule;

            return (
              <DxDetailTabButton
                key={i}
                first={i === 0}
                module={module}
                openDxDetails={openDxDetails}
                selected={selected}
              />
            );
          })}
        </div>
      )}
      <div>
        {openModules.map((module, i) => {
          const selected = module === selectedModule;

          // NOTE: Rendering only the selected tab content to save rendering time in IE.
          return selected ? (
            <DxDetailTabContent key={i} module={module} selected={selected} />
          ) : null;
        })}
        {!hasModules && <EmptyNote />}
      </div>
    </div>
  );
};
