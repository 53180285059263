import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { track } from '~/app/analytics';
import { ComplaintTableMenu, MenuOption } from './ComplaintTableMenu';
import '~/app/styles/ComplaintTableSection.scss';

export const ComplaintTableSection: React.FC<{
  label: string;
  module: string;
  sortingFunc(a: string, b: string): number;
  options: Record<string, MenuOption>;
  toggleOption(option: unknown): void;
}> = ({ label, sortingFunc, options, toggleOption, module }) => {
  const node = useRef<HTMLTableDataCellElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const totalOptions = Object.keys(options);
  const optionsShown = totalOptions.filter((o) => options[o].show);

  return (
    <tr className="ComplaintTableSection">
      <td
        ref={node}
        className={classNames('sticky', {
          active: menuOpen,
        })}
        onClick={() => {
          const trackToggle = menuOpen ? track.closedTableMenu : track.openedTableMenu;
          trackToggle({ label, module });
          setMenuOpen(!menuOpen);
        }}
      >
        {label}
        {optionsShown.length < totalOptions.length && (
          <span className="num-shown">{`(${optionsShown.length} of ${totalOptions.length})`}</span>
        )}
        <ComplaintTableMenu
          menuOpen={menuOpen}
          options={options}
          setMenuOpen={setMenuOpen}
          sortingFunc={sortingFunc}
          toggleOption={(option: string) => {
            const trackOption = optionsShown.includes(option)
              ? track.unselectedTableMenu
              : track.selectedTableMenu;
            trackOption({
              label,
              option: options[option].formattedLabel || option,
              module,
            });
            toggleOption(option);
          }}
        />
      </td>
      <td className="sticky-headerbg" />
    </tr>
  );
};
