import { shallowEqual } from 'fast-equals';
import _ from 'lodash';

import {
  NoteBlock,
  BulletNoteStatusRecord,
  RegardNoteState,
  IndexedConditionNoteBlock,
  IndexedNoteBlock,
} from '../@types/state';
import { noteBlocksToHtmlString } from '../utils';
import * as flags from '../flags';
import { persistDraftNote } from '../api';

export type UndoRedoItemState = Pick<
  RegardNoteState,
  'bulletNoteStatusByBulletId' | 'conditionsById' | 'masterNoteBlocks' | 'noteBlocksById'
>;
type UndoRedoItem = {
  state: UndoRedoItemState;
};

const MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK = 500;

let undoRedoStack: UndoRedoItem[] = []; // Wrapping array of saved edits
let latestUndoRedoItem = [-1]; // Points to last stored/retrieved undo (i.e. current state)
let undoRedoStackHead = [-1]; // Points to head of undo stack (to limit redos)
let undoRedoStackTail = [-1]; // Points to the tail of the undo stack
let undoRedoCounter = [0]; // Makes sure that a delayed pushState doesn't break undo/redo

/**
 * Undo stack arrangement:
 *
 *  - undoRedoStack has MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK entries
 *  - latestUndoRedoItem, undoRedoStackHead & undoRedoStackTail index into undoRedoStack and wrap modulo
 *    MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK
 *  - undoRedoStackHead points at most recent available redo state
 *  - undoRedoStackTail points at furthest available undo state
 *  - If latestUndoRedoItem === undoRedoStackTail, no undo is available
 *  - If latestUndoRedoItem === undoRedoStackHead, no redo is available
 *  - Initially, all pointers are set to -1, indicating no available undos or
 *    redos
 *  - If stack wraps (i.e. undoRedoStackHead === undoRedoStackTail), undoRedoStackTail is incremented,
 *    dropping one entry
 */

export const resetUndoRedo = () => {
  undoRedoStack = [];
  latestUndoRedoItem = [-1];
  undoRedoStackHead = [-1];
  undoRedoStackTail = [-1];
  undoRedoCounter = [0];
};

export const canUndo = (): boolean => latestUndoRedoItem[0] !== undoRedoStackTail[0];
export const canRedo = (): boolean => latestUndoRedoItem[0] !== undoRedoStackHead[0];

export const updateCanRedoAndCanUndo = (): void => {
  const prevCanRedo = window.store.getState().undo.canRedo;
  const nextCanRedo = canRedo();
  const prevCanUndo = window.store.getState().undo.canUndo;
  const nextCanUndo = canUndo();

  if (prevCanRedo !== nextCanRedo || prevCanUndo !== nextCanUndo) {
    window.store.dispatch({
      type: 'set can redo and can undo',
      payload: {
        canRedo: nextCanRedo,
        canUndo: nextCanUndo,
      },
    });
  }
};

/*
 * Returns the current value of the undo counter used to prevent race-conditions
 */
export const getUndoCounter = (): number => undoRedoCounter[0];

/*
 * Updates the undo history stack to reflect the current content of the document
 * and the current cursor position.
 */
export function pushUndo(regardUndoRedoItemState: UndoRedoItemState, counter: number) {
  // Check whether this call was obsoleted by undo/redo
  if (counter !== undoRedoCounter[0]) {
    return;
  }

  // Build new undo state object
  const newState: UndoRedoItem = {
    state: {
      bulletNoteStatusByBulletId: regardUndoRedoItemState.bulletNoteStatusByBulletId,
      conditionsById: regardUndoRedoItemState.conditionsById,
      masterNoteBlocks: regardUndoRedoItemState.masterNoteBlocks,
      noteBlocksById: regardUndoRedoItemState.noteBlocksById,
    },
  };

  // Check if regardUndoRedoItemState actually changed and ignore update if not
  if (
    latestUndoRedoItem[0] > -1 &&
    shallowEqual(undoRedoStack[latestUndoRedoItem[0]].state, newState.state)
  ) {
    return;
  }
  // Calculate history slot to use for new state
  const undoCurrent = (latestUndoRedoItem[0] + 1) % MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK;

  // If there are no redos left, add new state to top of stack
  if (latestUndoRedoItem[0] === undoRedoStackHead[0]) {
    latestUndoRedoItem[0] = undoCurrent;
    undoRedoStackHead[0] = undoCurrent;
    undoRedoStack[undoCurrent] = newState;
    if (undoCurrent === undoRedoStackTail[0]) {
      undoRedoStackTail[0] = (undoRedoStackTail[0] + 1) % MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK;
    } else if (undoRedoStackTail[0] === -1) {
      undoRedoStackTail[0] = 0;
    }
  } else if (shallowEqual(undoRedoStack[undoCurrent].state, newState.state)) {
    // Otherwise, check if the new state is basically a manual redo
    latestUndoRedoItem[0] = undoCurrent;
  } else {
    // If not, drop redo stack
    latestUndoRedoItem[0] = undoCurrent;
    undoRedoStackHead[0] = undoCurrent;
    undoRedoStack[undoCurrent] = newState;
  }

  updateCanRedoAndCanUndo();
}

const persistDraftDebounced = _.debounce(persistDraftNote, 1000);

const storeAssessmentPlanAndFooter = (
  index: NoteBlock[],
  basenoteId: string,
  encounterId: string,
  baseNoteHash: string | null
) => {
  if (!flags.isSalesDemoMode) {
    persistDraftDebounced(basenoteId, encounterId, baseNoteHash, noteBlocksToHtmlString(index));
  }
};

type PushToUndoStackFn = (args: {
  basenoteId: string;
  basenoteHash: string | null;
  encounterId: string;
  nextBulletNoteStatus: BulletNoteStatusRecord;
  nextConditionsById: Record<string, IndexedConditionNoteBlock>;
  nextNoteBlocks: NoteBlock[];
  nextNoteBlocksById: Record<string, IndexedNoteBlock>;
  shouldPersistNote: boolean;
}) => void;

const pushToUndoStack: PushToUndoStackFn = ({
  basenoteId,
  basenoteHash,
  encounterId,
  nextBulletNoteStatus,
  nextConditionsById,
  nextNoteBlocks,
  nextNoteBlocksById,
  shouldPersistNote,
}) => {
  pushUndo(
    {
      bulletNoteStatusByBulletId: nextBulletNoteStatus,
      conditionsById: nextConditionsById,
      masterNoteBlocks: nextNoteBlocks,
      noteBlocksById: nextNoteBlocksById,
    },
    getUndoCounter()
  );

  if (shouldPersistNote) {
    storeAssessmentPlanAndFooter(nextNoteBlocks, basenoteId, encounterId, basenoteHash);
  }
};

export const addToUndosLater = _.debounce(pushToUndoStack, 300);
export const addToUndosNow: PushToUndoStackFn = (args) => {
  // commit any changes waiting to be put onto the undo stack
  addToUndosLater.flush();
  // and subsequently commit our high priority change
  pushToUndoStack(args);
};

/*
 * Performs an undo operation on the history stack and returns an object containing
 * the state after the undo as well as any extras (like the cursor position).
 * For the very first undo, `extras` might have a value of undefined, in which case the
 * actual cursor position can be determined, for example, using `firstDifference(...)`.
 */
export const popUndo = (): UndoRedoItem | null => {
  // commit any changes waiting to be put onto the undo stack
  addToUndosLater.flush();

  undoRedoCounter[0] = (undoRedoCounter[0] + 1) & 0xfffffff; // eslint-disable-line no-bitwise

  // Check if undo stack is empty
  if (latestUndoRedoItem[0] === undoRedoStackTail[0]) {
    return null;
  }

  // Step back one entry
  latestUndoRedoItem[0] =
    (latestUndoRedoItem[0] + (MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK - 1)) %
    MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK;

  updateCanRedoAndCanUndo();

  return undoRedoStack[latestUndoRedoItem[0]];
};

/*
 * Performs a redo operation on the history stack and returns an object containing
 * the state after the undo as well as any extras (like the cursor position).
 */
export const popRedo = (): UndoRedoItem | null => {
  // commit any changes waiting to be put onto the undo stack
  addToUndosLater.flush();

  undoRedoCounter[0] = (undoRedoCounter[0] + 1) & 0xfffffff; // eslint-disable-line no-bitwise

  // Check if redo stack is empty
  if (latestUndoRedoItem[0] === undoRedoStackHead[0]) {
    return null;
  }

  // Step forward one entry
  latestUndoRedoItem[0] = (latestUndoRedoItem[0] + 1) % MAX_NUM_OF_UNDO_ITEMS_ALLOWED_ON_STACK;

  updateCanRedoAndCanUndo();

  return undoRedoStack[latestUndoRedoItem[0]];
};
