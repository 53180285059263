import { createDispatchHook } from 'react-redux';
import { Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { reducers } from '~/app/reducers';
import { AppState, store } from './store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractActionsFromReducer<P> = P extends Reducer<any, infer A> ? A : never;

/**
 * Not really the full type, since it doesn't accomodate thunks; see
 *  `useDispatch` below for a better idea. In my experience, this type isn't
 *  needed.
 */
export type AppDispatch = typeof store.dispatch;

type AppReducer = typeof reducers;
export type AppAction = ExtractActionsFromReducer<AppReducer>;

export type AppThunkAction = ThunkAction<unknown, AppState, unknown, AppAction>;

export type AppActionOrThunkAction = AppAction | AppThunkAction;

export const useDispatch = createDispatchHook() as () => (
  actionOrThunkAction: AppActionOrThunkAction
) => void;
