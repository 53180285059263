export const epicTheme = {
  colors: {
    accentBlue: '#49AAF8',
    accentGreen: '#2EC5A0',
    accentLightBlue: '#5ACCE5',
    accentMagenta: '#C52E7F',
    accentPurple: '#CCA3F5',
    accentYellow: '#EEE861',
    black: '#000000',
    darkBlue: '#00477D',
    darkGray: '#98A0A7',
    gray: '#E5E5E5',
    headerLightBlue: '#5ACCE5',
    headerLightGreen: '#BDF3E6',
    headerOrange: '#E5AE5A',
    headerPurple: '#D8BDF3',
    headerBlueGray: '#A2C4DE',
    hrBlue: '#0270C4',
    infoYellow: '#D9D124',
    lightGray: '#EAECF2',
    white: '#FFFFFF',
  },
  other: {
    borderRadius4: '4.1px',
  },
};
