import { forwardRef, memo } from 'react';

import { IconButton } from '../../../reuse';

const iconButtonCss = {
  borderRadius: 0,
  left: 0,
  position: 'absolute',
  top: 1,
} as const;

// This is a mistake by eslint
// eslint-disable-next-line react/no-unused-prop-types
type ConditionSuggestionRemoveButtonProps = { disabled: boolean; onClick(): void };

export const ConditionSuggestionRemoveButton = memo(
  forwardRef<HTMLButtonElement, ConditionSuggestionRemoveButtonProps>(
    ({ disabled, onClick }, ref) => (
      <IconButton
        ref={ref}
        color="tertiary-blue"
        css={iconButtonCss}
        data-condition-suggestion-remove-button
        data-cy-condition-suggestion-remove-button
        disabled={disabled}
        iconName="trash"
        onClick={onClick}
        size={{
          height: 'calc(100% - 2px)',
          width: 24,
        }}
      />
    )
  )
);
ConditionSuggestionRemoveButton.displayName = 'ConditionSuggestionRemoveButton';
