import { track } from '~/app/analytics';

/* Controls for managing amplitude events for the specChecker. The `unspecifiedRegardConditions` object
 * allows us to only send 1 amplitude event per module. I think it would be too much noise
 * to send the event multiple times per session. */
export const unspecifiedRegardConditions: Record<string, boolean> = {};
const specifyModule = (m: string) => {
  unspecifiedRegardConditions[m] = false;
  const name = m.replace('_', ' ');
  track.specifyModule(name, m);
};

export const createTooltipText = ({
  module,
  suggestedQualifier,
  foundQualifiers,
  regardTitleQualifiers,
}: {
  module: string;
  suggestedQualifier: string;
  foundQualifiers: string[];
  regardTitleQualifiers: string[];
}): string => {
  if (module === 'heart_failure') {
    // The `heart_failure` module has some special SpecChecker logic
    // If there are no hf qualifiers found in the user's title, nor the Regard Dx Title,
    // We still show a non-specific tooltip
    if (foundQualifiers.length === 0) {
      return regardTitleQualifiers.length
        ? `Specification Opportunity:\n${suggestedQualifier} or other`
        : 'Specification Opportunity:\nSpecify if diastolic or systolic';
    }
  } else if (foundQualifiers.length < regardTitleQualifiers.length) {
    if (suggestedQualifier) {
      const foundQualifierStrings: string[] = [...foundQualifiers];

      // determine qualifiers found by Regard but not currently present in title
      const missingQualifiers = regardTitleQualifiers.filter(
        (qualifier) => foundQualifierStrings.indexOf(qualifier) === -1
      );

      // join qualifiers
      const suggestion = missingQualifiers.join(', ');
      return `Specification Opportunity:\n${suggestion} or other`;
    }
    if (unspecifiedRegardConditions[module] === undefined)
      unspecifiedRegardConditions[module] = true;
  }

  if (unspecifiedRegardConditions[module]) {
    // if the logic branches here, we know the [m] module is specified
    // in the user's title. So we tell amplitude (only the first time)
    // with the following function:
    specifyModule(module);
  }

  return '';
};
