import { SummaryUl, SummaryOl } from './common';

export const pressureUlcerSummary = (
  <SummaryOl>
    <li>
      Regard will diagnose pressure ulcers by searching clinical notes (H&P and progress notes) from
      within the current encounter for keywords indicative of pressure ulcers. The algorithm will
      also search prior encounters for pressure ulcers including the word “chronic”.
    </li>
    <li>
      Determine number of ulcers with stage / location / laterality by parsing notes for keywords
      indicating specifications.
    </li>
    <li>
      Determine if ulcer was present on admission
      <SummaryUl innerList>
        <li>Determine if ulcer is chronic, or from prior encounters.</li>
        <li>Check if ulcer is noted as “present on admission” or “POA” within note.</li>
        <li>Check if pressure ulcer is mentioned in notes within 24 hours of admission.</li>
      </SummaryUl>
    </li>
  </SummaryOl>
);
