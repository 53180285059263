import { shallowEqual } from 'fast-equals';
import { FC, useCallback } from 'react';

import { getIsNotesAppEnabled, getNotesAppUrl } from '~/app/flags';
import { createSelector, useSelector, useDispatch } from '~/app/store';
import { IconButton, zIndices } from '~/app/reuse';

const regard2ModalOpenSelector = createSelector(
  (state) => state.ui.regard2ModalOpen,
  (diagnosis) => diagnosis
);

const modalContainerStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: zIndices.hiddenTopModal,
} as const;

const modalStyle = {
  background: 'white',
  position: 'relative',
  width: '90%',
  height: '90%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.5rem',
} as const;

const modalHeaderStyle = {
  padding: '1rem 1rem 0 1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
} as const;

const modalTitleStyle = {
  margin: 0,
  padding: 0,
};

const modalContentStyle = {
  padding: '1rem',
  flexGrow: 1,
  overflow: 'hidden',
} as const;

export const Regard2Modal: FC = () => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => dispatch({ type: 'close regard2 modal' }), [dispatch]);

  const regard2Allowed = getIsNotesAppEnabled();
  const regard2ModalOpen = useSelector(regard2ModalOpenSelector, shallowEqual);
  const isOpen = regard2Allowed && regard2ModalOpen;
  const notesAppUrl = getNotesAppUrl();

  if (!isOpen) return null;

  return (
    <div style={modalContainerStyle}>
      <div style={modalStyle}>
        <div style={modalHeaderStyle}>
          <h3 style={modalTitleStyle}>Regard 2.0 Notes App</h3>
          <IconButton
            aria-label="close"
            color="tertiary-grey"
            iconName="x"
            onClick={onClose}
            size="mini"
          />
        </div>
        <div style={modalContentStyle}>
          <iframe
            height="100%"
            src={notesAppUrl}
            style={{ border: 0 }}
            title="Regard 2.0 Notes App"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};
