import { deepEqual } from 'fast-equals';
import { FC, memo } from 'react';

import { ConditionLine } from '../conditionLine';
import { ConditionTitleDecorator } from '../conditionTitleDecorator';
import { ConditionLineStatus } from '../../../@types';
import { Keyword } from '../../../@types/state';
import { ConditionTitleLineContent } from './ConditionTitleLineContent';

interface ConditionTitleProps {
  hasNonCriticalSuggestions: boolean;
  html: HtmlString;
  isFirstLine: boolean;
  isLastLine: boolean;
  isShelvedCondition: boolean;
  keywords: Keyword[];
  modules?: string[];
  status: ConditionLineStatus;
  suggestionsAreMinimized: boolean;
  toggleSuggestionsMinimized(): void;
}

export const ConditionTitle: FC<ConditionTitleProps> = memo(
  ({
    hasNonCriticalSuggestions,
    html,
    isFirstLine,
    isLastLine,
    isShelvedCondition,
    keywords,
    modules,
    status,
    suggestionsAreMinimized,
    toggleSuggestionsMinimized,
  }) => (
    <ConditionLine
      html={undefined}
      ids={[]}
      isFirstLine={isFirstLine}
      isLastLine={isLastLine}
      nextStatus="none"
      previousStatus="none"
      status="none"
      type="title"
    >
      {!!modules?.length && (
        <ConditionTitleDecorator
          hasNonCriticalSuggestions={hasNonCriticalSuggestions}
          isShelvedCondition={isShelvedCondition}
          status={status}
          suggestionsAreMinimized={suggestionsAreMinimized}
          toggleSuggestionsMinimized={toggleSuggestionsMinimized}
        />
      )}
      <ConditionTitleLineContent html={html} keywords={keywords} />
    </ConditionLine>
  ),
  // We deep equal compare here to accomodate `modules`.
  deepEqual
);

ConditionTitle.displayName = 'ConditionTitle';
