import {
  EMPTY_BR_HTML_LINE,
  EMPTY_NBSP_HTML_LINE,
  EMPTY_EMPTY_HTML_LINE,
  EMPTY_LINE_NBSP,
} from '~/app/constants';
import {
  sanitizeHtmlTitles,
  removeRedundantTags,
  getIsEmptyHtmlString,
  wrapWithShelfSignifiers,
  nth,
} from '.';
import { isFooterDiv } from './isFooterDiv';

type EmptyLine = typeof EMPTY_LINE_NBSP | 'empty';

const trimEmptyLines = (input: HtmlString): HtmlString => {
  const escapedEmptyLine = EMPTY_BR_HTML_LINE.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special characters
  const regex = new RegExp(`^${escapedEmptyLine}+|${escapedEmptyLine}+$`, 'g');

  return input.replace(regex, '') as HtmlString;
};

const convertEditorNodesToHtmlChunks = (divs: NodeListOf<HTMLDivElement>): HtmlString[] => {
  const htmlChunks: HtmlString[] = [];
  const divArray = Array.from(divs);

  // 1. Get HTML lines from each note block
  divArray.forEach((elem, i) => {
    const isShelfDivider = elem.getAttribute('data-editor-type') === 'shelfDivider';

    if (isShelfDivider) {
      const nextDiv = nth(divArray, i + 1);

      // if next div does not exist or is a footer, don't copy the shelf divider
      if (!nextDiv || isFooterDiv(nextDiv)) return;

      const shelfHtml = elem.innerHTML as HtmlString;

      // if the shelf divider has no text, don't copy the shelf divider
      if (getIsEmptyHtmlString(shelfHtml)) return;

      // add shelf divider line chunk
      htmlChunks.push(wrapWithShelfSignifiers(shelfHtml));
    } else {
      // add chunk
      htmlChunks.push(elem.innerHTML as HtmlString);
    }
  });

  // 2.

  return htmlChunks;
};

export const getEditorHtmlChunks = (): HtmlString[] =>
  convertEditorNodesToHtmlChunks(
    document.querySelectorAll<HTMLDivElement>('[data-note-block-id] [contenteditable]')
  );

export const getEditorHtmlChunksById = (noteBlockId: string): HtmlString[] =>
  convertEditorNodesToHtmlChunks(
    document.querySelectorAll<HTMLDivElement>(
      `[data-note-block-id="${noteBlockId}"] [contenteditable]`
    )
  );

export const getEmptyLine = ({ emptyLine }: { emptyLine: EmptyLine }) => {
  if (emptyLine === EMPTY_LINE_NBSP) return EMPTY_NBSP_HTML_LINE;
  if (emptyLine === 'empty') return EMPTY_EMPTY_HTML_LINE;
  return EMPTY_BR_HTML_LINE;
};

interface CleanupDomHtmlOptions {
  emptyLine: EmptyLine;
  shouldRemoveRedundantTags: boolean;
}

export const cleanupDomHtml = (chunks: HtmlString[], options: CleanupDomHtmlOptions): string =>
  chunks
    // 1. Reject empty note blocks
    .filter((chunk) => chunk !== EMPTY_BR_HTML_LINE)
    .map((chunk) => {
      // 2. Sanitize titles
      const sanitizedTitlesChunk = sanitizeHtmlTitles(chunk);

      // 3. Trim leading and trailing empty lines
      const trimmedChunk = trimEmptyLines(sanitizedTitlesChunk);

      // 4. Remove redundant tags
      return options.shouldRemoveRedundantTags
        ? removeRedundantTags(trimmedChunk, {
            emptyLine: options.emptyLine,
          })
        : trimmedChunk;
    })
    // 5. Join into one big HTML string
    .join(getEmptyLine({ emptyLine: options.emptyLine }));

export const getDomHtml = (options: CleanupDomHtmlOptions): string =>
  cleanupDomHtml(getEditorHtmlChunks(), options);
