import styled from '@emotion/styled';

import { AnchorTag } from '../anchorTag';
import { theme } from '../theme';

const { blueRegular, grey4 } = theme.colors;

export const anchorCss = {
  color: blueRegular,
  display: 'inline',
  fontWeight: 'inherit',
  textDecoration: 'underline',
  '&:disabled': {
    color: grey4,
  },
} as const;

export const Root = styled(AnchorTag)(anchorCss);
