import { indexHtmlNote } from '../controllers/noteMeshing/indexHtmlNote';

import { HtmlVersion, NoteType, PatientLoadDuration, PatientLoadPhase } from '../@types';
import { RegardNoteState } from '../@types/state';
import {
  REQUEST_REGARD_NOTE,
  RECEIVE_REGARD_NOTE_FAILURE,
  FETCH_PREV_PROPS_FOR_NEW_BASENOTE,
  RegardNoteAction,
} from '../actions/regardNote';
import { getBlankBaseNote } from '../controllers/regardNote';
import * as flags from '../flags';
import { isConditionNoteBlock, linesToHtmlString, nth } from '../utils';

export const regardNoteInitialState: RegardNoteState = {
  baseNoteData: {
    author: '',
    baseNoteType: NoteType.Blank,
    effectiveTimestamp: null,
    dateSignedTimestamp: null,
    htmlVersion: HtmlVersion.HtmlWithEMU,
    noteHtml: '' as HtmlString,
    contentHash: null,
    physicalExamText: '',
    resourceId: '',
  },
  conditionsById: {},
  baseNotes: [],
  bulletsByModule: {},
  currentConditionResults: [],
  bulletNoteStatusByBulletId: {},
  encounterId: '',
  encounterStart: null,
  errors: [], // list of error complaint module names
  fetchError: null, // Server error for display or undefined
  indexingData: {
    allModuleMatchesByTitle: {},
    conditionsByModule: {},
    conditionKeywords: {},
    conditionQualifiers: {},
    conditionNameToICDCodesMap: {},
    exactMatchRegardTitlesInNoteByModule: {},
    regardGeneratedTitleLookup: {},
    negativeDiagnosisSet: new Set(),
  },
  isAuthorized: false,
  isFetching: false,
  fetching: {
    percentage: 0,
    phase: PatientLoadPhase.Started,
    estimatedPatientLoadDuration: PatientLoadDuration.Short,
  },
  masterNoteBlocks: [],
  newDxIndicatorLineHidden: false,
  nonHospitalProblemModules: [],
  noteBlocksById: {},
  patientMrn: '',
  physicalExamKey: '',
  physicianId: '',
  previousConditionResults: [],
  pt: '',
  reviewMode: false,
  stats: undefined,
  timestamp: null,
};

export function fetchNote(
  // eslint-disable-next-line default-param-last
  state = regardNoteInitialState,
  action: RegardNoteAction
): RegardNoteState {
  switch (action.type) {
    case REQUEST_REGARD_NOTE:
      return {
        ...state,
        ...regardNoteInitialState,
        isAuthorized: state.isAuthorized,
        isFetching: true,
      };
    case 'update patient load progress':
      // Ensure that progress never goes backwards
      if (state.fetching.percentage >= action.payload.percentage) {
        return state;
      }
      return {
        ...state,
        fetching: {
          percentage: action.payload.percentage,
          phase: action.payload.phase,
          estimatedPatientLoadDuration: action.payload.estimatedPatientLoadDuration,
        },
      };
    case 'successfully received /doc response for patient': {
      const newState = action.payload;

      // SIDE-EFFECT:
      // Because currently, masterNoteBlocks, conditionsById, and
      //  noteBlocksById all share object references, we're going to add the
      //  textTimestamp without cloning the objects.
      // We shouldn't need new object references here for re-rendering, because
      //  this is the first time react is seeing these objects anyways.
      newState.masterNoteBlocks.forEach((noteBlock) => {
        // eslint-disable-next-line no-param-reassign
        noteBlock.textTimestamp = Date.now();
      });

      // if all conditions are new, right away, hide the new dx indicator line
      const conditions = newState.masterNoteBlocks.filter(isConditionNoteBlock);
      const numberOfNewConditions = conditions.filter(
        (noteBlock) => 'isNew' in noteBlock && noteBlock.isNew
      ).length;
      const newDxIndicatorLineHidden = conditions.length === numberOfNewConditions;

      return {
        ...state,
        ...regardNoteInitialState,
        ...action.payload,
        ...(newDxIndicatorLineHidden ? { newDxIndicatorLineHidden } : {}),
        isAuthorized: true,
        reviewMode: flags.isReview(),
      };
    }
    case RECEIVE_REGARD_NOTE_FAILURE:
      return { ...regardNoteInitialState, isAuthorized: false, fetchError: action.error };
    case 'undo or redo note sections': {
      const { bulletNoteStatusByBulletId, conditionsById, masterNoteBlocks, noteBlocksById } =
        action.payload;

      const textTimestamp = Date.now();

      // Intentionally cloning rather than deep cloning, because object
      //  references are preserved across these three data structures
      const newConditionsById = { ...conditionsById };
      const newNoteBlocksById = { ...noteBlocksById };
      const newNoteBlocks = masterNoteBlocks.map((nextNoteBlock, i) => {
        const prevCondition = nth(state.masterNoteBlocks, i);

        // if html is not the same, add a `textTimestamp` to force a re-render
        const htmlIsSame =
          prevCondition &&
          linesToHtmlString(prevCondition.lines) === linesToHtmlString(nextNoteBlock.lines);

        if (htmlIsSame) {
          return nextNoteBlock;
        }

        const newNoteBlock = {
          ...nextNoteBlock,
          index: i,
          textTimestamp,
        };

        if (isConditionNoteBlock(newNoteBlock)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newConditionsById[nextNoteBlock.id] = newNoteBlock;
        }

        newNoteBlocksById[nextNoteBlock.id] = newNoteBlock;

        return newNoteBlock;
      });

      // NOTE: This is a side-effect which updates the state of the undo/redo
      //  stack. It's not a desired pattern, but it was just noticed and needs
      //  to be fixed later.
      // eslint-disable-next-line no-param-reassign
      action.payload.masterNoteBlocks = newNoteBlocks;
      // eslint-disable-next-line no-param-reassign
      action.payload.conditionsById = newConditionsById;
      // eslint-disable-next-line no-param-reassign
      action.payload.noteBlocksById = newNoteBlocksById;

      return {
        ...state,
        bulletNoteStatusByBulletId,
        conditionsById: newConditionsById,
        masterNoteBlocks: newNoteBlocks,
        noteBlocksById: newNoteBlocksById,
      };
    }
    case 'format note titles': {
      const { format } = action.payload;

      const staleBulletNoteStatus = state.bulletNoteStatusByBulletId;
      const staleIndexingData = state.indexingData;
      const staleNoteBlocks = state.masterNoteBlocks;

      const { bulletNoteStatus, conditionsById, indexingData, noteBlocks, noteBlocksById } =
        indexHtmlNote({
          staleBulletNoteStatus,
          staleIndexingData,
          userAction: {
            type: 'formatNoteTitles',
            format,
            staleNoteBlocks,
          },
        });

      return {
        ...state,
        bulletNoteStatusByBulletId: bulletNoteStatus,
        conditionsById,
        indexingData,
        masterNoteBlocks: noteBlocks,
        noteBlocksById,
      };
    }
    case 'add a condition at index': {
      const { index } = action.payload;

      const staleBulletNoteStatus = state.bulletNoteStatusByBulletId;
      const staleIndexingData = state.indexingData;
      const staleNoteBlocks = state.masterNoteBlocks;

      const { bulletNoteStatus, conditionsById, indexingData, noteBlocks, noteBlocksById } =
        indexHtmlNote({
          staleBulletNoteStatus,
          staleIndexingData,
          userAction: {
            index,
            staleNoteBlocks,
            type: 'add',
          },
        });

      return {
        ...state,
        bulletNoteStatusByBulletId: bulletNoteStatus,
        conditionsById,
        indexingData,
        masterNoteBlocks: noteBlocks,
        noteBlocksById,
      };
    }
    case 'modify note block': {
      const staleBulletNoteStatus = state.bulletNoteStatusByBulletId;
      const staleIndexingData = state.indexingData;
      const staleNoteBlocks = state.masterNoteBlocks;

      const {
        bulletNoteStatus,
        conditionsById,
        indexingData,
        newDxIndicatorLineHidden,
        noteBlocks,
        noteBlocksById,
      } = indexHtmlNote({
        staleBulletNoteStatus,
        staleIndexingData,
        userAction: {
          ...action.payload,
          staleNoteBlocks,
        },
      });

      return {
        ...state,
        bulletNoteStatusByBulletId: bulletNoteStatus,
        conditionsById,
        indexingData,
        masterNoteBlocks: noteBlocks,
        noteBlocksById,
        ...(newDxIndicatorLineHidden // we only go false --> true
          ? { newDxIndicatorLineHidden: true }
          : undefined),
      };
    }
    case FETCH_PREV_PROPS_FOR_NEW_BASENOTE:
      return {
        ...state,
        isFetching: true,
      };
    case "completed the process of re-meshing today's Regard props with the new basenote":
      return {
        ...state,
        isFetching: false,
        newDxIndicatorLineHidden:
          action.payload.baseNoteData.resourceId === getBlankBaseNote().resourceId,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default fetchNote;
