import { EMPTY_LINE_BR_TAG_UPPER_CASE, LINE_DIVIDER_TAG_UPPER_CASE } from '~/app/constants';
import { zeroWidthCharacterMatcher, stripInvalidCharacters } from '~/app/utils';
import { CKEditorInstance } from './ckeditorTypes';

const END_OF_LINE_SYMBOL = '_NL_';
type ChildrenNode = ChildNode | typeof END_OF_LINE_SYMBOL;

/**
 * Counts every character from the start of the parent node up until the final child
 * has been reached.
 * New lines count as a character.
 */
export const getCharacterCountFromParentToChild = (
  parent: HTMLElement,
  finalChild: HTMLElement
): number => {
  let caretPos = 0;

  // Track the ChildNodes that still need to be traveresed.
  // ChildNodes are stored instead of Elements so that textNodes can be included
  let remainingNodes: ChildrenNode[] = Array.from(parent.childNodes) as ChildrenNode[];

  while (remainingNodes.length) {
    const currentNode = remainingNodes.shift();
    if (!currentNode) break;

    // Final Child Case: The final child has been reached.
    if (currentNode === finalChild) break;

    // End of Line Case: `END_OF_LINE_SYMBOL` is a special node symbol used to
    // denote the end of a line's children (aka, a new line is hit)
    if (currentNode === END_OF_LINE_SYMBOL) {
      caretPos += 1;
    }
    // Text Node Case: Text nodes are leafs, so their content length can be
    // added to the caretPos without having to worry about double counting
    // children content.
    else if (currentNode.nodeType === Node.TEXT_NODE) {
      caretPos += currentNode.textContent
        ? stripInvalidCharacters(currentNode.textContent).length
        : 0;
    }
    // Br Element Case:
    else if (
      currentNode.nodeType === Node.ELEMENT_NODE &&
      (currentNode as Element).tagName === EMPTY_LINE_BR_TAG_UPPER_CASE
    ) {
      caretPos += 1;
      // If a Br element was at the end of the line, ignore the new line symbol
      // so the new line isn't double counted. ie <div><br></div> should only count
      // as a single new line
      if (remainingNodes[0] === END_OF_LINE_SYMBOL) {
        remainingNodes.shift();
      }
    } else {
      // Node with Children Case: Add children nodes
      const children = Array.from(currentNode.childNodes) as ChildrenNode[];

      const isLineDividerElement =
        currentNode.nodeType === Node.ELEMENT_NODE &&
        (currentNode as Element).tagName === LINE_DIVIDER_TAG_UPPER_CASE;
      if (isLineDividerElement) {
        children.push(END_OF_LINE_SYMBOL);
      }

      if (children.length) {
        remainingNodes = [...children, ...remainingNodes];
      }
    }
  }

  return caretPos;
};

/**
 * CKEditor will tell us what element is selected and how many characters into
 * that element the caret is at, so we walk through each child depth first and
 * count all the characters along the way, totalling them until we reach the
 * caret.
 */
export const getCkeditorCaretPos = ({
  editor,
  parent,
}: {
  editor: CKEditorInstance;
  parent: HTMLElement;
}): number => {
  const selection = editor.getSelection();
  if (!selection) return 0;

  const startElement = selection.getStartElement()?.$;
  if (!startElement) return 0;

  let startOffset = editor.getSelectedRanges()?.[0]?.startOffset ?? 0;
  if (startOffset > 0) {
    // Zero width characters may show up in the start offset. Since any use of the caretPos
    // will be on html with zero width characters sanitized out, offset the offset by
    // any zero width characters found.
    const zeroWidthCharacterCount =
      (startElement.innerHTML || '').substring(0, startOffset).split(zeroWidthCharacterMatcher)
        .length - 1;
    if (zeroWidthCharacterCount > 0) startOffset -= zeroWidthCharacterCount;
  }

  const characterCountUpToStartElement = getCharacterCountFromParentToChild(parent, startElement);
  const caretPos = startOffset + characterCountUpToStartElement;
  return caretPos;
};
