import { SummaryP, SummarySpanB } from './common';

export const hypermagnesemiaSummary = (
  <>
    <SummaryP first>
      Regard diagnoses hypermagnesemia based on an abnormally high magnesium lab during the current
      encounter or a prior note within the encounter has stated that the patient has
      hypermagnesemia.
    </SummaryP>
    <SummaryP>
      To determine <SummarySpanB>trajectory</SummarySpanB> we use a threshold of 0.3, if the labs
      are within 0.3 md/dL of one another then it is considered stable. Otherwise we look for an
      increase or decrease to determine the trajectory.
    </SummaryP>
    <SummaryP>
      <SummarySpanB>Acuity</SummarySpanB> is determined by the following. Acute is if the last lab
      is abnormal or an acute source was found. Chronic if a chronic source was found. Acute on
      chronic if both of them are true.
    </SummaryP>
  </>
);
