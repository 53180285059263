import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const atrialFibSummary = (
  <>
    <SummaryP first>
      <SummarySpanB>Atrial fibrillation</SummarySpanB> or <SummarySpanB>atrial</SummarySpanB>{' '}
      flutter are recommended only if there is a prior history.
    </SummaryP>
    <SummaryP>The algorithm then checks the most recent heart rate.</SummaryP>
    <SummaryUl>
      <li>
        Heart rate greater than 110 will return positive for{' '}
        <SummarySpanB>rapid ventricular response</SummarySpanB> (RVR)
      </li>
      <li>
        <SummarySpanB>CHADS2VASC</SummarySpanB> score will then be calculated.
      </li>
      <li>Most recent INR and aPTT will be displayed along with any associated medications.</li>
    </SummaryUl>
  </>
);
