import { useEffect, useRef, useState } from 'react';

type IncrementingNumberOptions = {
  increment?: number;
  max?: number;
  delay?: number;
};

export const useIncrementingNumber = (parentValue: number, options?: IncrementingNumberOptions) => {
  const { increment = 1, max = 100, delay = 1000 } = options ?? {};

  const [incrementingValue, setIncrementingValue] = useState(parentValue);
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (parentValue <= incrementingValue) return;

    clearTimeout(timeoutRef.current);
    setIncrementingValue(parentValue);
  }, [parentValue, incrementingValue]);

  useEffect(() => {
    if (incrementingValue < max) {
      timeoutRef.current = window.setTimeout(
        () => setIncrementingValue(incrementingValue + increment),
        delay
      );
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [incrementingValue, increment, max, delay]);

  return incrementingValue;
};
