import { SummaryP } from './common';

export const hypernatremiaSummary = (
  <>
    <SummaryP first>
      Regard will check for the most recent abnormally elevated serum sodium lab during the current
      encounter. If there is more than one sodium lab present during the encounter the program will
      display trajectory based on the most recent value including if the condition was resolved.
    </SummaryP>
    <SummaryP>
      Regard will then display any associated medications (active and discontinued).
    </SummaryP>
  </>
);
