import { FC, memo, useCallback, useMemo } from 'react';
import { Popover, IconButton, StaticTooltip, theme } from '~/app/reuse';

import { CONDITION_UNDERLAY_BUTTON_COLOR, CONDITION_UNDERLAY_BUTTON_SIZE } from '../const';
import { useNoteBlockContext } from '../noteBlockProvider';
import { MoveMenu } from './MoveMenu';
import { useMoveCondition } from './useMoveCondition';

const contentStyle = {
  width: 200,
} as const;

const contentHeaderStyle = {
  padding: '5px 12px 5px 12px',
  borderBottom: `1px solid ${theme.colors.grey4}`,
  color: theme.colors.grey1,
  fontWeight: 600,
} as const;

const contentBodyStyle = {
  padding: '0 12px 0 12px',
  color: theme.colors.grey2,
  maxHeight: 316,
  overflow: 'auto',
} as const;

export const ConditionMoveMenu: FC = memo(() => {
  const {
    id,
    noteBlockRef,
    index,
    onMoveMenuButtonEnter,
    onMoveMenuButtonLeave,
    isMoveMenuOpen,
    setIsMoveMenuOpen,
  } = useNoteBlockContext(
    ({
      id,
      noteBlockRef,
      index,
      onMoveMenuButtonEnter,
      onMoveMenuButtonLeave,
      isMoveMenuOpen,
      setIsMoveMenuOpen,
    }) => ({
      id,
      noteBlockRef,
      index,
      onMoveMenuButtonEnter,
      onMoveMenuButtonLeave,
      isMoveMenuOpen,
      setIsMoveMenuOpen,
    })
  );

  const { moveMenuItems, moveCondition } = useMoveCondition({
    conditionRef: noteBlockRef,
    id,
  });

  const disabled = moveMenuItems.length <= 1;

  const onPlacementClick = useCallback(
    (toIndex: number) => {
      moveCondition(toIndex);
      if (!isMoveMenuOpen) return;
      setIsMoveMenuOpen(false);
    },
    [moveCondition, isMoveMenuOpen, setIsMoveMenuOpen]
  );

  const onIconClick = useCallback(() => {
    setIsMoveMenuOpen(!isMoveMenuOpen);
  }, [isMoveMenuOpen, setIsMoveMenuOpen]);

  const onOutsideClick = useCallback(() => {
    if (!isMoveMenuOpen) return;
    setIsMoveMenuOpen(false);
  }, [isMoveMenuOpen, setIsMoveMenuOpen]);

  const popoverContent = useMemo(
    () => (
      <div style={contentStyle}>
        <div style={contentHeaderStyle}>Select new placement block</div>
        <div style={contentBodyStyle}>
          <MoveMenu
            currentIndex={index}
            items={moveMenuItems}
            onPlacementClick={onPlacementClick}
          />
        </div>
      </div>
    ),
    [moveMenuItems, index, onPlacementClick]
  );

  return (
    <StaticTooltip disabled={disabled} display="inline-block" placement="top" tooltip="Move">
      <Popover
        content={popoverContent}
        isVisible={isMoveMenuOpen}
        onOutsideClick={onOutsideClick}
        placement="bottom-left"
      >
        <IconButton
          color={CONDITION_UNDERLAY_BUTTON_COLOR}
          data-cy-condition-move-menu-button
          disabled={disabled}
          iconAttributes={{ width: 12, height: 13, viewBox: '0 0 12 13' }}
          iconName="arrows"
          onClick={onIconClick}
          onMouseEnter={onMoveMenuButtonEnter}
          onMouseLeave={onMoveMenuButtonLeave}
          size={CONDITION_UNDERLAY_BUTTON_SIZE}
        />
      </Popover>
    </StaticTooltip>
  );
});
ConditionMoveMenu.displayName = 'ConditionMoveMenu';
