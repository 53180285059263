import { EditorImage } from './undoUtils';

/**
 * A noop undo plugin.
 * Regard uses undo/redo logic in redux, so any
 * additional undo/redo in CKE will conflict with it.
 * However, CKE relies on the undo plugin for sending 'change'
 * events, and ckeditor4-react's useCKEditor calls into resetUndo.
 * So, the entire plugin can't just be removed.
 */
CKEDITOR.plugins.add('regardUndo', {
  init(editor) {
    // A noop so that useCKEditor won't fail when called
    // eslint-disable-next-line no-param-reassign
    editor.resetUndo = () => null;

    // CKE relies on the undo plugin to fire change events for "undoable" actions.
    // So we similarly attach listeners for:
    //   onKeyup, saveSnapshot, afterCommandExec

    // https://github.com/ckeditor/ckeditor4/blob/master/plugins/undo/plugin.js#L80
    editor.on('contentDom', () => {
      const onKeyup = () => {
        editor.fire('change');
      };

      const editable = editor.editable();
      editable.attachListener(editable, 'keyup', onKeyup, undefined, 999);
    });

    // https://github.com/ckeditor/ckeditor4/blob/master/plugins/undo/plugin.js#L368
    let lastImage: EditorImage | undefined;
    const checkShouldFireChange = () => {
      const editorIsNotReady = editor.status !== 'ready' || editor.mode !== 'wysiwyg';
      if (editorIsNotReady) {
        return;
      }

      const editable = editor.editable();
      const cantEditEditor = !editable || editable.status !== 'ready';
      if (cantEditEditor) {
        return;
      }

      const image = new EditorImage(editor);
      if (image.contents === false) {
        return;
      }

      const shouldFireChange = !lastImage || !image.equalsContent(lastImage);

      if (shouldFireChange) {
        editor.fire('change');
      }

      lastImage = image;
    };

    editor.on('afterCommandExec', checkShouldFireChange);
    editor.on('saveSnapshot', checkShouldFireChange);
  },
});
