export const getTimeDifferenceInWords = (
  currentTime: DateNumberValue,
  previousTime: DateNumberValue
): string => {
  const ms = currentTime - previousTime;

  const minutes = Math.round(ms / 1000 / 60);
  if (minutes < 1) return '';
  if (minutes < 60) return `${minutes}m ago`;

  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h ago`;

  return 'over 1d ago';
};
