import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const obesitySummary = (
  <>
    <SummaryP first>
      Regard will collect height and weight measurements from this encounter and up to 26 weeks
      before it. Pair heights and weights by matching their timestamps and drop all unpaired values
      and outliers then calculate BMI. If the patient’s most recent BMI is &lt; 25 dx is{' '}
      <SummarySpanB>negative</SummarySpanB>. After classifying BMI, Regard will include
      complications and previous bariatric surgeries.
    </SummaryP>
    <SummaryP>
      Regard will classify and prompt diagnosis depending on BMI range of most recent elevated BMI
      (&ge; 25) with the following recommendation:
    </SummaryP>
    <SummaryUl>
      <li>
        30 &le; BMI &lt; 35 (obesity <SummarySpanB>class 1</SummarySpanB>)
      </li>
      <li>
        35 &le; BMI &lt; 40 (obesity <SummarySpanB>class 2</SummarySpanB>)
      </li>
      <li>
        40 &le; BMI (obesity <SummarySpanB>class 3</SummarySpanB>)
      </li>
      <li>
        If BMI is 35+ <SummarySpanB>and</SummarySpanB> there is a complication of obesity (e.g.
        hypertension, diabetes, etc.) then “<SummarySpanB>severe</SummarySpanB>” is added (i.e.
        “obesity, severe” or “obesity, class III, severe”)
      </li>
    </SummaryUl>
  </>
);
