import _ from 'lodash';
import { CSSProperties } from 'react';

import { theme } from '../../../reuse';

export const ANIMATION_DURATION = 1500;

const defaultBaseStyle = {
  bottom: 0,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
} as const;

export const getStyle = _.memoize(
  ({
    buttonHoveredStyle,
    defaultStyle,
    hoverStyle,
  }: {
    buttonHoveredStyle: CSSProperties | undefined;
    defaultStyle: CSSProperties | undefined;
    hoverStyle: CSSProperties | undefined;
  }): {
    finalButtonHoveredStyle: CSSProperties;
    finalDefaultStyle: CSSProperties;
    finalHoverStyle: CSSProperties;
    finalHighlightStyle: CSSProperties;
  } => {
    const finalDefaultStyle = {
      ...defaultBaseStyle,
      ...defaultStyle,
    };

    return {
      finalDefaultStyle,
      finalHoverStyle: {
        ...finalDefaultStyle,
        backgroundColor: theme.colors.lightBlue1,
        ...hoverStyle,
      },
      finalButtonHoveredStyle: {
        ...finalDefaultStyle,
        backgroundColor: theme.colors.lightBlue2,
        ...buttonHoveredStyle,
      },
      finalHighlightStyle: {
        ...finalDefaultStyle,
        animation: `blue-background-fade-in-out ${ANIMATION_DURATION}ms 1`,
      },
    };
  }
);
