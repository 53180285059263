import { deepEqual, shallowEqual } from 'fast-equals';
import { createSelectorHook } from 'react-redux';
import { createSelector as _createSelector } from 'reselect';

import { AppState } from './store';

export type UseSelectorFn = <R>(
  selector: (state: AppState) => R,
  equalityFn?: (previous: R, next: R) => boolean
) => R;

export const useSelector: UseSelectorFn = createSelectorHook<AppState>();

// NOTE: shallowEqual will compare the first level of keys between two objects.
export const useShallowEqualSelector: UseSelectorFn = (selector) =>
  useSelector(selector, shallowEqual);

// NOTE: shallowEqual will compare the all levels of keys between two objects.
//  USE WITH EXTREME CARE! This is an expensive operation!
export const useDeepEqualSelector: UseSelectorFn = (selector) => useSelector(selector, deepEqual);

export const createSelector = _createSelector as <T, U>(
  select: (state: AppState) => T,
  combine: (selected: T) => U
) => (state: AppState) => U;
