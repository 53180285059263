import { FC } from 'react';

import { MeshedNoteApp } from './MeshedNoteApp';
import { MeshedProvider } from './MeshedProvider';
import { RedirectToNotes } from './regard2Modal';

export const Meshed: FC<{
  disableFeedback?: boolean;
}> = ({ disableFeedback }) => (
  <MeshedProvider>
    <RedirectToNotes />
    <MeshedNoteApp disableFeedback={disableFeedback} />
  </MeshedProvider>
);
