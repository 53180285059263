import { ReactNode } from 'react';

import { useDemoData } from './useDemoData';
import { formatDateLong } from '../../../utils';

export type DemoMedicationRow = {
  items: ReactNode[];
};

const columns = [
  {
    fallback: '',
    key: 'formulation',
    label: 'Formulation',
  },
  {
    fallback: new Date(),
    key: 'orderTime',
    label: 'Order Date',
  },
  {
    fallback: new Date(),
    key: 'startTime',
    label: 'Start Date',
  },
  {
    fallback: new Date(),
    key: 'endTime',
    label: 'End Date',
  },
] as const;

const header: DemoMedicationRow = {
  items: columns.map(({ label }) => label),
};

const sanitize = (value: Date | string): string =>
  value instanceof Date ? formatDateLong(value.toISOString()) : value;

export const useDemoMedicationRows = (): {
  rows: DemoMedicationRow[];
} => {
  const medications = useDemoData({ category: 'medications' });

  const content = medications.map((medication) => ({
    items: columns.map(({ fallback, key }) => {
      const valueOrFallback = key ? medication[key] : fallback;
      const sanitizedValue = sanitize(valueOrFallback);

      return sanitizedValue;
    }),
  }));

  return { rows: [header, ...content] };
};
