import { MutableRefObject, useCallback } from 'react';

import { useMeshedContext } from '../../meshed/MeshedProvider';
import { openDxDetails, setLastClickedKeywordModule } from '../../../actions/ui';
import { track } from '../../../analytics';
import { tableButtonNotifications } from '../../../controllers/notifications';
import { useDispatch } from '../../../store';

const useOpenDxDetails = ({
  conditionId,
}: {
  conditionId: string;
}): ((args: { dxDetailsButtonClicked: boolean; selectedModule: string }) => void) => {
  const dispatch = useDispatch();

  const result = useCallback(
    ({
      selectedModule,
      dxDetailsButtonClicked,
    }: {
      selectedModule: string;
      dxDetailsButtonClicked: boolean;
    }) => {
      dispatch(
        openDxDetails({
          conditionId,
          dxDetailsButtonClicked,
          selectedModule,
        })
      );
    },
    [conditionId, dispatch]
  );

  return result;
};

const conditionTitleIsVisible = (condition: Element | null) => {
  const conditionContainer = condition?.closest('[data-note-container-scroll-wrapper]');

  if (condition && conditionContainer) {
    const childTop = condition.getBoundingClientRect().top;
    const { height: parentHeight, top: parentTop } = conditionContainer.getBoundingClientRect();

    return childTop - parentTop < parentHeight - 38;
  }

  return false;
};

export const useOpenDxDetailsFromNote = ({
  conditionId,
  conditionRef,
  modules,
}: {
  conditionId: string;
  conditionRef: MutableRefObject<HTMLDivElement | null>;
  modules: string[];
}) => {
  const {
    dxDetailsVisible,
    isVerticalLayout,
    lastClickedKeywordModule,
    selectedModule: lastSelectedModule,
  } = useMeshedContext(
    ({ dxDetailsVisible, isVerticalLayout, lastClickedKeywordModule, selectedModule }) => ({
      dxDetailsVisible,
      lastClickedKeywordModule,
      isVerticalLayout,
      selectedModule,
    })
  );

  const openDxDetails = useOpenDxDetails({ conditionId });

  const modulesString = modules.join(',');

  const useOpenDxDetailsFromNote = useCallback(
    ({
      location,
      selectedModule,
    }: {
      location: 'titleButton' | 'titleKeyword';
      selectedModule: string;
    }) => {
      const hasUpdates = tableButtonNotifications[selectedModule];

      const couldBeOpeningTabPanel =
        selectedModule === lastClickedKeywordModule && !dxDetailsVisible;

      // Only send tracking call if DxDetails are visible and the module is changing
      // or dxDetails are about to be visible
      if ((dxDetailsVisible && selectedModule !== lastSelectedModule) || couldBeOpeningTabPanel) {
        track.openedDXDetails({
          detailsOpenedFor: selectedModule,
          hasUpdatedHiddenBullets: hasUpdates,
          location,
          modulesString,
          selectedModule,
        });
      }

      const dxDetailsButtonClicked = location === 'titleButton';

      openDxDetails({ selectedModule, dxDetailsButtonClicked });

      // If Dx Details is displaying on the bottom of the screen (rather than
      //  the side), we need to scroll to to the condition to make sure it's
      //  still visible after the details are opened!
      const condition = conditionRef.current;
      if (isVerticalLayout && condition) {
        setTimeout(
          () => {
            const isScrollingNecessary =
              location === 'titleButton' || !conditionTitleIsVisible(condition);

            if (isScrollingNecessary) condition.scrollIntoView({ behavior: 'smooth' });
          },
          // I'm not sure why, but this needs a timeout to get the scrolling to
          //  work in webkit. Works fine in IE without the timeout.
          // ALSO, we now need the timeout because we need to wait for the change
          //  in height of the note container when the dx details are opened,
          //  before knowing whether our clicked title keyword is out of view.
          0
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      conditionRef,
      dxDetailsVisible,
      isVerticalLayout,
      lastClickedKeywordModule,
      modulesString,
      openDxDetails,
      lastSelectedModule,
    ]
  );

  return useOpenDxDetailsFromNote;
};

export const useSetLastClickedKeywordModule = (): ((args: { moduleClicked: string }) => void) => {
  const dispatch = useDispatch();

  const result = useCallback(
    ({ moduleClicked }: { moduleClicked: string }) => {
      dispatch(setLastClickedKeywordModule(moduleClicked));
    },
    [dispatch]
  );

  return result;
};
