import { FC } from 'react';

import {
  subtitleButtonStyle,
  subtitleTextStyle,
  subtitleTextStyleNoButton,
  textStyle,
} from '../css';
import { NoteContainerHeaderTitle } from '../NoteContainerHeaderTitle';
import { LinkButton } from '../../../../reuse';
import { COMPANY_NAME } from '../../../../constants';
import { formatDatetime, formatDateForNoteHeader } from '../../../../utils';

import { SaveDraftNoteStatus } from '../saveDraftNoteStatus/SaveDraftNoteStatus';

export const NoteContainerHeaderDefaultTitle: FC<{
  baseNoteTimestamp: ISODateString | undefined;
  disabled: boolean;
  onStart(): void;
  rawNoteType: string;
  baseNotesCount: number;
  timestamp: ISODateString;
}> = ({ baseNoteTimestamp, disabled, onStart, rawNoteType, baseNotesCount, timestamp }) => {
  const formattedTimestamp = timestamp
    ? formatDateForNoteHeader({ datetime: timestamp, timeZone: window.providerTimezone })
    : 'moments ago';

  const blankBaseNoteText = baseNotesCount
    ? `Blank note (only ${COMPANY_NAME} Dxs)`
    : 'No previous notes found';

  return (
    <NoteContainerHeaderTitle
      subtitle={
        <>
          <span
            data-cy-cyob-note-header
            style={baseNotesCount ? subtitleTextStyle : subtitleTextStyleNoButton}
          >
            {baseNoteTimestamp
              ? `${formatDatetime(baseNoteTimestamp)} - ${rawNoteType}`
              : blankBaseNoteText}
          </span>
          {baseNotesCount ? (
            <LinkButton
              data-cy-cyob-change-basenote-button
              disabled={disabled}
              onClick={onStart}
              style={subtitleButtonStyle}
            >
              Change base note
            </LinkButton>
          ) : undefined}
        </>
      }
      title={
        <>
          <span style={textStyle}>Updated {formattedTimestamp}</span>
          <SaveDraftNoteStatus />
        </>
      }
    />
  );
};
