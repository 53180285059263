import { useCallback } from 'react';
import { useDispatch } from '~/app/store';

export const useShelveCondition = (): ((conditionId: string) => void) => {
  const dispatch = useDispatch();

  const shelveCondition = useCallback(
    (conditionId: string) => {
      dispatch({
        type: 'modify note block',
        payload: {
          conditionId,
          type: 'shelve',
        },
      });
    },
    [dispatch]
  );

  return shelveCondition;
};
