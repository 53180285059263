import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FieldList from './FieldList';
import { PATIENT_INFO_DISPLAY_FIELDS } from './screens';

const PatientInfo = ({ patientInfoFieldValues }) => (
  <div className="htemr-PatientInfo">
    <FieldList
      className="htemr-PatientInfo-fields"
      fields={PATIENT_INFO_DISPLAY_FIELDS}
      fieldValues={patientInfoFieldValues}
    />
  </div>
);

PatientInfo.propTypes = {
  patientInfoFieldValues: PropTypes.shape({}),
};

PatientInfo.defaultProps = {
  patientInfoFieldValues: null,
};

const mapStateToProps = (state) => ({
  patientInfoFieldValues: state.record.data.patientInfo,
});

export default connect(mapStateToProps, null)(PatientInfo);
