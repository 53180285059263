import { theme } from '../../../reuse';

export const suggestionsStyle = {
  paddingTop: 4,
  position: 'relative',
} as const;

export const suggestionsBorderStyle = {
  borderTop: `1px dashed ${theme.colors.grey2}`,
  height: 1,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 1,
} as const;

export const innerStyle = {
  position: 'relative',
} as const;
