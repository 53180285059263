import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import 'normalize.css';

import amplitude, { setProviderAndUserFullName } from '../analytics/amplitude';
// TODO: RND-1407 - Turn back on appcues
// import { initializeAppcues } from '../analytics/appcues';
import { VERSION } from '../version';

import '../subscriptions';
import { getOAuthSubjectOrAnonymousId, getUserReadOnly } from '../cookies';
import { isDev } from '../flags';
import { MissingRequiredConfigValues } from '../errors';

declare global {
  interface Window {
    AMPLITUDE_API_KEY: string;
    AMPLITUDE_SERVER_URL?: string;
    APPCUES_ACCOUNT_ID: string;
    DEBUG_LOGGING_TO_SENTRY: boolean;
    ENV: string;
    MAINTENANCE?: boolean;
    PRESERVE_CACHED_NOTE: string;
    SENTRY_DSN: string;
  }
}

if (!window.URLSearchParams) {
  window.URLSearchParams = URLSearchParams;
}

const requiredConfigKeys = [
  'AMPLITUDE_API_KEY',
  // 'AMPLITUDE_SERVER_URL', // Defaults to '/amplitude/2/httpapi' if not provided, so not required
  // TODO: RND-1407 - Turn back on appcues
  // 'APPCUES_ACCOUNT_ID',
  'INFLUX_HT_BUCKET',
  'INFLUX_HT_ORG',
  'INFLUX_HT_TOKEN',
  'INFLUX_URL',
  'SENTRY_DSN',
] as const;

const assertRequiredConfigValues = () => {
  if (isDev) return;

  const missingKeys = requiredConfigKeys.filter((key) => !window[key]);

  if (!missingKeys.length) return;

  Sentry.withScope((scope) => {
    scope.setExtra('missingKeys', missingKeys);
    Sentry.captureException(new MissingRequiredConfigValues(missingKeys.join(', ')));
  });
};

const initializeSentry = () => {
  const shouldLoadSentry = window.SENTRY_DSN && !getUserReadOnly();
  if (!shouldLoadSentry) return;

  Sentry.init({
    beforeSend: (event) => {
      // Get all cookies as a string
      const cookies = document.cookie;

      // Split the string into individual cookies
      const cookieArray = cookies.split(';');

      // Create a map of cookie names to the length of that cookie's string
      const cookieNameToLength = cookieArray.reduce((acc, cookie) => {
        const [name, value] = cookie.split('=');

        acc[name.trim()] = value ? value.length : 0; // If the cookie has a value, get its length, else 0

        return acc;
      }, {} as Record<string, number>);

      // Attach cookies to every event
      // eslint-disable-next-line no-param-reassign
      event.extra = {
        ...event.extra,
        cookieNameToLength,
      };

      return event;
    },
    dsn: window.SENTRY_DSN,
    release: VERSION,
    integrations: [
      new CaptureConsole({
        levels: window.DEBUG_LOGGING_TO_SENTRY
          ? ['debug', 'assert', 'log', 'neutral', 'warn', 'error']
          : ['error'],
      }),
    ],
  });

  const oauthSub = getOAuthSubjectOrAnonymousId();
  Sentry.setUser({ id: JSON.stringify(oauthSub) });
};

const getHostUserId = () => {
  const id = getOAuthSubjectOrAnonymousId();

  // Amplitude requires a length of at least 5 for the id, but some sites have 4 character
  // long ids. Adding the site name makes sure it's always over this requirment.
  const hostName = window.location.hostname;
  return `${hostName}_${id}`;
};

const initializeAmplitude = () => {
  amplitude.setUserId(getHostUserId());
  setProviderAndUserFullName();
};

export const initializeExternalLibraries = () => {
  initializeSentry();

  // Checked after Sentry is initialized so we can log any errors
  assertRequiredConfigValues();

  initializeAmplitude();

  // TODO: RND-1407 - Turn back on appcues
  // initializeAppcues();
};
