import { EpicTab } from './EpicTab';
import { epicTheme } from './epicTheme';
import { Meshed } from '../../meshed/Meshed';
import { COMPANY_NAME } from '../../../constants';

export const epicRegardTab = (
  <EpicTab
    accentColor={epicTheme.colors.accentLightBlue}
    isSelected={false}
    label={COMPANY_NAME}
    style={{
      padding: 0,
    }}
  >
    <div
      style={{
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
        height: '100%',
      }}
    >
      <Meshed />
    </div>
  </EpicTab>
);
