import Color from 'color';
import _ from 'lodash';

import { theme } from '../../../reuse';

export const tooltipCss = {
  '&.is--hover': {
    '[data-spec-checker-text]': {
      backgroundColor: theme.colors.gold,
    },
  },
  '.tippy-box': {
    left: '-8px !important',
    transform: 'translateY(-100%) translateY(-12px) !important',
    '.tippy-arrow': {
      left: '0 !important',
      transform: 'translateX(50%) !important',
    },
  },
} as const;

const getTextBackgroundColor = ({
  selected,
  type,
}: {
  selected: boolean;
  type: 'specChecker' | 'title';
}): string => {
  if (type === 'specChecker') {
    return Color(theme.colors.gold).alpha(0.5).string();
  }

  return selected ? theme.colors.lightBlue2 : '';
};

const siderlineBaseStyle = {
  background: theme.colors.blueRegular,
  bottom: -1,
  position: 'absolute',
  top: 0,
  width: 2,
} as const;

const siderlineHookBaseStyle = {
  background: theme.colors.blueRegular,
  content: '""',
  height: 1,
  position: 'absolute',
  top: 0,
  width: 2,
} as const;

export const getLefterlineCss = _.memoize(
  ({ selected }: { selected: boolean }) =>
    ({
      ...siderlineBaseStyle,
      left: -2,
      visibility: selected ? 'visible' : 'hidden',
      '&:before': {
        ...siderlineHookBaseStyle,
        right: -2,
      },
    } as const)
);

export const getRighterlineCss = _.memoize(
  ({ selected }: { selected: boolean }) =>
    ({
      ...siderlineBaseStyle,
      right: -2,
      visibility: selected ? 'visible' : 'hidden',
      '&:before': {
        ...siderlineHookBaseStyle,
        left: -2,
      },
    } as const)
);

export const getTextCss = _.memoize(
  ({ selected, type }: { selected: boolean; type: 'specChecker' | 'title' }) =>
    ({
      backgroundColor: getTextBackgroundColor({ selected, type }),
      position: 'relative',
    } as const)
);

export const getUnderlineStyle = _.memoize(
  ({ type }: { type: 'specChecker' | 'title' }) =>
    ({
      borderBottom: `2px ${type === 'specChecker' ? 'dashed' : 'solid'} ${
        theme.colors.blueRegular
      }`,
      bottom: -1,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    } as const)
);
