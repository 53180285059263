import _ from 'lodash';

import { ChunkOfLines } from '~/app/@types/state';
import { EMPTY_BR_HTML_LINE } from '~/app/constants';
import { htmlStringToPlainText } from '~/app/utils';
import { FOOTER_MARKER } from '~/app/controllers/regex';

export const getHtmlFooterIndicies = ({
  chunksWithLines,
  isAtLastIndex,
}: {
  chunksWithLines: Pick<ChunkOfLines, 'lines'>[];
  isAtLastIndex: boolean;
}): {
  footerChunkIndex: number;
  footerLineIndex: number;
  whitespaceFooter: boolean;
} => {
  // 0. Check for the isAtLastIndex case, where we're typing in the last
  //  condition area in the note
  if (isAtLastIndex) {
    let firstFooterMarkerChunkIndex = -1;
    let firstFooterMarkerLineIndex = -1;
    let thereIsANonFooterTitleBeforeTheFooter = false;

    chunksWithLines.some(({ lines }, chunkIndex) =>
      // when the following line returns `true` the `some` loop will stop
      lines.some((line, lineIndex) => {
        const isFooterMarker =
          (line.type === 'title' && line.plainTextWithoutTitleSignifier.match(FOOTER_MARKER)) ||
          (line.type === 'freetext' && htmlStringToPlainText(line.html).match(FOOTER_MARKER));

        if (!thereIsANonFooterTitleBeforeTheFooter && line.type === 'title' && !isFooterMarker) {
          thereIsANonFooterTitleBeforeTheFooter = true;
        } else if (isFooterMarker) {
          firstFooterMarkerChunkIndex = chunkIndex;
          firstFooterMarkerLineIndex = lineIndex;
          // stop the inner `some` loop
          return true;
        }
        return false;
      })
    );

    return thereIsANonFooterTitleBeforeTheFooter
      ? // we need to split a new condition out of the footer
        {
          footerChunkIndex: firstFooterMarkerChunkIndex,
          footerLineIndex: firstFooterMarkerLineIndex,
          whitespaceFooter: false,
        }
      : // it's all the footer
        {
          footerChunkIndex: 0,
          footerLineIndex: 0,
          whitespaceFooter: false,
        };
  }

  // 1. Prepare the result; we need a mutable object because we'll be returning
  //  the match from the previous iteration of the loop
  const result = {
    footerChunkIndex: -1, // -1 means no match
    footerLineIndex: -1,
    whitespaceFooter: false,
  };

  const lastChunkIndex = chunksWithLines.length - 1;

  // 2. Start from the bottom and work our way up the note chunks
  for (let footerChunkIndex = lastChunkIndex; footerChunkIndex >= 0; footerChunkIndex--) {
    // 3. Get the lines from the current chunk
    const { lines } = chunksWithLines[footerChunkIndex];

    // 4. Check for a footer marker at the beginning of each...
    const footerLineIndex = lines.findIndex(
      (line) =>
        // 4a. ...title line
        (line.type === 'title' && line.plainTextWithoutTitleSignifier.match(FOOTER_MARKER)) ||
        // 4b. ...freetext (bullet lines cannot be the top of the footer. we should assume a
        //                  bullet line is associated with the condition title above it)
        (line.type === 'freetext' && htmlStringToPlainText(line.html).match(FOOTER_MARKER))
    );

    if (footerLineIndex > -1) {
      // 5. Record match
      result.footerChunkIndex = footerChunkIndex;
      result.footerLineIndex = footerLineIndex;
    } else {
      // 6. If no footer was found, attempt to identify a whitespace footer
      if (lines.length && footerChunkIndex === lastChunkIndex) {
        const lastNonEmptyLineIndex = _.findLastIndex(
          lines,
          ({ html }) => html !== EMPTY_BR_HTML_LINE
        );

        if (lastNonEmptyLineIndex === -1) {
          // 6a. This chunk is all whitespace!
          result.footerChunkIndex = lastChunkIndex;
          result.footerLineIndex = 0;
          result.whitespaceFooter = true;
        } else if (
          lastNonEmptyLineIndex + 2 < // + 2 is two empty lines
          lines.length
        ) {
          // 6b. There were two or more whitespace lines at th end of the last
          //  chunk; we can consider these the footer
          result.footerChunkIndex = lastChunkIndex;
          // + 2 is two empty lines
          result.footerLineIndex = lastNonEmptyLineIndex + 2;
          result.whitespaceFooter = true;
        }
      }

      // 7. Footer no longer detected in adjacent chunks; abort mission
      break;
    }
  }

  return result;
};
