import { NotificationLevel } from '~/app/constants';
import { NotificationAttrs, SimpleCondition } from '~/app/@types/state';
import { NotificationType } from '~/app/@types';
import { interpretationIsCritical } from '~/app/utils';

import { bulletHasChanged } from '../bulletHasChanged';
import { createNotification } from './createNotification';

export const addCriticalConditionsNotification = (
  notifications: NotificationAttrs[],
  conditions: Record<string, SimpleCondition>
) => {
  let numConditionsWithCriticalObservations = 0;

  Object.entries(conditions).forEach(([, condition]) => {
    if (condition.negative) return;

    const hasCriticalBullet = condition.bullets.some(
      (bullet) =>
        bulletHasChanged(bullet.diff) &&
        interpretationIsCritical('interpretation' in bullet ? bullet.interpretation : undefined)
    );
    if (hasCriticalBullet) {
      numConditionsWithCriticalObservations += 1;
    }
  });

  if (!numConditionsWithCriticalObservations) return;

  const multiple = numConditionsWithCriticalObservations > 1;
  const content = `${numConditionsWithCriticalObservations} ${
    multiple ? 'diagnoses' : 'diagnosis'
  } ${multiple ? 'have' : 'has'} new critical results`;
  const buttonFunc = () =>
    document
      .querySelector('[data-condition-is-critical=true]')
      ?.scrollIntoView({ behavior: 'smooth' });

  const notification = createNotification({
    type: NotificationType.CriticalDiagnosis,
    level: NotificationLevel.Critical,
    content,
    buttonText: 'View',
    buttonFunc,
    retainOnButtonClick: true,
    allowDismissal: true,
  });
  notifications.push(notification);
};
