import { forwardRef } from 'react';

import { Root } from './css';
import { ButtonColorCSS, ButtonSizeCSS } from '../button/css';
import { ButtonColor, ButtonSize } from '../button/types';
import { InputTagProps } from '../inputTag';

// NOTE: This component was created with the intention of testing whether
//  `input type="submit"` works better with dragon than `button type="submit"`.

export type InputSubmitButtonProps = InputTagProps & {
  color: ButtonColor | ButtonColorCSS;
  size: ButtonSize | ButtonSizeCSS;
};

export const InputSubmitButton = forwardRef<HTMLInputElement, InputSubmitButtonProps>(
  (props, ref) => <Root ref={ref} type="submit" {...props} />
);
InputSubmitButton.displayName = 'InputSubmitButton';
