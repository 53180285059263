import { getFlagFromLocalStorageOrWindow } from '../utils/localStorageFlags';
import {
  UnleashClient,
  flagHasVariant,
  getFlagVariantValue,
  unleashClient as regardUnleashClient,
} from './unleash';

interface GetFlagValueOptions<K extends keyof Flags> {
  getFlagValue?: (key: K) => Flags[K] | undefined;
  defaultValue?: Flags[K] | undefined;
}

const defaultOptions = {
  getFlagValue: getFlagFromLocalStorageOrWindow,
  defaultValue: undefined,
} as const;

export const getFlagValue = <K extends keyof Flags>(
  flagKey: K,
  options: GetFlagValueOptions<K> = {},
  unleashClient: UnleashClient = regardUnleashClient
): Flags[K] | undefined => {
  const { getFlagValue, defaultValue } = { ...defaultOptions, ...options };

  const localValue = getFlagValue(flagKey);
  if (localValue !== undefined) {
    return localValue;
  }

  const shouldCheckUnleash = unleashClient && unleashClient.isEnabled(flagKey);
  if (shouldCheckUnleash) {
    const variant = unleashClient.getVariant(flagKey);
    const hasVariant = flagHasVariant(variant);
    if (hasVariant) {
      const variantValue = getFlagVariantValue(flagKey, variant);
      return variantValue;
    }
  }

  return defaultValue;
};

export const createGetFlagValueGetter =
  <K extends keyof Flags>(
    flagKey: K,
    options?: GetFlagValueOptions<K>,
    unleashClient?: UnleashClient
  ): (() => Flags[K] | undefined) =>
  () =>
    getFlagValue(flagKey, options, unleashClient);
