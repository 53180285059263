import { FC } from 'react';

import { NoteContainerHeaderTitle } from './NoteContainerHeaderTitle';
import { theme } from '../../../reuse';

const style = {
  color: theme.colors.grey2,
};

export const SelectBaseNoteTitle: FC<{
  numberOfAvailableBaseNotes: number;
}> = ({ numberOfAvailableBaseNotes }) => (
  <NoteContainerHeaderTitle
    subtitle={
      <span style={style}>
        ({numberOfAvailableBaseNotes} note{numberOfAvailableBaseNotes === 1 ? '' : 's'} available)
      </span>
    }
    title="Select base note"
  />
);
