import { UiState } from '../@types/state';
import { ReceiveNoteAction, RemeshedWithNewBaseNote } from '../actions/regardNote';
import {
  CHANGE_TAB,
  REMOVE_NOTIFICATION,
  OPEN_RESOURCES,
  CLOSE_RESOURCES,
  UiAction,
} from '../actions/ui';
import { isAiChatEnabled } from '../flags';
import { NULL_DATE_VALUE } from '../utils';

const tabs: ('Max' | 'Dx Details')[] = [
  ...(isAiChatEnabled ? (['Max'] as const) : []),
  'Dx Details',
];

const getClosedTabPanelState = (
  currentTabIndex: number
): Pick<UiState, 'tabIndex' | 'tabPanelOpen' | 'tabs'> => ({
  tabIndex: Math.max(currentTabIndex - 1, 0),
  tabPanelOpen: false,
  tabs,
});

const initState: UiState = {
  feedbackOpen: false,
  resourcesOpen: false,
  clearNoteDraftModalIsOpen: false,
  dxDetailsOpenConditionId: '',
  dxDetailsSelectedModule: '',
  notifications: [],
  openSource: undefined,
  openDxProcess: undefined,
  lastClickedKeywordModule: '',
  lastFocusedEditor: '',
  reduxReplayVersion: '',
  saveDraftNoteApiTimestamp: NULL_DATE_VALUE,
  saveDraftNoteLastSaved: NULL_DATE_VALUE,
  saveDraftNoteStatus: 'saved',
  showApplicationVersion: false,
  isScaledHorizontalLayout: false,
  regard2ModalOpen: false,
  ...getClosedTabPanelState(0),
};

function uiReducer(
  // eslint-disable-next-line default-param-last
  state = initState,
  action: UiAction | ReceiveNoteAction | RemeshedWithNewBaseNote
): UiState {
  switch (action.type) {
    case 'set last module clicked in note': {
      const sameModuleKeywordClickedTwice = action.payload === state.lastClickedKeywordModule;
      const tabPanelOpen = sameModuleKeywordClickedTwice || state.tabPanelOpen;
      return {
        ...state,
        lastClickedKeywordModule: action.payload,
        tabPanelOpen,
      };
    }
    case 'close tab panel': {
      return {
        ...state,
        openSource: undefined,
        tabPanelOpen: false,
        lastClickedKeywordModule: '',
      };
    }
    case 'open tab panel': {
      return {
        ...state,
        tabPanelOpen: true,
      };
    }
    case 'close dx details': {
      return {
        ...state,
        dxDetailsOpenConditionId: '',
        dxDetailsSelectedModule: '',
        openSource: undefined,
        ...getClosedTabPanelState(state.tabIndex),
      };
    }
    case 'open dx details': {
      let { tabs } = state;
      let dxDetailsTabIndex = tabs.findIndex((tab) => tab === 'Dx Details');
      if (dxDetailsTabIndex < 0) {
        dxDetailsTabIndex = 0;
        tabs = ['Dx Details', ...tabs];
      }

      return {
        ...state,
        dxDetailsOpenConditionId: action.payload.conditionId,
        dxDetailsSelectedModule: action.payload.selectedModule,
        openSource: undefined,
        openDxProcess: undefined,
        tabIndex: dxDetailsTabIndex,
        tabPanelOpen: action.payload.dxDetailsButtonClicked || state.tabPanelOpen,
        tabs,
      };
    }
    case 'select dx details tab': {
      return {
        ...state,
        dxDetailsSelectedModule: action.payload.selectedModule,
        openSource: undefined,
      };
    }
    case 'open source':
      return {
        ...state,
        openSource: action.payload,
      };
    case 'clear open source':
      return {
        ...state,
        openSource: undefined,
      };
    case 'open dx process':
      return {
        ...state,
        openDxProcess: action.payload,
      };
    case 'clear open dx process':
      return {
        ...state,
        openDxProcess: undefined,
      };
    case 'open regard2 modal':
      return {
        ...state,
        regard2ModalOpen: true,
      };
    case 'close regard2 modal':
      return {
        ...state,
        regard2ModalOpen: false,
      };
    case 'set last focused editor':
      return {
        ...state,
        lastFocusedEditor: action.payload,
      };
    case 'set save draft note status':
      return {
        ...state,
        // Protect against race conditions by comparing timestamps
        ...(action.payload.saveDraftNoteApiTimestamp >= state.saveDraftNoteApiTimestamp
          ? {
              saveDraftNoteStatus: action.payload.saveDraftNoteStatus,
              saveDraftNoteApiTimestamp: action.payload.saveDraftNoteApiTimestamp,
            }
          : undefined),
        // If we completed a save, always update saveDraftNoteLastSaved value
        ...(action.payload.saveDraftNoteStatus === 'saved'
          ? {
              saveDraftNoteLastSaved: new Date().valueOf(),
            }
          : undefined),
      };
    case REMOVE_NOTIFICATION:
      state.notifications.splice(action.index, 1);
      return {
        ...state,
        notifications: state.notifications.slice(),
      };
    case "completed the process of re-meshing today's Regard props with the new basenote":
    case 'successfully received /doc response for patient': {
      // Set notifications after regardNote's meshing or re-meshing process has finished
      // Also setup default Dx Details View if there is at least 1 un-dismissed regard condition
      const firstConditionWithModules = action.payload.masterNoteBlocks.find(
        (section) => 'modules' in section && section.modules.length
      );
      const firstModuleInNote =
        firstConditionWithModules &&
        'modules' in firstConditionWithModules &&
        firstConditionWithModules.modules[0];
      if (firstModuleInNote) {
        return {
          ...state,
          notifications: action.payload.notifications,
          tabIndex: 0,
          tabs,
          dxDetailsSelectedModule: firstModuleInNote,
          dxDetailsOpenConditionId: firstConditionWithModules.id,
          openSource: undefined,
          openDxProcess: undefined,
        };
      }
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    }
    case OPEN_RESOURCES:
      return { ...state, resourcesOpen: true, openDxProcess: undefined };
    case CLOSE_RESOURCES:
      return {
        ...state,
        resourcesOpen: false,
        openDxProcess: undefined,
      };
    case 'close user feedback widget':
      return { ...state, feedbackOpen: false };
    case 'open user feedback widget':
      return { ...state, feedbackOpen: true };
    case CHANGE_TAB:
      return { ...state, tabIndex: action.index };
    case 'show application version':
      return {
        ...state,
        reduxReplayVersion: action.payload.reduxReplayVersion ?? '',
        showApplicationVersion: true,
      };
    case 'open clear note draft modal':
      return { ...state, clearNoteDraftModalIsOpen: true };
    case 'close clear note draft modal':
      return { ...state, clearNoteDraftModalIsOpen: false };
    default:
      return state;
  }
}

export default uiReducer;
