import { shallowEqual } from 'fast-equals';
import { noop } from 'lodash';
import { FC, MutableRefObject, createContext, memo, useRef, useState } from 'react';

import { createSelectorProvider, omit, useContextSelector } from '~/app/utils';
import { useConditionButtonsHover } from './useConditionButtonsHover';
import { useConditionHighlighted } from './useConditionHighlighted';
import { useScrollToNoteBlock } from './useScrollToNoteBlock';
import { useCopyNoteBlock } from './useCopyNoteBlock';

export type NoteBlockContext = {
  buttonHovered: boolean;
  copyButtonHovered: boolean;
  copyNoteBlock(): void;
  dismissButtonHovered: boolean;
  highlighted: boolean;
  highlightedKey: number;
  id: string;
  index: number;
  isCopied: boolean;
  reverseIndex: number;
  onCopyButtonEnter(): void;
  onCopyButtonLeave(): void;
  onDismissButtonEnter(): void;
  onDismissButtonLeave(): void;
  onShelveButtonEnter(): void;
  onShelveButtonLeave(): void;
  onMoveMenuButtonEnter(): void;
  onMoveMenuButtonLeave(): void;
  isMoveMenuOpen: boolean;
  noteBlockRef: MutableRefObject<HTMLDivElement | null>;
  setIsMoveMenuOpen(isOpen: boolean): void;
  type: 'condition' | 'footer' | 'shelvedCondition' | 'shelfDivider';
};

const defaultNoteBlockContext: NoteBlockContext = {
  buttonHovered: false,
  copyButtonHovered: false,
  copyNoteBlock: noop,
  dismissButtonHovered: false,
  highlighted: false,
  highlightedKey: 0,
  id: '',
  index: -1,
  isCopied: false,
  reverseIndex: -1,
  onCopyButtonEnter: noop,
  onCopyButtonLeave: noop,
  onDismissButtonEnter: noop,
  onDismissButtonLeave: noop,
  onShelveButtonEnter: noop,
  onShelveButtonLeave: noop,
  onMoveMenuButtonEnter: noop,
  onMoveMenuButtonLeave: noop,
  isMoveMenuOpen: false,
  noteBlockRef: { current: null },
  setIsMoveMenuOpen: noop,
  type: 'condition',
};

const NoteBlockContext = createContext<NoteBlockContext>(defaultNoteBlockContext);
const NoteBlockContextProvider = createSelectorProvider(NoteBlockContext);

export const useNoteBlockContext = <T,>(selector: (context: NoteBlockContext) => T): T =>
  useContextSelector<NoteBlockContext, T>(NoteBlockContext, selector);

export const NoteBlockProvider: FC<
  Pick<NoteBlockContext, 'id' | 'index' | 'reverseIndex' | 'type'> & {
    triggerHighlight: number | undefined;
  }
> = memo(
  ({ children, id, index, reverseIndex, triggerHighlight, type }) => {
    const {
      buttonHovered,
      copyButtonHovered,
      dismissButtonHovered,
      onCopyButtonEnter,
      onCopyButtonLeave,
      onDismissButtonEnter,
      onDismissButtonLeave,
      onShelveButtonEnter,
      onShelveButtonLeave,
      onMoveMenuButtonEnter,
      onMoveMenuButtonLeave,
    } = useConditionButtonsHover();

    const { copyNoteBlock, isCopied } = useCopyNoteBlock(id);

    const { highlighted, highlightedKey } = useConditionHighlighted(triggerHighlight);

    const [isMoveMenuOpen, setIsMoveMenuOpen] = useState<boolean>(false);

    const noteBlockRef = useRef<HTMLDivElement>(null);
    useScrollToNoteBlock({ noteBlockRef, id });

    return (
      <NoteBlockContextProvider
        value={{
          buttonHovered,
          copyButtonHovered,
          copyNoteBlock,
          dismissButtonHovered,
          highlighted,
          highlightedKey,
          id,
          index,
          isCopied,
          reverseIndex,
          isMoveMenuOpen,
          setIsMoveMenuOpen,
          noteBlockRef,
          onCopyButtonEnter,
          onCopyButtonLeave,
          onDismissButtonEnter,
          onDismissButtonLeave,
          onShelveButtonEnter,
          onShelveButtonLeave,
          onMoveMenuButtonEnter,
          onMoveMenuButtonLeave,
          type,
        }}
      >
        {children}
      </NoteBlockContextProvider>
    );
  },
  /**
   * This is an optimization.
   *
   * We prevent children from triggering a rerender because children will
   *  always trigger a rerender--it defeats the memo function and is not useful
   *  in this respect.
   *
   */
  (prevProps, nextProps) => shallowEqual(omit(prevProps, 'children'), omit(nextProps, 'children'))
);
NoteBlockProvider.displayName = 'NoteBlockProvider';
