import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';
import { getUserFullName, getUserReadOnly } from '../cookies';
import { getEnv } from '../flags';

declare global {
  interface Window {
    AMPLITUDE_API_KEY: string;
  }
}

type AmplitudeClient = Pick<
  typeof amplitude,
  'logEvent' | 'setSessionId' | 'setUserId' | 'Identify' | 'identify'
>;
type LogResponse = amplitude.Types.AmplitudeReturn<amplitude.Types.Result>;
type IdentifyResponse = amplitude.Types.AmplitudeReturn<amplitude.Types.Result>;

const DEFAULT_AMPLITUDE_SERVER_URL = '/amplitude/2/httpapi';

const mockedAmplitudeInstance = {
  logEvent: () => ({} as LogResponse),
  setSessionId: _.noop,
  setUserId: _.noop,
  Identify: amplitude.Identify,
  identify: () => ({} as IdentifyResponse),
} as AmplitudeClient;

const getInstance = (): AmplitudeClient => {
  const shouldUseRealAmplitude = window.AMPLITUDE_API_KEY && !getUserReadOnly();
  if (shouldUseRealAmplitude) {
    amplitude.init(window.AMPLITUDE_API_KEY, undefined, {
      serverUrl: window.AMPLITUDE_SERVER_URL || DEFAULT_AMPLITUDE_SERVER_URL,
    });
    return amplitude;
  }

  return mockedAmplitudeInstance;
};

const instance = getInstance();

export const setProviderAndUserFullName = () => {
  const provider = getEnv();
  const userFullName = getUserFullName();

  const identify = new instance.Identify();
  identify.set('provider', provider);
  identify.set('userFullName', userFullName);
  instance.identify(identify);
};

export default instance;
