import { FC } from 'react';

// import customized ckeditor4 file and types
import '../../ckeditor4.19.1-edited';

import { ErrorBoundary } from '../../reuse/errorBoundary';

import { AppProvider } from './appProvider';
import { AppRouter } from './appRouter';

export const App: FC = () => (
  <ErrorBoundary>
    <AppProvider>
      <AppRouter />
    </AppProvider>
  </ErrorBoundary>
);
