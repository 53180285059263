import { BulletType } from '~/app/@types';
import {
  SimpleCondition,
  IndexingDataCondition,
  BulletNoteStatusRecord,
  ObservationBulletIndexingMetaData,
} from '~/app/@types/state';
import { EXISTING_DIFF, OUTDATED_DIFF } from '~/app/constants';
import { get } from '~/app/utils';

export const setupBulletTracking = ({
  conditionNames,
  currentRegardConditionsMap,
  conditionsByModule,
  combinedBulletsInText,
  bulletsAlreadyInText,
}: {
  conditionNames: string[];
  currentRegardConditionsMap: Record<string, SimpleCondition>;
  conditionsByModule: Record<string, IndexingDataCondition>;
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  combinedBulletsInText: Record<string, { text: string; conditionTextIndex: number }>;
  bulletsAlreadyInText: Record<string, boolean>;
}) => {
  const bulletNoteStatusUpdates: BulletNoteStatusRecord = {};

  conditionNames.forEach((conditionName) => {
    const currentRegardCondition = currentRegardConditionsMap[conditionName];
    const indexingModuleCondition = conditionsByModule[conditionName];
    if (!currentRegardCondition) return;

    // Here we loop thru all the Regard bullets mapped to this chunk of text. For each one
    // we see if it is in the basenote text
    currentRegardCondition.bullets.forEach((bullet) => {
      const bulletKey = bullet.text.trim().replace(/[ ]{2,}/g, ' ');
      const bulletNoteStatusId = `${conditionName}.${bullet.id}`;

      const foundRegardBulletInText = get(bulletsAlreadyInText, bulletKey);
      const foundRegardBulletInCombinedText = get(combinedBulletsInText, bulletKey);
      const foundBullet = !!(foundRegardBulletInText || foundRegardBulletInCombinedText);

      // we only want to track General and OUTDATED_DIFF bullets that are found in the basenote.
      // it would be a performance concern to add all general bullets so we filter out here
      const isIgnoredBullet = bullet.type === BulletType.General || bullet.diff === OUTDATED_DIFF;
      if (!foundBullet && isIgnoredBullet) return;

      const newBulletIndexingMetaData = {
        diff: bullet.diff,
        ids: [bulletNoteStatusId],
        tags: bullet.tags,
        type: bullet.type,
      };
      if ('interpretation' in bullet) {
        const obsIndexingMetadata = newBulletIndexingMetaData as ObservationBulletIndexingMetaData;
        obsIndexingMetadata.interpretation = bullet.interpretation;
      }

      // we mark found (but outdated) bullets as "existing" so Regard will show tracking (grey gutter in UI)
      if (bullet.diff === OUTDATED_DIFF) {
        newBulletIndexingMetaData.diff = EXISTING_DIFF;
      }

      // add this bullet to indexing data
      indexingModuleCondition.bulletsByTrimmedTextKey[bulletKey] = newBulletIndexingMetaData;
    });
  });

  return {
    bulletNoteStatusUpdates,
  };
};
