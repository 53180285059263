import { FC } from 'react';
import { AccordionTableSection } from '../../../reuse/accordionTable/types';
import { AccordionTable } from '../../../reuse';

interface SourceTableProps {
  module: string;
  sourceTableSections: AccordionTableSection[];
}

export const SourceTable: FC<SourceTableProps> = ({ module, sourceTableSections }) => (
  // This key will (thankfully) force the state of the sourcing table
  // (think which sections are open) to reset every time the module hanges.
  // we could replace it with a caching system to persist state per module
  // when clicking back and forth between dxs.
  <AccordionTable key={module} sections={sourceTableSections} />
);
