import { useDispatch } from 'react-redux';

import { LoginError, Patient } from '../../@types/state';
import { fetchPatientRecord } from '../../actions/record';
import { createSelector, useSelector } from '../../store';

// NOTE: This hook should probably only be instantiated in one place.

const fetchDemoDataSelector = createSelector(
  ({ regardNote, login, record }) => ({
    error: login.error || regardNote.fetchError || record.error || undefined,
    loading: record.isFetching,
    data: record.data,
  }),
  (x) => x
);

export const useFetchDemoData = ({
  pt,
  timestamp,
}: {
  pt: string | null;
  timestamp: ISODateString;
}): { data: Patient | null; error: LoginError | string | undefined; loading: boolean } => {
  const { data, error, loading } = useSelector(fetchDemoDataSelector);

  const dispatch = useDispatch();

  if (!data && !loading) {
    dispatch(fetchPatientRecord(pt, timestamp));
  }

  return {
    data,
    error,
    loading,
  };
};
