import { SummaryP, SummaryUl } from './common';

export const pneumoniaSummary = (
  <>
    <SummaryP first>Pneumonia diagnostic criteria</SummaryP>
    <SummaryP>Imaging criteria:</SummaryP>
    <SummaryUl>
      <li>
        Positive indications of pneumonia from chest imaging report impressions/findings
        (infiltrate, opacity, consolidation, density).
      </li>
    </SummaryUl>
    <SummaryP>PLUS 2 or more of the following:</SummaryP>
    <SummaryUl>
      <li>Elevated temperature.</li>
      <li>Elevated WBC count.</li>
      <li>Elevated procalcitonin.</li>
      <li>
        Microbiology: positive respiratory (sputum, BAL) culture OR Streptococcus pneumoniae antigen
        OR Legionella urine antigen.
      </li>
      <li>Hypoxemic respiratory failure.</li>
      <li>
        Symptoms: “cough”, “shortness of breath”, “dyspnea”, “pleuritic chest pain”, “pleuritic
        pain” noted in notes from the current encounter.
      </li>
    </SummaryUl>
  </>
);
