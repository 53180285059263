import { assertUnreachable } from '~/app/utils';
import { getOAuthSubject } from '~/app/cookies';
import { client } from '~/app/api';

type ChatReplyBase = {
  text: string;
};

export type ChatReplyStatus = 'done' | 'error' | 'processing' | 'responding';

type ChatAiResponse = ChatReplyBase & {
  status: ChatReplyStatus;
  type: 'response';
  citations: Record<string, string | undefined>;
};

type ChatUserPrompt = ChatReplyBase & {
  type: 'prompt';
};

export type ChatReply = ChatAiResponse | ChatUserPrompt;

const sendResponseRecord = ({
  chatId,
  text,
  index,
  citations,
}: {
  chatId: string;
  text: string;
  index: number;
  citations: Record<string, string | undefined>;
}) => {
  client.POST('/openapi/storeMessage', {
    body: {
      patientId: window.regardPatientId ?? '',
      encounterId: window.regardEncounterId ?? '',
      chatId,
      index,
      oauthSubject: getOAuthSubject(),
      content: text,
      source: 'bot',
      fields: {
        // Typing from the backend is intentionally non-specific
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        citations,
      },
      timestamp: Date.now(),
    },
  });
};

const processLastAiResponseInChatReplies = ({
  chatId,
  chatReplies,
  response,
}: {
  chatId: string;
  chatReplies: ChatReply[];
  response: ChatAiResponse;
}): ChatReply[] => {
  const thereAreNoChatReplies = !chatReplies.length;
  const lastReply = chatReplies[chatReplies.length - 1];
  const theLastReplyIsNotAnAiResponse = lastReply?.type !== 'response';
  const newChatReplies = [...chatReplies];

  if (response.status === 'done') {
    sendResponseRecord({
      chatId,
      text: response.text,
      // keep indexing consistent
      index: chatReplies.length - 1,
      citations: response.citations,
    });
  }

  if (thereAreNoChatReplies || theLastReplyIsNotAnAiResponse) {
    // Add response to the end of the list
    newChatReplies.push(response);
  } else if (lastReply.status === 'responding' && response.status === 'responding') {
    const prevText = newChatReplies[newChatReplies.length - 1].text;
    const currText = response.text;
    // Assume the longest text is the best or newest version of the response
    const longestText = prevText.length > currText.length ? prevText : currText;

    // Replace the last reply in the list with response plus the longest text
    newChatReplies[newChatReplies.length - 1] = { ...response, text: longestText };
  } else {
    newChatReplies[newChatReplies.length - 1] = response;
  }

  return newChatReplies;
};

// ////////
// State //
// ////////

export type ChatApiState = {
  chatId: string;
  chatCheckingForAiResponse: boolean;
  chatReplies: ChatReply[];
  errorStartingChat: string;
  initialized: boolean;
  model: string;
};

export const initialChatApiState: ChatApiState = {
  chatId: '',
  chatCheckingForAiResponse: false,
  chatReplies: [],
  errorStartingChat: '',
  initialized: false,
  model: '',
};

// //////////
// Actions //
// //////////

type InitializeAction = {
  type: 'initialize';
};

type StartAction = {
  type: 'start';
  payload: Pick<ChatApiState, 'chatId' | 'model'>;
};

type ErrorAction = {
  type: 'error';
  payload: { message: string };
};

type AddOrReplaceLastAiResponse = {
  type: 'addOrReplaceLastAiResponse';
  payload: {
    response: ChatAiResponse;
  };
};

type AddUserPrompt = {
  type: 'addUserPrompt';
  payload: {
    prompt: ChatUserPrompt;
  };
};

type IdleAction = {
  type: 'idle';
};

type RestartAction = {
  type: 'restart';
  payload: {
    userPromptText: string;
  };
};

type Action =
  | InitializeAction
  | StartAction
  | ErrorAction
  | AddOrReplaceLastAiResponse
  | AddUserPrompt
  | IdleAction
  | RestartAction;

// //////////
// Reducer //
// //////////

export const chatApiReducer = (state: ChatApiState, action: Action): ChatApiState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        initialized: true,
      };
    case 'start': {
      return {
        ...state,
        ...action.payload,
        chatCheckingForAiResponse: true,
      };
    }
    case 'error': {
      const { message } = action.payload;
      return {
        ...state,
        errorStartingChat: message,
        chatCheckingForAiResponse: false,
      };
    }
    case 'addOrReplaceLastAiResponse': {
      const { response } = action.payload;

      return {
        ...state,
        chatReplies: processLastAiResponseInChatReplies({
          chatId: state.chatId,
          chatReplies: state.chatReplies,
          response,
        }),
        chatCheckingForAiResponse: ['processing', 'responding'].includes(response.status),
      };
    }
    case 'addUserPrompt': {
      const { prompt } = action.payload;
      return {
        ...state,
        chatReplies: [...state.chatReplies, prompt],
        chatCheckingForAiResponse: true,
      };
    }
    case 'idle': {
      return {
        ...state,
        chatCheckingForAiResponse: false,
      };
    }
    case 'restart': {
      return {
        ...state,
        initialized: false,
      };
    }
    default:
      assertUnreachable(action);
      return state;
  }
};
