import { shallowEqual } from 'fast-equals';
import { FC, memo } from 'react';

import {
  ConditionLineStatus,
  ConditionLineTypeLiteral,
  ObservationInterpretation,
} from '~/app/@types';
import { interpretationIsCritical } from '~/app/utils';

import { ConditionButtons } from '../conditionButtons';
import { ConditionLineBackground } from '../conditionLineBackground';
import { ConditionLineStatusIndicator } from '../conditionLineStatusIndicator';
import { ConditionLineCriticalFlag, SvgStyle } from '../conditionLineCriticalFlag';

import { getRootStyle, indicatorContainerStyle } from './css';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionLine: FC<{
  html: HtmlString | undefined;
  ids: string[] | undefined;
  isFirstLine: boolean;
  isLastLine: boolean;
  nextStatus: ConditionLineStatus;
  previousStatus: ConditionLineStatus;
  status: ConditionLineStatus;
  type: ConditionLineTypeLiteral;
  interpretation?: ObservationInterpretation;
}> = memo(
  ({
    children,
    html,
    ids,
    isFirstLine,
    isLastLine,
    nextStatus,
    previousStatus,
    status,
    type,
    interpretation,
  }) => {
    const { buttonHovered, dismissButtonHovered, reverseIndex } = useNoteBlockContext(
      ({ buttonHovered, dismissButtonHovered, reverseIndex }) => ({
        buttonHovered,
        dismissButtonHovered,
        reverseIndex,
      })
    );

    const isCritical = interpretationIsCritical(interpretation);

    return (
      <div
        data-condition-line-ids={ids?.join(',') ?? ''}
        data-cy="condition-line"
        data-cy-condition-line
        data-cy-condition-line-type={type}
        role="listitem"
        style={getRootStyle({
          buttonHovered,
          dismissButtonHovered,
          isLastLine,
        })}
      >
        {isFirstLine && <ConditionButtons reverseIndex={reverseIndex} />}
        <ConditionLineBackground isFirstLine={isFirstLine} />
        <div data-cy="condition-line-contents" style={indicatorContainerStyle}>
          {isCritical && <ConditionLineCriticalFlag left={-26} svgStyle={SvgStyle.Note} />}
          <ConditionLineStatusIndicator
            isCritical={isCritical}
            left={-6}
            nextStatus={nextStatus}
            previousStatus={previousStatus}
            status={status}
          />
          {children || (
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: html ?? '' }}
            />
          )}
        </div>
      </div>
    );
  },
  shallowEqual
);
ConditionLine.displayName = 'ConditionLine';
