import { useMemo } from 'react';
import { shallowEqual } from 'fast-equals';

import { ConditionNoteBlock, Keyword, Line } from '~/app/@types/state';
import { createSelector, useSelector } from '~/app/store';

import { get } from '~/app/utils';
import { useMeasureRender } from '../measure';

export type LineWithHeaderData = Line & {
  isFirstLine: boolean;
  isLastLine: boolean;
};

export const addHeaderDataToLines = ({
  lines,
}: {
  isFirstCondition: boolean;
  lines: Line[];
}): LineWithHeaderData[] =>
  lines.map((line, i) => ({
    ...line,
    isFirstLine: i === 0,
    isLastLine: i === lines.length - 1,
  }));

const EMPTY_CONDITION_LINES_AND_KEYWORDS: Pick<ConditionNoteBlock, 'lines' | 'keywords'> = {
  lines: [],
  keywords: [],
};

export const useConditionLines = ({
  id,
  isFirstCondition,
}: {
  id: string;
  isFirstCondition: boolean;
}): {
  lines: LineWithHeaderData[];
  keywords: Keyword[];
} => {
  const getConditionLines = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.conditionsById, id),
        (condition) =>
          condition
            ? {
                lines: condition.lines,
                keywords: condition.keywords,
              }
            : EMPTY_CONDITION_LINES_AND_KEYWORDS
      ),
    [id]
  );
  const { lines, keywords } = useSelector(
    getConditionLines,
    (previous, next) =>
      previous.lines === next.lines && shallowEqual(previous.keywords, next.keywords)
  );

  const linesWithHeaderData = addHeaderDataToLines({
    isFirstCondition,
    lines,
  });

  // Here we mark the time where the react note editor rendered.
  useMeasureRender(linesWithHeaderData);

  return { lines: linesWithHeaderData, keywords };
};
