import _ from 'lodash';
import { useCallback, useMemo } from 'react';

import type { AccordionTableSection } from '../../../reuse/accordionTable/types';
import { getSourceTableSectionsFromSources } from '../sourceTable';

import { APIBullet } from '../../../@types/state';
import { DispatchOpenSource, openSource } from '../../../actions/ui';
import { createSelector, useDispatch, useSelector, useShallowEqualSelector } from '../../../store';

const sortSourcesByDate = <T extends { date: string }>(sources: T[]): T[] =>
  // Most recent date will appear first.
  _.reverse(_.sortBy(sources, (source) => source.date));

const currentBaseNoteResourceIdSelector = createSelector(
  (state) => state.regardNote.baseNoteData?.resourceId,
  (x) => x
);

export const useAdditionalDxDetails = (
  module: string
): { general: APIBullet[]; sourceTableSections: AccordionTableSection[] } => {
  const dispatch = useDispatch();
  const open = useCallback<DispatchOpenSource>(
    (payload) => dispatch(openSource(payload)),
    [dispatch]
  );

  const sourcesSelector = useMemo(
    () =>
      createSelector(
        (state) => state.regardNote.currentConditionResults,
        (currentConditionResults) => currentConditionResults.find((item) => item.module === module)
      ),
    [module]
  );
  const sources = useShallowEqualSelector(sourcesSelector);
  const currentBaseNoteResourceId = useSelector(currentBaseNoteResourceIdSelector);

  // Do not include base note in source table
  const filteredSourceNotes = (sources?.sourceNotes ?? []).filter(
    (note) => note.resource_id !== currentBaseNoteResourceId
  );

  return {
    general: sources?.general ?? [],
    sourceTableSections: getSourceTableSectionsFromSources({
      // Sort all categories by date.
      sourceConditions: sortSourcesByDate(sources?.sourceConditions ?? []),
      sourceNotes: sortSourcesByDate(filteredSourceNotes),
      supportingEvidence: sortSourcesByDate(sources?.supportingEvidence ?? []),
      open,
    }),
  };
};
