export const removeSubstringFromStartAndEnd = (substring: string, inputString: string): string => {
  if (substring === '' || substring.length > inputString.length) {
    return inputString;
  }

  if (inputString.indexOf(substring) === 0) {
    return removeSubstringFromStartAndEnd(substring, inputString.substring(substring.length));
  }

  if (inputString.lastIndexOf(substring) === inputString.length - substring.length) {
    return removeSubstringFromStartAndEnd(
      substring,
      inputString.substring(0, inputString.length - substring.length)
    );
  }

  return inputString;
};
