import { MouseEventHandler, useCallback } from 'react';

import { useConditionContext } from '../conditionProvider';
import { useNoteBlockContext } from '../noteBlockProvider';

export const useOnClickConditionTitleKeyword = () => {
  const { noteBlockRef } = useNoteBlockContext(({ noteBlockRef }) => ({
    noteBlockRef,
  }));
  const { openDxDetailsFromNote, setLastClickedKeywordModule } = useConditionContext(
    ({ openDxDetailsFromNote, setLastClickedKeywordModule }) => ({
      openDxDetailsFromNote,
      setLastClickedKeywordModule,
    })
  );

  const result = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const x = e.clientX;
      const y = e.clientY;

      const keywordEls = noteBlockRef.current?.querySelectorAll('[data-condition-keyword-module]');
      if (keywordEls) {
        const insideKeywordEl = Array.from(keywordEls).find((keywordEl) => {
          const { bottom, left, right, top } = keywordEl.getBoundingClientRect();
          return x >= left && x <= right && y >= top && y <= bottom;
        });

        if (insideKeywordEl) {
          const insideKeywordModule = insideKeywordEl.getAttribute('data-condition-keyword-module');

          if (insideKeywordModule) {
            setLastClickedKeywordModule({ moduleClicked: insideKeywordModule });
            openDxDetailsFromNote({
              location: 'titleKeyword',
              selectedModule: insideKeywordModule,
            });
          }
        }
      }
    },
    [noteBlockRef, openDxDetailsFromNote, setLastClickedKeywordModule]
  );

  return result;
};
