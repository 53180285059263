import { MutableRefObject, useCallback, useMemo } from 'react';

import { uniqueId } from '~/app/utils';
import { useDispatch } from '~/app/store';

import { useNoteContext } from '../noteProvider';
import { measureOnInput, measureThrottle, measureSetTimeout } from '../measure';
import { processRawHtml } from './processRawHtml';

type ConditionOnHtmlInputFnArgs = {
  caretPos: number;
  prevHtmlRef: MutableRefObject<HtmlString>;
  rawHtml: HtmlString;
};

export type ConditionOnHtmlInputFn = ({
  caretPos,
  prevHtmlRef,
  rawHtml,
}: ConditionOnHtmlInputFnArgs) => void;

export const useConditionOnHtmlInput = (id: string): ConditionOnHtmlInputFn => {
  const dispatch = useDispatch();
  const { throttleDelay, setTimeoutDelay } = useNoteContext(
    ({ throttleDelay, setTimeoutDelay }) => ({
      throttleDelay,
      setTimeoutDelay,
    })
  );

  const updateLater = useMemo(
    () =>
      throttleDelay(
        ({
          caretPos,
          rawHtml,
          prevHtmlRef,
          uniqueId,
        }: ConditionOnHtmlInputFnArgs & {
          uniqueId: string;
        }): void => {
          const html = processRawHtml(rawHtml);
          const previousHtml = prevHtmlRef.current;

          // Prevent identical updates
          if (html !== previousHtml) {
            // NOTE: Leave these comments for testing purposes; they have
            //  proved highly useful.
            // console.log('not same');
            // console.log('previousHtml', previousHtml);
            // console.log(
            //   'previousHtml codes',
            //   previousHtml.split('').map((char) => `${char} ${char.charCodeAt(0)}`)
            // );
            // console.log('html', html);
            // console.log(
            //   'html.current codes',
            //   html.split('').map((char) => `${char} ${char.charCodeAt(0)}`)
            // );

            // eslint-disable-next-line no-param-reassign
            prevHtmlRef.current = html;

            measureThrottle(uniqueId);

            setTimeoutDelay(() => {
              measureSetTimeout();
              if (!document.querySelector(`[data-note-block-id="${id}"]`)) {
                console.warn(`Note block with id ${id} no longer exists.`);
                return;
              }

              dispatch({
                type: 'modify note block',
                payload: {
                  noteBlockCaretPos: caretPos,
                  conditionId: id,
                  conditionHtml: html,
                  type: 'typing',
                },
              });
            });
          }
        }
      ),
    [dispatch, id, throttleDelay, setTimeoutDelay]
  );

  const onHtmlInput: ConditionOnHtmlInputFn = useCallback(
    ({ caretPos, prevHtmlRef, rawHtml }) => {
      const id = uniqueId();
      measureOnInput(id);
      updateLater({ caretPos, prevHtmlRef, rawHtml, uniqueId: id });
    },
    [updateLater]
  );

  return onHtmlInput;
};
