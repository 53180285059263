import { find } from 'lodash';
import { MutableRefObject, useMemo, useCallback } from 'react';
import { ConditionNoteBlock } from '~/app/@types/state';
import { track } from '~/app/analytics';
import { createSelector, useDeepEqualSelector, useDispatch } from '~/app/store';
import {
  get,
  htmlStringToPlainText,
  isConditionNoteBlock,
  isShelfDividerNoteBlock,
} from '~/app/utils';

import { MenuItem } from './types';

const EMPTY_BLOCK_TITLE = '[ Empty Line ]';

const getTop = ({ el }: { el: HTMLElement | null }): number => {
  if (!el) return 0;

  const elementTop = el.getBoundingClientRect().top;
  const parentTop = el.offsetParent?.getBoundingClientRect().top ?? 0;
  return elementTop - parentTop;
};

const createConditionName = (condition: Pick<ConditionNoteBlock, 'lines' | 'modules'>): string => {
  const firstTitleLine = find(condition.lines, (line) => line.type === 'title');
  if (firstTitleLine?.html) {
    return htmlStringToPlainText(firstTitleLine.html);
  }

  const moduleName = condition.modules.join(', ');
  if (moduleName) {
    return moduleName;
  }

  const firstNonEmptyLine = condition.lines.find((line) => htmlStringToPlainText(line.html));
  if (firstNonEmptyLine) {
    return htmlStringToPlainText(firstNonEmptyLine.html);
  }

  return EMPTY_BLOCK_TITLE;
};

const headerMoveMenuItem: MenuItem = {
  id: 'header',
  index: 0,
  name: '',
  menuStyle: 'header',
  modules: [],
};

const getMoveMenuItems = createSelector(
  (state) => state.regardNote.masterNoteBlocks,
  (noteBlocks) => {
    const menuItems: MenuItem[] = [headerMoveMenuItem];

    noteBlocks.forEach((noteBlock, i) => {
      const index = i + 1;
      const { id } = noteBlock;

      if (isConditionNoteBlock(noteBlock)) {
        menuItems.push({
          index,
          id,
          name: createConditionName(noteBlock),
          menuStyle: 'label',
          modules: noteBlock.modules,
        });
      } else if (isShelfDividerNoteBlock(noteBlock)) {
        menuItems.push({
          index,
          id,
          name: 'Shelved',
          menuStyle: 'divider',
          modules: [],
        });
      }
    });

    return menuItems;
  }
);
const BLANK_CONDITION = {
  index: -1,
  modules: [],
};

export const useMoveCondition = ({
  conditionRef,
  id,
}: {
  conditionRef: MutableRefObject<HTMLDivElement | null>;
  id: string;
}): {
  moveMenuItems: ReturnType<typeof getMoveMenuItems>;
  moveCondition: (toIndex: number) => void;
} => {
  const dispatch = useDispatch();

  const getCondition = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.conditionsById, id),
        (condition) =>
          condition
            ? {
                index: condition.index,
                modules: condition.modules,
              }
            : BLANK_CONDITION
      ),
    [id]
  );

  const { index, modules } = useDeepEqualSelector(getCondition);
  const moveMenuItems = useDeepEqualSelector(getMoveMenuItems);

  const moveCondition = useCallback(
    (_toIndex: number) => {
      const fromIndex = index;
      const firstModule = modules[0] ?? '';

      const direction = _toIndex > fromIndex ? 'down' : 'up';
      const toIndex = direction === 'down' ? _toIndex - 1 : _toIndex;

      const { masterNoteBlocks } = window.store.getState().regardNote;
      const shelfDividerNoteBlockIndex = masterNoteBlocks.findIndex(isShelfDividerNoteBlock);

      dispatch({
        type: 'modify note block',
        payload: {
          fromIndex,
          toIndex,
          top: getTop({ el: conditionRef?.current }),
          type: 'move',
        },
      });

      const movedIntoShelf =
        shelfDividerNoteBlockIndex !== -1 && // shelf exists
        toIndex >= shelfDividerNoteBlockIndex && // will be in shelf and
        fromIndex < shelfDividerNoteBlockIndex; // wasn't in shelf before
      const movedOutOfShelf =
        shelfDividerNoteBlockIndex !== -1 && // shelf exists
        toIndex <= shelfDividerNoteBlockIndex && // will be out of shelf and
        fromIndex > shelfDividerNoteBlockIndex; // was in shelf before

      track.movedCondition({
        direction,
        modules,
        fromIndex,
        toIndex,
        firstModule,
        movedIntoShelf,
        movedOutOfShelf,
      });

      if (movedIntoShelf) {
        modules.forEach((m) => track.conditionShelved({ module: m, uiElement: 'move button' }));
      }
    },
    [conditionRef, dispatch, index, modules]
  );

  return {
    moveMenuItems,
    moveCondition,
  };
};
