import { FC, memo } from 'react';

import { track } from '~/app/analytics';
import { ButtonTag, Icon } from '~/app/reuse';

import { useNoteContext } from '../noteProvider';
import { buttonCss, css, iconStyle, textStyle } from './css';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionAddButton: FC<{ bottom?: boolean }> = memo(({ bottom }) => {
  const { addCondition } = useNoteContext(({ addCondition }) => ({
    addCondition,
  }));
  const { index } = useNoteBlockContext(({ index }) => ({ index }));

  const atIndex = bottom ? index + 1 : index;

  return (
    // TS fails check on `msUserSelect` in `css`. potentially a bug:
    // https://github.com/styled-components/styled-components/issues/824
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div css={css}>
      <ButtonTag
        css={buttonCss}
        data-cy-condition-add-button
        onClick={(): void => {
          addCondition(atIndex);
          track.clickedAddDxButton({ atIndex });
        }}
      >
        <Icon iconName="focus" style={iconStyle} />
        <span style={textStyle}>Add Dx</span>
      </ButtonTag>
    </div>
  );
});
ConditionAddButton.displayName = 'ConditionAddButton';
