import _ from 'lodash';
import { ReactNode, useState } from 'react';

import { useDemoData } from './useDemoData';
import { formatDatetime } from '../../../utils';
import { Patient } from '../../../@types/state';

export type DemoImageRow = {
  items: ReactNode[];
  isSelected: boolean;
  onClick(): void;
};

const columns = [
  {
    fallback: '',
    key: 'description',
    label: 'Description',
  },
  {
    fallback: formatDatetime(new Date().toISOString()),
    key: 'orderDate',
    label: 'Order Date',
  },
] as const;

const header: DemoImageRow = {
  isSelected: false,
  items: columns.map(({ label }) => label),
  onClick: _.noop,
};

const sanitize = (value: Date | string): string =>
  value instanceof Date ? formatDatetime(value.toISOString()) : value;

export const useDemoImageRows = (): {
  rows: DemoImageRow[];
  selectedItem: Patient['images'][0];
} => {
  const [selected, setSelected] = useState(0);
  const images = useDemoData({ category: 'images' });

  const content = images.map((image, i) => {
    const isSelected = selected === i;

    return {
      isSelected,
      items: columns.map(({ fallback, key }) => {
        const valueOrFallback = key ? image[key] : fallback;
        const sanitizedValue = sanitize(valueOrFallback);

        return sanitizedValue;
      }),
      onClick: isSelected ? _.noop : () => setSelected(i),
    };
  });

  return { rows: [header, ...content], selectedItem: images[selected] };
};
