import { FC } from 'react';

import { theme } from '~/app/reuse';
import { ShelvedNameEditor } from './ShelvedNameEditor';

const rootStyle = {
  margin: '8px 0',
  position: 'relative',
  textAlign: 'center',
} as const;

const lineStyle = {
  backgroundColor: theme.colors.grey3,
  height: 1,
  left: 0,
  position: 'absolute',
  right: 0,
  top: '50%',
} as const;

const textStyle = {
  backgroundColor: theme.colors.white,
  fontSize: 12,
  color: theme.colors.grey3,
  display: 'inline-block',
  fontWeight: 600,
  padding: '0 12px',
  position: 'relative',
} as const;

export const ShelvedConditionsLine: FC = () => (
  <>
    <div data-cy-shelved-conditions-line style={rootStyle}>
      <div style={lineStyle} />
      <div style={textStyle}>SHELVED</div>
    </div>
    <ShelvedNameEditor />
  </>
);

ShelvedConditionsLine.displayName = 'ShelvedConditionsLine';
