import { ActionCreator } from 'redux';
import { parsePatient } from '../components/pmemr/content';
import { Patient } from '../@types/state';
import { TIMESTAMP_PARAM } from '../constants';

// Action Names //////////
export const CLOSE_DETAILS = 'close medical record details dialog';
export const FETCH_PT = 'fetch patient data via encrypted patient id and timestamp';
export const FETCH_PT_FAILURE = 'error occurred when receiving patient data from server';
export const FETCH_PT_SUCCESS = 'successfully received patient data from server';
export const SELECT_ITEM = 'select item to view in medical record details dialog';
export const SELECT_SCREEN = 'select medical record section to view';

type SelectItemAction = {
  type: typeof SELECT_ITEM;
  item: Record<string, unknown>;
};

// Action Types //////////
export type RecordAction =
  | { type: typeof CLOSE_DETAILS }
  | { type: typeof FETCH_PT }
  | { type: typeof FETCH_PT_FAILURE; error: string }
  | { type: typeof FETCH_PT_SUCCESS; data: Patient }
  | { type: typeof SELECT_ITEM; item: Record<string, unknown> }
  | { type: typeof SELECT_SCREEN; screen: Record<string, unknown> };
type RecordDispatch = (argo0: RecordAction) => void;

export function fetchPatientRecord(pt: string | null, timestamp: ISODateString) {
  return (dispatch: (arg0: RecordAction) => void): Promise<void> | void => {
    if (!pt || !timestamp) {
      return dispatch({ type: FETCH_PT_FAILURE, error: 'Please provide valid mrn and timestamp' });
    }
    dispatch({ type: FETCH_PT });
    const params = new URLSearchParams();
    if (pt) {
      params.set('pt', pt);
    }
    if (timestamp) {
      params.set(TIMESTAMP_PARAM, timestamp);
    }
    return fetch(`/api/record?${params.toString()}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        const patient = parsePatient(json);
        dispatch({ type: FETCH_PT_SUCCESS, data: patient });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PT_FAILURE,
          error: error.status ? `${error.status} ${error.statusText}` : `${error.stack}`,
        });
      });
  };
}

export function selectScreen(screen: Record<string, unknown>) {
  return (dispatch: RecordDispatch): void => dispatch({ type: SELECT_SCREEN, screen });
}

export const createSelectItemAction: ActionCreator<SelectItemAction> = (
  item: Record<string, unknown>
) => ({ type: SELECT_ITEM, item });

export function closeDetails() {
  return (dispatch: RecordDispatch): void => dispatch({ type: CLOSE_DETAILS });
}
