import _ from 'lodash';
import { ComponentProps, CSSProperties, FC, ReactNode } from 'react';

import { cernerTheme } from './cernerTheme';
import { Icon } from '../../../reuse';

export const CernerTab: FC<{
  add?: boolean;
  isSelected?: boolean;
  label: ReactNode;
  onClick?(): void;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: CSSProperties; // used by CernerTabs
}> = ({ add, isSelected, label, onClick }) =>
  onClick ? (
    <button
      css={{
        alignItems: 'center',
        backgroundColor: isSelected ? cernerTheme.colors.hoverBlue : cernerTheme.colors.darkishBlue,
        borderBottom: `1px solid ${cernerTheme.colors.blue}`,
        color: cernerTheme.colors.white,
        display: 'flex',
        height: 32,
        padding: '0 32px',
        width: '100%',
        '&:hover': {
          backgroundColor: cernerTheme.colors.hoverBlue,
        },
      }}
      onClick={isSelected ? _.noop : onClick}
      type="button"
    >
      {add ? (
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span>{label}</span>
          <span style={{ display: 'flex' }}>
            <Icon iconName="plus" style={{ marginRight: 2 }} /> Add
          </span>
        </span>
      ) : (
        label
      )}
    </button>
  ) : null;

export type CernerTabProps = ComponentProps<typeof CernerTab>;
