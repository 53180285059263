import { SummaryP, SummaryUl } from './common';

export const dementiaSummary = (
  <>
    <SummaryP first>
      Regard searches the patient’s past medical history and provider notes for any associated
      conditions and will include the diagnosis if found.
    </SummaryP>
    <SummaryUl>
      <li>
        If a specific ICD10 code is found, the algorithm will diagnose with the appropriate code for
        the type.
      </li>
      <li>Regard will search for other keywords to identify additional subtypes.</li>
      <li>
        Presents supplementary data, including: last cognitive assessment (MOCA, MMSE, SLUMS) score
        and date; abnormal labs; active/held medications; and MRI brain, CT head/brain, and CTA head
        reports.
      </li>
    </SummaryUl>
  </>
);
