import { EMPTY_BR_HTML_LINE, NBSP_SINGLE } from '~/app/constants';
import { nbspifyStringForCkeditor } from '../nbspifyStringForCkeditor';

const htmlifyPlainTextForCkeditor = (plainText: string): HtmlString =>
  // 3. Prevent successive spaces from being collapsed
  nbspifyStringForCkeditor(
    plainText
      // 1. Escape ampersands
      .replace(/&/g, '&amp;') // make sure we escape ampersands before we add ampersands!
      // 2. Escape angle brackets
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
  )
    // 4. Escape non-breaking spaces
    .replace(new RegExp(NBSP_SINGLE, 'g'), '&nbsp;') as HtmlString;

export const plainTextToHtmlLines = (plainText: string): HtmlString[] => {
  const result = plainText
    .split('\n')
    .map((line) =>
      line ? (`<div>${htmlifyPlainTextForCkeditor(line)}</div>` as HtmlString) : EMPTY_BR_HTML_LINE
    );

  return result;
};
