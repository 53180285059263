import {
  CONDITION_TEXTAREA_BOTTOM_GUTTER,
  CONDITION_TEXTAREA_LINE_HEIGHT,
  NOTE_LEFT_GUTTER,
  NOTE_RIGHT_GUTTER,
} from '../const';

export const style = {
  paddingLeft: NOTE_LEFT_GUTTER,
  paddingRight: NOTE_RIGHT_GUTTER,
} as const;

export const buttonHoveredStyle = {
  ...style,
  // using `opacity: 0` here to hide the editor will essentially "retain" the
  //  cursor while hovering the condition dismiss/move buttons and then
  //  "restore" it when unhovering, as opposed to using `visibility: hidden`
  opacity: 0,
} as const;

const overflowWrap = 'break-word';

export const wrapStyle = {
  overflowWrap, // 'overflow-wrap' is the updated name for 'word-wrap'
  // I'm switching from 'pre-wrap' to 'pre-line' with CKEditor4 for a very
  //  important reason:
  //
  //   If we use 'pre-wrap' CKEditor assumes that all whitespace is entered
  //    intentionally, in the sense of "If the user types three regular spaces
  //    in-a-row, they must not want those spaces to NOT collapse into a single
  //    space, otherwise they would have used keyboard shortcuts to enter
  //    non-breaking spaces...
  //
  // On the other hand, if we set 'pre-line' CKEditor happily manages repeated
  //  whitespace appropriately, automatically converting multiple, repeated
  //  spaces into the correct mixture of regular and non-breaking spaces to
  //  prevent that whitespace from being collapsed.
  whiteSpace: 'pre-line', // 'break-spaces' would be best but it's not supported by IE10
  wordWrap: overflowWrap,
} as const;

export const baseTextareaStyle = {
  ...wrapStyle,
  background: 'none',
  border: 0,
  color: 'inherit',
  display: 'block',
  lineHeight: `${CONDITION_TEXTAREA_LINE_HEIGHT}px`,
  overflow: 'hidden',
  padding: `0 0 ${CONDITION_TEXTAREA_BOTTOM_GUTTER}px`,
  position: 'relative',
  resize: 'none',
  width: '100%',
} as const;

export const textareaStyle = baseTextareaStyle;
