import { NotificationLevel, COMPANY_NAME } from '~/app/constants';
import { NotificationAttrs, Stats } from '~/app/@types/state';
import InfoCircleSVG from '~/app/images/info-circle.svg';
import { isReview, isSalesDemoMode } from '~/app/flags';
import { NotificationType } from '~/app/@types';
import { createNotification } from './createNotification';

export const addNoLabeledDiagnosesNotification = (
  notifications: NotificationAttrs[],
  stats: Stats
) => {
  if (isSalesDemoMode) return;
  if (isReview()) return;
  if (stats.noProgressNote || stats.matchedConditions.length > 0) return;
  if (stats.missedConditions.length > 0) return;

  const notification = createNotification({
    type: NotificationType.NoDiagnosis,
    content: (
      <>
        No labeled diagnoses were detected in the EHR note.
        <span className="tooltip">
          <InfoCircleSVG className="info-tool" />
          <div className="tooltiptext">
            For {COMPANY_NAME} to identify listed diagnoses, these must be preceded with a symbol or
            number, e.g.: # Hypernatremia
          </div>
        </span>
      </>
    ),
    level: NotificationLevel.Warn,
  });
  notifications.push(notification);
};
