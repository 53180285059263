export const zIndices = {
  conditionButtons: 10,
  // condition button z-indexes can range from 10 to the number of conditions,
  //  so to be safe, don't use a z-index below 100 if you want it to be above
  //  the condition buttons
  conditionCopiedMessage: 120,
  sourceDrawer: 180,
  noteToolbar: 175,
  resourcesFlyout: 190,
  userFeedbackForm: 205,
  processDrawer: 200,
  tabPanelButtons: 210,
  selectBaseNoteCurtain: 230,
  selectBaseNotePreview: 233,
  noteHeader: 235,
  devTools: 1001,
  hiddenTopModal: 99999,
} as const;
