import { noop } from 'lodash';
import { ReactNode, FC, useRef, useEffect } from 'react';

import '~/app/styles/Popover.scss';

export enum Placement {
  Top = 'top',
  // Additional placements to be implemented as needed
  // Bottom = 'bottom',
  // Right = 'right',
  // Left = 'left',
  // TopLeft = 'top-left',
  // TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  // BottomRight = 'bottom-right',
}

export type PlacementLiteral = `${Placement}`;

interface PopoverProps {
  className?: string;
  content: ReactNode;
  isVisible: boolean;
  placement: PlacementLiteral;
  onOutsideClick?: () => void;
}

export const Popover: FC<PopoverProps> = (props) => {
  const { children, className, placement, content, isVisible, onOutsideClick } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onOutsideClick || !isVisible) return noop;

    const handleOutsideClick = (event: MouseEvent) => {
      const currentElement = containerRef.current;
      if (!currentElement) return;
      if (!currentElement.contains(event?.target as Node | null)) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onOutsideClick, isVisible]);

  return (
    <span className={`RegardPopover ${className || ''}`} data-placement={placement}>
      {children}
      {isVisible && (
        <div ref={containerRef} className="popoverContainer" data-cy-popover>
          {content}
          <div className="popoverTriangle" />
        </div>
      )}
    </span>
  );
};
