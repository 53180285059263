import { theme } from '../../reuse';
import { IconButtonColorCSS } from '../../reuse/iconButton/css';
import { IconButtonSize } from '../../reuse/iconButton/types';
import { isSalesDemoMode } from '../../flags';

// Sourcing Table
export const authorDefault = isSalesDemoMode ? 'Moulian, MD, Alex' : 'Unknown Author';

// Note
export const NOTE_LEFT_GUTTER = 52;
export const NOTE_RIGHT_GUTTER = 24;

// Condition Overlay
export const CONDITION_UNDERLAY_BUTTONS_BORDER_RADIUS = {
  borderTopLeftRadius: theme.other.borderRadius4,
  borderTopRightRadius: theme.other.borderRadius4,
};
export const CONDITION_UNDERLAY_BUTTON_COLOR: IconButtonColorCSS = {
  backgroundColor: theme.colors.none,
  color: theme.colors.blueRegular,
  '&:focus': {
    backgroundColor: theme.colors.lightBlue2,
    color: theme.colors.blueMedium,
  },
  '&:hover': {
    backgroundColor: theme.colors.lightBlue2,
    color: theme.colors.blueMedium,
  },
  '&:active, &.active': {
    backgroundColor: theme.colors.lightBlue3,
    color: theme.colors.blueDark,
  },
  '&:disabled': {
    backgroundColor: theme.colors.none,
    color: theme.colors.grey4,
  },
} as const;
export const CONDITION_UNDERLAY_BUTTON_SIZE: IconButtonSize = 'mini' as const;

// Condition Textarea
export const CONDITION_TEXTAREA_LINE_HEIGHT = 20;
export const CONDITION_TEXTAREA_BOTTOM_GUTTER = 4;

// Condition Add
export const HEIGHT_OF_SPACE_BETWEEN_CONDITIONS = 18;
