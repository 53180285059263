import { FC, MouseEvent } from 'react';

import { isRefreshButtonDisabled } from '~/app/flags';
import { IconButton, StaticTooltip } from '~/app/reuse';
import {
  getTimeDifferenceInWords,
  getMostRecentRefreshTime,
  getModKeysForEvent,
} from '~/app/utils';
import { refresh } from '../../noteContainerHeader/noteContainerHeaderDefaultTitle/refresh';
import { buttonTooltipStyle } from '../css';

const getRecentTimeContent = () => {
  const mostRecentTime = getMostRecentRefreshTime() || new Date();
  const timeInPlainText = getTimeDifferenceInWords(Date.now(), mostRecentTime.getTime());
  return timeInPlainText;
};

const buttonStyle = {
  display: 'block',
} as const;

const recentTimeStyle = {
  marginLeft: 2,
} as const;

// This hidden navigation allows for testing chromium based Regard2.0 features in live locations
export const openRegard2Modal = (): void => {
  window.store.dispatch({
    type: 'open regard2 modal',
  });
};

export const RefreshButton: FC<{ onBeforeRefresh: () => void }> = ({ onBeforeRefresh }) => {
  if (isRefreshButtonDisabled) {
    return null;
  }

  const recentTimeContent = getRecentTimeContent();

  return (
    <>
      <StaticTooltip
        display="block"
        placement="bottom"
        style={buttonTooltipStyle}
        tooltip="Refresh"
      >
        <IconButton
          color="tertiary-grey"
          data-cy-refresh-button
          iconName="refresh"
          // The following arrangement is for testing copying of bold text in production.
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            const modKeys = getModKeysForEvent(e);
            if (modKeys.shift && (modKeys.ctrl || modKeys.alt)) {
              openRegard2Modal();
              e.preventDefault();
              return;
            }

            onBeforeRefresh();
            refresh();
          }}
          size="small"
          style={buttonStyle}
        />
      </StaticTooltip>
      {recentTimeContent && <span style={recentTimeStyle}>{recentTimeContent}</span>}
    </>
  );
};
