import { FC, useCallback, ReactElement } from 'react';
import * as Sentry from '@sentry/react';
import { DefaultErrorFallback } from './DefaultErrorFallback';

type ErrorData = {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
};

type FallbackRender = (errorData: ErrorData) => ReactElement;

type ErrorBoundaryProps = Sentry.ErrorBoundaryProps & {
  inline?: boolean;
};

export const ErrorBoundary: FC<ErrorBoundaryProps> = (props) => {
  const { inline, ...rest } = props;

  const fallbackRender: FallbackRender = useCallback(
    (fallbackProps: ErrorData) => <DefaultErrorFallback inline={inline} {...fallbackProps} />,
    [inline]
  );

  const onErrorDefault = useCallback((error: Error) => {
    console.error('Encountered a caught error ', error);
  }, []);

  return (
    <Sentry.ErrorBoundary
      {...rest}
      fallback={rest.fallback ?? fallbackRender}
      onError={rest.onError ?? onErrorDefault}
    />
  );
};
