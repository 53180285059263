import { FC } from 'react';
import _ from 'lodash';

import { formatDatetime } from '../../../utils';
import { CernerTable } from './CernerTable';
import { useDemoImageRows } from '../demoData';
import { CernerCompactTableWithDescription } from './CernerCompactTableWithDescription';

export const CernerTabImageContent: FC = () => {
  const { rows, selectedItem } = useDemoImageRows();

  const descriptionBody = (
    <>
      <div>
        <b>Description: </b>
        {_.startCase(selectedItem.description)}
      </div>
      <div>
        <b>Order Date: </b>
        {formatDatetime(selectedItem.orderDate.toISOString())}
      </div>
      <div>
        <b>Narrative: </b>
        {selectedItem.noteText}
      </div>
    </>
  );

  return (
    <>
      <CernerTable label="Images" rows={[]} />
      <div
        style={{
          alignItems: 'start',
          display: 'flex',
          marginTop: 10,
          minHeight: 0,
        }}
      >
        <CernerCompactTableWithDescription descriptionBody={descriptionBody} rows={rows} />
      </div>
    </>
  );
};
