import { ChunkOfLines } from '../@types/state';
import { getNonGlobalLineRegex } from '../controllers/regex';
import { htmlStringToHtmlLines } from './htmlStringToHtmlLines';
import { htmlStringToPlainText } from './htmlStringToPlainText';
import { isFooterChunk } from './isFooterChunk';

const lineRegex = getNonGlobalLineRegex();

export const isFooterDiv = (div: HTMLDivElement): boolean => {
  let titleLineWasAdded = false;
  const chunk: Pick<ChunkOfLines, 'lines'> = { lines: [] };

  const rawHtmlLines = htmlStringToHtmlLines(div.innerHTML as HtmlString);
  rawHtmlLines?.forEach((rawHtmlLine) => {
    const rawPlainText = htmlStringToPlainText(rawHtmlLine);

    const match: (string | undefined)[] = lineRegex.exec(rawPlainText) || [];
    const [
      ,
      //
      titleSignifier = '',
      ,
      bulletSignifier = '',
      plainText = '',
    ] = match;
    const plainTextTrimmed = plainText.trim();

    if (titleSignifier && plainText) {
      titleLineWasAdded = true;
      chunk.lines.push({
        html: rawHtmlLine,
        plainTextWithoutTitleSignifier: plainTextTrimmed,
        titleSignifier: '', // doesn't matter
        type: 'title',
      });
    } else if (titleLineWasAdded && bulletSignifier) {
      chunk.lines.push({
        bulletSignifier: '', // doesn't matter
        html: rawHtmlLine,
        plainTextWithoutBulletSignifier: '', // doesn't matter
        type: 'bullet',
      });
    } else {
      chunk.lines.push({
        html: rawHtmlLine,
        type: 'freetext',
      });
    }
  });

  return isFooterChunk(chunk);
};
