import { MutableRefObject, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

import { useDispatch } from '~/app/store';
import { track } from '~/app/analytics';
import { htmlStringToPlainText, uniqueId, wrapWithShelfSignifiers } from '~/app/utils';

import { useNoteContext } from '../noteProvider';
import { measureOnInput, measureThrottle, measureSetTimeout } from '../measure';
import { processRawHtml } from '../conditionRte/processRawHtml';

type ShelfDividerOnHtmlInputFnArgs = {
  caretPos: number;
  prevHtmlRef: MutableRefObject<HtmlString>;
  rawHtml: HtmlString;
};

export type ShelfDividerOnHtmlInputFn = ({
  caretPos,
  prevHtmlRef,
  rawHtml,
}: ShelfDividerOnHtmlInputFnArgs) => void;

const EDIT_DEBOUNCE_DELAY = 5000;

export const useShelfDividerOnHtmlInput = (id: string): ShelfDividerOnHtmlInputFn => {
  const dispatch = useDispatch();
  const { throttleDelay, setTimeoutDelay } = useNoteContext(
    ({ throttleDelay, setTimeoutDelay }) => ({
      throttleDelay,
      setTimeoutDelay,
    })
  );

  const debouncedTracker = useMemo(
    () =>
      debounce(
        (html: HtmlString) =>
          track.shelfDividerEdited({
            html,
            text: htmlStringToPlainText(html),
          }),
        EDIT_DEBOUNCE_DELAY
      ),
    []
  );

  const updateLater = useMemo(
    () =>
      throttleDelay(
        ({
          caretPos,
          rawHtml,
          prevHtmlRef,
          uniqueId,
        }: ShelfDividerOnHtmlInputFnArgs & {
          uniqueId: string;
        }): void => {
          const html = processRawHtml(rawHtml);
          const previousHtml = prevHtmlRef.current;

          // Prevent identical updates
          if (html !== previousHtml) {
            // eslint-disable-next-line no-param-reassign
            prevHtmlRef.current = html;

            const wrappedHtml = wrapWithShelfSignifiers(html);

            measureThrottle(uniqueId);
            debouncedTracker(wrappedHtml);

            setTimeoutDelay(() => {
              measureSetTimeout();
              dispatch({
                type: 'modify note block',
                payload: {
                  noteBlockCaretPos: caretPos,
                  noteBlockId: id,
                  noteBlockHtml: wrappedHtml,
                  type: 'shelfDividerTyping',
                },
              });
            });
          }
        }
      ),
    [throttleDelay, debouncedTracker, setTimeoutDelay, dispatch, id]
  );

  const onHtmlInput: ShelfDividerOnHtmlInputFn = useCallback(
    ({ caretPos, prevHtmlRef, rawHtml }) => {
      const id = uniqueId();
      measureOnInput(id);
      updateLater({ caretPos, prevHtmlRef, rawHtml, uniqueId: id });
    },
    [updateLater]
  );

  return onHtmlInput;
};
