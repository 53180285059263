import { SummaryP, SummarySpanB } from './common';

export const hypertensionSummary = (
  <>
    <SummaryP first>
      Regard will trigger a positive diagnosis if there is a history of “hypertension” in the
      patient’s notes.
    </SummaryP>
    <SummaryP>
      If no history is found, Regard will trigger a positive diagnosis if the patient has home
      anti-hypertensive meds <SummarySpanB>and</SummarySpanB> the patient’s BP &gt;140/90 on 2
      consecutive readings less than 24h apart.
    </SummaryP>
  </>
);
