import { SummaryP, SummarySpanB, SummaryUl, SummaryOl, SummaryLi } from './common';

export const pancytopeniaSummary = (
  <>
    <SummaryP first>
      Regard will trigger <SummarySpanB>positive</SummarySpanB> for pancytopenia if hemoglobin,
      white blood cell, and platelets are <SummarySpanB>all</SummarySpanB> low.
    </SummaryP>
    <SummaryOl>
      <li>
        If laboratory threshold not available, use following cutoffs: (Valent 2012)
        <SummaryUl innerList>
          <SummaryLi hideBullet>
            <SummaryUl innerList>
              <li>Hemoglobin: &lt;12 g/dL (female) / &lt;13 g/dL (male)</li>
              <li>
                Absolute neutrophil count (ANC): &lt;1800 /uL, <SummarySpanB>or</SummarySpanB> WBC
                &lt; 4000 /uL
              </li>
              <li>Platelets: &lt;150 x 10&#8313;/L</li>
            </SummaryUl>
          </SummaryLi>
        </SummaryUl>
      </li>
      <li>
        Screen for hematologic emergencies / peripheral destruction or sequestration:
        <SummaryUl innerList>
          <li>
            Screen for hemolytic anemia: check for: LDH (high), haptoglobin (low), direct bilirubin
            (high), “schistocytes” in peripheral blood smear
            <SummaryUl innerList>
              <li>
                if 2 or more are abnormal: <SummarySpanB>hemolysis</SummarySpanB> = true
              </li>
              <li>
                report relevant lab values and include: “pancytopenia with hemolysis, consider DIC
                or TTP”
              </li>
            </SummaryUl>
          </li>
          <li>
            If hemolysis is true, check for DIC or TTP diagnosis:
            <SummaryUl innerList>
              <li>
                Check for: fibrinogen and ADAMTS13
                <SummaryUl innerList>
                  <li>
                    If fibrinogen is low, report value and replace hemolysis report with: “consider
                    DIC”
                  </li>
                  <li>
                    If ADAMTS13 is low, report value and replace hemolysis report with: “consider
                    TTP”
                  </li>
                </SummaryUl>
              </li>
            </SummaryUl>
          </li>
        </SummaryUl>
      </li>
      <li>
        Screen for hypoproliferative bone marrow:
        <SummaryUl innerList>
          <li>
            If absolute reticulocytes &lt;20,000, <SummarySpanB>hypoproliferative</SummarySpanB> =
            true
          </li>
          <li>report: “concern for hypoproliferative pancytopenia”</li>
        </SummaryUl>
      </li>
    </SummaryOl>
  </>
);
