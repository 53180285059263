import { SummaryP, SummarySpanB, SummaryUl, SummarySpanI } from './common';

export const anemiaSummary = (
  <>
    <SummaryP first>
      Anemia is recommended for two or more low hemoglobin (hgb) labs within 2 days before the start
      of current encounter or one hgb value &lt; 9 g/dl.
    </SummaryP>
    <SummaryP>
      A <SummarySpanB>baseline hgb</SummarySpanB> is determined if there are more than 3 hgb labs
      available over at least a 12-week period <u>prior to the current encounter</u>. The baseline
      is averaged but weighted by most recent labs relative to the cutoff.
    </SummaryP>
    <SummaryP>
      <SummarySpanB>Anemia type</SummarySpanB> of macrocytic, normocytic or microcytic results if a
      mean corpuscular volume (MVC) lab is available.
    </SummaryP>
    <SummaryP>
      <SummarySpanB>Acuity</SummarySpanB> is resulted as:
    </SummaryP>
    <SummaryUl>
      <li>
        <SummarySpanI>Chronic:</SummarySpanI> if the baseline hgb is less than normal; or if a
        previous ICD10 code is found.
      </li>
      <li>
        <SummarySpanI>Acute:</SummarySpanI> if the last hgb lab is low and less than the baseline
        hemoglobin - 1 g/dL; if acute blood loss is present in a note; or GI bleed condition is
        active
      </li>
    </SummaryUl>
  </>
);
