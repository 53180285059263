import { FC, useCallback } from 'react';

import { embedExternalCssStyles } from './embedExternalCssStyles';
import { useUserFeedbackForm } from './useUserFeedbackForm';
import { UserFeedbackToast } from '../userFeedbackToast';
import { UserFeedbackForm } from '../userFeedbackForm';
import { closeFeedback as createCloseFeedbackAction } from '../../../../actions/ui';
import { createSelector, useDispatch, useShallowEqualSelector } from '../../../../store';

// NOTE: `embedCssStyles` must be run before submitting feedback.
setTimeout(embedExternalCssStyles, 1000);

const userFeedbackOpenSelector = createSelector(
  (state) => state,
  ({ regardNote, ui }) => ({
    feedbackOpen: ui.feedbackOpen,
    pt: regardNote.pt,
  })
);

export const UserFeedbackWidget: FC<{ fullSize?: boolean }> = ({ fullSize = false }) => {
  const { feedbackOpen, pt } = useShallowEqualSelector(userFeedbackOpenSelector);

  const dispatch = useDispatch();
  const closeFeedback = useCallback(() => dispatch(createCloseFeedbackAction()), [dispatch]);

  const { toastClose, ...rest } = useUserFeedbackForm({ closeFeedback, pt });

  const sent = rest.formState.type === 'sent';

  const showFeedbackForm = fullSize ? feedbackOpen : feedbackOpen && !sent;

  return (
    <>
      <UserFeedbackForm {...rest} fullSize={fullSize} visible={showFeedbackForm} />
      <UserFeedbackToast close={toastClose} fullSize={fullSize} visible={feedbackOpen && sent} />
    </>
  );
};
