import { ConditionLineStatus } from '../../../@types';
import { theme, ThemeColor } from '../../../reuse';

export const statusStyleMap: Record<
  ConditionLineStatus,
  {
    backgroundColor: ThemeColor;
    width: 0 | 2 | 4;
  }
> = {
  new: {
    backgroundColor: theme.colors.green,
    width: 4,
  },
  updated: {
    backgroundColor: theme.colors.green,
    width: 4,
  },
  existing: {
    backgroundColor: theme.colors.grey3,
    width: 2,
  },
  none: {
    backgroundColor: theme.colors.none,
    width: 0,
  },
} as const;

export const criticalStyle = {
  backgroundColor: theme.colors.red,
};

export const nextStatusStyleMap: Record<
  ConditionLineStatus,
  Record<
    ConditionLineStatus,
    {
      borderBottomLeftRadius?: 2;
      borderBottomRightRadius?: 2;
    }
  >
> = {
  new: {
    new: {},
    updated: {},
    existing: {
      borderBottomRightRadius: 2,
    },
    none: {
      borderBottomRightRadius: 2,
    },
  },
  updated: {
    new: {},
    updated: {},
    existing: {
      borderBottomRightRadius: 2,
    },
    none: {
      borderBottomRightRadius: 2,
    },
  },
  existing: {
    new: {},
    updated: {},
    existing: {},
    none: {
      borderBottomLeftRadius: 2,
      borderBottomRightRadius: 2,
    },
  },
  none: {
    new: {},
    updated: {},
    existing: {},
    none: {},
  },
} as const;

export const previousStatusStyleMap: Record<
  ConditionLineStatus,
  Record<
    ConditionLineStatus,
    {
      borderTopLeftRadius?: 2;
      borderTopRightRadius?: 2;
    }
  >
> = {
  new: {
    new: {},
    updated: {},
    existing: {
      borderTopRightRadius: 2,
    },
    none: {
      borderTopRightRadius: 2,
    },
  },
  updated: {
    new: {},
    updated: {},
    existing: {
      borderTopRightRadius: 2,
    },
    none: {
      borderTopRightRadius: 2,
    },
  },
  existing: {
    new: {},
    updated: {},
    existing: {},
    none: {
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
    },
  },
  none: {
    new: {},
    updated: {},
    existing: {},
    none: {},
  },
} as const;
