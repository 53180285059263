import { createSelector, useSelector } from '../../../store';

const selectReviewMode = createSelector(
  (state) => state.regardNote.reviewMode,
  (x) => x
);

export const useReviewMode = (): boolean => {
  const reviewMode = useSelector(selectReviewMode);

  return reviewMode;
};
