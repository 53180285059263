import { EMPTY_BR_HTML_LINE, EMPTY_LINE_BR, LINE_DIVIDER_TAG } from '~/app/constants';

const emptyLineMatch = /<div><br\s*\/?><\/div>/gi;
const brMatch = /<br\s*\/?>/gi;

export const replaceBrWithDivBlocks = (html: HtmlString): HtmlString => {
  // 1. Pre-split on empty line brs to avoid them being removed by the main regex
  const emptyLineSeparatedLines = html.split(emptyLineMatch);

  // 2. No need to do anything if there are no remaining br tags
  if (!emptyLineSeparatedLines.join('').match(brMatch)) {
    return html;
  }

  // 3. Split lines on br tags to wrap them in divider tags
  const wrappedLines = emptyLineSeparatedLines.map((lineHtml) => {
    const brSeparatedLines = lineHtml.split(brMatch);
    return brSeparatedLines
      .map((innerLine, i) =>
        i !== brSeparatedLines.length - 1 || innerLine
          ? `<${LINE_DIVIDER_TAG}>${innerLine || EMPTY_LINE_BR}</${LINE_DIVIDER_TAG}>`
          : ''
      )
      .filter(Boolean)
      .join('') as HtmlString;
  });

  // 4. Join the wrapped lines back together with an empty line br
  return wrappedLines.join(EMPTY_BR_HTML_LINE) as HtmlString;
};
