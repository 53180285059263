import _ from 'lodash';
import { shallowEqual } from 'fast-equals';
import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';

import { Source } from '../../../@types/state';
import { Drawer, theme, zIndices } from '../../../reuse';
import { useMeshedContext } from '../MeshedProvider';
import { drawerAnimationDuration } from '../../../reuse/drawerBase/css';
import { createSelector, useSelector, useDispatch } from '../../../store';
import { capitalizeFirstLetter, formatDateLong, isElementVisibleInViewport } from '../../../utils';
import { MIN_APP_WIDTH, DESKTOP_GUTTER_WIDTH_VALUE } from '../useMeshedNoteAppStyle';
import { authorDefault } from '../../note/const';

const OFFSET_FROM_PARENT = '32px';

const getStyle = _.memoize(
  ({
    isVerticalLayout,
    width,
    isScaledHorizontalLayout,
  }: {
    isVerticalLayout: boolean;
    width: number;
    isScaledHorizontalLayout: boolean;
  }) => {
    const dxDetailsScaledWidth = (width - DESKTOP_GUTTER_WIDTH_VALUE) / 3;
    const drawerWidth = isScaledHorizontalLayout
      ? `calc(${dxDetailsScaledWidth}px - 40px)`
      : `calc(${MIN_APP_WIDTH} - 40px)`;
    return {
      curtain: {
        height: `calc(100% - ${OFFSET_FROM_PARENT})`,
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
      },
      drawer: {
        height: 'calc(100% - 32px)',
        maxWidth: 'none',
        minWidth: `calc(${MIN_APP_WIDTH} - ${OFFSET_FROM_PARENT})`,
        position: 'absolute',
        width: isVerticalLayout ? 'calc(100% - 40px)' : drawerWidth,
      },
      header: {
        borderBottom: `1px solid ${theme.colors.grey4}`,
        fontSize: 14,
        fontWeight: 600,
        height: 42,
        padding: '10px 24px',
      },
    } as const;
  }
);

const sourceDrawerContentStyle = {
  padding: '10px 24px',
  whiteSpace: 'pre-wrap',
} as const;

const matchStyle = {
  backgroundColor: theme.colors.lightBlue2,
} as const;

const getContentFromSource = (source: Source): ReactNode => {
  if ('match' in source) {
    const splits = source.text.split(source.match);

    if (splits.length >= 2) {
      // match found
      return splits.map((split, i) => {
        const key = i * 2;

        return [
          i ? (
            <span
              key={key - 1}
              data-cy-source-drawer-matched-phrase
              data-scroll-to-source-drawer-matched-phrase
              style={matchStyle}
            >
              {source.match}
            </span>
          ) : null,
          <span key={key}>{split}</span>,
        ];
      });
    }
  }

  // match not found
  return source.text;
};

const getTitleFromSource = ({
  author,
  date,
  kind,
}: Pick<Source, 'author' | 'date' | 'kind'>): string =>
  `${capitalizeFirstLetter(kind)} - ${author || authorDefault} (${formatDateLong(date)})`;

const openSourceSelector = createSelector(
  (state) => state.ui.openSource,
  (source) => source
);

export const SourceDrawer: FC = () => {
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch({ type: 'clear open source' }), [dispatch]);

  const source = useSelector(openSourceSelector, shallowEqual);
  const isOpen = !!source;

  const { width, isVerticalLayout, isScaledHorizontalLayout } = useMeshedContext(
    ({ width, isVerticalLayout, isScaledHorizontalLayout }) => ({
      width,
      isVerticalLayout,
      isScaledHorizontalLayout,
    })
  );

  const timeout = useRef(0);
  useEffect(() => {
    if (isOpen) {
      timeout.current = window.setTimeout(() => {
        const matchEl = document.querySelector<HTMLElement>(
          '[data-scroll-to-source-drawer-matched-phrase]'
        );
        if (matchEl && !isElementVisibleInViewport(matchEl)) {
          matchEl.scrollIntoView({ behavior: 'smooth' });
        }
      }, drawerAnimationDuration);
    } else {
      window.clearTimeout(timeout.current);
    }

    return () => window.clearTimeout(timeout.current);
  }, [isOpen]);

  return (
    <Drawer
      close={close}
      content={
        <div style={sourceDrawerContentStyle}>{source ? getContentFromSource(source) : ''}</div>
      }
      data-cy-source-drawer
      enableCurtain={isVerticalLayout}
      isOpen={isOpen}
      style={getStyle({ width, isVerticalLayout, isScaledHorizontalLayout })}
      title={source ? getTitleFromSource(source) : ''}
      zIndex={zIndices.sourceDrawer}
    />
  );
};
