// This is a button that has the appearance of a link.

import { forwardRef } from 'react';

import { Root } from './css';
import { ButtonTagProps } from '../buttonTag';

export type LinkButtonProps = AugmentedRequired<Omit<ButtonTagProps, 'submit'>, 'onClick'>;

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>((props, ref) => (
  <Root ref={ref} submit={false} {...props} />
));
LinkButton.displayName = 'LinkButton';
