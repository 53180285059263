import { FC, MouseEventHandler, useCallback, useMemo } from 'react';

import { COMPANY_NAME } from '../../../constants';
import { LinkButton } from '../../../reuse';
import { store } from '../../../store';
import { track } from '../../../analytics';
import { openDxProcess } from '../../../actions/ui';
import { diagnoses } from '../dxProcessDrawer';

const wrapperStyle = {
  display: 'flex',
} as const;
const titleSpanStyle = {
  flex: '1',
} as const;
const howButtonStyle = {
  flex: '0 1 content',
  fontWeight: 'normal',
} as const;

export const DxDetailLinesTitle: FC<{
  module: string;
}> = ({ module }) => {
  const foundDiagnosis = useMemo(
    () => diagnoses.find((diagnosis) => diagnosis.conditionKeys.includes(module)),
    [module]
  );
  const hasSummary = !!foundDiagnosis?.summary;

  const onHowClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      // Prevent the clicking from bubbling up and affecting the DxDetailLines Accordion
      e.stopPropagation();

      if (foundDiagnosis) {
        track.openedDxProcessDrawerViaDxDetails(foundDiagnosis);
        store.dispatch(openDxProcess(foundDiagnosis));
      }
    },
    [foundDiagnosis]
  );

  return (
    <span style={wrapperStyle}>
      <span style={titleSpanStyle}>{COMPANY_NAME} Dx Lines</span>
      {hasSummary && (
        <LinkButton onClick={onHowClicked} style={howButtonStyle}>
          How is this Dxed?
        </LinkButton>
      )}
    </span>
  );
};
