import { IndexingData, PostModuleNoteBlock } from '~/app/@types/state';

import { getBulletsByTrimmedTextKeyForMultipleModules } from './getBulletsByTrimmedTextKeyForMultipleModules';

export const addBulletsByTrimmedTextKey = <
  T extends Pick<PostModuleNoteBlock, 'modules' | 'type'>
>({
  protoNoteBlock,
  conditionsByModule,
}: {
  protoNoteBlock: T;
  conditionsByModule: IndexingData['conditionsByModule'];
}): T & Pick<PostModuleNoteBlock, 'bulletsByTrimmedTextKey' | 'diff' | 'isNew'> => {
  const { modules } = protoNoteBlock;

  if (modules.length === 1) {
    const module = modules[0]; // safe assumption
    const { bulletsByTrimmedTextKey, diff } = conditionsByModule[module];

    return {
      ...protoNoteBlock,
      bulletsByTrimmedTextKey,
      diff,
      isNew: diff === 'NEW',
    };
  }

  if (modules.length > 1) {
    const { bulletsByTrimmedTextKey } = getBulletsByTrimmedTextKeyForMultipleModules({
      conditionsByModule,
      modules,
    });

    return {
      ...protoNoteBlock,
      bulletsByTrimmedTextKey,
      // NOTE: At the moment, this is correct (i.e. it matches the plain text
      //  logic); condition areas with more than one module get a `null` diff
      diff: null,
      isNew: false,
    };
  }

  // modules.length === 0
  return {
    ...protoNoteBlock,
    bulletsByTrimmedTextKey: {},
    diff: null,
    isNew: false,
  };
};
