import { noop } from 'lodash';
import { FC, createContext, useCallback, useState } from 'react';
import { createSelectorProvider, replaceOrAdd, useContextSelector } from '~/app/utils';
import { DislikedAiResponseSurvey } from '~/app/analytics/track';
import { UseChatApiReturn, useChatApi } from './useChatApi';

export type ChatReplyLiked = 'liked' | 'disliked' | undefined;

type ChatReplyMetadata = {
  liked: ChatReplyLiked;
};

type DislogDialogOnSubmit = (args: DislikedAiResponseSurvey) => void;

type DislogDialogState = {
  isOpen: boolean;
  onSubmit: DislogDialogOnSubmit;
  replyIndex: number | undefined;
};

type DetailsContext = UseChatApiReturn & {
  // Chat
  chatRepliesLength: number;
  chatRepliesMetadata: ChatReplyMetadata[];
  model: string;
  // Dislike Dialog
  closeDislikeDialog(): void;
  dislikeDialogState: DislogDialogState;
  likeReply(args: { index: number; liked: ChatReplyLiked }): void;
  openDislikeDialog(args: { index: number; onSubmit: DislogDialogOnSubmit }): void;
};

const defaultContext: DetailsContext = {
  // Chat
  chatId: '',
  chatCheckingForAiResponse: false,
  chatReplies: [],
  chatRepliesLength: 0,
  chatRepliesMetadata: [],
  model: '',
  prompt: noop,
  // Dislike Dialog
  closeDislikeDialog: noop,
  dislikeDialogState: {
    isOpen: false,
    onSubmit: noop,
    replyIndex: undefined,
  },
  likeReply: noop,
  openDislikeDialog: noop,
};

const context = createContext<DetailsContext>(defaultContext);
const DetailsContextProvider = createSelectorProvider(context);

export const useDetailsContext = <T,>(selector: (context: DetailsContext) => T): T =>
  useContextSelector<DetailsContext, T>(context, selector);

export const DetailsProvider: FC = ({ children }) => {
  // Dislike Dialog

  const [dislikeDialogState, setDislikeDialogState] = useState<DislogDialogState>(
    defaultContext.dislikeDialogState
  );

  const openDislikeDialog = useCallback(
    ({ index, onSubmit }: { index: number; onSubmit(args: DislikedAiResponseSurvey): void }) => {
      setDislikeDialogState({
        isOpen: true,
        onSubmit,
        replyIndex: index,
      });
    },
    [setDislikeDialogState]
  );

  const closeDislikeDialog = useCallback(
    () => setDislikeDialogState(defaultContext.dislikeDialogState),
    [setDislikeDialogState]
  );

  // Chat

  const { chatId, chatReplies, chatCheckingForAiResponse, model, prompt } = useChatApi();

  const [chatRepliesMetadata, setChatReplyMetadata] = useState(defaultContext.chatRepliesMetadata);
  const likeReply = ({ index, liked }: { index: number; liked: ChatReplyLiked }) => {
    setChatReplyMetadata((prev) => replaceOrAdd({ liked }, index, prev));
  };

  return (
    <DetailsContextProvider
      value={{
        chatId,
        chatCheckingForAiResponse,
        chatReplies,
        chatRepliesLength: chatReplies.length,
        chatRepliesMetadata,
        closeDislikeDialog,
        dislikeDialogState,
        likeReply,
        model,
        openDislikeDialog,
        prompt,
      }}
    >
      {children}
    </DetailsContextProvider>
  );
};
