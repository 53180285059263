import { SummaryOl } from './common';

export const giBleedSummary = (
  <SummaryOl>
    <li>
      The algorithm searches the patient’s record for GI bleed, including specificity for upper vs.
      lower.
    </li>
    <li>
      Any procedures from past encounters, such as a colonoscopy or EGD will display along with any
      transfusions from the current encounter. Regard will display diet orders, current blood
      counts, and coagulation studies along with associated medications (proton pump inhibitors,
      somatostatin analogues, and vasopressin analogues).
    </li>
    <li>Regard alerts the provider of any active antiplatelet or anticoagulant medications.</li>
  </SummaryOl>
);
