import styled from '@emotion/styled';
import { CSSProperties, FC } from 'react';

import { TooltipProps } from './types';

export const TOOLTIP_HOVER_CLASS = 'is--hover';

export type StaticTooltipProps = TooltipProps & {
  className?: string;
  display?: 'block' | 'inline-block';
  style?: CSSProperties;
};

type RootProps = Pick<StaticTooltipProps, 'disabled' | 'display' | 'visible'>;
const Root = styled.span<RootProps>(
  {
    position: 'relative',
  },
  ({ disabled, display, visible }) => ({
    display: display ?? 'block',
    ...(disabled || visible === false ? { '.tippy-box': { display: 'none' } } : undefined),
    ...(visible === true
      ? undefined
      : {
          [`&:not(:hover)&:not(.${TOOLTIP_HOVER_CLASS})`]: { '.tippy-box': { display: 'none' } },
        }),
  })
);

/**
 * Pros:
 * - Fast.
 *
 * Cons:
 * - Tooltip can appear of of view if close to the edge of the screen or parent
 *   container.
 *
 * Additional Notes:
 * - StaticTooltip creates a span around it's child, whereas DynamicTooltip does
 *    not. This means that if you wanted to convert a StaticTooltip to a
 *   DynamicTooltip, you would take the `css` prop of StaticTooltip and put it
 *   on a new span nested in DynamicTooltip. Furthermore, the `display` prop on
 *   StaticTooltip should be added to the styles of that new span.
 * - Span is used instead of a div to not break standard html when a StaticTooltip
 *   is used inside a paragraph tag (like in condition titles).
 */
export const StaticTooltip: FC<StaticTooltipProps> = ({
  children,
  className,
  disabled,
  display,
  placement,
  tooltip,
  visible,
  ...rest
}) => (
  <Root className={className} disabled={disabled} display={display} visible={visible} {...rest}>
    {children}
    <span className="tippy-box" data-placement={placement} data-static-tooltip>
      <span className="tippy-content">{tooltip}</span>
      <span className="tippy-arrow" />
    </span>
  </Root>
);
