import { BulletNoteStatus } from '~/app/@types';
import {
  NoteBlock,
  PostModuleNoteBlock,
  PreModuleNoteBlock,
  ChunkOfLinesWithText,
  BlockTypes,
} from '~/app/@types/state';
import {
  insert,
  remove,
  replace,
  replaceWithMany,
  moveBlock,
  uniqueId,
  linesToPlainText,
  nth,
} from '~/app/utils';
import { EMPTY_BR_HTML_LINE } from '~/app/constants';

import { track } from '~/app/analytics';
import { findIndex, findLastIndex } from 'lodash';

import { EditedCase, EditedCaseResult } from '../getEditedCase';
import {
  getDominantTitleSignifierFromChunksWithLines,
  getIsNumberTitleSignifier,
} from '../getDominantTitleSignifier';
import { createNewTitleSignifier } from '../createNewTitleSignifier';
import { renumberNoteBlocks } from '../renumberNoteBlocks';
import { addNoteBlockAtIndex } from '../addNoteBlockAtIndex';
import { getTitleSignifierForFormat } from '../getDominantTitleSignifier/getDominantTitleSignifier';

// Merging across the shelf divider boundary is not supported
const ALLOWED_MERGE_TYPES: BlockTypes[] = ['condition', 'shelvedCondition'];

const addUniqueId = <T extends object>(obj: T): T & { id: string } => ({
  ...obj,
  id: uniqueId(),
});

export enum RetainStaleNoteBlockDataCase {
  HideOrMonitorBullet = 'hideOrMonitorBullet',
  SameTitle = 'sameTitle',
  Done = 'done',
  NeedsModules = 'needsModules',
  NeedsModulesNoCaret = 'needsModulesNoCaret',
  Shelved = 'shelved',
}

export type RetainStaleNoteBlockDataResultHideOrMonitorBullet = {
  // `noteBlocksWithOutdatedEditedIndex` need updated suggestions.
  bulletStatus: BulletNoteStatus;
  bulletText: string;
  noteBlocksWithOutdatedEditedIndex: NoteBlock[];
  editedCase: EditedCase.HideOrMonitorBullet;
  editedIndex: number;
  retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.HideOrMonitorBullet;
};

export type RetainStaleNoteBlockDataResultSameTitle = {
  // `noteBlocks` is as `staleNoteBlocks`; it must have
  //  `editedIndex` replaced later
  noteBlocksWithOutdatedEditedIndex: NoteBlock[];
  noteBlockCaretPos: number;
  editedCase: EditedCase.AddBullet | EditedCase.SameTitle;
  editedIndex: number;
  editedPostModuleNoteBlock: PostModuleNoteBlock;
  retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.SameTitle;
};

export type RetainStaleNoteBlockDataResultDone = {
  noteBlocks: NoteBlock[];
  noteBlockCaretPos: number;
  editedCase: EditedCase.Add | EditedCase.DividerTitle | EditedCase.FormatNoteTitles;
  retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.Done;
};

export type RetainStaleNoteBlockDataNeedsModules = {
  noteBlockCaretPos: number;
  editedCase:
    | EditedCase.DifferentTitle
    | EditedCase.Dismiss
    | EditedCase.Move
    | EditedCase.Merge
    | EditedCase.Restore
    | EditedCase.SplitIntoMultipleChunks;
  editedIndex: number;
  movedIntoShelf?: boolean;
  movedOutOfShelf?: boolean;
  preModuleNoteBlocks: PreModuleNoteBlock[];
  retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules;
};

export type RetainStaleNoteBlockDataResultNeedsModulesNoCaret = {
  editedCase: EditedCase.NoStaleNoteBlocks;
  editedIndex: number;
  movedIntoShelf?: boolean;
  movedOutOfShelf?: boolean;
  preModuleNoteBlocks: PreModuleNoteBlock[];
  retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModulesNoCaret;
};

export type RetainStaleNoteBlockDataResult =
  | RetainStaleNoteBlockDataResultHideOrMonitorBullet
  | RetainStaleNoteBlockDataResultSameTitle
  | RetainStaleNoteBlockDataResultDone
  | RetainStaleNoteBlockDataNeedsModules
  | RetainStaleNoteBlockDataResultNeedsModulesNoCaret;

export const retainStaleNoteBlockData = ({
  editedCaseResult,
}: {
  editedCaseResult: EditedCaseResult;
}): RetainStaleNoteBlockDataResult => {
  switch (editedCaseResult.editedCase) {
    case EditedCase.HideOrMonitorBullet: {
      const { bulletStatus, bulletText, editedIndex, staleNoteBlocks } = editedCaseResult;

      return {
        bulletStatus,
        bulletText,
        noteBlocksWithOutdatedEditedIndex: staleNoteBlocks,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.HideOrMonitorBullet,
      };
    }
    case EditedCase.Add: {
      const { index, staleNoteBlocks } = editedCaseResult;

      // 1. Get the new signifier
      const {
        newConditionTitleSignifier,
        isNumberTitleSignifier,
        noteTitleSignifier,
        titleStyles,
      } = createNewTitleSignifier({
        noteBlocks: staleNoteBlocks,
        conditionIndex: index,
      });

      // 2. Get all note chunks with new condition text inserted
      const { noteBlocksWithAddedCondition, noteBlockCaretPos } = addNoteBlockAtIndex({
        index,
        newConditionTitleSignifier,
        staleNoteBlocks,
        titleStyles,
      });

      // 3. Renumber all conditions after the newly added condition if we're
      //  using a number-based title signifier in the note
      const renumberedNoteBlocksWithTextTimestamps = isNumberTitleSignifier
        ? renumberNoteBlocks({
            addTextTimestampToModifiedNoteBlocks: true,
            noteBlocks: noteBlocksWithAddedCondition,
            noteTitleSignifier,
            startingAtNoteBlockIndex: index + 1, // + 1 because we've added the new condition
          })
        : noteBlocksWithAddedCondition;

      return {
        noteBlocks: renumberedNoteBlocksWithTextTimestamps,
        noteBlockCaretPos,
        editedCase: editedCaseResult.editedCase,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.Done,
      };
    }
    case EditedCase.Dismiss: {
      const { conditionId, staleNoteBlocks } = editedCaseResult;

      // 1. Find the index of the dismissed condition.
      const editedIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);

      // 2. Remove the dismissed condition area (and dial back typing to PreModuleNoteBlock)
      const noteBlocksWithDismissedConditionRemoved: PreModuleNoteBlock[] = remove(
        editedIndex,
        1,
        staleNoteBlocks
      );

      // 3. Determine the note's title indicator style
      const noteTitleSignifier = getDominantTitleSignifierFromChunksWithLines(
        noteBlocksWithDismissedConditionRemoved
      );
      const isNumberTitleSignifier = getIsNumberTitleSignifier(noteTitleSignifier);

      // 4. Renumber all conditions after the dismissed condition if we're
      //  using a number-based title signifier in the note
      let renumberedNoteBlocks = isNumberTitleSignifier
        ? renumberNoteBlocks({
            addTextTimestampToModifiedNoteBlocks: true,
            noteBlocks: noteBlocksWithDismissedConditionRemoved,
            noteTitleSignifier,
            startingAtNoteBlockIndex: editedIndex,
          })
        : noteBlocksWithDismissedConditionRemoved;

      // 5. Update the caret position
      const previousNoteBlock = nth(renumberedNoteBlocks, editedIndex - 1);

      if (previousNoteBlock && previousNoteBlock.type !== 'shelfDivider') {
        renumberedNoteBlocks = replace(
          {
            ...previousNoteBlock,
            setCaretPosition: {
              caretPos: previousNoteBlock.text.length,
              timestamp: Date.now(),
            } as const,
          },
          editedIndex - 1,
          renumberedNoteBlocks
        );
      } else {
        renumberedNoteBlocks = replace(
          {
            ...renumberedNoteBlocks[editedIndex],
            setCaretPosition: {
              caretPos: 0,
              timestamp: Date.now(),
            } as const,
          },
          editedIndex,
          renumberedNoteBlocks
        );
      }

      return {
        noteBlockCaretPos: 0,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        preModuleNoteBlocks: renumberedNoteBlocks,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.Restore: {
      const { editedIndex, newChunkWithLines, staleNoteBlocks } = editedCaseResult;

      // 1. Determine the note's title indicator style
      const noteTitleSignifier = getDominantTitleSignifierFromChunksWithLines(staleNoteBlocks);
      const isNumberTitleSignifier = getIsNumberTitleSignifier(noteTitleSignifier);

      // 2. Insert the restored condition
      const noteBlocksWithRestoredConditionInserted = insert<PreModuleNoteBlock>(
        staleNoteBlocks,
        editedIndex,
        addUniqueId(newChunkWithLines)
      );

      // 3. Renumber all conditions after the restored condition if we're
      //  using a number-based title signifier in the note
      const renumberedNoteBlocks = isNumberTitleSignifier
        ? renumberNoteBlocks<PreModuleNoteBlock>({
            addTextTimestampToModifiedNoteBlocks: true,
            noteBlocks: noteBlocksWithRestoredConditionInserted,
            noteTitleSignifier,
            startingAtNoteBlockIndex: editedIndex,
          })
        : noteBlocksWithRestoredConditionInserted;

      return {
        noteBlockCaretPos: 0,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        preModuleNoteBlocks: renumberedNoteBlocks,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.Move: {
      const { fromIndex, staleNoteBlocks, toIndex, top, scrollToEditedIndex } = editedCaseResult;

      // 1. Reorder conditions while maintaining pretext on first condition
      const {
        blocks: movedBlocks,
        movedIntoShelf,
        movedOutOfShelf,
        editedIndex,
      } = moveBlock({
        blocks: staleNoteBlocks,
        fromIndex,
        toIndex,
      });

      // 2. Renumber note blocks

      // 2.a. Determine the note's title indicator style
      const noteTitleSignifier = getDominantTitleSignifierFromChunksWithLines(movedBlocks);
      const isNumberTitleSignifier = getIsNumberTitleSignifier(noteTitleSignifier);

      // 2.b. Renumber all conditions after the after the moved block if using number signifiers
      const renumberedNoteBlocks = isNumberTitleSignifier
        ? renumberNoteBlocks({
            addTextTimestampToModifiedNoteBlocks: true,
            noteBlocks: movedBlocks,
            noteTitleSignifier,
            startingAtNoteBlockIndex: Math.min(fromIndex, toIndex),
          })
        : movedBlocks;

      // 3. Mark the condition at the landing index for scrolling, if enabled
      const noteBlocksWithScrollIntoView = scrollToEditedIndex
        ? replace(
            {
              ...renumberedNoteBlocks[editedIndex],
              scrollIntoView: {
                timestamp: Date.now(),
                top,
              },
            },
            editedIndex,
            renumberedNoteBlocks
          )
        : renumberedNoteBlocks;

      return {
        noteBlockCaretPos: 0,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        movedIntoShelf,
        movedOutOfShelf,
        preModuleNoteBlocks: noteBlocksWithScrollIntoView,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.Merge: {
      const { fromIndex, staleNoteBlocks, toIndex } = editedCaseResult;

      const fromBlock = staleNoteBlocks[fromIndex];
      const toBlock = staleNoteBlocks[toIndex];
      const canMerge =
        fromBlock &&
        toBlock &&
        ALLOWED_MERGE_TYPES.includes(fromBlock.type) &&
        ALLOWED_MERGE_TYPES.includes(toBlock.type);

      if (!canMerge) {
        return {
          noteBlockCaretPos: 0,
          editedCase: editedCaseResult.editedCase,
          editedIndex: -1, // No blocks were directly edited
          preModuleNoteBlocks: staleNoteBlocks,
          retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
        };
      }

      const mergingUp = fromIndex > toIndex;
      const noteBlockCaretPos = mergingUp ? toBlock.text.length + 1 : fromBlock.text.length;
      const mergedLines = mergingUp
        ? [...toBlock.lines, ...fromBlock.lines]
        : [...fromBlock.lines, ...toBlock.lines];
      const mergedBlock = {
        ...toBlock,
        lines: mergedLines,
        text: linesToPlainText(mergedLines),
        textTimestamp: Date.now(),
        setCaretPosition: {
          caretPos: noteBlockCaretPos,
          timestamp: Date.now(),
        },
      };

      let mergedNoteBlocks = [...staleNoteBlocks];
      mergedNoteBlocks[toIndex] = mergedBlock;
      mergedNoteBlocks = remove(fromIndex, 1, mergedNoteBlocks);

      // toIndex may have shifted if merged down
      const editedIndex = mergedNoteBlocks.findIndex(({ id }) => id === mergedBlock.id);

      return {
        noteBlockCaretPos,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        preModuleNoteBlocks: mergedNoteBlocks,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.AddBullet:
    case EditedCase.SameTitle: {
      const { noteBlockCaretPos, editedIndex, editedPostModuleNoteBlock, staleNoteBlocks } =
        editedCaseResult;

      const typing = editedCaseResult.editedCase === EditedCase.SameTitle;

      return {
        noteBlockCaretPos,
        noteBlocksWithOutdatedEditedIndex: staleNoteBlocks,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        editedPostModuleNoteBlock: {
          ...editedPostModuleNoteBlock,
          // If this change didn't come from typing, then we must reset the
          //  editor's contents
          ...(typing ? undefined : { textTimestamp: Date.now() }),
        },
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.SameTitle,
      };
    }
    case EditedCase.SplitIntoMultipleChunks: {
      const {
        staleNoteBlockCaretPos: noteBlockCaretPos,
        caretPosition,
        editedIndex,
        newChunksWithLines,
        staleNoteBlocks,
      } = editedCaseResult;

      const splitNoteBlock = staleNoteBlocks[editedIndex];
      const splitNoteBlockModules =
        splitNoteBlock?.type === 'condition' ? splitNoteBlock.modules : [];
      track.splitNoteBlock({
        isSplitOnNewlineEnabled: true,
        modules: splitNoteBlockModules,
      });

      // 1. Replace the edited area with the new areas
      const preModuleNoteBlocks = replaceWithMany<PreModuleNoteBlock>(
        newChunksWithLines.map((chunk, index) => {
          // 1a. First chunk
          // - keep the same id on the first area
          // - add new text timestamp, because the system needs to force an
          //   override of the html rendered in the editor
          if (index === 0) {
            return {
              ...chunk,
              id: splitNoteBlock.id,
              textTimestamp: Date.now(),
            };
          }

          // 1b. Other chunks
          // - create new id
          // - remove empty newlines from start and end
          const indexOfFirstFilledLine = findIndex(
            chunk.lines,
            (line) => line.html !== EMPTY_BR_HTML_LINE
          );
          const indexOfLastFilledLine = findLastIndex(
            chunk.lines,
            (line) => line.html !== EMPTY_BR_HTML_LINE
          );
          const hasEmptyLinesAtStartOrEnd =
            indexOfFirstFilledLine > 0 || indexOfLastFilledLine < chunk.lines.length - 1;
          const lines = chunk.lines.slice(indexOfFirstFilledLine, indexOfLastFilledLine + 1);

          return {
            ...chunk,
            id: uniqueId(),
            ...(hasEmptyLinesAtStartOrEnd && {
              lines,
              text: linesToPlainText(lines),
            }),
          };
        }),
        editedIndex,
        staleNoteBlocks
      );

      // 2. Add caret
      let preModuleNoteBlocksWithCaret = preModuleNoteBlocks;
      if (caretPosition) {
        const nextNoteBlockAtIndex = nth(preModuleNoteBlocks, caretPosition.noteBlockIndex);

        if (nextNoteBlockAtIndex) {
          const updatedBlock = {
            ...nextNoteBlockAtIndex,
            setCaretPosition: {
              caretPos: caretPosition.caretPos,
              timestamp: Date.now(),
            },
          };
          preModuleNoteBlocksWithCaret = replace(
            updatedBlock,
            caretPosition.noteBlockIndex,
            preModuleNoteBlocks
          );
        }
      }

      // NOTE: If we were more sophisticated and could tell how titles were
      //  associated per module and could see if titles were maintained, etc.
      //  we could do some more optimizations here.
      return {
        noteBlockCaretPos,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        preModuleNoteBlocks: preModuleNoteBlocksWithCaret,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.DividerTitle: {
      const { noteBlockCaretPos, editedDividerNoteBlock, editedIndex, staleNoteBlocks } =
        editedCaseResult;

      return {
        noteBlocks:
          // 1. Replace the edited area with one that has the same id and
          //  different (i.e. new) lines
          replace(editedDividerNoteBlock, editedIndex, staleNoteBlocks),
        noteBlockCaretPos,
        editedCase: editedCaseResult.editedCase,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.Done,
      };
    }
    case EditedCase.DifferentTitle: {
      const { noteBlockCaretPos, editedIndex, editedPreModuleNoteBlock, staleNoteBlocks } =
        editedCaseResult;

      // 1. Replace the edited area with one that has the same id and new lines
      const preModuleNoteBlocks = replace<PreModuleNoteBlock>(
        editedPreModuleNoteBlock,
        editedIndex,
        staleNoteBlocks
      );

      // NOTE: We could be more specific about what it means for a title to
      //  change. If we have the same module mappings, then we're in the
      //  SameTitle case.
      return {
        noteBlockCaretPos,
        editedCase: editedCaseResult.editedCase,
        editedIndex,
        preModuleNoteBlocks,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModules,
      };
    }
    case EditedCase.FormatNoteTitles: {
      const { format, staleNoteBlocks } = editedCaseResult;

      const noteTitleSignifier = getTitleSignifierForFormat(format);
      const renumberedNoteBlocks = renumberNoteBlocks({
        addTextTimestampToModifiedNoteBlocks: true,
        noteBlocks: staleNoteBlocks,
        noteTitleSignifier,
        startingAtNoteBlockIndex: 0,
      });

      return {
        editedCase: editedCaseResult.editedCase,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.Done,
        noteBlocks: renumberedNoteBlocks,
        noteBlockCaretPos: 0,
      };
    }
    case EditedCase.NoStaleNoteBlocks:
    default: {
      const { chunksWithLines } = editedCaseResult;

      // We can't rely on the stale condition data
      const preModuleNoteBlocks = chunksWithLines.map(
        // 1. Add new ids
        addUniqueId
      ) as (ChunkOfLinesWithText & { id: string })[];

      return {
        editedCase: editedCaseResult.editedCase,
        editedIndex: -1, // No blocks were directly edited
        movedIntoShelf: false,
        movedOutOfShelf: false,
        preModuleNoteBlocks,
        retainStaleNoteBlockDataCase: RetainStaleNoteBlockDataCase.NeedsModulesNoCaret,
      };
    }
  }
};
