import _ from 'lodash';

import { BulletTag } from '~/app/@types';
import {
  APIHistoricalBullet,
  APIHistoricalObservationBullet,
  HistoricalBulletData,
  HistoricalCondition,
  PreviousConditionAssessmentPlan,
} from '~/app/@types/state';
import { stringifyItems } from '~/app/controllers/stringifyItems';

// This map is used when releasing bullet ID updates. Old bullet IDs are
// the key, and value is the new ID. Once these temporary bullet ID mappings
// are live in production for a week, they can be cleared out of this obj.
// NOTE: These bullet Ids can match ANY condition module. There is currently
//   no way to specify this functionality for specific modules.
const TEMP_BULLET_ID_REPLACEMENTS: Record<string, string> = {
  // ex: 'blood culture-obs-last': 'blood-culture',
};

const createOrUpdateEntryInBulletDataMap = ({
  bulletDataMapEntry,
  historicalBullet,
  historicalBulletText,
}: {
  bulletDataMapEntry: HistoricalBulletData | undefined;
  historicalBullet: APIHistoricalBullet;
  historicalBulletText: string;
}): HistoricalBulletData => {
  // If there is already an entry in the map, we add this bulletText variation
  if (bulletDataMapEntry) {
    const textVariationAlreadyCaptured =
      !!bulletDataMapEntry.textVariationsToTimestamps[historicalBulletText];

    // then we add either the first, for another timestamp for this bulletText variations
    if (textVariationAlreadyCaptured) {
      bulletDataMapEntry.textVariationsToTimestamps[historicalBulletText].push(
        historicalBullet.timestamp
      );
    } else {
      // eslint-disable-next-line no-param-reassign
      bulletDataMapEntry.textVariationsToTimestamps[historicalBulletText] = [
        historicalBullet.timestamp,
      ];
    }

    // return the mutated entry
    return bulletDataMapEntry;
  }

  // Create a fresh entry for the map with all relevant bullet data
  // we do not copy in "item" since we stringified it to bulletText & textVariations
  const bulletMetaData = _.omit(historicalBullet, ['item']);
  return {
    textVariationsToTimestamps: { [historicalBulletText]: [historicalBullet.timestamp] },
    isMed: historicalBullet.tags.includes(BulletTag.Medication),
    ...bulletMetaData,
  };
};

// iterate through all historical bullets to build two maps for diffing against "current"
// bullets and updating bullets in the basenote.
// `bulletIdToBulletDataMap` to provide a bullet data lookup table by bullet id
// `observationKeyToBulletDataMap` to provide a bullet data lookup table by observationKey
const historicalBulletsToDataMaps = (
  historicalBulletsForProcessing: HistoricalProcessingProps[]
) => {
  const bulletIdToBulletDataMap: Record<string, HistoricalBulletData> = {};
  historicalBulletsForProcessing.forEach(({ bulletText, bulletData }) => {
    const bulletId = TEMP_BULLET_ID_REPLACEMENTS[bulletData.id] || bulletData.id;
    bulletIdToBulletDataMap[bulletId] = createOrUpdateEntryInBulletDataMap({
      bulletDataMapEntry: bulletIdToBulletDataMap[bulletId],
      historicalBullet: bulletData,
      historicalBulletText: bulletText,
    });
  });

  const observationHistoricalBulletsForProcessing = historicalBulletsForProcessing.filter(
    ({ bulletData }) => 'observationKey' in bulletData && bulletData.observationKey
  );
  const observationKeyToBulletDataMap: Record<string, HistoricalBulletData> = {};
  observationHistoricalBulletsForProcessing.forEach(({ bulletText, bulletData }) => {
    const observationBulletData = bulletData as APIHistoricalObservationBullet; // we know this is true because of filter above
    observationKeyToBulletDataMap[observationBulletData.observationKey] =
      createOrUpdateEntryInBulletDataMap({
        bulletDataMapEntry: observationKeyToBulletDataMap[observationBulletData.observationKey],
        historicalBullet: bulletData,
        historicalBulletText: bulletText,
      });
  });

  return {
    bulletIdToBulletDataMap,
    observationKeyToBulletDataMap,
  };
};

type HistoricalProcessingProps = { bulletText: string; bulletData: APIHistoricalBullet };

const previousComplaintToBulletDataMaps = ({ bullets }: PreviousConditionAssessmentPlan) =>
  bullets.map<HistoricalProcessingProps>((sectionBullet) => ({
    bulletData: sectionBullet,
    bulletText: stringifyItems(sectionBullet.item),
  }));

// This creates a lookup object based on the previous day's Regard Conditions
// This lookup table is used by the `meshNotes` function to mark today's Regard
// Conditions/Bullets as 'existing', 'new', 'updated' or 'removed'
export const generatePreviousRegardConditionMap = (
  previousConditionAssessmentPlans: PreviousConditionAssessmentPlan[]
) => {
  const previousRegardConditionsMap: Record<string, HistoricalCondition> = {};

  // this map is used to detect Regard titles in the previous note text
  // Ex: { "Hyperkalemia:severe": "hyperkalemia" }
  const conditionNameToPreviousTitleMap: Record<string, string> = {};

  if (previousConditionAssessmentPlans) {
    previousConditionAssessmentPlans.forEach((complaint) => {
      const title = stringifyItems(complaint.title);
      conditionNameToPreviousTitleMap[complaint.module] = title;

      const allHistoricalAPIBullets = previousComplaintToBulletDataMaps(complaint);
      previousRegardConditionsMap[complaint.module] =
        historicalBulletsToDataMaps(allHistoricalAPIBullets);
    });
  }

  return {
    previousRegardConditionsMap,
    conditionNameToPreviousTitleMap,
  };
};
