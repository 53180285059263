import { FC } from 'react';

import { PatientLoadPhase, PatientLoadPhases } from '~/app/@types';
import RegardLogoSvg from '~/app/images/regard-logo.svg';
import { theme } from '~/app/reuse';

import { ProgressBar } from './ProgressBar';
import { useIncrementingNumber } from './useIncrementingNumber';

const DEFAULT_PHASE_DESCRIPTION = 'Fetching patient data';

const PHASE_DESCRIPTIONS: Record<PatientLoadPhases, string> = {
  [PatientLoadPhase.Started]: DEFAULT_PHASE_DESCRIPTION,
  [PatientLoadPhase.Fetching]: DEFAULT_PHASE_DESCRIPTION,
  [PatientLoadPhase.Mapping]: 'Analyzing patient data',
  [PatientLoadPhase.Dxing]: 'Running diagnostic algorithms',
  [PatientLoadPhase.Loading]: DEFAULT_PHASE_DESCRIPTION,
};

const backgroundStyle = {
  backgroundColor: theme.colors.blurple,
  height: '100%',
  width: '100%',
} as const;

const mainContainerStyle = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  color: theme.colors.white,
} as const;

const svgStyle = { width: '100%' } as const;

export const RegardLoading: FC<{
  isVerticalLayout: boolean;
  phase: PatientLoadPhases;
  percentage: number;
}> = ({ isVerticalLayout, percentage, phase }) => {
  const estimatedPercentage = useIncrementingNumber(percentage);

  return (
    <div style={backgroundStyle}>
      <div style={mainContainerStyle}>
        <div
          css={{
            '& svg': {
              fill: 'currentcolor',
            },
          }}
          style={{ width: isVerticalLayout ? '240px' : '320px', margin: 'auto' }}
        >
          <RegardLogoSvg style={svgStyle} />
        </div>
        <div
          data-cy-progress-text
          style={{
            textAlign: 'center',
            marginTop: '40px',
            marginBottom: isVerticalLayout ? '16px' : '20px',
          }}
        >
          {PHASE_DESCRIPTIONS[phase]} {estimatedPercentage}%
        </div>
        <ProgressBar isVerticalLayout={isVerticalLayout} percentage={estimatedPercentage} />
      </div>
    </div>
  );
};
