import { useMemo } from 'react';

import { ShelfDividerLine } from '~/app/@types/state';
import { createSelector, useShallowEqualSelector } from '~/app/store';
import { SHELF_DIVIDER_DEFAULT_TEXT, get, nth } from '~/app/utils';

const defaultDividerHtmlWithoutShelfSignifiers =
  `<div>${SHELF_DIVIDER_DEFAULT_TEXT}</div>` as HtmlString;

const textTimestampCache: Record<string /* note section id */, DateNumberValue> = {};
interface UseNoteBlockTextReturn {
  htmlDividerLineWithoutShelfSignifier: HtmlString;
  textTimestamp: DateNumberValue;
}

export const useShelfDividerText = (id: string): UseNoteBlockTextReturn => {
  const getNoteBlockText = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.noteBlocksById, id),
        (noteBlock) => {
          if (!noteBlock) {
            return {
              dividerLine: null,
              textTimestamp: undefined,
            };
          }

          const firstLine = nth(noteBlock.lines, 0);

          return firstLine?.type === 'shelfDivider'
            ? {
                dividerLine: firstLine as ShelfDividerLine,
                textTimestamp: noteBlock.textTimestamp,
              }
            : {
                dividerLine: null,
                textTimestamp: noteBlock.textTimestamp,
              };
        }
      ),
    [id]
  );
  const { dividerLine, textTimestamp } = useShallowEqualSelector(getNoteBlockText);

  /**
   * Caching the old timestamp value makes ids more consistent; it prevents the
   *  case where we switch timestamps back and forth like:
   */
  if (textTimestamp) textTimestampCache[id] = textTimestamp;
  const cachedTextTimestamp = get(textTimestampCache, id) ?? 0;

  return {
    htmlDividerLineWithoutShelfSignifier:
      dividerLine?.htmlWithoutShelfSignifiers || defaultDividerHtmlWithoutShelfSignifiers,
    textTimestamp: cachedTextTimestamp,
  };
};
