import { FC } from 'react';

import RegardLogoSvg from '~/app/images/regard-logo-small.svg';
import { PatientLoadDurations, PatientLoadPhases } from '~/app/@types';
import { getIsNewLoadingScreenEnabled } from '~/app/flags';

import { RegardLoading as OldRegardLoading } from './OldRegardLoading';
import { useIncrementingNumber } from './useIncrementingNumber';
import { LOADING_DESCRIPTIONS, PHASE_DESCRIPTIONS } from './textContent';
import {
  backgroundStyle,
  getContentStyle,
  textStyle,
  headerTextStyle,
  logoSvgStyle,
} from './styles';
import { ProgressFolder } from './ProgressFolder';

type RegardLoadingProps = {
  isVerticalLayout: boolean;
  phase: PatientLoadPhases;
  estimatedPatientLoadDuration: PatientLoadDurations;
  percentage: number;
};

const RegardLoading: FC<RegardLoadingProps> = (props) => {
  const { isVerticalLayout, percentage, estimatedPatientLoadDuration, phase } = props;
  const estimatedPercentage = useIncrementingNumber(percentage);

  return (
    <div style={backgroundStyle}>
      <RegardLogoSvg style={logoSvgStyle} />
      <div style={getContentStyle(isVerticalLayout)}>
        <h1 data-cy-loading-title-text style={headerTextStyle}>
          {LOADING_DESCRIPTIONS[estimatedPatientLoadDuration].title}
        </h1>
        <div style={textStyle}>
          {LOADING_DESCRIPTIONS[estimatedPatientLoadDuration].description}
        </div>
        <ProgressFolder
          isVerticalLayout={isVerticalLayout}
          text={`${PHASE_DESCRIPTIONS[phase]} ${estimatedPercentage}%`}
        />
      </div>
    </div>
  );
};

const RegardLoadingWrapper: FC<RegardLoadingProps> = (props) => {
  const LoadingComponent = getIsNewLoadingScreenEnabled() ? RegardLoading : OldRegardLoading;
  return <LoadingComponent {...props} />;
};

export { RegardLoadingWrapper as RegardLoading };
