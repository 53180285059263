import { track } from '~/app/analytics';
import {
  htmlConvertLinesToListOnCopy,
  htmlUseBrLineFormatOnCopy,
  htmlWrapWithFontOnCopy,
} from '~/app/flags';
import { copyHtmlToClipboard, copyTextToClipboard, createHtmlNote } from '~/app/utils';

import { getSelectedHtml } from './getSelectedHtml';

/**
 * CKE4 plugin to intercept copy/cut events in order to run the copied content through
 * our copy note logic to cleanup and ready the html for pasting into the site's EHR.
 * This, unfortunately, causes a security alert in IE, but that's prefered to having
 * incorrect formatting on the pasted content.
 *
 * Original Documentation (https://ckeditor.com/docs/ckeditor4/latest/guide/dev_clipboard.html#cut-and-copy):
 *   To avoid the security alerts in Internet Explorer when the user copies or cuts content
 *   using keyboard shortcuts, the editor does not use custom methods and lets the browser
 *   get or extract selected data.
 */
const copySelection = (event: CKEDITOR.eventInfo, removeSelection = false) => {
  if (document.getSelection()?.isCollapsed) return;
  event.cancel();
  event.data.preventDefault();

  const usePlaintext = false;
  const selection = document.getSelection();
  const selectedHtml = getSelectedHtml(selection, removeSelection);

  // Sanitize the html
  const formatOptions = {
    usePlaintext,
    includePhysicalExam: false,
    convertLinesToListOnCopy: htmlConvertLinesToListOnCopy,
    useBrLineFormat: htmlUseBrLineFormatOnCopy,
    wrapWithFontTag: htmlWrapWithFontOnCopy,
  } as const;
  const formattedHtml = createHtmlNote([selectedHtml], formatOptions);

  if (usePlaintext) {
    copyTextToClipboard(formattedHtml);
  } else {
    copyHtmlToClipboard(formattedHtml as HtmlString);
  }

  track.selectedAndCopiedTextInNoteEditor();
};

CKEDITOR.plugins.add('regardCopyFormatter', {
  init(editor) {
    const element = editor.element as CKEDITOR.dom.element;
    element.on('copy', (event: CKEDITOR.eventInfo) => {
      copySelection(event);
    });
    element.on('cut', (event: CKEDITOR.eventInfo) => {
      copySelection(event, true);
    });
  },
});
