import { SummaryUl, SummaryOl, SummarySpanB } from './common';

export const pulmonaryHypertensionSummary = (
  <SummaryOl>
    <li>
      Pulmonary hypertension is diagnosed by checking patient notes and history for evidence of
      pulmonary hypertension (pHTN) including the phrases “acute” or “acute on chronic” during the
      current encounter.
    </li>
    <li>
      Check echocardiogram (TTE or TEE) reports
      <SummaryUl innerList>
        <li>
          pulmonary artery systolic pressure or right ventricular systolic pressure &gt; 50 or peak
          tricuspid regurgitation velocity &gt;3.4 m/s: diagnose{' '}
          <SummarySpanB>“probable pulmonary hypertension”</SummarySpanB>
        </li>
        <li>
          {' '}
          If 2.9 m/s ≤ peak tricuspid regurgitation velocity &lt; 3.4 m/s: diagnose{' '}
          <SummarySpanB>“possible pulmonary hypertension”</SummarySpanB>
        </li>
      </SummaryUl>
    </li>
    <li>Display related evidence included right heart catheterization reports.</li>
  </SummaryOl>
);
