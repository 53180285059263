import classNames from 'classnames';
import { format } from 'date-fns';
import _ from 'lodash';
import { FC, createRef, useState } from 'react';

import { track } from '../../analytics';
import '../../styles/ComplaintTimeline.scss';
import { APITableDataSeries } from '../../@types/state';

/* Modified binary search function to determine which dot is the closest
 * to the mouse's position in the timeline. */
export const findClosestLeftOffset = (posArray: number[], xPos: number): number => {
  if (posArray.length === 2) {
    if (xPos - posArray[0] > posArray[1] - xPos) return posArray[1];
    return posArray[0];
  }
  const mid = Math.floor(posArray.length / 2);
  if (posArray[mid] <= xPos) {
    return findClosestLeftOffset(posArray.splice(mid, posArray.length), xPos);
  }
  return findClosestLeftOffset(posArray.splice(0, mid + 1), xPos);
};

export const ComplaintTimeline: FC<{
  dataMap: Record<string, APITableDataSeries>;
  greyMarkerLocation: Date;
  highlightLabel: string | undefined;
  scrollToDate(date: Date): void;
  setHighlightRow(label: string | null): void;
  sortedDatetimes: Date[];
  sortedLabels: string[];
}> = ({
  dataMap,
  greyMarkerLocation,
  highlightLabel,
  sortedDatetimes,
  sortedLabels,
  setHighlightRow,
  scrollToDate,
}) => {
  const [blueLineLeftOffset, setBlueLineLeftOffset] = useState<number | null>(null);

  // 0 should always map to the "most recent date" which is always
  // the first item in the sortedDatetime array
  const leftToDateMap: Record<number, Date> = { 0: sortedDatetimes[0] };
  let totalTimeDiff = 0;
  if (sortedDatetimes.length > 1) {
    const oldestDate = sortedDatetimes[sortedDatetimes.length - 1];
    totalTimeDiff = leftToDateMap[0].getTime() - oldestDate.getTime();
  }

  // Helper function to determine the css ``left`` value for each
  // blue dot on the timeline, based on time between most recent
  // and oldest dates in sortedDatetimes array
  const timeDiffPerecentage = (date: Date) => {
    let leftOffset = 0;
    if (totalTimeDiff > 0) {
      const timeDiff = leftToDateMap[0].getTime() - date.getTime();
      leftOffset = (timeDiff / totalTimeDiff) * 99.1;
    }
    leftToDateMap[leftOffset] = date;
    return leftOffset;
  };

  // Helper function for the mouseover functionality to set
  // the position of the blue date bubble and blue line
  const getClosetLeftOffset = (xPos: number) => {
    const sortedLeftOffsets = Object.keys(leftToDateMap)
      .map(parseFloat)
      .sort((a, b) => a - b);
    if (sortedLeftOffsets.length === 1) return sortedLeftOffsets[0];
    return findClosestLeftOffset(sortedLeftOffsets, xPos);
  };

  // Create blue lines and blue dots in each timeline
  const rows = sortedLabels.map((label) => (
    <div
      key={`${label}-line`}
      className={classNames('blueLine', {
        highlight: highlightLabel === label,
      })}
    >
      {dataMap[label].data.map((dataPoint, i) => {
        const style = { left: `${timeDiffPerecentage(new Date(dataPoint[0]))}%` };
        return <span key={`${label}-${i}`} style={style} />;
      })}
    </div>
  ));

  const timelineRef = createRef<HTMLDivElement>();
  return (
    <div className="ComplaintTimeline">
      <div className="labels">
        {sortedLabels.map((label) => (
          <div
            key={label}
            onMouseEnter={() => setHighlightRow(label)}
            onMouseLeave={() => setHighlightRow(null)}
          >
            {dataMap[label].abbreviation || dataMap[label].label}
          </div>
        ))}
      </div>
      <div
        ref={timelineRef}
        className="lines"
        onClick={() => {
          if (_.isNumber(blueLineLeftOffset)) {
            track.clickedTimeline({ date: leftToDateMap[blueLineLeftOffset] });
            scrollToDate(leftToDateMap[blueLineLeftOffset]);
          }
        }}
        onMouseLeave={() => setBlueLineLeftOffset(null)}
        onMouseMove={({ pageX }) => {
          const rect = timelineRef.current?.getBoundingClientRect() ?? { left: 0, width: 0 };
          const newOffset = getClosetLeftOffset(((pageX - rect.left) / rect.width) * 100);
          if (newOffset !== blueLineLeftOffset) setBlueLineLeftOffset(newOffset);
        }}
      >
        {blueLineLeftOffset !== null && (
          <span className="blue marker" style={{ left: `${blueLineLeftOffset}%` }}>
            <span>{format(leftToDateMap[blueLineLeftOffset], 'MM/DD/YYYY')}</span>
          </span>
        )}
        <span
          className="grey marker"
          style={{ left: `${timeDiffPerecentage(greyMarkerLocation)}%` }}
        >
          <span>{format(greyMarkerLocation, 'MM/DD/YYYY')}</span>
        </span>
        <span className="end marker">
          <span>{format(sortedDatetimes[sortedDatetimes.length - 1], 'MM/DD/YYYY')}</span>
        </span>
        {rows}
      </div>
      <div className="stopper" />
    </div>
  );
};
