import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { LoginState } from '~/app/@types/state';
import { AppThunkAction } from '~/app/store';
import { RECEIVE_REGARD_NOTE_FAILURE } from '~/app/actions/regardNote';

// Action Names //////////
export const LOGOUT_COMPLETE = 'logout complete';
export const REQUEST_LOGOUT = 'sending logout request';

// Action Types //////////
export type LoginAction = { type: typeof LOGOUT_COMPLETE } | { type: typeof REQUEST_LOGOUT };

// Action Creator Helpers //////////
export function handleErrorThunk(
  type: typeof RECEIVE_REGARD_NOTE_FAILURE,
  error: Response
): AppThunkAction {
  // If this isn't an HTTP error, throw it
  if (!error.headers && !error.status) {
    throw error;
  }

  return async (dispatch): Promise<void> => {
    const message = error.headers.get('Content-Type')?.includes('text/plain')
      ? await error.text()
      : error.statusText || 'unknown';
    const redirect = error.headers ? error.headers.get('X-HealthTensor-Auth-Redirect') : null;
    dispatch({
      type,
      error: {
        message,
        redirect,
        status: error.status,
      },
    });
  };
}

export function doLogout(): ThunkAction<Promise<void>, LoginState, undefined, Action<string>> {
  return (dispatch): Promise<void> => {
    dispatch({ type: REQUEST_LOGOUT });
    const logoutUrl = '/api/logout';
    return fetch(logoutUrl, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          dispatch({ type: LOGOUT_COMPLETE });
          return;
        }
        throw response;
      })
      .catch(() => {
        dispatch({ type: LOGOUT_COMPLETE });
      });
  };
}
