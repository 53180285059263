import {
  BulletNoteStatusRecord,
  IndexingData,
  PostModuleNoteBlock,
  PreModuleNoteBlock,
} from '~/app/@types/state';
import { addBulletsByTrimmedTextKey } from '../addBulletsByTrimmedTextKey';
import { addModulesToNoteBlocks } from '../addModulesToNoteBlocks';

// Adds:
//  - modules
//  - keywords
//  - bulletsByTrimmedTextKey
//  - isNew
export const preToPostModuleNoteBlocks = ({
  preModuleNoteBlocks,
  staleBulletNoteStatus,
  staleIndexingData,
}: {
  preModuleNoteBlocks: PreModuleNoteBlock[];
  staleBulletNoteStatus: BulletNoteStatusRecord;
  staleIndexingData: Pick<
    IndexingData,
    | 'allModuleMatchesByTitle'
    | 'conditionsByModule'
    | 'conditionQualifiers'
    | 'conditionKeywords'
    | 'exactMatchRegardTitlesInNoteByModule'
  >;
}): {
  allModuleMatchesByTitleUpdates: IndexingData['allModuleMatchesByTitle'];
  bulletNoteStatusUpdatesForModules: BulletNoteStatusRecord;
  postModuleNoteBlocks: PostModuleNoteBlock[];
} => {
  // 1. Add modules and keywords to condition areas
  const {
    allModuleMatchesByTitleUpdates,
    noteBlocksWithModulesAndKeywords,
    bulletNoteStatusUpdatesForModules,
  } = addModulesToNoteBlocks({
    chunks: preModuleNoteBlocks,
    staleBulletNoteStatus,
    staleIndexingData,
  });

  // 2. Add bulletsByTrimmedTextKey and isNew to condition areas
  const postModuleNoteBlocks = noteBlocksWithModulesAndKeywords.map((protoNoteBlock) =>
    addBulletsByTrimmedTextKey({
      protoNoteBlock,
      conditionsByModule: staleIndexingData.conditionsByModule,
    })
  );

  return {
    allModuleMatchesByTitleUpdates,
    bulletNoteStatusUpdatesForModules,
    postModuleNoteBlocks,
  };
};
