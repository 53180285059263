import { FC } from 'react';

import { IconButton, StaticTooltip } from '~/app/reuse';
import { buttonTooltipStyle, iconStyle } from '../css';

export const BoldButton: FC<{
  active: boolean;
  disabled: boolean;
  onBoldClick(): void;
}> = ({ active, disabled, onBoldClick }) => (
  <StaticTooltip disabled={disabled} placement="bottom" style={buttonTooltipStyle} tooltip="Bold">
    <IconButton
      active={active}
      color="tertiary-grey"
      data-cy-bold-button
      disabled={disabled}
      iconName="bold"
      onClick={onBoldClick}
      size="small"
      style={iconStyle}
    />
  </StaticTooltip>
);
