import {
  BulletNoteStatusRecord,
  IndexedConditionNoteBlock,
  IndexedNoteBlock,
  NoteBlock,
} from '../@types/state';
import { persistBulletNoteStatusDiffs } from '../controllers/bulletNoteStatus';
import { addToUndosLater, addToUndosNow, resetUndoRedo } from '../controllers/undoManager';
import { regardNoteInitialState } from '../reducers/regardNote';
import { AppState, store } from '../store';

// bulletNoteStatus
const bulletNoteStatusSelector = (state: AppState | undefined): BulletNoteStatusRecord =>
  state?.regardNote.bulletNoteStatusByBulletId || regardNoteInitialState.bulletNoteStatusByBulletId;
const isFetchingSelector = (state: AppState | undefined): boolean =>
  state?.regardNote.isFetching || regardNoteInitialState.isFetching;
const timestampSelector = (state: AppState | undefined): ISODateString | null =>
  state?.regardNote.timestamp || regardNoteInitialState.timestamp;

// undo / redo
const noteBlocksSelector = (state: AppState | undefined): NoteBlock[] =>
  state?.regardNote.masterNoteBlocks || regardNoteInitialState.masterNoteBlocks;
const conditionsByIdSelector = (
  state: AppState | undefined
): Record<string, IndexedConditionNoteBlock> =>
  state?.regardNote.conditionsById || regardNoteInitialState.conditionsById;
const noteBlocksByIdSelector = (state: AppState | undefined): Record<string, IndexedNoteBlock> =>
  state?.regardNote.noteBlocksById || regardNoteInitialState.noteBlocksById;

const regardNoteSelector = (state: AppState | undefined) =>
  state?.regardNote || regardNoteInitialState;

let nextState: AppState | undefined;

const onChange = (): void => {
  const prevState = nextState;
  nextState = store.getState();

  // bulletNoteStatus
  const prevBulletNoteStatus = bulletNoteStatusSelector(prevState);
  const nextBulletNoteStatus = bulletNoteStatusSelector(nextState);

  // After 'successfully received /doc response for patient' action is fired,
  // prevState.regardNote.isFetching is true and nextState.regardNote.isFetching is false.
  const patientLoadedIntoState = isFetchingSelector(prevState) && !isFetchingSelector(nextState);

  // If a new patient is loaded, but the store already had a timestamp we know the user
  // must have changed the baseNote and re-meshed the page. when this happens
  // we need to reset the undo/redo stack so the user cannot go to changes made
  // with the previous basenote
  const changedBaseNote = patientLoadedIntoState && timestampSelector(prevState);
  if (changedBaseNote) resetUndoRedo();

  // `updateBulletNoteStatusState` is noteMeshing.ts only returns a new object if
  // bulletNoteStatus differences are detected and should be persisted.
  const bulletNoteStatusChanged = prevBulletNoteStatus !== nextBulletNoteStatus;

  if (!patientLoadedIntoState && bulletNoteStatusChanged) {
    // We do not want to persist state changes in this function on the initial patient load
    // because it is done manually in the `action/regardNote.fetchNote` call. Otherwise, if
    // bulletNoteStatus have changed, persist differences to db.
    persistBulletNoteStatusDiffs(
      nextBulletNoteStatus,
      prevBulletNoteStatus,
      nextState.regardNote.encounterId,
      nextState.regardNote.indexingData.negativeDiagnosisSet
    );
  }

  // undo / redo
  const { reviewMode } = regardNoteSelector(nextState);
  const prevNoteBlocks = noteBlocksSelector(prevState);
  const nextNoteBlocks = noteBlocksSelector(nextState);
  const nextConditionsById = conditionsByIdSelector(nextState);
  const nextNoteBlocksById = noteBlocksByIdSelector(nextState);
  const noteBlockAddedOrRemoved = prevNoteBlocks.length !== nextNoteBlocks.length;
  const noteBlocksChanged = prevNoteBlocks !== nextNoteBlocks;

  // If `patientLoadedIntoState` is true, this indicates that we're loading a patient from the API.
  // In this case, we don't want to contribute to the undo stack or (as a side-effect) store the
  // changes in localStorage/on the server
  const shouldPersistNote = !reviewMode && !patientLoadedIntoState;

  if (bulletNoteStatusChanged || noteBlockAddedOrRemoved) {
    addToUndosNow({
      encounterId: nextState.regardNote.encounterId,
      basenoteId: nextState.regardNote.baseNoteData.resourceId,
      basenoteHash: nextState.regardNote.baseNoteData.contentHash,
      nextBulletNoteStatus,
      nextConditionsById,
      nextNoteBlocks,
      nextNoteBlocksById,
      shouldPersistNote,
    });
  } else if (noteBlocksChanged) {
    addToUndosLater({
      encounterId: nextState.regardNote.encounterId,
      basenoteId: nextState.regardNote.baseNoteData.resourceId,
      basenoteHash: nextState.regardNote.baseNoteData.contentHash,
      nextBulletNoteStatus,
      nextConditionsById,
      nextNoteBlocks,
      nextNoteBlocksById,
      shouldPersistNote,
    });
  }
};

store.subscribe(onChange);
