import { CSSProperties, FC, useMemo } from 'react';

import { curtainClosedStyle, curtainOpenStyle, dialogClosedStyle, dialogOpenStyle } from './css';

export type DialogBaseProps = {
  close(): void;
  isOpen: boolean;
  openByDefault?: boolean;
  style?: CSSProperties;
};

const dialogCss = {
  boxSizing: 'border-box',
  '& > *': {
    boxSizing: 'border-box',
  },
} as const;

export const DialogBase: FC<DialogBaseProps> = ({ children, close, isOpen, style, ...rest }) => {
  const finalDialogOpenStyle = useMemo(
    () => ({
      ...dialogOpenStyle,
      ...style,
    }),
    [style]
  );
  const finalDialogClosedStyle = useMemo(
    () => ({
      ...dialogClosedStyle,
      ...style,
    }),
    [style]
  );

  return (
    <>
      <div
        aria-label="close"
        onClick={close}
        role="button"
        style={isOpen ? curtainOpenStyle : curtainClosedStyle}
        tabIndex={0}
      />
      <div css={dialogCss} style={isOpen ? finalDialogOpenStyle : finalDialogClosedStyle} {...rest}>
        {children}
      </div>
    </>
  );
};
DialogBase.displayName = 'DialogBase';
