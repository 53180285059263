/**
 *  `process.env.NODE_ENV` is set by webpack and is determined at compile time.
 *  `window.ENV` is set by the frontend config.js file and is determined at run time.
 *  These two values may not be in sync and should be merged in a future cleanup as
 *  they handle similar concerns.
 */
const getProcessEnv = () => process.env.NODE_ENV as 'development' | 'production';
const getWindowEnv = () => window.ENV as 'prod' | 'Prod' | 'default-prod' | 'test' | 'dev';

export const isDev = getProcessEnv() !== 'production';
export const getEnv = () => getWindowEnv() || 'dev';
