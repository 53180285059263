import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '../../store';
import { track } from '../../analytics';
import { changeTab, openFeedback, openResources } from '../../actions/ui';
import '../../styles/TabPanel.scss';
import { theme, Button } from '../../reuse';
import { DxDetails } from './dxDetails';
import { useMeshedContext } from './MeshedProvider';
import { TabPanelButtons } from './TabPanelButtons';
import { MaxChat } from './maxChat';
import { MaxChatDislikeDialog } from './maxChat/maxChatDislikeDialog';

export const tabPanelStyle = {
  backgroundColor: `${theme.colors.grey5}`,
  borderLeft: `1px solid ${theme.colors.grey5}`,
  height: '100%',
  position: 'relative',
} as const;

const hideButtonStyle = {
  position: 'absolute',
  right: '20px',
  top: '10px',
  zIndex: '1',
} as const;

const UnconnectedTabPanel: FC<
  PropsFromRedux & {
    close(): void;
    detailsCollapsed: boolean;
    disableFeedback?: boolean;
    open(): void;
  }
> = ({
  changeTab,
  close,
  detailsCollapsed,
  disableFeedback,
  open,
  openFeedback,
  openResources,
  tabIndex,
  tabs,
}) => {
  const { isVerticalLayout, selectedModule } = useMeshedContext(
    ({ isVerticalLayout, selectedModule }) => ({
      isVerticalLayout,
      selectedModule,
    })
  );
  const HideButton = (
    <Button
      color="tertiary"
      data-cy-hide-dx-details-button
      onClick={close}
      size="small"
      style={hideButtonStyle}
    >
      Hide
    </Button>
  );

  return (
    <>
      {
        // In vertical view, the dialog should cover the note area
        isVerticalLayout && <MaxChatDislikeDialog />
      }
      <div className="tab-panel-container" data-cy-right-side-panel>
        {isVerticalLayout && !detailsCollapsed && HideButton}
        <div className="TabPanel" style={tabPanelStyle}>
          <div className="tabs">
            {tabs.map((name, i) => (
              <div
                key={name}
                className={`tab${i === tabIndex ? ' open' : ''}`}
                onClick={() => {
                  if (i !== tabIndex) changeTab(i);
                  track.clickedTabPanelTab({ module: selectedModule, name });
                  open();
                }}
              >
                {name}
              </div>
            ))}
          </div>
          <div className="panel-content">
            {(() => {
              const tab = tabs[tabIndex];
              switch (tab) {
                case 'Max':
                  return <MaxChat />;
                case 'Dx Details':
                default:
                  return <DxDetails />;
              }
            })()}
          </div>
          <TabPanelButtons
            disableFeedback={!!disableFeedback}
            openFeedback={openFeedback}
            openResources={openResources}
          />
        </div>
      </div>
    </>
  );
};

const connector = connect(
  ({ ui: { tabs, tabIndex } }: AppState) => ({
    tabs,
    tabIndex,
  }),
  {
    changeTab,
    openFeedback,
    openResources,
  }
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const TabPanel = connector(UnconnectedTabPanel);
