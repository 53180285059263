import { ConditionLineStatus } from '~/app/@types';
import { DiffLabel } from '~/app/@types/state';
import { exhaust } from '~/app/utils';

export const diffToStatus = (diff: DiffLabel): ConditionLineStatus => {
  switch (diff) {
    case 'NEW':
      return 'new';
    case 'UPDATED':
      return 'updated';
    case 'EXISTING':
      return 'existing';
    case 'OUTDATED':
    case null:
      return 'none';
    default:
      return exhaust(diff);
  }
};
