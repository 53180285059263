import { NoteBlock } from '~/app/@types/state';
import { assertUnreachable, htmlStringToPlainText } from '~/app/utils';

export const getConditionHtmlStringForLlm = (noteBlock: NoteBlock): HtmlString => {
  switch (noteBlock.type) {
    case 'condition':
    case 'shelvedCondition': {
      const { lines, suggestions } = noteBlock;

      const linesString = lines
        .map(({ html, type }) => {
          const text = htmlStringToPlainText(html);

          return type === 'title'
            ? `<h6 aria-description="condition title">${text}</h6>`
            : `<p>${text}</p>`;
        })
        .join('');

      const suggestedBulletsString = suggestions.length
        ? `<ul aria-description="bullets suggested by Regard">${`${suggestions
            .map(({ text }) => `<li>${text}</li>`)
            .join('')}</ul>`}`
        : '';

      return `<div aria-description="condition">${linesString}${suggestedBulletsString}</div>` as HtmlString;
    }
    case 'shelfDivider': {
      const { lines } = noteBlock;
      const linesString = lines
        .map(({ html }) => {
          const text = htmlStringToPlainText(html);

          return `<p>${text}</p>`;
        })
        .join('');

      return `<div aria-description="divider">${linesString}</div>` as HtmlString;
    }
    default:
      assertUnreachable(noteBlock);
      return '' as HtmlString;
  }
};
