import { KeywordMatches } from '../../../@types/state';

// When condition keywords are found AFTER one of the following phrases we consider that
// keyword to be a "secondary" match. This affects our title-to-regard-module matching logic
const NEGATORS_REGEX =
  /(2 ?\/ ?2)|((2nd|due|secondary) to)|((caused|exacerbated|complicated) by)|in setting of|(c ?\/ ?b)/;

// Splits a caption by lines (Stacked DX titles will cross multiple lines)
// then for each line we truncate the string up to any negator we find
export const truncateCaption = (caption: string) => {
  const captionLine = caption.replace('\n', ' ');
  const negatorMatched = captionLine.match(NEGATORS_REGEX);
  return negatorMatched
    ? [captionLine.slice(0, negatorMatched.index), captionLine.slice(negatorMatched.index)]
    : [captionLine, ''];
};

// Sometimes keywords can overlap such as "Pulmonary Hypertension" where both words are a match
// for pulmonary_hypertension, but "Hypertension" is also a keyword for hypertension. This function
// compares all the keywords found in the title and returns the name of the existing condition when overlapping occurs
const checkIfMatchIsOverlapping = (
  matches: KeywordMatches,
  keyword: string,
  captionIndex: number
) => {
  const matchesArray = Object.entries(matches).map(
    ([conditionName, { keyword, captionIndex }]) => ({
      conditionName,
      start: captionIndex,
      end: captionIndex + keyword.length,
    })
  );

  for (let i = 0; i < matchesArray.length; i++) {
    if (
      // Existing match fully contains the new match
      (captionIndex >= matchesArray[i].start &&
        captionIndex + keyword.length <= matchesArray[i].end) ||
      // Existing match is fully contained by the new match
      (captionIndex <= matchesArray[i].start &&
        captionIndex + keyword.length >= matchesArray[i].end)
    ) {
      return matchesArray[i].conditionName;
    }
  }

  return null;
};

// Use the condition keyword regexes to search the title caption for keywords.
// primaryMatches are keywords found before "caused by" or "2/2" clauses
// secondaryMatches are keywords found after such clauses
export const findKeywordMatchesInTitleText = (
  caption: string,
  conditionNameToKeywordRegex: Record<string, RegExp>
) => {
  const [primaryText, secondaryText] = truncateCaption(caption);
  const primaryMatches: KeywordMatches = {};
  const secondaryMatches: KeywordMatches = {};

  const moduleFinders = Object.entries(conditionNameToKeywordRegex).map(
    ([module, moduleMatcher]) => ({
      module,
      moduleMatcher,
      primarySearchText: primaryText,
      secondarySearchText: secondaryText,
      primaryTextOffset: 0,
      secondaryTextOffset: primaryText.length,
    })
  );

  // manually loop here so we can add-on modules that need to be re-mapped after an overlapping
  // match is found
  for (let i = 0; i < moduleFinders.length; i++) {
    const {
      module,
      moduleMatcher,
      primarySearchText,
      secondarySearchText,
      primaryTextOffset,
      secondaryTextOffset,
    } = moduleFinders[i];

    const primaryMatch = moduleMatcher.exec(primarySearchText);
    if (primaryMatch) {
      const keyword = primaryMatch[0];
      const captionIndex = primaryMatch.index ?? 0;
      const overlappingModule = checkIfMatchIsOverlapping(
        primaryMatches,
        keyword,
        captionIndex + primaryTextOffset
      );

      if (!overlappingModule) {
        primaryMatches[module] = { keyword, captionIndex: captionIndex + primaryTextOffset };
      } else if (keyword.length > primaryMatches[overlappingModule].keyword.length) {
        // we found an overlapping match, so lets take the new & longer match, remove the old
        delete primaryMatches[overlappingModule];
        primaryMatches[module] = { keyword, captionIndex: captionIndex + primaryTextOffset };
        // attempt to re-find the module we just unmatched
        moduleFinders.push({
          module: overlappingModule,
          moduleMatcher: conditionNameToKeywordRegex[overlappingModule],
          primarySearchText: primarySearchText.slice(captionIndex + keyword.length),
          secondarySearchText,
          primaryTextOffset: primaryTextOffset + captionIndex + keyword.length,
          secondaryTextOffset,
        });
      } else {
        // attempt to re-find the module we are looking for
        moduleFinders.push({
          module,
          moduleMatcher: conditionNameToKeywordRegex[module],
          primarySearchText: primarySearchText.slice(
            primaryMatches[overlappingModule].captionIndex +
              primaryMatches[overlappingModule].keyword.length
          ),
          secondarySearchText,
          primaryTextOffset:
            primaryTextOffset +
            primaryMatches[overlappingModule].captionIndex +
            primaryMatches[overlappingModule].keyword.length,
          secondaryTextOffset,
        });
      }
    } else if (secondarySearchText) {
      const secondaryMatch = moduleMatcher.exec(secondarySearchText);
      if (secondaryMatch) {
        const keyword = secondaryMatch[0];
        const captionIndex = secondaryMatch.index ?? 0;
        const overlappingModule = checkIfMatchIsOverlapping(
          secondaryMatches,
          keyword,
          captionIndex + secondaryTextOffset
        );

        if (!overlappingModule) {
          secondaryMatches[module] = {
            keyword,
            captionIndex: captionIndex + secondaryTextOffset,
          };
        } else if (keyword.length > secondaryMatches[overlappingModule].keyword.length) {
          // we found an overlapping match, so lets take the new & longer match, remove the old
          delete secondaryMatches[overlappingModule];
          secondaryMatches[module] = {
            keyword,
            captionIndex: captionIndex + secondaryTextOffset,
          };
          // attempt to re-find the module we just unmatched
          // in ONLY remaining secondaryText
          moduleFinders.push({
            module: overlappingModule,
            moduleMatcher: conditionNameToKeywordRegex[overlappingModule],
            secondarySearchText: secondarySearchText.slice(captionIndex + keyword.length),
            primarySearchText: '',
            primaryTextOffset,
            secondaryTextOffset: secondaryTextOffset + captionIndex + keyword.length,
          });
        } else {
          // attempt to re-find the module we are looking for
          // in ONLY remaining secondaryText
          moduleFinders.push({
            module,
            moduleMatcher: conditionNameToKeywordRegex[module],
            secondarySearchText: secondarySearchText.slice(
              secondaryMatches[overlappingModule].captionIndex +
                secondaryMatches[overlappingModule].keyword.length
            ),
            primarySearchText: '',
            primaryTextOffset,
            secondaryTextOffset:
              secondaryTextOffset +
              secondaryMatches[overlappingModule].captionIndex +
              secondaryMatches[overlappingModule].keyword.length,
          });
        }
      }
    }
  }

  return { primaryMatches, secondaryMatches };
};
