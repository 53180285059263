import { BulletNoteStatus } from '~/app/@types';
import {
  PreModuleNoteBlock,
  BulletNoteStatusRecord,
  IndexingData,
  ModuleMatches,
  PostModuleNoteBlock,
} from '~/app/@types/state';

import { matchChunkTitlesToModulesAndDeleteDuplicates } from '../matchChunkTitlesToModulesAndDeleteDuplicates';

export const addModulesToNoteBlocks = <T extends PreModuleNoteBlock>({
  chunks,
  staleBulletNoteStatus,
  staleIndexingData,
}: {
  chunks: T[];
  staleBulletNoteStatus: BulletNoteStatusRecord;
  staleIndexingData: Pick<
    IndexingData,
    | 'allModuleMatchesByTitle'
    | 'conditionsByModule'
    | 'conditionQualifiers'
    | 'conditionKeywords'
    | 'exactMatchRegardTitlesInNoteByModule'
  >;
}): {
  allModuleMatchesByTitleUpdates: Record<string, ModuleMatches>;
  noteBlocksWithModulesAndKeywords: (T & Pick<PostModuleNoteBlock, 'modules' | 'keywords'>)[];
  bulletNoteStatusUpdatesForModules: BulletNoteStatusRecord;
} => {
  // 1. Add modules and keywords to condition areas
  const { chunksWithModulesAndKeywords, allModuleMatchesByTitleUpdates } =
    matchChunkTitlesToModulesAndDeleteDuplicates({
      ...staleIndexingData,
      chunks,
    });

  // 2. Take a look at what modules are in the note
  const moduleStatusRecord: Record<string, BulletNoteStatus> = {};

  chunksWithModulesAndKeywords.forEach(({ modules, type }) => {
    if (modules.length) {
      const status: BulletNoteStatus =
        type === 'shelvedCondition' ? BulletNoteStatus.Shelved : BulletNoteStatus.Noted;

      modules.forEach((module) => {
        moduleStatusRecord[module] = status;
      });
    }
  });

  // 3. Compare that with what modules were in the note before this edit
  const bulletNoteStatusUpdatesForModules: BulletNoteStatusRecord = {};
  Object.keys(staleIndexingData.conditionsByModule).forEach((module) => {
    const prevStatus = staleBulletNoteStatus[module];
    const newStatus = moduleStatusRecord[module];

    if (!newStatus && prevStatus !== BulletNoteStatus.Hidden) {
      bulletNoteStatusUpdatesForModules[module] = BulletNoteStatus.Hidden;
    } else if (newStatus && prevStatus !== newStatus) {
      bulletNoteStatusUpdatesForModules[module] = newStatus;
    } // else it's the same
  });

  return {
    allModuleMatchesByTitleUpdates,
    noteBlocksWithModulesAndKeywords: chunksWithModulesAndKeywords,
    bulletNoteStatusUpdatesForModules,
  };
};
