import { useLocation } from 'react-router-dom';

import { PATIENT_PARAM, TIMESTAMP_PARAM } from '~/app/constants';
import { Ehr } from './const';
import { useEhrParam } from './useEhrParam';
import { paramToISODateString } from '../../utils';

const DEFAULT_PT =
  'gAAAAABeO0bTNWAEib0Y8Spnr6qlbeSEyO0G7Mo-2nCFtuhn-Lxrc-jvt-tdZYfMMeEqKqxY87p8RzKuwwdBQqeNyU4tpzB-mg==';
const DEFAULT_TIMESTAMP = new Date('2018-12-31T00:01:30.000-08:00').toISOString();

export const useDemoParams = (): {
  ehr: Ehr;
  pt: string;
  timestamp: ISODateString;
  toggleEhr(): void;
} => {
  // ehr
  const [ehr, toggleEhr] = useEhrParam();

  // pt & timestamp
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const pt = params.get(PATIENT_PARAM) ?? DEFAULT_PT;
  const timestamp = paramToISODateString(params.get(TIMESTAMP_PARAM)) ?? DEFAULT_TIMESTAMP;

  return {
    ehr,
    pt,
    timestamp,
    toggleEhr,
  };
};
