import _ from 'lodash';
import { FC, memo } from 'react';

import { typingThrottleTimeout } from '~/app/flags';
import { NoteProvider } from '../noteProvider';
import { NoteBlocks } from '../noteBlocks';
import { hideSpecCheckerTooltips, showSpecCheckerTooltips } from './showAndHideSpecCheckerTooltips';

const throttle = <T extends unknown[]>(fn: (...args: T) => void) =>
  _.throttle(fn, typingThrottleTimeout);
const setTimeout = (fn: () => void) => {
  window.setTimeout(fn, 0);
};

export const Note: FC = memo(() => (
  <NoteProvider setTimeoutDelay={setTimeout} throttleDelay={throttle}>
    <div data-the-note onMouseLeave={hideSpecCheckerTooltips} onMouseMove={showSpecCheckerTooltips}>
      <NoteBlocks />
    </div>
  </NoteProvider>
));
Note.displayName = 'Note';
