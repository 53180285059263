import { FC } from 'react';
import _ from 'lodash';

import { EpicHeader } from './EpicHeader';
import { useDemoImageRows } from '../demoData';
import { formatDatetime } from '../../../utils';
import { EpicCompactTableWithDescription } from './EpicCompactTableWithDescription';

export const EpicTabImageContent: FC = () => {
  const { rows, selectedItem } = useDemoImageRows();

  const descriptionBody = (
    <>
      <div>
        <b>Description: </b>
        {_.startCase(selectedItem.description)}
      </div>
      <div>
        <b>Order Date: </b>
        {formatDatetime(selectedItem.orderDate.toISOString())}
      </div>
      <div>
        <b>Narrative: </b>
        {selectedItem.noteText}
      </div>
    </>
  );

  return (
    <>
      <EpicHeader>Images</EpicHeader>
      <div
        style={{
          alignItems: 'start',
          display: 'flex',
          minHeight: 0,
        }}
      >
        <EpicCompactTableWithDescription descriptionBody={descriptionBody} rows={rows} />
      </div>
    </>
  );
};
