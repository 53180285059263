import { ChunkOfLines } from '../@types/state';
import { FOOTER_MARKER } from '../controllers/regex';
import { htmlStringToPlainText } from './htmlStringToPlainText';

export const isFooterChunk = (chunk: Pick<ChunkOfLines, 'lines'>): boolean => {
  for (let i = 0; i < chunk.lines.length; i += 1) {
    const line = chunk.lines[i];

    // 1. If we find a footer marker before a title, we're a footer
    if (
      (line.type === 'title' && line.plainTextWithoutTitleSignifier.match(FOOTER_MARKER)) ||
      (line.type === 'freetext' && htmlStringToPlainText(line.html).match(FOOTER_MARKER))
    ) {
      return true;
    }

    // 2. If we find a non-footer title before a footer marker, we're NOT a footer
    if (line.type === 'title') {
      return false;
    }
  }

  // 3. If we don't have any titles or footer markers, we're NOT a footer!
  return false;
};
