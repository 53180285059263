import { replaceTextInHtmlString } from '../../../utils';
import { MeshedNoteHtmlLine, MeshedNoteTitleHtmlLine, TitleSignifier } from '../../../@types/state';

export const renumberHtmlTitleLine = <T extends MeshedNoteTitleHtmlLine>({
  index,
  line,
  noteTitleSignifier,
}: {
  index: number;
  line: T;
  noteTitleSignifier: TitleSignifier;
}): { renumberedLine: T; lineWasModified: boolean } => {
  // 1. Make sure the number in the new title signifier matches the index
  const newTitleSignifier = noteTitleSignifier.replace('1', `${index + 1}`);
  const titleSignifierIsDifferent = line.titleSignifier !== newTitleSignifier;

  if (titleSignifierIsDifferent) {
    // 2. Rebuild the html string
    const newHtml = replaceTextInHtmlString(line.html, line.titleSignifier, newTitleSignifier);

    return {
      renumberedLine: {
        ...line,
        html: newHtml,
        // 3. Update the title signifier
        titleSignifier: newTitleSignifier,
      },
      lineWasModified: true,
    };
  }

  // 4. If the title signifier isn't different return the line as it was
  return {
    renumberedLine: line,
    lineWasModified: false,
  };
};

export const renumberHtmlTitleLines = <T extends MeshedNoteHtmlLine>({
  currentTitleIndex,
  lines,
  noteTitleSignifier,
}: {
  currentTitleIndex: number;
  lines: T[];
  noteTitleSignifier: TitleSignifier;
}): {
  linesWereModified: boolean;
  linesWithRenumberedTitleLines: T[];
  titleLineCount: number;
} => {
  let titleLineCount = 0;
  let linesWereModified = false;

  const linesWithRenumberedTitleLines = lines.map((line) => {
    // 1. Operate only on title lines
    if (line.type === 'title') {
      // 2. Renumber the line
      const { renumberedLine, lineWasModified } = renumberHtmlTitleLine({
        index: currentTitleIndex + titleLineCount,
        line,
        noteTitleSignifier,
      });

      // 3. Increment title count
      titleLineCount += 1;

      if (lineWasModified) {
        // 4. Note that the line was modified
        linesWereModified = true;
        // 5. And return the renumbered line
        return renumberedLine as T;
      }

      // 6. Otherwise, fall through to the next return
    }

    return line;
  });

  return {
    linesWereModified,
    // 7. Don't return a new object reference unless the lines were modif
    linesWithRenumberedTitleLines: linesWereModified ? linesWithRenumberedTitleLines : lines,
    titleLineCount,
  };
};
