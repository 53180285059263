import { openDxProcess } from '~/app/actions/ui';
import { store } from '~/app/store';
import { track } from '~/app/analytics';
import { TitledSmartAccordion, AccordionTable, theme } from '~/app/reuse';
import { ConditionDevelopmentState } from '~/app/@types';
import { COMPANY_NAME } from '~/app/constants';
import { diagnoses } from '../meshed/dxProcessDrawer';

import regardNoteDxStatesImage from './assets/regardNoteDxStates.png';
import regardNoteTrackingImage from './assets/regardNoteTracking.png';
import dxDetailsBulletsImage from './assets/dxDetailsBullets.png';

import {
  AccordionContent,
  OrderedList,
  ListItem,
  ListItemContent,
  ResourceImage,
  BoldSpan,
  PaddedSpan,
  AccordionTableSpacer,
} from './styledComponents';

const diagnosesSection = [
  {
    header: 'Diagnoses Covered',
    collapsable: false,
    columns: [
      {
        label: 'diagnosis',
        accessor: 'title',
        cellStyle: {
          display: 'inline-block',
          width: '75%',
          padding: '0 4px',
        },
      },
      {
        label: 'type',
        accessor: 'type',
        cellStyle: {
          display: 'inline-block',
          width: '25%',
          padding: '0 4px',
          borderLeft: `1px solid ${theme.colors.grey4}`,
        },
      },
    ],
    showColumnHeaders: false,
    content: diagnoses
      .filter((diagnosis) => diagnosis.developmentState === ConditionDevelopmentState.ProdReady)
      .map((diagnosis) => ({
        type: diagnosis.type,
        title: {
          label: diagnosis.title,
          ...(!!diagnosis.summary && {
            action: () => {
              track.openedDxProcessDrawerViaResourcePanel({ title: diagnosis.title });
              store.dispatch(openDxProcess(diagnosis));
            },
          }),
        },
      })),
  },
];

export const resourcesCopy = (
  <>
    <AccordionContent>
      <p>{COMPANY_NAME} helps you get a head start on your clinical note’s assessment and plan.</p>
      <p>
        The {COMPANY_NAME} algorithm generates diagnoses based on the data available in the EHR and
        merges it with your most recent progress note’s Assessment and Plan section. You can edit
        and add information directly into the note. Once the progress note has been completed you
        can copy and paste it into your regular note editing workflow.
      </p>
    </AccordionContent>

    <TitledSmartAccordion first openByDefault title={`Diagnoses covered by ${COMPANY_NAME}`}>
      <AccordionContent>
        <p>
          {COMPANY_NAME} provides two kinds of coverage for diagnoses: core coverage and
          supplemental coverage.
        </p>
        <p>
          <BoldSpan>Core Dxs:</BoldSpan> {COMPANY_NAME} runs a complete diagnostic calculation based
          on notes, labs, vitals, history, imaging. The diagnosis is not dependent on a previous
          existing diagnosis, it can be completely new
        </p>
        <p>
          <BoldSpan>Supplemental Dxs:</BoldSpan> {COMPANY_NAME} runs a full diagnostic for a
          previously documented diagnosis. If data is found that the problem is still active,{' '}
          {COMPANY_NAME} will suggest it as a diagnosis for your note.
        </p>
        <p>
          <BoldSpan>Live Dxs:</BoldSpan> If this condition is mentioned in the current note,{' '}
          {COMPANY_NAME} will suggest up-to-date information supporting a positive diagnosis.
        </p>
        <p>
          Click on the diagnosis name to see details on how {COMPANY_NAME} processes each diagnosis.
        </p>
        <AccordionTableSpacer>
          <AccordionTable sections={diagnosesSection} />
        </AccordionTableSpacer>
      </AccordionContent>
    </TitledSmartAccordion>

    <TitledSmartAccordion title={`The ${COMPANY_NAME} Note`}>
      <AccordionContent>
        <p>
          The {COMPANY_NAME} algorithm generates diagnoses based on the data available in the EMR
          and merges it with your most recent progress note’s Assessment and Plan section. You can
          edit and add information directly into the note. Once the progress note has been completed
          you can copy and paste it into your regular note editing workflow.
        </p>
        <p>The {COMPANY_NAME} note is composed of:</p>
        <OrderedList>
          <ListItem first>
            <ListItemContent>
              <BoldSpan>Assessment and Plan -</BoldSpan> this section is imported from the most
              recent, signed note of a current encounter.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemContent>
              <BoldSpan> {COMPANY_NAME} generated data -</BoldSpan> this includes diagnoses
              identified by
              {COMPANY_NAME} and supporting data for any diagnosis that {COMPANY_NAME} covers. Lines
              added by {COMPANY_NAME}
              (what we call “bullets”) are initially listed under the dignosis title. You can choose
              to add them to your note, remove them from view, or leave them in their default
              “monitor” state.
              <ResourceImage
                alt={`${COMPANY_NAME} note diagnosis and bullet states`}
                src={regardNoteDxStatesImage}
              />
              <PaddedSpan>
                {COMPANY_NAME} generated bullets are automatically updated and will reflect the most
                recent information available. However, editing the bullet text in any way will
                disable
                {COMPANY_NAME} tracking.
              </PaddedSpan>
              <ResourceImage
                alt={`${COMPANY_NAME} note tracking status`}
                src={regardNoteTrackingImage}
              />
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemContent>
              <BoldSpan>Physical Exam -</BoldSpan> If there is a physical exam from the prior day,
              the option to include it will appear at the top of the note (this is pulled directly
              from the previous signed note and is not updated by {COMPANY_NAME}). Once selected,
              you can make the updates as you normally would. It will be included when the note is
              copied.
            </ListItemContent>
          </ListItem>
        </OrderedList>
      </AccordionContent>
    </TitledSmartAccordion>

    <TitledSmartAccordion title="Dx Details">
      <AccordionContent>
        <p>
          All diagnoses covered by {COMPANY_NAME} will have a Dx Detail panel. This panel includes:
        </p>
        <OrderedList>
          <ListItem first>
            <ListItemContent>
              <BoldSpan>{COMPANY_NAME} Supporting Evidence -</BoldSpan> All bullets originally
              listed in the monitor section. This also includes the bullets that have been removed.
              From here, you can restore bullets that have been removed back to the monitor section,
              add a bullet to the note, see what bullets are currently in the note and being
              tracked.
              <ResourceImage alt="Dx Detail bullets" src={dxDetailsBulletsImage} />
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemContent>
              <BoldSpan>Additional Dx Details -</BoldSpan> Secondary information like history or
              source for the diagnosis.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemContent>
              <BoldSpan>Dx Table -</BoldSpan> All medications, labs, and vitals for the diagnosis
              organized by date. Each section can be expanded and collapsed and can be navigated by
              using the timeline bar at the bottom or the regular scrollbar.
            </ListItemContent>
          </ListItem>
        </OrderedList>
      </AccordionContent>
    </TitledSmartAccordion>

    <TitledSmartAccordion collapsable={false} openByDefault title={`${COMPANY_NAME} Support`}>
      <AccordionContent>
        <p>
          Requests for technical and administrative support can be submitted to
          support@withregard.com.
        </p>
        <p>
          If submitting a support request via email, please include your site location (Hospital &
          department), your name, your user ID, and a description of the problem you are facing.
          Please do NOT share or send Protected Health Information (PHI) via email. A support member
          will get back to you within one business day.
        </p>
        <p>Learn more about {COMPANY_NAME} at withregard.com.</p>
      </AccordionContent>
    </TitledSmartAccordion>
  </>
);
