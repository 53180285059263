import { FC, useState } from 'react';

import { IconButton, theme, zIndices } from '../../../reuse';
import { useLocalStorageState } from '../../../utils';

const boxShadow = '-2px 2px 1px 1px black';

export const DevTools: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cachePatientChecked, setCachePatientChecked] = useLocalStorageState(
    'regard-dev-cache-patient-checked'
  );

  return (
    <div
      css={{
        'input[type="checkbox"]': {
          marginRight: 6,
          position: 'relative',
          top: 2,
        },
      }}
      data-dev-tools
      style={{
        backgroundColor: 'wheat',
        border: '1px solid black',
        borderBottomLeftRadius: theme.other.borderRadius4,
        borderRight: 0,
        borderTopLeftRadius: theme.other.borderRadius4,
        boxShadow,
        padding: 8,
        position: 'fixed',
        right: 0,
        top: '50%',
        transform: isOpen ? 'translateY(-50%)' : 'translateX(100%) translateY(-50%)',
        transition: 'all 200ms ease',
        zIndex: zIndices.devTools,
      }}
    >
      <div
        style={{
          backgroundColor: 'inherit',
          border: '1px solid black',
          borderBottomLeftRadius: theme.other.borderRadius4,
          borderRight: 0,
          borderTopLeftRadius: theme.other.borderRadius4,
          boxShadow,
          paddingRight: 1,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateX(-100%) translateY(-50%)',
        }}
      >
        <IconButton
          color="tertiary-grey"
          iconName={isOpen ? 'x' : 'focus'}
          onClick={() => setIsOpen(!isOpen)}
          size="small"
          style={{
            display: 'block',
          }}
        />
      </div>
      <div>
        <label htmlFor="dev-tools-cache-patient">
          <input
            checked={!!cachePatientChecked}
            id="dev-tools-cache-patient"
            onChange={() => setCachePatientChecked(cachePatientChecked ? '' : 'true')}
            type="checkbox"
          />
          Cache patient
        </label>
      </div>
    </div>
  );
};
