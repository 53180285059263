import { NBSP } from '~/app/constants';
import {
  splitHtmlIntoCharacterItems,
  CharacterItems,
  characterItemsToTree,
  htmlTreeToString,
  CharacterItem,
} from '.';
import { getRawHtml } from './utils';

function replaceInTextCharacterItems(
  chars: CharacterItems,
  oldText: string,
  newText: string,
  index: number
) {
  const oldTextLength = oldText.length;
  // Use tags at the start of the index. This may clear subsequent character tags
  // if oldText is multiple characters that have differing tags.
  const oldTextTags = chars[index].tags;
  const splitChars = [
    ...chars.slice(0, index),
    ...newText.split('').map((newTextChar) => ({
      character: newTextChar,
      tags: oldTextTags,
    })),
    ...chars.slice(index + oldTextLength),
  ];
  return splitChars;
}

const isWhiteSpace = (char: CharacterItem) =>
  char.character === NBSP || char.character.match(/^\s+$/);

const trimCharacterItemsLeadingWhitespace = (chars: CharacterItems) => {
  let firstNonWhiteSpaceIndex = -1;
  for (let index = 0; index < chars.length; index += 1) {
    const char = chars[index];
    if (!isWhiteSpace(char)) break;
    firstNonWhiteSpaceIndex = index + 1;
  }

  if (firstNonWhiteSpaceIndex < 0) return chars;
  return chars.slice(firstNonWhiteSpaceIndex);
};

const trimCharacterItemsTrailingWhitespace = (chars: CharacterItems) => {
  let firstNonWhiteSpaceIndex = chars.length;
  for (let index = chars.length - 1; index >= 0; index -= 1) {
    const char = chars[index];
    if (!isWhiteSpace(char)) break;
    firstNonWhiteSpaceIndex = index - 1;
  }

  if (firstNonWhiteSpaceIndex >= chars.length) return chars;
  return chars.slice(0, firstNonWhiteSpaceIndex + 1);
};

interface ReplaceTextInHtmlStringOptions {
  trimLeadingWhitespace?: boolean;
  trimTrailingWhitespace?: boolean;
}

/**
 * Replaces plain text in an html string that can span different tags
 */
export function replaceTextInHtmlString(
  html: HtmlString,
  oldText: string,
  newText: string,
  options: ReplaceTextInHtmlStringOptions = {}
) {
  if (!html) return html;

  // 1. Split html string to characters
  const characters = splitHtmlIntoCharacterItems(html);

  // 2. Find replacement location in plain text
  const rawText = getRawHtml(characters);
  const index = rawText.indexOf(oldText);

  // 2b. No need to replace text if the old text isn't found
  if (index < 0) return html;

  // 3. Replace characters (also account for magnitude change, 9 -> 10, 99 -> 100, &c.)
  let newCharacters = replaceInTextCharacterItems(characters, oldText, newText, index);
  if (options.trimLeadingWhitespace) {
    newCharacters = trimCharacterItemsLeadingWhitespace(newCharacters);
  }
  if (options.trimTrailingWhitespace) {
    newCharacters = trimCharacterItemsTrailingWhitespace(newCharacters);
  }

  // 4. Convert back into HtmlString
  const htmlTree = characterItemsToTree(newCharacters);
  const newHtml = htmlTreeToString(htmlTree);
  return newHtml;
}
