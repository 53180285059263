export const cernerTheme = {
  colors: {
    black: '#000000',
    blue: '#0270C4',
    darkishBlue: '#005BA3',
    darkBlue: '#00477D',
    darkGray: '#98A0A7',
    headerBlue: '#A2C4DE',
    hoverBlue: '#1C6DAD',
    lightGray: '#E5E5E5',
    lighterGray: '#EDEDED',
    magenta: '#C52E7F',
    white: '#FFFFFF',
  },
  other: {
    borderRadius4: '4.1px',
  },
};
