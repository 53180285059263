import { FC, memo } from 'react';

import { Button, Icon } from '~/app/reuse';
import { buttonCss, color, iconStyle, size, criticalFlagStyle } from './css';

export const lineThroughStyle = { textDecoration: 'line-through' };

export const ConditionSuggestionAddButton: FC<{
  disabled: boolean;
  onClick(): void;
  removeButtonHovered: boolean;
  text: string;
  isCritical: boolean;
}> = memo(({ disabled, onClick, removeButtonHovered, text, isCritical }) => (
  <Button
    color={color}
    css={buttonCss}
    data-condition-suggestion-add-button
    data-cy-condition-suggestion-add-button
    disabled={disabled}
    onClick={onClick}
    size={size}
  >
    {isCritical && (
      <div
        data-condition-suggestion-add-button-critical-flag
        data-cy-condition-suggestion-add-button-critical-flag
        style={criticalFlagStyle}
      />
    )}
    <Icon iconName="plus" style={iconStyle} />
    <span style={removeButtonHovered ? lineThroughStyle : undefined}>- {text}</span>
  </Button>
));
