import { NotificationLevel } from '~/app/constants';
import { EligibleHtmlBaseNote, NotificationAttrs } from '~/app/@types/state';
import { isReview, isSalesDemoMode } from '~/app/flags';
import { NotificationType } from '~/app/@types';
import { createNotification } from './createNotification';

export const addPreviousEncounterNotification = (
  notifications: NotificationAttrs[],
  selectedBaseNote: EligibleHtmlBaseNote
) => {
  if (isSalesDemoMode) return;
  if (isReview()) return;
  if (selectedBaseNote.currentEncounter) return;
  const notification = createNotification({
    type: NotificationType.PreviousEncounter,
    content: 'Based on note from previous encounter',
    level: NotificationLevel.Warn,
  });
  notifications.push(notification);
};
