import { FC } from 'react';

import { epicTheme } from './epicTheme';

export const EpicHeader: FC = ({ children }) => (
  <div
    style={{
      color: epicTheme.colors.darkBlue,
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: '20px',
      marginBottom: 24,
    }}
  >
    {children}
  </div>
);
