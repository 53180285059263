import { FC } from 'react';

import { Patient } from '../../../@types/state';

export const DemoGridRowLabels: FC<{
  backgroundColor: string;
  rows: {
    label: string;
    items: Record<string, Patient['labResults'][0]>;
  }[];
}> = ({ backgroundColor, rows }) => (
  <div
    style={{
      display: 'inline-block',
      width: 250,
    }}
  >
    <div
      style={{
        borderRight: `1px solid black`,
        padding: '5px 8px 4px',
      }}
    >
      &nbsp;
    </div>
    <div
      style={{
        border: `1px solid black`,
        borderTop: 'none',
      }}
    >
      {rows.map((row, rowIndex) => (
        <div
          key={rowIndex}
          style={{
            backgroundColor,
            borderTop: `1px solid black`,
            fontWeight: 600,
            padding: '4px 8px',
          }}
        >
          {row.label}
        </div>
      ))}
    </div>
  </div>
);
