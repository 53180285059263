import { FC } from 'react';

import { formatISODateString } from '../../../utils';

export const DemoGridColumnLabels: FC<{
  backgroundColor: string;
  columns: ISODateString[];
}> = ({ backgroundColor, columns }) => (
  <div style={{ display: 'flex' }}>
    {columns.map((column, columnIndex) => (
      <div
        key={columnIndex}
        style={{
          backgroundColor,
          border: `1px solid black`,
          borderLeft: 0,
          fontWeight: 600,
          minWidth: 180,
          padding: '4px 8px',
          textAlign: 'center',
        }}
      >
        {formatISODateString(column, 'ddd MMM D YYYY HH:mm')}
      </div>
    ))}
  </div>
);
