import { FC } from 'react';

import { ConditionKeyword } from '../conditionKeyword';
import { HtmlTreeNode, HtmlTreeNodeType } from '../../../utils/htmlCharacterParser';

interface ConditionTitleLineHtmlNodeProps {
  node: HtmlTreeNode;
}

export const ConditionTitleLineHtmlNode: FC<ConditionTitleLineHtmlNodeProps> = ({ node }) => {
  if (node.type === HtmlTreeNodeType.TEXT) {
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: node.text }}
      />
    );
  }

  if (node.type === HtmlTreeNodeType.TAG) {
    const HtmlTag = node.tag as keyof JSX.IntrinsicElements;
    return (
      <HtmlTag>
        {node.children?.map((childNode, i) => (
          <ConditionTitleLineHtmlNode key={i} node={childNode} />
        ))}
      </HtmlTag>
    );
  }

  if (node.type === HtmlTreeNodeType.KEYWORD) {
    return (
      <ConditionKeyword key={node.keyword.text} keyword={node.keyword}>
        {node.children?.map((childNode, i) => (
          <ConditionTitleLineHtmlNode key={i} node={childNode} />
        ))}
      </ConditionKeyword>
    );
  }

  return null;
};
