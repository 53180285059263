import _ from 'lodash';
import { CSSProperties } from 'react';

import { theme } from '../theme';

const border = `1px solid ${theme.colors.grey4}`;

export const tableCss = {
  border,
  borderRadius: theme.other.borderRadius4,
  marginTop: 0,
  '& *': {
    boxSizing: 'border-box', // When box-sizing: border-box is moved to Normalize, this can be deleted.
  },
} as const;

export const getHeaderStyle = _.memoize(
  ({ first }: { first: boolean }): CSSProperties => ({
    backgroundColor: theme.colors.grey5,
    borderTop: first ? 0 : `1px solid ${theme.colors.grey4}`,
    clear: 'both',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '24px',
    padding: '0px 8px 0 4px',
  })
);

export const chevronBaseStyle = {
  display: 'inline-block',
  height: 16,
  marginRight: 4,
  position: 'relative',
  top: 4,
  transition: '150ms ease',
  userSelect: 'none',
  width: 16,
} as const;

export const upChevronStyle = chevronBaseStyle;

export const downChevronStyle = {
  ...chevronBaseStyle,
  transform: 'rotate(180deg)',
} as const;

export const chevronIconStyle = {
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
} as const;

export const floatRightStyle = {
  float: 'right',
} as const;

export const xOfYStyle = {
  color: theme.colors.grey2,
  fontWeight: 400,
  marginRight: 4,
} as const;

export const rowStyle = {
  borderTop: border,
  clear: 'both',
  fontSize: 14,
  lineHeight: '24px',
} as const;

export const cellStyle = {
  float: 'left',
  padding: '0 4px',
} as const;

export const linkButtonStyle = {
  maxWidth: '100%',
} as const;

export const ellipsisSpanStyle = {
  display: 'inline-block',
  lineHeight: 1.25,
  overflow: 'hidden',
  maxWidth: '100%',
  position: 'relative',
  textOverflow: 'ellipsis',
  top: 4,
  whiteSpace: 'nowrap',
} as const;

export const underlineStyle = {
  background: theme.colors.blueRegular,
  bottom: 1,
  height: 1,
  left: 0,
  position: 'absolute',
  right: 0,
} as const;
