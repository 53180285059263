/**
 * In production, we use MiniCssExtractPlugin to build two css files--one for
 *  vendor and one for main. To make sure the styles get saved as a part of the
 *  html blob, rather than sitting in some now inaccessible file, we grab their
 *  contents and inject them into a style tag.
 */
export const embedExternalCssStyles = async (): Promise<void> => {
  const head = document.querySelector('head');

  if (head) {
    const links = document.querySelectorAll('link[rel="stylesheet"]');

    if (links.length) {
      Array.from(links).forEach(async (link) => {
        const url = link.getAttribute('href');

        if (url) {
          const response = await fetch(url);
          const text = await response.text();

          const style = document.createElement('style');
          style.innerHTML = text;

          head.appendChild(style);
        }
      });
    }
  }
};
