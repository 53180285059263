import { captureException } from '@sentry/browser';
import { isIE } from '~/app/utils';

import { getClipboardIframe } from './clipboardIframe';
import { CopyResult } from './types';

export const copyTextToClipboard = async (text: string): Promise<CopyResult> => {
  // Any modern browser
  if (!isIE()) {
    // NOTE: This had to be added because the IE method doesn't work in fullscreen chromium browsers
    try {
      await navigator.clipboard.writeText(text);
      return { error: null, copyMethod: 'clipboard' };
    } catch (e) {
      captureException(e);
    }
  }

  // Fallback to the legacy `execCommand` method of copying to the clipboard (works in IE)
  try {
    const iframeDocument = getClipboardIframe()?.contentWindow?.document;
    if (!iframeDocument) {
      return { error: 'No iframe content', copyMethod: null };
    }

    const textarea = iframeDocument.createElement('textarea');
    // position fixed prevents this textarea from impacting the layout
    //  flow of the current DOM elements
    textarea.style.position = 'fixed';
    iframeDocument.body.appendChild(textarea);
    textarea.innerHTML = text;
    textarea.select();
    // NOTE: `execCommand` is deprecated, but IE doesn't support the newer
    //  Clipboard API: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
    iframeDocument.execCommand('copy');
    iframeDocument.body.removeChild(textarea);
    return { error: null, copyMethod: 'execCommand' };
  } catch (e) {
    captureException(e);
  }

  return { error: 'Unable to copy note to clipboard', copyMethod: null };
};
