import { NotificationType } from '~/app/@types';
import { NotificationAttrs, NotificationContent, NotificationTransform } from '~/app/@types/state';
import { NotificationLevel } from '~/app/constants';
import { uniqueId } from '~/app/utils';

export const createNotification = ({
  type,
  buttonText,
  buttonFunc,
  content,
  followingState,
  level,
  allowDismissal,
  retainOnButtonClick,
}: {
  type: NotificationType;
  buttonText?: string;
  buttonFunc?: (argo0?: string) => void;
  content: NotificationContent;
  followingState?: (frame: number) => NotificationTransform;
  level: NotificationLevel;
  allowDismissal?: boolean;
  retainOnButtonClick?: boolean;
}): NotificationAttrs => ({
  id: uniqueId(),
  type,
  content,
  level,
  buttonText,
  buttonFunc,
  followingState,
  allowDismissal,
  retainOnButtonClick,
});
