import { SummaryP, SummarySpanB, SummaryUl } from './common';

export const acuteKidneyInjurySummary = (
  <>
    <SummaryP first>
      Regard will attempt to calculate a <SummarySpanB>baseline serum creatinine</SummarySpanB>{' '}
      (sCr) level from labs over the last 2 years performing a modified linear regression
      interpolation algorithm (remove spikes, then remove outliers, then best fit line of remaining
      values). If there is not enough data a baseline will not be available. Next, Regard will
      exclude patients who are on dialysis.
    </SummaryP>
    <SummaryP>
      The algorithm will attempt 3 methods to diagnose AKI based on KDIGO guidelines: an increase in
      sCr from baseline or prior labs or decreased urine output.
    </SummaryP>
    <SummaryUl>
      <li>
        Regard will check any creatinine lab no more than 4 days before the start of the current
        encounter. A positive dx will trigger for an elevated sCr that is 1.5x the sCr baseline or
        0.3mg/dL more than the sCr baseline. The dx is considered resolved if the most recent sCr is
        no more than the baseline. If a sCr baseline is not available, Regard will apply the
        criteria between any two sCr labs within a 48-hour period.
      </li>
      <li>
        If no diagnosis is made, Regard will attempt to diagnose based on{' '}
        <SummarySpanB>low urine output</SummarySpanB>. Any urine volumes from the current encounter
        and past 4 days will be compared to the closest chronological weight. The algorithm will
        trigger a positive dx for any urine rate less than 0.5 ml/kg/hr.
      </li>
      <li>
        Lastly, if none of the above trigger a diagnosis, Regard will search the current notes and
        problem list for mention of AKI.
      </li>
    </SummaryUl>
  </>
);
