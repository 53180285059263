import { Keyword } from '../../@types/state';
import { insertAll } from '../insertAll';
import { CharacterItemTagType, CharacterItem, CharacterItemTag } from './types';
import { tagsAreEqual } from './characterItemTagsAreEqual';

function allAtIndexAreEqual<T>(lists: T[][], index: number, isEqual: (a: T, b: T) => boolean) {
  const valuesAtIndex = lists.map((item) => item[index]);
  const firstItemValue = lists[0][index];
  if (firstItemValue === undefined) {
    return false;
  }
  return valuesAtIndex.every((value) => isEqual(value, firstItemValue));
}

const findTagIndexOfFirstItemNotInCommon = (tags: CharacterItemTag[][]) => {
  let tagIndex = 0;
  while (tags[0][tagIndex]) {
    const allAreSame = allAtIndexAreEqual<CharacterItemTag>(tags, tagIndex, tagsAreEqual);
    if (allAreSame) {
      tagIndex += 1;
    } else {
      break;
    }
  }

  return tagIndex;
};

interface InsertTagAtMatchedKeywordParams {
  characterItems: CharacterItem[];
  rawText: string;
  keyword: Keyword;
  nextCharacterIndex: number;
}

interface InsertTagAtMatchedKeywordReturn {
  characterItems: CharacterItem[];
  nextCharacterIndex: number;
}

const insertTagAtMatchedKeyword = ({
  characterItems,
  rawText,
  keyword,
  nextCharacterIndex,
}: InsertTagAtMatchedKeywordParams): InsertTagAtMatchedKeywordReturn => {
  // Check if keyword is found
  const matchIndex = rawText.indexOf(keyword.text, nextCharacterIndex);
  // if no match is found, no change needed
  if (matchIndex === -1) {
    return { characterItems, nextCharacterIndex };
  }

  const characterStartIndex = matchIndex;
  const characterEndIndex = matchIndex + keyword.text.length;

  // For every item in the keyword range, find the tags in common
  const matchedCharacters = characterItems.slice(characterStartIndex, characterEndIndex);
  const tags = matchedCharacters.map((item) => item.tags);

  const tagIndex = findTagIndexOfFirstItemNotInCommon(tags);

  // Then insert the new tag after all the tags in common, which "wraps"
  // them in the same tag.
  const newKeywordTag: CharacterItemTag = { type: CharacterItemTagType.KEYWORD, keyword };

  const modifiedMatchedItems = matchedCharacters.map((matchedCharacter) => ({
    ...matchedCharacter,
    tags: insertAll(matchedCharacter.tags, tagIndex, [newKeywordTag]),
  }));

  const modifiedCharacterItems = [
    ...characterItems.slice(0, characterStartIndex),
    ...modifiedMatchedItems,
    ...characterItems.slice(characterEndIndex),
  ];

  return {
    characterItems: modifiedCharacterItems,
    nextCharacterIndex: characterEndIndex,
  };
};

interface InsertTagAtMatchedKeywordsParams {
  keywords: Keyword[];
  characterItems: CharacterItem[];
}

/**
 * For the given `characterItems`, insertTagAtMatchedKeywords looks for the given
 * keywords in order. When a match is found, a new `keyword` tag is
 * inserted into the highest like parent of the matched character items.
 * Note: Keywords are matched in order and later keywords must come after
 *   previous keywords in the `characterItems`.
 */
export const insertTagAtMatchedKeywords = ({
  keywords,
  characterItems,
}: InsertTagAtMatchedKeywordsParams): CharacterItem[] => {
  const rawText = characterItems.map((item) => item.character).join('');

  let modifiedCharacterItems = [...characterItems];
  let nextCharacterIndex = 0;

  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    ({ characterItems: modifiedCharacterItems, nextCharacterIndex } = insertTagAtMatchedKeyword({
      characterItems: modifiedCharacterItems,
      rawText,
      keyword,
      nextCharacterIndex,
    }));
  }

  return modifiedCharacterItems;
};
