import styled from '@emotion/styled';
import { memoize } from 'lodash';
import { theme, zIndices } from '~/app/reuse';
import { CONDITION_UNDERLAY_BUTTONS_BORDER_RADIUS, NOTE_RIGHT_GUTTER } from '../const';

export const Divider = styled.div({
  display: 'inline-block',
  width: 1,
  '&:after': {
    backgroundColor: theme.colors.grey4,
    content: '""',
    height: 16,
    position: 'absolute',
    top: 4,
    width: 1,
  },
});

const rootStyle = {
  borderLeft: `1px solid ${theme.colors.grey4}`,
  borderRight: `1px solid ${theme.colors.grey4}`,
  borderTop: `1px solid ${theme.colors.grey4}`,
  ...CONDITION_UNDERLAY_BUTTONS_BORDER_RADIUS,
  lineHeight: 0,
  padding: '2px 2px 0',
  position: 'absolute',
  right: NOTE_RIGHT_GUTTER,
  transform: 'translateY(-100%)',
  top: 1,
  zIndex: zIndices.conditionButtons,
} as const;

const rootHoverStyle = {
  ...rootStyle,
  borderLeft: `1px solid ${theme.colors.blueRegular}`,
  borderRight: `1px solid ${theme.colors.blueRegular}`,
  borderTop: `1px solid ${theme.colors.blueRegular}`,
} as const;

export const getRootStyle = memoize(
  ({ reverseIndex, buttonHovered }: { reverseIndex: number; buttonHovered: boolean }) =>
    ({
      ...(buttonHovered ? rootHoverStyle : rootStyle),
      // ZIndex based on the reverse index of the conditions allows popover menus (like the
      // condition move menu) to correctly render over the subsequent condition UI while
      // allowing the ConditionButtons to render over the previous condition's underlay.
      // 1 is added to make sure the buttons always render over the condition background
      zIndex: reverseIndex + 1,
    } as const)
);

const spacerStyle = {
  display: 'inline-block',
  width: 4,
} as const;

export const spacer = <div style={spacerStyle} />;
