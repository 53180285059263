import { theme } from '~/app/reuse';

const VERTICAL_PADDING = 20;
const TEXTAREA_HEIGHT = 66;
export const MAX_CHAT_FORM_VERTICAL_HEIGHT = VERTICAL_PADDING * 2 + TEXTAREA_HEIGHT;
export const MAX_CHAT_FORM_HEIGHT = MAX_CHAT_FORM_VERTICAL_HEIGHT + 40; // 40 is the heigh of the button and it's margin-top

export const style = {
  bottom: 0,
  height: MAX_CHAT_FORM_HEIGHT,
  position: 'absolute',
  width: '100%',
} as const;

export const verticalStyle = {
  ...style,
  height: MAX_CHAT_FORM_VERTICAL_HEIGHT,
} as const;

export const shadowStyle = {
  boxShadow: '0 0 6px 1px rgba(0, 0, 0, .2)',
  left: 0,
  position: 'absolute',
  right: 0,
  zIndex: 1,
} as const;

export const innerStyle = {
  backgroundColor: theme.colors.white,
  bottom: 0,
  left: 0,
  padding: `${VERTICAL_PADDING}px 24px`,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
} as const;

export const innerInnerStyle = {
  position: 'relative',
} as const;

export const textareaStyle = {
  border: `1px solid ${theme.colors.grey3}`,
  borderRadius: theme.other.borderRadius3,
  display: 'block',
  height: TEXTAREA_HEIGHT,
  padding: '4px 6px',
  resize: 'none',
  width: '100%',
} as const;

export const textareaVerticalStyle = {
  ...textareaStyle,
  width: 'calc(100% - 60px)',
} as const;

export const buttonContainerStyle = {
  marginTop: 8,
  textAlign: 'right',
} as const;

export const buttonContainerVerticalStyle = {
  position: 'absolute',
  right: 0,
  top: 0,
} as const;

export const submitButtonStyle = {
  minWidth: 40,
} as const;
