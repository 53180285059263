import { UiState } from '../../../../@types/state';
import { createSelector, useShallowEqualSelector } from '../../../../store';

const saveDraftNoteSelector = createSelector(
  (state) => ({
    saveDraftNoteLastSaved: state.ui.saveDraftNoteLastSaved,
    saveDraftNoteStatus: state.ui.saveDraftNoteStatus,
  }),
  (x) => x
);

export const useSaveDraftNoteStatus = (): {
  saveDraftNoteLastSaved: UiState['saveDraftNoteLastSaved'];
  saveDraftNoteStatus: UiState['saveDraftNoteStatus'];
} => {
  const { saveDraftNoteLastSaved, saveDraftNoteStatus } =
    useShallowEqualSelector(saveDraftNoteSelector);

  return {
    saveDraftNoteLastSaved,
    saveDraftNoteStatus,
  };
};
