import _ from 'lodash';
import { ReactNode, useState } from 'react';

import { useDemoData } from './useDemoData';
import { formatISODateString } from '../../../utils';
import { Patient } from '../../../@types/state';

export type DemoRow = {
  draft: boolean;
  isSelected: boolean;
  items: ReactNode[];
  onClick(): void;
};

const columns = [
  {
    fallback: new Date(),
    key: 'createTime',
    label: 'Service date',
  },
  {
    fallback: '',
    key: 'type',
    label: 'Subject',
  },
  {
    fallback: 'Internal Medicine',
    key: '',
    label: 'Type',
  },
] as const;

const header: DemoRow = {
  draft: false,
  isSelected: false,
  items: columns.map(({ label }) => label),
  onClick: _.noop,
};

const sanitize = (value: Date | string): string =>
  value instanceof Date ? formatISODateString(value.toISOString(), 'MM/DD/YYYY') : value;

export const useDemoNotesRows = (): {
  rows: DemoRow[];
  selectedItem: Patient['notes'][0];
} => {
  const [selected, setSelected] = useState(0);

  const notes = useDemoData({ category: 'notes' });

  const content = notes.map((note, i) => {
    const isSelected = selected === i;
    const { draft } = note;

    return {
      draft,
      isSelected,
      items: columns.map(({ fallback, key }) => {
        const valueOrFallback = key ? note[key] : fallback;
        const sanitizedValue = sanitize(valueOrFallback);
        const isTime = key === 'createTime';

        return isTime && draft ? (
          <>
            {sanitizedValue} <b>Draft</b>
          </>
        ) : (
          sanitizedValue
        );
      }),
      onClick: isSelected ? _.noop : () => setSelected(i),
    };
  });

  return { rows: [header, ...content], selectedItem: notes[selected] };
};
