import classNames from 'classnames';
import { FC, useCallback, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { PATIENT_PARAM } from '~/app/constants';
import { MeshedNoteAppDivider } from './MeshedNoteAppDivider';
import { useMeshedContext } from './MeshedProvider';
import { NoteContainer } from './NoteContainer';
import { SourceDrawer } from './sourceDrawer';
import { DxProcessDrawer } from './dxProcessDrawer';
import { TabPanel } from './TabPanel';
import { FetchError } from '../FetchError';
import { ResourcesFlyout } from '../resourcesFlyout';
import { AppState } from '../../@types/state';
import { UserFeedbackWidget } from '../form/userFeedback/userFeedbackWidget';
import { fetchNoteThunk } from '../../actions/regardNote';
import { createSelector, useDispatch, useSelector } from '../../store';
import '../../styles/MeshedNoteApp.scss';
import '../../styles/MeshedNoteAppVertical.scss';
import { useMeshedNoteAppStyle } from './useMeshedNoteAppStyle';
import { RegardLoading } from './regardLoading';
import { TrackFirstUserInteraction } from '../app/trackFirstUserInteraction';
import { DetailsProvider } from './detailsProvider';
import { Regard2Modal } from './regard2Modal';

const isTabPanelOpenSelector = createSelector(
  (state) => state.ui.tabPanelOpen,
  (x) => x
);

const useMeshedNoteApp = () => {
  const dispatch = useDispatch();

  const dispatchFetchNoteThunk = useCallback(() => dispatch(fetchNoteThunk()), [dispatch]);
  const dispatchOpenTabPanel = useCallback(
    () =>
      dispatch({
        type: 'open tab panel',
      }),
    [dispatch]
  );
  const dispatchCloseTabPanel = useCallback(
    () =>
      dispatch({
        type: 'close tab panel',
      }),
    [dispatch]
  );

  const isTabPanelOpen = useSelector(isTabPanelOpenSelector);

  return {
    dispatchCloseTabPanel,
    dispatchFetchNoteThunk,
    dispatchOpenTabPanel,
    isTabPanelOpen,
  };
};

const UnconnectedMeshedNoteApp: FC<
  {
    disableFeedback?: boolean;
  } & PropsFromRedux
> = ({ timestamp, disableFeedback, fetchError, isAuthorized, isFetching, login, pt, fetching }) => {
  const error = login.error || fetchError;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ptParam = params.get(PATIENT_PARAM);
  const ptChanged = pt !== ptParam;

  // Record encoded patient id; this is used with Amplitude
  window.regardEncodedPatientId = ptParam ?? '';

  const { isVerticalLayout } = useMeshedContext(({ isVerticalLayout }) => ({ isVerticalLayout }));

  const meshedNoteAppStyle = useMeshedNoteAppStyle();

  const { dispatchCloseTabPanel, dispatchFetchNoteThunk, dispatchOpenTabPanel, isTabPanelOpen } =
    useMeshedNoteApp();

  useEffect(() => {
    if (!timestamp && !isFetching && !error) {
      // This case translates to: "if we have no data, fetch the regardNote data from API
      dispatchFetchNoteThunk();
    } else if (ptChanged && !isFetching && !error) {
      dispatchFetchNoteThunk();
    }
  }, [dispatchFetchNoteThunk, error, isFetching, timestamp, ptChanged]);

  if (error) {
    return <FetchError message={error.message} redirect={error.redirect} status={error.status} />;
  }

  if (login.isFetching || isFetching || !timestamp) {
    return (
      <RegardLoading
        estimatedPatientLoadDuration={fetching.estimatedPatientLoadDuration}
        isVerticalLayout={isVerticalLayout}
        percentage={fetching.percentage}
        phase={fetching.phase}
      />
    );
  }

  return (
    <div
      className={classNames('MeshedNoteApp', {
        vert: isVerticalLayout,
        collapsed: !isTabPanelOpen,
      })}
      style={meshedNoteAppStyle}
    >
      {isAuthorized && <TrackFirstUserInteraction />}
      <SourceDrawer />
      <ResourcesFlyout />
      <DxProcessDrawer />
      <Regard2Modal />
      {!disableFeedback && <UserFeedbackWidget />}
      <div className="editor-container">
        <NoteContainer timestamp={timestamp} />
      </div>
      {!isVerticalLayout && <MeshedNoteAppDivider />}
      <DetailsProvider>
        <TabPanel
          close={dispatchCloseTabPanel}
          detailsCollapsed={!isTabPanelOpen}
          disableFeedback={disableFeedback}
          open={dispatchOpenTabPanel}
        />
      </DetailsProvider>
    </div>
  );
};

const connector = connect(
  ({
    regardNote: { timestamp, fetchError, fetching, isAuthorized, isFetching, pt },
    login,
  }: AppState) => ({
    timestamp,
    fetchError,
    fetching,
    isAuthorized,
    isFetching,
    login,
    pt,
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;

// eslint-disable-next-line import/prefer-default-export
export const MeshedNoteApp = connector(UnconnectedMeshedNoteApp);
