import { useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '~/app/utils';

export const useLocalStorageState = (key: string): [string, (value: string) => void] => {
  const [value, _setValue] = useState(getLocalStorageItem(key) ?? '');

  const setValue = (value: string) => {
    setLocalStorageItem(key, value);
    _setValue(value);
  };

  return [value, setValue];
};
