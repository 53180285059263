import { SummaryOl } from './common';

export const covidSummary = (
  <SummaryOl>
    <li>
      The algorithm will search notes for evidence of COVID-19 and assign it to one of four
      categories: conditions, notes, tests, or exposure.
    </li>
    <li>
      If the patient is positive for an exposure, the diagnosis will only fire if there is a
      positive test.
    </li>
    <li>
      For all other categories, Regard will suggest a positive COVID diagnosis and display relevant
      evidence.
    </li>
  </SummaryOl>
);
