/**
 * Safe wrappers for local and session storage.
 * IE11 will sometimes throw errors (like 'The operation attempted to access data outside the valid range')
 * when accessing localStorage. Since localStorage is only used as a caching layer and other nice to haves,
 * it's better to fail silently.
 *
 * Side Note: localStorage at UAMS seems to be inaccessible:
 * https://healthtensor.sentry.io/issues/3981102310/?project=6088545
 */

export const getLocalStorageItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch {
    return '';
  }
};

export const setLocalStorageItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // localStorage could not set item, quietly disregard
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // localStorage could not set item, quietly disregard
  }
};

export const getSessionStorageItem = (key: string) => {
  try {
    return sessionStorage.getItem(key);
  } catch {
    return '';
  }
};

export const setSessionStorageItem = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch {
    // localStorage could not set item, quietly disregard
  }
};

export const removeSessionStorageItem = (key: string) => {
  try {
    sessionStorage.removeItem(key);
  } catch {
    // localStorage could not set item, quietly disregard
  }
};
