export type ConditionLineStatus = 'new' | 'updated' | 'existing' | 'none';

export type Suggestion = {
  ids: string[];
  status: ConditionLineStatus;
  tags: BulletTag[];
  text: string;
  type: string;
  interpretation?: ObservationInterpretation;
};

// NOTE: Enums cannot be exported from a .d.ts file--at least not without some
//  finagling that I'm not familiar with!
export enum BulletType {
  Assessment = 'ASSESSMENT',
  General = 'GENERAL',
  Plan = 'PLAN',
}

// maps to constants in automed/assessment_play.py
export enum BulletTag {
  Info = 'info',
  Medication = 'medication',
  Observation = 'observation',
}

// maps to Interpretation constants in automed/models/ranges.py
export enum ObservationInterpretation {
  CriticallyLow = 'critically low',
  Low = 'low',
  Normal = 'normal',
  High = 'high',
  CriticallyHigh = 'critically high',
  Critical = 'critical',
  QualitativelyAbnormal = 'abnormal',
  Unknown = '',
}
export type ObservationInterpretationLiteral = `${ObservationInterpretation}`;

export enum BulletNoteStatus {
  Hidden = 'hidden',
  Noted = 'noted',
  Monitored = 'monitored',
  Shelved = 'shelved',
}

export enum NoteType {
  Blank = 'blank', // no DB note available, or empty note
  Draft = 'draft', // a draft stored in `generated_notes` DB table
  Note = 'note', // a note parsed from a signed EHR note
}

export enum SourceType {
  SourceCondition = 'SourceCondition',
  SourceNote = 'SourceNote',
  SupportingEvidence = 'SupportingEvidence',
}

export enum DiagnosisType {
  CoreDx = 'Core Dx', // best
  LiveDx = 'Live Dx', // good
  SupplementalDx = 'Supplemental Dx', // better
}
export type DiagnosisTypes = `${DiagnosisType}`;

export enum ConditionDevelopmentState {
  ProdReady = 'Prod Ready',
  CaseReview = 'Under Case Review',
  InProgress = 'Work In Progress',
  Unknown = 'Unknown',
}
export type ConditionDevelopmentStates = `${ConditionDevelopmentState}`;

export enum HtmlVersion {
  // PlainText = 0, // (deprecated) No Html tags or formatting
  // HtmlWithBold = 1, // (deprecated) Html with support for <p> and <strong> tags
  // HtmlWithDiv = 2, // (deprecated) Html with support for <div> and <strong> tags
  HtmlWithEMU = 3, // Html with support for <div>, <strong>, <em>, and <u> tags
}

export enum PatientLoadPhase {
  Started = 'STARTED',
  Fetching = 'FETCHING',
  Mapping = 'MAPPING',
  Dxing = 'DXING',
  Loading = 'LOADING', // Fallback status indicating that our tracking of load phase has failed
}
export type PatientLoadPhases = `${PatientLoadPhase}`;

export enum PatientLoadDuration {
  Long = 'LONG', // Initial patient load
  Medium = 'MEDIUM', // Patient load after 5 days
  Short = 'SHORT', // Near "instant" patient load
}
export type PatientLoadDurations = `${PatientLoadDuration}`;

export enum ConditionLineType {
  Title = 'title',
  FreeText = 'freetext',
  Bullet = 'bullet',
  ShelfDivider = 'shelfDivider',
}
export type ConditionLineTypeLiteral = `${ConditionLineType}`;

export enum NotificationType {
  ConditionsDismissed = 'conditions-dismissed',
  ConditionsUpdated = 'conditions-updated',
  NoDiagnosis = 'no-diagnosis',
  CriticalDiagnosis = 'critical-diagnosis',
  PreviousEncounter = 'previous-encounter',
  NoteError = 'note-error',
  BaseNoteChanged = 'base-note-changed',
}
export type NotificationTypeLiteral = `${NotificationType}`;
