import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import MedicalSVG from '~/app/images/medical.svg';
import TrafficBarrierSVG from '~/app/images/traffic-barrier.svg';
import {
  RECEIVE_REGARD_NOTE_FAILURE_STATUS,
  PT_BELOW_MIN_AGE_MESSAGE,
  COMPANY_NAME,
  COMPANY_SUPPORT_EMAIL,
} from '~/app/constants';
import '~/app/styles/FetchError.scss';
import { openFeedback as createOpenFeedbackAction } from '~/app/actions/ui';
import { Button } from '~/app/reuse';
import { getOAuthSubject } from '~/app/cookies';
import { reloadAndForceRefetch } from '~/app/utils';
import { clickedRefreshFromError } from '~/app/analytics/track';
import { regardDisabledMessage, regardDisabledMessageSecondary } from '~/app/flags';

import { UserFeedbackWidget } from './form';
import { copyBoldToClipboard } from './meshed/noteContainerHeader/noteContainerHeaderDefaultTitle/copyBoldToClipboard';

export const FetchError: FC<{
  redirect?: string | null;
  status: string | number | null | undefined;
  message?: string | null;
}> = ({ redirect, status, message }) => {
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);

  const openFeedback = useCallback(
    (e) => {
      // Used for testing copying of bold text in production when auth fails
      if (e.shiftKey) {
        copyBoldToClipboard({ includeExplicitStylesTest: true, includeFontTest: true });
        e.preventDefault();
        e.stopPropagation();
        setCopied(true);
        return;
      }

      dispatch(createOpenFeedbackAction());
    },
    [dispatch]
  );

  if (redirect) {
    if (redirect === window.location.pathname) {
      // If the error is redirecting to the current page then we're right where
      // we want to be: don't render the error.
      return null;
    }
    window.location.href = redirect;
    return null;
  }

  let h4Text = 'Something went wrong';
  let pText = 'The application has encountered an unexpected error.';
  const defaultReloadMsg = 'Try reloading the page or, if the problem persists, contact us.';
  let showPitch = false;
  let showContact = true;
  let showCustom = '';
  let showReload = true;
  let icon = <MedicalSVG alt="Medical" />;

  if (status === 400) {
    if (message === PT_BELOW_MIN_AGE_MESSAGE) {
      h4Text = 'Patient is below the minimum age';
      pText = `${COMPANY_NAME} notes are only available for patients at least 18 years of age.`;
      showCustom = "To view this patient's data, return to the EHR's patient information tabs.";
      showContact = false;
      showReload = false;
    } else if (message !== undefined) {
      h4Text = '400 - Bad Request';
      pText = message ? message.charAt(0).toUpperCase() + message.substring(1) : '';
      showCustom = defaultReloadMsg;
    } else {
      h4Text = '400 - Bad Request';
      pText = 'Sorry, our server did not understand the request.';
      showCustom = defaultReloadMsg;
    }
  } else if (status === 403) {
    h4Text = 'Authorization Required';
    pText =
      'Sorry, it seems you do not have access to this page. If you are already authorized and still unable to access, contact us.';
    const oauthSub = getOAuthSubject();
    // use `user ID` terminology to be user friendly
    showCustom = oauthSub ? `Your user ID is ${oauthSub}` : '';
    showPitch = false;
    showContact = true;
    showReload = true;
  } else if (status === 404) {
    h4Text = 'Patient not found';
    pText = 'Sorry, we were unable to locate this patient’s data in the server.';
    showCustom = defaultReloadMsg;
  } else if (status === 410) {
    h4Text = 'Access Denied';
    pText = regardDisabledMessage ?? 'Regard has been disabled';
    showCustom =
      regardDisabledMessageSecondary ??
      [
        'We’ve enjoyed collaborating with all the clinical users & champions and',
        'hope to work with you all in the future. Until then, don’t be a stranger,',
        'please stay in touch with the Regard team on ideas, input, or questions.',
      ].join(' ');
    showContact = false;
    showReload = false;
    icon = <TrafficBarrierSVG alt="Traffic Barrier" />;
  } else if (status === 449) {
    h4Text = 'Not authorized for patient';
    pText = 'You have not yet been authenticated to access this patient.';
    showCustom = 'Patients must be opened using the EHR launcher.';
    showContact = false;
    showReload = false;
  } else if (status === 500) {
    h4Text = 'Patient loading error';
    pText = 'Sorry, we were unable to load the patient.';
    showCustom = defaultReloadMsg;
  } else if (status === 503 && message === 'transient-auth-error') {
    h4Text = 'EHR Authentication Error';
    pText =
      'The EHR seems to be having an issue authenticating you. Try closing and reopening Regard.';
    showCustom = 'If the problem persists, please reach out to IT and support@withregard.com.';
    showContact = true;
    showReload = false;
  } else if (status === 999) {
    h4Text = 'Invalid access';
    pText = 'This application must be accessed through an EHR launcher.';
    showContact = false;
    showReload = false;
  } else if (status === RECEIVE_REGARD_NOTE_FAILURE_STATUS) {
    h4Text = 'Fetching patient data';
    pText = "It's taking longer than usual to fetch patient data from your EHR.";
    showCustom = defaultReloadMsg;
  }

  return (
    <div className="FetchErrorWindow">
      <div className="ErrorContainer">
        <UserFeedbackWidget fullSize />
        {icon}
        <h4>{h4Text}</h4>
        <p>{pText}</p>
        {showPitch && (
          <p className="NextParagraph">
            {COMPANY_NAME} is an AI-powered clinical decision support tool. If you are interested in
            using {COMPANY_NAME}, please contact us at{' '}
            <a href={`mailto:${COMPANY_SUPPORT_EMAIL}`}>{COMPANY_SUPPORT_EMAIL}</a> for more
            information.
          </p>
        )}
        {showCustom && <p className="NextParagraph">{showCustom}</p>}
        {showReload && (
          <Button
            color="primary"
            css={{ display: 'block', marginTop: 35, marginLeft: 'auto', marginRight: 'auto' }}
            onClick={() => {
              clickedRefreshFromError();
              reloadAndForceRefetch({ wait: 1200 }); // give enough time for the tracking call to resolve
            }}
            size="standard"
          >
            Reload page
          </Button>
        )}
        {showContact && (
          <Button
            color="tertiary"
            css={{ display: 'block', marginTop: 12, marginLeft: 'auto', marginRight: 'auto' }}
            data-cy-open-user-feedback-button
            onClick={openFeedback}
            size="standard"
          >
            Contact Us
          </Button>
        )}
        {copied && <small>Testing text copied</small>}
      </div>
    </div>
  );
};
