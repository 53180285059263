import { EMPTY_LINE_NBSP } from '~/app/constants';
import { htmlFontFaceOnCopy, htmlFontSizeOnCopy } from '~/app/flags';

import {
  stripInvalidCharacters,
  htmlStringToPlainText,
  replaceDivBlocksWithBr,
  plainTextToHtmlString,
  removeRedundantTags,
  getEmptyLine,
  getEditorHtmlChunks,
  cleanupDomHtml,
  replaceLineDivsWithLists,
  getEditorHtmlChunksById,
} from '~/app/utils';

import { getPhysicalExamText } from './getPhysicalExamText';

interface CreateFullNoteOptions {
  noteBlockId?: string;
  usePlaintext?: boolean;
  includePhysicalExam?: boolean;
  convertLinesToListOnCopy: boolean;
  useBrLineFormat: boolean;
  wrapWithFontTag: boolean;
}

const defaultNoteOptions = {
  usePlaintext: false,
  includePhysicalExam: false,
  convertLinesToListOnCopy: false,
  useBrLineFormat: false,
  wrapWithFontTag: false,
};

export const createPlainTextNote = (chunks: HtmlString[], includePhysicalExam?: boolean) => {
  const physicalExamText = includePhysicalExam ? getPhysicalExamText() : '';
  const domNote = chunks
    .filter((chunk) => chunk !== '' && chunk !== '\n')
    .map((chunk) => stripInvalidCharacters(htmlStringToPlainText(chunk as HtmlString)))
    .join('\n\n');

  return (physicalExamText ? `${physicalExamText}\n\n` : '') + domNote;
};

export const createHtmlNote = (
  chunks: HtmlString[],
  options: CreateFullNoteOptions = defaultNoteOptions
) => {
  const { includePhysicalExam, convertLinesToListOnCopy, useBrLineFormat, wrapWithFontTag } =
    options;

  const htmlEmptyLine = useBrLineFormat ? 'empty' : EMPTY_LINE_NBSP;
  const physicalExamText = includePhysicalExam ? getPhysicalExamText() : '';

  const emptyLine = getEmptyLine({
    emptyLine: htmlEmptyLine,
  });

  const domNote = cleanupDomHtml(chunks, {
    emptyLine: htmlEmptyLine,
    shouldRemoveRedundantTags: true,
  }) as HtmlString;

  let modifiedDomNote = domNote;

  if (physicalExamText) {
    modifiedDomNote = `${removeRedundantTags(plainTextToHtmlString(physicalExamText), {
      emptyLine: htmlEmptyLine,
    })}${emptyLine}${modifiedDomNote}` as HtmlString;
  }

  // Turning on `convertLinesToListOnCopy` and `useBrLineFormat` at the same time may break
  // `useBrLineFormat` since it doesn't account for list tags.
  // `convertLinesToListOnCopy` is currently only slated for the Epic client LGH, which does
  // not use `useBrLineFormat`. These two flags are made mutually exclusive for now and will
  // be revisited if Cerner clients ever need `convertLinesToListOnCopy`.
  if (convertLinesToListOnCopy && !useBrLineFormat) {
    modifiedDomNote = replaceLineDivsWithLists(modifiedDomNote, { emptyLine });
  }

  if (useBrLineFormat) {
    modifiedDomNote = replaceDivBlocksWithBr(modifiedDomNote);
  }

  if (wrapWithFontTag) {
    modifiedDomNote =
      `<font face="${htmlFontFaceOnCopy}" size="${htmlFontSizeOnCopy}">${modifiedDomNote}</font>` as HtmlString;
  }

  return modifiedDomNote;
};

/**
 * The function gets all the editors' text directly from the HTML
 * textareas. It trims each value, appends them with newlines and returns
 */
export const createFullNote = (options: CreateFullNoteOptions) => {
  const htmlChunks = options.noteBlockId
    ? getEditorHtmlChunksById(options.noteBlockId)
    : getEditorHtmlChunks();

  return options.usePlaintext
    ? createPlainTextNote(htmlChunks, options.includePhysicalExam)
    : createHtmlNote(htmlChunks, options);
};
