import { FC } from 'react';
import _ from 'lodash';

import { useMeshedContext } from '../MeshedProvider';
import { EligibleHtmlBaseNote } from '../../../@types/state';
import { getIsBlankBaseNote } from '../../../controllers/regardNote';
import { theme, zIndices } from '../../../reuse';
import { formatDatetime } from '../../../utils';
import { SELECT_BASE_NOTE_DROPDOWN_HEIGHT } from '../selectBaseNoteDropdown/css';
import { NOTE_CONTAINER_HEADER_HEIGHT } from './css';

const style = {
  backgroundColor: theme.colors.white,
  borderTop: `8px solid ${theme.colors.grey4}`,
  bottom: 0,
  color: theme.colors.grey3,
  height: `calc(${100 - SELECT_BASE_NOTE_DROPDOWN_HEIGHT}vh - ${
    NOTE_CONTAINER_HEADER_HEIGHT / 2 - 1
  }px)`,
  left: 0,
  overflow: 'auto',
  position: 'absolute',
  width: '100%',
  zIndex: zIndices.selectBaseNotePreview,
} as const;

export const getSelectBaseNotePreviewStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...style,
      padding: isScaledHorizontalLayout ? '0px calc(50% - 336px)' : undefined,
    } as const)
);

const headerStyle = {
  fontWeight: 600,
  margin: '20px 0',
  padding: '0 24px',
} as const;

const contentStyle = {
  margin: '20px 0',
  padding: '0 24px',
  whiteSpace: 'pre-wrap',
} as const;

const previousEncountersHeaderStyle = {
  background: '#F1CFB0',
  marginBottom: '8px',
} as const;

const previousEncountersTitleStyle = {
  color: theme.colors.grey1,
  marginLeft: '24px',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '24px',
  textTransform: 'uppercase',
} as const;

const htmlPreviewStyle = {
  div: {
    // because these div tags don't have brs in them
    minHeight: 21,
  },
} as const;

const noNoteSelectedPreview = (
  <span style={{ color: theme.colors.grey2, fontStyle: 'italic' }}>No note selected.</span>
);

const blankNoteSelectedPreview = (
  <>
    <div data-cy-preview-note-header style={headerStyle}>
      Remove base note
    </div>
    <div data-cy-cyob-preview-note-contents style={contentStyle}>
      Only loads diagnoses identified by Regard
    </div>
  </>
);

export const SelectBaseNotePreview: FC<{
  isOpen: boolean;
  selectedBaseNote: EligibleHtmlBaseNote | undefined;
}> = ({ isOpen, selectedBaseNote }) => {
  const { isScaledHorizontalLayout } = useMeshedContext(({ isScaledHorizontalLayout }) => ({
    isScaledHorizontalLayout,
  }));
  if (!isOpen) {
    return null;
  }

  if (!selectedBaseNote) {
    return noNoteSelectedPreview;
  }

  if (getIsBlankBaseNote(selectedBaseNote)) {
    return <div style={style}>{blankNoteSelectedPreview}</div>;
  }

  return (
    <div style={getSelectBaseNotePreviewStyle(isScaledHorizontalLayout)}>
      {!selectedBaseNote.currentEncounter && (
        <div data-cy-preview-previous-note-header style={previousEncountersHeaderStyle}>
          <span style={previousEncountersTitleStyle}>Previous encounter</span>
        </div>
      )}
      <div data-cy-preview-note-header style={headerStyle}>
        {formatDatetime(selectedBaseNote.effective)} - {selectedBaseNote.rawNoteType} -{' '}
        {selectedBaseNote.author}
      </div>
      <div data-cy-cyob-preview-note-contents style={contentStyle}>
        <div
          css={htmlPreviewStyle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: selectedBaseNote.noteHtml }}
        />
      </div>
    </div>
  );
};
