import { FC } from 'react';
import { memoize } from 'lodash';

const getSummaryPStyle = memoize(
  (first?: boolean) =>
    ({
      margin: 0,
      marginTop: first ? 0 : 12,
    } as const)
);
export const SummaryP: FC<{ first?: boolean }> = ({ first = false, children }) => (
  <p style={getSummaryPStyle(first)}>{children}</p>
);

const getSummaryListStyle = memoize(
  (innerList?: boolean) =>
    ({
      margin: 0,
      marginTop: innerList ? 0 : 4,
      paddingLeft: 16,
    } as const)
);
export const SummaryUl: FC<{ innerList?: boolean }> = ({ innerList = false, children }) => (
  <ul style={getSummaryListStyle(innerList)}>{children}</ul>
);

export const SummaryOl: FC<{ innerList?: boolean }> = ({ innerList = false, children }) => (
  <ol style={getSummaryListStyle(innerList)}>{children}</ol>
);

const getSummaryLiStyle = memoize(
  (hideBullet?: boolean) =>
    ({
      listStyle: hideBullet ? 'none' : 'initial',
    } as const)
);
export const SummaryLi: FC<{ hideBullet?: boolean }> = ({ hideBullet = false, children }) => (
  <li style={getSummaryLiStyle(hideBullet)}>{children}</li>
);

const summarySpanUStyle = {
  textDecoration: 'underline',
} as const;
export const SummarySpanU: FC = ({ children }) => <span style={summarySpanUStyle}>{children}</span>;

const summarySpanBStyle = {
  fontWeight: 700,
} as const;
export const SummarySpanB: FC = ({ children }) => <span style={summarySpanBStyle}>{children}</span>;

const summarySpanIStyle = {
  fontStyle: 'italic',
} as const;

export const SummarySpanI: FC = ({ children }) => <span style={summarySpanIStyle}>{children}</span>;

const summarySpanUBStyle = {
  fontWeight: 700,
  textDecoration: 'underline',
} as const;
export const SummarySpanUB: FC = ({ children }) => (
  <span style={summarySpanUBStyle}>{children}</span>
);
