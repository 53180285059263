import { FC, useCallback, useEffect, useRef } from 'react';
import fscreen from 'fscreen';

import { epicTheme } from './demo/epicDemo/epicTheme';
import { Button } from '../reuse';
import { useBooleanState } from '../utils';

const isFullscreenPossible = fscreen.fullscreenEnabled;

export const Fullscreen: FC<{
  toggleEhr(): void;
}> = ({ children, toggleEhr }) => {
  const fullscreenRef = useRef(null);

  const [isFullscreen, { setFalse: setIsFullscreenFalse, setTrue: setIsFullscreenTrue }] =
    useBooleanState(false);

  const syncFullscreenState = useCallback(() => {
    if (fscreen.fullscreenElement === fullscreenRef.current) {
      setIsFullscreenTrue();
    } else {
      setIsFullscreenFalse();
    }
  }, [setIsFullscreenFalse, setIsFullscreenTrue]);

  useEffect(() => {
    if (isFullscreenPossible) {
      fscreen.addEventListener('fullscreenchange', syncFullscreenState);
      syncFullscreenState();
    }

    return () => {
      fscreen.removeEventListener('fullscreenchange', syncFullscreenState);
      fscreen.exitFullscreen();
    };
  }, [syncFullscreenState]);

  return (
    <div
      ref={fullscreenRef}
      style={{
        backgroundColor: epicTheme.colors.white,
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {!isFullscreen ? (
        <div
          style={{
            position: 'absolute',
            top: 0,
            transform: 'translateY(-100%)',
            right: -1,
            padding: 4,
            border: `1px solid ${epicTheme.colors.darkBlue}`,
            borderTopRightRadius: epicTheme.other.borderRadius4,
            borderTopLeftRadius: epicTheme.other.borderRadius4,
          }}
        >
          <Button
            color="tertiary"
            onClick={toggleEhr}
            size="small"
            startIconName="refresh"
            style={{
              marginRight: 4,
            }}
          >
            Switch
          </Button>
          {isFullscreenPossible && (
            <Button
              color="tertiary"
              onClick={() => {
                const el = fullscreenRef.current;
                if (!isFullscreen && el) {
                  fscreen.requestFullscreen(el);
                }
              }}
              size="small"
            >
              Fullscreen
            </Button>
          )}
        </div>
      ) : null}
      {children}
    </div>
  );
};
