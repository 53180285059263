import { theme } from '../theme';

const curtainBaseStyle = {
  bottom: 0,
  cursor: 'pointer',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
} as const;

export const curtainClosedStyle = {
  ...curtainBaseStyle,
  transform: 'translateX(100%)',
} as const;

export const curtainOpenStyle = {
  ...curtainBaseStyle,
  transform: 'translateX(0)',
} as const;

export const drawerAnimationDuration = 200;
const drawerBaseStyle = {
  backgroundColor: theme.colors.white,
  bottom: 0,
  boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.06), 0px 4px 26px rgba(0, 0, 0, 0.08)',
  position: 'fixed',
  right: 0,
  top: 0,
  transition: `${drawerAnimationDuration}ms ease`,
} as const;

export const drawerClosedStyle = {
  ...drawerBaseStyle,
  transform: 'translateX(100%)',
} as const;

export const drawerOpenStyle = {
  ...drawerBaseStyle,
  transform: 'translateX(0)',
} as const;
