import { Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    breakpoints: {
      desktop: 1112;
      tablet: 752;
    };
    colors: {
      blueRegular: '#2C3FCE';
      blueMedium: '#1F2FA8';
      blueDark: '#172381';
      blurple: '#3D1CB2';
      gold: '#FABF85';
      green: '#1D8465';
      grey1: '#201C2B';
      grey2: '#515966';
      grey3: '#9098A7';
      grey4: '#C5CBD8';
      grey5: '#E7E9EF';
      grey6: '#F3F3F7';
      lightBlue1: '#F0F4FF';
      lightBlue2: '#D9E3FF';
      lightBlue3: '#B9CDFF';
      lightSky: '#EDFAFF';
      none: 'transparent';
      red: '#BB1650';
      teal: '#1BAC92';
      white: '#FFFFFF';
    };
    font: {
      sans: 'Source Sans Pro';
    };
    other: {
      borderRadius3: '3.1px';
      borderRadius4: '4.1px';
      dropShadow1: '0px 2px 6px rgba(0, 0, 0, 0.1)';
      dropShadow2: '0px 1px 14px rgba(0, 0, 0, 0.06), 0px 4px 26px rgba(0, 0, 0, 0.08)';
    };
  }
}

export type ThemeColor = ValuesType<Theme['colors']>;

export const theme: Theme = {
  breakpoints: {
    desktop: 1112,
    tablet: 752,
  },
  colors: {
    blueRegular: '#2C3FCE',
    blueMedium: '#1F2FA8',
    blueDark: '#172381',
    blurple: '#3D1CB2',
    gold: '#FABF85',
    green: '#1D8465',
    grey1: '#201C2B',
    grey2: '#515966',
    grey3: '#9098A7',
    grey4: '#C5CBD8',
    grey5: '#E7E9EF',
    grey6: '#F3F3F7',
    lightBlue1: '#F0F4FF',
    lightBlue2: '#D9E3FF',
    lightBlue3: '#B9CDFF',
    lightSky: '#EDFAFF',
    none: 'transparent',
    red: '#BB1650',
    teal: '#1BAC92',
    white: '#FFFFFF',
  },
  font: {
    sans: 'Source Sans Pro',
  },
  other: {
    borderRadius3: '3.1px',
    borderRadius4: '4.1px',
    dropShadow1: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    dropShadow2: '0px 1px 14px rgba(0, 0, 0, 0.06), 0px 4px 26px rgba(0, 0, 0, 0.08)',
  },
};

export type CSS = JSX.IntrinsicAttributes['css'];
