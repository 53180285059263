import { RefObject, useCallback, useRef } from 'react';

import { useBooleanState } from '../../utils';

export const useLabeledInput = <T extends HTMLInputElement | HTMLTextAreaElement>({
  value,
}: {
  value: string | number | readonly string[];
}): {
  focus(): void;
  isFilled: boolean;
  isFocused: boolean;
  ref: RefObject<T>;
  forceBlur(): void;
  forceFocus(): void;
} => {
  const [isFocused, { setTrue: forceFocus, setFalse: forceBlur }] = useBooleanState(false);

  const ref = useRef<T>(null);
  const focus = useCallback(() => ref.current?.focus(), [ref]);

  const isFilled = !!(isFocused || value);

  return {
    focus,
    isFilled,
    isFocused,
    ref,
    forceBlur,
    forceFocus,
  };
};
