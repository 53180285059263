import { FC } from 'react';
import { get } from 'lodash';
import { ellipsisSpanStyle, linkButtonStyle, underlineStyle } from './css';

import { LinkButton } from '..';
import {
  AccordionContentItem,
  AccordionContentColumn,
  AccordionContentItemDataObject,
} from './types';

interface getCellContentParams {
  data: AccordionContentItem;
  cellData: AccordionContentItemDataObject | null;
  hasAction: boolean;
}

const getCellContent = (params: getCellContentParams) => {
  const { cellData, hasAction, data } = params;

  if (!cellData) {
    return null;
  }

  const dataContent = (
    <span style={ellipsisSpanStyle}>
      {cellData.label}
      {hasAction && <div style={underlineStyle} />}
    </span>
  );

  if (hasAction) {
    return (
      <LinkButton
        onClick={() => {
          if (cellData?.action) {
            cellData.action(data);
          }
        }}
        style={linkButtonStyle}
      >
        {dataContent}
      </LinkButton>
    );
  }

  return dataContent;
};

interface AccordionTableContentCellProps {
  data: AccordionContentItem;
  column: AccordionContentColumn;
}

export const AccordionTableContentCell: FC<AccordionTableContentCellProps> = ({ column, data }) => {
  const ambiguousCellData = get(data, column.accessor); // || null;
  const cellData =
    typeof ambiguousCellData === 'string' ? { label: ambiguousCellData } : ambiguousCellData;

  const hasAction = !!cellData?.action;
  const dataContent = getCellContent({ data, cellData, hasAction });

  return <div style={column.cellStyle}>{dataContent}</div>;
};
