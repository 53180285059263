// CKEditor likes to insert zero-width spaces, i.e. unicode 200b, characters.
//
// When pasting this character into Cerner, it is converted to "?", which is
//  annoying and confusing to users. This character is simply bad outside the
//  context of the editor.

export const zeroWidthCharacterMatcher = /[\u200B-\u200D\uFEFF]/g;

export const stripInvalidCharacters = <T extends string>(text: T): T =>
  text.replace(zeroWidthCharacterMatcher, '') as T;
