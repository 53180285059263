import { FC } from 'react';
import { DynamicTooltip, theme } from '~/app/reuse';

const tooltipCss = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
} as const;

const squareCss = {
  bottom: -2,
  cursor: 'default',
  display: 'inline-block',
  height: 18,
  marginRight: -2,
  position: 'relative',
  width: 18,
  '&:hover [data-citation-circle]': {
    backgroundColor: theme.colors.grey2,
    color: theme.colors.white,
  },
} as const;

const circleCss = {
  backgroundColor: theme.colors.grey4,
  borderRadius: '50%',
  fontWeight: 600,
  height: 14,
  left: '50%',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  width: 14,
} as const;

const numberStyle = {
  fontSize: 10,
  position: 'relative',
  right: 0,
  top: -5.5,
} as const;

export const MaxChatAiResponseCitation: FC<{ citationNumber: number; quote: string }> = ({
  citationNumber,
  quote,
}) => (
  <DynamicTooltip css={tooltipCss} tooltip={quote}>
    <span css={squareCss}>
      <span css={circleCss} data-citation-circle>
        <span style={numberStyle}>{citationNumber}</span>
      </span>
    </span>
  </DynamicTooltip>
);
