import styled from '@emotion/styled';
import Color from 'color';
import _ from 'lodash';

import { ButtonColor, ButtonSize } from './types';
import { ButtonBase } from '../buttonBase';
import { theme } from '../theme';

const {
  blueRegular,
  blueMedium,
  blueDark,
  grey4,
  lightBlue1,
  lightBlue2,
  lightBlue3,
  none,
  white,
} = theme.colors;

const coreButtonCss = {
  borderColor: none,
  borderRadius: theme.other.borderRadius4,
  borderStyle: 'solid',
  borderWidth: '1px',
  cursor: 'pointer',
  fontWeight: 600,
  textDecoration: 'none',
  userSelect: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  '& [data-button-start-icon]': {
    display: 'inline-block',
    height: 16,
    verticalAlign: 'middle',
    marginRight: 2,
  },
} as const;

type ButtonColorCSSBase = {
  backgroundColor: string;
  borderColor?: string;
  color: string;
};

export type ButtonColorCSS = ButtonColorCSSBase &
  Record<'&:focus' | '&:hover' | '&:active' | '&:disabled', ButtonColorCSSBase>;

const colorCss: Record<ButtonColor, ButtonColorCSS> = {
  primary: {
    backgroundColor: blueRegular,
    color: white,
    '&:focus': {
      backgroundColor: blueMedium,
      color: white,
    },
    '&:hover': {
      backgroundColor: blueMedium,
      color: white,
    },
    '&:active': {
      backgroundColor: blueDark,
      color: white,
    },
    '&:disabled': {
      backgroundColor: grey4,
      color: white,
    },
  },
  secondary: {
    backgroundColor: none,
    borderColor: blueRegular,
    color: blueRegular,
    '&:focus': {
      backgroundColor: lightBlue2,
      borderColor: blueMedium,
      color: blueMedium,
    },
    '&:hover': {
      backgroundColor: lightBlue2,
      borderColor: blueMedium,
      color: blueMedium,
    },
    '&:active': {
      backgroundColor: lightBlue3,
      borderColor: blueDark,
      color: blueDark,
    },
    '&:disabled': {
      backgroundColor: none,
      borderColor: grey4,
      color: grey4,
    },
  },
  'secondary-reverse': {
    backgroundColor: none,
    borderColor: white,
    color: white,
    '&:focus': {
      backgroundColor: lightBlue1,
      borderColor: Color(white).alpha(0.1).string(),
      color: white,
    },
    '&:hover': {
      backgroundColor: lightBlue1,
      borderColor: Color(white).alpha(0.1).string(),
      color: white,
    },
    '&:active': {
      backgroundColor: lightBlue2,
      borderColor: Color(white).alpha(0.2).string(),
      color: white,
    },
    '&:disabled': {
      backgroundColor: none,
      borderColor: Color(white).alpha(0.4).string(),
      color: Color(white).alpha(0.4).string(),
    },
  },
  tertiary: {
    backgroundColor: none,
    color: blueRegular,
    '&:focus': {
      backgroundColor: lightBlue2,
      color: blueMedium,
    },
    '&:hover': {
      backgroundColor: lightBlue2,
      color: blueMedium,
    },
    '&:active': {
      backgroundColor: lightBlue3,
      color: blueDark,
    },
    '&:disabled': {
      backgroundColor: none,
      color: grey4,
    },
  },
};

export type ButtonSizeCSS = {
  fontSize: number;
  lineHeight: number | string;
  minWidth: number | string;
  padding: number | string;
};

const sizeCss: Record<ButtonSize, ButtonSizeCSS> = {
  standard: {
    fontSize: 14,
    lineHeight: '26px',
    minWidth: 60,
    padding: '0 8px',
  },
  small: {
    fontSize: 14,
    lineHeight: '22px',
    minWidth: 24,
    padding: '0 4px',
  },
  mini: {
    fontSize: 12,
    lineHeight: '18px',
    minWidth: 24,
    padding: '0 4px',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createStyledButtonComponent = (Component: any) =>
  styled(Component, {
    shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
  })(
    coreButtonCss,
    ({
      color,
      size,
    }: {
      color: ButtonColor | ButtonColorCSS;
      size: ButtonSize | ButtonSizeCSS;
    }) => [_.isString(color) ? colorCss[color] : color, _.isString(size) ? sizeCss[size] : size]
  );

export const Root = createStyledButtonComponent(ButtonBase);
