import { flatten } from 'lodash';

import { ChunkOfLines, MeshedNoteTitleHtmlLine, TitleStyles } from '~/app/@types/state';
import { getFirstCharacterStyles, isFooterChunk } from '~/app/utils';

const PERCENTAGE_CUTOFF = 0.5;

export const getDominantTitleStyles = (chunks: ChunkOfLines[]): TitleStyles => {
  const titleLines = flatten(
    chunks.map((chunk) =>
      (chunk.type === 'condition' || chunk.type === 'shelvedCondition') && !isFooterChunk(chunk)
        ? (chunk.lines.filter(({ type }) => type === 'title') as MeshedNoteTitleHtmlLine[])
        : []
    )
  );

  const lineStyles = titleLines.map((line) => getFirstCharacterStyles(line.html));
  if (!lineStyles.length) {
    return {
      bold: false,
      italic: false,
      underline: false,
    };
  }

  const cutoffValue = Math.ceil(lineStyles.length * PERCENTAGE_CUTOFF);
  const usageCount = {
    bold: 0,
    italic: 0,
    underline: 0,
  };

  lineStyles.forEach((style) => {
    if (style.bold) usageCount.bold += 1;
    if (style.italic) usageCount.italic += 1;
    if (style.underline) usageCount.underline += 1;
  });

  return {
    bold: usageCount.bold >= cutoffValue,
    italic: usageCount.italic >= cutoffValue,
    underline: usageCount.underline >= cutoffValue,
  };
};
