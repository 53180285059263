import {
  FETCH_PT,
  FETCH_PT_SUCCESS,
  FETCH_PT_FAILURE,
  SELECT_SCREEN,
  CLOSE_DETAILS,
  SELECT_ITEM,
  RecordAction,
} from '../actions/record';
import { RecordState } from '../@types/state';
import { SCREEN_NAV_ITEMS } from '../components/pmemr/screens';

const initState: RecordState = {
  isFetching: false,
  data: null,
  error: null,
  selectedScreen: SCREEN_NAV_ITEMS[0],
  selectedItem: null,
};

// eslint-disable-next-line default-param-last
function recordReducer(state = initState, action: RecordAction): RecordState {
  switch (action.type) {
    case FETCH_PT:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PT_FAILURE:
      return { ...initState, error: action.error };
    case FETCH_PT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };
    case SELECT_SCREEN:
      return {
        ...state,
        selectedScreen: action.screen,
        selectedItem: null,
      };
    case CLOSE_DETAILS:
      return {
        ...state,
        selectedItem: null,
      };
    case SELECT_ITEM:
      return {
        ...state,
        selectedItem: action.item,
      };
    default:
      return state;
  }
}

export default recordReducer;
