import { isEqual } from 'date-fns';
import { formatDatetime } from './format';
import { DataMapValue } from './convertTableDataToMaps';

/**
 * Generate datetime column header tds and replace empty data cells
 * with data if a value exists
 */
export const generateDatetimeTds = (
  sortedDatetimes: Date[],
  highlightColumnDate: Date | null,
  dataMap: Record<string, DataMapValue>,
  dataRows: Record<string, React.ReactNode[]>,
  inCurrentEnc: (d: Date) => boolean
) => {
  const getHighlightClass = (d: Date) =>
    isEqual(d, highlightColumnDate ?? new Date()) ? 'highlight' : '';

  const datetimeTds = [
    <td key="empty" className="sticky" />,
    <td key="hide me" className="hide-me" />,
  ];

  sortedDatetimes.forEach((d, i) => {
    const formattedDate = formatDatetime(d);
    const [date, time] = formattedDate.split(' ');
    datetimeTds.push(
      <td
        key={formattedDate}
        className={`${getHighlightClass(d)} ${inCurrentEnc(d) ? 'current-enc' : 'previous-enc'}`}
      >
        {date}
        <br />
        {time}
      </td>
    );

    const dataMapValue = dataMap[formattedDate] ?? {};
    const dataMapKeys = Object.keys(dataMapValue);

    return dataMapKeys.forEach((dataKey) => {
      if (dataKey === 'dateObject') return;
      const value = dataMap[formattedDate][dataKey];
      const dataRow = dataRows[dataKey];
      if (!dataRow) return;

      const dataRowIndex = i + 3;
      dataRow[dataRowIndex] = (
        <td
          key={`${dataKey} ${i}`}
          className={`to-highlight ${inCurrentEnc(d) ? 'current-enc' : 'previous-enc'}`}
        >
          {value}
        </td>
      );
    });
  });

  return datetimeTds;
};
