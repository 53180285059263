import { forwardRef } from 'react';

import { AnchorTagProps } from '../anchorTag';
import { Root } from './css';

export type AnchorProps = AnchorTagProps;

export const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(({ ...rest }, ref) => (
  <Root ref={ref} {...rest} />
));
Anchor.displayName = 'Anchor';
