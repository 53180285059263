import { FC } from 'react';

import { epicRegardTab } from './epicRegardTab';
import { EpicTab } from './EpicTab';
import { EpicTabs } from './EpicTabs';
import { EpicTable } from './EpicTable';
import { epicTheme } from './epicTheme';

export const EpicRightPane: FC = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      paddingTop: 16,
    }}
  >
    <EpicTabs>
      <EpicTab accentColor={epicTheme.colors.accentPurple} isSelected label="Sidebar Summary">
        <div style={{ display: 'grid', gap: 24 }}>
          <EpicTable
            headerColor={epicTheme.colors.headerLightGreen}
            label="Chief Complaint"
            rows={[
              [
                <>
                  <br />
                  Chest pain
                  <br />
                  <br />
                </>,
              ],
            ]}
          />
          <EpicTable
            headerColor={epicTheme.colors.headerLightGreen}
            label="Vital from encounters over past 365 days"
            rows={[
              [null, <b>12:00</b>, <b>1:00</b>, <b>2:00</b>],
              ['Temp', '98.6', null, null],
              ['BP', '132/72', '130/67', '130/67'],
              ['Respiratory Rate', '35', null, null],
              ['Oxygen saturation', '97', null, null],
              ['Weight', '69.4', null, null],
              ['Height', '152.4', null, null],
            ]}
          />
          <EpicTable
            headerColor={epicTheme.colors.headerOrange}
            label="Allergies"
            rows={[['Allergy'], ['Allergy'], ['Allergy']]}
          />
          <EpicTable
            headerColor={epicTheme.colors.headerLightBlue}
            label="Medical History"
            rows={[
              ['00/00/00', 'Arthritis'],
              ['00/00/00', 'Back pain'],
              ['00/00/00', 'Gall bladder surgery'],
              ['00/00/00', 'Pneumonia'],
              ['00/00/00', 'Back Pain'],
              ['00/00/00', 'DVT'],
              ['Height', '152.4'],
            ]}
          />
        </div>
      </EpicTab>
      {epicRegardTab}
    </EpicTabs>
  </div>
);
