import { MutableRefObject, useCallback, useEffect } from 'react';
import { CKEditorInstance } from 'ckeditor4-react';

import { rteSyncInterval } from '~/app/flags';
import { SyncRegister } from '~/app/utils';
import { CKEditorOnChangeProps } from '../ckeditorTypes';
import { assertSyncTimeWithinThreshold } from './asserts';

interface UseIntervalSyncParams {
  id: string;
  ready: boolean;
  editorInstance: MutableRefObject<CKEditorInstance>;
  onChange(props: CKEditorOnChangeProps): void;
}

const syncRegister = new SyncRegister('condition-rte', rteSyncInterval());

/**
 * Syncs a CKEditor instance every RTE_SYNC_INTERVAL to the redux store by forcing
 * the onChange handler to fire.
 * Some text changes caused by the Dragon speech to text software make DOM edits
 * without triggering CKEditor events. This sync helps prevent those unsynced changes
 * from getting cleared when another action happens that may affect the note in the redux store.
 */
export const useIntervalSync = (params: UseIntervalSyncParams) => {
  const { id, ready, editorInstance, onChange } = params;

  useEffect(() => {
    if (!ready || !editorInstance.current) {
      return undefined;
    }

    const onSync = () => {
      // If the app is reindexing, delay the current sync to prevent IE from
      // overloading.
      if (window.reindexing) {
        return;
      }

      const syncStart = new Date().valueOf();
      const event = { editor: editorInstance.current } as CKEditorOnChangeProps;
      onChange(event);

      assertSyncTimeWithinThreshold(syncStart);
    };

    syncRegister.registerSync({ id, onSync });
    return () => syncRegister.unregisterSync(id);
  }, [id, ready, editorInstance, onChange]);

  const delaySync = useCallback(() => syncRegister.delaySync(), []);
  return delaySync;
};
