import { ModKeys } from '~/app/@types/state';

export const getModKeysForEvent = (event: React.MouseEvent<HTMLButtonElement>): ModKeys =>
  ({
    shift: event.shiftKey,
    // Ctrl key on Mac is often used to open the right click menu,
    // so the metaKey ('command' on mac, windows key on windows)
    // is also allowed for the same behavior for testing on a Mac.
    ctrl: event.ctrlKey || event.metaKey,
    // Alt key on Windows, Option on Mac
    // Alt key on Windows is often used to open the right click menu,
    // so may not be a reliable mod key
    alt: event.altKey,
  } as const);
