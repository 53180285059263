import { FC, createContext } from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';

import { createSelectorProvider, useContextSelector } from '~/app/utils';
import { theme } from '~/app/reuse';

import { useDxDetails } from './useDxDetails';

const getIsHorizontalLayout = (width: number): boolean => width >= theme.breakpoints.tablet;
const getIsScaledHorizontalLayout = (width: number): boolean => width > theme.breakpoints.desktop;
const getIsVerticalLayout = (width: number): boolean => width < theme.breakpoints.tablet;

type MeshedContext = {
  dxDetailsVisible: boolean;
  isHorizontalLayout: boolean;
  isVerticalLayout: boolean;
  lastClickedKeywordModule: string;
  openModules: string[];
  selectedModule: string;
  width: number;
  isScaledHorizontalLayout: boolean;
};

const defaultContext: MeshedContext = {
  dxDetailsVisible: true,
  isHorizontalLayout: true,
  isVerticalLayout: false,
  lastClickedKeywordModule: '',
  openModules: [],
  selectedModule: '',
  width: 1024,
  isScaledHorizontalLayout: false,
};

const context = createContext<MeshedContext>(defaultContext);
const MeshedContextProvider = createSelectorProvider(context);

export const useMeshedContext = <T,>(selector: (context: MeshedContext) => T): T =>
  useContextSelector<MeshedContext, T>(context, selector);

export const UnsizedMeshedProvider: FC<SizeMeProps> = ({ children, size }) => {
  const width = size.width ?? 1024;
  const isHorizontalLayout = getIsHorizontalLayout(width);
  const isVerticalLayout = getIsVerticalLayout(width);
  const isScaledHorizontalLayout = getIsScaledHorizontalLayout(width);

  const { openModules, selectedModule, tabPanelOpen, lastClickedKeywordModule } = useDxDetails();

  const dxDetailsVisible = isHorizontalLayout || tabPanelOpen;

  return (
    <div
      // must render a div here or sizing will not work
      style={{
        height: '100%',
      }}
    >
      <MeshedContextProvider
        value={{
          dxDetailsVisible,
          isHorizontalLayout,
          isVerticalLayout,
          openModules,
          selectedModule,
          lastClickedKeywordModule,
          width,
          isScaledHorizontalLayout,
        }}
      >
        {children}
      </MeshedContextProvider>
    </div>
  );
};

export const MeshedProvider = withSize()(UnsizedMeshedProvider);
