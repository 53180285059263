import { UndoAction } from '../actions/undo';
import { exhaust } from '../utils';

type UndoState = {
  canRedo: boolean;
  canUndo: boolean;
};

export const undoInitialState: UndoState = {
  canRedo: false,
  canUndo: false,
};

// eslint-disable-next-line default-param-last
export const undo = (state = undoInitialState, action: UndoAction): UndoState => {
  switch (action.type) {
    case 'other':
      return state;
    case 'set can redo and can undo':
      return {
        canRedo: action.payload.canRedo,
        canUndo: action.payload.canUndo,
      };
    default:
      exhaust(action);
      return state;
  }
};
