import { useMemo } from 'react';

import { BulletNoteStatus } from '../../../@types';
import { updateBulletNoteStatusFromBulletMonitorThunk } from '../../../actions/regardNote';
import { useDispatch } from '../../../store';
import { track } from '../../../analytics';

export const useAddAndRemoveSuggestion = ({
  conditionId,
  id,
  isShelvedCondition,
  text,
}: {
  conditionId: string;
  id: string;
  isShelvedCondition: boolean;
  text: string;
}): {
  addSuggestion(): void;
  removeSuggestion(): void;
} => {
  const dispatch = useDispatch();

  const result = useMemo(() => {
    const createAddOrRemoveSuggestion = (addOrRemove: 'add' | 'remove') => {
      const [module, bulletId] = id.split('.');
      const trackingFunc = addOrRemove === 'add' ? track.bulletAdded : track.bulletHidden;

      return () => {
        trackingFunc({
          inShelf: isShelvedCondition,
          module,
          bulletId,
          uiElement: 'bullet monitor',
        });

        return dispatch(
          updateBulletNoteStatusFromBulletMonitorThunk({
            conditionId,
            newBulletNoteStatus:
              addOrRemove === 'add' ? BulletNoteStatus.Noted : BulletNoteStatus.Hidden,
            text,
          })
        );
      };
    };

    return {
      addSuggestion: createAddOrRemoveSuggestion('add'),
      removeSuggestion: createAddOrRemoveSuggestion('remove'),
    };
  }, [dispatch, conditionId, id, isShelvedCondition, text]);

  return result;
};
