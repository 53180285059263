import { FC, Ref, forwardRef } from 'react';

export type AnchorTagProps = AugmentedRequired<
  Pick<
    JSX.IntrinsicElements['a'],
    | 'children'
    | 'className'
    | 'css'
    | 'href'
    | 'onBlur'
    | 'onClick'
    | 'onFocus'
    | 'onMouseDown'
    | 'onMouseEnter'
    | 'onMouseLeave'
    | 'onMouseUp'
    | 'onTouchEnd'
    | 'onTouchMove'
    | 'onTouchStart'
    | 'style'
  >,
  'href'
> & {
  internal?: boolean;
  ref?: Ref<HTMLAnchorElement>;
};

const externalProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const AnchorTag: FC<AnchorTagProps> = forwardRef(({ children, internal, ...rest }, ref) => (
  <a ref={ref} {...(internal ? undefined : externalProps)} {...rest}>
    {children}
  </a>
));
AnchorTag.displayName = 'AnchorTag';
