import { capitalizeFirstLetter } from '../capitalizeFirstLetter';

const customConditionDisplayNames: Map<string, string> = new Map([
  ['acs', 'ACS'],
  ['afib', 'AFib'],
  ['alcohol', 'Alcohol Use Disorder'],
  ['ams', 'Encephalopathy'],
  ['bph', 'BPH'],
  ['cad', 'CAD'],
  ['covid', 'Covid-19'],
  ['gerd', 'GERD'],
  ['gi_bleed', 'GI Bleed'],
]);

export function getConditionDisplayName(module: string) {
  const customDisplayName = customConditionDisplayNames.get(module);
  if (customDisplayName) {
    return customDisplayName;
  }
  return `${module.split('_').map(capitalizeFirstLetter).join(' ')}`;
}
