import { FC } from 'react';

import { cernerTheme } from './cernerTheme';
import { Icon } from '../../../reuse';
import { useDemoData } from '../useDemoPatient';

export const CernerHeader: FC = () => {
  const data = useDemoData();

  const ageAndSexArray = data.patientInfo.ageAndSex.split(' ');
  const age = ageAndSexArray[0];
  const sex = ageAndSexArray[1];

  return (
    <>
      <div
        style={{
          alignItems: 'end',
          display: 'flex',
          flex: '0 0 auto',
          height: 32,
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: cernerTheme.colors.blue,
            borderTopLeftRadius: cernerTheme.other.borderRadius4,
            borderTopRightRadius: cernerTheme.other.borderRadius4,
            color: cernerTheme.colors.white,
            display: 'flex',
            height: 28,
            justifyContent: 'space-between',
            marginLeft: 4,
            padding: '0 10px 0 8px',
            width: 260,
          }}
        >
          <span
            style={{
              textTransform: 'uppercase',
            }}
          >
            <b>{data.patientInfo.name}</b>
          </span>
          <Icon iconName="x" />
        </div>
      </div>
      <div
        style={{
          backgroundColor: cernerTheme.colors.blue,
          color: cernerTheme.colors.white,
          flex: '0 0 auto',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            display: 'grid',
            gap: '0 12px',
            gridAutoFlow: 'column',
            gridTemplateColumns: '1.5fr 1fr 1fr 1fr 1fr 1fr',
            gridTemplateRows: '20% 20% 20% 20% 20%',
            height: 136,
            minWidth: 1280,
            padding: '8px 12px',
          }}
        >
          <div
            style={{
              textTransform: 'uppercase',
            }}
          >
            <b>{data.patientInfo.name}</b>
          </div>
          <div>Allergies: No known medication allergies</div>
          <div
            style={{
              textTransform: 'capitalize',
            }}
          >
            Sex: {sex}
          </div>
          <div>
            <b>ABO/RH:</b>
          </div>
          <div>
            <b>MFM Care:</b>
          </div>
          <div>
            <b>DOB: {data.patientInfo.dob}</b>
          </div>
          <div>Age: {age} years</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div
            style={{
              gridColumn: 'span 2',
            }}
          >
            Encounter: Inpatient
          </div>
          <div>Disease Alert:</div>
          <div>&nbsp;</div>
          <div>
            COVID 19: {'<'}NOT ORDERED{'>'}
          </div>
          <div>&nbsp;</div>
          <div>MRN: 112233</div>
          <div>Fin#: 9988776655</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>Admit: </div>
          <div>Attending: Jones MD, Kit</div>
          <div>LocPCU; 2030; 1</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>PCP: Brown DO, Lindsay M</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>Discharged: </div>
        </div>
      </div>
    </>
  );
};
