import { FC } from 'react';

import { theme } from '../../../reuse';
import { createSelector, useShallowEqualSelector } from '../../../store';
import { ApplicationVersion } from './ApplicationVersion';

const getApplicationVersion = createSelector(
  (state) => state.ui,
  ({ reduxReplayVersion, showApplicationVersion }) => ({
    reduxReplayVersion,
    showApplicationVersion,
  })
);

const dividerStyle = {
  backgroundColor: theme.colors.grey4,
  display: 'flex',
  fontSize: 10,
  msGridColumn: 2,
  gridColumn: 2,
  width: 32,
} as const;

export const MeshedNoteAppDivider: FC = () => {
  const { reduxReplayVersion, showApplicationVersion } =
    useShallowEqualSelector(getApplicationVersion);

  return (
    <div style={dividerStyle}>
      {showApplicationVersion && <ApplicationVersion reduxReplayVersion={reduxReplayVersion} />}
    </div>
  );
};
