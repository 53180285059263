import _ from 'lodash';
import { FC, memo, useRef } from 'react';
import { ErrorBoundary } from '~/app/reuse/errorBoundary';

import { ConditionAddButton } from '../conditionAddButton';
import { ConditionUnderlay } from '../conditionUnderlay';
import { useConditionContext } from '../conditionProvider';
import { ConditionSuggestions } from '../conditionSuggestions';
import { ConditionRte } from '../conditionRte';
import { NewConditionsLine } from '../newConditionsLine';

import { useConditionSetCaretPosition } from './useConditionSetCaretPosition';
import { useOnClickConditionTitleKeyword } from './useOnClickConditionTitleKeyword';
import { ConditionInnerWrapper, textStyle } from './css';
import { useNoteBlockContext } from '../noteBlockProvider';

export const Condition: FC = memo(() => {
  const { id, isMoveMenuOpen, noteBlockRef, type } = useNoteBlockContext(
    ({ id, isMoveMenuOpen, noteBlockRef, type }) => ({
      id,
      isMoveMenuOpen,
      noteBlockRef,
      type,
    })
  );
  const { isLastNoteBlock, isNewCondition, isCriticalCondition, modules } = useConditionContext(
    ({ isLastNoteBlock, isNewCondition, isCriticalCondition, modules }) => ({
      isLastNoteBlock,
      isNewCondition,
      isCriticalCondition,
      modules,
    })
  );

  const setRteCaretPositionRef = useRef<(position: number) => void>(_.noop);

  useConditionSetCaretPosition(id, setRteCaretPositionRef);

  const onClickConditionTitleKeyword = useOnClickConditionTitleKeyword();

  return (
    <div
      ref={noteBlockRef}
      data-condition-is-new={isNewCondition}
      data-cy-condition
      data-cy-condition-modules={modules}
      data-cy-in-shelf={type === 'shelvedCondition'}
      data-note-block-id={id}
      onClick={onClickConditionTitleKeyword}
    >
      <ConditionAddButton />
      <NewConditionsLine />
      <ConditionInnerWrapper
        data-condition-is-critical={isCriticalCondition}
        data-condition-move-menu-open={isMoveMenuOpen}
        data-cy-condition-inner
      >
        <ErrorBoundary inline>
          <div style={textStyle}>
            <ConditionUnderlay />
            <ConditionRte setRteCaretPositionRef={setRteCaretPositionRef} />
          </div>
          <ConditionSuggestions />
        </ErrorBoundary>
      </ConditionInnerWrapper>
      {isLastNoteBlock ? <ConditionAddButton bottom /> : null}
    </div>
  );
});
Condition.displayName = 'Condition';
