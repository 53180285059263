import { Bullet } from '../../@types/state';
import { BulletType } from '../../@types';

export type SafetyBulletTracker = {
  type: string;
  text: string;
  conditionName: string;
  id: string;
};

/**
 * 'General' bullets will be filtered out of bullet tracking if not already in the note,
 * so we don't mind if they are duplicates of an existing bullet
 */
const getCurrentTrackedBulletCount = (bullets: Bullet[]) =>
  bullets.filter((bullet) => bullet.type !== BulletType.General).length;

/* This function performs two checks on bullets sent from the BE:
 * 1. The first one checks if any bullets from a single condition have the
 *    same ID. This is bad because we use these IDs in BulletNoteStatus and react-rendering keys
 * 2. The second check looks for duplicate spaces in non-General bullet text */
export const safetyCheckBullets = (bullets: Bullet[], conditionName: string) => {
  const seenConditionIds: { [key: string]: { addedToDuplicates: boolean; bullets: Bullet[] } } = {};
  const blankIdBullets: SafetyBulletTracker[] = [];
  const duplicateBullets: SafetyBulletTracker[] = [];
  const badWhitespaceBullets: SafetyBulletTracker[] = [];

  for (let i = bullets.length - 1; i >= 0; i--) {
    const bullet = bullets[i];
    const { id, text, type } = bullet;
    if (!seenConditionIds[id]) {
      seenConditionIds[id] = { addedToDuplicates: false, bullets: [] };
    }

    seenConditionIds[id].bullets.push(bullet);

    if (id) {
      const seenCount = getCurrentTrackedBulletCount(seenConditionIds[id].bullets);
      if (seenCount > 1 && !seenConditionIds[id].addedToDuplicates) {
        seenConditionIds[id].addedToDuplicates = true;
        duplicateBullets.push({ text, type, id, conditionName });
      }
    } else {
      blankIdBullets.push({ text, type, id, conditionName });
    }

    if (type !== BulletType.General && text.match(/[ ]{2,}/g)) {
      badWhitespaceBullets.push({ text, type, id, conditionName });
    }
  }

  return {
    badWhitespaceBullets,
    blankIdBullets,
    duplicateBullets,
  };
};
