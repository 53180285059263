import { SummaryUl, SummarySpanB, SummaryP } from './common';

export const strokeSummary = (
  <>
    <SummaryP first>
      Regard will start by looking for evidence of stroke in the patient’s clinical data from the
      current and past encounters.
    </SummaryP>
    <SummaryUl>
      <li>
        Regard will diagnose <SummarySpanB>acute stroke</SummarySpanB> based on head imaging reports
        and notes and output relevant labs, vitals, imaging, and procedures.
      </li>
      <li>
        If there is no evidence of acute stroke but a history of stroke or stroke event is found in
        the patient’s history prior to the current encounter, Regard will diagnose{' '}
        <SummarySpanB>history of stroke</SummarySpanB>.
      </li>
    </SummaryUl>
  </>
);
