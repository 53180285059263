import _ from 'lodash';
import { MutableRefObject, useEffect, useMemo } from 'react';

import { createSelector, useShallowEqualSelector } from '../../../store';
import { NULL_DATE_VALUE, get } from '../../../utils';

export type SetRteCaretPosition = MutableRefObject<(position: number) => void>;

const BLANK = {
  caretPos: null,
  timestamp: NULL_DATE_VALUE,
};

// This value is a response to a bug where `setCaretPosition`s were being
//  reapplied. Let's say you add a condition and then move that condition; it
//  will still have a setCaretPosition, and that will be reapplied.
// The solution here is to make a record of each caret position as it's
//  appplied. Afterwards will never be applied again.
// In the future, it might be nice to have a solution that works with undo/redo
//  but for now, this solution works.
const caretTimestampCache: Record<string /* condition id */, DateNumberValue> = {};

// Allows the app to set the caret position inside a textarea.
export const useConditionSetCaretPosition = (
  id: string,
  setRteCaretPositionRef: SetRteCaretPosition
) => {
  const getConditionSetCaretPosition = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.conditionsById, id)?.setCaretPosition,
        (setCaretPosition) => setCaretPosition ?? BLANK
      ),
    [id]
  );

  const { caretPos, timestamp } = useShallowEqualSelector(getConditionSetCaretPosition);

  // NOTE: If this code is not wrapped in a useEffect, the following error
  //  occurs:
  //
  // Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React
  //  is already rendering.
  useEffect(() => {
    const setRteCaretPosition = setRteCaretPositionRef.current;

    const hasNewCaretPos =
      _.isNumber(caretPos) && timestamp > (caretTimestampCache[id] ?? NULL_DATE_VALUE);
    if (hasNewCaretPos) {
      caretTimestampCache[id] = timestamp;

      // NOTE: The original trigger for this case (see "Deprecated reasoning:")
      //  below is now circumvented with the addition of the
      //  SplitStackedDxFromEndOfFirstLine case. But, if we somehow happen to
      //  get a -1 for any reason, 0 will assuredly be a better alternative.
      //
      // Deprecated reasoning:
      // In the case of splitting a stacked dx by hitting return on the end of
      //  the first line, we'll get a -1; the following line avoids this case for
      //  now.
      const caretPosNoLessThanZero = Math.max(caretPos, 0);

      setRteCaretPosition(caretPosNoLessThanZero);
    }
  }, [caretPos, id, setRteCaretPositionRef, timestamp]);
};
