import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { AppState } from '~/app/@types/state';
import * as flags from '~/app/flags';
import { VERSION } from '~/app/version';

// TODO: RND-1407 - Turn back on appcues
// import { AppcuesPathWatcher } from '~/app/analytics/appcues';

import { Demo } from '../../demo';
import { DefaultContent } from '../../DefaultContent';
import CaseReviewToolbar from '../../CaseReviewToolbar';
import { DemoToolbar } from '../../DemoToolbar';
import { FetchError } from '../../FetchError';
import Logout from '../../Logout';
import { Meshed } from '../../meshed/Meshed';
import { QueryForm } from '../../queryForm/QueryForm';
import { DevTools } from '../../dev';

import '~/app/styles/App.scss';
import { buildRedirectUrl } from './buildRedirectUrl';

type UnconnectedAppRouterProps = PropsFromRedux;

export const UnconnectedAppRouter: FC<UnconnectedAppRouterProps> = ({ currentError, oauthSub }) => {
  if (
    ['/uselauncher', '/unauthorized', '/transient-auth-error'].every(
      (s) => !window.location.href.includes(s)
    )
  ) {
    if (!oauthSub && !currentError) {
      const currentUrl = window.location.pathname + window.location.search;
      window.location.href = `/api/login?next=${encodeURIComponent(currentUrl)}`;
      return <p>Redirecting to login...</p>;
    }
    if (currentError && currentError.redirect) {
      window.location.href = buildRedirectUrl(currentError.redirect);
      return <p>Redirecting to login...</p>;
    }
  }

  const demoElements = flags.isSalesDemoMode ? (
    [<DemoToolbar key="toolbar" />, <Demo key="demo" />]
  ) : (
    <Demo />
  );

  return (
    <Router>
      <div id="app">
        <span data-app-version style={{ display: 'none' }}>
          {VERSION}
        </span>
        {flags.isDevToolsEnabled && <DevTools />}
        {/* 
          // TODO: RND-1407 - Turn back on appcues
          <AppcuesPathWatcher />
        */}
        <Routes>
          {flags.isQueryEnabled() && <Route element={<QueryForm />} path="/query" />}
          <Route element={<Logout />} path="/logout" />
          <Route element={<FetchError status={403} />} path="/unauthorized" />
          <Route element={<FetchError status={410} />} path="/disabled" />
          <Route
            element={<FetchError message="transient-auth-error" status={503} />}
            path="/transient-auth-error"
          />
          <Route element={<FetchError status={999} />} path="/uselauncher" />
          {(flags.isSalesDemoMode || flags.isReview() || flags.isPMEMREnabled()) && (
            <Route element={demoElements} path="/record" />
          )}
          {flags.isSalesDemoMode ? (
            <Route element={demoElements} path="/doc" />
          ) : (
            <Route
              element={[
                flags.isReview() && <CaseReviewToolbar key="caseReviewToolbar" />,
                <Meshed key="meshed" />,
              ]}
              path="/doc"
            />
          )}
          <Route element={<DefaultContent />} path="/" />
        </Routes>
      </div>
    </Router>
  );
};

const connector = connect(({ login, regardNote }: AppState) => ({
  currentError: login.error || regardNote.fetchError,
  oauthSub: login.oauthSubject,
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

export const AppRouter = connector(UnconnectedAppRouter);
