import { IVariant, UnleashClient } from 'unleash-proxy-client';
import type { UnleashFlagValue } from './types';
import { getEnv } from './getEnv';

const getUnleashApiToken = (envLabel: string): string => {
  if (envLabel.includes('Prod')) {
    return 'default:production.00e880133a9aa60d27d5d8ae04cb13c5b6d806cabf0384e3c12b4483';
  }
  if (envLabel.includes('Test')) {
    return 'default:development.7692bb98fac18f061f93b002031808628747a9295002a98506cd590e';
  }
  return 'default:development.unleash-insecure-api-token';
};

const getCustomer = (envLabel: string): string =>
  envLabel.replace('Test', '').replace('Prod', '').toLowerCase();

const unleashEnv = getEnv();
const unleashUrl = `${window.location.origin}/unleash/api/frontend`;
const unleashConfig = {
  url: unleashUrl,
  clientKey: getUnleashApiToken(unleashEnv),
  // Refresh is turned off. Changing flags while the app is running may cause unpredictable behavior.
  refreshInterval: 0,
  appName: 'regard-app-fe',
  context: { properties: { customer: getCustomer(unleashEnv) } },
};

export const unleashClient = new UnleashClient(unleashConfig);

export const VALUE_VARIANT_NAME = 'value';

export const flagHasVariant = (variant: IVariant) =>
  variant &&
  variant.enabled &&
  variant.feature_enabled &&
  variant.name === VALUE_VARIANT_NAME &&
  variant.payload?.type === 'json';

const resolveValueFromJSON = <K extends keyof Flags>(
  flagKey: K,
  json: string
): UnleashFlagValue => {
  const payload = JSON.parse(json);
  const payloadValueType = payload.type;
  const payloadValue = payload.value;

  if (payloadValueType === 'boolean') {
    if (payloadValue === 'true') return true;
    if (payloadValue === 'false') return false;
    return !!payloadValue;
  }
  if (payloadValueType === 'string') {
    return payloadValue;
  }
  if (payloadValueType === 'number') {
    const value = parseFloat(payloadValue);
    if (Number.isNaN(value)) {
      throw new Error(`Number payload value not a number ${payloadValue}`);
    }
    return parseFloat(payloadValue);
  }

  throw new Error(
    `Unknown payload type ${payloadValueType} with value "${payloadValue}" for flag ${flagKey}`
  );
};

export const getFlagVariantValue = <K extends keyof Flags>(
  flagKey: K,
  variant: IVariant
): Flags[K] | undefined => {
  if (!variant.payload) {
    return undefined;
  }

  try {
    const json = variant.payload.value;
    const value = resolveValueFromJSON(flagKey, json);
    // Hard cast to resolve UnleashFlagValue to Flags[K]
    return value as Flags[K];
  } catch (e) {
    // Send a Sentry error as instead
    console.warn('error getting variant payload data', e);
    return undefined;
  }
};

export { UnleashClient };
