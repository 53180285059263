import { captureException } from '@sentry/browser';
import { htmlStringToPlainText } from '~/app/utils';

import { PASTE_ELEMENT_ID, getClipboardIframe } from './clipboardIframe';
import { canUseClipboardAPI } from './canUseClipboard';
import { CopyResult } from './types';

export const copyHtmlToClipboard = async (html: HtmlString): Promise<CopyResult> => {
  const clipboardAllowed = await canUseClipboardAPI();
  if (clipboardAllowed) {
    try {
      // Create a ClipboardItem object with the HTML content and plain text backup
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([htmlStringToPlainText(html)], { type: 'text/plain' }),
        'text/html': new Blob([html], { type: 'text/html' }),
      });

      // Write the ClipboardItem to the clipboard
      await navigator.clipboard.write([clipboardItem]);
      return { error: null, copyMethod: 'clipboard' };
    } catch (e) {
      captureException(e);
    }
  }

  // Fallback to the legacy `execCommand` method of copying to the clipboard (works in IE)
  try {
    const iframe = getClipboardIframe();
    const iframeWindow = iframe?.contentWindow;
    const iframeDocument = iframeWindow?.document;
    if (!iframe || !iframeDocument || !iframeWindow) {
      return { error: 'No iframe content', copyMethod: null };
    }

    const div = iframeDocument.getElementById(PASTE_ELEMENT_ID);
    if (!div) {
      return { error: 'No iframe paste element', copyMethod: null };
    }

    div.innerHTML = html;

    const range = iframeDocument.createRange();
    range.selectNode(div);
    iframeWindow.getSelection()?.removeAllRanges();
    iframeWindow.getSelection()?.addRange(range);
    iframeDocument.execCommand('copy');
    iframeWindow.getSelection()?.removeAllRanges();

    div.innerHTML = '';
    return { error: null, copyMethod: 'execCommand' };
  } catch (e) {
    captureException(e);
  }

  return { error: 'Unable to copy note to clipboard', copyMethod: null };
};
