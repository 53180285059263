import { SummaryUl, SummarySpanB, SummaryOl } from './common';

export const thromboSummary = (
  <SummaryOl>
    <li>
      Regard diagnoses thrombocytopenia or thrombocytosis based on abnormally low or elevated
      platelet labs or mention of thrombocytopenia/thrombocytosis in clinical notes or prior
      history. The algorithm will also determine acuity and display relevant labs and procedures.
    </li>
    <li>
      Check for clinical note or prior history of ITP
      <SummaryUl innerList>
        <li>
          If the patient has a note of, or a prior history of ITP, <SummarySpanB>and</SummarySpanB>{' '}
          the patient is on ITP meds diagnosis is <SummarySpanB>positive</SummarySpanB> for ITP.
          Acuity is determined from the most recent note; if no acuity is found the default is
          “acute”.
        </li>
      </SummaryUl>
    </li>
    <li>
      Check for clinical note or prior history of HIT
      <SummaryUl innerList>
        <li>
          If the patient has a note for HIT,{' '}
          <SummarySpanB>diagnosis is positive for HIT</SummarySpanB> and if HIT is found in the
          patient’s history, <SummarySpanB>diagnosis is history of HIT</SummarySpanB>.
        </li>
      </SummaryUl>
    </li>
    <li>
      Regard checks clinical note or prior history for etiology of thrombocytosis (essential,
      reactive, or, general)
    </li>
    <li>
      Check if the patient has a history of von Willebrand syndrome, and if positive, adjust the
      medications displayed accordingly for thrombocytosis.{' '}
    </li>
    <li>
      Display all active, inactive, held-home, discontinued, and discontinued-home meds, platelet
      count, and any procedures (platelet transfusion or splenectomy)
    </li>
  </SummaryOl>
);
