import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openResources } from '~/app/actions/ui';
import { Button } from '~/app/reuse';
import { EmptyNoteIcon } from './EmptyNoteIcon';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '2rem 0',
} as const;

const actionContainerStyle = {
  marginTop: '2rem',
} as const;

export const EmptyNote: FC = () => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => dispatch(openResources()), [dispatch]);

  return (
    <div style={containerStyle}>
      <EmptyNoteIcon />
      <h3>No Dx Details</h3>
      <div>There are no conditions that we cover in this note.</div>
      <div style={actionContainerStyle}>
        <Button color="tertiary" onClick={onClick} size="standard">
          View list of conditions that we currently cover
        </Button>
      </div>
    </div>
  );
};
