import { ConditionAssessmentPlan } from '../../@types/state';

export const generateConditionNameToKeywordRegexMap = (
  currentRegardConditions: Pick<ConditionAssessmentPlan, 'module'>[],
  dxKeywords: Record<string, string> // key is regard module ("anemia"), value is keyword ("blood loss")
) => {
  const validConditionNames = currentRegardConditions.map((c) => c.module);

  // Then we match all the dxKeywords to their regardModule
  const conditionNameToKeywords: Record<string, string[]> = {};
  Object.entries(dxKeywords).forEach(([conditionKeyword, conditionName]) => {
    // We ignore dxKeywords for modules without props (negative conditions)
    if (!validConditionNames.includes(conditionName)) return;

    if (!conditionNameToKeywords[conditionName]) {
      conditionNameToKeywords[conditionName] = [conditionKeyword];
    } else {
      conditionNameToKeywords[conditionName].push(conditionKeyword);
    }
  });

  // Finally we create the full regex expression for each regardCondition
  const conditionNameToKeywordRegex: Record<string, RegExp> = {};
  Object.entries(conditionNameToKeywords).forEach(([conditionName, conditionKeywordsArray]) => {
    conditionNameToKeywordRegex[conditionName] = new RegExp(
      `\\b(${conditionKeywordsArray.join('|')})\\b`,
      'i'
    );
  });

  return conditionNameToKeywordRegex;
};

export const generateConditionQualifiersRegexMap = (
  dxQualifiers: Record<string, Record<string, string>> // key is regard module, value is qualifier-regex map ("systolic": "arterial|systolic")
) => {
  const conditionQualifiers: Record<string, Record<string, RegExp>> = {};

  Object.entries(dxQualifiers).forEach(([conditionName, qualifierRegexMap]) => {
    if (qualifierRegexMap && typeof qualifierRegexMap === 'object') {
      Object.entries(qualifierRegexMap).forEach(([label, regexString]) => {
        if (!conditionQualifiers[conditionName]) conditionQualifiers[conditionName] = {};
        const formattedLabel = label.replace(/[_.]/g, ' ');
        conditionQualifiers[conditionName][formattedLabel] = new RegExp(regexString, 'i');
      });
    }
  });

  return conditionQualifiers;
};
