import 'normalize.css';
import ReactDOM from 'react-dom';

import './iePolyfills';
import './subscriptions';

import { Maintenance } from './Maintenance';
import { App } from './components';

import { initializeExternalLibraries } from './controllers/initializeExternalLibraries';

window.addEventListener('DOMContentLoaded', initializeExternalLibraries);

const AppComponent = window.MAINTENANCE ? Maintenance : App;
ReactDOM.render(<AppComponent />, document.getElementById('root'));
