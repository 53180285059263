// Modification of kmw's "doNothing" plugin for CKEditor 4
// https://stackoverflow.com/a/7991519

const doNothingCmd = {
  exec: () => true,
};

const pluginName = 'doNothing';
CKEDITOR.plugins.add(pluginName, {
  init(editor) {
    editor.addCommand(pluginName, doNothingCmd);
  },
});

export {};
