import { ChangeEvent, FC, memo, useState } from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import { track } from '~/app/analytics';
import * as utils from '~/app/utils';
import CheckedSVG from '~/app/images/checkbox.svg';
import UncheckedSVG from '~/app/images/checkbox-empty.svg';
import ChevronDownSVG from '~/app/images/chevron-down.svg';
import '~/app/styles/PhysicalExamContainer.scss';
import { setLocalStorageItem } from '~/app/utils';

interface Props {
  physicalExamText: string;
  physicalExamKey: string;
  included: boolean;
  flipIncluded: () => void;
  author: string;
  signedTimestamp: ISODateString | null;
}

export const PhysicalExamContainer: FC<Props> = memo(
  ({ author, flipIncluded, included, physicalExamKey, physicalExamText, signedTimestamp }) => {
    const [expanded, setExpanded] = useState(true);
    const flipExpanded = () => setExpanded(!expanded);
    const classes = classNames('PhysicalExamContainer', { expanded, included });
    const onChange = physicalExamKey
      ? ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
          const physicalExamText = (target as HTMLTextAreaElement).value;
          setLocalStorageItem(physicalExamKey, physicalExamText);
        }
      : undefined;

    let physicianSigned = '';
    if (author && signedTimestamp) {
      physicianSigned = `${author} - ${utils.formatDatetime(signedTimestamp)}`;
    } else if (author) physicianSigned = author;
    else if (signedTimestamp) physicianSigned = utils.formatDatetime(signedTimestamp);

    return (
      <div className={classes} data-cy-physical-exam>
        <div
          className="PhysicalExamHeader"
          data-cy-physical-exam-header
          onClick={() => {
            if (expanded) {
              track.clickedHidePhysicalExam();
            } else {
              track.clickedExpandPhysicalExam();
            }
            flipExpanded();
          }}
        >
          <ChevronDownSVG data-cy-physical-exam-arrow />
          <span
            className="checkbox"
            data-cy-physical-exam-checkbox
            onClick={(e) => {
              if (included) {
                track.clickedExcludePhysicalExamText();
              } else {
                track.clickedIncludePhysicalExamText();
              }
              if (!expanded && !included) flipExpanded();
              flipIncluded();
              e.stopPropagation();
            }}
          >
            {included ? <CheckedSVG /> : <UncheckedSVG />}
          </span>
          Physical Exam
          {physicianSigned && <span className="physician-date">{`(${physicianSigned})`}</span>}
        </div>
        <TextareaAutosize
          data-cy-physical-exam-textarea
          defaultValue={physicalExamText}
          id="physical-exam-textarea"
          maxRows={30}
          minRows={2}
          onChange={onChange}
          readOnly={!included}
        />
      </div>
    );
  }
);
PhysicalExamContainer.displayName = 'PhysicalExamContainer';
