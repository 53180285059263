import { useMemo } from 'react';

import { get } from '~/app/utils';
import { Suggestion } from '../../../@types';
import { createSelector, useSelector } from '../../../store';

const EMPTY_SUGGESTIONS: Suggestion[] = [];

export const useConditionSuggestions = (id: string): Suggestion[] => {
  const getConditionSuggestions = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.conditionsById, id)?.suggestions,
        (suggestions) => suggestions ?? EMPTY_SUGGESTIONS
      ),
    [id]
  );

  const suggestions = useSelector(getConditionSuggestions);

  return suggestions;
};
