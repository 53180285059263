import { paramToISODateString } from '~/app/utils';
import { client } from '~/app/api';
import { FeedbackFailed } from '../errors';
import { captureExceptionWithContext } from '../analytics';

const sendFeedback = async ({
  email,
  encounterId,
  runTimestamp,
  noteTimestamp,
  urlTimestamp,
  noteId,
  htmlBlob,
  patientMrn,
  notePatientId,
  urlPatientId,
  reason,
}: {
  email: string;
  encounterId: string;
  runTimestamp: ISODateString | null;
  noteTimestamp: ISODateString | null;
  urlTimestamp: string | null;
  noteId: string;
  htmlBlob: Blob;
  patientMrn: string;
  notePatientId: string | null;
  urlPatientId: string | null;
  reason: string;
}): Promise<void> => {
  // 1. Get appropriate pt, runTimestamp, and noteTimestamp fields
  const ptFieldData = notePatientId || urlPatientId || '';
  const getRunTimestampFieldData = () => {
    if (runTimestamp) {
      return new Date(runTimestamp).toISOString();
    }
    if (urlTimestamp) {
      return paramToISODateString(urlTimestamp) || '';
    }
    return '';
  };
  const runTimestampFieldData = getRunTimestampFieldData();
  const getNoteTimestampFieldData = () => {
    if (noteTimestamp) {
      return new Date(noteTimestamp).toISOString();
    }
    return '';
  };
  const noteTimestampFieldData = getNoteTimestampFieldData();

  // 2. Set query parameters
  // If this is executed in a context where regardNote has not been set in
  // the store (the generic error boundary message, shown in cases such as
  // auth errors), we need to take what we can from the query parameters
  // as a fallback.
  const params = new URLSearchParams();
  params.set('pt', ptFieldData);
  params.set('run-timestamp', runTimestampFieldData);
  params.set('note-timestamp', noteTimestampFieldData);
  params.set('note-id', noteId);

  // 3. Send request with query parameters and form data
  // In the case where there is no error regarding the store, backend prefers form data over query parameters
  try {
    const { error } = await client.POST(`/openapi/app/feedback`, {
      credentials: 'include',
      params: {
        query: Object.fromEntries(params),
      },
      body: {
        pt: ptFieldData,
        runTimestamp: runTimestampFieldData,
        noteTimestamp: noteTimestampFieldData,
        noteId,
        encounterId,
        // Unsure of a better way to type this
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        html: htmlBlob,
        patientMrn,
        reason,
        email,
      },
      // Unsure of a better way to type this
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bodySerializer(body: {
        pt: string;
        runTimestamp: string;
        noteTimestamp: string;
        noteId: string;
        encounterId: string;
        html: Blob;
        patientMrn: string;
        reason: string;
        email: string;
      }) {
        const formData = new FormData();
        formData.append('pt', body.pt);
        formData.append('runTimestamp', body.runTimestamp);
        formData.append('noteTimestamp', body.noteTimestamp);
        formData.append('noteId', body.noteId);
        formData.append('encounterId', body.encounterId);
        formData.append('html', body.html);
        formData.append('patientMrn', body.patientMrn);
        formData.append('reason', body.reason);
        formData.append('email', body.email);
        return formData;
      },
    });
    if (error) {
      throw new Error((error as { message?: string }).message);
    }
  } catch (e) {
    // If request to flask server fails, send to Sentry
    const extraScope = { runTimestamp, ptFieldData, e, reason };
    captureExceptionWithContext(new FeedbackFailed((e as Error).message), extraScope);
  }
};
export default sendFeedback;
