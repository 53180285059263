import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../webStorage';

const LOCAL_STORAGE_FLAG_KEY = 'regard-local-flags';

const getAndParseLocalStorageItem = <K extends keyof LocalStorageItems>(
  key: K
): LocalStorageItems[K] | null => {
  try {
    const itemString: string | null = getLocalStorageItem(key);

    return itemString === null ? itemString : (JSON.parse(itemString) as LocalStorageItems[K]);
  } catch {
    // Chances are, `localStorage` was not defined.
    return null;
  }
};

export const getFlagFromLocalStorageOrWindow = <K extends keyof Flags>(
  key: K
): Flags[K] | undefined => {
  const localStorageFlags = getAndParseLocalStorageItem(LOCAL_STORAGE_FLAG_KEY);
  const localStorageFlagsValue = localStorageFlags?.[key];
  if (localStorageFlagsValue !== undefined) {
    return localStorageFlagsValue;
  }

  const windowValue = window[key];
  if (windowValue !== undefined) {
    return windowValue;
  }

  return undefined;
};

const stringifyAndSetLocalStorageItem = <K extends keyof LocalStorageItems>(
  key: K,
  value: LocalStorageItems[K] | null
): void => {
  try {
    if (value === null) {
      removeLocalStorageItem(key);
    } else {
      setLocalStorageItem(key, JSON.stringify(value));
    }
  } catch {
    // Chances are, `localStorage` was not defined.
  }
};

export const setFlagInLocalStorage = <K extends keyof Flags>(
  key: K,
  value: Flags[K] | null
): void => {
  const localStorageFlags = getAndParseLocalStorageItem(LOCAL_STORAGE_FLAG_KEY);
  stringifyAndSetLocalStorageItem(LOCAL_STORAGE_FLAG_KEY, {
    ...localStorageFlags,
    [key]: value,
  });
};
