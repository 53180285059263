import { SummaryP } from './common';

export const acuteCoronarySyndromeSummary = (
  <SummaryP>
    Regard will search notes, problem list, and procedures to differentiate between STEMI, NSTEMI,
    unstable angina, or type II MI. If unable to differentiate the diagnosis will search for notes,
    problem list, and procedures for chest pain or troponin elevation. If NSTEMI is diagnosed,
    Regard will calculate a GRACE score. Associated labs will be displayed along with medications,
    imaging, and procedures including cardiac catheterizations, cardiac stress tests and CABGs.
  </SummaryP>
);
