import { forwardRef } from 'react';

import { ButtonColorCSS, ButtonSizeCSS, Root } from './css';
import { ButtonColor, ButtonSize } from './types';
import { ButtonBaseProps } from '../buttonBase';
import { IconName } from '../icons';
import { Icon } from '../icon';

export type ButtonProps = ButtonBaseProps & {
  color: ButtonColor | ButtonColorCSS;
  size: ButtonSize | ButtonSizeCSS;
  startIconName?: IconName;
};

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  ({ children, color, size, startIconName, ...rest }, ref) => (
    <Root ref={ref} color={color} size={size} {...rest}>
      {startIconName ? <Icon data-button-start-icon iconName={startIconName} /> : null}
      {children}
    </Root>
  )
);
Button.displayName = 'Button';
