import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate } from 'react-router-dom';

import * as flags from '../flags';
import { AppState } from '../store';

type DefaultContentProps = PropsFromRedux;

const UnconnectedDefaultContent: FC<DefaultContentProps> = ({ login }) => {
  if (login.oauthSubject) {
    if (flags.isQueryEnabled()) {
      return <Navigate replace to="/query" />;
    }
    return <p>You are already logged in! Try refreshing to continue.</p>;
  }
  return <p>You must login. Try refreshing to continue.</p>;
};

const connector = connect(({ login }: AppState) => ({ login }));

type PropsFromRedux = ConnectedProps<typeof connector>;

export const DefaultContent = connector(UnconnectedDefaultContent);
