import { ConditionNoteBlock, Line, NoteBlock, TitleStyles } from '~/app/@types/state';
import { insert, replace, uniqueId, addStylesToHtml, nth, linesToPlainText } from '~/app/utils';

// NOTE: This function is tested by retainStaleNoteBlockData.test.ts
export const addNoteBlockAtIndex = ({
  index,
  newConditionTitleSignifier,
  staleNoteBlocks,
  titleStyles,
}: {
  index: number;
  newConditionTitleSignifier: string;
  staleNoteBlocks: NoteBlock[];
  titleStyles: TitleStyles;
}): { noteBlocksWithAddedCondition: NoteBlock[]; noteBlockCaretPos: number } => {
  const blockTypeAtIndex = nth(staleNoteBlocks, index)?.type;
  const newConditionIsShelved = blockTypeAtIndex === 'shelvedCondition';

  const titleLine = `${newConditionTitleSignifier}&nbsp;` as HtmlString;
  const html = addStylesToHtml(titleLine, titleStyles) as HtmlString;

  // 1. Create the new condition area
  const lines: Line[] = [
    {
      html,
      key: `title-0`,
      plainTextWithoutTitleSignifier: '',
      titleSignifier: newConditionTitleSignifier,
      status: 'none',
      type: 'title',
    },
  ];
  const newConditionNoteBlock: ConditionNoteBlock = {
    bulletsByTrimmedTextKey: {},
    diff: null,
    id: uniqueId(),
    isNew: false,
    keywords: [],
    modules: [],
    lines,
    suggestions: [],
    text: linesToPlainText(lines),
    type: newConditionIsShelved ? 'shelvedCondition' : 'condition',
  };

  // 2. Insert the new condition
  const noteBlocksWithAddedCondition = insert(staleNoteBlocks, index, newConditionNoteBlock);

  // 3. Get the new condition caret position
  const noteBlockCaretPos = noteBlocksWithAddedCondition[index].text.length;
  const addedConditionWithCaret = {
    ...noteBlocksWithAddedCondition[index],
    setCaretPosition: {
      caretPos: noteBlockCaretPos,
      timestamp: Date.now(),
    },
  };

  // 4. Replace the note section with one with a caret
  const noteBlocksWithCaret = replace(addedConditionWithCaret, index, noteBlocksWithAddedCondition);

  return {
    noteBlocksWithAddedCondition: noteBlocksWithCaret,
    noteBlockCaretPos,
  };
};
