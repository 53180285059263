import { useCallback, useEffect } from 'react';

import { useDispatch } from '~/app/store';
import { KEY_CODE_1, KEY_CODE_2, KEY_CODE_3, KEY_CODE_4, TIMESTAMP_PARAM } from '~/app/constants';
import { fetchNoteThunk } from '~/app/actions/regardNote';
import { LONG_DEMO_DAY_1, LONG_DEMO_DAY_2, LONG_DEMO_DAY_3, LONG_DEMO_DAY_4 } from './demo/const';
import '~/app/styles/CaseReviewToolbar.scss';

// Toolbar for Sales Demo
export const DemoToolbar = () => {
  const dispatch = useDispatch();

  // We replace the timestamp in the URL and call the fetchNoteThunk to reload
  // the Regard app.
  const refetchOnURLChange = useCallback(
    (timestamp: string) => {
      const params = new URLSearchParams(window.location.search);
      params.set(TIMESTAMP_PARAM, timestamp);
      const newurl = `${window.location.origin + window.location.pathname}?${params.toString()}`;
      window.history.pushState({ path: newurl }, '', newurl);
      setTimeout(() => dispatch(fetchNoteThunk()));
    },
    [dispatch]
  );

  const goToDay1 = useCallback(() => refetchOnURLChange(LONG_DEMO_DAY_1), [refetchOnURLChange]);
  const goToDay2 = useCallback(() => refetchOnURLChange(LONG_DEMO_DAY_2), [refetchOnURLChange]);
  const goToDay3 = useCallback(() => refetchOnURLChange(LONG_DEMO_DAY_3), [refetchOnURLChange]);
  const goToDay4 = useCallback(() => refetchOnURLChange(LONG_DEMO_DAY_4), [refetchOnURLChange]);

  const keyboardEventToDay = useCallback(
    (event: KeyboardEvent): void => {
      if (!event.ctrlKey) return;
      if ((event.which || event.keyCode) === KEY_CODE_1) goToDay1();
      else if ((event.which || event.keyCode) === KEY_CODE_2) goToDay2();
      else if ((event.which || event.keyCode) === KEY_CODE_3) goToDay3();
      else if ((event.which || event.keyCode) === KEY_CODE_4) goToDay4();
    },
    [goToDay1, goToDay2, goToDay3, goToDay4]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyboardEventToDay);
    return () => {
      document.removeEventListener('keydown', keyboardEventToDay);
    };
  }, [keyboardEventToDay]);

  return (
    <div className="CaseReviewToolbar">
      <span className="title">REGARD DEMO</span>
      <span key="day-4" className="nav-link" onClick={goToDay4}>
        Day 4
      </span>
      <span key="day-3" className="nav-link" onClick={goToDay3}>
        Day 3
      </span>
      <span key="day-2" className="nav-link" onClick={goToDay2}>
        Day 2
      </span>
      <span key="day-1" className="nav-link" onClick={goToDay1}>
        Day 1
      </span>
    </div>
  );
};
