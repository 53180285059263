import { FC } from 'react';
import { getNotesAppUrl, getRedirectToNotesApp } from '~/app/flags';

export const RedirectToNotes: FC = () => {
  const shouldRedirect = getRedirectToNotesApp();
  if (!shouldRedirect) {
    return null;
  }

  const notesAppUrl = getNotesAppUrl();
  window.location.replace(notesAppUrl);
  return null;
};
