import { InfluxDB, Point, WriteApi } from '@influxdata/influxdb-client-browser';
import _ from 'lodash';
import { getOAuthSubject, getUserFullName, getUserReadOnly } from '../cookies';
import { getEnv } from '../flags';

declare global {
  interface Window {
    INFLUX_URL: string;
    INFLUX_HT_ORG: string;
    INFLUX_HT_BUCKET: string;
    INFLUX_HT_TOKEN: string;
  }
}

type LimitedWriteAPI = Pick<WriteApi, 'flush' | 'writePoint'>;

const noopApi: LimitedWriteAPI = {
  flush: async (): Promise<void> => {
    //
  },
  writePoint: _.noop,
};

const getWriteApi = (): LimitedWriteAPI => {
  const url = window.INFLUX_URL;
  const htOrg = window.INFLUX_HT_ORG;
  const htBucket = window.INFLUX_HT_BUCKET;
  const htToken = window.INFLUX_HT_TOKEN;

  const configHT = htOrg || htBucket || htToken;

  const buildWriteApi = (org: string, bucket: string, token: string) =>
    new InfluxDB({ url, token })
      .getWriteApi(org, bucket, 'ms', {
        flushInterval: 6000, // default is 60000, which is a little much
      })
      .useDefaultTags({
        provider: getEnv(),
        // for historical reasons, we refer to the OAuth Subject as `userId` in Influx/Grafana
        userId: getOAuthSubject() || 'null',
        // NOTE: Not necessary to remove commas (which will later be escaped as
        //  \054) because we can remove them in the Influx/Grafana query
        userName: getUserFullName() || 'null',
      });

  if (configHT && !getUserReadOnly()) {
    return buildWriteApi(htOrg, htBucket, htToken);
  }

  return noopApi;
};

const writeApi = getWriteApi();

export type InfluxField = boolean | number | string | Date | Array<string> | null | undefined;

export const buildPoint = (
  measurement: string,
  fields: Record<string, InfluxField>,
  tags: Record<string, InfluxField> = {},
  timestamp: Date | number | string = new Date()
): Point => {
  const point = new Point(measurement).timestamp(timestamp);

  Object.entries(fields).forEach(([key, value]) => {
    if (typeof value === 'boolean') {
      point.booleanField(key, value);
    } else if (typeof value === 'number') {
      // NOTE: `Number.isInteger` really detects if a float is a round number,
      //  which creates a problem if we're reporting a round float to influx
      //  as an integer, when it's expecting a float!
      if (Number.isInteger(value)) {
        point.intField(key, value);
      } else {
        point.floatField(key, value);
      }
    } else if (typeof value === 'string') {
      point.stringField(key, value);
    } else if (value instanceof Date) {
      point.stringField(key, value.toISOString());
    } else if (value instanceof Array) {
      // Assumes it is an array of strings - other array types may need
      // to be handled differently
      value.forEach((str: string) => point.intField(`${key}_${str}`, 1));
    }
    // NOTE: null and undefined will not be added
  });

  Object.entries(tags).forEach(([key, value]) => {
    if (value) {
      if (value instanceof Date) {
        point.tag(key, value.toISOString());
      } else {
        point.tag(key, value.toString());
      }
    }
  });

  return point;
};

// Influx writePoints tend to queue up for a while
const flush = () => {
  writeApi.flush();
};

const send = (
  measurement: string,
  fields: Record<string, InfluxField>,
  tags: Record<string, InfluxField>,
  timestamp: Date | number | string = new Date()
): void => {
  // All measurements must have at least one field
  const safeFields = _.isEmpty(fields) ? { [measurement]: true } : fields;
  const point = buildPoint(measurement, safeFields, tags, timestamp);
  writeApi.writePoint(point);
};

export const influx = {
  flush,
  send,
};
