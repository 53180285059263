import { SummaryUl, SummarySpanB, SummaryOl } from './common';

export const thyroidSummary = (
  <SummaryOl>
    <li>
      Check for hyper/hypothyroidism by searching patient data for a chronic history of the
      condition and relevant medications. If the condition is found, or there are active
      medications, the diagnosis <SummarySpanB>is positive</SummarySpanB>.
    </li>
    <li>
      If no relevant medications are found, check for active hypothyroidism based on TSH and free T4
      results.
    </li>
    <li>
      Check for hyperthyroidism by searching for evidence of a chronic history including procedures
      (thyroidectomy, radioactive iodine ablation), and medications. If there is a history of
      hyperthyroidism, <SummarySpanB>positive chronic hyperthyroidism</SummarySpanB>.
      <SummaryUl innerList>
        <li>
          If associated medications are found{' '}
          <SummarySpanB>diagnosis for hyperthyroidism is positive</SummarySpanB>
        </li>
        <li>
          If no relevant medications are found, check for active hyperthyroidism based on labs (TSH
          and free T4).
        </li>
      </SummaryUl>
    </li>
    <li>
      Normalize conflicting thyroid disorders
      <SummaryUl innerList>
        <li>
          If evidence of both hypothyroidism and hyperthyroidism are found, the patient is
          determined to be hypothyroid or hyperthyroid based on their current medications. If a
          procedure for hyperthyroid has been performed, they are diagnosed as “acquired
          hypothyroidism”.
        </li>
      </SummaryUl>
    </li>
  </SummaryOl>
);
