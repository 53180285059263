import styled from '@emotion/styled';

import { theme } from '../../../reuse';

const { blueMedium, blueRegular, grey2, grey3, none, white } = theme.colors;

export const Root = styled.div<{
  buttonHovered: boolean;
  dismissButtonHovered: boolean;
}>(
  {
    position: 'relative',
    '&:hover': {
      color: blueRegular,
    },
  },
  ({ buttonHovered, dismissButtonHovered }) => ({
    color: buttonHovered ? blueMedium : grey2,
    textDecoration: dismissButtonHovered ? 'line-through' : undefined,
  })
);

export const CONDITION_SUGGESTION_INNER_PADDING_LEFT = 24;

export const Inner = styled.div<{ reviewMode: boolean }>(
  {
    position: 'relative',
    borderBottom: `1px solid ${none}`,
    borderTop: `1px solid ${none}`,
    paddingLeft: CONDITION_SUGGESTION_INNER_PADDING_LEFT,
    '&:hover': {
      backgroundColor: white,
    },
    // Show the additional bullet UI on hover
    '&:not(:hover)': {
      '[data-condition-suggestion-add-button]:not(:focus) svg': { opacity: 0 },
      '[data-condition-suggestion-add-button]:not(:focus) [data-condition-suggestion-add-button-critical-flag]':
        { opacity: 0 },
      '[data-condition-suggestion-copy-button]:not(:focus) [data-condition-suggestion-copy-button-label]:not(:focus) ':
        { opacity: 0 },
      '[data-condition-suggestion-remove-button]:not(:focus)': { opacity: 0 },
    },
  },
  ({ reviewMode }) => ({
    '&:hover': {
      borderBottomColor: reviewMode ? grey3 : blueRegular,
      borderTopColor: reviewMode ? grey3 : blueRegular,
    },
  })
);
