import {
  ConditionAssessmentPlan,
  BulletNoteStatusRecord,
  EligibleHtmlBaseNote,
  NotificationAttrs,
  SimpleCondition,
  Stats,
} from '~/app/@types/state';
import { AppDispatch } from '~/app/store';

import { addBaseNoteChangedNotification } from './addBaseNoteChangedNotification';
import { addNewUpdatedConditionsNotification } from './addNewUpdatedConditionsNotification';
import { addErrorNotification } from './addErrorNotification';
import { addDismissedNotification } from './addDismissedNotification';
import { addNoLabeledDiagnosesNotification } from './addNoLabeledDiagnosesNotifiction';
import { addPreviousEncounterNotification } from './addPreviousEncounterNotification';
import { addCriticalConditionsNotification } from './addCriticalConditionsNotification';

export interface DraftData {
  baseNoteContentHash: string | null;
}

interface RegardNotificationsParams {
  dispatch: AppDispatch;
  draft: DraftData;
  conditions: Record<string, SimpleCondition>;
  currentConditionResults: ConditionAssessmentPlan[];
  bulletNoteStatus: BulletNoteStatusRecord;
  errors: { module: string }[];
  stats: Stats;
  selectedBaseNote: EligibleHtmlBaseNote;
}

/* This function generates an array of Notifications as React Components.
 * As a side effect we also send messages to Sentry if the resulting
 * notifications are warnings or errors */
export const regardNotifications = ({
  dispatch,
  draft,
  conditions,
  currentConditionResults,
  bulletNoteStatus,
  errors,
  stats,
  selectedBaseNote,
}: RegardNotificationsParams): NotificationAttrs[] => {
  const notifications: NotificationAttrs[] = [];

  addCriticalConditionsNotification(notifications, conditions);
  addBaseNoteChangedNotification(notifications, dispatch, draft, selectedBaseNote);
  addNewUpdatedConditionsNotification(notifications, conditions, stats, bulletNoteStatus);
  addErrorNotification(notifications, errors);
  addDismissedNotification(notifications, currentConditionResults, bulletNoteStatus);
  addNoLabeledDiagnosesNotification(notifications, stats);
  addPreviousEncounterNotification(notifications, selectedBaseNote);

  return notifications;
};
