import { useMemo } from 'react';
import { Patient } from '../../../@types/state';
import { createSelector, useSelector } from '../../../store';

export const useDemoData = <K extends keyof Patient>({ category }: { category: K }): Patient[K] => {
  const demoTableSelector = useMemo(
    () =>
      createSelector(
        (state) => state.record,
        (record) => record.data?.[category] ?? []
      ),
    [category]
  );

  const items = useSelector(demoTableSelector) as Patient[K];

  return items;
};
