import { isConditionNoteBlock, linesToPlainText } from '~/app/utils';
import { PreModuleNoteBlock, TitleSignifier } from '../../../@types/state';
import { renumberHtmlTitleLines } from '../renumberHtmlTitleLines';

type RenumberNoteBlocksBlock = Pick<PreModuleNoteBlock, 'type' | 'lines'>;

export const renumberNoteBlocks = <T extends RenumberNoteBlocksBlock>({
  addTextTimestampToModifiedNoteBlocks,
  noteBlocks,
  noteTitleSignifier,
  startingAtNoteBlockIndex,
}: {
  addTextTimestampToModifiedNoteBlocks: boolean;
  noteBlocks: T[];
  noteTitleSignifier: TitleSignifier;
  startingAtNoteBlockIndex: number;
}): T[] => {
  let currentTitleIndex = 0;
  let noteBlocksWereModified = false;

  const renumberedNoteBlocks = noteBlocks.map((noteBlock, index) => {
    // 1. Check if the condition needs to be renumbered
    if (isConditionNoteBlock(noteBlock) && index >= startingAtNoteBlockIndex) {
      // 2. Renumber the condition
      const { linesWereModified, linesWithRenumberedTitleLines, titleLineCount } =
        renumberHtmlTitleLines({
          currentTitleIndex,
          lines: noteBlock.lines,
          noteTitleSignifier,
        });

      currentTitleIndex += titleLineCount;

      if (linesWereModified) {
        // 3. Note that lines were modified
        noteBlocksWereModified = true;

        // 4. And return the condition area with renumbered lines
        return {
          ...noteBlock,
          // 5. Attach a new text timestamp
          ...(addTextTimestampToModifiedNoteBlocks ? { textTimestamp: Date.now() } : undefined),
          lines: linesWithRenumberedTitleLines,
          text: linesToPlainText(linesWithRenumberedTitleLines),
        };
      }

      // 5. Otherwise, return the unmodified condition area and don't fall into
      //  the next case, because we don't want to count title lines again
      return noteBlock;
    }

    // 4. Even if the condition doesn't need to be renumbered, increment
    //  `currentTitleIndex`
    const titleLineCount = noteBlock.lines.filter(({ type }) => type === 'title').length;
    currentTitleIndex += titleLineCount;

    return noteBlock;
  });

  return noteBlocksWereModified ? renumberedNoteBlocks : noteBlocks;
};
