import { CSSProperties, FC, ReactNode } from 'react';
import { epicTheme } from './epicTheme';

export const EpicTable: FC<{
  headerColor: string;
  label: string;
  rows: ReactNode[][];
  style?: CSSProperties;
}> = ({ headerColor, label, rows, style }) => (
  <div style={{ whiteSpace: 'pre-wrap', ...style }}>
    <div
      style={{
        backgroundColor: headerColor,
        border: `1px solid ${epicTheme.colors.darkBlue}`,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '12px 32px',
      }}
    >
      {label}
    </div>
    <div
      style={{
        border: `1px solid ${epicTheme.colors.darkBlue}`,
      }}
    >
      <div>
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              backgroundColor: rowIndex % 2 === 0 ? epicTheme.colors.white : epicTheme.colors.gray,
              display: 'flex',
              padding: '2px 32px',
            }}
          >
            {row.map((column, columnIndex) => (
              <div
                key={columnIndex}
                style={{
                  flexBasis: 0,
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'pre-line',
                }}
              >
                {column}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
);
