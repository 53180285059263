import { FC, memo } from 'react';

import { Icon } from '~/app/reuse';
import { ConditionLine } from '../conditionLine';
import { ConditionTitle } from '../conditionTitle';
import { ConditionLineStatus } from '../../../@types';
import { Keyword, Line } from '../../../@types/state';
import { nth } from '../../../utils';
import { LineWithHeaderData } from '../conditionProvider/useConditionLines';
import {
  contentCss,
  copiedBackgroundStyle,
  copiedMessageStyle,
  copiedMessageIconStyle,
  copiedPillStyle,
  copiedStyle,
} from './styles';

const getStatusOfSiblingLine = ({
  index,
  lines,
}: {
  index: number;
  lines: Line[];
}): ConditionLineStatus => {
  const line = nth(lines, index);
  const lineIsTitleLine = line?.type === 'title';
  // If the sibling line is a title, treat is as 'none', since titles have a
  //  different way of displaying statuses than the typical green or grey bar.
  const status = (!lineIsTitleLine && line?.status) || 'none';

  return status;
};

const linesToChildren = ({
  hasNonCriticalSuggestions,
  isShelvedCondition,
  keywords,
  lines,
  suggestionsAreMinimized,
  toggleSuggestionsMinimized,
}: {
  hasNonCriticalSuggestions: boolean;
  isShelvedCondition: boolean;
  keywords: Keyword[];
  lines: LineWithHeaderData[];
  suggestionsAreMinimized: boolean;
  toggleSuggestionsMinimized(): void;
}) =>
  lines.map((line, i) => {
    const previousStatus = getStatusOfSiblingLine({ index: i - 1, lines });
    const nextStatus = getStatusOfSiblingLine({ index: i + 1, lines });

    switch (line.type) {
      case 'title':
        return (
          <ConditionTitle
            key={line.key}
            hasNonCriticalSuggestions={hasNonCriticalSuggestions}
            html={line.html}
            isFirstLine={line.isFirstLine}
            isLastLine={line.isLastLine}
            isShelvedCondition={isShelvedCondition}
            keywords={keywords}
            modules={line.conditionNames}
            status={line.status}
            suggestionsAreMinimized={suggestionsAreMinimized}
            toggleSuggestionsMinimized={toggleSuggestionsMinimized}
          />
        );
      case 'bullet':
        return (
          <ConditionLine
            key={line.key}
            html={line.html}
            ids={line.ids}
            interpretation={line.interpretation}
            isFirstLine={line.isFirstLine}
            isLastLine={line.isLastLine}
            nextStatus={nextStatus}
            previousStatus={previousStatus}
            status={line.status}
            type={line.type}
          />
        );
      default: // freetext
        return (
          <ConditionLine
            key={line.key}
            html={line.html}
            ids={undefined}
            isFirstLine={line.isFirstLine}
            isLastLine={line.isLastLine}
            nextStatus={nextStatus}
            previousStatus={previousStatus}
            status={line.status}
            type={line.type}
          />
        );
    }
  });

export const UnconnectedConditionUnderlay: FC<{
  hasNonCriticalSuggestions: boolean;
  isCopied: boolean;
  isShelvedCondition: boolean;
  keywords: Keyword[];
  lines: LineWithHeaderData[];
  suggestionsAreMinimized: boolean;
  toggleSuggestionsMinimized(): void;
}> = memo(
  ({
    hasNonCriticalSuggestions,
    isCopied,
    isShelvedCondition,
    keywords,
    lines,
    suggestionsAreMinimized,
    toggleSuggestionsMinimized,
  }) => (
    <div css={contentCss} data-cy-condition-underlay data-reflow-on-resize role="list">
      {isCopied && (
        <div style={copiedStyle}>
          <div style={copiedBackgroundStyle} />
          <div style={copiedPillStyle}>
            <div style={copiedMessageStyle}>
              <Icon iconName="fileCheckNew" style={copiedMessageIconStyle} />
              <span>Copied to clipboard</span>
            </div>
          </div>
        </div>
      )}
      {linesToChildren({
        hasNonCriticalSuggestions,
        isShelvedCondition,
        lines,
        keywords,
        suggestionsAreMinimized,
        toggleSuggestionsMinimized,
      })}
    </div>
  )
);
UnconnectedConditionUnderlay.displayName = 'UnconnectedConditionUnderlay';
