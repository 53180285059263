const getAbsoluteValueOfIndex = ({ index, arrayLength }: { index: number; arrayLength: number }) =>
  index < 0 ? arrayLength + index : index;

const arrayMoveMutate = <T>(array: T[], from: number, to: number): void => {
  const { length: arrayLength } = array;

  const startIndex = getAbsoluteValueOfIndex({ index: from, arrayLength });

  const startIndexIsInArray = startIndex >= 0 && startIndex < arrayLength;
  if (startIndexIsInArray) {
    const endIndex = getAbsoluteValueOfIndex({ index: to, arrayLength });

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = <T>(array: T[], from: number, to: number): T[] => {
  const result = [...array];

  arrayMoveMutate(result, from, to);

  return result;
};
