import { Global } from '@emotion/react';

const a = {
  cursor: 'pointer',
  '&:active': {
    outline: 0, // Remove the gray background color from active links in IE 10.
  },
  '&:hover': {
    outline: 0, // Remove the gray background color from hover links in IE 10.
  },
} as const;

const body = {
  lineHeight: '150%',
  padding: 0,
  margin: 0,
} as const;

const button = {
  background: 'none',
  border: 0,
  borderRadius: 0,
  cursor: 'pointer',
  margin: 0,
  outline: 0,
  overflow: 'visible', // Address `overflow` set to `hidden` in IE 8/9/10/11.
  padding: 0,
  textTransform: 'none', // Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
  '&:disabled': {
    cursor: 'default',
  },
} as const;

const html = {
  padding: 0,
  margin: 0,
} as const;

export const Normalize = () => (
  <Global
    styles={{
      a,
      body,
      button,
      html,
    }}
  />
);
