import { isBefore } from 'date-fns';

import {
  APINote,
  BaseNoteData,
  BaseNoteHtml,
  ConditionAssessmentPlan,
  BulletNoteStatusRecord,
  EligibleHtmlBaseNote,
  IndexingData,
  PreviousConditionAssessmentPlan,
  SimpleCondition,
  Stats,
  NoteBlock,
  ConditionNoteBlock,
  IndexedNoteBlock,
} from '~/app/@types/state';
import { HtmlVersion } from '~/app/@types';

import { meshNotes } from './noteMeshing';
import { generateBaseNoteText } from './generateBaseNoteText';
import { indexHtmlNote } from './noteMeshing/indexHtmlNote';

export const blankBaseNoteResourceID = 'No note received from API';

declare global {
  interface Window {
    newConditions?: ReadonlySet<string>;
    matchedConditions?: ReadonlySet<string>;
  }
}

// When /doc response yields 0 progress notes we use this blank note data
const blankBaseNoteBase = {
  abbrNoteType: '',
  author: '',
  effective: new Date().toISOString(),
  dateSigned: new Date().toISOString(),
  contentHash: null,
  noteType: '',
  physicalExamText: '',
  rawNoteType: '',
  resourceId: blankBaseNoteResourceID,
  status: '',
  currentEncounter: true,
};

export const blankHtmlBaseNote: EligibleHtmlBaseNote = {
  ...blankBaseNoteBase,
  noteHtml: '' as HtmlString,
  htmlVersion: HtmlVersion.HtmlWithEMU,
};

export const getBlankBaseNote = (): EligibleHtmlBaseNote => blankHtmlBaseNote;

export const getIsBlankBaseNote = (baseNote: EligibleHtmlBaseNote | undefined): boolean =>
  baseNote === getBlankBaseNote();

export const concatMatchedConditionAssessmentPlans = (
  conditionResults: APINote
): ConditionAssessmentPlan[] =>
  conditionResults.matched.concat(
    conditionResults.unmatchedConditionsSorted,
    conditionResults.negative
  );

// The following function prepares the base note data, meshes the Regard conditions/bullets
// into that base note, then indexes the meshed note, and kicks out all the objects
// to stash in the regardNote redux store. used by the fetchNote and fetchPrevPropsAndRemesh thunks
export type MeshedNoteData = {
  baseNoteData: BaseNoteData;
  bulletNoteStatusByBulletId: BulletNoteStatusRecord;
  conditions: Record<string, SimpleCondition>;
  conditionsById: Record<string, ConditionNoteBlock>;
  indexingData: IndexingData;
  masterNoteBlocks: NoteBlock[];
  noteBlocksById: Record<string, IndexedNoteBlock>;
  previousConditionResults: PreviousConditionAssessmentPlan[];
  stats: Stats;
};

const getHtmlData = (baseNote: EligibleHtmlBaseNote): BaseNoteHtml => ({
  htmlVersion: HtmlVersion.HtmlWithEMU,
  noteHtml: baseNote.noteHtml,
});

interface MeshBaseNoteWithRegardConditionsParams {
  baseNote: EligibleHtmlBaseNote;
  draft?: { html: HtmlString; baseNoteContentHash: string | null };
  useDraft: boolean;
  currentConditionAssessmentPlans: ConditionAssessmentPlan[];
  previousConditionAssessmentPlans: PreviousConditionAssessmentPlan[];
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  conditionNameToKeywordRegex: Record<string, RegExp>; // key is regard module ("anemia"), value is all the synonyms as 1 regex
  conditionQualifiers: Record<string, Record<string, RegExp>>; // key is regard module,sub key is qualifier type like "type" for diabetes, values are regex for thos qualifiers
  encounterId: string;
  physicalExamKey: string;
}

export const meshBaseNoteWithRegardConditions = async ({
  baseNote,
  draft,
  useDraft,
  currentConditionAssessmentPlans,
  previousConditionAssessmentPlans,
  bulletNoteStatusFromAPI,
  conditionNameToKeywordRegex,
  conditionQualifiers,
  encounterId,
  physicalExamKey,
}: MeshBaseNoteWithRegardConditionsParams): Promise<MeshedNoteData> => {
  const { baseNoteHtml, baseNoteType, physicalExamText, baseNoteContentHash } =
    await generateBaseNoteText({
      draft,
      useDraft,
      noteFromAPI: baseNote,
      physicalExamTextFromAPI: baseNote.physicalExamText,
      physicalExamKey,
      encounterId,
    });

  const effectiveTimestampOrNull = baseNote !== blankHtmlBaseNote ? baseNote.effective : null;
  const dateSignedTimestampOrNull = baseNote.dateSigned ? baseNote.dateSigned : null;
  const eitherTimestampOrNull = dateSignedTimestampOrNull || effectiveTimestampOrNull;
  const isBeforeBasenoteEffective = eitherTimestampOrNull
    ? (timestamp: ISODateString) => isBefore(timestamp, eitherTimestampOrNull)
    : () => true;
  const baseNoteResourceId = baseNote.resourceId;
  const meshedNoteData = meshNotes({
    baseNoteHtml,
    bulletNoteStatusFromAPI,
    conditionNameToKeywordRegex,
    conditionQualifiers,
    currentConditionAssessmentPlans,
    previousConditionAssessmentPlans,
    isBeforeBasenoteEffective,
    baseNoteResourceId,
  });

  const { stats } = meshedNoteData;
  window.newConditions = new Set(stats.newConditions);
  window.matchedConditions = new Set(stats.matchedConditions);

  // Index note
  const { bulletNoteStatus, conditionsById, indexingData, noteBlocks, noteBlocksById } =
    indexHtmlNote({
      staleIndexingData: meshedNoteData.indexingData,
      staleBulletNoteStatus: bulletNoteStatusFromAPI,
      userAction: {
        htmlLines: meshedNoteData.meshedNoteHtmlChunks,
        type: 'initialize',
      },
    });

  return {
    previousConditionResults: previousConditionAssessmentPlans,
    baseNoteData: {
      author: baseNote.author,
      baseNoteType,
      dateSignedTimestamp: dateSignedTimestampOrNull,
      effectiveTimestamp: effectiveTimestampOrNull,
      physicalExamText,
      resourceId: baseNote.resourceId,
      contentHash: baseNoteContentHash,
      ...getHtmlData(baseNote),
    },
    bulletNoteStatusByBulletId: bulletNoteStatus,
    conditions: meshedNoteData.conditions,
    conditionsById,
    indexingData,
    masterNoteBlocks: noteBlocks,
    noteBlocksById,
    stats: meshedNoteData.stats,
  };
};
