import React from 'react';
import PropTypes from 'prop-types';

const ItemFilters = ({ filters, filterValues, updateFilter }) => (
  <div className="htemr-ItemFilters">
    {filters.map((filter) => (
      <span key={filter.key} className="htemr-ItemFilters-filter">
        {filter.options.map((option) =>
          option === filterValues[filter.key] ? (
            <strong key={option}>{option}</strong>
          ) : (
            <span key={option} onClick={() => updateFilter(filter.key, option)}>
              {option}
            </span>
          )
        )}
      </span>
    ))}
  </div>
);

ItemFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterValues: PropTypes.shape({}).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default ItemFilters;
