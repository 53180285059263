import { theme, zIndices } from '../../../../reuse';
import { isSalesDemoMode } from '../../../../flags';

const gutter = 8;

export const formZIndex = zIndices.userFeedbackForm;

export const getCss = ({ fullSize, visible }: { fullSize: boolean; visible: boolean }) =>
  ({
    background: theme.colors.white,
    borderRadius: theme.other.borderRadius4,
    padding: '0 16px 32px',
    position: fullSize || isSalesDemoMode ? 'absolute' : 'fixed',
    overflow: 'hidden',
    transition: isSalesDemoMode ? 'none' : 'all 200ms ease',
    width: fullSize ? '100%' : 280,
    zIndex: formZIndex,
    ...(fullSize
      ? {
          display: visible ? 'block' : 'none',
          height: '100%',
          left: 0,
          top: 0,
        }
      : {
          boxShadow: '0 4px 26px rgba(0, 0, 0, 0.08), 0 1px 14px rgba(0, 0, 0, 0.06)',
          display: isSalesDemoMode && !visible ? 'none' : 'block',
          right: gutter,
          bottom: gutter,
          transform: visible ? undefined : 'translateY(120%)', // Would like to use calc, but that's not possible int transforms in IE10
        }),
  } as const);

export const getTitleButtonStyle = ({ fullSize }: { fullSize: boolean }) =>
  ({
    color: theme.colors.grey1,
    cursor: fullSize ? 'auto' : 'pointer',
    fontWeight: 600,
    lineHeight: '16px',
    msUserSelect: 'none',
    padding: '12px 0 16px',
    textAlign: 'left',
    userSelect: 'none',
  } as const);
