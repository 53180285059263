import { SummaryP } from './common';

export const benignProstateHyperplasiaSummary = (
  <SummaryP first>
    The algorithm will check the patient’s chart for a chronic history of BPH before making a
    diagnosis. It will also collect recorded symptoms and determine whether there is lower urinary
    tract involvement. Regard will gather relevant procedures (digital rectal exams, catheter
    insertions), diagnostic reports (prostate specific antigen), medications, and display them.
  </SummaryP>
);
