import { flatten } from 'lodash';

import { NoteBlock } from '../../../@types/state';

import {
  getDominantTitleSignifierFromChunksWithLines,
  getIsNumberTitleSignifier,
} from '../getDominantTitleSignifier';
import { getDominantTitleStyles } from './getDominantTitleStyles';

export interface CreateNewTitleSignifierParams {
  noteBlocks: Pick<NoteBlock, 'lines' | 'type'>[];
  conditionIndex: number;
}

export const createNewTitleSignifier = (params: CreateNewTitleSignifierParams) => {
  const { noteBlocks, conditionIndex } = params;

  // 1. Get the number of titles preceeding this new condition
  const numberOfPreviousTitles = flatten(
    noteBlocks
      .slice(0, conditionIndex)
      .map(({ lines }) => lines.filter(({ type }) => type === 'title'))
  ).length;

  // 2. Check if other condition titles are bolded
  const titleStyles = getDominantTitleStyles(noteBlocks);

  // 3. Determine the note's title indicator style
  const noteTitleSignifier = getDominantTitleSignifierFromChunksWithLines(noteBlocks);
  const isNumberTitleSignifier = getIsNumberTitleSignifier(noteTitleSignifier);

  // 4. If the title indicator style is a number style, replace with this
  //  number, plus one. Mostly, we need this for editedConditionCaretPos.
  const newConditionTitleSignifier = isNumberTitleSignifier
    ? noteTitleSignifier.replace('1', `${numberOfPreviousTitles + 1}`)
    : noteTitleSignifier;

  return {
    newConditionTitleSignifier,
    noteTitleSignifier,
    isNumberTitleSignifier,
    titleStyles,
  };
};
