import { FC } from 'react';

import { backdropStyle, getCss, svgStyle } from './css';
import { IconButton } from '../../../../reuse';

export const UserFeedbackToast: FC<{
  close(): void;
  fullSize?: boolean;
  visible: boolean;
}> = ({ close, fullSize = false, visible }) => (
  <>
    {visible && fullSize && <div css={backdropStyle} data-user-feedback-toast-backdrop />}
    <div css={getCss({ fullSize, visible })} data-cy-user-feedback-toast data-user-feedback-toast>
      <IconButton
        color="tertiary-grey"
        iconName="x"
        onClick={close}
        size="mini"
        style={{
          position: 'absolute',
          right: 2,
          top: 2,
        }}
      />
      <svg
        fill="none"
        height="20"
        style={svgStyle}
        viewBox="0 0 21 20"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.3393 7.31817L4.33927 0.318175C3.78676 0.0431843 3.16289 -0.0547143 2.55271 0.0378269C1.94252 0.130368 1.37573 0.408845 0.929602 0.835291C0.483474 1.26174 0.179724 1.8154 0.0597636 2.42079C-0.0601964 3.02617 0.00947219 3.65383 0.259271 4.21817L2.65927 9.58818C2.71373 9.71801 2.74177 9.85739 2.74177 9.99818C2.74177 10.139 2.71373 10.2783 2.65927 10.4082L0.259271 15.7782C0.055971 16.2349 -0.0299735 16.7352 0.00924794 17.2335C0.0484693 17.7319 0.211613 18.2126 0.483853 18.6319C0.756092 19.0512 1.1288 19.3958 1.56809 19.6344C2.00739 19.873 2.49935 19.9981 2.99927 19.9982C3.4675 19.9935 3.92876 19.8842 4.34927 19.6782L18.3493 12.6782C18.8459 12.4284 19.2633 12.0455 19.555 11.5723C19.8466 11.099 20.0011 10.5541 20.0011 9.99818C20.0011 9.44228 19.8466 8.89732 19.555 8.4241C19.2633 7.95087 18.8459 7.56799 18.3493 7.31817H18.3393ZM17.4493 10.8882L3.44927 17.8882C3.26543 17.9764 3.059 18.0064 2.85766 17.974C2.65631 17.9416 2.46968 17.8485 2.32278 17.707C2.17589 17.5656 2.07575 17.3826 2.0358 17.1826C1.99585 16.9826 2.018 16.7752 2.09927 16.5882L4.48927 11.2182C4.52021 11.1465 4.54692 11.073 4.56927 10.9982H11.4593C11.7245 10.9982 11.9788 10.8928 12.1664 10.7053C12.3539 10.5177 12.4593 10.2634 12.4593 9.99818C12.4593 9.73296 12.3539 9.47861 12.1664 9.29107C11.9788 9.10353 11.7245 8.99818 11.4593 8.99818H4.56927C4.54692 8.92334 4.52021 8.84988 4.48927 8.77818L2.09927 3.40817C2.018 3.22114 1.99585 3.01372 2.0358 2.81374C2.07575 2.61376 2.17589 2.43078 2.32278 2.28932C2.46968 2.14787 2.65631 2.05471 2.85766 2.02233C3.059 1.98995 3.26543 2.0199 3.44927 2.10818L17.4493 9.10818C17.6131 9.19209 17.7505 9.31959 17.8465 9.47662C17.9425 9.63365 17.9933 9.81413 17.9933 9.99818C17.9933 10.1822 17.9425 10.3627 17.8465 10.5197C17.7505 10.6768 17.6131 10.8043 17.4493 10.8882Z" />
      </svg>
      <div style={{ fontWeight: 600, marginTop: 8 }}>Report Sent</div>
      <div style={{ marginTop: 8 }}>
        Thank you! Our team will address the issue as soon as possible.
      </div>
    </div>
  </>
);
