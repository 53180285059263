import { PreModuleNoteBlock } from '~/app/@types/state';
import { arrayMove } from '../arrayMove';
import { replace } from '../replace';
import { linesToPlainText } from '../linesToPlainText';
import { nth } from '../nth';
import { uniqueId } from '../uniqueId';
import { insert } from '../insert';
import { remove } from '../remove';

type MoveBlockReturn<T> = {
  blocks: T[];
  editedIndex: number;
  movedIntoShelf: boolean;
  movedOutOfShelf: boolean;
};

export const moveBlock = <
  T extends Pick<PreModuleNoteBlock, 'id' | 'lines' | 'text' | 'type'>
>(params: {
  blocks: T[];
  fromIndex: number;
  toIndex: number;
}): MoveBlockReturn<T> => {
  const { blocks, fromIndex, toIndex } = params;

  // I. Moving to same index or missing block
  if (fromIndex === toIndex || !blocks[fromIndex]) {
    return { blocks, editedIndex: toIndex, movedIntoShelf: false, movedOutOfShelf: false };
  }

  // II. Moving from first index and second index is shelfDivider
  if (fromIndex === 0 && nth(blocks, 1)?.type === 'shelfDivider') {
    const movingBlock = blocks[fromIndex];

    const titleIndex = movingBlock.lines.findIndex((line) => line.type === 'title');
    // If a title isn't found, the block only contains pretext, which isn't
    // allowed to be moved. This case should be prevented in the UI.
    if (titleIndex === -1) {
      return { blocks, editedIndex: toIndex, movedIntoShelf: false, movedOutOfShelf: false };
    }

    const pretextLines = movingBlock.lines.slice(0, titleIndex);

    // If there's pretext lines, split the block into pretext and condition
    // text so that only the condition text is moved.
    // Otherwise, fall through to the normal move case.
    if (pretextLines.length) {
      // 1. Create a new condition block with only pretext lines
      const pretextBlock = {
        ...movingBlock,
        lines: pretextLines,
        text: linesToPlainText(pretextLines),
        textTimestamp: Date.now(),
      };

      // 2. Create a new condition block without pretext lines
      const conditionLines = movingBlock.lines.slice(titleIndex);
      const conditionBlock = {
        ...movingBlock,
        type: 'shelvedCondition',
        id: uniqueId(),
        lines: conditionLines,
        text: linesToPlainText(conditionLines),
      };

      // 3. Keep the pretext in place and "move" the condition
      const newBlocksWithPretextBlock = replace(pretextBlock, fromIndex, blocks);
      const newBlocks = insert(newBlocksWithPretextBlock, toIndex + 1, conditionBlock);
      return {
        blocks: newBlocks,
        editedIndex: toIndex + 1,
        movedIntoShelf: true,
        movedOutOfShelf: false,
      };
    }
    // Proceed to III...
  }

  // III. Typical move

  // 1. Compute information about the move
  const isMovingDown = fromIndex < toIndex;
  const fromBlockType = blocks[fromIndex].type;
  const toBlockType = blocks[toIndex].type;

  const isMovingIntoShelf =
    toBlockType === 'shelvedCondition' ||
    // When moving down, shelfDivider index is considered "in the shelf"
    (isMovingDown && toBlockType === 'shelfDivider');

  const newBlockType = isMovingIntoShelf ? 'shelvedCondition' : 'condition';

  const blockTypeChanged = fromBlockType !== newBlockType;
  const movedIntoShelf = blockTypeChanged && newBlockType === 'shelvedCondition';
  const movedOutOfShelf = blockTypeChanged && newBlockType === 'condition';

  // 3. Move the block and update type if necessary
  const movedBlocks = arrayMove(blocks, fromIndex, toIndex);

  if (blockTypeChanged) {
    movedBlocks[toIndex] = {
      ...movedBlocks[toIndex],
      type: newBlockType,
    };
  }

  // 4. Delete next block if exists and empty
  // This happens when we have a pretext only condition right above the shelf
  //   divider and the user moves a shelved condition to the top of the note
  const nextBlock = nth(movedBlocks, toIndex + 1);

  const filledBlocks =
    nextBlock && !movedBlocks[toIndex + 1].text ? remove(toIndex + 1, 1, movedBlocks) : movedBlocks;

  return { blocks: filledBlocks, editedIndex: toIndex, movedIntoShelf, movedOutOfShelf };
};
