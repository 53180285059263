import { CSSProperties, FC, memo } from 'react';
import { memoize } from 'lodash';

import { suggestionCriticalFlagSvg, noteCriticalFlagSvg, dxDetailsCriticalFlagSvg } from './icons';

const getFlagStyle = memoize(
  (left: number) =>
    ({
      position: 'absolute',
      left,
      height: '100%',
      overflow: 'hidden',
      pointerEvents: 'none',
    } as CSSProperties)
);

export enum SvgStyle {
  // 20px tall
  Note = 'inNote',
  // 24px tall
  Suggestion = 'suggestion',
  // 29px tall
  DxDetails = 'dxDetails',
}

interface ConditionLineCriticalFlagProps {
  left: number;
  svgStyle: SvgStyle;
}

export const ConditionLineCriticalFlag: FC<ConditionLineCriticalFlagProps> = memo(
  ({ left, svgStyle }: ConditionLineCriticalFlagProps) => (
    <div data-cy-condition-line-critical-flag style={getFlagStyle(left)}>
      {svgStyle === SvgStyle.Note && noteCriticalFlagSvg}
      {svgStyle === SvgStyle.Suggestion && suggestionCriticalFlagSvg}
      {svgStyle === SvgStyle.DxDetails && dxDetailsCriticalFlagSvg}
    </div>
  )
);
