import { FC } from 'react';

import { TitledSmartAccordion } from '~/app/reuse/titledSmartAccordion';
import { useDxDetailTable } from './useDxDetailTable';
import { ComplaintTable } from '../complaintTable';

export const DxDetailTable: FC<{ module: string }> = ({ module }) => {
  const { meds, tableData } = useDxDetailTable(module);

  const showTable = Object.keys(tableData?.series ?? {}).length || meds.length;

  return showTable ? (
    <TitledSmartAccordion openByDefault title="Table">
      <ComplaintTable meds={meds} module={module} tableData={tableData} />
    </TitledSmartAccordion>
  ) : null;
};
