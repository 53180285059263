import { SummaryUl, SummaryOl } from './common';

export const respiratoryFailureSummary = (
  <SummaryOl>
    <li>
      Regard will gather and diagnose based on available laboratory and vital data within the
      current encounter. For a positive diagnosis the patient needs to be on supplemental oxygen (
      {'>'} 2 L/min by nasal cannula) and have one additional sign including hypoxemia, hypercapnia,
      tachypnea, or bradypnea.
      <SummaryUl innerList>
        <li>
          hypoxemia: SpO2 {'<'} 91% OR pO2 {'<'} 60 mmHg on ABG
        </li>
        <li>
          hypercapnia: pCO2 {'>'} 50 mmHg AND pH {'<'} 7.35 on VBG or ABG
        </li>
        <li>
          respiratory rate (RR) criteria: RR {'>'} 20 OR RR {'<'} 10
        </li>
      </SummaryUl>
    </li>
    <li>
      Regard will also search notes from the current encounter to determine if the clinician has
      made a diagnosis of respiratory failure.
    </li>
    <li>
      Regard will attempt to determine whether the respiratory failure is hypoxemic, hypercapnic, or
      mixed based on the criteria above. If the patient meets criteria for both hypoxemic and
      hypercapnic, Regard will diagnose mixed hypoxemic and hypercapnic respiratory failure.
    </li>
    <li>
      Regard will then determine acuity. If diagnosis is based only on labs within the current
      encounter, chronicity is &quot;acute&quot;. If based on prior notes the patient has a history
      of chronic respiratory failure, then the chronicity is &quot;chronic&quot;. If they have both
      a history of chronic respiratory failure and current labs and vitals suggesting an acute
      component, the chronicity is &quot;acute-on-chronic&quot;.
    </li>
  </SummaryOl>
);
