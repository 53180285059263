import { shallowEqual } from 'fast-equals';
import { noop } from 'lodash';
import { FC, createContext, memo, useCallback, useEffect } from 'react';

import { Keyword } from '~/app/@types/state';
import {
  createSelectorProvider,
  interpretationIsCritical,
  omit,
  useBooleanState,
  useContextSelector,
} from '~/app/utils';

import { track } from '~/app/analytics';
import { Suggestion } from '~/app/@types';
import { useConditionModules } from '../conditionModules';
import { LineWithHeaderData, useConditionLines } from './useConditionLines';
import {
  useOpenDxDetailsFromNote,
  useSetLastClickedKeywordModule,
} from './useOpenDxDetailsFromNote';
import { useNoteBlockContext } from '../noteBlockProvider';
import { useConditionSuggestions } from './useCondititionSuggestions';

type ConditionContext = {
  hasNonCriticalSuggestions: boolean;
  id: string;
  isCriticalCondition: boolean;
  isFirstCondition: boolean;
  isFirstNewCondition: boolean;
  isLastNoteBlock: boolean;
  isNewCondition: boolean;
  isShelvedCondition: boolean;
  keywords: Keyword[];
  lines: LineWithHeaderData[];
  modules: string[];
  openDxDetailsFromNote(args: {
    location: 'titleButton' | 'titleKeyword';
    selectedModule: string;
  }): void;
  setLastClickedKeywordModule(args: { moduleClicked: string }): void;
  suggestions: Suggestion[];
  suggestionsAreMinimized: boolean;
  toggleSuggestionsMinimized(): void;
};

const defaultConditionContext: ConditionContext = {
  hasNonCriticalSuggestions: false,
  id: '',
  isCriticalCondition: false,
  isFirstCondition: false,
  isFirstNewCondition: false,
  isLastNoteBlock: false,
  isNewCondition: false,
  isShelvedCondition: false,
  keywords: [],
  lines: [],
  modules: [],
  openDxDetailsFromNote: noop,
  setLastClickedKeywordModule: noop,
  suggestions: [],
  suggestionsAreMinimized: false,
  toggleSuggestionsMinimized: noop,
};

const ConditionContext = createContext<ConditionContext>(defaultConditionContext);
const ConditionContextProvider = createSelectorProvider(ConditionContext);

export const useConditionContext = <T,>(selector: (context: ConditionContext) => T): T =>
  useContextSelector<ConditionContext, T>(ConditionContext, selector);

export const ConditionProvider: FC<
  Pick<
    ConditionContext,
    | 'id'
    | 'isFirstCondition'
    | 'isFirstNewCondition'
    | 'isLastNoteBlock'
    | 'isNewCondition'
    | 'isCriticalCondition'
  >
> = memo(
  ({
    children,
    id,
    isFirstCondition,
    isFirstNewCondition,
    isLastNoteBlock,
    isNewCondition,
    isCriticalCondition,
  }) => {
    const { index, noteBlockRef, type } = useNoteBlockContext(({ index, noteBlockRef, type }) => ({
      index,
      noteBlockRef,
      type,
    }));

    const { lines, keywords } = useConditionLines({ id, isFirstCondition });

    const modules = useConditionModules(id);

    const openDxDetailsFromNote = useOpenDxDetailsFromNote({
      conditionId: id,
      conditionRef: noteBlockRef,
      modules,
    });

    const setLastClickedKeywordModule = useSetLastClickedKeywordModule();

    // Shelved
    const isShelvedCondition = type === 'shelvedCondition';

    const [
      suggestionsAreMinimized,
      { setTrue: minimizeSuggestions, toggle: toggleSuggestionsMinimized },
    ] = useBooleanState(isShelvedCondition);

    const toggleSuggestionsMinimizedWithTracking = useCallback(() => {
      track.clickedToggleDxSuggestionsButton({
        atIndex: index,
        modules,
        // suggestionsAreMinimized is being toggled so it matches the "expanding" negative already
        isExpanding: suggestionsAreMinimized,
      });
      toggleSuggestionsMinimized();
    }, [index, modules, suggestionsAreMinimized, toggleSuggestionsMinimized]);

    useEffect(() => {
      // This should capture the state where the user is shelving a condition (or unshelving, but that doesn't matter)
      if (isShelvedCondition) {
        minimizeSuggestions();
      }
    }, [isShelvedCondition, minimizeSuggestions]);

    // Suggestions
    const suggestions = useConditionSuggestions(id);

    // return
    return (
      <ConditionContextProvider
        value={{
          hasNonCriticalSuggestions: !!suggestions.some(
            (suggestion) => !interpretationIsCritical(suggestion.interpretation)
          ),
          id,
          isCriticalCondition,
          isFirstCondition,
          isFirstNewCondition,
          isLastNoteBlock,
          isNewCondition,
          isShelvedCondition,
          keywords,
          lines,
          modules,
          openDxDetailsFromNote,
          setLastClickedKeywordModule,
          suggestions,
          suggestionsAreMinimized,
          toggleSuggestionsMinimized: toggleSuggestionsMinimizedWithTracking,
        }}
      >
        {children}
      </ConditionContextProvider>
    );
  },
  /**
   * This is an optimization.
   *
   * We prevent children from triggering a rerender because children will
   *  always trigger a rerender--it defeats the memo function and is not useful
   *  in this respect. If we want the child of ConditionProvider to rerender,
   *  assuming that this child is Condition, we should expect a prop on
   *  ConditionProvider to change.
   *
   */
  (prevProps, nextProps) => shallowEqual(omit(prevProps, 'children'), omit(nextProps, 'children'))
);
ConditionProvider.displayName = 'ConditionProvider';
