import _ from 'lodash';

import { getInputBaseCss } from '../labeledInput/css';

const paddingTop = 20;
const paddingBottom = 12;
const paddingX = 12;

export const getTextareaCss = _.memoize(
  ({ error, isFocused }: { error: boolean | string | undefined; isFocused: boolean }) =>
    ({
      ...getInputBaseCss({ error }),
      borderWidth: isFocused ? 2 : 1,
      overflow: 'auto',
      padding: isFocused
        ? `${paddingTop - 1}px ${paddingX - 1}px ${paddingBottom - 1}px`
        : `${paddingTop}px ${paddingX}px ${paddingBottom}px`,
    } as const)
);
