import { useCallback } from 'react';

import { selectDxDetailsTab } from '../../../actions/ui';
import { useDispatch } from '../../../store';
import { track } from '../../../analytics';

export const useSelectDxDetailsTab = (): ((args: { selectedModule: string }) => void) => {
  const dispatch = useDispatch();

  const result = useCallback(
    ({ selectedModule }: { selectedModule: string }) => {
      track.selectedDxDetailsTab({ selectedModule });

      dispatch(selectDxDetailsTab({ selectedModule }));
    },
    [dispatch]
  );

  return result;
};
