import { useMemo } from 'react';
import { parse } from 'date-fns';

import { APITableDataSeries, ComplaintTableData } from '~/app/@types/state';

import { TableOption, TableOptions } from './getInitialTableState';
import { getSeriesKey } from './getSeriesKey';
import { formatDatetime } from './format';

export type DataMapValue = { [x: string]: string | Date; dateObject: Date };

/**
 * This function is the logical "guts" of the Complaint Table tree.
 * Here we take the state (which is generated from the props) and
 * generate a data map with all the dates & data points for rendering
 * the data table.
 */
const convertTableDataToMaps = (
  tableData: ComplaintTableData | undefined,
  module: string,
  timestamp: string | null,
  labsOptions: TableOptions,
  medicationOptions: TableOptions,
  vitalsOptions: TableOptions
) => {
  const vitalsLabels: string[] = [];
  const labsLabels: string[] = [];
  const rawSeriesIds: string[] = [];
  Object.keys(vitalsOptions).forEach((dataTitle) => {
    if (vitalsOptions[dataTitle].show) {
      vitalsLabels.push(dataTitle);
      rawSeriesIds.push(vitalsOptions[dataTitle].seriesId ?? '');
    }
  });
  Object.keys(labsOptions).forEach((dataTitle) => {
    if (labsOptions[dataTitle].show) {
      labsLabels.push(dataTitle);
      rawSeriesIds.push(labsOptions[dataTitle].seriesId ?? '');
    }
  });
  const seriesIds = Array.from(new Set(rawSeriesIds));

  /* Second we use the seriesIds to aggregate the data points into a format like:
   * {
   *   '06/23/2016 5:00:00': {
   *     'TSH (mcU/mL)': 20,
   *   },
   *   '06/24/2016 03:00:00': {
   *     'TSH (mcU/mL)': 4,
   *     'Free T4 (ng/dL)': 0.1,
   *   },
   * } */
  const timelineDataMap: Record<string, APITableDataSeries> = {};
  const dataMap: Record<string, DataMapValue> = {};
  seriesIds.forEach((seriesId) => {
    if (!tableData) return;
    const seriesData = tableData.series[seriesId];
    if (!seriesData) return;

    const key = getSeriesKey(seriesData);
    timelineDataMap[key] = seriesData;
    seriesData.data.forEach((dataPoint) => {
      const [dateString, valueString] = dataPoint;
      const datetime = formatDatetime(parse(dateString));

      if (dataMap[datetime]) {
        // Cannot render multiple lab/vital values with identical timestamp in DxDetails Table
        // Overwrite first value with second value
        dataMap[datetime][key] = valueString;
      } else {
        dataMap[datetime] = {
          dateObject: new Date(dateString),
          [key]: valueString,
        };
      }
    });
  });

  const medData: Record<string, TableOption> = {};
  Object.keys(medicationOptions).forEach((key) => {
    const med = medicationOptions[key];
    medData[key] = med;
    if (med.show) {
      const orderedTime = formatDatetime(med.ordered);
      const startTime = formatDatetime(med.start);
      const stopTime = formatDatetime(med.stop);

      if (!dataMap[orderedTime]) dataMap[orderedTime] = { dateObject: med.ordered ?? new Date() };

      if (med.stop && !dataMap[stopTime]) dataMap[stopTime] = { dateObject: med.stop };
      if (med.start && !dataMap[startTime] && !med.isOnce?.()) {
        dataMap[startTime] = { dateObject: med.start };
      }
    }
  });

  const sortedDatetimes = Object.keys(dataMap)
    .map((d) => dataMap[d].dateObject)
    .filter((d) => d < new Date(timestamp || ''))
    .sort((a, b) => a.getTime() - b.getTime())
    .reverse();

  return {
    dataMap,
    sortedDatetimes,
    vitalsLabels,
    labsLabels,
    timelineDataMap,
    medData,
  };
};

export const useTableData = (
  tableData: ComplaintTableData | undefined,
  module: string,
  timestamp: string | null,
  labsOptions: TableOptions,
  medicationOptions: TableOptions,
  vitalsOptions: TableOptions
) => {
  const values = useMemo(
    () =>
      convertTableDataToMaps(
        tableData,
        module,
        timestamp,
        labsOptions,
        medicationOptions,
        vitalsOptions
      ),
    [tableData, module, timestamp, labsOptions, medicationOptions, vitalsOptions]
  );
  return values;
};
