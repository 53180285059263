import _ from 'lodash';

import { CSS, theme } from '../../../reuse';
import { NOTE_CONTAINER_HEADER_HEIGHT } from '../noteContainerHeader/css';

const ABBR_NOTE_TYPE_WIDTH = 38;
const CELL_GUTTER = 16;
const NOTE_AUTHOR_WIDTH = 100;
export const SELECT_BASE_NOTE_DROPDOWN_HEIGHT = 33;
const SVG_SIZE = 14;
const SVG_GUTTER = 6;
const TIMESTAMP_WIDTH = 100;

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
} as const;

export const dropdownStyle = {
  backgroundColor: theme.colors.white,
  borderTop: `1px solid ${theme.colors.grey4}`,
  bottom: 0,
  left: 0,
  height: `calc(${SELECT_BASE_NOTE_DROPDOWN_HEIGHT}vh - ${NOTE_CONTAINER_HEADER_HEIGHT / 2}px)`,
  overflow: 'auto',
  position: 'absolute',
  transform: 'translateY(100%)',
  width: '100%',
} as const;

export const getdropdownStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...dropdownStyle,
      padding: isScaledHorizontalLayout ? '0px calc(50% - 336px)' : undefined,
    } as const)
);

export const dropdownOpenStyle = {
  ...dropdownStyle,
} as const;

export const getdropdownOpenStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...dropdownStyle,
      padding: isScaledHorizontalLayout ? '0px calc(50% - 336px)' : undefined,
    } as const)
);

export const dropdownClosedStyle = {
  ...dropdownStyle,
  display: 'none',
} as const;

export const getdropdownClosedStyle = _.memoize(
  (isScaledHorizontalLayout: boolean) =>
    ({
      ...dropdownStyle,
      padding: isScaledHorizontalLayout ? '0px calc(50% - 336px)' : undefined,
      display: 'none',
    } as const)
);

export const dropdownRowInnerStyle = {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
} as const;

export const timestampCellStyle = {
  display: 'inline-block',
  marginRight: CELL_GUTTER,
  width: TIMESTAMP_WIDTH,
} as const;

export const abbrNoteTypeCellStyle = {
  display: 'inline-block',
  marginRight: CELL_GUTTER,
  width: ABBR_NOTE_TYPE_WIDTH,
} as const;

export const authorCellStyle = {
  ...ellipsisStyle,
  display: 'inline-block',
  marginBottom: -6,
  marginRight: CELL_GUTTER,
  width: NOTE_AUTHOR_WIDTH,
} as const;

export const statusCellStyle = {
  display: 'inline-block',
  width: `calc(100% - ${
    SVG_SIZE +
    SVG_GUTTER +
    TIMESTAMP_WIDTH +
    CELL_GUTTER +
    ABBR_NOTE_TYPE_WIDTH +
    CELL_GUTTER +
    NOTE_AUTHOR_WIDTH +
    CELL_GUTTER
  }px)`,
} as const;

export const getRowCss = _.memoize(
  ({ current, opacity }: { current: boolean; opacity?: number }): CSS => {
    const color = current ? theme.colors.grey4 : theme.colors.grey1;
    const hover = current
      ? {
          backgroundColor: theme.colors.grey6,
        }
      : {
          color: theme.colors.blueMedium,
          backgroundColor: theme.colors.lightBlue2,
        };
    const active = current
      ? {
          color: theme.colors.grey3,
          backgroundColor: theme.colors.grey5,
        }
      : {
          color: theme.colors.blueDark,
          backgroundColor: theme.colors.lightBlue3,
        };

    return {
      color,
      cursor: 'pointer',
      height: 32,
      opacity, // this doesn't do anything but make test runs between `yarn test` and `yarn test:coverage` consistent
      paddingLeft: 24,
      paddingRight: 24,
      whiteSpace: 'nowrap',
      '&:hover': hover,
      '&:active': active,
      '& svg': {
        fill: 'currentColor',
        height: SVG_SIZE,
        position: 'relative',
        marginRight: SVG_GUTTER,
        top: 2,
        width: SVG_SIZE,
      },
    };
  }
);

export const previousEncountersContainerStyle = {
  backgroundColor: theme.colors.grey6,
} as const;

export const previousEncountersHeaderStyle = {
  position: 'relative',
  '&:before': {
    display: 'block',
    content: '""',
    borderTop: '1px solid #9098A7',
    top: '50%',
    width: '100%',
    height: '1px',
    position: 'absolute',
    zIndex: 1,
  },
} as const;

export const previousEncountersTitleStyle = {
  backgroundColor: theme.colors.grey6,
  marginLeft: '20px',
  paddingLeft: '8px',
  paddingRight: '8px',
  color: theme.colors.grey2,
  fontWeight: '700',
  fontSize: '10px',
  lineHeight: '20px',
  textTransform: 'uppercase',
  zIndex: 2,
  position: 'relative',
} as const;
