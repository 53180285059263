import { ReactElement, useState } from 'react';

import { CernerTab, CernerTabProps } from './CernerTab';
import { cernerTheme } from './cernerTheme';
import { Icon } from '../../../reuse';

export const CernerTabs = ({ children }: { children: ReactElement<CernerTabProps>[] }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabsProps = children.map(({ props }, i) => ({
    ...props,
    isSelected: i === selectedTabIndex,
    onClick: () => setSelectedTabIndex(i),
  }));

  const { children: content, label, style } = children[selectedTabIndex].props;

  return (
    <div
      style={{
        backgroundColor: cernerTheme.colors.darkishBlue,
        display: 'flex',
        flex: '1 1 auto',
        minHeight: 0,
      }}
    >
      <div
        style={{
          backgroundColor: cernerTheme.colors.darkishBlue,
          flex: '0 0 auto',
          height: '100%',
          overflow: 'auto',
          width: 280,
        }}
      >
        <div
          css={{
            alignItems: 'center',
            backgroundColor: cernerTheme.colors.darkBlue,
            color: cernerTheme.colors.white,
            display: 'flex',
            fontWeight: 600,
            height: 32,
            padding: '0 32px',
            position: 'relative',
            width: '100%',
          }}
        >
          <Icon
            iconName="ellipsisVertical"
            style={{
              left: 17,
              position: 'absolute',
              top: 8,
            }}
          />
          Menu
        </div>
        {tabsProps.map((tabProps, i) => (
          <CernerTab key={i} {...tabProps} />
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: 0,
          minWidth: 0,
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: cernerTheme.colors.darkishBlue,
            color: cernerTheme.colors.white,
            display: 'flex',
            flex: '0 0 auto',
            fontWeight: 600,
            height: 32,
            padding: '0 20px',
          }}
        >
          {label}
        </div>
        <div
          data-cy-demo-tab-content
          style={{
            backgroundColor: cernerTheme.colors.white,
            flex: '1 1 auto',
            overflow: 'auto',
            padding: '40px 20px',
            ...style,
          }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};
