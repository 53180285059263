import { FC } from 'react';
import { ButtonTag, Icon } from '~/app/reuse';

import { MenuItem } from './types';
import {
  currentItemStyle,
  menuItemDividerLineStyle,
  menuItemDividerNameStyle,
  menuItemDividerStyle,
  menuItemNameStyle,
  moveButton,
} from './css';

interface MoveMenuItemProps extends MenuItem {
  index: number;
  onPlacementClick?: (index: number) => void;
}

const PlacementButton: FC<Pick<MoveMenuItemProps, 'index' | 'onPlacementClick'>> = ({
  index,
  onPlacementClick,
}) => (
  <ButtonTag
    css={moveButton}
    onClick={() => {
      onPlacementClick?.(index);
    }}
  >
    <Icon iconName="arrowRight" />
    <span>Click to place here</span>
  </ButtonTag>
);

const MenuItemLabel: FC<Pick<MoveMenuItemProps, 'name'>> = ({ name }) =>
  name ? (
    <div style={menuItemNameStyle} title={name}>
      {name}
    </div>
  ) : null;

const MenuItemDivider: FC<Pick<MoveMenuItemProps, 'name'>> = ({ name }) => (
  <div data-cy-move-menu-divider style={menuItemDividerStyle}>
    <div style={menuItemDividerLineStyle} />
    <div style={menuItemDividerNameStyle}>{name}</div>
  </div>
);

export const MoveMenuItem: FC<MoveMenuItemProps> = (props: MoveMenuItemProps) => {
  const { index, name, menuStyle, onPlacementClick } = props;

  return (
    <>
      {menuStyle === 'divider' && <MenuItemDivider name={name} />}
      {menuStyle === 'label' && <MenuItemLabel name={name} />}
      {onPlacementClick && <PlacementButton index={index} onPlacementClick={onPlacementClick} />}
    </>
  );
};

export const MoveMenuCurrentItem: FC<MenuItem> = ({ name }) => (
  <div style={currentItemStyle} title={name}>
    {name}
  </div>
);
