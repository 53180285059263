import { FC } from 'react';

import { IconButton, StaticTooltip } from '../../../../reuse';
import { useSelector } from '../../../../store';
import { buttonTooltipStyle, iconStyle } from '../css';

const useCanUndo = (): boolean => useSelector((state) => state.undo.canUndo);

export const UndoButton: FC<{
  disabled: boolean;
  onUndoClick(): void;
}> = ({ disabled, onUndoClick }) => {
  const canUndo = useCanUndo();
  const undoDisabled = disabled || !canUndo;

  return (
    <StaticTooltip
      disabled={undoDisabled}
      placement="bottom"
      style={buttonTooltipStyle}
      tooltip="Undo"
    >
      <IconButton
        color="tertiary-grey"
        data-cy-undo-button
        disabled={undoDisabled}
        iconName="undo"
        onClick={onUndoClick}
        size="small"
        style={iconStyle}
      />
    </StaticTooltip>
  );
};
