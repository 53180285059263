import { FC } from 'react';

import { DemoProvider } from './DemoProvider';
import { DemoSwitcher } from './DemoSwitcher';

export const Demo: FC = () => (
  <DemoProvider>
    <DemoSwitcher />
  </DemoProvider>
);
