import { get, getIsModuleShelved } from '~/app/utils';
import { BulletNoteStatusRecord } from '../@types/state';
import { BulletNoteStatus } from '../@types';

import { captureExceptionWithContext, track } from '../analytics';
import { BulletNoteStatusError } from '../errors';

const KEY_SEPARATOR = '.';

const bulletNoteStatusKeyIsCondition = (key: string) => key.split(KEY_SEPARATOR).length === 1;

// key should be in the format "conditionModule" or "conditionModule.bulletId"
// a key in the "conditionModule" format represents the bullet_note_status record for an entire condition ex: "kidney_disease"
// a key in the "conditionModule.bullet_id" format represents a bullet bullet_note_status record, ex: "kidney_disease.recent_scr"
export const getBulletNoteStatusState = (
  key: string,
  bulletNoteStatusRecord: BulletNoteStatusRecord
): BulletNoteStatus => {
  const state = get(bulletNoteStatusRecord, key);
  if (state) return state;

  // if we cannot find the state in the records, return the default state
  // default state for a conditionModule is "noted", "monitored" for a bullet
  return bulletNoteStatusKeyIsCondition(key) ? BulletNoteStatus.Noted : BulletNoteStatus.Monitored;
};

// helper function to filter "noted" conditions and bullets
export const isNoted = (key: string, bulletNoteStatusRecord: BulletNoteStatusRecord): boolean =>
  getBulletNoteStatusState(key, bulletNoteStatusRecord) === BulletNoteStatus.Noted;

// helper function to filter "monitored" bullets. conditions cannot be monitored
export const isMonitored = (
  key: string,
  bulletNoteStatusRecord: BulletNoteStatusRecord
): boolean => {
  if (bulletNoteStatusKeyIsCondition(key)) return false;
  return getBulletNoteStatusState(key, bulletNoteStatusRecord) === BulletNoteStatus.Monitored;
};

// helper function to filter "hidden" conditions and bullets
export const isHidden = (key: string, bulletNoteStatusRecord: BulletNoteStatusRecord): boolean =>
  getBulletNoteStatusState(key, bulletNoteStatusRecord) === BulletNoteStatus.Hidden;

// helper function to filter dismissed conditions (non-negative and hidden)
export const isDismissed = (
  key: string,
  bulletNoteStatusRecord: BulletNoteStatusRecord,
  isNegative: boolean
): boolean => isHidden(key, bulletNoteStatusRecord) && !isNegative;

// helper function to filter shelved conditions
export const isShelved = (key: string, bulletNoteStatusRecord: BulletNoteStatusRecord): boolean =>
  getBulletNoteStatusState(key, bulletNoteStatusRecord) === BulletNoteStatus.Shelved;

// helper function to determine if a bullet should NOT be included in editor text
export const isOmitted = (key: string, bulletNoteStatusRecord: BulletNoteStatusRecord): boolean =>
  getBulletNoteStatusState(key, bulletNoteStatusRecord) !== BulletNoteStatus.Noted;

// helper function to iterate through calculated bullet_note_status changes and track them
export const trackBulletNoteStatusUpdates = (
  bulletNoteStatusUpdates: BulletNoteStatusRecord,
  staleBulletNoteStatus: BulletNoteStatusRecord
) => {
  Object.entries(bulletNoteStatusUpdates).forEach(
    ([bulletNoteStatusId, newBulletNoteStatusState]) => {
      const [module, bulletId] = bulletNoteStatusId.split('.');
      const bulletNoteStatusStateChange = module && bulletId;
      const uiElement = 'textarea';

      if (bulletNoteStatusStateChange) {
        // The setTimeout is necessary to ensure getIsModuleShelved is called after
        //  the redux state has been updated and not during. Another way would be to
        //  pass the `inShelf` status as an argument to this function.
        setTimeout(() => {
          const inShelf = getIsModuleShelved(module);

          if (newBulletNoteStatusState === BulletNoteStatus.Noted) {
            track.bulletAdded({ inShelf, module, bulletId, uiElement });
          } else if (newBulletNoteStatusState === BulletNoteStatus.Hidden) {
            track.bulletHidden({ inShelf, module, bulletId, uiElement });
          } else if (newBulletNoteStatusState === BulletNoteStatus.Monitored) {
            track.bulletEditedOrDeleted({ inShelf, module, bulletId });
          }
        }, 0);
      } else if (newBulletNoteStatusState === BulletNoteStatus.Hidden) {
        const conditionStatus = get(staleBulletNoteStatus, bulletNoteStatusId);
        const inShelf = conditionStatus === BulletNoteStatus.Shelved;

        track.conditionDismissed({ module, uiElement, dismissedFromShelf: inShelf });
      } else if (newBulletNoteStatusState === BulletNoteStatus.Noted) {
        track.conditionRestored({ module, uiElement });
      }
    }
  );
};

/* Integrates bullet note status updates from note indexing into `BulletNoteStatus`
 * object. NOTE: This function returns staleBulletNoteStatus if there are no
 * updates in BulletNoteStatus */
export const updateBulletNoteStatusState = (
  bulletNoteStatusUpdates: BulletNoteStatusRecord,
  staleBulletNoteStatus: BulletNoteStatusRecord
): BulletNoteStatusRecord => {
  if (Object.keys(bulletNoteStatusUpdates).length === 0) {
    // if there are no updates we want to return the staleBulletNoteStatus to inform React
    // there is no change in BulletNoteStatus
    return staleBulletNoteStatus;
  }

  return { ...staleBulletNoteStatus, ...bulletNoteStatusUpdates };
};

// helper function to catch and log failed BulletNoteStatus API requests
const bulletNoteStatusCatch = (error: Error): void => {
  const extraScope = { error };
  captureExceptionWithContext(
    new BulletNoteStatusError('Failed to Update BulletNoteStatus'),
    extraScope
  );
};

// API function to persist BulletNoteStatus to DB
export const persistBulletNoteStatus = (
  encounterId: string,
  bulletNoteStatusRecord: BulletNoteStatusRecord,
  negativeDiagnosisSet: Set<string>
): Promise<void> | null => {
  if (!encounterId) throw Error('No Encounter ID available for bullet_note_status');

  const params = new URLSearchParams();
  params.set('enc', encounterId);

  const fetchOptions = {
    method: 'POST',
    credentials: 'include',
    body: new FormData(),
  } as const;

  const bulletNoteStatusEntries = Object.entries(bulletNoteStatusRecord);
  if (bulletNoteStatusEntries.length === 0) return null;

  bulletNoteStatusEntries.forEach(([key, bulletNoteStatusState]) => {
    // Here we filter out negative conditions since negative conditions
    // are only here for Live-DX. We want to exempt Live-DX'd conditions from
    // the BulletNoteStatus system.
    if (!negativeDiagnosisSet.has(key)) {
      fetchOptions.body.append(key, bulletNoteStatusState);
    }
  });

  return fetch(`/api/setBulletNoteStatus?${params.toString()}`, fetchOptions)
    .then((response) => {
      if (!response.ok || response.status !== 200) {
        // Try one more time
        fetch(`/api/setBulletNoteStatus?${params.toString()}`, fetchOptions).catch(
          bulletNoteStatusCatch
        );
      }
    })
    .catch(bulletNoteStatusCatch);
};

// Function to persist store.getState().regardNote.bulletNoteStatus changes to the DB
export const persistBulletNoteStatusDiffs = (
  newBulletNoteStatusRecord: BulletNoteStatusRecord,
  oldBulletNoteStatusRecord: BulletNoteStatusRecord,
  encounterId: string,
  negativeDiagnosisSet: Set<string>
): Promise<void> | null => {
  const bulletNoteStatusRecordDiffs: BulletNoteStatusRecord = {};
  const newBulletNoteStatusEntries = Object.entries(newBulletNoteStatusRecord);

  // loop through the newBulletNoteStatusEntries and persist differences to the server
  // here we DON'T use getBulletNoteStatus state because if oldBulletNoteStatus[key] is undefined we want to make a DB record
  newBulletNoteStatusEntries.forEach(([key, bulletNoteStatusState]) => {
    if (bulletNoteStatusState !== oldBulletNoteStatusRecord[key])
      bulletNoteStatusRecordDiffs[key] = bulletNoteStatusState;
  });

  return Object.keys(bulletNoteStatusRecordDiffs).length > 0
    ? persistBulletNoteStatus(encounterId, bulletNoteStatusRecordDiffs, negativeDiagnosisSet)
    : null;
};
