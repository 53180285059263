import { flatten } from 'lodash';

import { isFooterChunk } from '~/app/utils';
import { useNumbersInsteadOfHashtagsWhenAppendingDxes } from '../../../flags';
import {
  DEFAULT_TITLE_SIGNIFIER,
  DEFAULT_NUMBER_TITLE_SIGNIFIER,
  DEFAULT_HASH_TITLE_SIGNIFIER,
} from '../../regex';
import { ChunkOfLines, MeshedNoteTitleHtmlLine, TitleSignifier } from '../../../@types/state';

// This function expects strings like:
//
//   "1."
//
// and NOT strings like:
//
//   "1. COVID-19"
//
export const getDominantTitleSignifier = (titleSignifiers: string[]): TitleSignifier => {
  const filledSignifiers = titleSignifiers.filter(Boolean);
  const filledSignifiersCount = filledSignifiers.length;

  if (filledSignifiersCount) {
    let signifiersWithHash = 0;
    let signifiersWithNumber = 0;
    let signifiersWithParenthesis = 0;
    let signifiersWithDot = 0;

    filledSignifiers.forEach((signifier) => {
      if (signifier.includes('#')) signifiersWithHash += 1;
      if (signifier.match(/\d/)) signifiersWithNumber += 1;
      if (signifier.includes(')')) signifiersWithParenthesis += 1;
      if (signifier.includes('.')) signifiersWithDot += 1;
    });

    const halfSignifiersCount = Math.ceil(filledSignifiersCount / 2); // Half, rounded-up
    const hasNumber = signifiersWithNumber >= halfSignifiersCount;
    const hasHash = signifiersWithHash >= halfSignifiersCount;
    const hasParen = signifiersWithParenthesis >= halfSignifiersCount;
    const hasDot = signifiersWithDot >= halfSignifiersCount;

    if (hasNumber) {
      // Note that we always return a number with a ) or .
      if (signifiersWithParenthesis >= signifiersWithDot) {
        return hasHash ? '#1)' : '1)';
      }
      return hasHash ? '#1.' : '1.';
    }

    if (hasHash) {
      if (hasParen) {
        return '#)';
      }
      if (hasDot) {
        return '#.';
      }
      return '#';
    }
  }

  // There is no dominant pattern or no title bullets to analyze.
  return useNumbersInsteadOfHashtagsWhenAppendingDxes
    ? DEFAULT_NUMBER_TITLE_SIGNIFIER
    : DEFAULT_TITLE_SIGNIFIER;
};

export const getDominantTitleSignifierFromChunksWithLines = (
  chunksWithLines: ChunkOfLines[]
): TitleSignifier => {
  const titleLines = flatten(
    chunksWithLines.map((chunk) =>
      (chunk.type === 'condition' || chunk.type === 'shelvedCondition') && !isFooterChunk(chunk)
        ? (chunk.lines.filter(({ type }) => type === 'title') as MeshedNoteTitleHtmlLine[])
        : []
    )
  );
  const titleSignifiers = titleLines.map(({ titleSignifier }) => titleSignifier);
  return getDominantTitleSignifier(titleSignifiers);
};

export const getIsNumberTitleSignifier = (titleSignifier: TitleSignifier): boolean =>
  titleSignifier.includes('1');

export const getTitleSignifierForFormat = (format: 'hash' | 'number'): TitleSignifier => {
  if (format === 'hash') return DEFAULT_HASH_TITLE_SIGNIFIER;
  if (format === 'number') return DEFAULT_NUMBER_TITLE_SIGNIFIER;
  return DEFAULT_TITLE_SIGNIFIER;
};
