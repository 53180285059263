// import { saveAs } from 'file-saver';
import { flatten } from 'lodash';

export const getHtmlBlob = (): Blob => {
  const scrollMarker = document.querySelector<HTMLElement>('[data-note-container-scroll-marker]');
  const scrollWrapper = document.querySelector<HTMLElement>('[data-note-container-scroll-wrapper]');

  // ////
  // A //
  // ////
  //
  // Add reference marker to indicate which condition the user was scrolled to.
  if (scrollMarker && scrollWrapper) {
    scrollMarker.style.display = 'block';
    scrollMarker.style.top = `${scrollWrapper.scrollTop}px`;
  }

  // ////
  // B //
  // ////
  //
  // 1. Add back some inline styles for textareas that get dropped by IE.
  // 2. And hide the feedback form.
  //
  const head = document.querySelector('head');
  const style = document.createElement('style');
  if (head) {
    style.innerHTML = `
textarea { background: none; resize: none; }
[data-user-feedback-toast-backdrop],
[data-user-feedback-toast],
[data-user-feedback-form] { display: none; }
html { width: ${window.innerWidth}px; height: ${window.innerHeight}px }`;
    head.appendChild(style);
  }

  // ////
  // C //
  // ////
  //
  // Create a copy of the emotion styles that will persist in the snapshot.
  //
  // In production, emotion uses a browser API named `insertRule` which
  //  essentially creates a shadow stylesheet, represented in the DOM as an
  //  empyty style tag. An empty tag is no help when saving a copy of the html,
  //  but fortunately, there is a special way to access these shadow styles!
  //
  // See for more context: https://github.com/emotion-js/emotion/issues/1248
  //
  // This same note, strangley enough, also applies to style-loader but only in
  //  development, since at the time of writing, we reserve style loader to
  //  development only.

  const emotionStyle = document.createElement('style');
  emotionStyle.setAttribute('all-css-rules', 'true');
  if (head) {
    const emotions = document.querySelectorAll<HTMLStyleElement>('style');
    // Here is where we're extracting those shadow styles, with
    //  `el.sheet.cssRules`, rather than `el.innerHtml`.
    const failedProcessingErrorMessages: string[] = [];
    const emotionStrings: string[] = flatten(
      Array.from(emotions).map(({ sheet }) => {
        try {
          return Array.from(sheet?.cssRules ?? []).map((rules) => rules.cssText);
        } catch (e) {
          // IE11 seems to sometimes throw an `Error: Member not found` which we believe is caused by a race-condition
          // between emotion rendering and the `createElement` above
          if (typeof e === 'string') {
            failedProcessingErrorMessages.push(
              `failed to process ${sheet ? sheet.cssRules : 'undefined sheet'}\n${e}`
            );
          } else if (e instanceof Error) {
            failedProcessingErrorMessages.push(
              `failed to process ${sheet ? sheet.cssRules : 'undefined sheet'}\n${e.stack}`
            );
          }
          return [];
        }
      })
    );

    if (failedProcessingErrorMessages.length)
      console.error(
        `Failed to process emotion styles creating HTML screenshot:\n${failedProcessingErrorMessages.join(
          '\n'
        )}`
      );

    const emotionString = emotionStrings.join('\n');
    emotionStyle.innerHTML = emotionString;
    head.appendChild(emotionStyle);
  }

  // CREATE THE BLOB

  const blob = new Blob([document.documentElement.innerHTML], {
    type: 'text/html;charset=utf-8',
  });

  // For debugging:
  // saveAs(blob, 'regard.html');

  // ////
  // A //
  // ////
  //
  // Clean up reference marker.
  if (scrollMarker) {
    scrollMarker.style.display = 'none';
  }

  // ////
  // B //
  // ////
  //
  // Clean up styles.
  if (head) {
    head.removeChild(style);
  }

  // ////
  // C //
  // ////
  //
  // Clean up emotion styles.
  if (head) {
    head.removeChild(emotionStyle);
  }

  return blob;
};
