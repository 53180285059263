import { findLastIndex } from 'lodash';
import {
  IndexingData,
  ConditionAssessmentPlan,
  BulletNoteStatusRecord,
  PreviousConditionAssessmentPlan,
  Stats,
  SimpleCondition,
} from '~/app/@types/state';
import { getIsEmptyHtmlString, insertAll, isFooterChunk } from '~/app/utils';
import { track } from '~/app/analytics';

import { analyzeAndMeshHtmlChunks } from './analyzeAndMeshChunks';
import { appendNewRegardDxes } from './appendNewRegardDxes';
import { generateCurrentRegardConditionsMap } from './currentConditions';
import { generatePreviousRegardConditionMap } from './previousConditions';
import { parseHtmlNoteByTitles } from './parseHtmlNoteByTitles';
import { addModulesToChunks } from './addModulesToChunks';
import { getDominantTitleSignifierFromChunksWithLines } from './getDominantTitleSignifier/getDominantTitleSignifier';
import { getDominantTitleStyles } from './createNewTitleSignifier/getDominantTitleStyles';
import { renumberHtmlTitleLines } from './renumberHtmlTitleLines/renumberHtmlTitleLines';

/* Locates conditions in progressNote by finding all bullets and matching their caption to the
 * provided list of dxKeywords. Then, for unmatched Regard DXes, we add new titles to the note,
 * thus "meshing" the the previous progress note and the Regard note */
export const meshNotes = ({
  baseNoteHtml,
  bulletNoteStatusFromAPI,
  conditionNameToKeywordRegex,
  conditionQualifiers,
  currentConditionAssessmentPlans,
  previousConditionAssessmentPlans,
  isBeforeBasenoteEffective,
  baseNoteResourceId,
}: {
  baseNoteHtml: HtmlString;
  bulletNoteStatusFromAPI: BulletNoteStatusRecord;
  conditionNameToKeywordRegex: Record<string, RegExp>; // key is regard module ("anemia"), value is all the synonyms as 1 regex
  conditionQualifiers: Record<string, Record<string, RegExp>>; // key is regard module,sub key is qualifier type like "type" for diabetes, values are regex for thos qualifiers
  currentConditionAssessmentPlans: ConditionAssessmentPlan[];
  previousConditionAssessmentPlans: PreviousConditionAssessmentPlan[];
  isBeforeBasenoteEffective: (timestamp: ISODateString) => boolean;
  baseNoteResourceId: string;
}): {
  meshedNoteHtmlChunks: HtmlString[];
  conditions: Record<string, SimpleCondition>;
  indexingData: IndexingData;
  stats: Stats;
} => {
  const { chunksWithLines, noteHasHeader } = parseHtmlNoteByTitles({
    noteHtml: baseNoteHtml,
    parseMode: 'mesh',
  });

  // Track note mesh event
  track.noteMeshed({
    numberOfTitleLines: chunksWithLines.reduce(
      (numTitlesInNote, chunk) =>
        numTitlesInNote + chunk.lines.filter((line) => line.type === 'title').length,
      0
    ),
    noteResourceId: baseNoteResourceId,
    isSplitOnNewlineEnabled: true,
    noteHasHeader,
  });

  // Convert previous ConditionAssessmentPlan objects from API to lookup maps
  const { previousRegardConditionsMap, conditionNameToPreviousTitleMap } =
    generatePreviousRegardConditionMap(previousConditionAssessmentPlans);

  // Convert ConditionAssessmentPlan objects from API to lists and lookup maps
  const {
    currentRegardConditions,
    currentRegardConditionsMap,
    regardGeneratedTitleLookup,
    previousTitleToNewTitle,
    updatableBulletTextByConditionName,
    removableBulletTextKeyToConditionNameMap,
    conditionNameToICDCodesMap,
  } = generateCurrentRegardConditionsMap({
    currentConditionAssessmentPlans,
    isBeforeBasenoteEffective,
    previousRegardConditionsMap,
    conditionQualifiers,
    conditionNameToPreviousTitleMap,
  });

  // Figure out dominant bullet style that we will use in meshed note
  const titleSignifier = getDominantTitleSignifierFromChunksWithLines(chunksWithLines);
  const titleStyles = getDominantTitleStyles(chunksWithLines);

  // Now that we have parsed our note into "sections" (titles with lines)
  // We use a series of logic to match each section to an array of module names
  // NOTE: the following function mutates parsedNote

  const { chunksWithModules, allModuleMatchesByTitle: htmlAllModuleMatchesByTitle } =
    addModulesToChunks({
      conditionKeywords: conditionNameToKeywordRegex,
      chunks: chunksWithLines,
    });

  const negativeDiagnosisSet: Set<string> = new Set();
  currentConditionAssessmentPlans.forEach(({ module, negative }) => {
    if (negative) {
      negativeDiagnosisSet.add(module);
    }
  });

  // Prepare indexing data
  const indexingData = {
    allModuleMatchesByTitle: htmlAllModuleMatchesByTitle, // Conditions by literal title match for quick lookup
    conditionsByModule: {}, // Conditions by module name in case quick lookup fails
    conditionKeywords: conditionNameToKeywordRegex, // Module names by keyword regex in case quick lookup fails
    conditionQualifiers,
    regardGeneratedTitleLookup,
    footerHtml: '' as HtmlString,
    footerText: '',
    exactMatchRegardTitlesInNoteByModule: {},
    conditionNameToICDCodesMap,
    negativeDiagnosisSet,
  };

  // Mesh our modules into progress note and fill out corresponding indexing data and stats
  const {
    currentTitleIndex: currentTitleHtmlIndex,
    existingChunks,
    footerTitleLineCount,
    stats: htmlStats,
  } = analyzeAndMeshHtmlChunks({
    currentRegardConditionsMap,
    bulletNoteStatusFromAPI,
    chunksWithModules,
    indexingData,
    previousTitleToNewTitle,
    removableBulletTextKeyToConditionNameMap,
    titleSignifier,
    updatableBulletTextByConditionName,
  });

  // What are the conditions Regard found that are not in the note?
  const startingBulletNumber = currentTitleHtmlIndex + 1 - footerTitleLineCount;
  const newConditionHtmlStrings = appendNewRegardDxes({
    currentRegardConditions,
    currentRegardConditionsMap,
    bulletNoteStatusFromAPI,
    startingBulletNumber,
    titleSignifier,
    titleStyles,
    indexingData,
    stats: htmlStats,
  });
  const newConditionHtmlStringsCount = newConditionHtmlStrings.length;

  // Insert the conditions regard found right after the last existing condition of `type === 'condition'`
  const indexOfLastCondition = findLastIndex(
    existingChunks,
    (chunk, i) =>
      // is condition (not a shelved condition)
      chunk.type === 'condition' &&
      // and is not footer
      !(i === existingChunks.length - 1 && isFooterChunk(chunk)) &&
      // and is not empty
      !!chunk.lines.some((line) => !getIsEmptyHtmlString(line.html))
  );
  let footerHtmlString = '';
  const existingHtmlStrings: HtmlString[] = [];
  existingChunks.forEach((chunk, i) => {
    const isFooter = i === existingChunks.length - 1 && isFooterChunk(chunk);

    if (isFooter) {
      // Renumber the footer title lines because we are adding new conditions
      //  before the footer
      const { linesWithRenumberedTitleLines } = renumberHtmlTitleLines({
        currentTitleIndex: startingBulletNumber + newConditionHtmlStringsCount + -1,
        lines: chunk.lines,
        noteTitleSignifier: titleSignifier,
      });
      linesWithRenumberedTitleLines.forEach(({ html }) => {
        footerHtmlString += html;
      });
    } else {
      let htmlString = '';
      chunk.lines.forEach(({ html }) => {
        htmlString += html;
      });
      if (htmlString) existingHtmlStrings.push(htmlString as HtmlString);
    }
  });
  const sectionHtmlStrings =
    indexOfLastCondition === -1
      ? [...newConditionHtmlStrings, ...existingHtmlStrings]
      : insertAll(existingHtmlStrings, indexOfLastCondition + 1, newConditionHtmlStrings);

  if (footerHtmlString) {
    sectionHtmlStrings.push(footerHtmlString as HtmlString);
  }

  // Return meshed note and indexing data
  return {
    meshedNoteHtmlChunks: sectionHtmlStrings,
    conditions: currentRegardConditionsMap,
    indexingData,
    stats: htmlStats,
  };
};
