import { FC } from 'react';

import { IconButton, StaticTooltip } from '../../../../reuse';
import { useSelector } from '../../../../store';
import { buttonTooltipStyle, iconStyle } from '../css';

const useCanRedo = (): boolean => useSelector((state) => state.undo.canRedo);

export const RedoButton: FC<{
  disabled: boolean;
  onRedoClick(): void;
}> = ({ disabled, onRedoClick }) => {
  const canRedo = useCanRedo();
  const redoDisabled = disabled || !canRedo;

  return (
    <StaticTooltip
      disabled={redoDisabled}
      placement="bottom"
      style={buttonTooltipStyle}
      tooltip="Redo"
    >
      <IconButton
        color="tertiary-grey"
        data-cy-redo-button
        disabled={redoDisabled}
        iconName="redo"
        onClick={onRedoClick}
        size="small"
        style={iconStyle}
      />
    </StaticTooltip>
  );
};
