import { useCallback, useState } from 'react';

import { EligibleHtmlBaseNote } from '../../../@types/state';
import { fetchPrevPropsAndRemesh } from '../../../actions/regardNote';
import { getBlankBaseNote } from '../../../controllers/regardNote';
import { track } from '../../../analytics';
import { createSelector, useDispatch, useShallowEqualSelector } from '../../../store';
import { useBooleanState } from '../../../utils';

const baseNotesSelector = createSelector(
  (state) => ({
    baseNotes: state.regardNote.baseNotes,
    baseNoteData: state.regardNote.baseNoteData,
  }),
  (x) => x
);

const useBaseNotes = (): {
  baseNotes: EligibleHtmlBaseNote[];
  currentBaseNote: EligibleHtmlBaseNote;
} => {
  const { baseNotes, baseNoteData } = useShallowEqualSelector(baseNotesSelector);

  const currentBaseNote = baseNotes.find(
    ({ resourceId }) => resourceId === baseNoteData.resourceId
  );

  return { baseNotes, currentBaseNote: currentBaseNote ?? getBlankBaseNote() };
};

export const useController = (): {
  baseNotes: EligibleHtmlBaseNote[];
  currentBaseNote: EligibleHtmlBaseNote;
  isSelecting: boolean;
  onCancelSelection(): void;
  onStartSelection(): void;
  onSubmitSelection(): void;
  selectedBaseNote: EligibleHtmlBaseNote | undefined;
  setSelectedBaseNote(note: EligibleHtmlBaseNote): void;
} => {
  const [isSelecting, { setFalse: setIsSelectingFalse, setTrue: setIsSelectingTrue }] =
    useBooleanState(false);

  const onStartSelection = useCallback(() => {
    track.clickedChangeBaseNote();
    setIsSelectingTrue();
  }, [setIsSelectingTrue]);

  const { baseNotes, currentBaseNote } = useBaseNotes();

  const [selectedBaseNote, setSelectedBaseNote] = useState(currentBaseNote);

  const onCancelSelection = useCallback(() => {
    setIsSelectingFalse();
    setSelectedBaseNote(currentBaseNote);
  }, [currentBaseNote, setSelectedBaseNote, setIsSelectingFalse]);

  const dispatch = useDispatch();
  const onSubmitSelection = useCallback(() => {
    if (
      isSelecting // some protection against double-clicking
    ) {
      if (
        currentBaseNote &&
        selectedBaseNote &&
        currentBaseNote !== selectedBaseNote // some protection against useless submissions
      ) {
        track.startTimeToChangeBaseNote({
          resourceId: selectedBaseNote.resourceId,
        });
        setIsSelectingFalse();
        dispatch(fetchPrevPropsAndRemesh(selectedBaseNote, { useDraft: true }));
      } else {
        throw Error('Cannot submit selection: no base note selected.');
      }
    }
  }, [currentBaseNote, dispatch, isSelecting, selectedBaseNote, setIsSelectingFalse]);

  return {
    baseNotes,
    currentBaseNote,
    isSelecting,
    onCancelSelection,
    onSubmitSelection,
    onStartSelection,
    selectedBaseNote,
    setSelectedBaseNote,
  };
};
