import styled from '@emotion/styled';
import { FC } from 'react';

import { theme } from '../../../reuse';
import { COMPANY_NAME } from '../../../constants';

const Root = styled.div({
  margin: '8px 0',
  position: 'relative',
  textAlign: 'center',
});

const Line = styled.div({
  backgroundColor: theme.colors.green,
  height: 1,
  left: 0,
  position: 'absolute',
  right: 0,
  top: '50%',
});

const Text = styled.div({
  backgroundColor: theme.colors.white,
  color: theme.colors.green,
  display: 'inline-block',
  fontWeight: 600,
  padding: '0 12px',
  position: 'relative',
});

export const UnconnectedNewConditionsLine: FC<{
  numberOfNewConditions: number;
}> = ({ numberOfNewConditions }) => (
  <Root data-cy-new-conditions-line>
    <Line />
    <Text>
      {numberOfNewConditions} New {COMPANY_NAME} Diagnos
      {numberOfNewConditions === 1 ? 'is' : 'es'}
    </Text>
  </Root>
);
