import { keyframes } from '@emotion/react';
import _ from 'lodash';
import { FC, createContext } from 'react';

import { DEFAULT_EHR, Ehr } from './const';
import { useDemoParams } from './useDemoParams';
import { useFetchDemoData } from './useFetchDemoData';
import { FetchError } from '../FetchError';
import { createSelectorProvider, NULL_DATE, useContextSelector } from '../../utils';

const dot1 = keyframes`
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  96% {
    opacity: 1;
  }
`;
const dot2 = keyframes`
  0% {
    opacity: 0;
  }
  29% {
    opacity: 0;
  }
  32% {
    opacity: 1;
  }
  97% {
    opacity: 1;
  }
`;
const dot3 = keyframes`
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
`;
const duration = '1.5s ease infinite';

type DemoContext = {
  ehr: Ehr;
  pt: string;
  timestamp: ISODateString;
  toggleEhr(): void;
};

const defaultDemoContext: DemoContext = {
  ehr: DEFAULT_EHR,
  pt: '',
  timestamp: NULL_DATE.toISOString(),
  toggleEhr: _.noop,
};

const DemoContext = createContext<DemoContext>(defaultDemoContext);
const DemoContextProvider = createSelectorProvider(DemoContext);

export const useDemoContext = <T,>(selector: (context: DemoContext) => T): T =>
  useContextSelector<DemoContext, T>(DemoContext, selector);

export const DemoProvider: FC = ({ children }) => {
  const { ehr, pt, timestamp, toggleEhr } = useDemoParams();
  const { data, error, loading } = useFetchDemoData({ pt, timestamp });

  return (
    <DemoContextProvider
      value={{
        ehr,
        pt,
        timestamp,
        toggleEhr,
      }}
    >
      {(() => {
        if (error) {
          if (typeof error === 'string') {
            return (
              <p>
                Error:
                {error}
              </p>
            );
          }

          return <FetchError {...error} />;
        }

        if (loading || !data) {
          return (
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              Loading
              <span css={{ animation: `${dot1} ${duration}` }}>.</span>
              <span css={{ animation: `${dot2} ${duration}` }}>.</span>
              <span css={{ animation: `${dot3} ${duration}` }}>.</span>
            </div>
          );
        }

        return children;
      })()}
    </DemoContextProvider>
  );
};
