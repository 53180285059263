import { useCallback, useState } from 'react';

const useStateTrueFalse = (
  defaultValue: boolean
): [value: boolean, setValueTrue: () => void, setValueFalse: () => void] => {
  const [value, setValue] = useState(defaultValue);
  const setValueTrue = useCallback(() => setValue(true), [setValue]);
  const setValueFalse = useCallback(() => setValue(false), [setValue]);

  return [value, setValueTrue, setValueFalse];
};

export const useConditionButtonsHover = (): {
  buttonHovered: boolean;
  copyAllButtonHovered: boolean;
  copyButtonHovered: boolean;
  dismissButtonHovered: boolean;
  shelveButtonHovered: boolean;
  onCopyAllButtonEnter(): void;
  onCopyAllButtonLeave(): void;
  onCopyButtonEnter(): void;
  onCopyButtonLeave(): void;
  onDismissButtonEnter(): void;
  onDismissButtonLeave(): void;
  onShelveButtonEnter(): void;
  onShelveButtonLeave(): void;
  onMoveMenuButtonEnter(): void;
  onMoveMenuButtonLeave(): void;
} => {
  const [
    copyAllButtonHovered, //
    onCopyAllButtonEnter,
    onCopyAllButtonLeave,
  ] = useStateTrueFalse(false);

  const [
    copyButtonHovered, //
    onCopyButtonEnter,
    onCopyButtonLeave,
  ] = useStateTrueFalse(false);

  const [
    dismissButtonHovered, //
    onDismissButtonEnter,
    onDismissButtonLeave,
  ] = useStateTrueFalse(false);

  const [
    shelveButtonHovered, //
    onShelveButtonEnter,
    onShelveButtonLeave,
  ] = useStateTrueFalse(false);

  const [
    moveMenuButtonHovered, //
    onMoveMenuButtonEnter,
    onMoveMenuButtonLeave,
  ] = useStateTrueFalse(false);

  const moveButtonHovered = moveMenuButtonHovered;

  const buttonHovered = dismissButtonHovered || moveButtonHovered || shelveButtonHovered;

  return {
    buttonHovered,
    copyAllButtonHovered,
    copyButtonHovered,
    dismissButtonHovered,
    shelveButtonHovered,
    onCopyAllButtonEnter,
    onCopyAllButtonLeave,
    onCopyButtonEnter,
    onCopyButtonLeave,
    onDismissButtonEnter,
    onDismissButtonLeave,
    onShelveButtonEnter,
    onShelveButtonLeave,
    onMoveMenuButtonEnter,
    onMoveMenuButtonLeave,
  };
};
