import { FC, memo } from 'react';

import { ConditionBackground } from '../conditionBackground';
import { theme } from '../../../reuse';

const buttonHoveredStyle = {
  borderBottom: `1px solid ${theme.colors.blueRegular}`,
};

const defaultStyle = {
  backgroundColor: theme.colors.grey6,
};

export const ConditionSuggestionsBackground: FC = memo(() => (
  <ConditionBackground
    buttonHoveredStyle={buttonHoveredStyle}
    defaultStyle={defaultStyle}
    isSuggestion
  />
));
ConditionSuggestionsBackground.displayName = 'ConditionSuggestionsBackground';
