import { FC, memo } from 'react';

import { ConditionLineStatus, Suggestion } from '~/app/@types';
import { interpretationIsCritical, useHover } from '~/app/utils';

import { ConditionLineStatusIndicator } from '../conditionLineStatusIndicator';
import { ConditionSuggestionAddButton } from '../conditionSuggestionAddButton';
import { ConditionSuggestionRemoveButton } from '../conditionSuggestionRemoveButton';
import { useNoteContext } from '../noteProvider';

import { useAddAndRemoveSuggestion } from './useAddAndRemoveSuggestion';
import { Inner, Root } from './css';
import { ConditionLineCriticalFlag, SvgStyle } from '../conditionLineCriticalFlag';
import { useNoteBlockContext } from '../noteBlockProvider';
import { useConditionContext } from '../conditionProvider';

type ConditionSuggestionProps = Pick<Suggestion, 'status' | 'text' | 'interpretation'> & {
  conditionId: string;
  id: string;
  nextStatus: ConditionLineStatus;
  previousStatus: ConditionLineStatus;
};

export const ConditionSuggestion: FC<ConditionSuggestionProps> = memo(
  ({ conditionId, id, nextStatus, previousStatus, status, text, interpretation }) => {
    const { isShelvedCondition } = useConditionContext(({ isShelvedCondition }) => ({
      isShelvedCondition,
    }));

    const { reviewMode } = useNoteContext(({ reviewMode }) => ({
      reviewMode,
    }));

    const { buttonHovered, dismissButtonHovered } = useNoteBlockContext(
      ({ buttonHovered, dismissButtonHovered }) => ({
        buttonHovered,
        dismissButtonHovered,
      })
    );

    const [removeButtonHoveredRef, removeButtonHovered] = useHover<HTMLButtonElement>();

    const { addSuggestion, removeSuggestion } = useAddAndRemoveSuggestion({
      conditionId,
      id,
      isShelvedCondition,
      text,
    });

    const isCritical = interpretationIsCritical(interpretation);

    return (
      <Root
        buttonHovered={buttonHovered}
        data-condition-suggestion-id={id}
        data-cy-suggested-bullet
        dismissButtonHovered={dismissButtonHovered}
      >
        {isCritical && <ConditionLineCriticalFlag left={26} svgStyle={SvgStyle.Suggestion} />}
        <ConditionLineStatusIndicator
          isCritical={isCritical}
          left={46}
          nextStatus={nextStatus}
          previousStatus={previousStatus}
          status={status}
        />
        <Inner reviewMode={reviewMode}>
          <ConditionSuggestionRemoveButton
            ref={removeButtonHoveredRef}
            disabled={reviewMode}
            onClick={removeSuggestion}
          />
          <ConditionSuggestionAddButton
            disabled={reviewMode}
            isCritical={isCritical}
            onClick={addSuggestion}
            removeButtonHovered={removeButtonHovered}
            text={text}
          />
        </Inner>
      </Root>
    );
  }
);
ConditionSuggestion.displayName = 'ConditionSuggestion';
