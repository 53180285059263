import { noop } from 'lodash';
import { FC, memo, useRef } from 'react';
import { ErrorBoundary } from '~/app/reuse/errorBoundary';

import { ConditionAddButton } from '../conditionAddButton';
import { useNoteBlockContext } from '../noteBlockProvider';
import { ShelvedConditionsLine } from '../shelvedConditionsLine';

import { useConditionSetCaretPosition } from './useConditionSetCaretPosition';

export const ShelfDivider: FC<{
  isLastNoteBlock: boolean;
}> = memo(({ isLastNoteBlock }) => {
  const { noteBlockRef, id } = useNoteBlockContext(({ noteBlockRef, id }) => ({
    noteBlockRef,
    id,
  }));

  const setRteCaretPositionRef = useRef<(position: number) => void>(noop);
  useConditionSetCaretPosition(id, setRteCaretPositionRef);

  return (
    <div ref={noteBlockRef} data-cy-shelf-divider data-note-block-id={id}>
      <ConditionAddButton />
      <ErrorBoundary inline>
        <ShelvedConditionsLine />
      </ErrorBoundary>
      {isLastNoteBlock ? <ConditionAddButton bottom /> : null}
    </div>
  );
});
ShelfDivider.displayName = 'ShelfDivider';
