import { memoize } from 'lodash';
import { theme } from '~/app/reuse';

const centeredDiv = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
} as const;

export const backgroundStyle = {
  ...centeredDiv,
  height: '100%',
  width: '100%',
  backgroundColor: theme.colors.blurple,
  color: theme.colors.white,
} as const;

export const getContentStyle = memoize(
  (isVerticalLayout: boolean) =>
    ({
      ...centeredDiv,
      width: isVerticalLayout ? '90%' : '66%',
      overflow: 'hidden',
    } as const)
);

export const textStyle = {
  width: '100%',
  textWrap: 'wrap',
  textAlign: 'center',
} as const;

export const headerTextStyle = {
  ...textStyle,
  fontSize: '2.5rem',
  lineHeight: '2.5rem',
  margin: '16px 0',
} as const;

export const getProgressContainerStyle = memoize(
  (isVerticalLayout: boolean) =>
    ({
      ...centeredDiv,
      marginTop: isVerticalLayout ? '20px' : '40px',
    } as const)
);

export const progressTextStyle = {
  ...textStyle,
  marginTop: '16px',
} as const;

export const logoSvgStyle = {
  position: 'absolute',
  left: '1rem',
  top: '1rem',
} as const;
