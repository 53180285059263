import { FC, memo } from 'react';

import { UnconnectedConditionUnderlay } from '../unconnectedConditionUnderlay';
import { useConditionContext } from '../conditionProvider';
import { useNoteBlockContext } from '../noteBlockProvider';

export const ConditionUnderlay: FC = memo(() => {
  const { isCopied } = useNoteBlockContext(({ isCopied }) => ({
    isCopied,
  }));

  const {
    hasNonCriticalSuggestions,
    isShelvedCondition,
    keywords,
    lines,
    suggestionsAreMinimized,
    toggleSuggestionsMinimized,
  } = useConditionContext(
    ({
      hasNonCriticalSuggestions,
      isShelvedCondition,
      keywords,
      lines,
      suggestionsAreMinimized,
      toggleSuggestionsMinimized,
    }) => ({
      hasNonCriticalSuggestions,
      isShelvedCondition,
      keywords,
      lines,
      suggestionsAreMinimized,
      toggleSuggestionsMinimized,
    })
  );

  return (
    <UnconnectedConditionUnderlay
      hasNonCriticalSuggestions={hasNonCriticalSuggestions}
      isCopied={isCopied}
      isShelvedCondition={isShelvedCondition}
      keywords={keywords}
      lines={lines}
      suggestionsAreMinimized={suggestionsAreMinimized}
      toggleSuggestionsMinimized={toggleSuggestionsMinimized}
    />
  );
});
ConditionUnderlay.displayName = 'ConditionUnderlay';
