import { FC } from 'react';

export const EmptyNoteIcon: FC = () => (
  <svg
    fill="none"
    height="200"
    viewBox="0 0 250 200"
    width="250"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="white" height="200" width="250" />
    <path
      clipRule="evenodd"
      d="M207 65C210.866 65 214 68.134 214 72C214 75.866 210.866 79 207 79H167C170.866 79 174 82.134 174 86C174 89.866 170.866 93 167 93H189C192.866 93 196 96.134 196 100C196 103.866 192.866 107 189 107H178.826C173.952 107 170 110.134 170 114C170 116.577 172 118.911 176 121C179.866 121 183 124.134 183 128C183 131.866 179.866 135 176 135H93C89.134 135 86 131.866 86 128C86 124.134 89.134 121 93 121H54C50.134 121 47 117.866 47 114C47 110.134 50.134 107 54 107H94C97.866 107 101 103.866 101 100C101 96.134 97.866 93 94 93H69C65.134 93 62 89.866 62 86C62 82.134 65.134 79 69 79H109C105.134 79 102 75.866 102 72C102 68.134 105.134 65 109 65H207ZM207 93C210.866 93 214 96.134 214 100C214 103.866 210.866 107 207 107C203.134 107 200 103.866 200 100C200 96.134 203.134 93 207 93Z"
      fill="#F6F6F9"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M153.623 63.7636L162.924 131.606L163.76 138.413C164.029 140.605 162.47 142.601 160.277 142.87L101.717 150.061C99.5245 150.33 97.5287 148.771 97.2595 146.578L88.2437 73.1503C88.1091 72.054 88.8888 71.0561 89.9851 70.9215C89.992 70.9207 89.9989 70.9198 90.0059 70.9191L94.8642 70.3741"
      fill="white"
      fillRule="evenodd"
    />
    <path
      d="M153.623 63.7636L162.924 131.606L163.76 138.413C164.029 140.605 162.47 142.601 160.277 142.87L101.717 150.061C99.5245 150.33 97.5287 148.771 97.2595 146.578L88.2437 73.1503C88.1091 72.054 88.8888 71.0561 89.9851 70.9215C89.992 70.9207 89.9989 70.9198 90.0059 70.9191L94.8642 70.3741"
      stroke="#9098A7"
      strokeLinecap="round"
      strokeWidth="2.5"
    />
    <path
      clipRule="evenodd"
      d="M151.141 68.2692L159.56 129.753L160.318 135.921C160.562 137.908 159.168 139.715 157.204 139.956L104.762 146.395C102.798 146.636 101.009 145.22 100.765 143.233L92.6148 76.8568C92.4802 75.7604 93.2598 74.7625 94.3562 74.6279L100.844 73.8314"
      fill="#E7E9EF"
      fillRule="evenodd"
    />
    <path
      d="M107.922 54C107.922 52.4812 109.153 51.25 110.672 51.25H156.229C156.958 51.25 157.657 51.5395 158.173 52.0549L171.616 65.4898C172.132 66.0056 172.422 66.7053 172.422 67.4349V130C172.422 131.519 171.191 132.75 169.672 132.75H110.672C109.153 132.75 107.922 131.519 107.922 130V54Z"
      fill="white"
      stroke="#9098A7"
      strokeWidth="2.5"
    />
    <path
      d="M156.672 52.4028V64C156.672 65.6569 158.015 67 159.672 67H167.605"
      stroke="#9098A7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
    <path
      clipRule="evenodd"
      d="M98.058 70.0348L102.645 69.5203L98.058 70.0348Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      d="M98.058 70.0348L102.645 69.5203"
      stroke="#9098A7"
      strokeLinecap="round"
      strokeWidth="2.5"
    />
    <path d="M117.301 64L117.301 75" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M121.699 64L121.699 75" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 67.3H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 71.7H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M117.301 85L117.301 96" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M121.699 85L121.699 96" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 88.3H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 92.7H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M117.301 106L117.301 117" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M121.699 106L121.699 117" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 109.3H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M114 113.7H125" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="2" />
    <path d="M133 69H149" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="4" />
    <path d="M132 90H164" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="4" />
    <path d="M131 112H163" stroke="#C5CBD8" strokeLinecap="round" strokeWidth="4" />
  </svg>
);
