import { format, parse } from 'date-fns';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import '../styles/CaseReviewToolbar.scss';
import { TIMESTAMP_PARAM } from '../constants';

const datetimeFormatString = 'MM/DD/YYYY H:mm:ss';

const CaseReviewToolbar = () => {
  const location = useLocation();
  const record = `/record${location.search}`;
  const note = `/doc${location.search}`;
  const params = new URLSearchParams(location.search);
  const timestamp = params.get(TIMESTAMP_PARAM) ? parse(params.get(TIMESTAMP_PARAM)) : null;
  const timestampLinks = [];
  const navAttrs = { className: 'nav-link' };

  const [currentTimestamp, setCurrentTimestamp] = useState(timestamp);

  if (currentTimestamp) {
    const prevDay = new URLSearchParams(params);
    const prevDayTimestamp = new Date(currentTimestamp);
    prevDayTimestamp.setDate(currentTimestamp.getDate() - 1);
    prevDay.set(TIMESTAMP_PARAM, format(prevDayTimestamp));
    timestampLinks.push(
      <Link
        key="link--1d"
        onClick={() => setCurrentTimestamp(prevDayTimestamp)}
        to={`/doc?${prevDay.toString()}`}
        {...navAttrs}
      >
        -1d
      </Link>
    );

    const prevHour = new URLSearchParams(params);
    const prevHourTimestamp = new Date(currentTimestamp.getTime() - 3600000);
    prevHour.set(TIMESTAMP_PARAM, format(prevHourTimestamp));
    timestampLinks.push(
      <Link
        key="link--1h"
        onClick={() => setCurrentTimestamp(prevHourTimestamp)}
        to={`/doc?${prevHour.toString()}`}
        {...navAttrs}
      >
        -1h
      </Link>
    );

    const nextHour = new URLSearchParams(params);
    const nextHourTimestamp = new Date(currentTimestamp.getTime() + 3600000);
    nextHour.set(TIMESTAMP_PARAM, format(nextHourTimestamp));
    timestampLinks.push(
      <Link
        key="link-1h"
        onClick={() => setCurrentTimestamp(nextHourTimestamp)}
        to={`/doc?${nextHour.toString()}`}
        {...navAttrs}
      >
        +1h
      </Link>
    );

    const nextDay = new URLSearchParams(params);
    const nextDayTimestamp = new Date(currentTimestamp);
    nextDayTimestamp.setDate(currentTimestamp.getDate() + 1);
    nextDay.set(TIMESTAMP_PARAM, format(nextDayTimestamp));
    timestampLinks.push(
      <Link
        key="link-1d"
        onClick={() => setCurrentTimestamp(nextDayTimestamp)}
        to={`/doc?${nextDay.toString()}`}
        {...navAttrs}
      >
        +1d
      </Link>
    );
  }

  return (
    <div className="CaseReviewToolbar">
      <span className="title">CASE REVIEW</span>

      <Link key="query" to="/query?review=true" {...navAttrs}>
        Query
      </Link>
      <Link key="record" to={record} {...navAttrs}>
        PMEMR
      </Link>
      <Link key="note" to={note} {...navAttrs}>
        Note
      </Link>
      <div className="current-timestamp">
        {timestampLinks.reverse()}
        {currentTimestamp !== timestamp && (
          <span>
            {format(currentTimestamp, datetimeFormatString)}
            &nbsp;&mdash;&nbsp;
            <span className="button secondary small" onClick={() => window.location.reload()}>
              Go
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default CaseReviewToolbar;
