import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CKEditor, CKEditorEventHandler, CKEditorEventPayload } from 'ckeditor4-react';
import { noop } from 'lodash';

import { get, htmlLinesToHtmlLinesWithoutDivTags } from '~/app/utils';
import { KEY_CODE_BACKSPACE, KEY_CODE_CMD_BACKSPACE, KEY_CODE_DELETE } from '~/app/constants';
import { TypingDirection } from '~/app/actions/regardNote';

import './plugins/regardBasicStyles';
import './plugins/regardClipboard';
import './plugins/regardCopyFormatter';
import './plugins/regardDoNothing';
import './plugins/regardEditorPlaceholder';
import './plugins/regardUndo';

import { useIntervalSync } from './useIntervalSync';
import { config as defaultConfig } from './config';
import { CKEditorOnChangeProps } from './ckeditorTypes';
import { getCkeditorCaretPos } from './getCkeditorCaretPos';
import { setCkeditorCaretPos } from './setCkeditorCaretPos';
import { tryToTriggerNonEmptyBackspace } from './tryToTrigerNonEmptyBackspace';

export type SetRteCaretPosition = MutableRefObject<(position: number) => void>;

type OnHtmlInputFnArgs = {
  caretPos: number;
  prevHtmlRef: MutableRefObject<HtmlString>;
  rawHtml: HtmlString;
};
export type OnHtmlInputFn = ({ caretPos, prevHtmlRef, rawHtml }: OnHtmlInputFnArgs) => void;

export const CKEditorRichTextEditor: FC<{
  htmlLines: HtmlString[];
  id: string;
  editorType?: string;
  onBlur?: CKEditorEventHandler<'blur'>;
  onDestroy?: CKEditorEventHandler<'destroy'>;
  onEmptyBackspace?: (id: string, direction: TypingDirection) => void;
  onNonEmptyBackspace?: (id: string, direction: TypingDirection) => void;
  onFocus?: CKEditorEventHandler<'focus'>;
  onHtmlInput: OnHtmlInputFn;
  onSelectionChange?: CKEditorEventHandler<'selectionChange'>;
  readOnly?: boolean;
  config?: typeof defaultConfig;
  setRteCaretPositionRef: SetRteCaretPosition;
}> = ({
  htmlLines,
  id,
  onBlur,
  onDestroy,
  onEmptyBackspace,
  onNonEmptyBackspace,
  onHtmlInput,
  onFocus,
  onSelectionChange,
  readOnly,
  config = defaultConfig,
  setRteCaretPositionRef,
  editorType,
}) => {
  const html = htmlLines.join('') as HtmlString;

  // --- ready --- //

  // The below logic is convoluted, but it allows an external hook, like the
  //  useConditionSetCaretPosition hook, to set the editor's caret position
  //  whenever it wants--as long as the CKEditor instance is "ready".
  //
  // If the instance is not yet ready, we save the trigger and fire it in the
  //  hook below.
  const queuedSetCaretRef = useRef(noop);
  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    setRteCaretPositionRef.current = (position: number) => {
      const editor = get(window.CKEDITOR.instances, id);

      if (editor) {
        setCkeditorCaretPos({ position, id });
      } else {
        queuedSetCaretRef.current = () => setCkeditorCaretPos({ position, id });
      }
    };
  }, [id, setRteCaretPositionRef]);

  // CKEditor4 takes a moment to load and render, so we have to wait for its
  //  onInstanceReady event to fire before trying to set the caret position.
  const [ready, setReady] = useState(false);
  const editorInstance = useRef<CKEDITOR.editor>();
  const onInstanceReady = useCallback<CKEditorEventHandler<'instanceReady'>>(
    ({ editor }: CKEditorEventPayload<'instanceReady'>) => {
      const queuedSetCaret = queuedSetCaretRef.current;
      editorInstance.current = editor;

      if (queuedSetCaret) {
        queuedSetCaret();
        queuedSetCaretRef.current = noop;
      }

      setReady(true);

      if (editorType) {
        editor.editable()?.setAttribute('data-editor-type', editorType);
      }
    },
    [setReady, editorType]
  );

  // --- onChange --- //

  // 1. Load cache with initial html/text
  const prevHtmlRef = useRef(html);

  // 2. Instantiate onChange function
  const onChange = useCallback(
    (props: CKEditorOnChangeProps) => {
      const { editor } = props;
      const parent = editor?.element?.$;

      if (parent) {
        const caretPos = getCkeditorCaretPos({
          editor,
          parent,
        });

        onHtmlInput({
          caretPos,
          rawHtml: parent.innerHTML as HtmlString,
          prevHtmlRef,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onHtmlInput]
  );

  // 3. Fix for Dragon not firing onChange events immediately
  //
  // When speaking to Dragon, the software will type that text using system-
  //  level commands and not keyboard input. Only when Dragon is absolutely sure
  //  you are OK with the text output--and this seems to be when you speak a
  //  subsequent chunk of text--it will trigger an onChange event.
  //
  // The effect of this is that our application is always one chunk behind on
  //  re-proccessing text added to the rich-text editor, even though the text
  //  exists in the DOM. From the user's perspective, this could mean that the
  //  last text they spoke doesn't show up in the Copy Note text or when they
  //  click to add a suggested bullet, we delete the last text they spoke.
  //
  // To combat this issue, we've introduced polling which manually triggers
  //  and onChange event in each rich-text editor in the hopes that we will
  //  catch any newly added text from Dragon, before the user triggers some
  //  other event that's dependent on that text having already been processed,
  //  in order to maintain a seamless experience.

  // Prevent interval syncing while typing to prevent reduced responsiveness
  const delaySync = useIntervalSync({ id, ready, editorInstance, onChange });

  // 4. Dismiss empty condition if backspace or delete are pressed again
  const onKey: CKEditorEventHandler<'key'> = (props) => {
    // editor is empty
    const editorElement = props?.editor?.element?.$;
    const text = editorElement?.innerText || '';
    const emptyText = text === '\n';

    // backspace or delete is pressed
    const keyCode = props.data?.keyCode;
    const isForwardBackspace = keyCode === KEY_CODE_DELETE;
    const isBackwardBackspace =
      keyCode === KEY_CODE_BACKSPACE || keyCode === KEY_CODE_CMD_BACKSPACE;

    const triggerEmptyBackspace =
      emptyText && (isForwardBackspace || isBackwardBackspace) && onEmptyBackspace;
    if (triggerEmptyBackspace) {
      // timeout required here to prevent an uncaught error in CKEditor
      setTimeout(() => onEmptyBackspace(id, isForwardBackspace ? 'forward' : 'backward'), 0);
    }

    const triggerNonEmptyBackspace =
      !emptyText && (isForwardBackspace || isBackwardBackspace) && onNonEmptyBackspace;
    if (triggerNonEmptyBackspace) {
      tryToTriggerNonEmptyBackspace({
        id,
        editorElement,
        isBackwardBackspace,
        isForwardBackspace,
        onNonEmptyBackspace,
      });
    }

    delaySync();
  };

  return (
    <>
      <CKEditor
        config={config}
        initData={html}
        name={id}
        onBlur={onBlur}
        onChange={onChange}
        onDestroy={onDestroy}
        onFocus={onFocus}
        onInstanceReady={onInstanceReady}
        onKey={onKey}
        onSelectionChange={onSelectionChange}
        readOnly={readOnly}
        type="inline"
      />
      {!ready && (
        // Include this placeholder so the user doesn't see CKEditor snapping
        //  in; unfortunately, the plugin does not render instantaneously, even
        //  in Chrome
        <div data-ckeditor-placeholder>
          {htmlLinesToHtmlLinesWithoutDivTags(htmlLines ?? []).map((htmlString, i) => (
            <div
              key={i}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: htmlString }}
            />
          ))}
        </div>
      )}
    </>
  );
};
CKEditorRichTextEditor.displayName = 'CKEditorRichTextEditor';
