import { isAfter, parse, format, isSameDay } from 'date-fns';
import { formatToTimeZone, convertToLocalTime } from 'date-fns-timezone';
import { RECENCY_THRESHOLD_PARAM, TIMESTAMP_PARAM } from '~/app/constants';

export const NULL_DATE = new Date(0);
export const NULL_DATE_VALUE = NULL_DATE.valueOf();

const stringToDate = (string: string): Date => parse(string);

const dateNumberValueIsValid = (value: DateNumberValue): boolean => value > 0;

const dateIsValid = (date: Date): boolean => dateNumberValueIsValid(date.valueOf());

type SupportedDateFormats =
  | '(HH:mm)'
  | 'M/D/YY'
  | 'M/D/YY H:mm'
  | 'M/D/YYYY'
  | 'MM/DD/YYYY'
  | 'M/D/YYYY H:mm'
  | 'M/D/YY (HH:mm)'
  | 'MM/DD/YYYY H:mm:ss'
  | 'HH:mm:ss MM/DD/YYYY'
  | 'ddd MMM D YYYY HH:mm';

export const formatISODateString = (
  isoDateString: ISODateString | null,
  stringFormat: SupportedDateFormats
): FormattedDateString => {
  if (isoDateString) {
    const date = stringToDate(isoDateString);

    if (dateIsValid(date)) {
      // rather than return a date like "January 1st, 1970", return ""
      // this will allow us to easily confirm whether or not
      //  FormattedDateString is truthy or falsy
      return (
        window.providerTimezone
          ? formatToTimeZone(date, stringFormat, { timeZone: window.providerTimezone })
          : format(date, stringFormat)
      ) as FormattedDateString;
    }
  }

  return '' as FormattedDateString;
};

export const formatDate = (date: ISODateString) => formatISODateString(date, 'M/D/YY');

export const formatDateLong = (date: ISODateString) => formatISODateString(date, 'MM/DD/YYYY');

export const formatDateLongYear = (date: ISODateString) => formatISODateString(date, 'M/D/YYYY');

export const formatDatetime = (datetime: ISODateString) =>
  formatISODateString(datetime, 'M/D/YY H:mm');

export const formatDatetimeLongYear = (datetime: ISODateString) =>
  formatISODateString(datetime, 'M/D/YYYY H:mm');

export const formatDateForNoteHeader = ({
  datetime,
  timeZone,
}: {
  datetime: ISODateString;
  timeZone: string;
}) =>
  isSameDay(
    // today
    convertToLocalTime(new Date(), { timeZone }),
    // the timestamp of the note
    convertToLocalTime(datetime, { timeZone })
  )
    ? `today ${formatISODateString(datetime, '(HH:mm)')}`
    : formatISODateString(datetime, 'M/D/YY (HH:mm)');

export const paramToISODateString = (param: string | null): ISODateString | null => {
  if (param) {
    const date = stringToDate(param);

    if (dateIsValid(date)) return date.toISOString();
  }

  return null;
};

// DateFns.parse will always return a date object. Falsey and invalid date strings
// will get returned as epoch (1/1/1970). So we check if the parsed
// date is within the last 25 years or return null.
export const parseStringToDateOrNull = (
  date: string | null | undefined | number,
  daysAgoMaximumToBeValid = 365 * 25
): Date | null => {
  if (!date || typeof date !== 'string') return null;
  const parsedDate = parse(date);
  const minimumDateToBeValid = new Date(
    new Date().setDate(new Date().getDate() - daysAgoMaximumToBeValid)
  );
  return isAfter(parsedDate, minimumDateToBeValid) ? parsedDate : null;
};

export const getMostRecentRefreshTime = (urlSearchQuery: string = window.location.search) => {
  const params = new URLSearchParams(urlSearchQuery);
  const timestamp = parseStringToDateOrNull(params.get(TIMESTAMP_PARAM));
  const recencyThreshold = parseStringToDateOrNull(params.get(RECENCY_THRESHOLD_PARAM));

  if (timestamp && recencyThreshold) {
    const mostRecentTime = isAfter(recencyThreshold, timestamp) ? recencyThreshold : timestamp;
    return mostRecentTime;
  }

  return timestamp || recencyThreshold || null;
};
