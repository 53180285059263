import { isEqual, throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { EXEC_COMMAND_BOLD, EXEC_COMMAND_ITALIC, EXEC_COMMAND_UNDERLINE } from '~/app/constants';
import { toolbarSyncInterval } from '~/app/flags';
import { SyncRegister, useDebounceNotByReference } from '~/app/utils';

const defaultState = {
  bold: false,
  italic: false,
  underline: false,
  inEditor: false,
};

const SYNC_ID = 'toolbarStylesSync';
const syncRegister = new SyncRegister('toolbar-active-styles', toolbarSyncInterval);
const EXPLICIT_SYNC_THROTTLE_TIME = 200;

// Keys that may affect the toolbar status
// backspace: 8, delete: 46
// arrow keys: 37, 38, 39, and 40
const watchedKeyCodes = [8, 46, 37, 38, 39, 40];

// Checking the comand state is the most stable and accurate way of seeing if the next
// keystroke will create a style character.
const getStyleStatus = (command: string) => document.queryCommandState(command);

const isCKEChild = (domNode?: Node | null) => {
  if (!domNode) return false;

  // Walk up the tree from the given node until a cke_editable is found or there are no more parents
  let possibleEditorElement: Node | null | undefined = domNode;
  while (possibleEditorElement) {
    const isDiv =
      possibleEditorElement.nodeType === Node.ELEMENT_NODE &&
      (possibleEditorElement as Element).tagName === 'DIV';

    const isCkeEditable =
      isDiv && (possibleEditorElement as Element).classList.contains('cke_editable');
    if (isCkeEditable) {
      break;
    }

    // All classes get stripped from html on paste, so if a div is encountered that has a className
    // (after the 'cke_editable' check above), then the current node being checked can't be in an
    // editor. So we can short circuit the search to prevent having to loop all the way to the root node.
    const isDivOutsideEditor = isDiv && (possibleEditorElement as Element).classList.length > 0;
    if (isDivOutsideEditor) {
      possibleEditorElement = null;
      break;
    }

    possibleEditorElement = possibleEditorElement.parentNode;
  }

  if (!possibleEditorElement) return false;

  // The document selection remains the same even if the focused/active element changes
  // (like during blur or pressing tab). So the "possible editor element" is cross checked
  // with the current active element.
  const editorIsActive = document.activeElement === possibleEditorElement;
  return editorIsActive;
};

/**
 * Gets what styles are currently applied at the user's cusor.
 */
export const useActiveStyles = () => {
  // ref and state are maintained and kept in sync manually to allow performant
  // isEqual checks against the most recent activeStyle object.
  const activeStylesRef = useRef(defaultState);
  const [activeStyles, setActiveStyles] = useState(defaultState);

  // Value changes are debounced to prevent icon flickering due to quickly
  // changing partial states
  const debouncedActiveStyles = useDebounceNotByReference(activeStyles);

  useEffect(() => {
    // Check the active styles on an interval as a last resort for edge cases.
    // This updates slugishly so isn't relied on 100% of the time, but it does catch the
    // following:
    // - onKeyDown events for backspacing an empty styling tag
    //   ("<div>word<strong>{cursor here}</strong></div>") can be eaten by CKE on
    //   Chrome (works in IE)
    // - `queryCommandState` results after an event can be out of sync for up to 500ms in IE.
    //   This is most prominently seen when selecting the end of a line with styles.
    const updateActiveStyles = () => {
      const currentActiveStyles = {
        bold: getStyleStatus(EXEC_COMMAND_BOLD),
        italic: getStyleStatus(EXEC_COMMAND_ITALIC),
        underline: getStyleStatus(EXEC_COMMAND_UNDERLINE),
        inEditor: isCKEChild(document.getSelection()?.anchorNode),
      };

      // Only update state if the styles have changed
      if (isEqual(activeStylesRef.current, currentActiveStyles)) return;
      activeStylesRef.current = currentActiveStyles;
      setActiveStyles(currentActiveStyles);
    };
    syncRegister.registerSync({ id: SYNC_ID, onSync: updateActiveStyles });

    // Alternatively, trigger a sync on selection and related key down events since they'll
    // feel more immediate for the cases they handle.
    const throttledTriggerSync = throttle(
      () => syncRegister.triggerSync(),
      EXPLICIT_SYNC_THROTTLE_TIME
    );
    document.addEventListener('selectionchange', throttledTriggerSync);
    document.addEventListener('onKeyDown', (e: Event) => {
      const keyboardEvent = e as KeyboardEvent;
      if (!watchedKeyCodes.includes(keyboardEvent.keyCode)) return;
      throttledTriggerSync();
    });

    return () => {
      document.removeEventListener('selectionchange', throttledTriggerSync);
      document.removeEventListener('onKeyDown', throttledTriggerSync);
      syncRegister.unregisterSync(SYNC_ID);
    };
  }, []);

  return debouncedActiveStyles;
};
