import { ChunkOfLines, IndexingData, ModuleMatches } from '../../../@types/state';
import { matchChunkTitlesToModulesAndDeleteDuplicates } from '../matchChunkTitlesToModulesAndDeleteDuplicates';

export const addModulesToChunks = <T extends ChunkOfLines>({
  conditionKeywords,
  chunks,
}: Pick<IndexingData, 'conditionKeywords'> & {
  chunks: T[];
}): {
  chunksWithModules: (T & { modules: string[] })[];
  allModuleMatchesByTitle: Record<string, ModuleMatches>;
} => {
  const { chunksWithModulesAndKeywords, allModuleMatchesByTitleUpdates } =
    matchChunkTitlesToModulesAndDeleteDuplicates({
      allModuleMatchesByTitle: {},
      conditionKeywords,
      conditionQualifiers: {},
      conditionsByModule: {},
      exactMatchRegardTitlesInNoteByModule: {},
      chunks,
    });

  return {
    chunksWithModules: chunksWithModulesAndKeywords,
    allModuleMatchesByTitle: allModuleMatchesByTitleUpdates,
  };
};
