import * as Sentry from '@sentry/browser';

import { syncTimeAboveThreshold } from '~/app/analytics/track';
import { RegardNoteError } from '~/app/errors';

/**
 * When a sync takes longer than this threshold, a sentry error is captured.
 */
const SYNC_TIME_ERROR_THRESHOLD = 1000;

export const assertSyncTimeWithinThreshold = (syncStart: number) => {
  const syncTime = new Date().valueOf() - syncStart;
  if (syncTime > SYNC_TIME_ERROR_THRESHOLD) {
    syncTimeAboveThreshold({ timeToSync: syncTime });
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtra('timeToSync', syncTime);
      Sentry.captureException(new RegardNoteError('RTE sync time above threshold'));
    });
  }
};
