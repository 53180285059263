import { forwardRef } from 'react';

export type InputTagProps = Pick<
  JSX.IntrinsicElements['input'],
  | 'children'
  | 'className'
  | 'css'
  | 'disabled'
  | 'onBlur'
  | 'onFocus'
  | 'onMouseDown'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onMouseUp'
  | 'onTouchEnd'
  | 'onTouchMove'
  | 'onTouchStart'
  | 'style'
  | 'type'
  | 'value'
> & {
  children?: undefined;
};

export const InputTag = forwardRef<HTMLInputElement, InputTagProps>((props, ref) => (
  <input ref={ref} {...props} />
));
InputTag.displayName = 'InputTag';
