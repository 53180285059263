import { FC } from 'react';

import { epicTheme } from './epicTheme';
import doctorPng from './doctor.png';
import patientPng from './patient.png';

const EpicBlueHorizontalRule: FC = () => (
  <div
    style={{
      backgroundColor: epicTheme.colors.hrBlue,
      height: 1,
      margin: '0 -16px',
    }}
  />
);

const EpicYellowBox: FC = ({ children }) => (
  <div
    style={{
      backgroundColor: epicTheme.colors.infoYellow,
      borderRadius: 4,
      color: epicTheme.colors.black,
      padding: '8px 16px',
    }}
  >
    {children}
  </div>
);

export const EpicSidebar: FC = () => (
  <div
    style={{
      color: epicTheme.colors.white,
      overflow: 'auto',
      maxWidth: 280,
      minWidth: 240,
      width: '18%',
    }}
  >
    <div
      style={{
        overflow: 'hidden', // contains negative margins from hrs
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 16px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            margin: '16px 0',
            height: 104,
            width: 104,
          }}
        >
          <img alt="patient" src={patientPng} />
        </div>
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>Claudia Lopez</span>
          <br />
          Female, 59 y.o., 12/27/1962
          <br />
          MRN: 112233
          <br />
          Room/Bed: 1122-01
          <br />
          Code: FULL (no ACP docs)
          <br />
          Patient Class: Inpatient
          <br />
          PCP: Brown DO, Lindsay M
          <br />
        </div>
      </div>
      <div style={{ margin: 16 }}>
        <EpicYellowBox>Infection: Gastro-intestinal rule-out</EpicYellowBox>
      </div>
      <div style={{ display: 'flex', margin: '8px 16px' }}>
        <div
          style={{
            borderRadius: '50%',
            flex: '0 0 48px',
            height: 48,
            width: 48,
          }}
        >
          <img alt="doctor" src={doctorPng} />
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            marginLeft: 4,
          }}
        >
          James L Marshall, DO
          <br />
          Attending
        </div>
      </div>
      <EpicBlueHorizontalRule />
      <div style={{ margin: '8px 16px' }}>
        <EpicYellowBox>Allergies (3)</EpicYellowBox>
      </div>
      <EpicBlueHorizontalRule />
      <div style={{ margin: '8px 32px' }}>
        Ht: 157 cm (5’2”)
        <br />
        Wt: 163 kg (340 lbs)
        <br />
        PCP: Brown DO, Lindsay M
      </div>
      <EpicBlueHorizontalRule />
      <div style={{ margin: '8px 32px' }}>
        <span style={{ color: epicTheme.colors.infoYellow, fontWeight: 'bold' }}>
          Admitted: 5/5/2021
        </span>
        <br />
        Patient Class: Inpatient
        <br />
        Expected Discharge: Tomorrow
      </div>
      <EpicBlueHorizontalRule />
      <div style={{ margin: '8px 32px' }}>
        <span style={{ color: epicTheme.colors.infoYellow, fontWeight: 'bold' }}>
          Active Meds (12)
        </span>
        <br />
        Scheduled (5)
        <br />
        PRN (7)
      </div>
      <EpicBlueHorizontalRule />
      <div style={{ margin: '8px 32px' }}>
        Care Team
        <br />
        <br />
        Mary Smith, APN
        <br />
        PCP - General
      </div>
    </div>
  </div>
);
