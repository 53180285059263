import Color from 'color';
import _ from 'lodash';

import { theme } from '~/app/reuse';
import { CONDITION_TEXTAREA_LINE_HEIGHT } from '../const';

const getBackgroundColor = ({ statusNew }: { statusNew: boolean }) => {
  if (statusNew) return theme.colors.green;
  return theme.colors.none;
};

export const getBackgroundStyle = _.memoize(
  ({ statusNew }: { statusNew: boolean }) =>
    ({
      backgroundColor: getBackgroundColor({ statusNew }),
      borderBottomRightRadius: theme.other.borderRadius4,
      borderTopRightRadius: theme.other.borderRadius4,
      height: CONDITION_TEXTAREA_LINE_HEIGHT,
      left: 0,
      position: 'absolute',
      top: 0,
      width: 24,
    } as const)
);

export const getIconSyle = _.memoize(
  ({ statusNew, statusNone }: { statusNew: boolean; statusNone: boolean }) =>
    ({
      fill: statusNew ? Color(theme.colors.white).alpha(0.7).string() : theme.colors.grey4,
      left: 4,
      position: 'absolute',
      top: 2,
      ...(statusNone ? { display: 'none' } : undefined),
    } as const)
);
