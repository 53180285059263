import { keyframes } from '@emotion/react';
import { theme } from '~/app/reuse';

// Layout

const ICON_SIZE = 30;
const ICON_AND_TEXT_GAP = 16;

export const replyBaseStyle = {
  marginBottom: 8,
  overflow: 'hidden', // helps to contain tooltips in IE and not trigger horizontal scrollbars
  position: 'relative',
} as const;

export const style = {
  ...replyBaseStyle,
  backgroundColor: theme.colors.grey6,
  borderBottom: `1px solid ${theme.colors.grey4}`,
  borderTop: `1px solid ${theme.colors.grey4}`,
  padding: '20px 24px 0',
} as const;

export const copiedStyle = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
} as const;

export const copiedBackgroundStyle = {
  backgroundColor: theme.colors.lightBlue2,
  bottom: 0,
  left: 0,
  opacity: 0.6,
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 3,
} as const;

export const copiedTextStyle = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.other.borderRadius4,
  color: theme.colors.blueRegular,
  fontSize: 18,
  fontWeight: 600,
  left: '50%',
  padding: '6px 0',
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  width: 280,
  zIndex: 3,
} as const;

export const replyInnerStyle = {
  position: 'relative',
} as const;

export const replyLeftStyle = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  top: 0,
} as const;

export const replyIconStyle = {
  borderRadius: '50%',
  height: ICON_SIZE,
  width: ICON_SIZE,
} as const;

export const replyIconImageStyle = {
  width: '100%',
  height: '100%',
} as const;

export const replyRightStyle = {
  paddingLeft: ICON_SIZE + ICON_AND_TEXT_GAP,
  minHeight: ICON_SIZE,
} as const;

export const replyPlaceholderTextStyle = {
  visibility: 'hidden',
  whiteSpace: 'pre-wrap',
} as const;

export const replyVisibleTextStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  whiteSpace: 'pre-wrap',
} as const;

// Cursor

export const cursorContainerStyle = {
  display: 'inline-block',
  position: 'relative',
  width: 10,
} as const;

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const cursorCss = {
  animation: `${blink} 900ms infinite`,
};

export const cursorStyle = {
  backgroundColor: theme.colors.blurple,
  height: 16,
  position: 'absolute',
  right: 0,
  transform: 'translateY(-13px)',
  width: 8,
} as const;
