import { FC, useState } from 'react';

import { TrackFirstUserInteractionListener } from './TrackFirstUserInteractionListener';

let staticInteracted = false;

export const TrackFirstUserInteraction: FC = () => {
  const [interacted, setInteracted] = useState(staticInteracted);
  const setInteractedTrue = () => {
    staticInteracted = true;
    setInteracted(true);
  };

  // Get rid of the event listeners once we've interacted
  return interacted ? null : (
    <TrackFirstUserInteractionListener setInteracted={setInteractedTrue} />
  );
};
