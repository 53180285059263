import { NotificationLevel } from '~/app/constants';
import { isSalesDemoMode, isReview } from '~/app/flags';
import { EligibleHtmlBaseNote, NotificationAttrs } from '~/app/@types/state';
import { AppDispatch } from '~/app/store';

import { openClearNoteDraftModalAction } from '~/app/actions/ui';
import { NotificationType } from '~/app/@types';
import { createNotification } from './createNotification';
import { DraftData } from './notifications';

export const addBaseNoteChangedNotification = (
  notifications: NotificationAttrs[],
  dispatch: AppDispatch,
  draft: DraftData,
  selectedBaseNote: EligibleHtmlBaseNote
) => {
  if (isSalesDemoMode) return;
  if (isReview()) return;

  // Check base note and draft note hashes to see if the basenote has changed
  const baseNoteHasChanged =
    draft.baseNoteContentHash != null &&
    selectedBaseNote.contentHash != null &&
    draft.baseNoteContentHash !== selectedBaseNote.contentHash;
  if (!baseNoteHasChanged) return;

  const notification = createNotification({
    type: NotificationType.BaseNoteChanged,
    level: NotificationLevel.Warn,
    buttonText: 'Update Base Note',
    content: (
      <div data-cy-update-base-note-notification-content>
        A newer version of the current base note is available. <br />
        Update base note? (current draft will be lost)
      </div>
    ),
    allowDismissal: true,
    buttonFunc: () => dispatch(openClearNoteDraftModalAction()),
  });
  notifications.push(notification);
};
