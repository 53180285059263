import { FC } from 'react';

import { EpicCenterPane } from './EpicCenterPane';
import { EpicRightPane } from './EpicRightPane';
import { EpicSidebar } from './EpicSidebar';
import { epicTheme } from './epicTheme';

export const EpicDemo: FC = () => (
  <div
    css={{
      '*': {
        boxSizing: 'border-box',
      },
    }}
    data-cy-demo-epic
    style={{
      backgroundColor: epicTheme.colors.darkBlue,
      display: 'flex',
      height: '100%',
      overflow: 'auto',
    }}
  >
    <EpicSidebar />
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '7fr 2px 5fr',
        width: '82%',
      }}
    >
      <EpicCenterPane />
      <div
        style={{
          backgroundColor: epicTheme.colors.darkBlue,
        }}
      />
      <EpicRightPane />
    </div>
  </div>
);
