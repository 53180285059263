import { SummaryP, SummaryOl, SummarySpanB } from './common';

export const hyperlipidemiaSummary = (
  <>
    <SummaryP first>
      Hyperlipidemia recommendations are based on 2019 ACC/AHA Guidelines for the Primary Prevention
      of Cardiovascular Disease.
    </SummaryP>
    <SummaryOl>
      <li>
        Regard starts by collecting relevant labs starting from 90 days before the beginning of the
        encounter. These include triglyceride, total cholesterol, HDL cholesterol, LDL cholesterol
        (calculated or direct).
      </li>
      <li>
        A previous diagnosis of atherosclerotic cardiovascular disease (ASCVD) will trigger a{' '}
        <SummarySpanB>positive</SummarySpanB> diagnosis.
      </li>
      <li>The module will check for a history of diabetes.</li>
      <li>
        The program will check for <SummarySpanB>hypertriglyceridemia</SummarySpanB> by looking at
        triglyceride labs starting with the most recent, and is considered positive for a value ≥
        500 mg/dL.
      </li>
      <li>
        If the patient is ≥ 20 years of age and has an available LDL (direct) or LDL (calculated)
        labs available, the program will check for <SummarySpanB>hypercholesterolemia</SummarySpanB>{' '}
        by searching for the most recent direct or calculated LDL that is ≥ 190 mg/dL.
      </li>
      <li>
        If the patient is not positive for ASCVD, hypertriglyceridemia, or hypercholesterolemia it
        will run an ASCVD calculation. If the ASCVD calculation &lt; 5% it will return a{' '}
        <SummarySpanB>negative diagnosis</SummarySpanB>.
      </li>
      <li>
        If the patient does not have a statin prescribed, Regard will recommend adding one based on
        the patient’s risk factors including: ASCVD history, age, hyperlipidemia,
        hypertriglyceridemia or hypercholesterolemia, diabetes, and LDL.
      </li>
    </SummaryOl>
  </>
);
