const filterRules = CKEDITOR.env.ie
  ? [(data: string) => data.replace(/\s+data-cke-expando=".*?"/g, '')]
  : [];

function applyRules(data: string) {
  return CKEDITOR.tools.array.reduce(filterRules, (currentData, rule) => rule(currentData), data);
}

/**
 * Contains a snapshot of the editor content and selection at a given point in time.
 * https://github.com/ckeditor/ckeditor4/blob/master/plugins/undo/plugin.js#L810
 *
 * @private
 * @class CKEDITOR.plugins.undo.Image
 * @constructor Creates an Image class instance.
 * @param {CKEDITOR.editor} editor The editor instance on which the image is created.
 */
export class EditorImage {
  editor: CKEDITOR.editor;

  contents: boolean | string = false;

  bookmarks: '' | CKEDITOR.dom.bookmark2[];

  constructor(editor: CKEDITOR.editor) {
    this.editor = editor;

    const contents = editor.getSnapshot();

    if (contents) {
      this.contents = applyRules(contents);
    }

    const selection = contents && editor.getSelection();
    this.bookmarks = selection && selection.createBookmarks2(true);
  }

  /**
   * @param {CKEDITOR.plugins.undo.Image} otherImage Image to compare to.
   * @returns {Boolean} Returns `true` if content in `otherImage` is the same.
   */
  equalsContent(otherImage: EditorImage) {
    const thisContents = this.contents;
    const otherContents = otherImage.contents;

    if (thisContents !== otherContents) return false;

    return true;
  }
}
