import { useMemo } from 'react';

import { get } from '~/app/utils';
import { createSelector, useShallowEqualSelector } from '../../../store';

const EMPTY_MODULES: string[] = [];

export const useConditionModules = (id: string): string[] => {
  const getConditionModules = useMemo(
    () =>
      createSelector(
        (state) => get(state.regardNote.conditionsById, id)?.modules,
        (modules) => modules ?? EMPTY_MODULES
      ),
    [id]
  );

  const suggestions = useShallowEqualSelector(getConditionModules);

  return suggestions;
};
