import { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { DEFAULT_EHR, Ehr, ehrTypes } from './const';

export const getAndSetEhrParam = ({
  navigate,
  search,
}: {
  navigate({ search }: { search: string }): void;
  search: string;
}): Ehr => {
  const params = new URLSearchParams(search);
  const param = params.get('ehr');

  // we have a correctly formed param
  if (param === 'cerner' || param === 'classic' || param === 'epic') return param;

  // fall back to default and reset the param
  params.set('ehr', DEFAULT_EHR);
  navigate({
    search: params.toString(),
  });

  return DEFAULT_EHR;
};

export const useEhrParam = (): [Ehr, () => void] => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [ehr, setEhr] = useState<Ehr>(getAndSetEhrParam({ navigate, search }));

  const toggleEhr = useCallback(() => {
    const params = new URLSearchParams(search);
    setEhr((value) => {
      // eslint-disable-next-line no-nested-ternary
      const nextEhr = ehrTypes[(ehrTypes.indexOf(value) + 1) % ehrTypes.length];

      params.set('ehr', nextEhr);
      navigate({
        search: params.toString(),
      });

      return nextEhr;
    });
  }, [navigate, search, setEhr]);

  return [ehr, toggleEhr];
};
