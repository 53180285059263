import { useMemo } from 'react';

import { useMeshedContext } from './MeshedProvider';

import '../../styles/MeshedNoteApp.scss';
import '../../styles/MeshedNoteAppVertical.scss';

export const MIN_APP_WIDTH = '360px';
export const NOTE_DESKTOP_WIDTH = '720px';
export const DESKTOP_GUTTER_WIDTH_VALUE = 32;
export const DESKTOP_GUTTER_WIDTH = `${DESKTOP_GUTTER_WIDTH_VALUE}px`;

export const useMeshedNoteAppStyle = () => {
  const { isVerticalLayout, isScaledHorizontalLayout } = useMeshedContext(
    ({ isVerticalLayout, isScaledHorizontalLayout }) => ({
      isVerticalLayout,
      isScaledHorizontalLayout,
    })
  );
  const gridTemplateColumns = isScaledHorizontalLayout
    ? `minmax(${NOTE_DESKTOP_WIDTH}, 2fr) ${DESKTOP_GUTTER_WIDTH} minmax(${MIN_APP_WIDTH}, 1fr)`
    : `minmax(${MIN_APP_WIDTH}, 1fr) ${DESKTOP_GUTTER_WIDTH} ${MIN_APP_WIDTH}`;
  const meshedNoteAppStyle = useMemo(
    () =>
      ({
        ...(!isVerticalLayout
          ? {
              msGridColumns: gridTemplateColumns,
              gridTemplateColumns,
            }
          : undefined),
      } as const),
    [gridTemplateColumns, isVerticalLayout]
  );

  return meshedNoteAppStyle;
};
