import _ from 'lodash';
import { CSSProperties, FC, ReactNode } from 'react';
import { cernerTheme } from './cernerTheme';

export const CernerTable: FC<{
  label: ReactNode;
  rows: (ReactNode[] | { type: 'header'; label: string })[];
  style?: CSSProperties;
}> = ({ label, rows, style }) => (
  <div style={{ whiteSpace: 'pre-wrap', ...style }}>
    <div
      style={{
        backgroundColor: cernerTheme.colors.lighterGray,
        borderBottom: `1px solid ${cernerTheme.colors.darkBlue}`,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '14px 32px',
      }}
    >
      {label}
    </div>
    <div style={{}}>
      <div>
        {rows.map((row, rowIndex) => {
          if (_.isArray(row)) {
            return (
              <div
                key={rowIndex}
                style={{
                  backgroundColor:
                    rowIndex % 2 === 0 ? cernerTheme.colors.white : cernerTheme.colors.lightGray,
                  display: 'flex',
                  padding: '2px 32px',
                }}
              >
                {row.map((column, columnIndex) => (
                  <div
                    key={columnIndex}
                    style={{
                      flexBasis: 0,
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {column}
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div
              key={rowIndex}
              style={{
                backgroundColor: cernerTheme.colors.headerBlue,
                display: 'flex',
                fontWeight: 600,
                padding: '6px 32px',
              }}
            >
              {row.label}
            </div>
          );
        })}
      </div>
    </div>
  </div>
);
