import { EMPTY_BR_HTML_LINE } from '~/app/constants';
import { NoteBlock } from '~/app/@types/state';
import { linesToHtmlString } from '../linesToHtmlString';
import { removeSubstringFromStartAndEnd } from './removeSubstringFromStartAndEnd';

export const noteBlocksToHtmlString = (noteBlocks: Pick<NoteBlock, 'lines'>[]): HtmlString => {
  let firstFilledNoteBlock = true;
  let result = '';

  // One loop is slightly faster than a map + join
  noteBlocks.forEach(({ lines }) => {
    const htmlString = linesToHtmlString(lines);

    if (htmlString !== EMPTY_BR_HTML_LINE) {
      // don't add an empty note block to the saved draft
      result +=
        (firstFilledNoteBlock // if is first
          ? '' // add nothing before
          : EMPTY_BR_HTML_LINE) + // otherwise, add a blank line before
        removeSubstringFromStartAndEnd(EMPTY_BR_HTML_LINE, htmlString);
      firstFilledNoteBlock = false;
    }
  });

  return result as HtmlString;
};

export const noteBlocksToHtmlStringSlower = (noteBlocks: Pick<NoteBlock, 'lines'>[]) =>
  noteBlocks.map(({ lines }) => linesToHtmlString(lines)).join(EMPTY_BR_HTML_LINE) as HtmlString;
