import { ActionCreator } from 'redux';
import {
  Diagnosis,
  Action,
  DispatchActionFn,
  PayloadAction,
  PayloadActionCreator,
  Source,
  UiState,
} from '../@types/state';

type CloseFeedbackAction = Action<'close user feedback widget'>;
export const closeFeedback: ActionCreator<CloseFeedbackAction> = () => ({
  type: 'close user feedback widget',
});

type OpenFeedbackAction = Action<'open user feedback widget'>;
export const openFeedback: ActionCreator<OpenFeedbackAction> = () => ({
  type: 'open user feedback widget',
});

type CloseTabPanelAction = Action<'close tab panel'>;
type OpenTabPanelAction = Action<'open tab panel'>;

type CloseDxDetailsAction = Action<'close dx details'>;
export const closeDxDetails: ActionCreator<CloseDxDetailsAction> = () => ({
  type: 'close dx details',
});

type OpenDxDetailsAction = PayloadAction<
  'open dx details',
  {
    conditionId: string;
    dxDetailsButtonClicked: boolean;
    selectedModule: string;
  }
>;
export const openDxDetails: PayloadActionCreator<OpenDxDetailsAction> = (payload) => ({
  type: 'open dx details',
  payload,
});

type OpenClearNoteDraftModalAction = Action<'open clear note draft modal'>;
export const openClearNoteDraftModalAction: ActionCreator<OpenClearNoteDraftModalAction> = () => ({
  type: 'open clear note draft modal',
});

type CloseClearNoteDraftModalAction = Action<'close clear note draft modal'>;
export const closeClearNoteDraftModalAction: ActionCreator<
  CloseClearNoteDraftModalAction
> = () => ({
  type: 'close clear note draft modal',
});

type SelectDxDetailsTabAction = PayloadAction<'select dx details tab', { selectedModule: string }>;
export const selectDxDetailsTab: PayloadActionCreator<SelectDxDetailsTabAction> = (payload) => ({
  type: 'select dx details tab',
  payload,
});

export type OpenSourceAction = PayloadAction<'open source', Source>;
export const openSource: PayloadActionCreator<OpenSourceAction> = (payload) => ({
  type: 'open source',
  payload,
});
export type DispatchOpenSource = DispatchActionFn<typeof openSource>;

export type ClearOpenSourceAction = Action<'clear open source'>;

export type OpenDxProcessAction = PayloadAction<'open dx process', Diagnosis>;
export const openDxProcess: PayloadActionCreator<OpenDxProcessAction> = (payload) => ({
  type: 'open dx process',
  payload,
});
export type DispatchOpenDxProcess = DispatchActionFn<typeof openDxProcess>;

export type ClearOpenDxProcessAction = Action<'clear open dx process'>;

export type OpenRegard2ModalAction = Action<'open regard2 modal'>;
export type CloseRegard2ModalAction = Action<'close regard2 modal'>;

export type SetLastFocusedEditor = PayloadAction<'set last focused editor', string>;
export const setLastFocusedEditor: PayloadActionCreator<SetLastFocusedEditor> = (payload) => ({
  type: 'set last focused editor',
  payload,
});

export type SetLastClickedKeywordModule = PayloadAction<'set last module clicked in note', string>;
export const setLastClickedKeywordModule: PayloadActionCreator<SetLastClickedKeywordModule> = (
  payload
) => ({
  type: 'set last module clicked in note',
  payload,
});

type SetSaveDraftNoteStatusAction = PayloadAction<
  'set save draft note status',
  Pick<UiState, 'saveDraftNoteStatus' | 'saveDraftNoteApiTimestamp'>
>;
export const setSaveDraftNoteStatus: PayloadActionCreator<SetSaveDraftNoteStatusAction> = (
  payload
) => ({
  type: 'set save draft note status',
  payload,
});

type ShowApplicationVersionAction = PayloadAction<
  'show application version',
  { reduxReplayVersion?: string }
>;
export const showApplicationVersion: PayloadActionCreator<ShowApplicationVersionAction> = (
  payload
) => ({
  type: 'show application version',
  payload,
});

export const CHANGE_TAB = 'change selected tab in side panel';
export const CLOSE_RESOURCES = 'close resources flyout';
export const OPEN_RESOURCES = 'open resources flyout';
export const REMOVE_NOTIFICATION = 'remove a notification';

// Action Types //////////
export type UiAction =
  | ClearOpenDxProcessAction
  | ClearOpenSourceAction
  | CloseDxDetailsAction
  | CloseFeedbackAction
  | CloseTabPanelAction
  | CloseClearNoteDraftModalAction
  | CloseRegard2ModalAction
  | OpenRegard2ModalAction
  | OpenDxDetailsAction
  | OpenDxProcessAction
  | OpenFeedbackAction
  | OpenSourceAction
  | OpenTabPanelAction
  | OpenClearNoteDraftModalAction
  | SelectDxDetailsTabAction
  | SetLastFocusedEditor
  | SetLastClickedKeywordModule
  | SetSaveDraftNoteStatusAction
  | ShowApplicationVersionAction
  | { type: typeof CHANGE_TAB; index: number }
  | { type: typeof CLOSE_RESOURCES }
  | { type: typeof OPEN_RESOURCES }
  | { type: typeof REMOVE_NOTIFICATION; index: number };

// Action Creators //////////

export const changeTab = (index: number): UiAction => ({ type: CHANGE_TAB, index });
export const closeResources = (): UiAction => ({ type: CLOSE_RESOURCES });
export const openResources = (): UiAction => ({ type: OPEN_RESOURCES });
export const removeNotification = (index: number): UiAction => ({
  type: REMOVE_NOTIFICATION,
  index,
});
