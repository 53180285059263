import { SummaryUl, SummaryOl } from './common';

export const diabetesSummary = (
  <SummaryOl>
    <li>
      Condition will trigger after review of the patient’s history for at least two of three
      criteria:
      <SummaryUl innerList>
        <li>Prior chronic history of diabetes</li>
        <li>hemoglobin A1c &ge; 6.5% on any prior lab</li>
        <li>
          any active or held home diabetes medications (from groups: SGLT2 inhibitors, GLP1
          agonists, anti-diabetic non-insulin drugs, insulin analogues).
        </li>
      </SummaryUl>
    </li>
    <li>Regard will further diagnose type 1 or type 2 diabetes based on the available data.</li>
  </SummaryOl>
);
