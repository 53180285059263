export const PASTE_ELEMENT_ID = 'paste-content';

// We need to catch backspace keydown events in the iframe to prevent
// the browser from attempting to navigate backwards, which to a user,
// looks like regard is refreshing for no reason
const baseHtmlContent = `
  <script>
    var backspaceCode = 8;
    function iframeBackspaceDisabler(event) {
      if (event.keyCode === backspaceCode) {
        event.preventDefault();
      }
    }
    document.addEventListener("keydown", iframeBackspaceDisabler, false);
  </script>
  <div id="${PASTE_ELEMENT_ID}" style="position: fixed;" />
`;

/**
 * Creates an iframe to use as a copy source that doesn't include
 * all the extra styling in the main document.
 *
 * Note: Creating the iframe ahead of time also prevents the need
 *   for asynchronous code during copy, which breaks the direct
 *   user action -> copy logic browsers require for security reasons.
 *
 * Note: Only a single iframe will ever be created. Subsequent calls
 *   will reuse the existing one.
 */
let iframe: HTMLIFrameElement | null = null;
(() => {
  iframe = document.createElement('iframe');
  iframe.classList.add('copy-html-to-clipboard-iframe');
  iframe.setAttribute(
    'style',
    'position: absolute; width: 1px; height: 1px; top: 0; left: -100000px;'
  );
  iframe.allow = 'clipboard-write';
  document.body.appendChild(iframe);

  iframe.contentWindow?.document.open();
  iframe.contentWindow?.document.write(baseHtmlContent);
  iframe.contentWindow?.document.close();
})();

export const getClipboardIframe = () => iframe;
