import { memoize } from 'lodash';
import { FC } from 'react';
import { theme } from '~/app/reuse/theme';

const getContainerStyle = memoize(
  (isVerticalLayout) =>
    ({ position: 'relative', width: isVerticalLayout ? '280px' : '480px', height: '16px' } as const)
);

const backgroundBarStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.colors.white,
  opacity: 0.5,
  borderRadius: '4px',
} as const;

const getBarStyle = memoize(
  (percentage: number) =>
    ({
      position: 'absolute',
      width: `${percentage}%`,
      height: '100%',
      backgroundColor: theme.colors.white,
      borderRadius: '4px',
      transition: 'width 1s ease',
    } as const)
);

export const ProgressBar: FC<{ isVerticalLayout: boolean; percentage: number }> = ({
  isVerticalLayout,
  percentage,
}) => (
  <div style={getContainerStyle(isVerticalLayout)}>
    <div style={backgroundBarStyle} />
    <div style={getBarStyle(percentage)} />
  </div>
);
