import _ from 'lodash';
import { shallowEqual } from 'fast-equals';
import { FC, useCallback } from 'react';

import { capitalizeFirstLetter } from '../../../utils';
import { createSelector, useSelector, useDispatch } from '../../../store';
import { Drawer, theme, zIndices } from '../../../reuse';

import { useMeshedContext } from '../MeshedProvider';
import { MIN_APP_WIDTH, DESKTOP_GUTTER_WIDTH_VALUE } from '../useMeshedNoteAppStyle';

const OFFSET_FROM_PARENT = '32px';

const getStyle = _.memoize(
  ({
    isVerticalLayout,
    width,
    isScaledHorizontalLayout,
  }: {
    isVerticalLayout: boolean;
    width: number;
    isScaledHorizontalLayout: boolean;
  }) => {
    const dxDetailsScaledWidth = (width - DESKTOP_GUTTER_WIDTH_VALUE) / 3;
    const drawerWidth = isScaledHorizontalLayout
      ? `calc(${dxDetailsScaledWidth}px - 40px)`
      : `calc(${MIN_APP_WIDTH} - 40px)`;
    return {
      curtain: {
        height: '100%',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      drawer: {
        height: '100%',
        minWidth: `calc(${MIN_APP_WIDTH} - ${OFFSET_FROM_PARENT})`,
        position: 'absolute',
        width: isVerticalLayout
          ? // accounts for a 40px vertical layout gutter
            `calc(100% - 40px - ${OFFSET_FROM_PARENT})`
          : drawerWidth,
      },
      header: {
        fontSize: 14,
        fontWeight: 600,
        height: 40,
        padding: '10px 24px',
      },
    } as const;
  }
);

const typeBarStyle = {
  padding: '4px 24px',
  background: theme.colors.grey5,
} as const;
const processContentStyle = {
  padding: '8px 24px',
} as const;

const openDxProcessSelector = createSelector(
  (state) => state.ui.openDxProcess,
  (diagnosis) => diagnosis
);

export const DxProcessDrawer: FC = () => {
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch({ type: 'clear open dx process' }), [dispatch]);

  const diagnosis = useSelector(openDxProcessSelector, shallowEqual);
  const isOpen = !!diagnosis;

  const { isVerticalLayout, width, isScaledHorizontalLayout } = useMeshedContext(
    ({ isVerticalLayout, width, isScaledHorizontalLayout }) => ({
      isVerticalLayout,
      width,
      isScaledHorizontalLayout,
    })
  );
  const drawerStyle = getStyle({ isVerticalLayout, width, isScaledHorizontalLayout });

  const title = diagnosis ? `${capitalizeFirstLetter(diagnosis.title)} - Condition Summary` : '';
  const coverageType = diagnosis ? `Regard coverage type: ${diagnosis.type}` : '';

  return (
    <Drawer
      close={close}
      content={
        <div>
          <div style={typeBarStyle}>{coverageType}</div>
          <div style={processContentStyle}>{diagnosis?.summary || ''}</div>
        </div>
      }
      data-cy-dx-process-drawer
      enableCurtain={isVerticalLayout}
      isOpen={isOpen}
      style={drawerStyle}
      title={title}
      zIndex={zIndices.processDrawer}
    />
  );
};
