let storedId = 0;

/**
 * A helper to get a unique id.
 * This is used over _.uniqueId() so that the ids
 * can be seeded in tests to make them more desterministic
 * and not shift based on which tests were run previously.
 */
export const uniqueId = () => {
  storedId += 1;
  return `${storedId}`;
};

// eslint-disable-next-line no-underscore-dangle
export const _setUniqueId = (nextId: number) => {
  storedId = nextId;
};

// eslint-disable-next-line no-underscore-dangle
export const _resetUniqueId = () => {
  storedId = 0;
};
