import { FC, memo, MouseEvent } from 'react';

import { buttonsContainerStyle, getNoteHeaderStyle } from './css';
import { useMeshedContext } from '../MeshedProvider';
import { CopyButton } from './CopyButton';
import { SelectBaseNoteButtons } from './SelectBaseNoteButtons';
import { SelectBaseNoteCurtain } from './SelectBaseNoteCurtain';
import { SelectBaseNoteDropdown } from '../selectBaseNoteDropdown';
import { useController } from './useController';
import { SelectBaseNoteTitle } from './SelectBaseNoteTitle';
import { NoteContainerHeaderDefaultTitle } from './noteContainerHeaderDefaultTitle';
import { SelectBaseNotePreview } from './SelectBaseNotePreview';
import { getIsBlankBaseNote } from '../../../controllers/regardNote';

type NoteContainerHeaderProps = {
  copied: boolean;
  onCopyClick(e: MouseEvent<HTMLButtonElement>): void;
  timestamp: ISODateString;
  unreadModalIsOpen: boolean;
};
export const NoteContainerHeader: FC<NoteContainerHeaderProps> = memo(
  ({ copied, onCopyClick, timestamp, unreadModalIsOpen }) => {
    const {
      baseNotes,
      currentBaseNote,
      isSelecting,
      onCancelSelection,
      onStartSelection,
      onSubmitSelection,
      selectedBaseNote,
      setSelectedBaseNote,
    } = useController();

    const hasSelectedNewBaseNote = !!selectedBaseNote && selectedBaseNote !== currentBaseNote;

    const disabled = unreadModalIsOpen || copied;

    const { isScaledHorizontalLayout } = useMeshedContext(({ isScaledHorizontalLayout }) => ({
      isScaledHorizontalLayout,
    }));
    return (
      <>
        <div style={getNoteHeaderStyle({ isScaledHorizontalLayout, isSelecting })}>
          {isSelecting ? (
            <SelectBaseNoteTitle numberOfAvailableBaseNotes={baseNotes.length} />
          ) : (
            <NoteContainerHeaderDefaultTitle
              baseNotesCount={baseNotes.length}
              baseNoteTimestamp={
                getIsBlankBaseNote(currentBaseNote) ? undefined : currentBaseNote.effective
              }
              disabled={disabled}
              onStart={onStartSelection}
              rawNoteType={currentBaseNote?.rawNoteType ?? ''}
              timestamp={timestamp}
            />
          )}
          <SelectBaseNoteDropdown
            baseNotes={baseNotes}
            currentBaseNote={currentBaseNote}
            isOpen={isSelecting}
            onSelect={setSelectedBaseNote}
            selectedBaseNote={selectedBaseNote}
          />
          <div style={buttonsContainerStyle}>
            {isSelecting ? (
              <SelectBaseNoteButtons
                isSubmitEnabled={hasSelectedNewBaseNote}
                onCancel={onCancelSelection}
                onSubmit={onSubmitSelection}
              />
            ) : (
              <CopyButton copied={copied} disabled={disabled} onClick={onCopyClick} />
            )}
          </div>
        </div>
        <SelectBaseNotePreview isOpen={isSelecting} selectedBaseNote={selectedBaseNote} />
        <SelectBaseNoteCurtain isOpen={isSelecting} onClick={onCancelSelection} />
      </>
    );
  }
);
NoteContainerHeader.displayName = 'NoteContainerHeader';
