import { FC } from 'react';

import { DxDetailLinesSection } from '../dxDetailLinesSection';
import { DxDetailAdditionalSection } from '../dxDetailAdditionalSection';
import { DxDetailTable } from '../dxDetailTable';

const rootStyle = {} as const;

const rootNotSelctedStyle = {
  ...rootStyle,
  display: 'none',
} as const;

export const DxDetailTabContent: FC<{ module: string; selected: boolean }> = ({
  module,
  selected,
}) => (
  <div data-cy-dx-detail-tab-content={module} style={selected ? rootStyle : rootNotSelctedStyle}>
    <DxDetailLinesSection module={module} />
    <DxDetailAdditionalSection module={module} />
    <DxDetailTable module={module} />
  </div>
);
