import { FC, ReactNode } from 'react';

import { EpicCompactTable } from './EpicCompactTable';
import { epicTheme } from './epicTheme';
import { DemoImageRow, DemoRow } from '../demoData';

export const EpicCompactTableWithDescription: FC<{
  rows: (DemoRow | DemoImageRow)[];
  descriptionBody: ReactNode;
}> = ({ rows, descriptionBody }) => (
  <>
    <EpicCompactTable
      rows={rows}
      style={{
        flex: '0 0 280px',
        maxHeight: '100%',
        overflow: 'auto',
        textTransform: 'capitalize',
      }}
    />
    <div style={{ flex: '0 0 20px', height: '100%' }} />
    <div
      style={{
        backgroundColor: epicTheme.colors.white,
        border: `1px solid ${epicTheme.colors.darkBlue}`,
        flex: '1 0 320px',
        maxHeight: '100%',
        maxWidth: 500,
        overflow: 'auto',
        padding: '16px 24px',
        whiteSpace: 'pre-wrap',
      }}
    >
      {descriptionBody}
    </div>
    <div style={{ flex: '0 0 20px', height: '100%' }} />
  </>
);
