import { FC } from 'react';

import { useAdditionalDxDetails } from './useAdditionalDxDetails';
import { TitledSmartAccordion } from '../../../reuse/titledSmartAccordion';
import { SourceTable } from '../sourceTable';
import { displayComplaintSection } from '../../../controllers/stringifyItems';

const innerCss = {
  padding: '0 16px 12px',
  '& ul': {
    margin: 0,
    padding: 0,
  },
  '& li': {
    listStyleType: 'none',
    paddingLeft: 16,
    position: 'relative',
  },
  '& li::before': {
    content: '"- "',
    position: 'absolute',
    left: 8,
  },
} as const;

export const DxDetailAdditionalSection: FC<{
  module: string;
}> = ({ module }) => {
  const { general, sourceTableSections } = useAdditionalDxDetails(module);
  const hasAdditionalDxDetails = !!general.length || !!sourceTableSections.length;

  return hasAdditionalDxDetails ? (
    <TitledSmartAccordion openByDefault title="Additional Dx Details">
      <div css={innerCss}>
        <ul>
          {displayComplaintSection(general).map((i) => (
            <li {...i} />
          ))}
        </ul>
        <SourceTable module={module} sourceTableSections={sourceTableSections} />
      </div>
    </TitledSmartAccordion>
  ) : null;
};
