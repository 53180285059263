import { Keyword } from '../../@types/state';

export const enum CharacterItemTagType {
  TAG = 'tag',
  KEYWORD = 'keyword',
}

export interface CharacterItemConditionTag {
  type: CharacterItemTagType.KEYWORD;
  keyword: Keyword;
}

export interface CharacterItemHtmlTag {
  type: CharacterItemTagType.TAG;
  tag: string;
  // Used for distinguishing different divider tags from each other
  id: string;
}

// Strings treated as a shorthand for CharacterItemHtmlTag
export type CharacterItemTag = CharacterItemHtmlTag | CharacterItemConditionTag;

export interface CharacterItem {
  character: string;
  // Original character string if a conversion (like escaped characters)
  original?: string;
  tags: CharacterItemTag[];
}

export type CharacterItems = CharacterItem[];

export const enum HtmlTreeNodeType {
  TEXT = 'text',
  TAG = 'tag',
  // For use in Condition Titles
  KEYWORD = 'keyword',
}

export interface TextNode {
  type: HtmlTreeNodeType.TEXT;
  text: string;
  // Original text string if any characters were converted
  originalText?: string;
}

export interface TagNode {
  type: HtmlTreeNodeType.TAG;
  tag: string;
  children?: HtmlTree;
}

export interface KeywordNode {
  type: HtmlTreeNodeType.KEYWORD;
  keyword: Keyword;
  children?: HtmlTree;
}

export type HtmlTreeNode = TextNode | TagNode | KeywordNode;
export type HtmlTree = HtmlTreeNode[];
