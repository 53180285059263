import { FC } from 'react';
import { useTemporarilyTrueBoolean } from '~/app/utils';
import { ChatReplyLiked } from '../../detailsProvider';
import {
  copiedBackgroundStyle,
  copiedStyle,
  copiedTextStyle,
  cursorContainerStyle,
  cursorCss,
  cursorStyle,
  replyPlaceholderTextStyle,
  replyIconStyle,
  replyInnerStyle,
  replyLeftStyle,
  replyRightStyle,
  style,
  replyVisibleTextStyle,
  replyIconImageStyle,
} from './style';
import max from './max.png';
import { MaxChatAiResponseButtons } from './maxChatAiResponseButtons';
import { ChatReplyStatus } from '../../detailsProvider/chatApiReducer';
import { MaxChatAiResponseCitation } from './maxChatAiResponseCitation';

// NOTE: keep this consistent with the BE
const CITATION_REFERENCE_PATTERN = /(\[\^\d+\^\])/g;
const CITATION_REFERENCE_NUMBER_PATTERN = /\[\^(\d+)\^\]/g;
const MAX_CITATION_CONTENT_LENGTH = 100;

// Since the reply from ChatGPT is streamed in, partial citation references arise if a well-formed citation reference
// was cut off due to streaming. Rather than display characters that will be removed when the reference completely comes in,
// we use this regex to remove those characters.
const PARTIAL_CITATION_REFERENCE_AT_END_PATTERN = /\[(?:\^(?:\d+(?:\^)?)?)?$/;

/**
 * Given text containing inline citation references (like [^1^]) and a mapping from citation reference to citation content,
 * return a list of React components where plain text stays as is but citation references become expandable spans which
 * display the citation content upon hover.
 */
const getPlainTextAndInlineCitations = ({
  text,
  citations,
}: {
  text: string;
  citations: Record<string, string | undefined>;
}) =>
  text
    .replace(PARTIAL_CITATION_REFERENCE_AT_END_PATTERN, '')
    .split(CITATION_REFERENCE_PATTERN)
    .map((chunk, i) => {
      const referenceNumberMatch = CITATION_REFERENCE_NUMBER_PATTERN.exec(chunk);
      if (referenceNumberMatch) {
        const referenceNumber = referenceNumberMatch[1];
        const quote = citations[referenceNumber];

        return quote ? (
          <MaxChatAiResponseCitation
            key={`${i}-${chunk}`}
            citationNumber={Number(referenceNumber)}
            quote={
              quote.length > MAX_CITATION_CONTENT_LENGTH
                ? `${quote.slice(0, MAX_CITATION_CONTENT_LENGTH - 3)}...`
                : quote
            }
          />
        ) : null; // If the citation has no quote, don't render it
      }
      return chunk;
    });

export const MaxChatAiResponse: FC<{
  index: number;
  liked: ChatReplyLiked;
  status: ChatReplyStatus;
  text: string;
  citations: Record<string, string | undefined>;
}> = ({ index, liked, status, text, citations }) => {
  const [isCopied, { setTemporarilyTrue: setIsCopied }] = useTemporarilyTrueBoolean(2000);

  const isGenerating = ['processing', 'responding'].includes(status);
  const cursor = isGenerating && (
    <span data-cy-chat-reply-cursor style={cursorContainerStyle}>
      <span css={cursorCss} style={cursorStyle} />
    </span>
  );
  const replyText = (
    <>
      {getPlainTextAndInlineCitations({ text, citations })}
      {cursor}
    </>
  );

  return (
    <div data-cy-chat-ai-response style={style}>
      {isCopied && (
        <div style={copiedStyle}>
          <div style={copiedBackgroundStyle} />
          <div style={copiedTextStyle}>Copied to clipboard</div>
        </div>
      )}
      <div style={replyInnerStyle}>
        <div style={replyLeftStyle}>
          <div style={replyIconStyle}>
            <img alt="Max" src={max} style={replyIconImageStyle} />
          </div>
        </div>
        <div style={replyRightStyle}>
          <div style={replyPlaceholderTextStyle}>{replyText}</div>
          <div style={replyVisibleTextStyle}>{replyText}</div>
        </div>
      </div>
      <MaxChatAiResponseButtons
        index={index}
        liked={liked}
        onCopy={setIsCopied}
        responseText={text.replace(CITATION_REFERENCE_PATTERN, '')}
      />
    </div>
  );
};
