import _ from 'lodash';

import { ChunkOfLines } from '~/app/@types/state';
import { EMPTY_BR_HTML_LINE } from '~/app/constants';

import { getHtmlFooterIndicies } from '../getFooterIndicies';

export const splitFooterFromChunksWithLines = ({
  chunksWithLines,
  splitFooterInto,
}: {
  chunksWithLines: ChunkOfLines[];
  splitFooterInto: 'separateChunk' | 'separateChunkAtLastIndex' | 'none';
}): {
  // NOTE: In some cases, a footer chunck will simply not be present in this list.
  chunksWithFooter: ChunkOfLines[];
} => {
  // 0. Not splitting footer; abort
  if (splitFooterInto === 'none') {
    return {
      chunksWithFooter: chunksWithLines,
    };
  }

  // 1. Retrieve the location of the footer
  const { footerChunkIndex, footerLineIndex, whitespaceFooter } = getHtmlFooterIndicies({
    chunksWithLines,
    isAtLastIndex: splitFooterInto === 'separateChunkAtLastIndex',
  });

  // 2. If a footer was not found; abort...
  if (footerChunkIndex === -1) {
    return {
      chunksWithFooter: chunksWithLines,
    };
  }

  // 3. Get the non-footer chunks
  const nonFooterChunks = chunksWithLines.slice(0, footerChunkIndex);

  // 4.a. Get the footer lines. These could still contain non-footer lines at
  //  this point; we'll have to look at the `footerLineIndex` next
  const footerChunks = chunksWithLines.slice(footerChunkIndex);
  const footerChunksType =
    footerChunks[0].type === 'shelvedCondition' ? 'shelvedCondition' : 'condition';
  let footerLines = _.flatten(footerChunks.map(({ lines }) => lines));

  // 4.b. Sanity check
  if (footerChunks.length) {
    // 5. A `footerLineIndex > 0` indicates that these lines must be split
    if (footerLineIndex > 0) {
      const lastNonFooterLines = footerLines.slice(0, footerLineIndex);

      // 6. Trim whitespace, essentially; no need to retain a bunch of blank
      //  lines
      footerLines = whitespaceFooter
        ? [{ html: EMPTY_BR_HTML_LINE, type: 'freetext' }]
        : footerLines.slice(footerLineIndex);

      // 7. If there were any non-footer lines in there, create a new chunk
      //  and push it into the `nonFooterChunks`
      if (lastNonFooterLines.length) {
        nonFooterChunks.push({
          lines: lastNonFooterLines,
          type: footerChunksType,
        });
      }
    }
  }

  return {
    chunksWithFooter: [
      ...nonFooterChunks,
      // Add a footer chunk
      {
        lines: footerLines,
        type: footerChunksType,
      },
    ],
  };
};
