import { BulletNoteStatus } from '~/app/@types';
import {
  ChunkOfLinesWithText,
  NoteBlock,
  MeshedNoteBulletHtmlLine,
  MeshedNoteTitleHtmlLine,
  TitleLine,
  ConditionNoteBlock,
  PreModuleNoteBlock,
  PostModuleNoteBlock,
  ShelfDividerBlock,
  MeshedNoteFreetextHtmlLine,
} from '~/app/@types/state';
import {
  htmlStringToPlainText,
  nth,
  linesToHtmlString,
  stripShelfSignifiers,
  isShelfDividerNoteBlock,
  isFooterChunk,
  plainTextToHtmlLines,
} from '~/app/utils';
import { EMPTY_BR_HTML_LINE, LINE_DIVIDER_TAG } from '~/app/constants';
import { TypingDirection } from '~/app/actions/regardNote';

import { track } from '~/app/analytics';
import { DEFAULT_BULLET_SIGNIFIER } from '../../regex';
import { parseHtmlNoteByTitles } from '../parseHtmlNoteByTitles';
import { ParseMode } from '../parseHtmlNoteByTitles/parseHtmlNoteByTitles';

export type UserAction =
  | {
      addBulletText: string;
      conditionId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'addBullet';
    }
  | {
      oneLinerText: string;
      staleNoteBlocks: NoteBlock[];
      type: 'addOneLiner';
    }
  | {
      bulletStatus: BulletNoteStatus;
      bulletText: string;
      conditionId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'hideOrMonitorBullet';
    }
  | {
      index: number;
      staleNoteBlocks: NoteBlock[];
      type: 'add';
    }
  | {
      conditionId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'dismiss';
    }
  | {
      conditionId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'shelve';
    }
  | {
      fromIndex: number;
      toIndex: number;
      top: number;
      staleNoteBlocks: NoteBlock[];
      type: 'move';
    }
  | {
      conditionId: string;
      direction: TypingDirection;
      staleNoteBlocks: NoteBlock[];
      type: 'merge';
    }
  | {
      conditionHtml: HtmlString;
      staleNoteBlocks: NoteBlock[];
      type: 'restore';
    }
  | {
      noteBlockCaretPos: number;
      conditionHtml: HtmlString;
      conditionId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'typing';
    }
  | {
      noteBlockCaretPos: number;
      noteBlockHtml: HtmlString;
      noteBlockId: string;
      staleNoteBlocks: NoteBlock[];
      type: 'shelfDividerTyping';
    }
  | {
      htmlLines: HtmlString[];
      type: 'initialize';
    }
  | {
      type: 'formatNoteTitles';
      format: 'hash' | 'number';
      staleNoteBlocks: NoteBlock[];
    };

export enum EditedCase {
  AddBullet = 'addBullet',
  HideOrMonitorBullet = 'hideOrMonitorBullet',
  Add = 'add',
  Dismiss = 'dismiss',
  Restore = 'restore',
  Move = 'move',
  Merge = 'merge',
  SplitIntoMultipleChunks = 'splitIntoMultipleChunks',
  DividerTitle = 'dividerTitle',
  SameTitle = 'sameTitle',
  DifferentTitle = 'differentTitle',
  FormatNoteTitles = 'formatNoteTitles',
  NoStaleNoteBlocks = 'noStaleNoteBlocks',
}

export type EditedCaseResult =
  | {
      editedCase: EditedCase.AddBullet | EditedCase.SameTitle;
      editedIndex: number;
      editedPostModuleNoteBlock: PostModuleNoteBlock;
      noteBlockCaretPos: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      bulletStatus: BulletNoteStatus;
      bulletText: string;
      editedCase: EditedCase.HideOrMonitorBullet;
      editedIndex: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.Add;
      index: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      conditionId: string;
      editedCase: EditedCase.Dismiss;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.Restore;
      editedIndex: number;
      newChunkWithLines: ChunkOfLinesWithText;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.Move;
      fromIndex: number;
      staleNoteBlocks: NoteBlock[];
      toIndex: number;
      top: number;
      scrollToEditedIndex: boolean;
    }
  | {
      editedCase: EditedCase.Merge;
      fromIndex: number;
      staleNoteBlocks: NoteBlock[];
      toIndex: number;
    }
  | {
      caretPosition: {
        noteBlockIndex: number;
        caretPos: number;
      } | null;
      editedCase: EditedCase.SplitIntoMultipleChunks;
      editedIndex: number;
      newChunksWithLines: ChunkOfLinesWithText[];
      staleNoteBlockCaretPos: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.DividerTitle;
      editedIndex: number;
      editedDividerNoteBlock: ShelfDividerBlock;
      noteBlockCaretPos: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.DifferentTitle;
      editedIndex: number;
      editedPreModuleNoteBlock: PreModuleNoteBlock;
      noteBlockCaretPos: number;
      staleNoteBlocks: NoteBlock[];
    }
  | {
      editedCase: EditedCase.FormatNoteTitles;
      format: 'hash' | 'number';
      staleNoteBlocks: NoteBlock[];
    }
  | {
      chunksWithLines: ChunkOfLinesWithText[];
      editedCase: EditedCase.NoStaleNoteBlocks;
    };

export const getEditedCase = ({ userAction }: { userAction: UserAction }): EditedCaseResult => {
  switch (userAction.type) {
    case 'addBullet': {
      const { addBulletText, conditionId, staleNoteBlocks } = userAction;

      const editedIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);

      // 1. Index not found

      if (editedIndex === -1) {
        throw new Error(`Could not reprocess note. No condition with id ${conditionId}`);
      }

      // 2. Note section not a condition

      const editedNoteBlock = staleNoteBlocks[editedIndex];

      if (editedNoteBlock.type !== 'condition' && editedNoteBlock.type !== 'shelvedCondition') {
        throw new Error(
          `Note section with id ${conditionId} is not a condition or shelved condition.`
        );
      }

      // 3. AddBullet

      const { lines, text, type, ...rest } = editedNoteBlock as ConditionNoteBlock;

      const addBulletLine: MeshedNoteBulletHtmlLine = {
        bulletSignifier: DEFAULT_BULLET_SIGNIFIER,
        html: `<${LINE_DIVIDER_TAG}>- ${addBulletText}</${LINE_DIVIDER_TAG}>` as HtmlString,
        plainTextWithoutBulletSignifier: addBulletText,
        type: 'bullet',
      };
      const linesWithBullet = [...lines, addBulletLine];
      const textWithBullet = `${text}\n- ${addBulletText}`;
      const noteBlockCaretPos = textWithBullet.length;

      return {
        noteBlockCaretPos,
        editedCase: EditedCase.AddBullet,
        editedIndex,
        editedPostModuleNoteBlock: {
          ...rest,
          lines: linesWithBullet,
          text: textWithBullet,
          type,
        },
        staleNoteBlocks,
      };
    }
    case 'addOneLiner': {
      const { oneLinerText, staleNoteBlocks } = userAction;
      const editedIndex = 0;

      // 1. Note block not a condition
      // Always try to append to the first note block

      const editedNoteBlock = nth(staleNoteBlocks, editedIndex);

      if (!editedNoteBlock) {
        throw new Error(`Note block at index 0 does not exist.`);
      }
      if (editedNoteBlock.type !== 'condition' && editedNoteBlock.type !== 'shelvedCondition') {
        throw new Error(`Note block at index 0 is not a condition or shelved condition.`);
      }

      // 2. AddBullet

      const { lines, text, type, ...rest } = editedNoteBlock as ConditionNoteBlock;

      const oneLinerLines: MeshedNoteFreetextHtmlLine[] = plainTextToHtmlLines(oneLinerText).map(
        (html) => ({
          html,
          type: 'freetext',
        })
      );
      const newline: MeshedNoteFreetextHtmlLine = {
        html: EMPTY_BR_HTML_LINE,
        type: 'freetext',
      };
      const linesWithOneLiner = [...oneLinerLines, newline, ...lines];
      const textWithOneLiner = `${oneLinerText}\n\n${text}`;

      return {
        noteBlockCaretPos: 0,
        editedCase: EditedCase.AddBullet,
        editedIndex,
        editedPostModuleNoteBlock: {
          ...rest,
          lines: linesWithOneLiner,
          text: textWithOneLiner,
          type,
        },
        staleNoteBlocks,
      };
    }
    case 'hideOrMonitorBullet': {
      const { bulletStatus, bulletText, conditionId, staleNoteBlocks } = userAction;

      const editedIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);

      // 1. Index not found

      if (editedIndex === -1) {
        throw new Error(`Could not reprocess note. No condition with id ${conditionId}`);
      }

      // 2. HideOrMonitorBullet

      return {
        bulletStatus,
        bulletText,
        editedCase: EditedCase.HideOrMonitorBullet,
        editedIndex,
        staleNoteBlocks,
      };
    }
    case 'add': {
      const { index, staleNoteBlocks } = userAction;

      return {
        editedCase: EditedCase.Add,
        index,
        staleNoteBlocks,
      };
    }
    case 'dismiss': {
      const { conditionId, staleNoteBlocks } = userAction;

      return {
        conditionId,
        editedCase: EditedCase.Dismiss,
        staleNoteBlocks,
      };
    }
    case 'restore': {
      const { conditionHtml, staleNoteBlocks } = userAction;

      // 1. Get the new chunk with lines
      const { chunksWithLines: newChunksWithLines } = parseHtmlNoteByTitles({
        noteHtml: conditionHtml,
        parseMode: 'single',
      });

      // We can assume `newChunksWithLines.length === 1`
      const newChunkWithLines = newChunksWithLines[0];

      // 2. Get the index at which to restore
      let editedIndex = 0;
      const lastIndex = staleNoteBlocks.length - 1;

      const shelfDividerIndex = staleNoteBlocks.findIndex(isShelfDividerNoteBlock);
      if (shelfDividerIndex === -1) {
        editedIndex = isFooterChunk(staleNoteBlocks[lastIndex]) ? lastIndex : lastIndex + 1;
      } else {
        editedIndex = shelfDividerIndex;
      }

      return {
        editedCase: EditedCase.Restore,
        editedIndex,
        newChunkWithLines,
        staleNoteBlocks,
      };
    }
    case 'shelve': {
      const { conditionId, staleNoteBlocks } = userAction;
      const fromIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);
      const lastNoteBlock = staleNoteBlocks[staleNoteBlocks.length - 1];

      const isLastNoteBlockFooter = isFooterChunk(lastNoteBlock);

      const toIndex = isLastNoteBlockFooter
        ? staleNoteBlocks.length - 2
        : staleNoteBlocks.length - 1;

      return {
        editedCase: EditedCase.Move,
        fromIndex,
        staleNoteBlocks,
        toIndex,
        top: 0,
        scrollToEditedIndex: false,
      };
    }
    case 'move': {
      const { fromIndex, staleNoteBlocks, toIndex, top } = userAction;

      return {
        editedCase: EditedCase.Move,
        fromIndex,
        staleNoteBlocks,
        toIndex,
        top,
        scrollToEditedIndex: true,
      };
    }
    case 'merge': {
      const { staleNoteBlocks, conditionId, direction } = userAction;
      const fromIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);
      const toIndex = fromIndex + (direction === 'forward' ? 1 : -1);

      // Analytics

      const fromNoteBlock = nth(staleNoteBlocks, fromIndex);
      const fromModules = fromNoteBlock?.type === 'condition' ? fromNoteBlock.modules : [];
      const toNoteBlock = nth(staleNoteBlocks, toIndex);
      const toModules = toNoteBlock?.type === 'condition' ? toNoteBlock.modules : [];

      track.mergedNoteBlock({
        direction: direction === 'forward' ? 'down' : 'up',
        fromModules,
        toModules,
      });

      return {
        editedCase: EditedCase.Merge,
        fromIndex,
        staleNoteBlocks,
        toIndex,
      };
    }
    case 'shelfDividerTyping': {
      const { noteBlockCaretPos, noteBlockHtml, noteBlockId, staleNoteBlocks } = userAction;
      const editedIndex = staleNoteBlocks.findIndex(({ id }) => id === noteBlockId);

      // 1. Index not found
      if (editedIndex === -1) {
        throw new Error(`Could not reprocess note. No note section with id ${noteBlockId}`);
      }

      // 2. Note section not a condition or footer
      const editedNoteBlock = staleNoteBlocks[editedIndex];
      if (editedNoteBlock.type !== 'shelfDivider') {
        throw new Error(`Note section with id ${noteBlockId} is not a shelf divider.`);
      }
      const dividerLine = editedNoteBlock.lines[0];
      if (dividerLine.type !== 'shelfDivider') {
        throw new Error(`Note section with id ${noteBlockId} has a non shelf divider line.`);
      }

      const editedDividerNoteBlock = {
        ...editedNoteBlock,
        lines: [
          {
            ...dividerLine,
            htmlWithoutShelfSignifiers: stripShelfSignifiers(noteBlockHtml),
            html: noteBlockHtml,
          } as const,
        ],
        text: htmlStringToPlainText(noteBlockHtml),
      };

      return {
        editedCase: EditedCase.DividerTitle,
        editedDividerNoteBlock,
        editedIndex,
        noteBlockCaretPos,
        staleNoteBlocks,
      };
    }
    case 'typing': {
      const { noteBlockCaretPos, conditionHtml, conditionId, staleNoteBlocks } = userAction;

      const editedIndex = staleNoteBlocks.findIndex(({ id }) => id === conditionId);

      // 1. Index not found

      if (editedIndex === -1) {
        throw new Error(`Could not reprocess note typing. No condition with id ${conditionId}`);
      }

      // 2. Note section not a condition or footer

      const editedNoteBlock = staleNoteBlocks[editedIndex];

      if (editedNoteBlock.type !== 'condition' && editedNoteBlock.type !== 'shelvedCondition') {
        throw new Error(
          `Note section with id ${conditionId} is not a condition or shelved condition.`
        );
      }
      const editedConditionNoteBlock = editedNoteBlock;

      // ===

      const parseMode: ParseMode = 'singleBlockDoubleNewline';

      const {
        chunksWithLines: newChunksWithLines,
        shelfDividerWasAdded,
        caretPosition,
      } = parseHtmlNoteByTitles({
        noteHtml: conditionHtml,
        parseMode,
        noteBlockCaretPos,
      });

      // 3. NoStaleNoteBlocks - shelfDividerWasAdded
      // We have to find the other shelf divider and re-type all the conditions
      if (shelfDividerWasAdded) {
        const rejoinedNoteHtml = userAction.staleNoteBlocks
          .map(({ id, lines }) =>
            id === userAction.conditionId ? conditionHtml : linesToHtmlString(lines)
          )
          .join(EMPTY_BR_HTML_LINE) as HtmlString;
        return {
          chunksWithLines: parseHtmlNoteByTitles({
            noteHtml: rejoinedNoteHtml,
            parseMode: 'index',
          }).chunksWithLines,
          editedCase: EditedCase.NoStaleNoteBlocks,
        };
      }

      // 4. SplitIntoMultipleChunks

      if (newChunksWithLines.length > 1) {
        const newCaretPosition = {
          noteBlockIndex: editedIndex + caretPosition.noteBlockIndex,
          caretPos: caretPosition.caretPos,
        };

        return {
          staleNoteBlockCaretPos: noteBlockCaretPos,
          editedCase: EditedCase.SplitIntoMultipleChunks,
          editedIndex,
          newChunksWithLines,
          staleNoteBlocks,
          caretPosition: newCaretPosition,
        };
      }

      // 5. SameTitle OR DifferentTitle

      // We can assume `newChunksWithLines.length === 1`
      const newChunkWithLines = newChunksWithLines[0];
      const { lines, text } = newChunkWithLines;
      const newTitleLines = lines.filter(
        ({ type }) => type === 'title'
      ) as MeshedNoteTitleHtmlLine[];

      const staleTitleLines = editedConditionNoteBlock.lines.filter(
        ({ type }) => type === 'title'
      ) as TitleLine[];

      const newTitleLineText = newTitleLines
        .map(({ plainTextWithoutTitleSignifier }) => plainTextWithoutTitleSignifier)
        .join('');
      const staleTitleLineText = staleTitleLines
        .map(({ plainTextWithoutTitleSignifier }) => plainTextWithoutTitleSignifier)
        .join('');

      return newTitleLineText === staleTitleLineText
        ? {
            noteBlockCaretPos,
            editedCase: EditedCase.SameTitle,
            editedIndex,
            editedPostModuleNoteBlock: {
              ...editedConditionNoteBlock,
              lines,
              text,
            },
            staleNoteBlocks,
          }
        : {
            noteBlockCaretPos,
            editedCase: EditedCase.DifferentTitle,
            editedIndex,
            editedPreModuleNoteBlock: {
              ...editedConditionNoteBlock,
              lines,
              text,
            },
            staleNoteBlocks,
          };
    }
    case 'formatNoteTitles': {
      const { format, staleNoteBlocks } = userAction;

      return {
        editedCase: EditedCase.FormatNoteTitles,
        staleNoteBlocks,
        format,
      };
    }
    case 'initialize':
    default: {
      // NoStaleNoteBlocks
      return {
        chunksWithLines: parseHtmlNoteByTitles({
          noteHtml: userAction.htmlLines.join(EMPTY_BR_HTML_LINE) as HtmlString,
          parseMode: 'index',
        }).chunksWithLines,
        editedCase: EditedCase.NoStaleNoteBlocks,
      };
    }
  }
};
