import { FC } from 'react';

import { containerStyle, getInputCss, getLabelStyle, getSubLabelStyle } from './css';
import { useLabeledInput } from './useLabeledInput';

export type LabeledInputProps = {
  error?: string;
  inputProps?: Pick<JSX.IntrinsicElements['input'], 'autoComplete' | 'type'>;
  label: string;
  required?: boolean;
  subLabel?: string;
} & AugmentedRequired<
  Pick<JSX.IntrinsicElements['input'], 'onChange' | 'style' | 'value'>,
  'onChange' | 'value'
>;

export const LabeledInput: FC<LabeledInputProps> = ({
  error,
  inputProps,
  label,
  onChange,
  required,
  style,
  subLabel,
  value,
  ...rest
}) => {
  const { focus, forceBlur, forceFocus, isFilled, isFocused, ref } =
    useLabeledInput<HTMLInputElement>({ value });

  const finalSubLabel = error || subLabel;

  return (
    <div onClick={focus} style={style} {...rest}>
      <div style={containerStyle}>
        <span
          style={getLabelStyle({
            error,
            isFilled,
            isFocused,
          })}
        >
          {label}
          {required ? '*' : ''}
        </span>
        <input
          ref={ref}
          css={getInputCss({ error, isFocused })}
          onBlur={forceBlur}
          onChange={onChange}
          onFocus={forceFocus}
          value={value}
          {...inputProps}
        />
      </div>
      {finalSubLabel ? <span style={getSubLabelStyle({ error })}>{finalSubLabel}</span> : null}
    </div>
  );
};
LabeledInput.displayName = 'LabeledInput';
