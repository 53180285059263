import { ComplaintTableData } from '~/app/@types/state';
import { TableOptions } from './getInitialTableState';

const sortByAlpha = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortTableDataByLabel = (tableData: ComplaintTableData, tableOptions: TableOptions) => {
  const sortHandler = (aLabel: string, bLabel: string) => {
    const aId = tableOptions[aLabel].seriesId ?? '';
    const bId = tableOptions[bLabel].seriesId ?? '';
    const aSeries = tableData.series[aId];
    const bSeries = tableData.series[bId];

    if (!aSeries && !bSeries) return sortByAlpha(aLabel, bLabel);
    if (!bSeries) return -1;
    if (!aSeries) return 1;

    if (aSeries.category === bSeries.category) {
      const aOrdering = aSeries.ordering;
      const bOrdering = bSeries.ordering;
      if (!aOrdering && !bOrdering) return sortByAlpha(aLabel, bLabel);
      if (!bOrdering) return -1;
      if (!aOrdering) return 1;
      return aOrdering - bOrdering;
    }

    if (aSeries.category && bSeries.category) {
      return sortByAlpha(aSeries.category, bSeries.category);
    }

    if (aSeries.category && !bSeries.category) return -1;
    if (bSeries.category && !aSeries.category) return 1;

    return sortByAlpha(aLabel, bLabel);
  };

  return sortHandler;
};
