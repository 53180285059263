import { useEffect, useState } from 'react';

export const useDebounceNotByReference = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
        clearTimeout(timer);
      };
    },
    // It's critical that we compare the contents of the object as opposed to
    //  the reference, otherwise we'll get a bunch of unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(value), delay]
  );

  return debouncedValue;
};
