import { ReactElement } from 'react';
import { useBoolean } from 'usehooks-ts';

import { AccordionAPI, DumbAccordion, DumbAccordionProps } from '../dumbAccordion';

export type SmartAccordionProps<
  C extends Record<string, unknown>,
  H extends Record<string, unknown>
> = Omit<DumbAccordionProps<C, H>, keyof AccordionAPI> & {
  openByDefault?: boolean;
};

export const SmartAccordion = <
  C extends Record<string, unknown>,
  H extends Record<string, unknown>
>({
  openByDefault = false,
  ...rest
}: SmartAccordionProps<C, H>): ReactElement => {
  const { setFalse, setTrue, toggle, value } = useBoolean(openByDefault);

  return <DumbAccordion close={setFalse} isOpen={value} open={setTrue} toggle={toggle} {...rest} />;
};
SmartAccordion.displayName = 'SmartAccordion';
