import { FC } from 'react';

import { CernerCompactTable } from './CernerCompactTable';
import { CernerTable } from './CernerTable';
import { useDemoMedicationRows } from '../demoData';

export const CernerTabMedicationContent: FC = () => {
  const { rows } = useDemoMedicationRows();

  return (
    <>
      <CernerTable label="Medication" rows={[]} />
      <div style={{ height: 10 }} />
      <CernerCompactTable
        rows={rows}
        style={{
          flexBasis: '40%',
          textTransform: 'capitalize',
        }}
      />
    </>
  );
};
