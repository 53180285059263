import { FC } from 'react';

import { tableCss } from './css';
import { AccordionTableAccordion } from './AccordionTableAccordion';
import { AccordionTableSection } from './types';

interface AccordionTableProps {
  sections: AccordionTableSection[];
}

export const AccordionTable: FC<AccordionTableProps> = ({ sections }) =>
  sections.length ? (
    <div css={tableCss} data-cy-accordion-table>
      {sections.map((section, i) => (
        <AccordionTableAccordion key={section.header} first={i === 0} {...section} />
      ))}
    </div>
  ) : null;
