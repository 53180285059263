import { NotificationLevel, COMPANY_NAME } from '~/app/constants';
import { NotificationAttrs } from '~/app/@types/state';
import { isSalesDemoMode } from '~/app/flags';
import { NotificationType } from '~/app/@types';
import { createNotification } from './createNotification';

export const addErrorNotification = (
  notifications: NotificationAttrs[],
  errors: { module: string }[]
) => {
  if (isSalesDemoMode) return;

  const formattedErrors = errors
    .filter((e) => e.module)
    .map((e) => `#${e.module}`)
    .join(', ');

  // If the BE fails to run certain condition module(s) show an error notification
  if (!formattedErrors) return;

  const notification = createNotification({
    type: NotificationType.NoteError,
    content:
      `${COMPANY_NAME} was not able to process ${formattedErrors} ` +
      'because of an error reading the supporting data. Our team has already ' +
      'been notified of this error and is working to correct it.',
    level: NotificationLevel.Error,
  });
  notifications.push(notification);
};
