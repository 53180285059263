import { FC, memo, useCallback } from 'react';
import { CKEditorEventHandler } from 'ckeditor4-react';

import { useDispatch } from '~/app/store';
import { setLastFocusedEditor } from '~/app/actions/ui';

import { useNoteContext } from '../noteProvider';
import { SetRteCaretPosition } from '../condition/useConditionSetCaretPosition';
import { UnconnectedConditionRte } from '../unconnectedConditionRte';

import { useConditionText } from './useConditionText';
import { useConditionOnHtmlInput } from './useConditionOnHtmlInput';
import { useNoteBlockContext } from '../noteBlockProvider';

let lastFocusedEditor = '';

export const ConditionRte: FC<{
  setRteCaretPositionRef: SetRteCaretPosition;
}> = memo(({ setRteCaretPositionRef }) => {
  const dispatch = useDispatch();

  const { dismissCondition, mergeCondition, reviewMode } = useNoteContext(
    ({ dismissCondition, mergeCondition, reviewMode }) => ({
      dismissCondition,
      mergeCondition,
      reviewMode,
    })
  );

  const { buttonHovered, id, type } = useNoteBlockContext(({ buttonHovered, id, type }) => ({
    buttonHovered,
    id,
    type,
  }));
  const { htmlLines, textTimestamp } = useConditionText(id);
  const onHtmlInput = useConditionOnHtmlInput(id);

  const onFocus: CKEditorEventHandler<'focus'> = useCallback(
    ({ editor }) => {
      lastFocusedEditor = editor.name;
      dispatch(setLastFocusedEditor(editor.name));
    },
    [dispatch]
  );

  const onBlur: CKEditorEventHandler<'blur'> = useCallback(
    ({ editor }) => {
      // If we're blurring the editor that was last focused, that's something to
      //  care about; otherwise, this event is simply noise
      if (editor.name === lastFocusedEditor) {
        dispatch(setLastFocusedEditor(''));
      }
    },
    [dispatch]
  );

  const onDestroy: CKEditorEventHandler<'destroy'> = useCallback(
    ({ editor }) => {
      // If the editor is destroyed, treat it like the editor is being blured,
      // since the blur event isn't fired.
      if (editor.name === lastFocusedEditor) {
        dispatch(setLastFocusedEditor(''));
      }
    },
    [dispatch]
  );

  return (
    <UnconnectedConditionRte
      buttonHovered={buttonHovered}
      editorType={type}
      htmlLines={htmlLines}
      id={id}
      onBlur={onBlur}
      onDestroy={onDestroy}
      onEmptyBackspace={dismissCondition}
      onFocus={onFocus}
      onHtmlInput={onHtmlInput}
      onNonEmptyBackspace={mergeCondition}
      readOnly={reviewMode}
      setRteCaretPositionRef={setRteCaretPositionRef}
      textTimestamp={textTimestamp}
    />
  );
});
ConditionRte.displayName = 'ConditionRte';
