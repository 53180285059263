import { useCallback } from 'react';

import { useDispatch } from '~/app/store';

export const useDismissCondition = (): ((conditionId: string) => void) => {
  const dispatch = useDispatch();

  const dismissCondition = useCallback(
    (conditionId: string) => {
      dispatch({
        type: 'modify note block',
        payload: {
          type: 'dismiss',
          conditionId,
        },
      });
    },
    [dispatch]
  );

  return dismissCondition;
};
