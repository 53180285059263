import { FC } from 'react';

import { IconButton, StaticTooltip } from '~/app/reuse';
import { buttonTooltipStyle, subsequentIconStyle } from '../css';

export const UnderlineButton: FC<{
  active: boolean;
  disabled: boolean;
  onUnderlineClick(): void;
}> = ({ active, disabled, onUnderlineClick }) => (
  <StaticTooltip
    disabled={disabled}
    placement="bottom"
    style={buttonTooltipStyle}
    tooltip="Underline"
  >
    <IconButton
      active={active}
      color="tertiary-grey"
      data-cy-underline-button
      disabled={disabled}
      iconName="underline"
      onClick={onUnderlineClick}
      size="small"
      style={subsequentIconStyle}
    />
  </StaticTooltip>
);
