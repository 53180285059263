import { FC, ReactNode } from 'react';

import { CernerCompactTable } from './CernerCompactTable';
import { cernerTheme } from './cernerTheme';
import { DemoImageRow, DemoRow } from '../demoData';

export const CernerCompactTableWithDescription: FC<{
  rows: (DemoRow | DemoImageRow)[];
  descriptionBody: ReactNode;
}> = ({ rows, descriptionBody }) => (
  <>
    <CernerCompactTable
      rows={rows}
      style={{
        flexBasis: '40%',
        maxHeight: '100%',
        overflow: 'auto',
        textTransform: 'capitalize',
      }}
    />
    <div style={{ flex: '0 0 20px' }} />
    <div
      style={{
        backgroundColor: cernerTheme.colors.white,
        border: `1px solid ${cernerTheme.colors.darkBlue}`,
        flexBasis: '60%',
        maxHeight: '100%',
        overflow: 'auto',
        padding: 24,
        whiteSpace: 'pre-wrap',
      }}
    >
      {descriptionBody}
    </div>
  </>
);
