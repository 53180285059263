import { FC, memo } from 'react';

import { useConditionContext } from '../conditionProvider';
import { useNoteContext } from '../noteProvider';
import { UnconnectedNewConditionsLine } from './UnconnectedNewConditionsLine';
import { useNewDxIndicatorLineHidden } from './useNewDxIndicatorLineHidden';

export const NewConditionsLine: FC = memo(() => {
  const { isFirstNewCondition } = useConditionContext(({ isFirstNewCondition }) => ({
    isFirstNewCondition,
  }));
  const { numberOfNewConditions } = useNoteContext(({ numberOfNewConditions }) => ({
    numberOfNewConditions,
  }));
  const newDxIndicatorLineHidden = useNewDxIndicatorLineHidden();

  return isFirstNewCondition && !newDxIndicatorLineHidden ? (
    <UnconnectedNewConditionsLine numberOfNewConditions={numberOfNewConditions} />
  ) : null;
});
