import { noop } from 'lodash';

const delay = (callback: () => void, delayTime = 0) =>
  new Promise((resolve, reject) => {
    window.setTimeout(() => {
      try {
        callback();
        resolve(0);
      } catch (e) {
        reject(e);
      }
    }, delayTime);
  });

interface SyncRegistration {
  id: string;
  onSync: () => void;
}

/* eslint-disable no-underscore-dangle */
/**
 * Manages running multiple syncing operations at a given interval.
 * Designed to slow down and not overload a system if each "sync"
 * operation takes longer than the interval.
 */
export class SyncRegister {
  _registerId = '';

  _syncInterval = 0;

  _syncTimeoutId = 0;

  _syncRegistrations: SyncRegistration[] = [];

  constructor(registerId: string, syncInterval = 1000) {
    this._registerId = registerId;
    this._syncInterval = syncInterval;
  }

  _isWaitingOnInterval() {
    return this._syncTimeoutId !== 0;
  }

  async _sync() {
    // Make sure all syncs have finished before triggering next interval
    // This prevents a backup of sync operations if the registered syncs
    // take longer than the sync interval (aka, a new sync is started before
    // the last one has finished)

    await Promise.all(
      this._syncRegistrations.map(
        // An error should be thrown only if an editor no longer exists. Which
        // should be cleaned up on the next iteration automatically.
        (sync) => delay(sync.onSync).catch(noop)
      )
    );

    this._triggerNextInterval();
  }

  _triggerNextInterval() {
    // Clear any existing intervals before starting a new one. This prevents the rare
    // occurence of an explicit sync triggered via `triggerSync` while `_sync` is still
    // working through an inprogress sync operation
    this._clearSyncInterval();
    this._syncTimeoutId = window.setTimeout(() => this._sync(), this._syncInterval);
  }

  _clearSyncInterval() {
    window.clearTimeout(this._syncTimeoutId);
    this._syncTimeoutId = 0;
  }

  registerSync(registration: SyncRegistration) {
    this.unregisterSync(registration.id);
    this._syncRegistrations.push(registration);
    if (!this._isWaitingOnInterval()) {
      this._triggerNextInterval();
    }
  }

  unregisterSync(id: string) {
    this._syncRegistrations = this._syncRegistrations.filter((sync) => sync.id !== id);
    if (!this._syncRegistrations.length) {
      this._clearSyncInterval();
    }
  }

  /**
   * Delays any sync intervals in progress
   */
  delaySync() {
    if (!this._isWaitingOnInterval()) return;
    this._clearSyncInterval();
    this._triggerNextInterval();
  }

  /**
   * Clears current interval and immedetiately syncs
   */
  triggerSync() {
    this._clearSyncInterval();
    this._sync();
  }
}
/* eslint-enabled no-underscore-dangle */
