export const circleCheck = (
  <svg fill="none" height="15" viewBox="0 0 14 15" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.8135 5.36065L5.9535 8.22732L4.8535 7.12732C4.79373 7.05753 4.72019 7.00085 4.63748 6.96083C4.55477 6.92082 4.46468 6.89833 4.37287 6.89479C4.28105 6.89124 4.1895 6.90671 4.10395 6.94023C4.0184 6.97374 3.9407 7.02458 3.87573 7.08955C3.81076 7.15452 3.75992 7.23222 3.72641 7.31777C3.69289 7.40332 3.67742 7.49487 3.68097 7.58669C3.68451 7.6785 3.707 7.76859 3.74701 7.8513C3.78703 7.93401 3.84371 8.00755 3.9135 8.06732L5.48017 9.64065C5.54246 9.70244 5.61634 9.75132 5.69756 9.7845C5.77879 9.81767 5.86576 9.83449 5.9535 9.83398C6.12839 9.83325 6.29599 9.76381 6.42017 9.64065L9.7535 6.30732C9.81598 6.24534 9.86558 6.17161 9.89943 6.09037C9.93327 6.00913 9.9507 5.92199 9.9507 5.83398C9.9507 5.74598 9.93327 5.65884 9.89943 5.5776C9.86558 5.49636 9.81598 5.42263 9.7535 5.36065C9.62859 5.23648 9.45962 5.16679 9.2835 5.16679C9.10738 5.16679 8.93841 5.23648 8.8135 5.36065ZM7.00017 0.833984C5.68162 0.833984 4.39269 1.22498 3.29636 1.95752C2.20004 2.69006 1.34555 3.73125 0.840969 4.94943C0.336385 6.1676 0.204362 7.50805 0.461597 8.80125C0.718832 10.0945 1.35377 11.2823 2.28612 12.2147C3.21847 13.147 4.40636 13.782 5.69956 14.0392C6.99277 14.2965 8.33321 14.1644 9.55139 13.6598C10.7696 13.1553 11.8108 12.3008 12.5433 11.2045C13.2758 10.1081 13.6668 8.81919 13.6668 7.50065C13.6668 6.62517 13.4944 5.75827 13.1594 4.94943C12.8243 4.14059 12.3333 3.40566 11.7142 2.78661C11.0952 2.16755 10.3602 1.67649 9.55139 1.34145C8.74255 1.00642 7.87564 0.833984 7.00017 0.833984ZM7.00017 12.834C5.94533 12.834 4.91419 12.5212 4.03712 11.9352C3.16006 11.3491 2.47648 10.5162 2.07281 9.54163C1.66914 8.56709 1.56352 7.49474 1.76931 6.46017C1.9751 5.4256 2.48305 4.4753 3.22893 3.72941C3.97481 2.98354 4.92512 2.47558 5.95968 2.2698C6.99425 2.06401 8.0666 2.16963 9.04114 2.57329C10.0157 2.97696 10.8486 3.66055 11.4347 4.53761C12.0207 5.41467 12.3335 6.44582 12.3335 7.50065C12.3335 8.91514 11.7716 10.2717 10.7714 11.2719C9.77121 12.2721 8.41465 12.834 7.00017 12.834Z" />
  </svg>
);

export const circleSelected = (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99675 3.66341C5.15674 3.66341 3.66341 5.15674 3.66341 6.99675C3.66341 8.83675 5.15674 10.3301 6.99675 10.3301C8.83675 10.3301 10.3301 8.83675 10.3301 6.99675C10.3301 5.15674 8.83675 3.66341 6.99675 3.66341ZM6.99675 0.330078C3.31674 0.330078 0.330078 3.31674 0.330078 6.99675C0.330078 10.6767 3.31674 13.6634 6.99675 13.6634C10.6767 13.6634 13.6634 10.6767 13.6634 6.99675C13.6634 3.31674 10.6767 0.330078 6.99675 0.330078ZM6.99675 12.3301C4.05008 12.3301 1.66341 9.94341 1.66341 6.99675C1.66341 4.05008 4.05008 1.66341 6.99675 1.66341C9.94341 1.66341 12.3301 4.05008 12.3301 6.99675C12.3301 9.94341 9.94341 12.3301 6.99675 12.3301Z" />
  </svg>
);

export const circleEmpty = (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00016 0.333984C3.32016 0.333984 0.333496 3.32065 0.333496 7.00065C0.333496 10.6807 3.32016 13.6673 7.00016 13.6673C10.6802 13.6673 13.6668 10.6807 13.6668 7.00065C13.6668 3.32065 10.6802 0.333984 7.00016 0.333984ZM7.00016 12.334C4.0535 12.334 1.66683 9.94732 1.66683 7.00065C1.66683 4.05398 4.0535 1.66732 7.00016 1.66732C9.94683 1.66732 12.3335 4.05398 12.3335 7.00065C12.3335 9.94732 9.94683 12.334 7.00016 12.334Z" />
  </svg>
);
