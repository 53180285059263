import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createSelectItemAction } from '../../actions/record';
import { formatDate } from './content';

const ItemList = ({ items, selectedItem, columns, select }) => (
  <div className="htemr-ItemList">
    <div className="htemr-ItemList-row htemr-ItemList-headerRow">
      {columns.map((column) => (
        <strong key={column.key}>{column.label}</strong>
      ))}
    </div>
    <div className="htemr-ItemList-rows">
      {items.map((item) => (
        <div
          key={item.id}
          className={classNames('htemr-ItemList-row', {
            'htemr-ItemList-selectedRow': selectedItem === item,
          })}
          onClick={selectedItem === item ? null : () => select(item)}
        >
          {columns.map((column) => (
            <div key={column.key}>
              {formatDate(item[column.key])}
              {column.draftBadge && item.draft ? (
                <span className="htemr-ItemList-draftBadge">Draft</span>
              ) : null}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

ItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
    })
  ).isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.any.isRequired,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  select: PropTypes.func.isRequired,
};

ItemList.defaultProps = {
  selectedItem: null,
};

const mapStateToProps = (state, ownProps) => ({
  items: state.record.data[state.record.selectedScreen.contentKey].filter(ownProps.filterItem),
  selectedItem: state.record.selectedItem,
  columns: state.record.selectedScreen.displayFields.filter((f) => f.listColumn),
});

const mapDispatchToProps = {
  select: createSelectItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
