import { useCallback } from 'react';
import { TypingDirection } from '~/app/actions/regardNote';
import { useDispatch } from '~/app/store';

export const useMergeCondition = (): ((
  conditionId: string,
  direction: TypingDirection
) => void) => {
  const dispatch = useDispatch();

  const mergeCondition = useCallback(
    (conditionId: string, direction: TypingDirection) => {
      dispatch({
        type: 'modify note block',
        payload: {
          type: 'merge',
          conditionId,
          direction,
        },
      });
    },
    [dispatch]
  );

  return mergeCondition;
};
